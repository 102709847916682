/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';

import theme from 'Common/Utils/theme';
import { addEmptyItemAction } from 'App/State/MatterActions';
import push from 'Common/Utils/push';
import OptionsDrawer from 'Common/UI/OptionsDrawer/OptionsDrawer';
import { getSection } from 'Common/Data/App/appSections';
import {
  BANNER_HIDE,
  CONTENT_HIDE,
  DashboardPages,
  Sections,
} from 'Common/constants';
import AppFooter from 'Common/UI/AppFooter/AppFooter';
import MainDrawer from 'Common/UI/Layout/MainDrawer';
import Tooltip from 'Common/UI/Tooltip/Tooltip';
import { ThemeProvider } from 'styled-components';
import {
  DashboardProvider,
  DashboardConsumer,
} from 'Common/UI/DashboardContext';
import getBannerTheme from 'App/UI/Banner/getBannerTheme';
import { TopMessageDrawer } from 'Common/UI/Layout/Drawer';
import Banner from 'App/UI/Banner';
import BottomBannerWrapper from 'App/UI/Banner/BottomBannerWrapper';
import Loader from 'Common/UI/Loader/Loader';
import AppHeader from 'App/UI/AppHeader';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import PageContainer from 'Common/UI/Layout/PageContainer';
import ScrollerContainer from 'Common/UI/Layout/ScrollerContainer';
import generateDashboardTabs from 'Common/UI/Dashboard/common/generateDashboardTabs';
import {
  DrawerContent,
  HeaderContainer,
  NextPageButton,
} from 'Common/UI/Dashboard/common/index';
import { generateAppStateAction } from 'App/State/AppActions';
import { DataGroup } from 'Common/Data/Types/appSections';
import { DashboardPageProps } from 'Common/Data/Types/types';

type DashboardProps = {
  section: Sections;
  tab: DashboardPages;
  pages: { [key: string]: React.FC<DashboardPageProps> };
  topContent?: JSX.Element;
};

const Dashboard: React.FC<DashboardProps> = ({
  tab,
  section,
  pages,
  topContent,
}) => {
  const matter = useAppSelector(state => state.matter);
  const newAppState = useAppSelector(state => state.app.appState);
  const dispatch = useAppDispatch();

  const navigate = (to: string, state?: any) => dispatch(push(to, state));

  const [isLoading, setIsLoading] = useState(false);

  // There are still some places that need to reload the appstate on changes,
  // eg banner dismissing saving to session storage
  const updateAppState = () => {
    dispatch(generateAppStateAction({ matter, section, page: tab }));
  };

  // We load the app state whenever the matter gets updated
  useEffect(() => {
    dispatch(generateAppStateAction({ matter, section, page: tab }));
  }, [matter, tab]);

  const sectionData = getSection(section);

  if (!sectionData) {
    return null;
  }

  const hasMultiplePages =
    sectionData.children && sectionData.children.length > 1;

  const { bannerTopState, appState, bannerBottomState } = newAppState;

  // if we're not in production, include the app state on the dashboard for cypress tests to check
  // eslint-disable-next-line
  let DevAppState = null;

  /// #if STAGE !== 'production'
  DevAppState = <span data-cy="appState" data-cy-appstate={appState} />;
  /// #endif

  const showTopBanner =
    typeof bannerTopState === 'function' ? bannerTopState() : bannerTopState;

  const DashboardPageContent: React.FC = () => {
    const { contentState } = newAppState;

    if (contentState === CONTENT_HIDE) {
      return null;
    }

    const addEmptyItem = (sectionName: string) =>
      dispatch(addEmptyItemAction(sectionName));

    const handleAddItemById = (group: DataGroup) => {
      setIsLoading(true);

      const { id } = group;

      let incrementID;

      if ('isIndividual' in group && group.isIndividual) {
        const { self } = getPartyKeys();

        incrementID = `${id}${self}`;
      } else {
        incrementID = id;
      }

      return addEmptyItem(incrementID)
        .then(updateAppState)
        .then(() => setIsLoading(false));
    };

    const PageComponent = pages[tab];

    if (!PageComponent) {
      return null;
    }

    return (
      <DrawerContent>
        <PageComponent
          matter={matter}
          navigate={navigate}
          appState={appState}
          handleAddItemById={handleAddItemById}
        />
      </DrawerContent>
    );
  };

  return (
    <ThemeProvider theme={sectionData.theme ? sectionData.theme : theme}>
      <DashboardProvider
        appState={appState}
        updateAppState={updateAppState}
        section={section}
        tab={tab}
      >
        <DashboardConsumer>
          {({
            openOptionsDrawer,
            optionsForCard,
            optionsDrawerItems,
            closeOptionsDrawer,
          }) => (
            <PageContainer id="scrollMemory">
              <HeaderContainer className="transition-fade-up">
                <AppHeader
                  withNav={hasMultiplePages}
                  withProfile
                  matter={matter}
                  title={sectionData.title}
                  theme={sectionData.appHeaderTheme}
                  tabs={generateDashboardTabs({ sectionData, matter })}
                  withBackButton
                />
              </HeaderContainer>
              {showTopBanner !== BANNER_HIDE && (
                <TopMessageDrawer
                  hasFlex
                  open
                  zindex="1"
                  theme={getBannerTheme(appState)}
                >
                  <Banner
                    appState={appState}
                    navigate={navigate}
                    section={section}
                    page={tab}
                  />
                </TopMessageDrawer>
              )}
              <MainDrawer>
                <ScrollerContainer>
                  <>
                    {topContent}

                    <ResponsiveContainer>
                      <DashboardPageContent />
                    </ResponsiveContainer>

                    {hasMultiplePages && (
                      <ResponsiveContainer fullWidth>
                        <NextPageButton section={sectionData} page={tab} />
                      </ResponsiveContainer>
                    )}
                  </>
                </ScrollerContainer>
                {bannerBottomState !== BANNER_HIDE && (
                  <BottomBannerWrapper theme={getBannerTheme(appState)}>
                    <Banner
                      appState={appState}
                      navigate={navigate}
                      section={section}
                      page={tab}
                    />
                  </BottomBannerWrapper>
                )}
              </MainDrawer>
              <OptionsDrawer
                isOpen={openOptionsDrawer}
                card={optionsForCard}
                optionItems={optionsDrawerItems}
                handleClose={closeOptionsDrawer}
              />
              <AppFooter />
              {isLoading && <Loader floating />}
            </PageContainer>
          )}
        </DashboardConsumer>
      </DashboardProvider>
      <Tooltip />
      {DevAppState}
    </ThemeProvider>
  );
};

export default Dashboard;
