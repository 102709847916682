import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import { Row } from './Flex';
import defaultTheme from 'Common/Utils/theme';
import FlexDrawer from './FlexDrawer';

const StyledRow = styled(Row)`
  ${props => props.verticalButtons && 'flex-direction: column;'}
`;

const Dialog = ({
  showDialog,
  content,
  theme,
  buttons,
  verticalButtons,
  children,
}) => (
  <ThemeProvider theme={theme}>
    <FlexDrawer isVisible={showDialog}>
      {content && content}
      {buttons && (
        <StyledRow
          verticalButtons={verticalButtons}
          justifyContent="space-between"
        >
          {buttons}
        </StyledRow>
      )}
      {children && children}
    </FlexDrawer>
  </ThemeProvider>
);

Dialog.propTypes = {
  content: PropTypes.node,
  buttons: PropTypes.node,
  showDialog: PropTypes.bool,
  verticalButtons: PropTypes.bool,
  children: PropTypes.node,
  theme: PropTypes.shape({}),
};

Dialog.defaultProps = {
  showDialog: false,
  theme: defaultTheme,
  verticalButtons: false,
  content: null,
  buttons: null,
  children: null,
};

export default Dialog;
