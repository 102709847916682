import MatterProps, { getMatter } from 'Common/Utils/MatterProps';
import { getAppSections } from 'Common/Data/App/appSections';
import {
  CARD_NORMAL,
  CARD_CONTINUE,
  CARD_JURISDICTION,
  SECTION_CONSENT_ORDERS,
  PAGE_CONSENT_ORDERS_RELATIONSHIP,
  PAGE_CONSENT_ORDERS_ASSETS,
  PAGE_CONSENT_ORDERS_FINANCES,
  PAGE_CONSENT_ORDERS_GET_STARTED,
  STATUS_APPROVED,
} from 'Common/constants';
import {
  areSectionCardsCompleted,
  getSectionCardStatsRaw,
} from 'Common/Utils/cardHelpers';
import { isConsentOrdersAgreedToStart } from 'Common/Utils/consentOrders';
import AssetProperties from 'Common/Data/App/assetProperties';

export const isDefacto = () => {
  const relationshipData =
    MatterProps('items', []).find(item => item.SectionID === 'relationship') ||
    {};
  return !relationshipData.married;
};

export const consentOrderAppTally = () =>
  getSectionCardStatsRaw({
    appSections: getAppSections(),
    filter: [CARD_NORMAL, CARD_CONTINUE, CARD_JURISDICTION],
  });

export const areConsentOrderCardsCompleted = ({ page } = {}) =>
  areSectionCardsCompleted({
    appSections: getAppSections(),
    filter: [CARD_NORMAL, CARD_CONTINUE, CARD_JURISDICTION],
    canStart: isConsentOrdersAgreedToStart(),
    section: SECTION_CONSENT_ORDERS,
    page,
  });

export const consentOrdersCompletePercentage = () => {
  const {
    stats: { total, removed, completed },
  } = consentOrderAppTally();
  return (completed / (total - removed)) * 100;
};

export const getStartedCheck = {
  required: () => MatterProps('self.isSuitableConsentOrders'),
  // Note: this is coming back null if false
  requiredNotSuited: () =>
    MatterProps('self.isSuitableConsentOrders') !== true &&
    typeof MatterProps('self.isSuitableConsentOrders') !== 'undefined',
  jurisdictionSuited: () => {
    const jurisdiction = MatterProps('consentOrders.jurisdiction');
    if (jurisdiction === 'WA' || jurisdiction === 'FCA') {
      return true;
    }
    if (jurisdiction === 'Not Suited') {
      return false;
    }
    return undefined;
  },
  allCompleted: () =>
    areConsentOrderCardsCompleted({ page: PAGE_CONSENT_ORDERS_GET_STARTED }) &&
    getStartedCheck.required() &&
    getStartedCheck.jurisdictionSuited(),
};

export const isConsentOrdersComplete = () =>
  areConsentOrderCardsCompleted() && getStartedCheck.allCompleted();

export const canExportConsentOrdersKey = 'canStartConsentOrders';
export const notReadyToExportConsentOrdersKey = 'notReadyToStartConsentOrders';
export const checkPageUnLocked = {
  getStarted: {
    unlocked: () =>
      isConsentOrdersAgreedToStart() &&
      MatterProps('self.hasSeenConsentOrdersIntro', false),
    completed: () =>
      isConsentOrdersAgreedToStart() && getStartedCheck.allCompleted(),
  },
  relationship: {
    unlocked: () =>
      isConsentOrdersAgreedToStart() && getStartedCheck.allCompleted(),
    completed: () =>
      isConsentOrdersAgreedToStart() &&
      areConsentOrderCardsCompleted({ page: PAGE_CONSENT_ORDERS_RELATIONSHIP }),
  },
  assets: {
    unlocked: () =>
      isConsentOrdersAgreedToStart() && getStartedCheck.allCompleted(),
    completed: () =>
      isConsentOrdersAgreedToStart() &&
      areConsentOrderCardsCompleted({ page: PAGE_CONSENT_ORDERS_ASSETS }),
  },
  finances: {
    unlocked: () =>
      isConsentOrdersAgreedToStart() && getStartedCheck.allCompleted(),
    completed: () =>
      isConsentOrdersAgreedToStart() &&
      areConsentOrderCardsCompleted({ page: PAGE_CONSENT_ORDERS_FINANCES }),
  },
  completed: {
    /** Have all other tabs been filled out and agreed by both parties
     *
     * @returns {boolean}
     */
    unlocked: () =>
      isConsentOrdersAgreedToStart() &&
      getStartedCheck.allCompleted() &&
      areConsentOrderCardsCompleted(),
    /** Is `unlocked()` true and, if applicable, have both parties completed the super splitting process
     *
     * @returns {boolean}
     */
    completed: () => {
      const { isSuperIncludedInOffer } = AssetProperties();

      if (!isSuperIncludedInOffer) {
        return (
          isConsentOrdersAgreedToStart() &&
          getStartedCheck.allCompleted() &&
          areConsentOrderCardsCompleted()
        );
      }

      const { items = [] } = getMatter();

      const superannuationItem = items.find(
        item => item.SectionID === 'superannuationfundclauses'
      );

      if (
        !superannuationItem ||
        superannuationItem.status !== STATUS_APPROVED
      ) {
        return false;
      }

      return true;
    },
  },
};
