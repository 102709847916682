import React from 'react';
import PropTypes from 'prop-types';

import {
  SECTION_PARENTING,
  PAGE_PARENTING_ESSENTIALS,
  PAGE_PARENTING_SCHEDULE,
  PAGE_PARENTING_ADDITIONS,
  PAGE_PARENTING_COMPLETE,
} from 'Common/constants';
import Dashboard from 'App/UI/Dashboard/Dashboard';
import Essentials from './DashboardParenting/Essentials';
import Schedule from './DashboardParenting/Schedule';
import Additions from './DashboardParenting/Additions';
import Complete from './DashboardParenting/Complete';

const DashboardParenting = ({ tab }) => (
  <Dashboard
    tab={tab}
    section={SECTION_PARENTING}
    pages={{
      [PAGE_PARENTING_ESSENTIALS]: Essentials,
      [PAGE_PARENTING_SCHEDULE]: Schedule,
      [PAGE_PARENTING_ADDITIONS]: Additions,
      [PAGE_PARENTING_COMPLETE]: Complete,
    }}
  />
);

DashboardParenting.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default DashboardParenting;
