import React from 'react';
import { useAppSelector, useAppDispatch } from 'App/State/Store';
import {
  DOC_TYPE_PROPERTY_AGREEMENT,
  DOC_TYPE_CONSENT_ORDERS,
  FLAG_MATTER_OPTED_IN,
} from 'Common/constants';
import { hasItemBeenPaidFor, isAmicaFree } from 'Common/Utils/Payments';
import { getPropertyState } from 'App/Utils/sectionHelpers';
import {
  setPartyFlagsAction,
  getAgreementDocURLAction,
  setMatterFlagsAction,
} from 'App/State/MatterActions';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import openResolvedPromiseURL from 'Common/Utils/openResolvedPromiseURL';
import push from 'Common/Utils/push';
import { currentStatusConsentOrders } from 'Common/Utils/documentStatus';
import AssetProperties from 'Common/Data/App/assetProperties';
import getDocumentCardStatus from '../getDocumentCardStatus';
import ApplicationForConsentOrders from './ApplicationForConsentOrders';
import PropertyAgreement from './PropertyAgreement';

const PropertyDocumentCards: React.FC = () => {
  const matter = useAppSelector(state => state.matter);

  const { flags } = matter;
  const optedInFlags = flags.optedInSections ? flags.optedInSections : {};

  const isFree = isAmicaFree(matter);

  const { withLoading } = usePromiseWithLoading();

  const dispatch = useAppDispatch();

  const setPartyFlags = (partyFlags: Record<string, boolean>) =>
    withLoading(() => dispatch(setPartyFlagsAction(partyFlags)));
  const getPropertyAgreementDocURL = () =>
    dispatch(
      getAgreementDocURLAction({ name: DOC_TYPE_PROPERTY_AGREEMENT })
    ).unwrap();
  const openPropertyAgreement = () =>
    openResolvedPromiseURL(withLoading(getPropertyAgreementDocURL));
  const consentOrdersOptIn = () =>
    withLoading(() =>
      dispatch(
        setMatterFlagsAction({
          [FLAG_MATTER_OPTED_IN]: {
            ...optedInFlags,
            SECTION_CONSENT_ORDERS: true,
          },
        })
      )
    );
  const navigate = (route: string, state?: any) => dispatch(push(route, state));

  const propertyAgreementState = getDocumentCardStatus(
    DOC_TYPE_PROPERTY_AGREEMENT,
    getPropertyState()
  );

  const consentOrdersStatus = getDocumentCardStatus(
    DOC_TYPE_CONSENT_ORDERS,
    currentStatusConsentOrders()
  );

  const { isSuperIncludedInOffer } = AssetProperties();

  return (
    <>
      <PropertyAgreement
        isFree={isFree}
        isPaid={hasItemBeenPaidFor(matter, DOC_TYPE_PROPERTY_AGREEMENT)}
        status={propertyAgreementState}
        setPartyFlags={setPartyFlags}
        openAgreementFunctions={{ propertyAgreement: openPropertyAgreement }}
        isDisabled={isSuperIncludedInOffer}
        navigate={navigate}
      />
      <ApplicationForConsentOrders
        isFree={isFree}
        isPaid={hasItemBeenPaidFor(matter, DOC_TYPE_CONSENT_ORDERS)}
        status={consentOrdersStatus}
        setPartyFlags={setPartyFlags}
        navigate={navigate}
        consentOrdersOptIn={consentOrdersOptIn}
      />
    </>
  );
};

export default PropertyDocumentCards;
