import React from 'react';
import moment from 'moment';
import {
  DATE_FORMAT_LONG,
  PARTY_A,
  PARTY_B,
  DEFAULT_OTHER_TEXT,
  Party,
} from 'Common/constants';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import isDate from 'Common/Utils/isDate';
import isString from 'Common/Utils/isString';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import capitaliseText from 'Common/Utils/capitaliseText';
import getOtherParty from 'Common/Utils/getOtherParty';
import Matter from 'Common/Data/Types/matter';

export const childrenQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about children you had with your
        former partner.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    help: {
      label: 'Why is this important?',
      content: (
        <>
          <H1Conversational>Why is this important?</H1Conversational>

          <Paragraph large>
            If you have children under 18 who were born or adopted during the
            relationship, the way you share responsibility for taking care of
            the children will affect how your money and property are divided.
            You will be able to give us more information about your children in
            the parenting section of amica.
          </Paragraph>
        </>
      ),
    },
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'childName',
    label: "What is your child's name",
    hint: '',
    type: 'text',
    defaultValue: '',
    index: 1,
    sensitive: true,
    validate: value =>
      isString(value) ? [true] : [false, "Please enter your child's name"],
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>

          <Paragraph large>
            This means the full name of the child as it appears on their Birth
            Certificate or Change of Name Certificate.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'childAge',
    label: (values: Record<string, unknown>) =>
      `When was ${values.childName} born?`,
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 2,
    sensitive: true,
    validate: value =>
      isDate(value) ? [true] : [false, 'Please tell us how old your child is'],
    formAttributes: { max: new Date() },
  },
  {
    name: 'b1',
    label: () => (
      <>
        You only need to include children under 18 in amica. If you need help{' '}
        making parenting orders for children over 18, seek help and support{' '}
        <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
          here
        </ReferralLink>
      </>
    ),
    hint: '',
    type: 'blocker',
    defaultValue: '',
    index: 3,
    visible: answers => isDate(answers.childAge, { atLeast: 18 }),
  },
  {
    name: 'childHealthIssues',
    label: (values: Record<string, unknown>) =>
      `Does ${values.childName} have any additional needs?`,
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 4,
    help: {
      label: 'What is an additional need?',
      content: (
        <>
          <H1Conversational>What is an additional need?</H1Conversational>
          <Paragraph large>
            An additional need is a health issue or anything that means your
            child requires extra support or care. This might be a physical
            disability, a learning difficulty or a mental health issue. This is
            important because it can affect how much you or your former partner
            can earn, or what you may need, in the future.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'childcareResponsibilities',
    label: 'How do you plan to share parenting responsibilities?',
    hint: '',
    type: 'select',
    options: _MatterPropsJS => [
      {
        label: "I'll be sole carer",
        value: _MatterPropsJS('self.party'),
      },
      {
        label: "I'll have more childcare responsibility",
        value: `mostly${_MatterPropsJS('self.party')}`,
      },
      {
        label: "We'll share equally",
        value: 'both',
      },
      {
        label: `${capitaliseText(
          _MatterPropsJS('other.firstname', DEFAULT_OTHER_TEXT)
        )} will have more childcare responsibility`,
        value: `mostly${_MatterPropsJS('other.party')}`,
      },
      {
        label: `${capitaliseText(
          _MatterPropsJS('other.firstname', DEFAULT_OTHER_TEXT)
        )} will be sole carer`,
        value: _MatterPropsJS('other.party', PARTY_A),
      },
    ],
    defaultValue: '',
    index: 5,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            You will give us more information about your parenting arrangements
            later in the parenting section of amica. Right now we are only
            gathering an overview of your situation.
          </Paragraph>
          <Paragraph large>
            If you are unsure about how much childcare responsibility you are
            going to have, see the{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Help and Support page
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'childSupport',
    label: 'Do either of you pay child support?',
    hint: '',
    type: 'select',
    options: _MatterPropsJS => [
      {
        label: 'I pay',
        value: _MatterPropsJS('self.party'),
      },
      {
        label: `${_MatterPropsJS(
          'other.firstname',
          'Your former partner'
        )} pays`,
        value: _MatterPropsJS('other.party'),
      },
      {
        label: 'Neither of us pays',
        value: 'none',
      },
    ],
    defaultValue: '',
    index: 6,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            Depending on your situation, one of you may have to pay Child
            Support. The amount of Child Support that you have to pay depends on
            your circumstances. If you are unsure if you should be seeking or
            paying Child Support, see the
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Help and Support page
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 7,
  },
];

export const childrenStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'childName',
    'childAge',
    'childHealthIssues',
    'childcareResponsibilities',
    'childSupport',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(childrenQuestions, data, hideSensitive);

  const partyNames = {
    A: getFirstName(matter.partyA, PARTY_A, hideSensitive),
    B: getFirstName(matter.partyB, PARTY_B, hideSensitive),
  };

  const childName = redact('childName');

  const childInfo = highlight`${updated(
    'childName',
    redact('childName')
  )} was born 
    ${updated(
      'childAge',
      redact('childAge', moment(data.childAge).format(DATE_FORMAT_LONG))
    )}. They 
    ${updated(
      'childHealthIssues',
      data.childHealthIssues ? 'have' : "don't have"
    )} additional needs.`;

  let carer;
  let childSupport;

  if (
    data.childcareResponsibilities === 'A' ||
    data.childcareResponsibilities === 'B'
  ) {
    carer = highlight`${partyNames[data.childcareResponsibilities as Party]}
        will be the sole carer of ${updated(
          'childcareResponsibilities',
          childName
        )}.`;
  } else if (
    data.childcareResponsibilities === 'mostlyA' ||
    data.childcareResponsibilities === 'mostlyB'
  ) {
    const mostlyParty = data.childcareResponsibilities.slice(
      data.childcareResponsibilities.length - 1
    );

    carer = highlight`${partyNames[mostlyParty as Party]}
        will have more childcare responsibility for ${updated(
          'childcareResponsibilities',
          childName
        )}.`;
  } else {
    carer = highlight`${partyNames.A} and ${partyNames.B} 
        will share childcare responsibility ${updated(
          'childcareResponsibilities',
          'equally'
        )}.`;
  }

  if (data.childSupport === 'A' || data.childSupport === 'B') {
    childSupport = highlight`${updated(
      'childSupport',
      partyNames[data.childSupport as Party]
    )}
        is currently paying child support.`;
  } else {
    childSupport = highlight`${updated(
      'childSupport',
      'Neither person'
    )} is currently paying child support.`;
  }

  if (plainText) {
    return `${childInfo} ${carer} ${childSupport}`;
  }

  return (
    <>
      {childInfo} {carer} {childSupport}
    </>
  );
};

export const noChildrenQuestions: QuestionAny[] = [
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 0,
  },
];

export const noChildrenStatement = ({
  matter,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const highlight = getHighlightStatementValuesFunction(plainText);

  const partyAName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  const partyBName = getFirstName(matter.partyB, PARTY_B, hideSensitive);

  const statement = `${partyAName} and ${partyBName} do not have any children together.`;

  if (plainText) {
    return statement;
  }

  const highlightedText = highlight`${statement}`;

  return <>{highlightedText}</>;
};
