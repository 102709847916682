import React from 'react';

import {
  STATUS_APPROVED,
  STATUS_DISCUSSION,
  STATUS_NEW_BOTH_PARTIES,
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_REMOVED,
  STATUS_UPDATE_REQUESTED,
} from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import Card from 'Common/UI/Card';
import { youWereTheLastInDiscussion } from 'Common/Utils/getMessages';
import { canApproveCard } from 'Common/Utils/cardHelpers';
import { QuestionStateCard, QuestionCard } from 'Common/Data/Types/appSections';

// TODO: Need to be refactored
export const CardContent: React.FC<{
  noBox?: boolean;
  data: QuestionStateCard<QuestionCard>;
}> = ({ noBox, data }) => {
  const { notEditable, date, cardForParty } = data;

  return (
    <div style={{ flexGrow: 1 }}>
      <Card.Statement
        date={date}
        cardForParty={cardForParty}
        noBox={noBox}
        notEditable={notEditable}
      />
    </div>
  );
};

const ShowMore: React.FC<{ data: QuestionStateCard<QuestionCard> }> = ({
  data,
}) => {
  if ('showMoreText' in data && 'popUpContent' in data) {
    const { showMoreText, popUpContent } = data;

    return (
      <Card.LearnMoreButton
        spaced={{ marginTop: 'medium', marginBottom: 'medium' }}
        popUpContent={popUpContent}
      >
        {showMoreText}
      </Card.LearnMoreButton>
    );
  }

  return null;
};

type NormalCardProps = {
  data: QuestionStateCard<QuestionCard>;
  isActive?: boolean;
  themeName?: string;
  cardHeadingText?: string;
  matter: Matter;
};

const NormalCard: React.FC<NormalCardProps> = ({
  data,
  isActive,
  themeName,
  cardHeadingText,
  matter,
}) => {
  const {
    cardID,
    label,
    otherName,
    navToQuestions,
    navToView,
    status,
    date,
    typeName,
    cardForParty,
    autoApprove,
  } = data;

  switch (status) {
    case STATUS_NEW_ONLY_SELF:
      return (
        <Card data={data} theme={themeName} active={isActive}>
          <Card.MainHeading cardForParty={cardForParty} enableMoreButton>
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            Only you can create this statement
          </Card.Paragraph>
          <ShowMore data={data} />
          <Card.Button onClick={navToQuestions} dataCy="get-started-button">
            Get Started
          </Card.Button>
        </Card>
      );
    case STATUS_NEW_BOTH_PARTIES:
      return (
        <Card data={data} theme={themeName} active={isActive}>
          <Card.MainHeading cardForParty={cardForParty} enableMoreButton>
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            {`Either you or ${otherName} can create this statement`}
          </Card.Paragraph>
          <ShowMore data={data} />
          <Card.Button onClick={navToQuestions} dataCy="get-started-button">
            Get Started
          </Card.Button>
        </Card>
      );
    case STATUS_NEW_ONLY_THEM:
      return (
        <Card cardTheme="defaultOnlyThem" data={data} active={isActive}>
          <Card.MainHeading cardForParty={cardForParty}>
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph
            spaced={{ marginBottom: 'xlarge' }}
          >{`Only ${otherName} can create this statement`}</Card.Paragraph>
        </Card>
      );
    case STATUS_APPROVED:
      if (autoApprove) {
        return (
          <Card key={cardID} cardTheme="approved" data={data}>
            <Card.MainHeading isApproved enableMoreButton>
              {cardHeadingText}
            </Card.MainHeading>
            <Card.Statement noBox />
            <Card.AutoApproveFooter />
          </Card>
        );
      }

      return (
        <Card cardTheme="approved" data={data}>
          <Card.MainHeading isApproved enableMoreButton>
            {cardHeadingText}
          </Card.MainHeading>
          <CardContent noBox data={data} />
          <Card.ApprovedFooter />
        </Card>
      );
    case STATUS_REMOVED:
      return (
        <Card data={data} cardTheme="removed">
          <Card.HeadingWithSideText heading={label} text={date} />
          <Card.MainHeading>{cardHeadingText}</Card.MainHeading>
          <ShowMore data={data} />
          <CardContent data={data} />
        </Card>
      );
    case STATUS_DISCUSSION:
    case STATUS_UPDATE_REQUESTED:
      return (
        <Card
          cardTheme={
            youWereTheLastInDiscussion(matter.messages, cardID)
              ? 'self'
              : 'other'
          }
          data={data}
          active={isActive}
        >
          <Card.HeadingWithSideText heading={label} />
          <Card.MainHeading
            spaced={{ marginTop: 'xxsmall', marginBottom: 'medium' }}
          >
            {cardHeadingText}
          </Card.MainHeading>
          <CardContent data={data} />
          <Card.LatestMessage />
          <ShowMore data={data} />
          <Card.Button size="fullWidth" onClick={navToView}>
            {youWereTheLastInDiscussion(matter.messages, cardID)
              ? 'View'
              : 'Review'}{' '}
            {typeName}
          </Card.Button>
        </Card>
      );
    default:
      if (canApproveCard(data, matter)) {
        return (
          <Card cardTheme="other" data={data} active={isActive}>
            <Card.HeadingWithSideText heading={label} enableMoreButton />
            <Card.MainHeading
              spaced={{ marginTop: 'xxsmall', marginBottom: 'medium' }}
            >
              {cardHeadingText}
            </Card.MainHeading>
            <div style={{ flexGrow: 1 }}>
              <CardContent data={data} />
            </div>
            <Card.LatestMessage />
            <ShowMore data={data} />
            <Card.Button size="fullWidth" onClick={navToView}>
              Review {typeName}
            </Card.Button>
          </Card>
        );
      }

      return (
        <Card cardTheme="self" data={data} active={isActive}>
          <Card.HeadingWithSideText heading={label} enableMoreButton />
          <Card.MainHeading
            spaced={{ marginTop: 'xxsmall', marginBottom: 'medium' }}
          >
            {cardHeadingText}
          </Card.MainHeading>
          <div style={{ flexGrow: 1 }}>
            <CardContent data={data} />
          </div>
          <Card.LatestMessage />
          <ShowMore data={data} />
          <Card.Button size="fullWidth" onClick={navToView}>
            View {typeName}
          </Card.Button>
        </Card>
      );
  }
};

export default NormalCard;
