import React, { ReactNode, useContext } from 'react';

import Matter from 'Common/Data/Types/matter';
import {
  EphemeralStateCard,
  QuestionStateCard,
  CardNormal,
  CardComponent,
  CardYourSuperannuation,
} from 'Common/Data/Types/appSections';
import { generateCardHeading, NormalCard } from 'App/UI/Dashboard/common/Cards';
import { DashboardContext } from 'Common/UI/DashboardContext';
import isPageContentsUnlocked from 'App/AppState/isPageContentsUnlocked';
import YourSuperannuationCard from '../common/Cards/YourSuperannuationCard/YourSuperannuationCard';

// Group
export const generateNormalCard = (
  data: QuestionStateCard<CardNormal>,
  matter: Matter
): ReactNode => {
  const { cardID } = data;

  const { section, tab } = useContext(DashboardContext);

  const isActive = isPageContentsUnlocked(matter, section, tab);
  const cardHeadingText = generateCardHeading(data, matter);

  return (
    <NormalCard
      key={cardID}
      data={data}
      matter={matter}
      isActive={isActive}
      cardHeadingText={cardHeadingText}
    />
  );
};

export const generateComponentCard = (
  data: EphemeralStateCard<CardComponent>
): ReactNode => {
  const { cardID } = data;

  if ('ComponentClass' in data) {
    const { ComponentClass } = data;

    return <ComponentClass key={cardID} />;
  }

  return null;
};

export const generateYourSuperannuationCard = (
  data: QuestionStateCard<CardYourSuperannuation>,
  matter: Matter
): ReactNode => {
  const { cardID } = data;

  const { section, tab } = useContext(DashboardContext);

  const isActive = isPageContentsUnlocked(matter, section, tab);
  const cardHeadingText = generateCardHeading(data, matter);

  return (
    <YourSuperannuationCard
      key={cardID}
      data={data}
      matter={matter}
      isActive={isActive}
      cardHeadingText={cardHeadingText}
    />
  );
};
