import React, { useEffect, useRef, useState } from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';

import {
  ODRS_PROFILE_ROUTE,
  ODRS_DASH_HOME_ROUTE,
  ODRS_PAYMENT_ROUTE,
  ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
  ODRS_NOTIFICATIONS_ROUTE,
  ODRS_DOCUMENTS_ROUTE,
  ODRS_PROFILE_SIGNOUT_ROUTE,
} from 'Common/routes';

import { isAmicaFree, isPaymentActionNeeded } from 'Common/Utils/Payments';
import IconProfile from 'Common/Assets/images/icon-profile.svg';
import IconHome from 'Common/Assets/images/icon-home.svg';
import IconDollar from 'Common/Assets/images/icon-dollar.svg';
import IconHamburger from 'Common/Assets/images/icon-hamburger.svg';
import IconClose from 'Common/Assets/images/icon-close-white.svg';
import IconBell from 'Common/Assets/images/icon-bell.svg';
import IconDocument from 'Common/Assets/images/icon-document.svg';
import IconLogout from 'Common/Assets/images/icon-logout.svg';
import { darkerTheme, Theme } from 'Common/Utils/theme';
import push from 'Common/Utils/push';
import Matter from 'Common/Data/Types/matter';
import { useAppDispatch } from 'App/State/Store';
import AmicaLogo from 'App/UI/AmicaLogo';

import { NavHeading3 } from 'Common/UI/Layout/SwipeNav';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import Appears from 'Common/UI/Layout/Appears';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { Title } from 'Common/UI/Text/Headings';
import { HighlightedLabel } from 'Common/UI/Text/Label';
import BackButton from 'Common/UI/Button/BackButton';
import { Location } from 'history';
import Navigation from 'Common/UI/Navigation';
import getNotifications from 'App/AppState/getNotifications';

const StyledBackButton = styled(BackButton)<{
  theme: Theme;
}>`
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

const LogoWrapper = styled.div`
  display: inline-block;
`;

const LogoBackWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const StyledAppHeader = styled.header<{
  theme: Theme;
  isMobileMenuOpen?: boolean;
  logoColour?: string;
  small?: boolean;
}>`
  transform: translate3d(0, 0, 0);
  background-color: ${props =>
    props.isMobileMenuOpen
      ? props.theme.colours.darkerBlack
      : props.theme.backgroundColour};
  position: relative;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  padding-top: ${({ theme }) => theme.spacing[8]};
  padding-bottom: 1px;
  transition: all 0.3s;

  svg {
    height: auto;
    width: 77px;
    transition: color 0.2s 0.1s;

    color: ${props => props.logoColour || props.theme.logoColour};
  }

  ${NavHeading3} {
    transition: font-size 0.5s;
    margin-bottom: 0;
  }

  ${props =>
    props.small &&
    css`
      padding: ${props.theme.padding.medium}px ${props.theme.padding.medium}px
        ${props.theme.padding.small}px;

      img {
        opacity: 0;
        transition: all 0.3s;
      }
    `}

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: ${({ theme }) => theme.spacing[8]};
  }
`;

const HeaderButton = styled.div<{
  theme: Theme;
  mobileMenuToggle?: boolean;
  withBackground?: boolean;
}>`
  background: transparent;
  border: 2px solid ${({ theme }) => theme.headerNavBackground};
  padding: 7px 16px 5px 16px;
  border-radius: 42px;
  margin-left: 16px;
  position: relative;

  ${({ mobileMenuToggle, theme }) =>
    mobileMenuToggle &&
    css`
      background: ${theme.headerNavBackgroundActive};
      border: 2px solid transparent;
    `}

  img {
    position: relative;
    top: 2px;
  }

  p {
    color: white;
    display: inline-block;
    padding-left: 8px;
    font-weight: bold;
    line-height: 2;
  }

  &.active {
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      z-index: -10;
      top: 0;
      bottom: 0;
      left: -${({ theme }) => theme.padding.medium}px;
      right: -${({ theme }) => theme.padding.medium}px;
      background: ${({ theme }) => theme.headerNavBackgroundActive};
    }
  }

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    background-color: ${({ theme }) => theme.headerNavBackground};
    transition: border 200ms, backgroundColor 200ms;

    &.active {
      border: 2px solid ${({ theme }) => theme.headerNavBorderActive};
      background-color: ${({ theme }) => theme.headerNavBackgroundActive};

      &:before {
        display: none;
      }
    }

    &:hover {
      border: 2px solid ${({ theme }) => theme.headerNavBorderHover};
    }
  }
`;

const HeaderIconButton = styled(HeaderButton)<{
  theme: Theme;
}>`
  margin-left: ${({ theme }) => theme.spacing[4]};
  padding: 7px 10px 5px 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.headerNavBackgroundActive};

  img {
    width: 18px;
    height: 18px;
  }

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    background-color: ${({ theme }) => theme.headerNavBackground};
    margin-left: ${({ theme }) => theme.spacing[12]};

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

const MobileMenuWrapper = styled.div<{
  theme: Theme;
}>`
  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    display: none;
  }
`;

const DesktopMenuWrapper = styled.div<{
  theme: Theme;
}>`
  display: none;

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    display: block;
  }
`;

const StyledAppears = styled(Appears)<{
  theme: Theme;
}>`
  ${({ theme }) => css`
    margin-left: -${theme.tokens.pageSpacingHorizontalSizes.default};
    margin-right: -${theme.tokens.pageSpacingHorizontalSizes.default};
  `}
`;

const MobileMenu = styled.div<{
  theme: Theme;
  isOpen?: boolean;
}>`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  padding: 0 ${props => props.theme.padding.medium}px 40px;
  background: ${props => props.theme.colours.black};
  position: relative;

  ${HeaderButton} {
    border-color: transparent;
    display: block;
    margin-left: 0;
    padding: 24px 0;
    border-bottom: 1px solid #2b2a36;
    border-radius: 0;
    text-decoration: none;

    p {
      float: none;
      padding-left: 12px;
    }
  }
`;

const StyledTopHeader = styled.div<{
  theme: Theme;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing[4]};
  position: relative;

  @media ${({ theme }) => theme.sizes.sm.breakpoint} {
    padding-bottom: ${({ theme }) => theme.spacing[12]};
  }

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  }
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: horizontal;
`;

const HeaderTitle = styled(Title)<{
  theme: Theme;
}>`
  margin: 0 0 ${({ theme }) => theme.spacing[2]};

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    margin: 0 0 ${({ theme }) => theme.spacing[2]};
  }
`;

const BubbleNotification = styled.div<{
  theme: Theme;
}>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colours.darkPurple};
  background: ${({ theme }) => theme.colours.lightBlue};
  position: absolute;
  top: -8px;
  right: -8px;
  color: ${({ theme }) => theme.colours.darkPurple};
  text-align: center;
  font-size: 12px;
  padding: 4px;

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    top: -6px;
    right: -6px;
  }
`;

type NotificationButtonProps = {
  numNotifications: number;
};

const NotificationButton: React.FC<
  NotificationButtonProps & React.ComponentProps<typeof HeaderIconButton>
> = ({ numNotifications, children, ...props }) => (
  <HeaderIconButton {...props}>
    {children}
    {numNotifications > 0 && (
      <BubbleNotification>{numNotifications}</BubbleNotification>
    )}
  </HeaderIconButton>
);

const PaymentActionIndicator = styled.div`
  width: 12px;
  height: 12px;
  background: ${props => props.theme.colours.lightBlue};
  margin-left: 8px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 1px;

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    top: 0;
  }
`;

type PaymentButtonProps = {
  needsAction?: boolean;
};

const PaymentButton: React.FC<
  PaymentButtonProps & React.ComponentProps<typeof HeaderButton>
> = ({ needsAction, children, ...props }) => (
  <HeaderButton {...props}>
    {children}
    {needsAction && <PaymentActionIndicator />}
  </HeaderButton>
);

type Tabs = {
  withNav: true;
  tabs: [
    {
      label: string;
      to: string;
      status: 'completed' | 'locked' | 'updated' | 'not-completed';
      testId: string;
      disabled: boolean;
      onClick: () => void;
      active: boolean;
    }
  ];
};

type NoTabs = {
  withNav?: false;
  tabs?: undefined;
};

type AppHeaderProps = {
  withProfile?: boolean;
  small?: boolean;
  className?: string;
  matter: Matter;
  logoColour?: string;
  theme?: Theme;
  title?: string;
  withBackButton?: boolean;
  children?: React.ReactNode;
} & (Tabs | NoTabs);

const AppHeader: React.FC<AppHeaderProps> = ({
  withNav = false,
  withProfile = false,
  small = false,
  className,
  matter,
  logoColour,
  tabs,
  theme = darkerTheme,
  title,
  children,
  withBackButton = false,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const mobileHider = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const dispatch = useAppDispatch();

  const navigate = (to: string, state?: { location: Location }) =>
    dispatch(push(to, state));

  useEffect(() => {
    if (
      isMenuOpen &&
      mobileHider &&
      mobileHider.current &&
      container &&
      container.current &&
      mobileHider.current.style.position !== 'absolute'
    ) {
      container.current.style.minHeight = `${container.current.clientHeight}px`;
      mobileHider.current.style.left = `${mobileHider.current.offsetLeft}px`;
      mobileHider.current.style.top = `${mobileHider.current.offsetTop}px`;
      mobileHider.current.style.right = `${mobileHider.current.offsetLeft}px`;
      mobileHider.current.style.position = 'absolute';
    }
  }, [isMenuOpen]);

  const notifications = getNotifications(matter);

  return (
    <ResponsiveContainer fullWidth>
      <ThemeProvider theme={theme}>
        <StyledAppHeader
          small={small}
          className={className}
          logoColour={logoColour}
          isMobileMenuOpen={isMenuOpen}
          ref={container}
        >
          <StyledTopHeader>
            <LogoBackWrapper>
              {withBackButton && (
                <StyledBackButton
                  onClick={() => navigate(ODRS_DASH_HOME_ROUTE)}
                >
                  Back
                </StyledBackButton>
              )}
              <Link to={ODRS_DASH_HOME_ROUTE}>
                <LogoWrapper>
                  <AmicaLogo />
                </LogoWrapper>
              </Link>
            </LogoBackWrapper>
            <DesktopMenuWrapper>
              <ButtonContainer>
                <HeaderButton
                  as={NavLink}
                  exact
                  to={ODRS_DASH_HOME_ROUTE}
                  data-cy="home-button"
                >
                  <img src={IconHome} alt="Home" />
                  <HighlightedLabel> Home</HighlightedLabel>
                </HeaderButton>
                <HeaderButton
                  as={NavLink}
                  exact
                  to={ODRS_DOCUMENTS_ROUTE}
                  data-cy="documents-page"
                >
                  <img src={IconDocument} alt="Documents" />
                  <HighlightedLabel> Documents</HighlightedLabel>
                </HeaderButton>
                {matter && !isAmicaFree(matter) && (
                  <PaymentButton
                    needsAction={isPaymentActionNeeded(matter)}
                    as={NavLink}
                    to={
                      isPaymentActionNeeded(matter)
                        ? ODRS_PAYMENT_REVIEW_ORDER_ROUTE
                        : ODRS_PAYMENT_ROUTE
                    }
                    data-cy="payment-button"
                  >
                    <img src={IconDollar} alt="Payments" />
                    <HighlightedLabel> Payment</HighlightedLabel>
                  </PaymentButton>
                )}
                {withProfile && (
                  <HeaderButton
                    as={NavLink}
                    to={ODRS_PROFILE_ROUTE}
                    data-cy="profile-button"
                  >
                    <img src={IconProfile} alt="View your account" />
                    <HighlightedLabel> Account</HighlightedLabel>
                  </HeaderButton>
                )}
                <HeaderButton
                  as={NavLink}
                  to={ODRS_PROFILE_SIGNOUT_ROUTE}
                  data-cy="signout-button"
                >
                  <img src={IconLogout} alt="View your account" />
                  <HighlightedLabel> Sign out</HighlightedLabel>
                </HeaderButton>
                <NotificationButton
                  onClick={() =>
                    navigate(ODRS_NOTIFICATIONS_ROUTE, { location })
                  }
                  numNotifications={notifications.length}
                >
                  <img src={IconBell} alt="View your notifications" />
                </NotificationButton>
              </ButtonContainer>
            </DesktopMenuWrapper>
            <MobileMenuWrapper>
              <ButtonContainer>
                {!isMenuOpen && (
                  <>
                    <HeaderButton
                      as="button"
                      data-cy="menu-button"
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                      mobileMenuToggle
                    >
                      <img src={IconHamburger} alt="Menu" />
                      <HighlightedLabel> Menu</HighlightedLabel>
                    </HeaderButton>
                    <NotificationButton
                      onClick={() =>
                        navigate(ODRS_NOTIFICATIONS_ROUTE, { location })
                      }
                      numNotifications={notifications.length}
                      withBackground
                    >
                      <img src={IconBell} alt="View your notifications" />
                    </NotificationButton>
                  </>
                )}
                {isMenuOpen && (
                  <HeaderButton
                    as="button"
                    data-cy="menu-button"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    <img src={IconClose} alt="Close menu" />
                    <HighlightedLabel> Close</HighlightedLabel>
                  </HeaderButton>
                )}
              </ButtonContainer>
            </MobileMenuWrapper>
          </StyledTopHeader>

          <StyledAppears on={isMenuOpen}>
            <MobileMenu isOpen>
              <HeaderButton
                as={NavLink}
                exact
                to={ODRS_DASH_HOME_ROUTE}
                data-cy="home-button"
              >
                <img src={IconHome} alt="Home" />
                <HighlightedLabel> Home</HighlightedLabel>
              </HeaderButton>
              <HeaderButton
                as={NavLink}
                exact
                to={ODRS_DOCUMENTS_ROUTE}
                data-cy="documents-page"
              >
                <img src={IconDocument} alt="Documents" />
                <HighlightedLabel> Documents</HighlightedLabel>
              </HeaderButton>
              {matter && !isAmicaFree(matter) && (
                <PaymentButton
                  needsAction={isPaymentActionNeeded(matter)}
                  as={NavLink}
                  to={
                    isPaymentActionNeeded(matter)
                      ? ODRS_PAYMENT_REVIEW_ORDER_ROUTE
                      : ODRS_PAYMENT_ROUTE
                  }
                  data-cy="payment-button"
                >
                  <img src={IconDollar} alt="Payments" />
                  <HighlightedLabel> Payment</HighlightedLabel>
                </PaymentButton>
              )}
              {withProfile && (
                <HeaderButton
                  as={NavLink}
                  exact
                  to={ODRS_PROFILE_ROUTE}
                  data-cy="profile-button"
                >
                  <img src={IconProfile} alt="View your profile" />
                  <HighlightedLabel> Account</HighlightedLabel>
                </HeaderButton>
              )}
              <HeaderButton
                as={NavLink}
                to={ODRS_PROFILE_SIGNOUT_ROUTE}
                data-cy="signout-button"
              >
                <img src={IconLogout} alt="View your account" />
                <HighlightedLabel> Sign out</HighlightedLabel>
              </HeaderButton>
            </MobileMenu>
          </StyledAppears>

          <SpacedBox
            marginBottom="small"
            className="mobile-hider"
            ref={mobileHider}
          >
            {title && <HeaderTitle>{title}</HeaderTitle>}

            {withNav && tabs && <Navigation tabs={tabs} />}
          </SpacedBox>
          {children}
        </StyledAppHeader>
      </ThemeProvider>
    </ResponsiveContainer>
  );
};

export default AppHeader;
