import React from 'react';
import { APP_SECTION_ID_CONSENT_ORDER } from 'Common/constants';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSYourContributions = props => (
  <MatterGuard>
    <CardQuestions
      appSectionID={APP_SECTION_ID_CONSENT_ORDER}
      baseID="yourcontributions"
      index="self"
      {...props}
    />
  </MatterGuard>
);

export default ODRSYourContributions;
