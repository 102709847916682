import {
  checkPageUnLocked,
  getStartedCheck,
} from 'Common/Utils/consentOrderPageChecks';
import {
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_NEW_BOTH_PARTIES,
  STATUS_INFO_ONLY,
  DATA_SECTION,
  DATA_GROUP,
  CARD_NORMAL,
  CARD_CONTINUE,
  CARD_JURISDICTION,
  CARD_SUBHEADING,
  CARD_AGREED_DIVISION,
  CARD_DIVIDING_ASSETS,
  CARD_VIEW,
  PAGE_CONSENT_ORDERS_GET_STARTED,
  PAGE_CONSENT_ORDERS_RELATIONSHIP,
  PAGE_CONSENT_ORDERS_ASSETS,
  PAGE_CONSENT_ORDERS_FINANCES,
  PAGE_CONSENT_ORDERS_COMPLETED,
  CARD_INFO,
  PARTY_BOTH,
  PARTY_A,
  PARTY_B,
  SECTION_CONSENT_ORDERS,
  DATA_PAGE,
  CARD_COMPONENT,
  CardStatus,
  CARD_CUSTOM,
} from 'Common/constants';

import MatterPropsJS, { getMatter } from 'Common/Utils/MatterProps';
import {
  ODRS_ABOUTYOU_CONTINUE_ROUTE,
  ODRS_CHILDREN_CONTINUE_ROUTE,
  ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE,
  ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE,
  ODRS_CONSENT_ORDERS_JURISDICTION_ROUTE,
  ODRS_CONSENT_ORDERS_RELATIONSHIP_ROUTE,
  ODRS_CONSENT_ORDERS_ASSET_ROUTE,
  ODRS_CONSENT_ORDERS_FINANCES_ROUTE,
  ODRS_CONSENT_ORDERS_COMPLETE_ROUTE,
  ODRS_RETURN_SPECIFIC_ITEMS_ROUTE,
  ODRS_YOURSUPERANNUATION_CONTINUE_ROUTE,
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_DIVISION_ASSETS_INFO,
  ODRS_ASSET_TABLE,
  ODRS_PROPERTIES_CONTINUE_ROUTE,
  ODRS_YOUR_CONTRIBUTIONS_ROUTE,
  ODRS_YOURINCOME_ROUTE,
  ODRS_BANKACCOUNT_ROUTE,
  ODRS_ACQUIRED_SOLD_ITEMS_ROUTE,
  ODRS_CONSENT_ORDERS_ROUTE,
  ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE,
} from 'Common/routes';
import { getNavItemStatus } from 'Common/UI/Navigation';
import BackgroundQuestionFlowConsentOrders from 'Common/Assets/images/question-flow-bg-consent-orders.png';
import colours from 'Common/Utils/colours';
import { darkGreenTheme, greenTheme } from 'Common/Utils/theme';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import ConsentOrdersDocumentCards from 'App/UI/DocumentCard/ConsentOrders/ConsentOrdersDocumentCards';
import {
  fullSuperannuationFundClauseGenerator,
  summarySuperannuationFundClauseGenerator,
} from 'Common/UI/Form/SuperannuationFundClauseEditor/SuperannuationFundClauseGenerator';
import SuperannuationFundClauseEditorCard from 'Common/UI/Form/SuperannuationFundClauseEditor/SuperannuationFundClauseEditorCard';
import AssetProperties from 'Common/Data/App/assetProperties';
import {
  jurisdictionPopContent,
  relationshipLearnMoreContent,
  contributionsLearnMoreContent,
  completedLearnMoreContent,
} from '../../../20200101/LearnMore/consentOrders';
import { buildCard } from '../../../buildCard';
import {
  childrenQuestionsContinue,
  childrenStatementContinue,
  returnSpecificItemsQuestions,
  returnSpecificItemsStatement,
  jurisdictionQuestions,
  jurisdictionStatement,
  propertiesContinueQuestions,
  propertiesContinueStatement,
  yourContributionsQuestions,
  yourContributionsStatement,
  yourIncomeQuestions,
  yourIncomeStatement,
  bankAccountQuestions,
  bankAccountStatement,
  acquiredSoldItemsQuestions,
  acquiredSoldItemsStatement,
} from '../../../20200101/Questions/ConsentOrders';
import {
  aboutYouQuestionsContinue,
  aboutYouStatementContinue,
} from '../../../20221124/Questions/ConsentOrders/aboutyouContinue';
import {
  consentOrderRequiredQuestions,
  yourSuperannuationQuestionsContinue,
  yourSuperannuationStatementContinue,
} from '../../Questions/ConsentOrders';
import { Item } from '../../../../Types/matter';
import {
  CardAgreedDivision,
  CardAny,
  CardComponent,
  CardContinue,
  CardCustom,
  CardDividingAssets,
  CardInfo,
  CardJurisdiction,
  CardNormal,
  CardSubheading,
  CardView,
  DataGroupRepeatable,
  DataSection,
} from '../../../../Types/appSections';
import {
  superannuationFundClausesQuestions,
  superannuationFundClausesStatement,
} from '../../Questions/ConsentOrders/superannuationFundClauses';

const generateConsentOrders = ({
  existingAgreedChildren,
  existingAgreedProperties,
  existingAgreedSuperannuationA,
  existingAgreedSuperannuationB,
}: {
  existingAgreedChildren?: any[];
  existingAgreedProperties?: any[];
  doesSuperExist: {
    [PARTY_A]: boolean;
    [PARTY_B]: boolean;
  };
  existingAgreedSuperannuationA?: any[];
  existingAgreedSuperannuationB?: any[];
}): DataSection => {
  const { isPayer } = AssetProperties();

  const matter = getMatter();

  const isSelfPartyA = matter.self.party === PARTY_A;

  // build superannuation cards for both parties
  const existingAgreedSuperannuationCardsA = existingAgreedSuperannuationA
    ? existingAgreedSuperannuationA?.map(superItem =>
        buildCard<CardContinue>({
          type: CARD_CONTINUE,
          baseID: 'yoursuperannuationcontinue',
          cardID: `yoursuperannuationcontinue${superItem.CardIndex}`,
          extendID: `yoursuperannuation${superItem.CardIndex}`,
          title: superItem.superannuationName,
          sensitiveTitle: 'Your Superannuation',
          questions: yourSuperannuationQuestionsContinue,
          statement: yourSuperannuationStatementContinue,
          index: superItem.CardIndex,
          questionsRoute: `${ODRS_YOURSUPERANNUATION_CONTINUE_ROUTE}/${superItem.CardIndex}`,
          updateRoute: `${ODRS_YOURSUPERANNUATION_CONTINUE_ROUTE}/${superItem.CardIndex}/update`,
          status: isSelfPartyA ? STATUS_NEW_ONLY_SELF : STATUS_NEW_ONLY_THEM,
          cannotBeRemoved: true,
          isRepeatable: false,
          requiredLabel: true,
        })
      )
    : [];

  const existingAgreedSuperannuationCardsB = existingAgreedSuperannuationB
    ? existingAgreedSuperannuationB?.map(superItem =>
        buildCard<CardContinue>({
          type: CARD_CONTINUE,
          baseID: 'yoursuperannuationcontinue',
          cardID: `yoursuperannuationcontinue${superItem.CardIndex}`,
          extendID: `yoursuperannuation${superItem.CardIndex}`,
          title: superItem.superannuationName,
          sensitiveTitle: 'Your Superannuation',
          questions: yourSuperannuationQuestionsContinue,
          statement: yourSuperannuationStatementContinue,
          index: superItem.CardIndex,
          questionsRoute: `${ODRS_YOURSUPERANNUATION_CONTINUE_ROUTE}/${superItem.CardIndex}`,
          updateRoute: `${ODRS_YOURSUPERANNUATION_CONTINUE_ROUTE}/${superItem.CardIndex}/update`,
          status: isSelfPartyA ? STATUS_NEW_ONLY_THEM : STATUS_NEW_ONLY_SELF,
          cannotBeRemoved: true,
          isRepeatable: false,
          requiredLabel: true,
        })
      )
    : [];

  const superannuationClausesCard = buildCard<CardCustom>({
    type: CARD_CUSTOM,
    baseID: 'superannuationfundclauses',
    cardID: 'superannuationfundclauses',
    title: 'Your application',
    questions: superannuationFundClausesQuestions,
    questionsRoute: ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE,
    statement: superannuationFundClausesStatement,
    status: isPayer ? STATUS_NEW_ONLY_SELF : STATUS_NEW_ONLY_THEM,
    updateRoute: `${ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE}/update`,
    summaryComponentGenerator: summarySuperannuationFundClauseGenerator,
    fullComponentGenerator: fullSuperannuationFundClauseGenerator,
    customCardComponent: SuperannuationFundClauseEditorCard,
    hideQuestionFlowHeader: true,
  });

  return {
    type: DATA_SECTION,
    id: SECTION_CONSENT_ORDERS,
    title: 'Creating an application for consent orders',
    route: ODRS_CONSENT_ORDERS_ROUTE,
    theme: darkGreenTheme,
    appHeaderTheme: greenTheme,
    questionFlowBackgroundImage: BackgroundQuestionFlowConsentOrders,
    questionFlowBackgroundColour: colours.lightAqua,
    children: [
      {
        type: DATA_PAGE,
        id: PAGE_CONSENT_ORDERS_GET_STARTED,
        title: '1. Get Started',
        subTitle: '1. Get Started',
        generateStatus: () =>
          getNavItemStatus({
            isCompleted: checkPageUnLocked.getStarted.completed(),
            isLocked: false,
          }),
        route: ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE,
        children: [
          {
            type: DATA_GROUP,
            id: 'requirements',
            title: undefined,
            children: [
              buildCard<CardView>({
                type: CARD_VIEW,
                baseID: 'consentRequirements',
                cardID: `consentRequirements${MatterPropsJS(
                  'self.party',
                  'A'
                )}`,
                title: 'Requirements for applying for consent orders',
                sensitiveTitle: 'Requirements for applying for consent orders',
                description:
                  'We need to check that your situation is suitable for seeking consent orders using amica.',
                matterCheck: () => ({
                  // used to show appropriate UI based on who has/hasn't filled out the card
                  selfComplete: MatterPropsJS(
                    'self.isSuitableConsentOrders',
                    false
                  ),
                  otherComplete: MatterPropsJS(
                    'other.isSuitableConsentOrders',
                    false
                  ),
                }),
                questions: consentOrderRequiredQuestions,
                statement: aboutYouStatementContinue,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE,
                updateRoute: `${ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE}/update`,
                status: STATUS_NEW_ONLY_SELF,
                hideFromOtherParty: true,
              }),
              buildCard<CardView>({
                type: CARD_VIEW,
                baseID: 'consentRequirements',
                cardID: `consentRequirements${MatterPropsJS(
                  'other.party',
                  'B'
                )}`,
                title: 'Requirements for applying for consent orders',
                sensitiveTitle: 'Requirements for applying for consent orders',
                description:
                  'We need to check that your situation is suitable for seeking consent orders using amica.',
                matterCheck: () => ({
                  // used to show appropriate UI based on who has/hasn't filled out the card
                  selfComplete: MatterPropsJS(
                    'self.isSuitableConsentOrders',
                    false
                  ),
                  otherComplete: MatterPropsJS(
                    'other.isSuitableConsentOrders',
                    false
                  ),
                }),
                questions: consentOrderRequiredQuestions,
                statement: aboutYouStatementContinue,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE,
                updateRoute: `${ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE}/update`,
                status: STATUS_NEW_ONLY_SELF,
                hideFromOtherParty: true,
              }),
              buildCard<CardJurisdiction>({
                type: CARD_JURISDICTION,
                baseID: 'jurisdiction',
                cardID: 'jurisdiction',
                title: 'Jurisdiction',
                typeName: 'jurisdiction',
                sensitiveTitle: 'Jurisdiction',
                questions: jurisdictionQuestions,
                statement: jurisdictionStatement,
                disableCard: () => getStartedCheck.required(),
                questionsRoute: ODRS_CONSENT_ORDERS_JURISDICTION_ROUTE,
                updateRoute: `${ODRS_CONSENT_ORDERS_JURISDICTION_ROUTE}/update`,
                showMoreText: 'More information',
                popUpContent: jurisdictionPopContent,
                status: STATUS_NEW_BOTH_PARTIES,
              }),
            ],
          },
        ],
      },
      {
        type: DATA_PAGE,
        id: PAGE_CONSENT_ORDERS_RELATIONSHIP,
        title: '2. Relationship',
        subTitle: '2. About you and your relationship',
        description: 'You can provide more details about your situation here',
        infoContent: relationshipLearnMoreContent,
        generateStatus: () =>
          getNavItemStatus({
            isCompleted: checkPageUnLocked.relationship.completed(),
            isLocked: !checkPageUnLocked.relationship.unlocked(),
          }),
        route: ODRS_CONSENT_ORDERS_RELATIONSHIP_ROUTE,
        children: [
          {
            type: DATA_GROUP,
            id: 'about',
            children: [
              buildCard<CardContinue>({
                type: CARD_CONTINUE,
                extendID: `aboutyou${MatterPropsJS('self.party', 'A')}`,
                baseID: 'aboutyoucontinue',
                cardID: `aboutyoucontinue${MatterPropsJS('self.party', 'A')}`,
                title: 'About You',
                sensitiveTitle: `About Party ${MatterPropsJS(
                  'self.party',
                  'A'
                )}`,
                questions: aboutYouQuestionsContinue,
                statement: aboutYouStatementContinue,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_ABOUTYOU_CONTINUE_ROUTE,
                updateRoute: `${ODRS_ABOUTYOU_CONTINUE_ROUTE}/update`,
                status: STATUS_NEW_ONLY_SELF,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
              buildCard<CardContinue>({
                type: CARD_CONTINUE,
                extendID: `aboutyou${MatterPropsJS('other.party', 'B')}`,
                baseID: 'aboutyoucontinue',
                cardID: `aboutyoucontinue${MatterPropsJS('other.party', 'B')}`,
                title: `About ${MatterPropsJS('other.firstname', 'Them')}`,
                sensitiveTitle: `About Party ${MatterPropsJS(
                  'other.party',
                  'B'
                )}`,
                questions: aboutYouQuestionsContinue,
                statement: aboutYouStatementContinue,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_ABOUTYOU_CONTINUE_ROUTE,
                status: STATUS_NEW_ONLY_THEM,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
            ],
          },
          ...(existingAgreedChildren && existingAgreedChildren.length > 0
            ? ([
                {
                  type: DATA_GROUP,
                  id: 'children',
                  title: 'Children',
                  singular: 'child',
                  plural: 'children',
                  defaultRoute: `${ODRS_CHILDREN_CONTINUE_ROUTE}`,
                  children: (() =>
                    /**
                     * This function was duplicated from appSections
                     */
                    existingAgreedChildren.reduce((acc, item) => {
                      const newAcc = [...acc];
                      const index = item.CardIndex;
                      const displayIndex = index;
                      const cardID = item.SectionID.replace(
                        /children(\d{1,2})/g,
                        'children-continue$1'
                      );
                      const extendID: string = item.SectionID.replace(
                        /children-continue(\d{1,2})/g,
                        'children$1'
                      );
                      return [
                        ...newAcc,
                        buildCard<CardContinue>({
                          type: CARD_CONTINUE,
                          baseID: 'children-continue',
                          extendID,
                          cardID: `${cardID}${MatterPropsJS(
                            'self.party',
                            'A'
                          )}`,
                          title: `Your parenting arrangements for ${item.childName}`,
                          sensitiveTitle: `Child ${displayIndex}`,
                          questions: childrenQuestionsContinue,
                          statement: childrenStatementContinue,
                          index: `${index}${MatterPropsJS('self.party', 'A')}`,
                          isRepeatable: true,
                          questionsRoute: `${ODRS_CHILDREN_CONTINUE_ROUTE}/${index}${MatterPropsJS(
                            'self.party',
                            'A'
                          )}`,
                          updateRoute: `${ODRS_CHILDREN_CONTINUE_ROUTE}/${index}${MatterPropsJS(
                            'self.party',
                            'A'
                          )}/update`,
                          status: STATUS_NEW_ONLY_SELF,
                          cannotBeRemoved: true,
                        }),
                        buildCard<CardContinue>({
                          type: CARD_CONTINUE,
                          baseID: 'children-continue',
                          extendID,
                          cardID: `${cardID}${MatterPropsJS(
                            'other.party',
                            'B'
                          )}`,
                          title: `${MatterPropsJS(
                            'other.firstname',
                            'Your Former Partner'
                          )}'s parenting arrangements for ${item.childName}`,
                          sensitiveTitle: `Child ${displayIndex}`,
                          questions: childrenQuestionsContinue,
                          statement: childrenStatementContinue,
                          index: `${index}${MatterPropsJS('other.party', 'B')}`,
                          isRepeatable: true,
                          questionsRoute: `${ODRS_CHILDREN_CONTINUE_ROUTE}/${index}${MatterPropsJS(
                            'other.party',
                            'B'
                          )}`,
                          updateRoute: `${ODRS_CHILDREN_CONTINUE_ROUTE}/${index}${MatterPropsJS(
                            'other.party',
                            'B'
                          )}/update`,
                          status: STATUS_NEW_ONLY_THEM,
                          cannotBeRemoved: true,
                        }),
                      ];
                    }, []))(),
                },
              ] as DataGroupRepeatable[])
            : []),
        ],
      },
      {
        type: DATA_PAGE,
        id: PAGE_CONSENT_ORDERS_ASSETS,
        title: '3. Assets',
        subTitle: '3. Assets',
        description:
          "We need to ask you a few more details about your assets. We've transferred the answers you’ve already completed.",
        generateStatus: () =>
          getNavItemStatus({
            isCompleted: checkPageUnLocked.assets.completed(),
            isLocked: !checkPageUnLocked.assets.unlocked(),
          }),
        route: ODRS_CONSENT_ORDERS_ASSET_ROUTE,
        children: [
          {
            type: DATA_GROUP,
            id: 'assets',
            children: [
              buildCard<CardInfo>({
                type: CARD_INFO,
                title: 'Your Asset pool',
                cardID: 'yourAssetPool',
                sensitiveTitle: 'Your Asset pool',
                description:
                  'We have transfered all your assets from the property section.',
                buttonRoute: ODRS_ASSET_TABLE,
                status: STATUS_NEW_BOTH_PARTIES,
              }),
              buildCard<CardSubheading>({
                type: CARD_SUBHEADING,
                id: 'superannuation',
                title: 'Superannuation',
              }),
              ...(isSelfPartyA
                ? [
                    ...existingAgreedSuperannuationCardsA,
                    ...existingAgreedSuperannuationCardsB,
                  ]
                : [
                    ...existingAgreedSuperannuationCardsB,
                    ...existingAgreedSuperannuationCardsA,
                  ]),
            ],
          },
          {
            type: DATA_GROUP,
            id: 'dividingassets',
            title: 'Dividing assets',
            description:
              "You have the option to enter more details about your divided assets. We've transferred the answers you've already completed.",
            children: [
              ...(existingAgreedProperties &&
              existingAgreedProperties.length > 0
                ? ([
                    buildCard<CardSubheading>({
                      type: CARD_SUBHEADING,
                      id: 'realestate',
                      title: 'Real Estate',
                    }),
                    ...(() =>
                      // Both A / B can update
                      // Filter out any card that have not been approved
                      existingAgreedProperties
                        .filter(item => {
                          // filter out solely owned properties that havent been selected in asset transfer
                          const index = item.CardIndex;
                          const { propertyTitle } = item;
                          const extendID = `properties${index}`;

                          const transferAssets = MatterPropsJS(
                            'divi.assets',
                            []
                          );

                          return (
                            propertyTitle.toUpperCase() === PARTY_BOTH ||
                            transferAssets.find(
                              (asset: any) => asset.id === extendID
                            )
                          );
                        })
                        .map(item => {
                          const index = item.CardIndex;
                          const { propertyAddress, propertyTitle } = item;
                          const extendID = `properties${index}`;

                          let newStatus: CardStatus;

                          if (propertyTitle.toUpperCase() === PARTY_BOTH) {
                            newStatus = STATUS_NEW_BOTH_PARTIES;
                          } else if (
                            (MatterPropsJS('self.party') === PARTY_A &&
                              propertyTitle === PARTY_A) ||
                            (MatterPropsJS('self.party') === PARTY_B &&
                              propertyTitle === PARTY_B)
                          ) {
                            newStatus = STATUS_NEW_ONLY_SELF;
                          } else {
                            newStatus = STATUS_NEW_ONLY_THEM;
                          }

                          return buildCard<CardContinue>({
                            type: CARD_CONTINUE,
                            baseID: 'properties-continue',
                            cardID: `properties-continue${index}`,
                            title: propertyAddress,
                            extendID,
                            sensitiveTitle: `Property ${index}`,
                            questions: propertiesContinueQuestions,
                            statement: propertiesContinueStatement,
                            index,
                            isRepeatable: true,
                            questionsRoute: `${ODRS_PROPERTIES_CONTINUE_ROUTE}/${index}`,
                            updateRoute: `${ODRS_PROPERTIES_CONTINUE_ROUTE}/${index}/update`,
                            status: newStatus,
                            cannotBeRemoved: true,
                          });
                        }))(),
                  ] as CardAny[])
                : []),
              buildCard<CardSubheading>({
                type: CARD_SUBHEADING,
                id: 'specificitems',
                title: 'Specific Items',
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'returnspecificitems',
                cardID: `returnspecificitems${MatterPropsJS(
                  'self.party',
                  'A'
                )}`,
                title: 'Return of specific items',
                sensitiveTitle: 'Return of specific items',
                questions: returnSpecificItemsQuestions,
                statement: returnSpecificItemsStatement,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_RETURN_SPECIFIC_ITEMS_ROUTE,
                updateRoute: `${ODRS_RETURN_SPECIFIC_ITEMS_ROUTE}/update`,
                status: STATUS_NEW_ONLY_SELF,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'returnspecificitems',
                cardID: `returnspecificitems${MatterPropsJS(
                  'other.party',
                  'B'
                )}`,
                title: 'Return of specific items',
                sensitiveTitle: 'Return of specific items',
                questions: returnSpecificItemsQuestions,
                statement: returnSpecificItemsStatement,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_RETURN_SPECIFIC_ITEMS_ROUTE,
                status: STATUS_NEW_ONLY_THEM,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'acquiredsolditems',
                cardID: `acquiredsolditems${MatterPropsJS('self.party', 'A')}`,
                title: 'Acquired and sold items',
                sensitiveTitle: 'Acquired and sold items',
                questions: acquiredSoldItemsQuestions,
                statement: acquiredSoldItemsStatement,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_ACQUIRED_SOLD_ITEMS_ROUTE,
                updateRoute: `${ODRS_ACQUIRED_SOLD_ITEMS_ROUTE}/update`,
                status: STATUS_NEW_ONLY_SELF,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'acquiredsolditems',
                cardID: `acquiredsolditems${MatterPropsJS('other.party', 'B')}`,
                title: 'Acquired and sold items',
                sensitiveTitle: 'Acquired and sold items',
                questions: acquiredSoldItemsQuestions,
                statement: acquiredSoldItemsStatement,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_ACQUIRED_SOLD_ITEMS_ROUTE,
                status: STATUS_NEW_ONLY_THEM,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
              buildCard<CardSubheading>({
                type: CARD_SUBHEADING,
                id: 'assetdivision',
                title: 'Asset Division',
              }),
              buildCard<CardAgreedDivision>({
                type: CARD_AGREED_DIVISION,
                cardID: 'Your agreed division',
                title: 'Your agreed division',
                sensitiveTitle: 'Your agreed division',
                buttonRoute: ODRS_DIVISION_SPLIT_VIEW_ROUTE,
                status: STATUS_INFO_ONLY,
              }),
              buildCard<CardDividingAssets>({
                type: CARD_DIVIDING_ASSETS,
                cardID: 'dividingassets',
                title: 'Dividing assets',
                sensitiveTitle: 'Dividing assets',
                buttonRoute: ODRS_DIVISION_ASSETS_INFO,
                status: STATUS_INFO_ONLY,
              }),
            ],
          },
        ],
      },
      {
        type: DATA_PAGE,
        id: PAGE_CONSENT_ORDERS_FINANCES,
        title: '4. Finances',
        subTitle: '3. Finances',
        description:
          "We need to ask you a few more details about your finances. We've transferred the answers you've already completed.",
        generateStatus: () =>
          getNavItemStatus({
            isCompleted: checkPageUnLocked.finances.completed(),
            isLocked: !checkPageUnLocked.finances.unlocked(),
          }),
        route: ODRS_CONSENT_ORDERS_FINANCES_ROUTE,
        children: [
          {
            type: DATA_GROUP,
            id: 'finances',
            children: [
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'yourincome',
                cardID: `yourincome${MatterPropsJS('self.party', 'A')}`,
                title: 'Your Income',
                sensitiveTitle: `Your Income ${MatterPropsJS(
                  'self.party',
                  'A'
                )}`,
                questions: yourIncomeQuestions,
                statement: yourIncomeStatement,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_YOURINCOME_ROUTE,
                updateRoute: `${ODRS_YOURINCOME_ROUTE}/update`,
                status: STATUS_NEW_ONLY_SELF,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'yourincome',
                cardID: `yourincome${MatterPropsJS('other.party', 'B')}`,
                title: `${MatterPropsJS(
                  'other.firstname',
                  'Your former partner'
                )}'s income`,
                sensitiveTitle: `${MatterPropsJS('other.party', 'B')}'s income`,
                questions: yourIncomeQuestions,
                statement: yourIncomeStatement,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_YOURINCOME_ROUTE,
                updateRoute: `${ODRS_YOURINCOME_ROUTE}/update`,
                status: STATUS_NEW_ONLY_THEM,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
            ],
          },
          {
            type: DATA_GROUP,
            id: 'bankaccounts',
            title: 'Bank accounts',
            children: (() => {
              const children: CardAny[] = [];
              const partyIndex = MatterPropsJS('self.party');
              const partnerPartyIndex = MatterPropsJS('other.party');

              const items = MatterPropsJS('items');

              if (
                !items.filter((i: Item) => i.BaseSection === 'yourfinances') ||
                !items.filter((i: Item) => i.BaseSection === 'sharedfinances')
              ) {
                return [];
              }

              const baseProps = (
                i: number,
                letter: string
              ): Pick<
                CardContinue,
                | 'index'
                | 'subIndex'
                | 'type'
                | 'cardID'
                | 'baseID'
                | 'questions'
                | 'statement'
                | 'questionsRoute'
                | 'updateRoute'
                | 'cannotBeRemoved'
              > => {
                const index = `${i}${letter}`;
                return {
                  index,
                  subIndex: i,
                  type: CARD_CONTINUE,
                  cardID: `bankaccount${index}`,
                  baseID: 'bankaccount',
                  questions: bankAccountQuestions,
                  statement: bankAccountStatement,
                  questionsRoute: `${ODRS_BANKACCOUNT_ROUTE}/${index}`,
                  updateRoute: `${ODRS_BANKACCOUNT_ROUTE}/${index}/update`,
                  cannotBeRemoved: true,
                } as CardContinue;
              };

              // Add "your" bank accounts first
              const yourAccountItem = items.find(
                (i: Item) =>
                  i.BaseSection === 'yourfinances' && i.CardIndex === partyIndex
              );
              const yourAccounts =
                yourAccountItem && yourAccountItem.personalSavings
                  ? yourAccountItem.personalSavings
                  : [];

              yourAccounts.forEach((account: any, i: number) => {
                const { text } = account;
                children.push(
                  buildCard<CardContinue>({
                    ...baseProps(i, partyIndex),
                    extendID: `yourfinances${partyIndex}`,
                    title: `Your ${text} account`,
                    sensitiveTitle: `Your ${text} account`,
                    status: STATUS_NEW_ONLY_SELF,
                  })
                );
              });

              // Then add "shared" accounts
              const sharedAccountItem = items.find(
                (i: Item) => i.BaseSection === 'sharedfinances'
              );
              const sharedAccounts =
                sharedAccountItem && sharedAccountItem.sharedSavings
                  ? sharedAccountItem.sharedSavings
                  : [];

              sharedAccounts.forEach((account: any, i: number) => {
                const { text } = account;
                children.push(
                  buildCard<CardContinue>({
                    ...baseProps(i, 'S'),
                    extendID: 'sharedfinances',
                    title: `Your shared ${text} account`,
                    sensitiveTitle: `Your shared ${text} account`,
                    status: STATUS_NEW_BOTH_PARTIES,
                  })
                );
              });

              // Finally add "former partners" accounts
              const formerPartnersAccountItem = items.find(
                (i: Item) =>
                  i.BaseSection === 'yourfinances' &&
                  i.CardIndex === partnerPartyIndex
              );
              const formerPartnersAccount =
                formerPartnersAccountItem &&
                formerPartnersAccountItem.personalSavings
                  ? formerPartnersAccountItem.personalSavings
                  : [];

              formerPartnersAccount.forEach((account: any, i: number) => {
                const { text } = account;
                children.push(
                  buildCard<CardContinue>({
                    ...baseProps(i, partnerPartyIndex),
                    extendID: `yourfinances${partnerPartyIndex}`,
                    title: `${MatterPropsJS(
                      'other.firstname',
                      'Your former partner'
                    )}'s ${text} account`,
                    sensitiveTitle: `${MatterPropsJS(
                      'other.firstname',
                      'Your former partner'
                    )}'s ${text} account`,
                    status: STATUS_NEW_ONLY_THEM,
                  })
                );
              });

              return children;
            })(),
          },

          {
            type: DATA_GROUP,
            id: 'contributions',
            title: 'Contributions',
            description:
              "We're now going to ask some questions about what contributions were made by you throughout the relationship.",
            infoContent: contributionsLearnMoreContent,
            children: [
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'yourcontributions',
                cardID: `yourcontributions${MatterPropsJS('self.party', 'A')}`,
                title: 'Your contributions',
                sensitiveTitle: `Your contributions ${MatterPropsJS(
                  'self.party',
                  'A'
                )}`,
                questions: yourContributionsQuestions,
                statement: yourContributionsStatement,
                index: MatterPropsJS('self.party', 'A'),
                questionsRoute: ODRS_YOUR_CONTRIBUTIONS_ROUTE,
                updateRoute: `${ODRS_YOUR_CONTRIBUTIONS_ROUTE}/update`,
                status: STATUS_NEW_ONLY_SELF,
                autoApprove: true,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
              buildCard<CardNormal>({
                type: CARD_NORMAL,
                baseID: 'yourcontributions',
                cardID: `yourcontributions${MatterPropsJS('other.party', 'B')}`,
                title: `${MatterPropsJS(
                  'other.firstname',
                  'Your former partner'
                )}'s contributions`,
                sensitiveTitle: `${MatterPropsJS(
                  'other.party',
                  'B'
                )}'s life contributions`,
                questions: yourContributionsQuestions,
                statement: yourContributionsStatement,
                index: MatterPropsJS('other.party', 'B'),
                questionsRoute: ODRS_YOUR_CONTRIBUTIONS_ROUTE,
                updateRoute: `${ODRS_YOUR_CONTRIBUTIONS_ROUTE}/update`,
                status: STATUS_NEW_ONLY_THEM,
                autoApprove: true,
                cannotBeRemoved: true,
                isRepeatable: false,
              }),
            ],
          },
        ],
      },
      {
        type: DATA_PAGE,
        id: PAGE_CONSENT_ORDERS_COMPLETED,
        title: '5. Document creation',
        subTitle: '5. Document creation',
        description:
          'When you print your application amica will also provide you with a detailed Guide for filing your application with the Court. Read the Guide carefully. It contains a checklist where you can tick off the steps you will need to follow.',
        infoContent: completedLearnMoreContent,
        generateStatus: () =>
          getNavItemStatus({
            isCompleted: checkPageUnLocked.completed.completed(),
            isLocked: !checkPageUnLocked.completed.unlocked(),
          }),
        route: ODRS_CONSENT_ORDERS_COMPLETE_ROUTE,
        children: [
          {
            type: DATA_GROUP,
            id: 'complete',
            children: [
              superannuationClausesCard,
              buildCard<CardComponent>({
                type: CARD_COMPONENT,
                cardID: 'consentOrderDocumentCards',
                ComponentClass: ConsentOrdersDocumentCards,
              }),
            ],
          },
        ],
      },
    ],
  };
};

export default generateConsentOrders;
