import React from 'react';

import Documents from 'App/UI/Documents/Documents';
import MatterGuard from '../Guard/MatterGuard';

const ODRSDocuments = () => (
  <MatterGuard>
    <Documents />
  </MatterGuard>
);

export default ODRSDocuments;
