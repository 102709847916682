import React from 'react';
import { Redirect } from 'react-router-dom';

import DiviProps from 'Common/Data/App/diviProperties';
import {
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_DIVISION_VIEW_SUGGESTED,
  ODRS_DASH_HOME_ROUTE,
} from 'Common/routes';

const DivisionRedirecter = () => {
  const { isMatterInDivision, lastRound, hasMadeOffer } = DiviProps();

  if (!isMatterInDivision) {
    return <Redirect to={ODRS_DASH_HOME_ROUTE} />;
  }
  if (lastRound && !hasMadeOffer('self')) {
    return <Redirect to={ODRS_DIVISION_VIEW_SUGGESTED} />;
  }

  return <Redirect to={ODRS_DIVISION_SPLIT_VIEW_ROUTE} />;
};

export default DivisionRedirecter;
