import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import {
  INVITE_OTHER_PARTY,
  DASHBOARD_TAB_LOCKED,
  DEFAULT_OTHER_TEXT,
  PARENTING_PLAN_HAVE_NO_CHILDREN,
  PARENTING_PLAN_START,
  PARENTING_PLAN_AGREED,
  PARENTING_PLAN_WAITING_FOR_OTHER,
  PARENTING_PLAN_NEEDS_CONFIRMATION,
  PARENTING_PLAN_SELF_NOT_READY,
  PARENTING_PLAN_OTHER_NOT_READY,
  DOC_TYPE_PARENTING_AGREEMENT,
  NEW_PARENTING_PLAN_WAITING_FOR_OTHER,
  NEW_PARENTING_PLAN_NEEDS_CONFIRMATION,
  NEW_PARENTING_PLAN_SELF_NOT_READY,
  NEW_PARENTING_PLAN_OTHER_NOT_READY,
  DOC_TYPE_PARENTING_PLAN,
  PARENTING_PLAN_PAYMENT_REQUIRED,
  RELATIONSHIP_DASHBOARD_NOT_COMPLETED,
} from 'Common/constants';
import {
  sessionStorageSave,
  clearAllDismissSessionStorageParenting,
} from 'Common/Utils/sessionStorage';
import MatterPropsJS from 'Common/Utils/MatterProps';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

import {
  resetAgreementStateAction,
  setPartyFlagsAction,
} from 'App/State/MatterActions';

import {
  ODRS_INVITE_PARTNER_ROUTE,
  ODRS_DASH_PARENTING_PLAN_INTRO_ROUTE,
  ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
  ODRS_DASH_RELATIONSHIP_ROUTE,
} from 'Common/routes';
import TextButton from 'Common/UI/Button/TextButton';
import { useAppSelector } from 'App/State/Store';
import { getSection } from 'Common/Data/App/appSections';

import ParentingPlanConfirmPopup from 'App/UI/DocumentCard/Parenting/ParentingPlanConfirmPopup';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import Paragraph, { ParagraphNoMarginBottom } from 'Common/UI/Text/Paragraph';
import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import GenericButton from 'Common/UI/Button/GenericButton';
import DashboardTabLocked from 'Common/UI/Banners/DashboardTabLocked';
import { RelationshipDashboardNotCompletedParenting } from 'Common/UI/Banners/RelationshipDashboardNotCompleted';
import { DashboardContext } from 'Common/UI/DashboardContext';

const ButtonContainer = styled.div`
  ${GenericButton}:first-child {
    margin-bottom: ${({ theme }) => theme.padding.xsmall}px;
  }
`;

const ParentingPlanBannerContent = ({
  navigate,
  readyToExportParenting,
  readyToExportParentingPlan,
  notReadyToExportParenting,
  notReadyToExportNewParentingPlan,
  resetParentingAgreementState,
  resetNewParentingPlanAgreementState,
  appState,
  section,
}) => {
  const [isConfirmDrawerOpen, setIsConfirmDrawerOpen] = useState(false);

  const matter = useAppSelector(state => state.matter);

  const otherName = MatterPropsJS('other.firstname', DEFAULT_OTHER_TEXT);

  const { withLoading } = usePromiseWithLoading();
  const { updateAppState } = useContext(DashboardContext);

  let firstInProgressPageInSection;
  let pages;

  switch (appState) {
    case RELATIONSHIP_DASHBOARD_NOT_COMPLETED:
      return (
        <RelationshipDashboardNotCompletedParenting otherName={otherName} />
      );
    case DASHBOARD_TAB_LOCKED:
      pages = getSection(section)?.children || [];
      firstInProgressPageInSection = pages.find(
        ({ generateStatus }) =>
          generateStatus && generateStatus(matter) === 'not-completed'
      );

      return (
        <DashboardTabLocked
          navigate={navigate}
          firstInProgressPageInSection={firstInProgressPageInSection}
        />
      );
    case INVITE_OTHER_PARTY:
      return (
        <>
          <Heading2MarginTopNone>
            Invite your former partner to amica
          </Heading2MarginTopNone>
          <GenericButton
            small
            onClick={() =>
              navigate(ODRS_INVITE_PARTNER_ROUTE, { fromDashboard: true })
            }
          >
            Send an invite to their email
          </GenericButton>
        </>
      );
    case PARENTING_PLAN_HAVE_NO_CHILDREN:
      return (
        <>
          <Heading2MarginTopNone data-cy="parenting-blocker-banner">
            You haven’t added any children yet
          </Heading2MarginTopNone>
          <SpacedBox marginBottom="medium">
            <ParagraphNoMarginBottom>
              In order to start a parenting arrangement, you will need to add
              and agree on any children you and your former partner have in the
              ‘About you and your relationship’ section.
            </ParagraphNoMarginBottom>
          </SpacedBox>
          <GenericButton
            small
            onClick={() => navigate(ODRS_DASH_RELATIONSHIP_ROUTE)}
          >
            Go to section
          </GenericButton>
        </>
      );
    case PARENTING_PLAN_START:
      return (
        <>
          <Heading2MarginTopNone>Things to know</Heading2MarginTopNone>
          <SpacedBox marginBottom="medium">
            <ParagraphNoMarginBottom>
              Learn more about the purpose of parenting agreements, and how you
              can create an agreement in the best interest of your child.
            </ParagraphNoMarginBottom>
          </SpacedBox>
          <GenericButton
            small
            onClick={() => navigate(ODRS_DASH_PARENTING_PLAN_INTRO_ROUTE)}
            data-cy="things-to-know-start-button"
          >
            Learn about parenting agreements
          </GenericButton>
        </>
      );
    case PARENTING_PLAN_SELF_NOT_READY:
    case PARENTING_PLAN_AGREED:
      return (
        <>
          <SpacedBox marginBottom="medium">
            <Heading2MarginTopNone>
              You have completed all the required sections for your parenting
              agreement
            </Heading2MarginTopNone>
            <ParagraphNoMarginBottom>
              You can now create your parenting agreement, or you can add more
              statements before you finalise it.
            </ParagraphNoMarginBottom>
          </SpacedBox>
          <GenericButton
            fullWidth
            onClick={() => {
              sessionStorageSave(`${PARENTING_PLAN_AGREED}Dismissed`, true);
              updateAppState();
            }}
            small
          >
            Got it
          </GenericButton>
        </>
      );
    case PARENTING_PLAN_WAITING_FOR_OTHER:
      return (
        <SpacedBox marginBottom="xxsmall">
          <Heading2MarginTopNone>
            Waiting for {otherName} to confirm
          </Heading2MarginTopNone>
          <ParagraphNoMarginBottom>
            You will be able to view and save your agreements once you have both
            confirmed.
          </ParagraphNoMarginBottom>
        </SpacedBox>
      );
    case PARENTING_PLAN_NEEDS_CONFIRMATION:
      return (
        <>
          <Heading2MarginTopNone>
            Are you ready to create your parenting agreement document?
          </Heading2MarginTopNone>
          <Paragraph large>
            {otherName} has requested to create your parenting agreement
            document. You both need to confirm that you are ready to create your
            document as you can only do this once.
          </Paragraph>
          <ButtonContainer>
            <GenericButton
              small
              fullWidth
              onClick={() => withLoading(readyToExportParenting)}
            >
              Agree to create parenting agreement
            </GenericButton>
            <TextButton
              small
              fullWidth
              onClick={() => withLoading(notReadyToExportParenting)}
              color="#FFFFFF"
            >
              I&#39;m not ready yet
            </TextButton>
          </ButtonContainer>
        </>
      );
    case PARENTING_PLAN_OTHER_NOT_READY:
      return (
        <SpacedBox marginBottom="xxsmall">
          <Heading2MarginTopNone>
            {otherName} is not ready to create a parenting agreement
          </Heading2MarginTopNone>
          <Paragraph large>
            You will both need to agree on the document types before moving
            forward.
          </Paragraph>
          <GenericButton
            small
            fullWidth
            onClick={() =>
              withLoading(() => {
                clearAllDismissSessionStorageParenting();
                return resetParentingAgreementState();
              })
            }
          >
            Got it
          </GenericButton>
        </SpacedBox>
      );
    case PARENTING_PLAN_PAYMENT_REQUIRED:
      return (
        <>
          <Heading2MarginTopNone>
            Great! You have agreed to create a parenting agreement
          </Heading2MarginTopNone>
          <Paragraph large>
            You will need to pay to view or download your parenting agreement.
            You can pay now or return when you are ready to pay.
          </Paragraph>
          <ButtonContainer>
            <GenericButton
              fullWidth
              onClick={() => navigate(ODRS_PAYMENT_REVIEW_ORDER_ROUTE)}
              small
            >
              Pay now
            </GenericButton>
            <TextButton
              fullWidth
              onClick={() => {
                sessionStorageSave(
                  `${PARENTING_PLAN_PAYMENT_REQUIRED}Dismissed`,
                  true
                );
                updateAppState();
              }}
              small
              color="#FFFFFF"
            >
              Pay later
            </TextButton>
          </ButtonContainer>
        </>
      );
    case NEW_PARENTING_PLAN_SELF_NOT_READY: {
      return (
        <>
          <Heading2MarginTopNone>
            You have the option to turn your parenting agreement into a
            parenting plan by adding your digital signatures and the date
          </Heading2MarginTopNone>
          <Paragraph large>
            It is not essential, but a parenting plan holds more weight than a
            parenting agreement.{' '}
            <ReferralLink href="https://www.amica.gov.au/parenting-arrangements.html">
              Learn more.
            </ReferralLink>
          </Paragraph>
          <ButtonContainer>
            <GenericButton
              fullWidth
              onClick={() => setIsConfirmDrawerOpen(true)}
              small
            >
              Turn document into a parenting plan
            </GenericButton>
            <TextButton
              fullWidth
              onClick={() => {
                sessionStorageSave(
                  `${NEW_PARENTING_PLAN_SELF_NOT_READY}Dismissed`,
                  true
                );
                updateAppState();
              }}
              small
              color="#FFFFFF"
            >
              Dismiss
            </TextButton>
          </ButtonContainer>
          <ParentingPlanConfirmPopup
            isVisible={isConfirmDrawerOpen}
            closeDrawer={() => setIsConfirmDrawerOpen(false)}
            setPartyFlags={() => withLoading(readyToExportParentingPlan)}
          />
        </>
      );
    }
    case NEW_PARENTING_PLAN_WAITING_FOR_OTHER:
      return (
        <SpacedBox marginBottom="xxsmall">
          <Heading2MarginTopNone>
            Waiting for {otherName} to agree to turn your parenting agreement
            into a parenting plan
          </Heading2MarginTopNone>
          <ParagraphNoMarginBottom>
            If they agree, you can download your parenting plan.
          </ParagraphNoMarginBottom>
        </SpacedBox>
      );
    case NEW_PARENTING_PLAN_NEEDS_CONFIRMATION: {
      return (
        <>
          <Heading2MarginTopNone>
            {otherName} has requested to turn your parenting agreement into a
            parenting plan
          </Heading2MarginTopNone>
          <Paragraph large>
            You both need to confirm that you want to turn your parenting
            agreement into a parenting plan.
          </Paragraph>
          <ButtonContainer>
            <GenericButton
              small
              fullWidth
              onClick={() => setIsConfirmDrawerOpen(true)}
            >
              Agree to create parenting plan
            </GenericButton>
            <TextButton
              small
              fullWidth
              onClick={() => withLoading(notReadyToExportNewParentingPlan)}
              color="#FFFFFF"
            >
              Do not create this document
            </TextButton>
          </ButtonContainer>
          <ParentingPlanConfirmPopup
            isVisible={isConfirmDrawerOpen}
            closeDrawer={() => setIsConfirmDrawerOpen(false)}
            setPartyFlags={() => withLoading(readyToExportParentingPlan)}
          />
        </>
      );
    }
    case NEW_PARENTING_PLAN_OTHER_NOT_READY:
      return (
        <SpacedBox marginBottom="xxsmall">
          <Heading2MarginTopNone>
            {otherName} does not want to turn your parenting agreement into a
            parenting plan
          </Heading2MarginTopNone>
          <Paragraph large>
            You will both need to agree on the document types before moving
            forward.
          </Paragraph>
          <GenericButton
            small
            fullWidth
            onClick={() =>
              withLoading(() => {
                clearAllDismissSessionStorageParenting();
                return resetNewParentingPlanAgreementState();
              })
            }
          >
            Got it
          </GenericButton>
        </SpacedBox>
      );
    default:
      return null;
  }
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
  readyToExportParenting: () =>
    dispatch(setPartyFlagsAction({ canExportParenting: true })),
  readyToExportParentingPlan: () =>
    dispatch(setPartyFlagsAction({ canExportParentingPlan: true })),
  notReadyToExportParenting: () =>
    dispatch(setPartyFlagsAction({ notReadyToExportParenting: true })),
  notReadyToExportNewParentingPlan: () =>
    dispatch(setPartyFlagsAction({ notReadyToExportParentingPlan: true })),
  resetParentingAgreementState: () =>
    dispatch(resetAgreementStateAction({ name: DOC_TYPE_PARENTING_AGREEMENT })),
  resetNewParentingPlanAgreementState: () =>
    dispatch(resetAgreementStateAction({ name: DOC_TYPE_PARENTING_PLAN })),
});

ParentingPlanBannerContent.propTypes = {
  navigate: PropTypes.func.isRequired,
  readyToExportParenting: PropTypes.func.isRequired,
  readyToExportParentingPlan: PropTypes.func.isRequired,
  notReadyToExportParenting: PropTypes.func.isRequired,
  notReadyToExportNewParentingPlan: PropTypes.func.isRequired,
  resetParentingAgreementState: PropTypes.func.isRequired,
  resetNewParentingPlanAgreementState: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentingPlanBannerContent);
