import { useAppSelector } from 'App/State/Store';
import {
  DIALOG_CANCEL_SUPERANNUATION_BALANCE_REQUEST,
  DIALOG_REMOVE_SUPERANNUATION,
  DIALOG_RESTART_SUGGESTED_DIVISION,
  DIALOG_SUPER_CONFIRM_NO_CLAUSE_CHANGES,
} from 'Common/constants';
import theme from 'Common/Utils/theme';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import DialogCancelSuperannuationBalanceRequest from '../Division/Dialog/DialogCancelSuperannuationBalanceRequest';
import DialogRemoveSuperannuation from '../Division/Dialog/DialogRemoveSuperannuation';
import DialogRestartSuggestedDivision from '../Division/Dialog/DialogRestartSuggestedDivision';
import DialogConfirmNoClauseChanges from '../Form/SuperannuationFundClauseEditor/DialogConfirmNoClauseChanges';

const DialogManagerInner: React.FC = () => {
  const { dialog, isDialogShowing } = useAppSelector(state => state.app);

  if (isDialogShowing) {
    switch (dialog) {
      case DIALOG_RESTART_SUGGESTED_DIVISION:
        return <DialogRestartSuggestedDivision />;
      case DIALOG_SUPER_CONFIRM_NO_CLAUSE_CHANGES:
        return <DialogConfirmNoClauseChanges />;
      case DIALOG_CANCEL_SUPERANNUATION_BALANCE_REQUEST:
        return <DialogCancelSuperannuationBalanceRequest />;
      case DIALOG_REMOVE_SUPERANNUATION:
        return <DialogRemoveSuperannuation />;
      default:
        return null;
    }
  }

  return null;
};

const DialogManager: React.FC = () => (
  <ThemeProvider theme={theme}>
    <DialogManagerInner />
  </ThemeProvider>
);

export default DialogManager;
