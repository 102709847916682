import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { PARTY_DIVI } from 'Common/constants';
import DiviProps from 'Common/Data/App/diviProperties';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import { generateMessage } from 'App/UI/Messaging/Messages';
import { SCREEN_STATE_VIEW } from '../constants';
import SplitIndicator from '../Indicator/SplitIndicator';
import MakeOffer from '../Negotiate/MakeOffer';
import DivisionLearnMore from '../DivisionLearnMore';

const Wrapper = styled.div`
  margin: ${({ theme }) =>
    `0 ${theme.padding.medium}px ${theme.padding.small}px ${theme.padding.medium}px`};
`;

const DiviSuggestion = ({
  screenState,
  onUpdateOffer,
  showSuggested,
  showHistory,
  theme,
  invalidOffer,
  onInvalidOffer,
}) => {
  const { division, TAP, lastRound, getOfferColour } = DiviProps();

  const message =
    lastRound && lastRound.message
      ? {
          ...lastRound.message,
          owner: lastRound.party,
        }
      : undefined;

  const lastParty = !lastRound || showSuggested ? PARTY_DIVI : lastRound.party;

  const shouldShowMessage =
    screenState === SCREEN_STATE_VIEW && !showSuggested && message;

  const indicatorColours = getOfferColour(lastParty, theme);

  return (
    <Wrapper>
      {screenState === SCREEN_STATE_VIEW ? (
        <FlexGrid medium={2} large={2}>
          <SplitIndicator
            primary={lastRound && !showSuggested ? lastRound.split : division}
            secondary={0}
            totalAssetPool={TAP}
            screenState={screenState}
            animate
            indicatorColour={indicatorColours.indicatorColour}
            indicatorTextColour={indicatorColours.textColour}
          />
          {!showHistory && (
            <OffsetColumn>
              <DivisionLearnMore />
            </OffsetColumn>
          )}
        </FlexGrid>
      ) : (
        <MakeOffer
          onUpdateOffer={onUpdateOffer}
          onInvalidOffer={onInvalidOffer}
          invalidOffer={invalidOffer}
        />
      )}
      {shouldShowMessage && generateMessage(message)}
    </Wrapper>
  );
};

DiviSuggestion.propTypes = {
  screenState: PropTypes.string.isRequired,
  onUpdateOffer: PropTypes.func.isRequired,
  showSuggested: PropTypes.bool,
  onInvalidOffer: PropTypes.func,
  invalidOffer: PropTypes.bool,
  showHistory: PropTypes.bool,
  theme: PropTypes.shape({}),
};

DiviSuggestion.defaultProps = {
  showSuggested: false,
  invalidOffer: false,
  showHistory: false,
  theme: null,
};

export default withTheme(DiviSuggestion);
