import React from 'react';
import moment from 'moment';

import { PARTY_A, DATE_FORMAT_LONG, PARTY_B } from 'Common/constants';

import { formatTimeHhMmToAmPm } from 'Common/Utils/getDate';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getFirstName,
  getRedactFunction,
} from 'Common/Utils/statementHelpers';
import handoverQuestions from './handoverQuestions';

const generateHandoverStatement = ({
  data,
  matter,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}) => {
  if (!data || (data && Object.keys(data).length === 0)) {
    return 'This is where you will set handovers';
  }

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'willThereBeHandovers',
    'weekdayHandovers',
    'weekdayHandoverCustomLocation',
    'weekdayHandoverCustomTime',
    'weekendHandovers',
    'weekendHandoverCustomLocation',
    'weekdayHandoverCustomTime',
    'handoverStart',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(handoverQuestions, data, hideSensitive);

  const partyNames = {
    A: getFirstName(matter[`party${PARTY_A}`], PARTY_A, hideSensitive),
    B: getFirstName(matter[`party${PARTY_B}`], PARTY_B, hideSensitive),
  };

  const { willThereBeHandovers = true } = data;

  if (willThereBeHandovers) {
    let weekdayHandover;
    const weekdayHandoverTime = updated(
      ['weekdayHandovers', 'weekdayHandoverCustomTime'],
      formatTimeHhMmToAmPm(data.weekdayHandoverCustomTime)
    );

    switch (data.weekdayHandovers) {
      case matter.self.party:
      case matter.other.party:
        weekdayHandover = highlight`
          On weekdays, we will hand over the children at 
          ${updated('weekdayHandovers', partyNames[data.weekdayHandovers])}'s 
          house at ${weekdayHandoverTime}.`;

        break;

      case 'pickMeetingPoint':
        weekdayHandover = highlight`
          On weekdays, we will hand over the children at 
          ${updated(
            ['weekdayHandovers', 'weekdayHandoverCustomLocation'],
            redact('weekdayHandoverCustomLocation')
          )} 
          at ${weekdayHandoverTime}.
        `;

        break;

      case 'atSchool':
        weekdayHandover = highlight`
          On weekdays, we will hand over the children at 
          ${updated(['weekdayHandovers'], 'school')} 
        at ${weekdayHandoverTime}.`;
        break;

      case 'organiseLater':
      default:
        weekdayHandover = highlight`
        ${updated(
          'weekdayHandovers',
          'We will agree later where to hand over the children'
        )}.`;
        break;
    }

    let weekendHandover;
    const weekendHandoverTime = updated(
      ['weekendHandovers', 'weekdayHandoverCustomTime'],
      formatTimeHhMmToAmPm(data.weekendHandoverCustomTime)
    );

    switch (data.weekendHandovers) {
      case matter.self.party:
      case matter.other.party:
        weekendHandover = highlight`
          On weekends, we will hand over the children at 
          ${updated('weekendHandovers', partyNames[data.weekendHandovers])}'s 
          house at ${weekendHandoverTime}.`;
        break;

      case 'pickMeetingPoint':
        weekendHandover = highlight`
          On weekends, we will hand over the children at 
          ${updated(
            ['weekendHandovers', 'weekendHandoverCustomLocation'],
            redact('weekendHandoverCustomLocation')
          )} 
          at ${weekendHandoverTime}.
        `;
        break;

      case 'atSchool':
        weekendHandover = highlight`
          On weekends, we will hand over the children at 
          ${updated(['weekendHandovers'], 'school')} 
          at ${weekendHandoverTime}.`;
        break;

      case 'organiseLater':
      default:
        weekendHandover = highlight`
          ${updated(
            'weekendHandovers',
            'We will agree later where to hand over the children'
          )}.`;
        break;
    }

    const handoverStart = highlight`
      ${updated(
        'handoverStart',
        redact(
          'handoverStart',
          moment(data.handoverStart).format(DATE_FORMAT_LONG)
        )
      )}
    `;
    return plainText ? (
      `${weekdayHandover} ${weekendHandover} This schedule will start from ${handoverStart}`
    ) : (
      <>
        {weekdayHandover} {weekendHandover} This schedule will start from
        {handoverStart}
      </>
    );
  }

  const statement = highlight`${partyNames.A} and ${partyNames.B} ${updated(
    'willThereBeHandovers',
    'do not'
  )} need to agree on a handover time and location.`;

  return plainText ? statement : <>{statement}</>;
};

export default generateHandoverStatement;
