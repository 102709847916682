import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { ODRS_LOGIN_ROUTE } from 'Common/routes';
import { ProgressBarContext } from 'Common/Utils/ProgressBarContext';

import { InnerContentMiddle } from 'Common/UI/Signup/Layout';

import Loader from 'Common/UI/Loader/Loader';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { registerUserAction } from 'App/State/UserActions';
import Nude from 'Common/UI/Nude';

const SignupSignup = ({
  goNext,
  setParentState,
  registerUser,
  questionsInput,
  loadingRegisterUser,
  signupError,
  stepNumber,
}) => {
  const context = useContext(ProgressBarContext);
  const { updateStep } = context;

  useEffect(() => {
    updateStep(stepNumber);
  }, []);

  return (
    <InnerContentMiddle className="step-transition-fade-up">
      <Nude
        componentDidMount={() => {
          setParentState({ loadingRegisterUser: true, signupError: null });
          registerUser(questionsInput)
            .then(() => {
              setParentState({
                loadingRegisteredUser: false,
                signupError: null,
              });
              goNext();
            })
            .catch(error => {
              const { message = '' } = error;
              const { email = '' } = questionsInput;

              const errorsToSkipLogging = [
                'An account with the given email already exists.',
                'Need verification code.',
              ];

              if (!errorsToSkipLogging.includes(message)) {
                // this should never happen
                Sentry.withScope(scope => {
                  scope.setUser({ providedEmail: email });
                  Sentry.captureException(message);
                });
              }

              setParentState({
                loadingRegisteredUser: false,
                signupError: (
                  <>
                    <H1Conversational data-cy="signup-error">
                      Whoops, looks like that email address is already in use
                    </H1Conversational>
                    <Paragraph>
                      If you already have an account,{' '}
                      <Link to={ODRS_LOGIN_ROUTE}>log in</Link>
                    </Paragraph>
                  </>
                ),
              });
            });
        }}
      >
        {loadingRegisterUser && <Loader />}
        {signupError}
      </Nude>
    </InnerContentMiddle>
  );
};

SignupSignup.propTypes = {
  goNext: PropTypes.func.isRequired,
  setParentState: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  questionsInput: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  loadingRegisterUser: PropTypes.bool.isRequired,
  signupError: PropTypes.node,
  stepNumber: PropTypes.number,
};

SignupSignup.defaultProps = {
  signupError: null,
  stepNumber: 0,
};

const mapDispatchToProps = dispatch => ({
  registerUser: userProps => dispatch(registerUserAction(userProps)).unwrap(),
});

export default connect(null, mapDispatchToProps)(SignupSignup);
