import isPageContentsUnlocked from 'App/AppState/isPageContentsUnlocked';
import { DashboardPages, Sections } from 'Common/constants';
import { CardAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';
import generateOptionsForCard from 'App/UI/Dashboard/common/generateOptionsForCard';

const generateCardOptionsData = (
  card: CardAny,
  matter: Matter,
  section: Sections,
  tab: DashboardPages,
  showOptionsDrawer: (card: CardAny, options: string[]) => void
) => {
  let options: string[];
  let hasOptions = false;

  if (isPageContentsUnlocked(matter, section, tab)) {
    [options, hasOptions] = generateOptionsForCard(card);
  }

  return {
    showOptionsDrawer: () => options && showOptionsDrawer(card, options),
    hasOptions,
  };
};

export default generateCardOptionsData;
