import React, { useEffect, useState } from 'react';
import Paragraph from 'Common/UI/Text/Paragraph';

import BubbleNotification from 'Common/UI/BubbleNotification';
import { localStorageSave, localStorageLoad } from 'Common/Utils/localStorage';
import { AMICA_IS_FREE_NOTIFICATION_DISMISSED_LOCALSTORAGE_KEY } from 'Common/constants';
import { isAmicaFree } from 'Common/Utils/Payments';
import { useAppSelector } from 'App/State/Store';

const AmicaIsFree: React.FC = () => {
  const [showNotification, setShowNotification] = useState(false);

  const matter = useAppSelector(state => state.matter);

  const isFree = isAmicaFree(matter);

  useEffect(() => {
    if (
      isFree &&
      !localStorageLoad(AMICA_IS_FREE_NOTIFICATION_DISMISSED_LOCALSTORAGE_KEY)
    ) {
      setShowNotification(true);
    }
  }, []);

  const dismissNotification = () => {
    localStorageSave(
      AMICA_IS_FREE_NOTIFICATION_DISMISSED_LOCALSTORAGE_KEY,
      true
    );
    setShowNotification(false);
  };

  return showNotification ? (
    <BubbleNotification
      buttonLabel="Got it"
      onClick={dismissNotification}
      align="flex-start"
      padding="0px"
      dataCy="amica-is-free-notification"
    >
      <Paragraph>
        You and your former partner's income is below the threshold, so you can
        create documents in amica for free. You can learn more in the payments
        section.
      </Paragraph>
    </BubbleNotification>
  ) : null;
};

export default AmicaIsFree;
