import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  BANNER_HIDE,
  BANNER_SHOW,
  SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYEE,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import AssetProperties from 'Common/Data/App/assetProperties';

export const superannuationConsentOrderNoChangesPayeeFlags: AppState = {
  id: 'superannuationConsentOrderNoChangesPayee',
  description:
    'Notify the payee that the payer has confirmed that no changes are required to superannuation, CO is now finalised',
  check: () => {
    const { isSuperIncludedInOffer, superSplittingFlags, isPayee } =
      AssetProperties();

    const { payerHasConfirmedNoChangesToClauses } = superSplittingFlags;

    if (!isSuperIncludedInOffer || !isPayee) {
      return false;
    }

    return !!payerHasConfirmedNoChangesToClauses;
  },
  flags: {
    appState: SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYEE,
    bannerTopState: () =>
      sessionStorageLoad('superannuationConsentOrderNoChangesPayeeDismissed')
        ? BANNER_HIDE
        : BANNER_SHOW,
  },
};

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperannuationConsentOrderNoChangesPayee: React.FC<Props> = ({
  otherName,
  updateAppState,
}) => (
  <div data-cy="banner-consent-order-no-changes-payee">
    <SpacedBox marginBottom={8}>
      <Heading2MarginTopNone>
        Great, {otherName} has confirmed your draft minutes of consent
      </Heading2MarginTopNone>
      <Paragraph>
        Now that the superannuation fund has approved the draft minutes of
        consent, you can print and sign all of your documents with {otherName}.
      </Paragraph>
      <Paragraph>This includes the following documents:</Paragraph>
      <Paragraph as="ul">
        <li>Procedural Fairness Letter</li>
        <li>Proposed Minutes of Consent</li>
        <li>Application for Consent Orders</li>
      </Paragraph>
    </SpacedBox>
    <GenericButton
      fullWidth
      onClick={() => {
        sessionStorageSave(
          `superannuationConsentOrderNoChangesPayeeDismissed`,
          true
        );
        updateAppState();
      }}
      small
      data-cy="dismiss-button"
    >
      Got it
    </GenericButton>
  </div>
);
