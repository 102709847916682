import React from 'react';
import PropTypes from 'prop-types';

import { TopMessageDrawer } from 'Common/UI/Layout/Drawer';

import { H2Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

import {
  PROFILE_STATE_LIMIT_EXCEEDED,
  PROFILE_STATE_VERIFYING_PHONE_ERROR,
  PROFILE_STATE_VERIFYING_EMAIL_ERROR,
  PROFILE_STATE_VERIFY_EMAIL,
  PROFILE_STATE_VERIFYING_EMAIL,
  PROFILE_STATE_VERIFY_PHONE,
  PROFILE_STATE_VERIFYING_PHONE,
} from './constants';

const ProfilePromptContent = ({ state }) => {
  let promptContent = null;

  switch (state) {
    case PROFILE_STATE_LIMIT_EXCEEDED:
      promptContent = (
        <>
          <H2Conversational>Something went wrong</H2Conversational>
          <Paragraph>Please try again later.</Paragraph>
        </>
      );
      break;
    case PROFILE_STATE_VERIFYING_PHONE_ERROR:
      promptContent = (
        <>
          <H2Conversational>That code didn&rsquo;t match</H2Conversational>
          <Paragraph>Please check the code on your phone, or resend</Paragraph>
        </>
      );
      break;
    case PROFILE_STATE_VERIFYING_EMAIL_ERROR:
      promptContent = (
        <>
          <H2Conversational>That code didn&rsquo;t match</H2Conversational>
          <Paragraph>Please check the code in your email, or resend</Paragraph>
        </>
      );
      break;
    case PROFILE_STATE_VERIFY_EMAIL:
    case PROFILE_STATE_VERIFYING_EMAIL:
      promptContent = (
        <>
          <H2Conversational>Check your email</H2Conversational>
          <Paragraph>
            We&rsquo;ve sent you an email with a six digit verification code.
          </Paragraph>
        </>
      );
      break;
    case PROFILE_STATE_VERIFY_PHONE:
    case PROFILE_STATE_VERIFYING_PHONE:
      promptContent = (
        <>
          <H2Conversational>Check your phone</H2Conversational>
          <Paragraph>
            We&rsquo;ve sent you an SMS with a six digit verification code.
          </Paragraph>
        </>
      );
      break;
    default:
      promptContent = null;
  }

  return (
    <TopMessageDrawer zindex={1} open={promptContent !== null}>
      {promptContent}
    </TopMessageDrawer>
  );
};

ProfilePromptContent.propTypes = {
  state: PropTypes.string.isRequired,
};

export default ProfilePromptContent;
