import { propertyDashboardCompletedFlags } from 'Common/UI/Banners/PropertyDashboardCompleted';
import { assetSplitSelfOfferInProgressFlags } from 'Common/UI/Banners/AssetSplitSelfOfferInProgress';
import { documentTypesOtherNotReadyFlags } from 'Common/UI/Banners/DocumentTypesOtherNotReady';
import { documentTypesWaitingForOtherFlags } from 'Common/UI/Banners/DocumentTypesWaitingForOther';
import {
  SECTION_HOME,
  BANNER_HIDE,
  DOCUMENT_TYPES_COMPLETED,
} from 'Common/constants';
import {
  resetSuggestedDivisionWaitingForSelfFlags,
  resetSuggestedDivisionOtherNotReadyFlags,
  resetSuggestedDivisionWaitingForOtherFlags,
} from 'Common/UI/Banners/ResetSuggestedDivision';
import { areDocumentsAllCompleted } from 'Common/Utils/documentHelpers';
import { AppState } from 'Common/Data/Types/appState';

const states: AppState[] = [
  {
    id: 'docTypesCompleted',
    description: 'You both have completed the document types',
    check: () => areDocumentsAllCompleted(),
    flags: {
      bannerTopState: BANNER_HIDE,
      appState: DOCUMENT_TYPES_COMPLETED,
    },
  },
];

const data = {
  [SECTION_HOME]: {
    pageBlock: [],
    state: [
      resetSuggestedDivisionWaitingForOtherFlags,
      resetSuggestedDivisionOtherNotReadyFlags,
      resetSuggestedDivisionWaitingForSelfFlags,
      documentTypesOtherNotReadyFlags,
      documentTypesWaitingForOtherFlags,
      ...states,
      propertyDashboardCompletedFlags,
      assetSplitSelfOfferInProgressFlags,
    ],
  },
};

export default data;
