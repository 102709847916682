import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AssetProps from 'Common/Data/App/assetProperties';
import {
  ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
  ODRS_DIVISION_ASSETS_TRANSFER_JOINT_PROPERTY,
  ODRS_DIVISION_ASSETS_VIEW_OFFER,
} from 'Common/routes';
import Paragraph from 'Common/UI/Text/Paragraph';
import PageContainer from 'Common/UI/Layout/PageContainer';
import PageContent from 'Common/UI/Layout/PageContent';
import push from 'Common/Utils/push';
import Header from './Header';
import TransferIntro from '../Components/TransferIntro';
import { TransferFooter, DialogViewProposal } from './common';
import TransferAssetsList from '../Components/TransferAssetsList';
import { DivisionAssetsProvider } from './DivisionAssetsContext';

const DivisionAssetsTransferJoint = ({ matter, navigate }) => {
  const {
    transferrableAssets,
    transferrableDebts,
    haveAllJointAssetsExceptPropertiesBeenAssigned,
    absoluteRemainingAmountToTransfer,
    remainingAmountToTransfer,
    isAssetSplitCompleted,
    isOwingParty,
    otherPartyIsMakingAnOffer,
    shouldShowViewProposalDialog,
  } = useMemo(() => AssetProps(), [matter]);

  useEffect(() => {
    if (isAssetSplitCompleted) {
      navigate(ODRS_PROPERTY_ASSET_DIVISION_ROUTE);
    }

    if (otherPartyIsMakingAnOffer) {
      navigate(ODRS_DIVISION_ASSETS_VIEW_OFFER);
    }
  }, []);

  const assets = transferrableAssets.filter(
    item => item.isJointlyOwned && item.type !== 'property'
  );

  const debts = transferrableDebts.filter(item => item.isJointlyOwned);

  return (
    <DivisionAssetsProvider>
      <PageContainer>
        <Header tab="transfer-joint" />
        <TransferIntro showSplitInfo isRequired>
          <Paragraph large>
            You will need to select what you plan to do with each shared asset.
          </Paragraph>
        </TransferIntro>

        <PageContent>
          {(assets.length > 0 || debts.length > 0) && (
            <TransferAssetsList assets={assets} debts={debts} isRequired />
          )}

          {assets.length === 0 && debts.length === 0 && (
            <Paragraph large>
              There are no jointly owned assets to transfer.
            </Paragraph>
          )}
        </PageContent>
        <TransferFooter
          nextRoute={ODRS_DIVISION_ASSETS_TRANSFER_JOINT_PROPERTY}
          buttonLabel="Next: Joint properties"
          buttonDisabled={!haveAllJointAssetsExceptPropertiesBeenAssigned}
          remainingAmountToTransfer={remainingAmountToTransfer}
          absoluteRemainingAmountToTransfer={absoluteRemainingAmountToTransfer}
          isAssetSplitCompleted={isAssetSplitCompleted}
          isOwingParty={isOwingParty}
          withPropertyDisclaimer
        />
      </PageContainer>
      <DialogViewProposal showDialog={shouldShowViewProposalDialog} />
    </DivisionAssetsProvider>
  );
};

const mapStateToProps = state => ({
  matter: state.matter,
});

DivisionAssetsTransferJoint.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

const mapPropsToDispatch = dispatch => ({
  navigate: to => dispatch(push(to)),
});

export default connect(
  mapStateToProps,
  mapPropsToDispatch
)(DivisionAssetsTransferJoint);
