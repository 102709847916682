import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useSlider from 'Common/Utils/Hooks/useSlider';
import defaultTheme from 'Common/Utils/theme';

import SplitIndicator from './SplitIndicator';
import DiviProps from 'Common/Data/App/diviProperties';

const Knob = styled.div`
  box-sizing: content-box;
  background-color: ${({ theme }) => theme.colours.clickableGrey};
  color: ${({ theme }) => theme.colours.purple};
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  border: 3px solid white;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: left 0.05s linear;
  box-shadow: ${props => props.theme.shadow.medium};
`;

const InteractiveSplitIndicator = ({
  startingAmount,
  override,
  setOverride,
  isInvalid,
  totalAssetPool,
  onUpdate,
  ...rest
}) => {
  const sliderRef = React.useRef(null);
  const [{ value, pos, width }, setState] = useSlider(sliderRef, {
    initialValue: startingAmount,
  });
  const { rounds } = DiviProps();

  useEffect(() => {
    onUpdate(Number(value).toPrecision(2));
    if (override) {
      setState({
        value: override,
        pos: override * width,
        width,
      });
      setTimeout(() => {
        setOverride(false);
      }, 500);
    }
  }, [value, override]);

  return (
    <SplitIndicator
      secondary={startingAmount}
      primary={value}
      displayValue={value}
      secondaryIndicatorColour={
        rounds && rounds.length > 0
          ? defaultTheme.colours.lightAqua
          : defaultTheme.colours.lightOrange
      }
      indicatorColour={
        isInvalid
          ? defaultTheme.colours.midLightGrey
          : defaultTheme.colours.purple
      }
      indicatorTextColour={defaultTheme.colours.purple}
      textColour={defaultTheme.colours.purple}
      baseColour={defaultTheme.colours.offWhite}
      baseStrokeWidth={16}
      totalAssetPool={totalAssetPool}
      ref={sliderRef}
      dataCy="slider-split-indicator"
      {...rest}
    >
      <Knob data-cy="knob-split-indicator" style={{ left: `${pos}px` }}>
        <svg width="48" height="48" viewBox="0 0 48 48">
          <path
            d="M15.5 24.87C14.83 24.48 14.83 23.52 15.5 23.14L18.75 21.26C19.42 20.87 20.25 21.36 20.25 22.12L20.25 25.88C20.25 26.65 19.42 27.13 18.75 26.74L15.5 24.87Z"
            fill="currentColor"
          />
          <path
            d="M32.5 23.13C33.17 23.52 33.17 24.48 32.5 24.86L29.25 26.74C28.58 27.13 27.75 26.64 27.75 25.88L27.75 22.12C27.75 21.35 28.58 20.87 29.25 21.26L32.5 23.13Z"
            fill="#currentColor"
          />
        </svg>
      </Knob>
    </SplitIndicator>
  );
};

InteractiveSplitIndicator.propTypes = {
  ...SplitIndicator.propTypes,
  startingAmount: PropTypes.number.isRequired,
  totalAssetPool: PropTypes.number.isRequired,
  onUpdate: PropTypes.func,
  override: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  setOverride: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
};

InteractiveSplitIndicator.defaultProps = {
  onUpdate: () => {},
  isInvalid: false,
  override: false,
  isInvalid: false,
};

export default InteractiveSplitIndicator;
