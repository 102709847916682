import React from 'react';

import { CustomCardComponentProps } from 'Common/Data/Types/types';
import { ScheduleProvider } from './ScheduleContext';
import SchedulePickerFull from './Components/SchedulePickerFull';
import SchedulePickerViewer from './Components/SchedulePickerViewer';

const SchedulePicker: React.FC<CustomCardComponentProps> = ({
  showSummaryView,
  values = {},
  isActive,
  onInputChange,
  onSubmit,
}) => {
  if (showSummaryView) {
    return (
      <div data-cy="schedule-picker">
        <ScheduleProvider value={values.schedule} isActive={isActive}>
          <SchedulePickerViewer isActive={isActive} />
        </ScheduleProvider>
      </div>
    );
  }

  return (
    <div data-cy="schedule-picker">
      <ScheduleProvider
        onInputChange={onInputChange}
        onSubmit={onSubmit}
        value={values.schedule}
        isActive={isActive}
      >
        <SchedulePickerFull />
      </ScheduleProvider>
    </div>
  );
};

export default SchedulePicker;
