import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import { Callout } from 'Common/UI/Text/Paragraph';
import {
  SlideInner,
  SlideText,
  SlideIcon,
} from 'Common/UI/Carousel/ImageSlideWrapper';
import SlideImage01 from './content-order-image-1.svg';

const ConsentOrdersSlide1 = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={SlideImage01} srcSet={`${SlideImage01} 2x`} />
    </SlideIcon>
    <SlideText data-cy="content-order-slide-1">
      <H2Conversational>
        You have chosen to create an application for consent orders
      </H2Conversational>
      <Callout>
        You can use amica to create an application for consent orders in
        relation to financial matters (money and property).
        <br />
        Note: amica does not create applications for consent orders in relation
        to children’s matters (parenting issues).
      </Callout>
    </SlideText>
  </SlideInner>
);

export default ConsentOrdersSlide1;
