import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import DashboardConsentOrders from 'App/Pages/DashboardConsentOrders';

import {
  PAGE_CONSENT_ORDERS_GET_STARTED,
  PAGE_CONSENT_ORDERS_RELATIONSHIP,
  PAGE_CONSENT_ORDERS_ASSETS,
  PAGE_CONSENT_ORDERS_FINANCES,
  PAGE_CONSENT_ORDERS_COMPLETED,
} from 'Common/constants';
import {
  ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE,
  ODRS_CONSENT_ORDERS_RELATIONSHIP_ROUTE,
  ODRS_CONSENT_ORDERS_ASSET_ROUTE,
  ODRS_CONSENT_ORDERS_FINANCES_ROUTE,
  ODRS_CONSENT_ORDERS_COMPLETE_ROUTE,
  ODRS_CONSENT_ORDERS_INTRO_SLIDER_ROUTE,
} from 'Common/routes';
import DiviRoute from 'Common/UI/DiviRoute';
import { darkGreenTheme, blueGreyMobileTheme } from 'Common/Utils/theme';
import ConsentOrdersSlider from 'App/UI/Dashboard/ConsentOrders/ConsentOrderSlider';
import MatterGuard from '../Guard/MatterGuard';

const ODRSDashboardConsentOrder = () => (
  <MatterGuard>
    <Switch>
      <DiviRoute
        path={ODRS_CONSENT_ORDERS_INTRO_SLIDER_ROUTE}
        component={ConsentOrdersSlider}
        theme={blueGreyMobileTheme}
      />
      <DiviRoute
        path={ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE}
        component={DashboardConsentOrders}
        tab={PAGE_CONSENT_ORDERS_GET_STARTED}
        theme={darkGreenTheme}
        noTransition
        exact
      />
      <DiviRoute
        path={ODRS_CONSENT_ORDERS_RELATIONSHIP_ROUTE}
        component={DashboardConsentOrders}
        tab={PAGE_CONSENT_ORDERS_RELATIONSHIP}
        theme={darkGreenTheme}
        noTransition
        exact
      />
      <DiviRoute
        path={ODRS_CONSENT_ORDERS_ASSET_ROUTE}
        component={DashboardConsentOrders}
        tab={PAGE_CONSENT_ORDERS_ASSETS}
        theme={darkGreenTheme}
        noTransition
      />
      <DiviRoute
        path={ODRS_CONSENT_ORDERS_FINANCES_ROUTE}
        component={DashboardConsentOrders}
        tab={PAGE_CONSENT_ORDERS_FINANCES}
        theme={darkGreenTheme}
        noTransition
        exact
      />
      <DiviRoute
        path={ODRS_CONSENT_ORDERS_COMPLETE_ROUTE}
        component={DashboardConsentOrders}
        tab={PAGE_CONSENT_ORDERS_COMPLETED}
        theme={darkGreenTheme}
        noTransition
        exact
      />
      <Redirect to={ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE} />
    </Switch>
  </MatterGuard>
);
export default ODRSDashboardConsentOrder;
