import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import { ProgressBarContext } from 'Common/Utils/ProgressBarContext';
import Appears from 'Common/UI/Layout/Appears';
import Loader from 'Common/UI/Loader/Loader';
import {
  H1Conversational,
  HeadingSpacedContainer,
} from 'Common/UI/Text/Headings';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import RoundButton from 'Common/UI/Button/RoundButton';
import ValidationMessage from 'Common/UI/Form/ValidationMessage';
import CheckboxField from 'Common/UI/Form/CheckboxField';
import {
  InnerContentMiddle,
  StyledTextfield,
  ButtonsContainerInner,
  StyledTextButton,
} from 'Common/UI/Signup/Layout';
import sanitiseMobile from 'Common/Utils/sanitiseMobile';

import {
  verifyUserAction,
  authenticateUserAction,
  enableMFAForUserAction,
  rememberDeviceForUserAction,
  resendVerificationAction,
} from 'App/State/UserActions';

import { createMatterAction, joinMatterAction } from 'App/State/MatterActions';

const StyledCallout = styled(Callout)`
  line-height: 160%;
  margin-left: ${({ theme }) => theme.padding.xxxsmall}px;
`;

const VerifyCodeStep = ({
  loadingVerification,
  parentState,
  setParentState,
  onChange,
  verificationError,
  createMatter,
  joinMatter,
  verifyUser,
  resendVerification,
  authenticateUser,
  enableMFAForUser,
  rememberDeviceForUser,
  goNext,
  isFirstParty,
  startingStepNumber,
}) => {
  const questionsInput =
    parentState.signupQuestionsInput || parentState.questionsInput;

  const context = useContext(ProgressBarContext);
  const { updateStep } = context;

  useEffect(() => {
    updateStep(startingStepNumber);
  }, []);

  return (
    <InnerContentMiddle className="step-transition-fade-up">
      <ResponsiveTextContainer>
        {loadingVerification && <Loader floating />}

        <br />
        <H1Conversational>
          Thanks, we’ve sent a verification code to your mobile phone.
        </H1Conversational>
        <Paragraph>
          We sent a verification code to{' '}
          <strong>{sanitiseMobile(questionsInput.phone_number)}</strong>. Check
          your phone and then enter the verification code below.
        </Paragraph>

        <StyledTextfield
          value={questionsInput.verification}
          name="verification"
          label="Code"
          placeholder="000000"
          onChange={value => onChange('verification', value)}
          data-cy="verify-code-input"
        />

        <Appears on={verificationError}>
          <ValidationMessage>
            The verification code did not match.
          </ValidationMessage>
        </Appears>

        <HeadingSpacedContainer marginBottom="24px">
          <CheckboxField
            value={questionsInput.remember}
            name="remember"
            label={
              <StyledCallout>
                Remember this device (we won&apos;t send you a verification code
                the next time you login.)
              </StyledCallout>
            }
            onChange={updateValue => onChange('remember', updateValue)}
          />
        </HeadingSpacedContainer>

        <ButtonsContainerInner>
          <RoundButton
            bumpDown={!!verificationError}
            disabled={!questionsInput.verification}
            className="step-transition-fade-down"
            onClick={() => {
              updateStep(startingStepNumber + 1);
              setParentState({ loadingVerification: true });
              verifyUser(questionsInput)
                .then(() => authenticateUser(questionsInput))
                .then(() => enableMFAForUser())
                .then(() =>
                  questionsInput.remember
                    ? rememberDeviceForUser()
                    : Promise.resolve()
                )
                .then(() => {
                  if (isFirstParty) {
                    return createMatter(questionsInput);
                  }
                  return joinMatter({
                    data: questionsInput,
                    InviteID: questionsInput.InviteID,
                  });
                })
                .then(() => {
                  setParentState({
                    loadingVerification: false,
                    verificationError: null,
                  });
                  goNext();
                })
                .catch(error => {
                  if (
                    error &&
                    error.code &&
                    error.code === 'CodeMismatchException'
                  ) {
                    setParentState({
                      loadingVerification: false,
                      verificationError: error,
                    });
                  } else {
                    throw error;
                  }
                });
            }}
          >
            Verify code
          </RoundButton>
          <StyledTextButton
            type="button"
            onClick={() => {
              setParentState({ verificationError: null });
              resendVerification();
            }}
          >
            Resend code
          </StyledTextButton>
        </ButtonsContainerInner>
      </ResponsiveTextContainer>
    </InnerContentMiddle>
  );
};

VerifyCodeStep.propTypes = {
  onChange: PropTypes.func.isRequired,
  setParentState: PropTypes.func.isRequired,
  verificationError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  loadingVerification: PropTypes.bool,
  parentState: PropTypes.shape({
    signupQuestionsInput: PropTypes.shape({
      phone_number: PropTypes.string,
      verification: PropTypes.string,
      remember: PropTypes.bool,
    }),
  }).isRequired,
  createMatter: PropTypes.func.isRequired,
  joinMatter: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
  resendVerification: PropTypes.func.isRequired,
  authenticateUser: PropTypes.func.isRequired,
  enableMFAForUser: PropTypes.func.isRequired,
  rememberDeviceForUser: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  isFirstParty: PropTypes.bool.isRequired,
  startingStepNumber: PropTypes.number,
};

VerifyCodeStep.defaultProps = {
  verificationError: null,
  loadingVerification: false,
  startingStepNumber: 0,
};

const mapDispatchToProps = dispatch => ({
  createMatter: userProps => dispatch(createMatterAction(userProps)),
  joinMatter: data => dispatch(joinMatterAction(data)),
  verifyUser: userProps => dispatch(verifyUserAction(userProps)).unwrap(),
  resendVerification: userProps =>
    dispatch(resendVerificationAction(userProps)),
  authenticateUser: userProps => dispatch(authenticateUserAction(userProps)),
  enableMFAForUser: () => dispatch(enableMFAForUserAction()),
  rememberDeviceForUser: () => dispatch(rememberDeviceForUserAction()),
});

export default connect(null, mapDispatchToProps)(VerifyCodeStep);
