/**
 * THIS IS A VERSIONING FILE - see readme for more info
 */

import getVersionedFunction from 'Common/Utils/getVersionedFunction';
import { CardAny } from '../Types/appSections';
import * as v20200101 from './20200101/Cards/VERSIONED_appSections';
import * as v20211123 from './20211123/Cards/VERSIONED_appSections';
import * as v20221124 from './20221124/Cards/VERSIONED_appSections';
import * as v20230101 from './20230101/Cards/VERSIONED_appSections';
import * as v20230215 from './20230215/Cards/VERSIONED_appSections';

const appSectionsVersionGroups = {
  v20200101,
  v20211123,
  v20221124,
  v20230101,
  v20230215,
};

export const getAppSections = (
  ...args: Parameters<
    typeof appSectionsVersionGroups.v20200101['getAppSections']
  >
) =>
  getVersionedFunction<
    typeof appSectionsVersionGroups.v20200101['getAppSections']
  >(
    appSectionsVersionGroups,
    'getAppSections'
  )(...args);

export const getSection = (
  ...args: Parameters<typeof appSectionsVersionGroups.v20200101['getSection']>
) =>
  getVersionedFunction<typeof appSectionsVersionGroups.v20200101['getSection']>(
    appSectionsVersionGroups,
    'getSection'
  )(...args);

export const getPage = (
  ...args: Parameters<typeof appSectionsVersionGroups.v20200101['getPage']>
) =>
  getVersionedFunction<typeof appSectionsVersionGroups.v20200101['getPage']>(
    appSectionsVersionGroups,
    'getPage'
  )(...args);

export const getCard = (
  ...args: Parameters<typeof appSectionsVersionGroups.v20200101['getCard']>
) =>
  getVersionedFunction<typeof appSectionsVersionGroups.v20200101['getCard']>(
    appSectionsVersionGroups,
    'getCard'
  )(...args);

export const definitelyGetCard = <T extends CardAny>(
  ...args: Parameters<
    typeof appSectionsVersionGroups.v20200101['definitelyGetCard']
  >
) =>
  getVersionedFunction<
    typeof appSectionsVersionGroups.v20200101['definitelyGetCard']
  >(
    appSectionsVersionGroups,
    'definitelyGetCard'
  )<T>(...args);

export const getGroupContainingCard = (
  ...args: Parameters<
    typeof appSectionsVersionGroups.v20200101['getGroupContainingCard']
  >
) =>
  getVersionedFunction<
    typeof appSectionsVersionGroups.v20200101['getGroupContainingCard']
  >(
    appSectionsVersionGroups,
    'getGroupContainingCard'
  )(...args);

export const getCardDashboardRoute = (
  ...args: Parameters<
    typeof appSectionsVersionGroups.v20200101['getCardDashboardRoute']
  >
) =>
  getVersionedFunction<
    typeof appSectionsVersionGroups.v20200101['getCardDashboardRoute']
  >(
    appSectionsVersionGroups,
    'getCardDashboardRoute'
  )(...args);

export const getCardQuestionFlowMetadata = (
  ...args: Parameters<
    typeof appSectionsVersionGroups.v20200101['getCardQuestionFlowMetadata']
  >
) =>
  getVersionedFunction<
    typeof appSectionsVersionGroups.v20200101['getCardQuestionFlowMetadata']
  >(
    appSectionsVersionGroups,
    'getCardQuestionFlowMetadata'
  )(...args);

export const isConsentOrdersQuestionLocation = (
  ...args: Parameters<
    typeof appSectionsVersionGroups.v20200101['isConsentOrdersQuestionLocation']
  >
) =>
  getVersionedFunction<
    typeof appSectionsVersionGroups.v20200101['isConsentOrdersQuestionLocation']
  >(
    appSectionsVersionGroups,
    'isConsentOrdersQuestionLocation'
  )(...args);

export const getPageAndSectionIdsContainingCard = (
  ...args: Parameters<
    typeof appSectionsVersionGroups.v20230101['getPageAndSectionIdsContainingCard']
  >
) =>
  getVersionedFunction<
    typeof appSectionsVersionGroups.v20230101['getPageAndSectionIdsContainingCard']
  >(
    appSectionsVersionGroups,
    'getPageAndSectionIdsContainingCard'
  )(...args);
