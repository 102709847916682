import MatterPropsJS from 'Common/Utils/MatterProps';

const guessFatherMother = () => {
  const matterItems = MatterPropsJS('items');
  const aboutYou =
    matterItems.filter(item => item.BaseSection.includes('aboutyoucontinue')) ||
    [];
  const male = aboutYou.filter(item => item.gender === 'Male') || [];
  const female = aboutYou.filter(item => item.gender === 'Female') || [];
  if (male.length === 1 && female.length === 1) {
    return { fatherParty: male[0].CardIndex, motherParty: female[0].CardIndex };
  }
  return false;
};

export default guessFatherMother;
