/**
 * THIS IS A VERSIONING FILE - see readme for more info
 */

import getVersionedFunction from 'Common/Utils/getVersionedFunction';
import * as v20200101 from './20200101/VERSIONED_DivisionAssetsTransferSelf';
import * as v20211123 from './20211123/VERSIONED_DivisionAssetsTransferSelf';
import * as v20221124 from './20221124/VERSIONED_DivisionAssetsTransferSelf';
import * as v20230101 from './20230101/VERSIONED_DivisionAssetsTransferSelf';
import * as v20230215 from './20230215/VERSIONED_DivisionAssetsTransferSelf';

const divisionAssetsTransferSelfVersionGroups = {
  v20200101,
  v20211123,
  v20221124,
  v20230101,
  v20230215,
};

const DivisionAssetsTransferSelf = (...args) =>
  getVersionedFunction(
    divisionAssetsTransferSelfVersionGroups,
    'default'
  )(...args);

export default DivisionAssetsTransferSelf;
