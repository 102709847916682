import {
  BANNER_HIDE,
  BANNER_SHOW,
  SECTION_PROPERTY,
  SUGGESTED_DIVISION_AGREED,
  SUGGESTED_DIVISION_LOCKED,
  SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER,
  SUGGESTED_DIVISION_OTHER_MADE_OFFER,
  SUGGESTED_DIVISION_READY,
  SUGGESTED_DIVISION_START,
  SUGGESTED_DIVISION_WAITING,
  SUGGESTED_DIVISION_YOU_MADE_OFFER,
  DOCUMENT_TYPES_COMPLETED,
  ASSET_SPLIT_OTHER_PARTY_COMPLETING,
  SUGGESTED_DIVISION_CANT_CONTINUE,
  ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER,
  ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER,
  ASSET_SPLIT_OFFER_SENT,
  DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
  DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED,
  DOC_TYPE_CONSENT_ORDERS,
  DOC_TYPE_PROPERTY_AGREEMENT,
  PAGE_PROPERTY_ASSET_DIVISION,
} from 'Common/constants';
import { isPaymentDue } from 'Common/Utils/Payments';
import { areSettlementAssetsAllAccountedFor } from 'App/Utils/matterHelpers';
import AssetProps from 'Common/Data/App/assetProperties';
import DiviProps from 'Common/Data/App/diviProperties';
import { sessionStorageLoad } from 'Common/Utils/sessionStorage';
import { propertyDashboardCompletedFlags } from 'Common/UI/Banners/PropertyDashboardCompleted';
import {
  propertyAgreementNeedsConfirmationFlags,
  consentOrderNeedsConfirmationFlags,
} from 'Common/UI/Banners/DocumentTypesNeedsConfirmation';
import { documentTypesOtherNotReadyFlags } from 'Common/UI/Banners/DocumentTypesOtherNotReady';
import { documentTypesWaitingForOtherFlags } from 'Common/UI/Banners/DocumentTypesWaitingForOther';
import { assetSplitSelfOfferInProgressFlags } from 'Common/UI/Banners/AssetSplitSelfOfferInProgress';
import { dashboardTabLockedFlags } from 'Common/UI/Banners/DashboardTabLocked';
import { relationshipDashboardNotCompletedFlags } from 'Common/UI/Banners/RelationshipDashboardNotCompleted';
import Matter from 'Common/Data/Types/matter';
import { AppState, SectionState } from 'Common/Data/Types/appState';
import {
  resetSuggestedDivisionWaitingForOtherFlags,
  resetSuggestedDivisionOtherNotReadyFlags,
  resetSuggestedDivisionWaitingForSelfFlags,
} from 'Common/UI/Banners/ResetSuggestedDivision';
import { areDocumentsAllCompleted } from 'Common/Utils/documentHelpers';
import { superannuationBalanceRequestCancelledRequesteeFlags } from 'Common/UI/Banners/SuperannuationBalanceRequestCancelledRequestee';
import { superannuationRequestBalanceStartedRequesterFlags } from 'Common/UI/Banners/SuperannuationRequestBalanceStartedRequester';
import { superannuationRequestBalanceUploadLetterRequesterFlags } from 'Common/UI/Banners/SuperannuationRequestBalanceUploadLetterRequester';
import { superannuationBalanceRequestCompletedBothPartiesFlags } from 'Common/UI/Banners/SuperannuationBalanceRequestCompletedBothParties';
import { superannuationBalanceRequestInProgressRequesteeFlags } from 'Common/UI/Banners/SuperannuationBalanceRequestInProgressRequestee';
import { superSplittingRemoveSuperannuationWaitingForSelfFlags } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationWaitingForSelf';
import { superSplittingRemoveSuperannuationWaitingForOtherFlags } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationWaitingForOther';
import { superSplittingRemoveSuperannuationApprovedRequesterFlags } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationApprovedRequester';
import { superSplittingRemoveSuperannuationApprovedRequesteeFlags } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationApprovedRequestee';
import { superSplittingRemoveSuperannuationRejectedRequesterFlags } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationRejectedRequester';
import { superSplittingRemoveSuperannuationRejectedRequesteeFlags } from 'Common/UI/Banners/SuperSplitting/RemoveSuperannuationRejectedRequestee';

const docTypeStates: AppState[] = [
  {
    id: 'docTypesConsentOrderPaymentRequired',
    description: 'Payment required for consent order',
    check: (matter: Matter) => isPaymentDue(matter, DOC_TYPE_CONSENT_ORDERS),
    flags: {
      appState: DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
      bannerTopState: () =>
        sessionStorageLoad(
          `${DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED}Dismissed`
        )
          ? BANNER_HIDE
          : BANNER_SHOW,
    },
    usedAsNotificationState: true,
  },
  documentTypesOtherNotReadyFlags,
  documentTypesWaitingForOtherFlags,
  propertyAgreementNeedsConfirmationFlags,
  consentOrderNeedsConfirmationFlags,
  {
    id: 'docTypesPropertyAgreementPaymentRequired',
    description: 'Payment required for property agreement',
    check: matter => isPaymentDue(matter, DOC_TYPE_PROPERTY_AGREEMENT),
    flags: {
      appState: DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED,
      bannerTopState: () =>
        sessionStorageLoad(
          `${DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED}Dismissed`
        )
          ? BANNER_HIDE
          : BANNER_SHOW,
    },
    usedAsNotificationState: true,
  },
  {
    id: 'docTypesCompleted',
    description: 'You both have completed the document types',
    check: () => areDocumentsAllCompleted(),
    flags: {
      bannerTopState: BANNER_HIDE,
      appState: DOCUMENT_TYPES_COMPLETED,
    },
  },
];

const assetSplitStates: AppState[] = [
  {
    id: 'settlementCantContinue',
    description: 'TAP is negative',
    check: () => {
      const { isTAPNegative } = AssetProps();
      return areSettlementAssetsAllAccountedFor() && isTAPNegative;
    },
    flags: {
      appState: SUGGESTED_DIVISION_CANT_CONTINUE,
      bannerTopState: () => BANNER_SHOW,
    },
  },
  propertyDashboardCompletedFlags,
  assetSplitSelfOfferInProgressFlags,
  {
    id: 'settlementOtherPartyCompletingAssetSplit',
    description: 'Other party is currently completing Asset Split',
    check: () => {
      const { otherPartyIsMakingAnOffer, isAssetSplitCompleted } = AssetProps();
      return !isAssetSplitCompleted && otherPartyIsMakingAnOffer;
    },
    flags: {
      appState: ASSET_SPLIT_OTHER_PARTY_COMPLETING,
      bannerTopState: () => {
        const { numberOfOffers } = AssetProps();
        return sessionStorageLoad(
          `AssetSplitOfferOtherPartyCompleting-${numberOfOffers}`
        )
          ? BANNER_HIDE
          : BANNER_SHOW;
      },
    },
  },
  {
    id: 'settlementOtherHasMadeAssetSplitInitialOffer',
    description: 'Other party has made an initial offer',
    check: () => {
      const { isExactlyOneOfferAndMadeByOtherParty, isAssetSplitCompleted } =
        AssetProps();
      return !isAssetSplitCompleted && isExactlyOneOfferAndMadeByOtherParty;
    },
    flags: {
      appState: ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER,
    },
    usedAsNotificationState: true,
  },
  {
    id: 'settlementOtherHasMadeAssetSplitCounterOffer',
    description: 'Other party has made a counter offer',
    check: () => {
      const { hasOtherPartyMadeCounterOffer, isAssetSplitCompleted } =
        AssetProps();
      return !isAssetSplitCompleted && hasOtherPartyMadeCounterOffer;
    },
    flags: {
      appState: ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER,
    },
    usedAsNotificationState: true,
  },
  {
    id: 'settlementAssetSplitWaitingForOtherToViewOffer',
    description:
      'Offer has been sent to the other party and is waiting to be viewed',
    check: () => {
      const { isWaitingForOtherPartyToViewOffer, isAssetSplitCompleted } =
        AssetProps();
      return !isAssetSplitCompleted && isWaitingForOtherPartyToViewOffer;
    },
    flags: {
      appState: ASSET_SPLIT_OFFER_SENT,
      bannerTopState: () => {
        const { numberOfOffers } = AssetProps();
        return sessionStorageLoad(
          `AssetSplitOfferSentToOtherDismissed-${numberOfOffers}`
        )
          ? BANNER_HIDE
          : BANNER_SHOW;
      },
    },
  },
  {
    id: 'settlementAgreedToSuggestedDivision',
    description: 'Have agreed to Suggested Division',
    check: () => {
      const { agreedToDivision } = DiviProps();
      const { hasAnyOfferBeenStarted } = AssetProps();
      return agreedToDivision && !hasAnyOfferBeenStarted;
    },
    flags: {
      appState: SUGGESTED_DIVISION_AGREED,
    },
    usedAsNotificationState: true,
  },
];

const suggestedDivisionStates: AppState[] = [
  {
    id: 'settlementLockedSuggestedDivision',
    description: 'Suggested Division is still locked',
    check: () => !areSettlementAssetsAllAccountedFor(),
    flags: {
      appState: SUGGESTED_DIVISION_LOCKED,
      bannerTopState: BANNER_HIDE,
    },
  },
  {
    id: 'settlementYouHaveNotConfirmed',
    description: 'You have not confirmed to start',
    check: ({ self }: Matter) =>
      areSettlementAssetsAllAccountedFor() && !self.hasConfirmedStatements,
    flags: {
      appState: SUGGESTED_DIVISION_START,
      bannerTopState: BANNER_HIDE,
    },
    usedAsNotificationState: true,
  },
  {
    id: 'settlementOtherHasNotConfirmed',
    description: 'Other Party has not confirmed to start',
    check: ({ other }: Matter) => !other.hasConfirmedStatements,
    flags: {
      appState: SUGGESTED_DIVISION_WAITING,
    },
  },
  {
    id: 'settlementNoOffersHaveBeenMade',
    description: 'No Offers Have Been Made',
    check: () => {
      const {
        agreedToDivision,
        lastOfferBy,
        bothPartiesReadyForSuggestedDivision,
      } = DiviProps();
      return (
        bothPartiesReadyForSuggestedDivision &&
        !agreedToDivision &&
        lastOfferBy('none')
      );
    },
    flags: {
      appState: SUGGESTED_DIVISION_READY,
    },
    usedAsNotificationState: true,
  },
  {
    id: 'settlementYouMadeAnOffer',
    description: 'You made an offer and waiting',
    check: () => {
      const { agreedToDivision, lastOfferBy } = DiviProps();
      return !agreedToDivision && lastOfferBy('self');
    },
    flags: {
      appState: SUGGESTED_DIVISION_YOU_MADE_OFFER,
    },
  },
  {
    id: 'settlementOtherMadeAnOffer',
    description: 'Other made an offer and waiting',
    check: () => {
      const { agreedToDivision, lastOfferBy, hasMadeOffer } = DiviProps();
      return !agreedToDivision && lastOfferBy('other') && !hasMadeOffer('self');
    },
    flags: {
      appState: SUGGESTED_DIVISION_OTHER_MADE_OFFER,
    },
    usedAsNotificationState: true,
  },
  {
    id: 'settlementBothMadeAnOffers',
    description: 'You made an offer and waiting',
    check: () => {
      const { agreedToDivision, lastOfferBy, hasMadeOffer } = DiviProps();
      return !agreedToDivision && lastOfferBy('other') && hasMadeOffer('self');
    },
    flags: {
      appState: SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER,
    },
    usedAsNotificationState: true,
  },
];

const data: SectionState = {
  [SECTION_PROPERTY]: {
    pageBlock: [relationshipDashboardNotCompletedFlags],
    state: [
      superSplittingRemoveSuperannuationApprovedRequesterFlags, // the remove superannuation flags needs to be above `dashboardTabLockedFlags` so that the banners are still shown on a locked page
      superSplittingRemoveSuperannuationApprovedRequesteeFlags,
      superSplittingRemoveSuperannuationWaitingForSelfFlags,
      superSplittingRemoveSuperannuationRejectedRequesteeFlags,
      superSplittingRemoveSuperannuationRejectedRequesterFlags,
      superSplittingRemoveSuperannuationWaitingForOtherFlags,
      dashboardTabLockedFlags,
      resetSuggestedDivisionWaitingForOtherFlags,
      resetSuggestedDivisionOtherNotReadyFlags,
      resetSuggestedDivisionWaitingForSelfFlags,
      ...docTypeStates,
      ...assetSplitStates,
      superannuationBalanceRequestCompletedBothPartiesFlags,
      superannuationBalanceRequestInProgressRequesteeFlags,
      superannuationRequestBalanceUploadLetterRequesterFlags,
      superannuationRequestBalanceStartedRequesterFlags,
      superannuationBalanceRequestCancelledRequesteeFlags,
      ...suggestedDivisionStates,
    ],
  },
  [PAGE_PROPERTY_ASSET_DIVISION]: {
    pageBlock: [],
    state: [
      {
        id: 'settlementYouHaveNotConfirmed',
        description: 'You have not confirmed to start',
        check: ({ self }: Matter) =>
          areSettlementAssetsAllAccountedFor() && !self.hasConfirmedStatements,
        flags: {
          appState: SUGGESTED_DIVISION_START,
        },
        usedAsNotificationState: true,
      },
    ],
  },
};

export default data;
