import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import { ODRS_INVITE_PARTNER_ROUTE } from 'Common/routes';
import { isPartyBInvited } from 'App/Utils/matterHelpers';
import MatterProps from 'Common/Utils/MatterProps';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import Card from 'Common/UI/Card';
import AddPersonIcon from 'Common/Assets/images/icon-person-add.svg';
import { resendInvitePartnerAction } from 'App/State/UserActions';

const StyledFormerPartnerWrap = styled.span`
  margin-left: 24px;
  position: relative;
  width: 20px;
  height: 16px;
  display: inline-block;
`;

const StyledFormerPartnerIcon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

// this is the maximum number of invites that a user can receive (including the initial one)
// note: if you change this, make sure you change the same variable in the API too
const maxInvitesAllowed = 2;

const InviteOtherParty = ({ resendInvitePartner, matter, navigate }) => {
  let content;

  const hasPartyBReceivedReminder =
    matter.partyB && matter.partyB.sentCount > 1;

  const canResendInvitationReminder =
    matter.partyB &&
    matter.partyB.sentCount < maxInvitesAllowed &&
    matter.partyB.sentDates &&
    moment().diff(
      moment(matter.partyB.sentDates[matter.partyB.sentDates.length - 1]),
      'days'
    ) > 7;

  if (canResendInvitationReminder) {
    content = (
      <Card cardTheme="inviteSent">
        <Card.MainHeading>
          {MatterProps('other.firstname')} still has not accepted your
          invitation to sign up to amica
        </Card.MainHeading>
        <Card.Paragraph>
          Would you like to send {MatterProps('other.firstname')} a reminder to
          sign up. You will only be able to do this once.
        </Card.Paragraph>
        <Card.Button onClick={() => resendInvitePartner()}>
          Resend invite
        </Card.Button>
      </Card>
    );
  } else if (hasPartyBReceivedReminder) {
    content = (
      <Card cardTheme="inviteSent">
        <Card.MainHeading>
          We have sent {MatterProps('other.firstname')} a reminder to sign up
        </Card.MainHeading>
        <Card.Paragraph>
          We have sent another email to{' '}
          <strong>{MatterProps('other.email')}</strong>. We will let you know
          when {MatterProps('other.firstname')} has signed up.
        </Card.Paragraph>
      </Card>
    );
  } else if (isPartyBInvited(matter)) {
    content = (
      <Card cardTheme="inviteSent">
        <Card.MainHeading>
          Your invite has been sent to {MatterProps('other.firstname')}
        </Card.MainHeading>
        <Card.Paragraph>
          We have sent an email to <strong>{MatterProps('other.email')}</strong>
          . We will let you know when {MatterProps('other.firstname')} has
          signed up.
        </Card.Paragraph>
      </Card>
    );
  } else {
    content = (
      <Card cardTheme="inviteInitial">
        <Card.MainHeading>Invite your former partner</Card.MainHeading>
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          You will need to invite your former partner to start creating your
          agreement document.
        </Card.Paragraph>
        <Card.Button subtle onClick={() => navigate(ODRS_INVITE_PARTNER_ROUTE)}>
          Invite your former partner
          <StyledFormerPartnerWrap>
            <StyledFormerPartnerIcon
              src={AddPersonIcon}
              alt="invite former partner"
            />
          </StyledFormerPartnerWrap>
        </Card.Button>
      </Card>
    );
  }

  return (
    <SpacedBox marginTop="medium2" data-cy="invite-former-partner-card">
      {content}
    </SpacedBox>
  );
};

InviteOtherParty.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  resendInvitePartner: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  resendInvitePartner: () => dispatch(resendInvitePartnerAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteOtherParty);
