import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Outer = styled.div`
  flex: 1;
  overflow: auto;
`;

const Inner = styled.div`
  // height: 100%;
`;

const FlexScroller = ({ children, className }) => (
  <Outer>
    <Inner className={className}>{children}</Inner>
  </Outer>
);

FlexScroller.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FlexScroller.defaultProps = {
  className: '',
};

export default FlexScroller;
