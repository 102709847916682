import { CARD_STATEMENT } from 'Common/constants';
import { getCard } from 'Common/Data/App/appSections';

const isCardTypeStatement = cardId => {
  const card = getCard(cardId);

  return card.type === CARD_STATEMENT;
};

export default isCardTypeStatement;
