import React from 'react';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';

import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import { SubHeadingBold } from 'Common/UI/Text/Headings';
import OptionsDrawerPortal from 'Common/UI/OptionsDrawer/OptionsDrawerPortal';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import GenericButton from 'Common/UI/Button/GenericButton';
import { TwoButtonRow } from 'Common/UI/Button/ButtonGroup';
import { useAppDispatch } from 'App/State/Store';
import { hideDialog } from 'App/State/AppReducer';
import { setPartyFlagsAction } from 'App/State/MatterActions';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import push from 'Common/Utils/push';
import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${props => props.theme.padding.medium}px;
  margin-top: ${props => props.theme.padding.xxsmall}px;
`;

const StyledSubHeading = styled(SubHeadingBold)`
  margin-bottom: ${props => props.theme.padding.xxsmall}px;
`;

const DialogRestartSuggestedDivision: React.FC = () => {
  const dispatch = useAppDispatch();
  const { withLoading } = usePromiseWithLoading();

  const closeDrawer = () => dispatch(hideDialog());
  const restart = () =>
    withLoading(async () => {
      await dispatch(
        setPartyFlagsAction({ readyToResetSuggestedDivision: true })
      );
      closeDrawer();
      dispatch(push(ODRS_PROPERTY_ASSET_DIVISION_ROUTE));
    });

  return (
    <OptionsDrawerPortal isOpen onBackgroundClick={closeDrawer}>
      <FlexDrawer isVisible>
        <ResponsiveTextContainer>
          <StyledSubHeading>
            Confirming that you want to restart the asset division section?
          </StyledSubHeading>
          <StyledParagraph>
            If you both agree to restart the asset division section, you’ll be
            able to update your information and renegotiate how you divide your
            money and property
          </StyledParagraph>
          <TwoButtonRow>
            <GenericButton reverse onClick={closeDrawer} small>
              Cancel
            </GenericButton>
            <GenericButton onClick={restart} small>
              Restart
            </GenericButton>
          </TwoButtonRow>
        </ResponsiveTextContainer>
      </FlexDrawer>
    </OptionsDrawerPortal>
  );
};

export default DialogRestartSuggestedDivision;
