import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import {
  H2Conversational,
  Heading2,
  SubHeadingHelp,
} from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { UList } from 'Common/UI/Text/List';
import { orangeTheme } from 'Common/Utils/theme';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';

const Wrap = styled.div`
  background-color: ${({ theme }) => theme.backgroundColour};
  margin-bottom: ${({ theme }) => theme.padding.xlarge}px;
  padding: ${({ theme }) =>
    `${theme.padding.medium}px ${theme.padding.small}px`};
  border-radius: 4px;
`;

const ThingsToKnowContent = (
  <>
    <Heading2>What&rsquo;s a &lsquo;parenting agreement&rsquo;?</Heading2>
    <Paragraph>
      Parenting Agreements are informal agreements between you and your former
      partner about the care arrangements for your children after you have
      separated. They can include whatever your family and your children need,
      such as time the children spend with each parent, what schools they will
      attend and how you are going to go about making the long term decisions
      about the children. They always need to be made in the best interests of
      the child.
    </Paragraph>
    <Paragraph>
      A clear Parenting Agreement helps everyone know what is happening and it
      acts as a written reminder of the arrangements you have reached so that
      you both stick to it. amica can help you agree on these essentials and
      also work out a schedule for your parenting arrangements. (A Parenting
      Agreement does not work out how to divide the property of the
      relationship.)
    </Paragraph>

    <SubHeadingHelp>
      What are the &lsquo;best interests&rsquo; of a child?
    </SubHeadingHelp>
    <Paragraph>
      In deciding the best interests of a child, the primary consideration of
      the family law courts are:
    </Paragraph>
    <UList>
      <li>
        benefit to children of meaningful relationships with both parents, and
      </li>
      <li>
        the need to protect children from physical or psychological harm and
        from being subjected or exposed to abuse, neglect or family violence
      </li>
    </UList>
    <Paragraph>
      In applying these two considerations, the court must give greater weight
      to the need to protect the child from such harm. In addition, it will also
      consider factors such as:
    </Paragraph>
    <UList>
      <li>
        the views of the child, depending on the maturity and level of
        understanding of the child
      </li>
      <li>
        the child’s relationship with each parent and other people, including
        grandparents and other relatives
      </li>
      <li>
        the willingness and ability of each parent to facilitate and encourage a
        close and continuing relationship between the child and the other parent
      </li>
      <li>
        the likely effect on the child of changed circumstances, including
        separation from the parent or person/s they currently live with
      </li>
    </UList>

    <SubHeadingHelp>What could a Parenting Agreement look like?</SubHeadingHelp>
    <Paragraph>
      Every family is different, and you and your former partner will know what
      is best for you and your family. What some families do may not work for
      your family. Each family differs in the way they enjoy spending time
      together. For example if the children have primarily been cared for by one
      parent whilst the other parent has been working, the children may be used
      that lifestyle. You should think how your family has worked best in the
      past when you look to work out your Parenting Agreement.
    </Paragraph>

    <SubHeadingHelp>What comes next?</SubHeadingHelp>
    <Paragraph>
      You can just make a parenting agreement and leave it at that, if that
      works best for your family. Or you can take further steps to add weight or
      make it more formal. If you sign and date your parenting agreement it
      becomes know as a ‘parenting plan’, and has more weight. If you want to go
      a step further to formalise your parenting agreement or parenting plan,
      you can seek consent orders - this is where you ask the court to formalise
      what you have agreed into court orders.
    </Paragraph>
  </>
);

const ThingsToKnow = () => (
  <ThemeProvider theme={orangeTheme}>
    <Wrap>
      <H2Conversational>Things to know</H2Conversational>
      <Paragraph>
        Take a moment to learn about the purpose of parenting arrangements, and
        how you can come to an agreement in the best interest of your child.
      </Paragraph>
      <LearnMoreButton
        theme={orangeTheme}
        text="Learn more about the process"
        popUpContent={ThingsToKnowContent}
        data-cy="things-to-know-start-button"
      />
    </Wrap>
  </ThemeProvider>
);

export default ThingsToKnow;
