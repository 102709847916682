import React from 'react';
import moment from 'moment';

import { PARTY_A, DATE_FORMAT_LONG, PARTY_B } from 'Common/constants';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import calculateMultiItemsValue from 'Common/Utils/calculateMultiItemsValue';
import formatCurrency from 'Common/Utils/formatCurrency';
import Paragraph from 'Common/UI/Text/Paragraph';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { string } from 'prop-types';

export const acquiredSoldItemsQuestions: QuestionAny[] = [
  {
    name: 'info1',
    label:
      'The next few questions are about whether or not you have acquired or disposed of (sold) property since separation.',
    hint: '',
    type: 'info',
    defaultValue: '',
    help: {
      label: 'Why are you asking?',
      content: (
        <Paragraph large>
          The court needs to know if either party has done this so that the
          court has a complete picture of your property pool.
        </Paragraph>
      ),
    },
    index: 0,
  },
  {
    name: 'hasAcquiredProperty',
    label: 'Have you acquired any property since the date of separation?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 1,
  },
  {
    name: 'acquiredProperty',
    label: 'Please list the items you have acquired and their value',
    hint: '',
    type: 'multiItems',
    visible: answers => !!answers.hasAcquiredProperty,
    defaultValue: '',
    formAttributes: {
      hasDate: true,
      dateInfo:
        'If you can’t recall the exact date, you can enter an approximate date.',
      popupTitle: 'Acquired property',
      buttonText: 'Enter items',
      hintText:
        'Please enter the items you have acquired since your date of separation and their value, e.g. Honda Civic, Sony Bravia A8G 55-Inch Smart TV',
      textLabel: 'Item name',
      textPlaceholder: 'Enter item name',
    },
    index: 2,
  },
  {
    name: 'hasSoldProperty',
    label: 'Have you sold any property since the date of separation?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 3,
  },
  {
    name: 'soldProperty',
    label: 'Please list the items you have sold and their value',
    hint: '',
    type: 'multiItems',
    visible: answers => !!answers.hasSoldProperty,
    defaultValue: '',
    formAttributes: {
      hasDate: true,
      popupTitle: 'Sold property',
      buttonText: 'Enter items',
      hintText:
        'Please enter the items you have sold since your date of separation and their value, e.g. Honda Civic, Sony Bravia A8G 55-Inch Smart TV',
      textLabel: 'Item name',
      textPlaceholder: 'Enter item name',
    },
    index: 4,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 5,
  },
];

const makeHTMLList = (
  items: { text: string; number: number; date: string }[]
) => (
  <ul>
    {items.map(({ text, number, date }) => (
      <li key={text}>
        {text} for {formatCurrency(number)} on{' '}
        {moment(date).format(DATE_FORMAT_LONG)}
      </li>
    ))}
  </ul>
);

export const acquiredSoldItemsStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.creator || matter.self.party;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'hasAcquiredProperty',
    'hasSoldProperty',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );

  let name;
  if (party === PARTY_A) {
    name = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    name = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  const {
    hasAcquiredProperty,
    hasSoldProperty,
    acquiredProperty,
    soldProperty,
  } = data;

  if (!hasAcquiredProperty && !hasSoldProperty) {
    const statement = highlight`${name} has ${updated(
      ['hasAcquiredProperty', 'hasSoldProperty'],
      'not acquired or sold'
    )} property since your separation.`;

    if (plainText) {
      return `${statement}`;
    }

    return <>{statement}</>;
  }

  let acquired: React.ReactNode = '';
  let acquiredList: React.ReactNode = '';
  let sold: React.ReactNode = '';
  let soldList: React.ReactNode = '';

  if (hasAcquiredProperty) {
    const acquiredItems = calculateMultiItemsValue(acquiredProperty);

    acquired = highlight`${name} ${updated(
      'hasAcquiredProperty',
      'acquired'
    )} the following property since the date of separation:`;

    if (plainText) {
      acquiredList = acquiredItems.string;
    } else {
      acquiredList = makeHTMLList(acquiredItems.items);
    }
  }

  if (hasSoldProperty) {
    const soldItems = calculateMultiItemsValue(soldProperty);

    sold = highlight`${name} ${updated(
      'hasSoldProperty',
      'sold'
    )} the following property since the date of separation:`;

    if (plainText) {
      soldList = soldItems.string;
    } else {
      soldList = makeHTMLList(soldItems.items);
    }
  }

  if (plainText) {
    return `${acquired} ${acquiredList} ${sold} ${soldList}`;
  }

  return (
    <>
      {acquired} {acquiredList} {sold} {soldList}
    </>
  );
};
