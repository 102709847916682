import React from 'react';

import {
  STATUS_NEW_BOTH_PARTIES,
  STATUS_NEW_ONLY_SELF,
} from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import Card from 'Common/UI/Card';
import ProgressMeter from 'Common/UI/ProgressMeter';
import { QuestionStateCard, CardContinue } from 'Common/Data/Types/appSections';
import { NormalCard } from '.';

type ContinueCardProps = {
  data: QuestionStateCard<CardContinue>;
  isActive?: boolean;
  themeName?: string;
  cardHeadingText?: string;
  matter: Matter;
};

const ContinueCard: React.FC<ContinueCardProps> = ({
  data,
  isActive,
  themeName,
  cardHeadingText,
  matter,
}) => {
  const { cardID, navToQuestions, status, requiredLabel, cardForParty } = data;
  switch (status) {
    case STATUS_NEW_ONLY_SELF:
      return (
        <Card key={cardID} data={data} cardTheme={themeName} active={isActive}>
          <Card.MainHeading cardForParty={cardForParty}>
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            Only you can create this statement
          </Card.Paragraph>
          <Card.ButtonWrapper>
            <Card.Button
              onClick={navToQuestions}
              spaced={{ marginBottom: 'xxsmall' }}
              dataCy="get-started-button"
            >
              Continue
            </Card.Button>
            {requiredLabel && <Card.RequiredLabel>Required</Card.RequiredLabel>}
          </Card.ButtonWrapper>
          <ProgressMeter percentage={70} />
        </Card>
      );
    case STATUS_NEW_BOTH_PARTIES:
      return (
        <Card key={cardID} data={data} cardTheme={themeName} active={isActive}>
          <Card.MainHeading cardForParty={cardForParty}>
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph spaced={{ marginBottom: 'xxsmall' }}>
            Only you can create this statement
          </Card.Paragraph>
          <Card.Button
            spaced={{ marginBottom: 'xxsmall' }}
            onClick={navToQuestions}
            dataCy="get-started-button"
          >
            Continue
          </Card.Button>
          <ProgressMeter percentage={70} />
        </Card>
      );
    default:
      return (
        <NormalCard
          key={cardID}
          data={data}
          matter={matter}
          isActive={isActive}
          cardHeadingText={cardHeadingText}
        />
      );
  }
};

export default ContinueCard;
