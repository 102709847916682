import React, { useState } from 'react';

import formatCurrency from 'Common/Utils/formatCurrency';
import {
  TRANSFER_OPTION_SELL,
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
  TRANSFER_OPTION_SPLIT_EVENLY,
  AssetSplitOption,
} from 'Common/constants';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import { Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import { AssetCardContentType, AssetEnhanced } from 'Common/Data/Types/assets';
import {
  AssetTitle,
  FlexHeadingContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Value,
} from './helpers';
import TransferrableAssetBase from './TransferrableAssetBase';

const TransferrableAsset: React.FC<{
  asset: AssetEnhanced;
  isLoading: boolean;
  selectedOption?: AssetSplitOption;
  onTransferAsset: ({
    id,
    option,
    remove,
  }: {
    id: string;
    option: AssetSplitOption;
    remove: boolean;
  }) => Promise<void>;
}> = ({ asset, isLoading = false, selectedOption, onTransferAsset }) => {
  const [selectedTransferOption, setSelectedTransferOption] =
    useState<AssetCardContentType>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { name, id, value, cards = [], isSelected } = asset;

  const handleTransferAsset = (option: AssetSplitOption) =>
    onTransferAsset({ id, option, remove: !!isSelected }).then(() =>
      setIsModalVisible(false)
    );

  const handleSelectCard = (option: AssetSplitOption) => {
    if (
      ![
        TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
        TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
        TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
        TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
        TRANSFER_OPTION_SELL,
        TRANSFER_OPTION_SPLIT_EVENLY,
      ].includes(option)
    ) {
      throw Error(`Invalid transfer option (${option})`);
    }

    if (isSelected) {
      handleTransferAsset(option).then(() =>
        setSelectedTransferOption(undefined)
      );
    } else {
      const selected = cards.find(card => card.option === option);

      if (selected) {
        setSelectedTransferOption(selected);

        setIsModalVisible(true);
      }
    }
  };

  return (
    <TransferrableAssetBase
      asset={asset}
      isLoading={isLoading}
      selectedOption={selectedOption}
      isModalVisible={isModalVisible}
      hideModal={() => setIsModalVisible(false)}
      handleSelectCard={handleSelectCard}
    >
      <ModalHeader>
        <FlexHeadingContainer>
          <AssetTitle>{name}</AssetTitle>
          <Value>{formatCurrency(value)}</Value>
        </FlexHeadingContainer>
      </ModalHeader>
      {selectedTransferOption && (
        <>
          <ModalContent>
            <ResponsiveTextContainer>
              <Heading3>{selectedTransferOption.title}</Heading3>

              {React.isValidElement(selectedTransferOption.info) ? (
                selectedTransferOption.info
              ) : (
                <Paragraph large>{selectedTransferOption.info}</Paragraph>
              )}
            </ResponsiveTextContainer>
          </ModalContent>
          <ModalFooter>
            <GenericButton
              data-cy="asset-confirm-transfer"
              fullWidth
              onClick={() => handleTransferAsset(selectedTransferOption.option)}
            >
              Done
            </GenericButton>
          </ModalFooter>
        </>
      )}
    </TransferrableAssetBase>
  );
};

export default TransferrableAsset;
