import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const getScrollPage = () => {
  let docScrollTop = 0;
  if (document.documentElement && document.documentElement !== null) {
    docScrollTop = document.documentElement.scrollTop;
  }
  return window.pageYOffset || docScrollTop;
};

const getScrollElement = elementId => {
  let elemScrollTop = 0;
  const element = document.getElementById(elementId);
  if (element !== null) {
    elemScrollTop = element.scrollTop;
  }
  return elemScrollTop;
};

const scrollTo = scrollnumber => {
  window.requestAnimationFrame(() => {
    window.scrollTo(0, scrollnumber);
  });
};

const scrollToElement = (scrollnumber = 0, elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollTop = scrollnumber;
  }
};

const isBrowser = () => typeof window !== 'undefined';

class ScrollMemory extends React.Component {
  constructor(props) {
    super(props);
    // add event for click on previous or next browser button
    this.detectPop = this.detectPop.bind(this);
    // stock location key with scroll associate
    this.url = new Map();
  }

  componentDidMount() {
    window.addEventListener('popstate', this.detectPop);
  }

  shouldComponentUpdate(nextProps) {
    if (!isBrowser()) return false;
    const { location, elementID, scrollTopOffset } = this.props;
    const actual = location;
    const next = nextProps.location;
    const locationChanged =
      next.pathname !== actual.pathname || next.search !== actual.search;
    if (locationChanged) {
      let scroll = elementID ? getScrollElement(elementID) : getScrollPage();

      if (scrollTopOffset) {
        scroll += scrollTopOffset;
      }
      // save scroll with pathname location
      this.url.set(actual.pathname, scroll);
      // Force update to trigger componentDidUpdate
      this.forceUpdate();
    }
    return false;
  }

  componentDidUpdate() {
    const {
      location: { pathname },
      elementID,
    } = this.props;
    const nextFind = this.url.get(pathname);
    if (elementID) {
      scrollToElement(nextFind || 0, elementID);
    } else {
      scrollTo(nextFind || 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectPop);
  }

  detectPop() {
    if (!isBrowser()) {
      return;
    }
    const { elementID } = this.props;
    const key = window.location.pathname;
    const nextFind = this.url.get(key);
    if (nextFind) {
      if (elementID) {
        scrollToElement(nextFind || 0, elementID);
      } else {
        scrollTo(nextFind || 0);
      }
    }
  }

  render() {
    return null;
  }
}

ScrollMemory.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  elementID: PropTypes.string,
  scrollTopOffset: PropTypes.number,
};

ScrollMemory.defaultProps = {
  elementID: undefined,
  scrollTopOffset: undefined,
};

export default withRouter(ScrollMemory);
