import React from 'react';
import Paragraph from 'Common/UI/Text/Paragraph';
import CardWarning from './CardWarning';

import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { isThereALargeSuperDifferential } from '../../../Utils/superDifferential';

const ConditionalSuperWarning = () => {
  if (!isThereALargeSuperDifferential()) {
    return null;
  }

  return (
    <CardWarning>
      <Paragraph>
        It seems that there is a large difference in your superannuation
        balances. You may want to consider splitting superannuation in your
        situation. amica cannot facilitate splitting superannuation. For more
        information see our{' '}
        <ReferralLink href="https://www.amica.gov.au/money-and-property.html">
          Money and Property page
        </ReferralLink>
        .
      </Paragraph>
    </CardWarning>
  );
};

export default ConditionalSuperWarning;
