import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import formatCurrency from 'Common/Utils/formatCurrency';
import AssetProps from 'Common/Data/App/assetProperties';
import DiviProps from 'Common/Data/App/diviProperties';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Heading1 } from 'Common/UI/Text/Headings';
import getYouOrThem from 'Common/Utils/getYouOrThem';
import { DivisionAssetsContext } from '../AssetTransfer/DivisionAssetsContext';
import OptionalRequiredText from './OptionalRequiredText';

const MoneyText = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colours.darkOrange};
`;

const Heading = styled(Heading1)`
  margin-top: ${props => props.theme.padding.small}px;
`;

const Wrapper = styled.div`
  margin-top: 0;
  border-bottom: 1px solid ${props => props.theme.colours.lightGrey};
  padding: ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}

  ${Paragraph}:last-child {
    margin: 0 0 ${props => props.theme.padding.small}px;
  }
`;

const TransferIntro = ({
  location,
  showSplitInfo,
  children,
  isRequired,
  hideOptionalRequired,
}) => {
  const context = useContext(DivisionAssetsContext);
  const { getPageTitle } = context;

  const {
    startPercentageToTransfer,
    absoluteStartAmountToTransfer,
    owedParty,
  } = AssetProps();

  const name = getYouOrThem(owedParty);

  const { pathname = '' } = location;

  const { agreedSplit } = DiviProps();

  return (
    <Wrapper>
      <ResponsiveTextContainer>
        <Heading>
          {getPageTitle(pathname)}{' '}
          {!hideOptionalRequired && (
            <OptionalRequiredText isRequired={isRequired}>
              {isRequired ? 'Required' : 'Optional'}
            </OptionalRequiredText>
          )}
        </Heading>
        {children}
        {showSplitInfo && (
          <Paragraph large>
            In order to reach your {Math.round(agreedSplit * 100)} /{' '}
            {100 - Math.round(agreedSplit * 100)} split,{' '}
            <MoneyText>
              {Math.round(startPercentageToTransfer * 100)}% (
              {formatCurrency(absoluteStartAmountToTransfer)})
            </MoneyText>{' '}
            of the net asset pool will need to be transferred to {name}.
          </Paragraph>
        )}
      </ResponsiveTextContainer>
    </Wrapper>
  );
};

TransferIntro.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  showSplitInfo: PropTypes.bool,
  children: PropTypes.node,
  hideOptionalRequired: PropTypes.bool,
};

TransferIntro.defaultProps = {
  children: null,
  showSplitInfo: false,
  isRequired: false,
  hideOptionalRequired: false,
};

export default withRouter(TransferIntro);
