import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 72px;
    height: 40px;
    background: ${({ theme }) => theme.colours.clickableGrey};
    display: block;
    border-radius: 40px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    background: ${({ theme }) => theme.colours.midLightGrey};
    border-radius: 30px;
    transition: 0.3s;
  }

  input:checked + label {
    background: ${({ theme }) => theme.colours.pastelPurple};
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
    background: ${({ theme }) => theme.colours.midLightPurple};
  }
`;

const Toggle = ({ id, value, onChange }) => (
  <Wrapper>
    <input
      type="checkbox"
      id={id}
      value={value}
      checked={value}
      onChange={evt => onChange(evt.target.checked, evt)}
    />
    <label htmlFor={id}>Toggle</label>
  </Wrapper>
);

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  value: false,
};

export default Toggle;
