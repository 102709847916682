import React from 'react';

import { QuestionAny, Statement } from 'Common/Data/Types/appSections';

export const superannuationFundClausesQuestions: QuestionAny[] = [
  {
    name: 'clauses',
    type: 'customComponent',
    index: 0,
  },
];

export const superannuationFundClausesStatement = ({
  plainText = false,
}: Statement) => {
  if (plainText) {
    return `Superannuation fund clauses statement`;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>Superannuation fund clauses statement</>;
};
