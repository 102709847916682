import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MatterProps from 'Common/Utils/MatterProps';
import DiviProps from 'Common/Data/App/diviProperties';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import getYouOrThem from '../../../../Utils/getYouOrThem';
import { ScheduleContext } from '../ScheduleContext';

const Label = styled(Paragraph)`
  margin: 0;
`;

const BoldLabel = styled(Paragraph)`
  font-weight: bold;
  margin: 0;
`;

const StyledNightsCounter = styled.div`
  text-align: left;
  padding-right: 5vw;
`;

const IconMoon = ({ colour }) => (
  <svg
    width="40"
    height="24"
    viewBox="0 0 40 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill={colour} />
    <path
      d="M17 12.4505C16.9124 13.3981 16.5568 14.3013 15.9746 15.0542C15.3925 15.8071 14.608 16.3787 13.7129 16.702C12.8177 17.0253 11.8491 17.087 10.9202 16.8799C9.99125 16.6728 9.14055 16.2054 8.46758 15.5324C7.79461 14.8595 7.32722 14.0087 7.1201 13.0798C6.91298 12.1509 6.97469 11.1823 7.29801 10.2871C7.62133 9.39202 8.19289 8.6075 8.94581 8.02537C9.69873 7.44324 10.6019 7.08758 11.5495 7C10.9947 7.75063 10.7277 8.67547 10.7971 9.60631C10.8666 10.5372 11.2678 11.4122 11.9278 12.0722C12.5878 12.7322 13.4628 13.1334 14.3937 13.2029C15.3245 13.2723 16.2494 13.0053 17 12.4505Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Number = styled(Callout)`
  color: ${props =>
    props.zero ? props.theme.colours.clickableGrey : props.theme.colours.black};
  ${props => props.highlight && `color: ${props.theme.colours.purple};`}
  font-weight: normal;
  display: flex;
  font-size: 28px;
  line-height: 1;
  align-items: center;
  margin: 9px 0 10px;
`;

const NightsCounter = ({ party, highlight }) => {
  const self = party === MatterProps('self.party');
  const { getScheduleColours } = DiviProps();
  const context = useContext(ScheduleContext);
  const { getNumberNightsWithSelf, getNumberNightsWithOther } = context;
  const nights = self ? getNumberNightsWithSelf() : getNumberNightsWithOther();

  return (
    <StyledNightsCounter self={self}>
      {self ? (
        <BoldLabel>Nights with {getYouOrThem(party)}</BoldLabel>
      ) : (
        <Label>Nights with {getYouOrThem(party)}</Label>
      )}
      <Number zero={nights === 0} highlight={highlight}>
        <IconMoon colour={getScheduleColours(party).nightsColour} /> {nights}
      </Number>
    </StyledNightsCounter>
  );
};

NightsCounter.propTypes = {
  party: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
};

NightsCounter.defaultProps = {
  highlight: false,
};

export default NightsCounter;
