import {
  ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER,
  ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER,
  CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
  DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
  DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED,
  NEW_PARENTING_PLAN_NEEDS_CONFIRMATION,
  PARENTING_PLAN_NEEDS_CONFIRMATION,
  PARENTING_PLAN_PAYMENT_REQUIRED,
  SUGGESTED_DIVISION_OTHER_MADE_OFFER,
  SUGGESTED_DIVISION_START,
  RESET_SUGGESTED_DIVISION_OTHER_NOT_READY,
  RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF,
  SUGGESTED_DIVISION_READY,
  AGREEMENT_CREATED,
  PROPERTY_AGREEMENT_NEEDS_CONFIRMATION,
  CONSENT_ORDER_NEEDS_CONFIRMATION,
  DOCUMENT_TYPES_OTHER_NOT_READY,
  NEW_PARENTING_PLAN_OTHER_NOT_READY,
  PARENTING_PLAN_OTHER_NOT_READY,
  SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER,
  SUGGESTED_DIVISION_AGREED,
  PROPERTY_DASHBOARD_COMPLETED,
  SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER,
  SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER,
  SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE,
  SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTER,
} from 'Common/constants';
import { Notification } from 'Common/Data/Types/appState';
import Matter from 'Common/Data/Types/matter';
import {
  ODRS_DIVISION_ASSETS_VIEW_OFFER,
  ODRS_PARENTING_COMPLETE_ROUTE,
  ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
  ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
  ODRS_INVITE_PARTNER_ROUTE,
  ODRS_PROPERTY_COMPLETE_ROUTE,
  ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
} from 'Common/routes';
import getNames from 'Common/Utils/getNames';
import { getAppSections } from 'Common/Data/App/appSections';
import { getCardNotificationData } from 'Common/Utils/cardHelpers';
import { isPartyBInvited } from 'App/Utils/matterHelpers';
import { checkPageUnLocked } from 'Common/Utils/consentOrderPageChecks';
import { currentStatusConsentOrders } from 'Common/Utils/documentStatus';
import { getConsentOrderRoute } from 'Common/Utils/getRoute';
import { documentCheckKeys } from 'Common/Utils/documentHelpers';
import { generateNotificationStates } from './generateAppState';

const getNotifications = (matter: Matter): Notification[] => {
  const names = getNames();

  const activeStates = generateNotificationStates(matter);

  const notifications: Notification[] = [];

  if (!isPartyBInvited(matter)) {
    notifications.push({
      text: 'Invite your former partner to amica',
      route: ODRS_INVITE_PARTNER_ROUTE,
    });
  }

  notifications.push(
    ...getCardNotificationData({
      appSections: getAppSections(),
      matter,
    })
  );

  if (
    activeStates.includes(DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED) ||
    activeStates.includes(
      CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED
    )
  ) {
    notifications.push({
      text: 'Pay for your consent order in order to download it',
      route: ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
    });
  }

  if (activeStates.includes(RESET_SUGGESTED_DIVISION_OTHER_NOT_READY)) {
    notifications.push({
      text: `${names.other} has declined your request to restart the asset division section`,
      route: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
    });
  }

  if (activeStates.includes(RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF)) {
    notifications.push({
      text: `${names.other} has requested to restart the asset division section`,
      route: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
    });
  }

  if (
    activeStates.includes(DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED)
  ) {
    notifications.push({
      text: 'Pay for your property agreement in order to download it',
      route: ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
    });
  }

  if (activeStates.includes(PROPERTY_AGREEMENT_NEEDS_CONFIRMATION)) {
    notifications.push({
      text: `${names.other} has requested to create a property agreement`,
      route: ODRS_PROPERTY_COMPLETE_ROUTE,
    });
  }

  if (activeStates.includes(CONSENT_ORDER_NEEDS_CONFIRMATION)) {
    notifications.push({
      text: `${names.other} has requested to create an application for consent orders`,
      route: ODRS_PROPERTY_COMPLETE_ROUTE,
    });
  }

  if (activeStates.includes(DOCUMENT_TYPES_OTHER_NOT_READY)) {
    if (documentCheckKeys.BOTH || documentCheckKeys.PROPERTY_AGREEMENT) {
      notifications.push({
        text: `${names.other} has declined your request to create a property agreement`,
        route: ODRS_PROPERTY_COMPLETE_ROUTE,
      });
    }

    if (documentCheckKeys.BOTH || documentCheckKeys.CONSENT_ORDERS) {
      notifications.push({
        text: `${names.other} has declined your request to create an application for consent orders`,
        route: ODRS_PROPERTY_COMPLETE_ROUTE,
      });
    }
  }

  if (activeStates.includes(PARENTING_PLAN_PAYMENT_REQUIRED)) {
    notifications.push({
      text: 'Pay for your parenting agreement in order to download it',
      route: ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
    });
  }

  if (activeStates.includes(PARENTING_PLAN_NEEDS_CONFIRMATION)) {
    notifications.push({
      text: `${names.other} has requested to create a parenting agreement`,
      route: ODRS_PARENTING_COMPLETE_ROUTE,
    });
  }

  if (activeStates.includes(NEW_PARENTING_PLAN_NEEDS_CONFIRMATION)) {
    notifications.push({
      text: `${names.other} has requested to turn your parenting agreement into a parenting plan`,
      route: ODRS_PARENTING_COMPLETE_ROUTE,
    });
  }

  if (activeStates.includes(PARENTING_PLAN_OTHER_NOT_READY)) {
    notifications.push({
      text: `${names.other} has declined your request to create a parenting agreement`,
      route: ODRS_PARENTING_COMPLETE_ROUTE,
    });
  }

  if (activeStates.includes(NEW_PARENTING_PLAN_OTHER_NOT_READY)) {
    notifications.push({
      text: `${names.other} has declined your request to turn your parenting agreement into a parenting plan`,
      route: ODRS_PARENTING_COMPLETE_ROUTE,
    });
  }

  if (
    activeStates.includes(SUGGESTED_DIVISION_START) ||
    activeStates.includes(SUGGESTED_DIVISION_READY)
  ) {
    notifications.push({
      text: 'You can now get started on the suggested division of your money and property',
      route: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
    });
  }

  if (activeStates.includes(SUGGESTED_DIVISION_OTHER_MADE_OFFER)) {
    notifications.push({
      text: `View ${names.other}'s suggested division offer`,
      route: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
    });
  }

  if (activeStates.includes(SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER)) {
    notifications.push({
      text: `View ${names.other}'s suggested division counter offer`,
      route: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
    });
  }

  if (activeStates.includes(SUGGESTED_DIVISION_AGREED)) {
    notifications.push({
      text: `You can now get started on the division of your assets`,
      route: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
    });
  }

  if (
    activeStates.includes(ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER) ||
    activeStates.includes(ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER)
  ) {
    notifications.push({
      text: `View ${names.other}'s asset division offer`,
      route: ODRS_DIVISION_ASSETS_VIEW_OFFER,
    });
  }

  if (activeStates.includes(PROPERTY_DASHBOARD_COMPLETED)) {
    notifications.push({
      text: `You can now generate a property agreement and get started on an Application for Consent Orders`,
      route: ODRS_PROPERTY_COMPLETE_ROUTE,
    });
  }

  // super splitting notifications

  if (activeStates.includes(SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER)) {
    notifications.push({
      text: `Let amica know that you have received a Superannuation Information Kit response from ${names.other}'s superannuation fund`,
      route: ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
    });
  }

  if (
    activeStates.includes(
      SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER
    )
  ) {
    notifications.push({
      text: `Upload the Superannuation Information Kit response from ${names.other}'s superannuation fund`,
      route: ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
    });
  }

  if (
    activeStates.includes(SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE)
  ) {
    notifications.push({
      text: `${names.other} is requesting more information about your superannuation balance`,

      route: ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
    });
  }

  if (
    activeStates.includes(SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE)
  ) {
    notifications.push({
      text: `${names.other} has cancelled their request for more information on your superannuation balance.`,

      route: ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
    });
  }
  if (
    activeStates.includes(
      SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF
    )
  ) {
    notifications.push({
      text: `${names.other} wants to remove superannuation from the division of assets`,

      route: ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
    });
  }
  if (
    activeStates.includes(
      SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTER
    )
  ) {
    notifications.push({
      text: `Superannuation has been removed from your division of assets`,

      route: ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
    });
  }

  if (
    !checkPageUnLocked.getStarted.unlocked() &&
    currentStatusConsentOrders() === AGREEMENT_CREATED
  ) {
    notifications.push({
      text: `Get started on your application for consent orders`,
      route: getConsentOrderRoute(),
    });
  }

  return notifications;
};

export default getNotifications;
