import React from 'react';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  PROPERTY_AGREEMENT_NEEDS_CONFIRMATION,
  CONSENT_ORDER_NEEDS_CONFIRMATION,
} from 'Common/constants';
import {
  documentCheckKeys,
  documentsNeedsConfirmation,
} from 'Common/Utils/documentHelpers';
import TextButton from 'Common/UI/Button/TextButton';
import { AppState } from 'Common/Data/Types/appState';

export const propertyAgreementNeedsConfirmationFlags: AppState = {
  id: 'propertyAgreementWaitingOnYou',
  description: 'Waiting on you for propertyAgreement',
  check: () => {
    const docsNeedingConfirmation = documentsNeedsConfirmation();

    return (
      docsNeedingConfirmation === documentCheckKeys.PROPERTY_AGREEMENT ||
      docsNeedingConfirmation === documentCheckKeys.BOTH
    );
  },
  flags: {
    appState: PROPERTY_AGREEMENT_NEEDS_CONFIRMATION,
  },
  usedAsNotificationState: true,
};

export const consentOrderNeedsConfirmationFlags: AppState = {
  id: 'consentOrderWaitingOnYou',
  description: 'Waiting on you for consent order',
  check: () => {
    const docsNeedingConfirmation = documentsNeedsConfirmation();

    return (
      docsNeedingConfirmation === documentCheckKeys.CONSENT_ORDERS ||
      docsNeedingConfirmation === documentCheckKeys.BOTH
    );
  },
  flags: {
    appState: CONSENT_ORDER_NEEDS_CONFIRMATION,
  },
  usedAsNotificationState: true,
};

type Props = {
  otherName: string;
  readyToExport: () => void;
  notReadyToExport: () => void;
};

export const PropertyAgreementNeedsConfirmation: React.FC<Props> = ({
  otherName,
  readyToExport,
  notReadyToExport,
}) => (
  <>
    <Heading2MarginTopNone>
      {otherName} has requested to create a property agreement
    </Heading2MarginTopNone>
    <Paragraph large>
      Creating a property agreement will provide you both with a written record
      of your agreement.
    </Paragraph>
    <GenericButton small fullWidth onClick={readyToExport}>
      Create property agreement
    </GenericButton>
    <TextButton fullWidth onClick={notReadyToExport} small color="#FFFFFF">
      Do not create this document
    </TextButton>
  </>
);

// DocumentTypesNeedsConfirmation
export const ConsentOrderNeedsConfirmation: React.FC<Props> = ({
  otherName,
  readyToExport,
  notReadyToExport,
}) => (
  <>
    <Heading2MarginTopNone>
      {otherName} has requested to create a consent order application
    </Heading2MarginTopNone>
    <Paragraph large>
      You will both need to agree to create this document before continuing.
    </Paragraph>
    <GenericButton small fullWidth onClick={readyToExport}>
      Agree to create an application
    </GenericButton>
    <TextButton fullWidth onClick={notReadyToExport} small color="#FFFFFF">
      Do not create this document
    </TextButton>
  </>
);
