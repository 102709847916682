import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { SUPER_SPLITTING_CONSENT_ORDER_WAITING_FOR_OTHER_TO_SEND_PAYEE } from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import AssetProperties from 'Common/Data/App/assetProperties';
import { checkPageUnLocked } from 'Common/Utils/consentOrderPageChecks';
import { getMatter } from 'Common/Utils/MatterProps';

export const superannuationConsentOrderWaitingForOtherToSendPayeeFlags: AppState =
  {
    id: 'superannuationConsentOrderWaitingForOtherToSendPayee',
    description:
      'Notify the payee that they need to wait for the payer to send the documents to their superannuation fund',
    check: () => {
      const { isSuperIncludedInOffer, superSplittingFlags, isPayee } =
        AssetProperties();

      const { payerHasConfirmedNoChangesToClauses } = superSplittingFlags;

      // if super is not being split then return false
      if (!isSuperIncludedInOffer) {
        return false;
      }

      // if the `completed` page is not unlocked yet then return false
      if (!checkPageUnLocked.completed.unlocked()) {
        return false;
      }

      const { items = [] } = getMatter();

      const superannuationItem = items.find(
        item => item.SectionID === 'superannuationfundclauses'
      );

      // if this user is the payee and the payer has not yet confirmed or edited the clauses then show this banner
      if (
        isPayee &&
        !payerHasConfirmedNoChangesToClauses &&
        !superannuationItem
      ) {
        return true;
      }

      return false;
    },
    flags: {
      appState: SUPER_SPLITTING_CONSENT_ORDER_WAITING_FOR_OTHER_TO_SEND_PAYEE,
    },
  };

type Props = {
  otherName: string;
};

export const SuperannuationConsentOrderWaitingForOtherToSendPayee: React.FC<Props> =
  ({ otherName }) => (
    <div data-cy="banner-requestee-waiting-for-other">
      <SpacedBox marginBottom={8}>
        <Heading2MarginTopNone>
          {otherName} will need to send the draft minutes of consent to the
          trustee of their superannuation fund
        </Heading2MarginTopNone>
        <Paragraph>
          Their superannuation fund will need to review the draft minutes of
          consent so they can approve or propose edits to the superannuation
          splitting orders.
        </Paragraph>
      </SpacedBox>
    </div>
  );
