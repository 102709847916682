import styled from 'styled-components';

const PageHeader = styled.div`
  flex: 0;
  padding: ${props => props.theme.padding.medium}px;
  transition: all 1s;
  margin-top: ${props => (props.show ? '0' : '-93px')};
`;

export default PageHeader;
