import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';

import StyledTextfield from 'Common/UI/Form/Textfield';
import { Label } from 'Common/UI/Text/Label';
import Appears from 'Common/UI/Layout/Appears';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import useForm from 'Common/Utils/Hooks/useForm';
import ToneWarning from '../../Messaging/ToneWarning';
import DialogSendOffer from '../Dialog/DialogSendOffer';

const MessageWrapper = styled.div`
  padding: ${props => props.theme.padding.small}px;
  background-color: ${({ toneWarning, theme: { colours } }) =>
    toneWarning ? colours.beige : colours.offWhite};

  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    padding: ${props =>
      `${props.theme.padding.large}px ${props.theme.padding.xlarge}px`};
  }
`;

const MessageInput = styled(StyledTextfield)`
  flex: 1;

  > div {
    height: 100%;
  }

  label {
    display: inline;
  }

  textarea {
    border-radius: 4px;
    line-height: 144%;
    margin-top: 0;
    border: 1px solid ${props => props.theme.colours.clickableGrey};
    background: ${props => props.theme.colours.white};
    padding: 9px 16px;
    // 196px is the height of the Message Textfield
    height: calc(100% - 196px);
    min-height: 150px;
    width: 100%;
  }
`;

const LabelBold = styled(Label)`
  font-weight: bold;
  margin-bottom: ${props => props.theme.padding.small}px;
`;

const Messaging = ({ afterMessageSend, onSubmit, onCancel, inputLabel }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [toneWarning, updateToneWarning] = useState(false);

  const handleToneError = err => {
    if (err.type && err.type === 'ToneError') {
      updateToneWarning(true);
      Sentry.withScope(scope => {
        scope.setLevel('debug');
        Sentry.captureException(err);
      });
    } else {
      throw err;
    }
  };

  useEffect(() => {
    setTimeout(() => setShowDialog(true), 1000);
  }, []);

  const { inputs, handleInputChange, handleSubmit } = useForm(() =>
    onSubmit(inputs.message).then(afterMessageSend).catch(handleToneError)
  );

  return (
    <>
      <MessageWrapper toneWarning={toneWarning}>
        <FlexGrid medium={2}>
          <div>
            <LabelBold>{inputLabel}</LabelBold>
            <MessageInput
              onChange={(value, event) => {
                updateToneWarning(false);
                handleInputChange(value, event);
              }}
              value={inputs.message}
              name="message"
              label=""
              rows={12}
              placeholder="This is fair because..."
              data-cy="message-input-box"
            />
          </div>
          <OffsetColumn>
            <Appears on={toneWarning}>
              <ToneWarning
                onClick={() => {
                  updateToneWarning(false);
                }}
              />
            </Appears>
          </OffsetColumn>
        </FlexGrid>
      </MessageWrapper>
      <DialogSendOffer
        showDialog={showDialog && !toneWarning}
        onCancel={onCancel}
        onSend={handleSubmit}
        sendDisabled={!inputs.message}
      />
    </>
  );
};

Messaging.propTypes = {
  afterMessageSend: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  inputLabel: PropTypes.string,
};

Messaging.defaultProps = {
  afterMessageSend: () => {},
  inputLabel: '',
};

export default Messaging;
