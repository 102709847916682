import React from 'react';

import { ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE } from 'Common/routes';
import { Success } from 'Common/UI/Layout/Completed';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import getNames from 'Common/Utils/getNames';

const LetterUploadedConfirmation: React.FC = () => {
  const otherName = getNames().other;

  return (
    <Success
      buttonRoute={ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE}
      buttonLabel="Back to the dashboard"
    >
      <H1Conversational>
        Great, you have uploaded your document
      </H1Conversational>

      <Paragraph>
        {otherName} will be able to view the letter their superannuation fund
        sent you.
      </Paragraph>
    </Success>
  );
};

export default LetterUploadedConfirmation;
