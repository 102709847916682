import React, { useEffect } from 'react';
import { HOME_ROUTE } from 'Common/routes';
import { signOutAction } from 'App/State/UserActions';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';

const Signout: React.FC = () => {
  const dispatch = useAppDispatch();

  const navigate = (to: string, state?: { location: any }) =>
    dispatch(push(to, state));
  const signOut = () => dispatch(signOutAction());

  useEffect(() => {
    // wait one cycle to sign out as it throws an error otherwise
    setTimeout(() => {
      signOut().then(() => {
        navigate(HOME_ROUTE);
      });
    });
  }, []);

  return null;
};

export default Signout;
