import React from 'react';

import { DEFAULT_OTHER_TEXT } from 'Common/constants';
import MatterProps from 'Common/Utils/MatterProps';
import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Sent } from 'Common/UI/Layout/Completed';

const DivisionMessagingSent = () => (
  <Sent
    buttonLabel="Back to home"
    buttonRoute={ODRS_PROPERTY_ASSET_DIVISION_ROUTE}
  >
    <H1Conversational>Your offer has been sent</H1Conversational>
    <Paragraph large>
      Your offer has been sent to{' '}
      {MatterProps('other.firstname', DEFAULT_OTHER_TEXT)}. We will let you know
      once they have reviewed your offer
    </Paragraph>
  </Sent>
);

export default DivisionMessagingSent;
