import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import {
  ODRS_LOGIN_ROUTE,
  INTRO_ROUTE,
  ODRS_DASH_HOME_ROUTE,
  MARKETING_SITE,
  AMICA_ONE,
} from 'Common/routes';
import { Title, Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import PageContainer from 'Common/UI/Layout/PageContainer';
import PageContent from 'Common/UI/Layout/PageContent';
import PageFooter from 'Common/UI/Layout/PageFooter';
import TextButton from 'Common/UI/Button/TextButton';
import RoundButton from 'Common/UI/Button/RoundButton';
import FloatingCloseButton from 'Common/UI/Button/FloatingCloseButton';

import BackgroundShapesBottomLeft from 'Common/UI/Icons/Background_shapes-3.svg';
import UserIcon from 'Common/UI/Icons/User.svg';
import theme from 'Common/Utils/theme';
import AppLogo from 'App/UI/AppLogo';
import AmicaLogo from 'App/UI/AmicaLogo';
import AmicaOneLogo from 'App/UI/AmicaOneLogo';
import MatterGuard from './Guard/MatterGuard';

const UserCircleContainer = styled.div`
  align-self: start;
  padding-bottom: ${theme.spacing[4]};
  margin-bottom: ${theme.spacing[8]};
  width: 55px;
  border-bottom: solid 1px ${theme.colours.lightGrey};
`;

const UserCircle = styled.div`
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url('${UserIcon}');
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${theme.colours.lightPurple};

  &:not(:first-child) {
    z-index: 1;
    margin-left: -10px;
    filter: brightness(1.25) hue-rotate(270deg);
  }
`;

const StyledPageContent = styled(PageContent)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${theme.spacing[4]};
`;

const TitleContainer = styled.div`
  margin: ${theme.spacing[6]} 0 ${theme.spacing[4]} 0;

`;
const StyledTitle = styled(Title)`
  margin-top: 0;
  font-size: 2rem;
`;

const StyledPageFooter = styled(PageFooter)`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: ${theme.spacing[4]};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 940px;
  padding: 0 ${theme.spacing[4]};
  margin: 92px auto 0 auto;

  @media ${theme.sizes.md.breakpoint} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 144px;
  }

  &:before {
    content: '';
    position: fixed;
    inset: 0 0 0 0;
    background-image: url(${BackgroundShapesBottomLeft});
    background-repeat: no-repeat;
    background-position: bottom left;
    pointer-events: none;
    z-index: -1;
  }
`;

const StyledBox = styled(PageContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 440px;
  margin-top: ${theme.spacing[2]};
  margin-bottom: ${theme.spacing[2]};
  padding: 20px;
  background-color: ${theme.colours.backgroundGrey};
  border-radius: 16px;

  &:first-child {
    background-color: ${theme.colours.white};
    box-shadow: 4px 4px 24px 0px rgba(33, 32, 44, 0.03);
  }

  @media ${theme.sizes.md.breakpoint} {
    width: 48%;
    margin-left: 2%;

    &:first-child {
      margin-left: 0;
    }
  }

`;

const Onboarding = ({ navigate }) => (
  <MatterGuard expectNone destination={ODRS_DASH_HOME_ROUTE}>
    <PageContainer id="scrollMemory">
      <AppLogo />
      <FloatingCloseButton as="a" href={MARKETING_SITE}>
        Back to home
      </FloatingCloseButton>
      <StyledContainer>
        <StyledBox>
          <StyledPageContent>
            <UserCircleContainer>
              <UserCircle />
              <UserCircle />
            </UserCircleContainer>
            <div style={{ color: theme.colours.purple }}>
              <AmicaLogo width="69px" />
            </div>
            <TitleContainer>
              <StyledTitle data-cy="landing-heading">Ready to start?<br />Sign up to amica</StyledTitle>
            </TitleContainer>
            <Paragraph>
            <b>A smarter way to reach parenting and property agreements</b>
            </Paragraph>
            <Paragraph>
  Create an account and invite your former partner to create separation agreements that work for you
            </Paragraph>
            <StyledPageFooter className="transition-fade-down">
              <RoundButton
                data-cy="signup-button"
                onClick={() => navigate(INTRO_ROUTE)}
              >
                Sign up
              </RoundButton>
              <TextButton
                color={theme.colours.purple}
                data-cy="login-button"
                onClick={() => navigate(ODRS_LOGIN_ROUTE)}
                style={{ paddingLeft: '0', textDecoration: 'underline'}}
              >
                I already have an account
              </TextButton>
            </StyledPageFooter>
          </StyledPageContent>
        </StyledBox>
        <StyledBox>
          <StyledPageContent>
            <UserCircleContainer>
              <UserCircle />
            </UserCircleContainer>
            <div style={{ color: theme.colours.purple }}>
              <AmicaOneLogo width="98px" />
            </div>
            <TitleContainer>
              <Heading3>See what it&apos;s like to use amica, with amica one</Heading3>
            </TitleContainer>
            <Paragraph>
            Get an idea of how amica works without involving your former partner.
            </Paragraph>
            <Paragraph>
              Want to explore amica, or see what your separation could look like before inviting your former partner? Try amica one
            </Paragraph>
            <StyledPageFooter className="transition-fade-down">
              <RoundButton
                subtle
                data-cy="amicaone-button"
                onClick={async () => {window.location.assign(AMICA_ONE)}}
                style={{ textDecoration: 'none'}}
              >
                Try out amica one
              </RoundButton>
            </StyledPageFooter>
          </StyledPageContent>
        </StyledBox>
      </StyledContainer>
    </PageContainer>
  </MatterGuard>
);

Onboarding.propTypes = {
  navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
});

export default connect(null, mapDispatchToProps)(Onboarding);
