import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  BANNER_HIDE,
  BANNER_SHOW,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_OTHER,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import AssetProperties from 'Common/Data/App/assetProperties';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import getPartyKeys from 'Common/Utils/getPartyKeys';

export const superSplittingRemoveSuperannuationWaitingForOtherFlags: AppState =
  {
    id: 'superSplittingRemoveSuperannuationWaitingForOther',
    description:
      'Party has requested to remove superannuation and is waiting for other party to confirm',
    check: () => {
      const {
        isAssetSplitCompleted,
        isSuperIncludedInOffer,
        superSplittingFlags,
      } = AssetProperties();

      const {
        removeSuperannuationInProgress,
        removeSuperannuationRequestedBy,
        removeSuperannuationRequestStatus,
      } = superSplittingFlags;

      const partyKeys = getPartyKeys();

      const selfRequestedRemoveSuper =
        removeSuperannuationRequestedBy === partyKeys.self;

      // if asset split isn't completed, super isn't included, or the remove super flow has not been started, this app state is not relevant
      if (
        !isSuperIncludedInOffer ||
        !isAssetSplitCompleted ||
        !removeSuperannuationInProgress
      ) {
        return false;
      }

      // self requested to remove super and the other party has not yet responded
      if (selfRequestedRemoveSuper && !removeSuperannuationRequestStatus) {
        return true;
      }

      return false;
    },
    flags: {
      appState: SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_OTHER,
      bannerTopState: () =>
        sessionStorageLoad(
          'SuperSplittingRemoveSuperannuationWaitingForOtherDismissed'
        )
          ? BANNER_HIDE
          : BANNER_SHOW,
    },
  };

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperSplittingRemoveSuperannuationWaitingForOther: React.FC<Props> =
  ({ otherName, updateAppState }) => (
    <>
      <SpacedBox marginBottom={8}>
        <Heading2MarginTopNone>
          {otherName} needs to confirm that they are comfortable with removing
          superannuation from the asset division.
        </Heading2MarginTopNone>
        <Paragraph>
          If they are OK with removing your superannuation from the asset
          division, you’ll have to create a new proposed division of assets.
        </Paragraph>
      </SpacedBox>
      <GenericButton
        fullWidth
        onClick={() => {
          sessionStorageSave(
            `SuperSplittingRemoveSuperannuationWaitingForOtherDismissed`,
            true
          );
          updateAppState();
        }}
        small
      >
        Got it
      </GenericButton>
    </>
  );
