import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  SlideInner,
  SlideText,
  SlideIcon,
} from 'Common/UI/Carousel/ImageSlideWrapper';
import DivisionImage02 from './division-image-2.svg';

export default function () {
  return (
    <SlideInner>
      <SlideIcon className="slide-icon" backgroundImage={DivisionImage02}>
        <img alt="" src={DivisionImage02} srcSet={`${DivisionImage02} 2x`} />
      </SlideIcon>
      <SlideText data-cy="suggested-division-slide-2">
        <H2Conversational>Future needs - Age & Health</H2Conversational>
        <Paragraph>
          Future needs means your future financial needs after separation,
          taking into consideration all the circumstances of the relationship.
          For example, if one party has ongoing health issues, or looks after
          young children, they may be entitled to receive a greater division of
          money and property to allow for their future needs.
        </Paragraph>
      </SlideText>
    </SlideInner>
  );
}
