import React from 'react';

import { Party, TRANSFER_OPTION_SELL } from 'Common/constants';
import {
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import { getReceivingPartyForAsset } from 'Common/Data/App/assetHelpers';
import Paragraph from 'Common/UI/Text/Paragraph';
import getNames from 'Common/Utils/getNames';
import {
  QuestionAny,
  QuestionCard,
  Statement,
} from 'Common/Data/Types/appSections';
import AssetProperties from 'Common/Data/App/assetProperties';

export const propertiesContinueQuestions = (
  card?: QuestionCard
): QuestionAny[] => {
  let transferOrSell;
  let isSelling = false;
  const { other: otherName } = getNames();

  if (card && 'extendID' in card) {
    const { extendID } = card;

    const { agreedAssetsToSplit } = AssetProperties();

    const asset = agreedAssetsToSplit.find(
      (a: Record<string, unknown>) => a.id === extendID
    );

    if (!asset) {
      throw Error('No asset found but it should exist');
    }

    if ('option' in asset && asset.option === TRANSFER_OPTION_SELL) {
      transferOrSell = 'sell';
      isSelling = true;
    } else {
      transferOrSell = 'transfer';
    }
  }

  return [
    {
      name: 'propertyAddress',
      label: `You have already agreed to ${transferOrSell} the following:`,
      type: 'text',
      disabled: true,
      description: `You and ${otherName} have already agreed to this.`,
      index: 0,
    },
    {
      name: 'propertyIdentifier',
      label: 'What is the Certificate of Title Folio Identifier?',
      hint: 'Reference number',
      type: 'text',
      sensitive: true,
      defaultValue: '',
      content: (
        <Paragraph large>
          If the property is identified by any other reference, ie. lot number,
          plan number or valuation number, then please provide the reference.
        </Paragraph>
      ),
      index: 1,
    },
    {
      name: 'methodOfSale',
      label: 'Which method of sale would you prefer?',
      type: 'select',
      options: () => [
        { label: 'Private treaty', value: 'private treaty' },
        { label: 'Public auction', value: 'public auction' },
      ],
      visible: () => isSelling,
      index: 2,
    },
    {
      name: 'statement',
      type: 'statement',
      index: 3,
      label: 'You created a statement',
    },
  ];
};

export const propertiesContinueStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const { propertyAddress, methodOfSale, SectionID, BaseSection, CardIndex } =
    data;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'methodOfSale',
    'propertyIdentifier',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    propertiesContinueQuestions(),
    data,
    hideSensitive
  );

  const { agreedAssetsToSplit } = AssetProperties();

  // had to do this hack to work around not directly having access to the extendID here
  let id: string;
  if (BaseSection && BaseSection === 'properties-continue') {
    id = `properties${CardIndex}`;
  } else if (BaseSection && BaseSection === 'properties') {
    id = SectionID;
  }

  const asset = agreedAssetsToSplit.find(a => a.id === id);
  let receivingPartyName;

  let transferOrSell = 'transfer';
  let sellText: React.ReactNode = '';

  if (SectionID) {
    if (!asset) {
      throw Error('No asset found but it should exist');
    }

    if ('option' in asset && asset.option === TRANSFER_OPTION_SELL) {
      transferOrSell = 'sell';
      sellText = highlight` We will sell it in a ${updated(
        'methodOfSale',
        methodOfSale
      )}.`;
    } else {
      const receivingParty = getReceivingPartyForAsset(asset) as Party;
      receivingPartyName = ` to ${getFirstName(
        matter[`party${receivingParty}`],
        receivingParty,
        hideSensitive
      )}`;
    }
  }

  const identifier = highlight`You have agreed to ${transferOrSell} ${propertyAddress}${receivingPartyName}. The Certificate of Title Folio Identifier number is ${updated(
    'propertyIdentifier',
    redact('propertyIdentifier')
  )}.`;

  if (plainText) {
    return `${identifier}${sellText}`;
  }

  return (
    <>
      {identifier}
      {sellText}
    </>
  );
};
