import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
  PAGE_PROPERTY_ASSET_DIVISION,
  PAGE_PROPERTY_COMPLETE,
  PAGE_PROPERTY_CONTRIBUTIONS,
  PAGE_PROPERTY_MONEY_AND_PROPERTY,
} from 'Common/constants';
import {
  ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
  ODRS_PROPERTY_COMPLETE_ROUTE,
  ODRS_PROPERTY_CONTRIBUTIONS_ROUTE,
  ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
} from 'Common/routes';
import { darkerNoBackground } from 'Common/Utils/theme';
import DashboardProperty from 'App/Pages/DashboardProperty';
import DiviRoute from 'Common/UI/DiviRoute';
import MatterGuard from '../Guard/MatterGuard';

const ODRSDashboardProperty = () => (
  <MatterGuard>
    <Switch>
      <DiviRoute
        path={ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE}
        component={DashboardProperty}
        theme={darkerNoBackground}
        tab={PAGE_PROPERTY_MONEY_AND_PROPERTY}
      />
      <DiviRoute
        path={ODRS_PROPERTY_CONTRIBUTIONS_ROUTE}
        component={DashboardProperty}
        theme={darkerNoBackground}
        tab={PAGE_PROPERTY_CONTRIBUTIONS}
      />
      <DiviRoute
        path={ODRS_PROPERTY_ASSET_DIVISION_ROUTE}
        component={DashboardProperty}
        theme={darkerNoBackground}
        tab={PAGE_PROPERTY_ASSET_DIVISION}
      />
      <DiviRoute
        path={ODRS_PROPERTY_COMPLETE_ROUTE}
        component={DashboardProperty}
        theme={darkerNoBackground}
        tab={PAGE_PROPERTY_COMPLETE}
      />
      <Redirect to={ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE} />
    </Switch>
  </MatterGuard>
);
export default ODRSDashboardProperty;
