import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PageContainer from 'Common/UI/Layout/PageContainer';
import push from 'Common/Utils/push';
import { getCompletedOrders } from 'Common/Utils/Payments';
import { AGREEMENT_PAYMENTS } from 'Common/constants';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { getInvoiceAction } from 'App/State/MatterActions';
import openResolvedPromiseURL from 'Common/Utils/openResolvedPromiseURL';
import { ThemeProvider } from 'styled-components';
import baseTheme from 'Common/Utils/theme';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import PaymentHeader from './PaymentHeader';
import { PaymentProvider } from './PaymentContext';
import { DrawerContent, OrderSummary, PaymentDrawer } from './common';

const PaymentPastPayments = ({ navigate, matter, getInvoice }) => {
  const orders = getCompletedOrders(matter);
  const partyKeys = getPartyKeys();

  const onClickTaxInvoice = receiptNumber =>
    openResolvedPromiseURL(getInvoice(receiptNumber));

  return (
    <ThemeProvider theme={baseTheme}>
      <PaymentProvider navigate={navigate} matter={matter}>
        <PageContainer>
          <PaymentHeader matter={matter} />

          <PaymentDrawer>
            <DrawerContent>
              <Heading2>Past payments</Heading2>

              <Paragraph>
                You have successfully paid for the following document:
              </Paragraph>
              {orders.map(order => (
                <OrderSummary
                  key={order.receiptNumber}
                  order={order}
                  items={AGREEMENT_PAYMENTS.filter(item =>
                    order.skus.includes(item.type)
                  )}
                  showTaxInvoiceLink={order.payingParty === partyKeys.self}
                  onClickTaxInvoice={() =>
                    onClickTaxInvoice(order.receiptNumber)
                  }
                />
              ))}
            </DrawerContent>
          </PaymentDrawer>
        </PageContainer>
      </PaymentProvider>
    </ThemeProvider>
  );
};

PaymentPastPayments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  getInvoice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (route, state, replace) => dispatch(push(route, state, replace)),
  getInvoice: receiptNumber =>
    dispatch(getInvoiceAction(receiptNumber)).unwrap(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentPastPayments);
