import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import BackNextButtons from 'Common/UI/Questions/BackNextButtons';

import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import CheckboxField from 'Common/UI/Form/CheckboxField';
import { InnerContentMiddle } from 'Common/UI/Signup/Layout';
import Actions from 'Common/UI/Actions';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import WhatDoesSeparatedCoupleMeanHelpButton from 'Common/UI/Signup/WhatDoesSeparatedCoupleMeanHelp';

export const StyledParagraph = styled(Paragraph)`
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

export const StyledCheckboxField = styled(CheckboxField)`
  margin: ${props => props.theme.padding.small}px 0 0;
  align-self: flex-start;
`;

const IsAmicaRightForYouButton = styled(GenericButton)`
  display: inline-block;
  margin: ${({ theme }) => theme.spacing[8]} 0;
  text-decoration: none;
`;

const StyledInnerContentMiddle = styled(InnerContentMiddle)`
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  @media (max-height: 1000px) {
    display: block;
  }
`;

const IsAmicaRightForYou = ({ goBack, goNext, onChange }) => {
  const [confirmedRightForYou, setConfirmedRightForYou] = useState(false);
  const [readTerms, setReadTerms] = useState(false);
  const [isQuestionSubmitting, setIsQuestionSubmitting] = useState(false);

  const clickConfirmedRightForYou = value => {
    setConfirmedRightForYou(value);
    onChange('confirmedRightForYou', value);
  };

  const clickReadTerms = value => {
    setReadTerms(value);
    onChange('readTerms', value);
  };

  const onHandleNext = () => {
    setIsQuestionSubmitting(true);
    goNext();
  };

  return (
    <>
      <StyledInnerContentMiddle>
        <ResponsiveTextContainer className="step-transition-fade-up">
          <H1Conversational>
            Check whether amica is right for you
          </H1Conversational>
          <Paragraph>
            amica is suitable for separated couples who are reasonably amicable,
            and whose situation is reasonably straightforward.
          </Paragraph>

          <WhatDoesSeparatedCoupleMeanHelpButton />

          <StyledParagraph>
            It is essential to check that it suits your situation before you
            sign up. We have some quick questions to help you check this.
          </StyledParagraph>

          <IsAmicaRightForYouButton
            reverse
            as={ReferralLink}
            externalLinkIconColour="black"
            href="https://amica.gov.au/is-amica-right-for-me"
            showExternalIcon
          >
            Check if amica is right for you
          </IsAmicaRightForYouButton>

          <Actions noPadding>
            <StyledCheckboxField
              value={confirmedRightForYou}
              name="confirmedRightForYou"
              label={
                <Paragraph>
                  I have checked whether amica is right for my situation
                </Paragraph>
              }
              onChange={updateValue => clickConfirmedRightForYou(updateValue)}
            />

            <StyledCheckboxField
              value={readTerms}
              name="readTerms"
              label={
                <Paragraph>
                  I have read and agree to amica’s{' '}
                  <ReferralLink href="https://www.amica.gov.au/terms-and-conditions.html">
                    Terms of Use
                  </ReferralLink>
                </Paragraph>
              }
              onChange={updateValue => clickReadTerms(updateValue)}
            />
          </Actions>
        </ResponsiveTextContainer>
      </StyledInnerContentMiddle>

      <div>
        <BackNextButtons
          hasPreviousQuestion
          onBack={goBack}
          onNext={onHandleNext}
          isInputValid={
            confirmedRightForYou && readTerms && !isQuestionSubmitting
          }
          nextButtonLabel="Next"
          data-cy="confirm-button"
          className="step-transition-fade-down"
        />
      </div>
    </>
  );
};

IsAmicaRightForYou.propTypes = {
  goNext: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default IsAmicaRightForYou;
