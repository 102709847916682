import React from 'react';
import { createPortal } from 'react-dom';

import formatCurrency from 'Common/Utils/formatCurrency';
import { AssetSplitOption } from 'Common/constants';
import AssetProps from 'Common/Data/App/assetProperties';
import capitaliseText from 'Common/Utils/capitaliseText';
import Loader from 'Common/UI/Loader/Loader';
import FullScreenModal from 'Common/UI/Layout/FullScreenModal';
import { AssetEnhanced } from 'Common/Data/Types/assets';
import getNames from 'Common/Utils/getNames';
import styled from 'styled-components';
import { AssetCard } from '../TransferCards';
import {
  AssetTitle,
  FlexHeadingContainer,
  modalPortal,
  TransferrableAssetWrapper,
  Value,
} from './helpers';

const Highlight = styled.span`
  background: ${props => props.theme.colours.lightOrange};
  cursor: pointer;
`;

const TransferrableAssetBase: React.FC<{
  children: React.ReactNode;
  asset: AssetEnhanced;
  isLoading: boolean;
  selectedOption?: AssetSplitOption;
  isModalVisible: boolean;
  hideModal: () => void;
  handleSelectCard: (
    option: AssetSplitOption,
    options?: { isEditing: boolean }
  ) => void;
}> = ({
  children,
  asset,
  isLoading = false,
  selectedOption,
  isModalVisible,
  hideModal,
  handleSelectCard,
}) => {
  const {
    canStartOrWorkOnOffer,
    owingParty,
    owedParty,
    isSuperIncludedInOffer,
    isOwingParty,
  } = AssetProps();

  const names = getNames();

  const {
    name,
    id,
    value,
    cards = [],
    isSelected,
    type,
    selectedValue = 0,
  } = asset;

  const modalTitle = capitaliseText(asset.type);

  const portal = createPortal(
    <FullScreenModal
      data-cy="modal-asset-transfer"
      show={isModalVisible}
      onClose={hideModal}
      title={modalTitle}
      closeButtonType="icon"
    >
      {children}
    </FullScreenModal>,
    modalPortal
  );

  const transferCards =
    cards &&
    cards.length > 0 &&
    cards.map(card => {
      let cardTitle: React.ReactNode;
      // decided to hard code this as there is only one case where it requires a different title that can't currently be
      // generated by the content generation functions
      if (type === 'superannuation' && isSelected) {
        cardTitle = isOwingParty ? (
          <>
            Transfer{' '}
            <Highlight
              onClick={(_event: any) =>
                handleSelectCard(card.option, { isEditing: true })
              }
              data-cy="superannuation-selected-amount"
            >
              {formatCurrency(selectedValue)}
            </Highlight>{' '}
            of your superannuation to {names[owedParty]}.
          </>
        ) : (
          <>
            You have chosen to transfer{' '}
            <Highlight
              onClick={(_event: any) =>
                handleSelectCard(card.option, { isEditing: true })
              }
              data-cy="superannuation-selected-amount"
            >
              {formatCurrency(selectedValue)}
            </Highlight>{' '}
            of {names[owingParty]}'s superannuation to you.
          </>
        );
      } else {
        cardTitle = card.title;
      }

      const disabled =
        type === 'superannuation'
          ? isSuperIncludedInOffer && !isSelected
          : isSelected && card.option !== selectedOption;

      return (
        <AssetCard
          key={`${asset.id}-${card.option}`}
          selected={card.option === selectedOption}
          disabled={disabled}
          title={cardTitle}
          description={
            card.option === selectedOption
              ? card.selectedDescription
              : card.description
          }
          option={card.option}
          onButtonClick={handleSelectCard}
          canInteract={canStartOrWorkOnOffer}
        />
      );
    });

  return (
    <TransferrableAssetWrapper
      data-cy="transferrable-asset"
      data-cy-id={id}
      data-cy-selected={isSelected}
    >
      <FlexHeadingContainer>
        <AssetTitle>{name}</AssetTitle>
        <Value>{formatCurrency(value)}</Value>
      </FlexHeadingContainer>

      {transferCards}

      {portal}

      {isLoading && <Loader floating />}
    </TransferrableAssetWrapper>
  );
};

export default TransferrableAssetBase;
