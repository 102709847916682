import React from 'react';

import { replaceTextWithChildName } from 'Common/Utils/getChildrenData';
import guessFatherMother from 'Common/Utils/guessFatherMother';
import SchedulePicker from 'Common/UI/Form/SchedulePicker/SchedulePicker';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

// Questions
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getFirstName,
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
} from 'Common/Utils/statementHelpers';
import { PARTY_A, PARTY_B } from 'Common/constants';
import scheduleQuestions, {
  generateScheduleStatement,
} from './scheduleQuestions';
import handoverQuestions from './handoverQuestions';
import generateHandoverStatement from './generateHandoverStatement';

import { plainTextOrElement } from './helpers';

export default childrenData => {
  const textWithChildName = (text, hideSensitive, andOr = 'and') =>
    replaceTextWithChildName(text, childrenData, hideSensitive, andOr);

  const statementTexts = {
    // special occasions
    birthdays:
      "[child] will spend time with the parent when it is that parent's birthday. We will share time or alternate time equally with [child] when it is their birthday.",
    mothersFathersDay:
      'Select who will spend time with [child] on Father’s Day and Mother’s Day',
    schoolHolidayShort:
      "[child] will spend the short school holidays on a week about basis with each parent. We understand that this means the school term time arrangements don't apply during school holidays.",
    schoolHolidayLong:
      '[child] will spend equal time with each parent during long school holidays.',
    christmas:
      'We will share Christmas equally between us and alternate the arrangements each year.',
    easter:
      'We will share the Easter break equally between us and alternate the arrangements each year.',

    // education
    extracurricular:
      "We will notify each other about [child]'s activities and extracurricular events and we agree that each parent will be able to attend these events.",
    parentTeacherInterviews:
      'We will notify each other about parent teacher interviews and school events and we agree that each parent is able to attend these events.',

    // health
    doctorsAppointments:
      'We will notify each other as soon as possible if [child] [has] a medical emergency, illness, or injury.',
    medication:
      'We will keep each other informed about the names and details of any medical professional or health practitioners who treat [child]. We agree that the practitioners can provide information to both of us about the condition and treatment of [child].',

    // communication
    travelling:
      'We both consent to [child] travelling interstate or overseas. If either of us intends to travel with [child] interstate or overseas, we agree that we will notify each other ahead and provide a detailed travel itinerary.',
    expenses:
      "We agree that we will discuss major expenses for [child]'s care and welfare.",
    communicationMethod: 'How would you like to communicate about [child]?',
    communication:
      'We agree that when we have [child] in our care, we will let them contact the other parent if they would like to.',
    cooperating:
      'We will speak positively about each other with [child], and encourage others to do the same.',
    contactDetails:
      'We will keep each other informed about our current address and phone numbers.',

    // religion
    religionAndCulture:
      'We agree to ensure that [child] will be able to practice their religion, and participate in their culture, including attending in any special days related to their culture/religion.',
  };

  return {
    basics: {
      majorDecisions: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(
              'We will communicate with each other and reach agreement before making major decisions about how we raise [child].',
              hideSensitive
            ),
            plainText
          ),
      },
      disputeResolution: {
        statement: ({ plainText = false }) =>
          plainTextOrElement(
            'If we are unable to agree on any aspect of parenting in the future, we agree to attend a family dispute resolution service in the first instance to resolve our differences.',
            plainText
          ),
      },
    },

    scheduling: {
      schedule: {
        statement: ({
          matter,
          data,
          plainText = false,
          hideSensitive = false,
        }) =>
          plainTextOrElement(
            generateScheduleStatement({
              matter,
              data,
              plainText,
              hideSensitive,
            }),
            plainText
          ),
        questions: scheduleQuestions,
        summaryComponentGenerator: ({ values, isActive = false }) => (
          <SchedulePicker showSummaryView values={values} isActive={isActive} />
        ),
        fullComponentGenerator: ({
          onInputChange,
          onSubmit,
          values,
          onClose,
        }) => (
          <SchedulePicker
            onInputChange={onInputChange}
            onSubmit={onSubmit}
            values={values}
            closePicker={onClose}
            isActive
          />
        ),
      },
      handovers: {
        statement: ({
          matter,
          data,
          card,
          plainText = false,
          withUpdateLink,
          hideSensitive = false,
        }) =>
          plainTextOrElement(
            generateHandoverStatement({
              data,
              matter,
              card,
              plainText,
              withUpdateLink,
              hideSensitive,
            }),
            plainText
          ),
        questions: handoverQuestions,
      },
      birthdays: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.birthdays, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'birthday',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.birthdays),
            type: 'includeStatement',
          },
        ],
      },
      mothersFathersDay: (() => {
        const mothersFathersDayQuestions = [
          {
            name: 'mothersFathersDay',
            label: () =>
              `Would you like to make arrangements for Mother's Day and Father's Day?`,
            hint: textWithChildName(statementTexts.mothersFathersDay),
            type: 'includeStatement',
            index: 0,
          },
          {
            name: 'whoMothersDay',
            label: () =>
              textWithChildName("Who will spend Mother's Day with [child]?"),
            type: 'select',
            options: _MatterPropsJS => {
              const fatherMotherParties = guessFatherMother();
              if (fatherMotherParties) {
                const { fatherParty, motherParty } = fatherMotherParties;
                return [
                  {
                    label: _MatterPropsJS(`party${motherParty}.firstname`),
                    value: motherParty,
                  },
                  {
                    label: _MatterPropsJS(`party${fatherParty}.firstname`),
                    value: fatherParty,
                  },
                ];
              }
              return [
                { label: _MatterPropsJS('partyA.firstname'), value: 'A' },
                { label: _MatterPropsJS('partyB.firstname'), value: 'B' },
              ];
            },
            visible: data => data.mothersFathersDay === 'Yes',
            defaultValue: '',
            index: 1,
          },
          {
            name: 'whoFathersDay',
            label: () =>
              textWithChildName("Who will spend Father's Day with [child]?"),
            type: 'select',
            options: _MatterPropsJS => {
              const fatherMotherParties = guessFatherMother();
              if (fatherMotherParties) {
                const { fatherParty, motherParty } = fatherMotherParties;
                return [
                  {
                    label: _MatterPropsJS(`party${fatherParty}.firstname`),
                    value: fatherParty,
                  },
                  {
                    label: _MatterPropsJS(`party${motherParty}.firstname`),
                    value: motherParty,
                  },
                ];
              }
              return [
                { label: _MatterPropsJS('partyA.firstname'), value: 'A' },
                { label: _MatterPropsJS('partyB.firstname'), value: 'B' },
              ];
            },
            visible: data => data.mothersFathersDay === 'Yes',
            defaultValue: '',
            index: 2,
          },
        ];

        return {
          statement: ({
            matter,
            data,
            card,
            plainText = false,
            withUpdateLink = false,
            hideSensitive = false,
          }) => {
            if (
              data.mothersFathersDay &&
              data.whoMothersDay &&
              data.whoFathersDay
            ) {
              const { partyA = {}, partyB = {} } = matter;

              const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
                'whoMothersDay',
                'whoFathersDay',
              ]);
              const highlight = getHighlightStatementValuesFunction(plainText);
              const updated = getWasUpdatedFunction(
                itemDiff,
                plainText,
                withUpdateLink,
                card
              );

              const redact = getRedactFunction(
                mothersFathersDayQuestions,
                data,
                hideSensitive
              );

              const partyNames = {
                A: getFirstName(partyA, PARTY_A, hideSensitive),
                B: getFirstName(partyB, PARTY_B, hideSensitive),
              };

              const motherName = partyNames[data.whoMothersDay] || 'the Mother';
              const fatherName = partyNames[data.whoFathersDay] || 'the Father';
              return plainTextOrElement(
                highlight`${textWithChildName(
                  '[child]',
                  hideSensitive
                )} will spend Mother's Day with ${updated(
                  'whoMothersDay',
                  redact('whoMothersDay', motherName)
                )} and Father's Day with ${updated(
                  'whoFathersDay',
                  redact('whoFathersDay', fatherName)
                )}.`,
                plainText
              );
            }
            return plainTextOrElement(
              textWithChildName(
                statementTexts.mothersFathersDay,
                hideSensitive
              ),
              plainText
            );
          },
          questions: mothersFathersDayQuestions,
        };
      })(),
      schoolHolidayShort: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.schoolHolidayShort, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'schoolHolidayShort',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.schoolHolidayShort),
            type: 'includeStatement',
          },
        ],
      },
      schoolHolidayLong: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.schoolHolidayLong, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'schoolHolidayLong',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.schoolHolidayLong),
            type: 'includeStatement',
          },
        ],
      },
      christmas: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.christmas, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'christmas',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.christmas),
            type: 'includeStatement',
          },
        ],
      },
      easter: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.easter, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'easter',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.easter),
            type: 'includeStatement',
          },
        ],
      },
    },

    additions: {
      extracurricular: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.extracurricular, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'extracurricular',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.extracurricular),
            type: 'includeStatement',
          },
        ],
      },
      parentTeacherInterviews: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(
              statementTexts.parentTeacherInterviews,
              hideSensitive
            ),
            plainText
          ),
        questions: [
          {
            name: 'parentTeacherInterviews',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.parentTeacherInterviews),
            type: 'includeStatement',
          },
        ],
      },
      doctorsAppointments: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(
              statementTexts.doctorsAppointments,
              hideSensitive,
              'or'
            ),
            plainText
          ),
        questions: [
          {
            name: 'doctorsAppointments',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(
              statementTexts.doctorsAppointments,
              false,
              'or'
            ),
            type: 'includeStatement',
          },
        ],
      },
      medication: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.medication, hideSensitive, 'or'),
            plainText
          ),
        questions: [
          {
            name: 'medication',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.medication, false, 'or'),
            type: 'includeStatement',
          },
        ],
      },
      travelling: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.travelling, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'travelling',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.travelling),
            type: 'includeStatement',
          },
        ],
      },
      expenses: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.expenses, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'expenses',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.expenses),
            type: 'includeStatement',
          },
        ],
      },
      communicationMethod: {
        statement: ({ data, plainText = false, hideSensitive = false }) => {
          if (!data.communicationMethod) {
            return plainTextOrElement(
              textWithChildName(
                statementTexts.communicationMethod,
                hideSensitive
              ),
              plainText
            );
          }
          const communicationMethodData = data.communicationMethod;
          const optionValues = Object.keys(communicationMethodData).filter(
            key => data.communicationMethod[key]
          );

          // Note: the number 3 will have to be updated if more multiselect
          // options are added
          let optionValuesString = optionValues.length !== 3 ? 'mainly ' : '';

          optionValuesString +=
            optionValues.length === 1
              ? optionValues[0]
              : `${optionValues
                  .slice(0, optionValues.length - 1)
                  .join(', ')} and ${optionValues.slice(
                  optionValues.length - 1
                )}`;

          return plainTextOrElement(
            textWithChildName(
              `We agree to communicate with each other about [child] by ${optionValuesString}.`,
              hideSensitive
            ),
            plainText
          );
        },
        questions: [
          {
            name: 'communicationMethod',
            label: () =>
              'How would you like to communicate with each other about the children?',
            hint: '',
            type: 'multiselect',
            options: () => [
              { label: 'Telephone', value: 'Telephone' },
              { label: 'Email', value: 'Email' },
              { label: 'SMS', value: 'SMS' },
            ],
          },
        ],
      },
      cooperating: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.cooperating, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'cooperating',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.cooperating),
            type: 'includeStatement',
          },
        ],
      },
      contactDetails: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.contactDetails, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'contactDetails',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.contactDetails),
            type: 'includeStatement',
          },
        ],
      },
      communication: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.communication, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'communication',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.communication),
            type: 'includeStatement',
            help: {
              label: 'I need more information',
              content: (
                <>
                  <H1Conversational>I need more information</H1Conversational>
                  <Paragraph large>
                    Each family has their own way of communicating with each
                    other about the care arrangement for the children. You
                    should think about what works best for you.
                  </Paragraph>
                </>
              ),
            },
          },
        ],
      },
      religionAndCulture: {
        statement: ({ plainText = false, hideSensitive = false }) =>
          plainTextOrElement(
            textWithChildName(statementTexts.religionAndCulture, hideSensitive),
            plainText
          ),
        questions: [
          {
            name: 'religionAndCulture',
            label: () => 'Would you like to include the following statement?',
            hint: textWithChildName(statementTexts.religionAndCulture),
            type: 'includeStatement',
            help: {
              label: 'I need more information',
              content: (
                <>
                  <H1Conversational>I need more information</H1Conversational>
                  <Paragraph large>
                    {`It is in the best interests of the child to consider their cultural or religious needs. 
                    This can include exploring and maintaining connections with their religious, cultural or ancestral 
                    identity. For more information about this see the `}
                    <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
                      Help and Support page.
                    </ReferralLink>
                  </Paragraph>
                </>
              ),
            },
          },
        ],
      },
    },
  };
};
