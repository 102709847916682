import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { ThemeProvider } from 'styled-components';

import defaultTheme, { Theme } from 'Common/Utils/theme';
import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import CheckboxField from 'Common/UI/Form/CheckboxField';
import { TwoButtonRow } from 'Common/UI/Button/ButtonGroup';
import { Heading3 } from 'Common/UI/Text/Headings';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import GenericButton from 'Common/UI/Button/GenericButton';
import Paragraph from 'Common/UI/Text/Paragraph';

const StyledCheckDisclaimer = styled.div<{
  theme: Theme;
}>`
  color: ${({ theme }) => theme.colours.black};
  line-height: 160%;
  margin-left: ${({ theme }) => theme.padding.xxxsmall}px;
  margin-bottom: ${({ theme }) => theme.padding.medium}px;
`;

const StyledFlexDrawer = styled(FlexDrawer)`
  position: fixed;
  z-index: 10;
`;

const optionsDrawerPortal = document.getElementById('optionsDrawer')!;

type Props = {
  isVisible: boolean;
  closeDrawer: () => void;
  setPartyFlags: (flags: Record<string, boolean>) => Promise<void>;
};

const ParentingPlanConfirmPopup: React.FC<Props> = ({
  isVisible,
  closeDrawer,
  setPartyFlags,
}) => {
  const [checked, setChecked] = useState(false);

  return isVisible
    ? createPortal(
        <ThemeProvider theme={defaultTheme}>
          <StyledFlexDrawer isVisible>
            <ResponsiveTextContainer>
              <Heading3>
                Confirm you want to create a parenting plan by adding your
                digital signature
              </Heading3>
              <Paragraph>
                If you wish to convert this agreement to a parenting plan, both
                parties will need to consent to using their digital signature by
                clicking ‘I understand and agree’. Once your former partner
                confirms, you will both be able to download a copy of your final
                parenting plan. You won&apos;t be able to edit or change your
                current answers on amica if you confirm. Parenting plans can be
                changed later if you both agree.
              </Paragraph>
              <CheckboxField
                value={checked}
                name="acceptedTerms"
                label={
                  <StyledCheckDisclaimer>
                    I understand and agree
                  </StyledCheckDisclaimer>
                }
                onChange={() => setChecked(!checked)}
              />

              <TwoButtonRow>
                <GenericButton reverse small onClick={closeDrawer}>
                  Cancel
                </GenericButton>
                <GenericButton
                  small
                  disabled={!checked}
                  onClick={() =>
                    setPartyFlags({ canExportParentingPlan: true }).finally(
                      closeDrawer
                    )
                  }
                >
                  Confirm
                </GenericButton>
              </TwoButtonRow>
            </ResponsiveTextContainer>
          </StyledFlexDrawer>
        </ThemeProvider>,
        optionsDrawerPortal
      )
    : null;
};

export default ParentingPlanConfirmPopup;
