import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  SlideInner,
  SlideText,
  SlideIcon,
} from 'Common/UI/Carousel/ImageSlideWrapper';
import DivisionImage03 from './division-image-3.svg';

const DivisionSlide3 = () => (
  <SlideInner>
    <SlideIcon className="slide-icon" backgroundImage={DivisionImage03}>
      <img alt="" src={DivisionImage03} srcSet={`${DivisionImage03} 2x`} />
    </SlideIcon>
    <SlideText data-cy="suggested-division-slide-3">
      <H2Conversational>Future Needs - Income</H2Conversational>
      <Paragraph>
        Future needs means your future financial needs after separation, taking
        into consideration all the circumstances of the relationship. For
        example if one party earns more than the other party, this may affect
        the overall division.
      </Paragraph>
    </SlideText>
  </SlideInner>
);

export default DivisionSlide3;
