import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';

import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import { Heading2 } from 'Common/UI/Text/Headings';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import theme from 'Common/Utils/theme';
import { TwoButtonResponsiveGroup } from 'Common/UI/Button/ButtonGroup';
import { MediumButton } from 'Common/UI/Button/GenericButton';
import OptionsDrawerPortal from 'Common/UI/OptionsDrawer/OptionsDrawerPortal';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { hideDialog } from 'App/State/AppReducer';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import { MESSAGE_STATUS_UPDATE_DONE } from 'Common/constants';
import { definitelyGetCard } from 'Common/Data/App/appSections';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { CardYourSuperannuation } from 'Common/Data/Types/appSections';
import DialogCancelSuperannuationBalanceRequestContent from './DialogCancelSuperannuationBalanceRequest.content';

const StyledHeading = styled(Heading2)`
  margin-top: 0;
`;

const StyledFlexDrawer = styled(FlexDrawer)`
  position: fixed;
`;

const DialogCancelSuperannuationBalanceRequest: React.FC = () => {
  const { withLoading } = usePromiseWithLoading();

  const dispatch = useAppDispatch();

  const closeDrawer = () => dispatch(hideDialog());

  const { dialogData: cardID } = useAppSelector(state => state.app);

  if (!cardID) {
    console.warn('No card ID passed to dialog');

    closeDrawer();

    return null;
  }

  let card: CardYourSuperannuation;

  try {
    card = definitelyGetCard<CardYourSuperannuation>(cardID as string);
  } catch (e) {
    closeDrawer();

    return null;
  }

  const { cardIDAsArray, data } = card;

  const selfParty = getPartyKeys().self;

  const onRemove = () =>
    withLoading(async () => {
      const newCardData = {
        ...data,
        __flags: {
          superBalanceRequested: false,
          letterReceived: false,
          letterUploaded: false,
          cancelledRequest: true,
        },
      };

      const messageData = {
        section: cardIDAsArray,
        owner: selfParty,
        message: '',
        status: MESSAGE_STATUS_UPDATE_DONE,
        checkTone: false,
      };

      await dispatch(
        updateMatterSectionAction({
          section: cardIDAsArray,
          sectionData: newCardData,
          messageData,
        })
      );

      closeDrawer();
    });

  return (
    <OptionsDrawerPortal isOpen onBackgroundClick={closeDrawer}>
      <ThemeProvider theme={theme}>
        <StyledFlexDrawer isVisible dataCy="cancel-balance-request-drawer">
          <FlexGrid align="center" medium={2}>
            <div>
              <StyledHeading>
                {DialogCancelSuperannuationBalanceRequestContent.heading}
              </StyledHeading>
              <Paragraph>
                {DialogCancelSuperannuationBalanceRequestContent.paragraph}
              </Paragraph>
            </div>
            <OffsetColumn>
              <TwoButtonResponsiveGroup>
                <MediumButton subtle onClick={closeDrawer} data-cy="button-no">
                  {DialogCancelSuperannuationBalanceRequestContent.noButton}
                </MediumButton>
                <MediumButton onClick={onRemove} data-cy="button-yes">
                  {DialogCancelSuperannuationBalanceRequestContent.yesButton}
                </MediumButton>
              </TwoButtonResponsiveGroup>
            </OffsetColumn>
          </FlexGrid>
        </StyledFlexDrawer>
      </ThemeProvider>
    </OptionsDrawerPortal>
  );
};

export default DialogCancelSuperannuationBalanceRequest;
