import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSVehicles = props => (
  <MatterGuard>
    <CardQuestions baseID="vehicles" indexFromParams {...props} />
  </MatterGuard>
);

export default ODRSVehicles;
