import React from 'react';
import PropTypes from 'prop-types';

import GenericButton from 'Common/UI/Button/GenericButton';
import Paragraph from 'Common/UI/Text/Paragraph';
import { DASHBOARD_TAB_LOCKED, Sections } from 'Common/constants';
import { getPage } from 'Common/Data/App/appSections';
import Matter from 'Common/Data/Types/matter';
import { DataPage } from 'Common/Data/Types/appSections';

export const dashboardTabLockedFlags = {
  id: 'dashboardTabLocked',
  description: 'Dashboard tab is locked',
  check: (matter: Matter, section?: Sections, page?: string) => {
    if (!section || !page) {
      return false;
    }

    const pageData = getPage(section, page);

    if (!pageData) {
      return false;
    }

    const { generateStatus } = pageData;

    if (!generateStatus) {
      return false;
    }

    const status = generateStatus(matter);

    if (status === 'locked') {
      return true;
    }

    return false;
  },
  flags: {
    appState: DASHBOARD_TAB_LOCKED,
  },
};

type Props = {
  navigate: (route: string) => void;
  firstInProgressPageInSection?: DataPage;
};

const DashboardTabLocked: React.FC<Props> = ({
  navigate,
  firstInProgressPageInSection,
}) => (
  <div data-cy="dashboard-tab-locked-banner">
    <Paragraph large>
      <strong>
        You will need to agree on all your statements before you can get started
        on this section.
      </strong>
    </Paragraph>
    {firstInProgressPageInSection && (
      <GenericButton
        fullWidth
        onClick={() => navigate(firstInProgressPageInSection.route)}
        small
      >
        Go to {firstInProgressPageInSection.title}
      </GenericButton>
    )}
  </div>
);

DashboardTabLocked.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default DashboardTabLocked;
