import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { hasItemBeenPaidFor, isAmicaFree } from 'Common/Utils/Payments';
import formatCurrency from 'Common/Utils/formatCurrency';
import {
  DOC_TYPE_CONSENT_ORDERS,
  DOC_TYPE_PARENTING_AGREEMENT,
  AGREEMENT_PAYMENTS_LUT,
  DOC_TYPE_PROPERTY_AGREEMENT,
} from 'Common/constants';
import {
  Heading2,
  Heading3,
  HeadingSpacedContainer,
} from 'Common/UI/Text/Headings';
import Paragraph, { FormalInformalText } from 'Common/UI/Text/Paragraph';

const PriceCardPropTypes = {
  matter: PropTypes.shape({}).isRequired,
};

const Wrapper = styled.div`
  display: block;
  padding: ${props => props.theme.padding.small}px
    ${props => props.theme.padding.xsmall}px;
  margin-top: ${({ theme }) => theme.padding.medium}px;
  border: 2px solid ${props => props.theme.colours.inactiveGrey};
  border-radius: 6px;
  margin-bottom: ${({ theme }) => theme.padding.medium}px;

  > *:first-child {
    margin-top: 0;
  }
`;

const Divider = styled.div`
  width: 100%;
  margin: ${props => props.theme.padding.medium}px 0;
  border-top: 1px solid ${({ theme }) => theme.colours.lightGrey};
`;

const StyledPrice = styled(Paragraph).attrs(() => ({
  large: true,
}))`
  color: ${({ theme, color }) => theme.colours[color] || theme.textColour};
`;

const NoExtraCostLabel = styled(Paragraph)`
  color: ${({ theme }) => theme.colours.midDarkGrey};
`;

export const PropertyPriceCard = ({ matter }) => {
  let propertyAgreementPriceText;
  let consentOrderPriceText;

  if (!isAmicaFree(matter)) {
    propertyAgreementPriceText = hasItemBeenPaidFor(
      matter,
      DOC_TYPE_PROPERTY_AGREEMENT
    )
      ? 'Paid'
      : formatCurrency(
          AGREEMENT_PAYMENTS_LUT[DOC_TYPE_PROPERTY_AGREEMENT].price
        );

    consentOrderPriceText = hasItemBeenPaidFor(matter, DOC_TYPE_CONSENT_ORDERS)
      ? 'Paid'
      : formatCurrency(AGREEMENT_PAYMENTS_LUT[DOC_TYPE_CONSENT_ORDERS].price);
  }

  return (
    <Wrapper>
      <Heading2>Property</Heading2>
      <Paragraph large>
        You have the option to create a property agreement, or create an
        application for consent orders, or both.
      </Paragraph>

      <Divider />

      <HeadingSpacedContainer>
        <FormalInformalText>Informal</FormalInformalText>

        {propertyAgreementPriceText && (
          <StyledPrice color="darkOrange">
            {propertyAgreementPriceText}
          </StyledPrice>
        )}
      </HeadingSpacedContainer>
      <Heading3>Property Agreement</Heading3>

      <Paragraph>
        Creating a property agreement allows you to create a written record of
        the agreement you&apos;ve reached using amica.
      </Paragraph>
      <Paragraph>
        This will provide you both with an immediate written agreement which
        clearly sets out what you have agreed.
      </Paragraph>

      <Divider />

      <HeadingSpacedContainer>
        <FormalInformalText>Formal</FormalInformalText>

        {consentOrderPriceText && (
          <StyledPrice color="darkOrange">{consentOrderPriceText}</StyledPrice>
        )}
      </HeadingSpacedContainer>
      <Heading3>Application for consent orders</Heading3>

      <Paragraph>
        You can apply to the courts to make orders reflecting what you have
        agreed. You can use amica to create your court application documents.{' '}
      </Paragraph>
    </Wrapper>
  );
};

PropertyPriceCard.propTypes = PriceCardPropTypes;

export const ParentingPriceCard = ({ matter }) => {
  let parentingPlanPriceText;

  if (!isAmicaFree(matter)) {
    parentingPlanPriceText = hasItemBeenPaidFor(
      matter,
      DOC_TYPE_PARENTING_AGREEMENT
    )
      ? 'Paid'
      : formatCurrency(
          AGREEMENT_PAYMENTS_LUT[DOC_TYPE_PARENTING_AGREEMENT].price
        );
  }

  return (
    <Wrapper>
      <Heading2>Parenting</Heading2>
      <Paragraph large>
        You have the option to create a parenting agreement, a parenting plan,
        or both.
      </Paragraph>

      <Divider />

      <HeadingSpacedContainer>
        <FormalInformalText>Informal</FormalInformalText>

        {parentingPlanPriceText && (
          <StyledPrice color="darkOrange">{parentingPlanPriceText}</StyledPrice>
        )}
      </HeadingSpacedContainer>
      <Heading3>Parenting Agreement</Heading3>

      <Paragraph>
        Creating a parenting agreement document gives you a written record of
        the parenting arrangements that you&apos;ve agreed on amica.
      </Paragraph>
      <Paragraph>
        This will provide you both with an immediate written agreement which
        clearly sets out what you have agreed.
      </Paragraph>

      <Divider />

      <HeadingSpacedContainer>
        <FormalInformalText>Informal</FormalInformalText>

        <NoExtraCostLabel>No extra cost</NoExtraCostLabel>
      </HeadingSpacedContainer>
      <Heading3>Parenting Plan</Heading3>

      <Paragraph>
        You will also have the option to convert your parenting agreement into a
        parenting plan which has more legal weight.
      </Paragraph>
    </Wrapper>
  );
};

ParentingPriceCard.propTypes = PriceCardPropTypes;
