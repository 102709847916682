import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import { STATUS_INCOMPLETE } from 'Common/constants';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';
import PageFooter from 'Common/UI/Layout/PageFooter';
import { Success } from 'Common/UI/Layout/Completed';
import { H1Conversational } from 'Common/UI/Text/Headings';
import { CloseButtonQuestionsHeader } from 'Common/UI/Button/CloseButton';
import { passwordResetQuestions } from 'Common/Data/App/20200101/Questions/passwordReset';
import QuestionsFlowWrapper from 'App/UI/Questions/QuestionsFlowWrapper';
import {
  QuestionFlowHeader,
  StyledPageContent,
} from 'Common/UI/Questions/common';
import ProgressBar from 'Common/UI/ProgressBar';

type Props = {
  navigate: (route: string) => void;
};

const PasswordReset: React.FC<Props> = () => {
  const [questionsInput, setQuestionsInput] = useState({});
  const [showCompleteScreen, setShowCompleteScreen] = useState(false);
  const {
    state: { redirect, previous: previousRoute = ODRS_DASH_HOME_ROUTE } = {},
  } = useLocation<{ redirect?: string; previous?: string }>();

  const onChange = (key: string, value: any) => {
    const newInput = {
      ...questionsInput,
      [key]: value,
      status: STATUS_INCOMPLETE,
    };

    setQuestionsInput(newInput);
  };

  if (showCompleteScreen) {
    return (
      <Success buttonRoute={redirect || previousRoute} buttonLabel="Done">
        <H1Conversational>
          Your password has been successfully reset.
        </H1Conversational>
      </Success>
    );
  }

  return (
    <ProgressBarProvider>
      <QuestionFlowHeader>
        <CloseButtonQuestionsHeader as={Link} to={previousRoute}>
          &nbsp;&nbsp;Close
        </CloseButtonQuestionsHeader>
      </QuestionFlowHeader>
      <StyledPageContent>
        <QuestionsFlowWrapper
          title="Reset Password"
          questions={passwordResetQuestions}
          onInputChange={onChange}
          isUpdating={false}
          values={questionsInput}
          onSubmit={() => {
            setShowCompleteScreen(true);
            return Promise.resolve();
          }}
        />
      </StyledPageContent>
      <PageFooter>
        <ProgressBar
          label="Reset password"
          steps={passwordResetQuestions.length}
          currentStep={0}
        />
      </PageFooter>
    </ProgressBarProvider>
  );
};

export default PasswordReset;
