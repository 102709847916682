import {
  BANNER_HIDE,
  BANNER_SHOW,
  CONTENT_SHOW,
  BANNER_GENERIC,
  DashboardPages,
  Sections,
  SECTIONS,
  BANNER_GENERIC_CARDS_UNLOCKED,
  SECTION_PROPERTY,
  SECTION_RELATIONSHIP,
  SECTION_PARENTING,
  SECTION_CONSENT_ORDERS,
} from 'Common/constants';

import Matter from 'Common/Data/Types/matter';
import {
  AppState,
  FullAppState,
  GeneratedAppState,
} from 'Common/Data/Types/appState';
import { hasSectionBeenStarted } from 'App/Utils/sectionHelpers';
import home from './Sections/home';
import property from './Sections/property';
import relationship from './Sections/relationship';
import parenting from './Sections/parenting';
import consentOrders from './Sections/consentOrders';

/**
 * Generate App State
 * -----
 *  The function generates an appState depending on what matter data is present
 *  for a user. The appState controls
 *  - Banner State - Whether visible at the top / bottom and what content to show
 *  - Content  - Whether visible
 *  - Card
 *      - Whether cards are active on a given page
 *      - Whether you can add optional cards in a given page
 *
 *  There are three scopes to set app state for - all of a page, all of a section, or global
 *  If multiple things are matched, the first match will take priority e.g. if there is
 *  a matching section and page state, only the page state will be used
 * */

export const appStatesMap: FullAppState = {
  allSectionsDefault: [
    // If any states that are common between the pages
  ],
  ...home,
  ...relationship,
  ...property,
  ...parenting,
  ...consentOrders,
  genericCardsUnlocked: {
    id: 'bannerGenericCardsUnlocked',
    description:
      'Generic banner - doesnt render anything but allows cards to be edited/added',
    check: (matter: Matter, section?: string) => {
      // fixes bug in https://portableaustralia.atlassian.net/browse/DIVI-1782 which occurs when users have
      // finished asset split and can re-open property cards
      // this is caused because we were having the generic catch-all app state allowing users to edit cards so if
      // we had a gap in our app state it would fall back to the generic one and allow cards to be edited
      // now, the generic one does not allow editing and we have to specifically allow editing in this state
      // note that this is only used if no other app state matches - cards may still be/not be editable in dashboards
      // depending on other app states that get triggered before we reach this one

      switch (section) {
        case SECTION_PROPERTY:
          // return false if either party has started the suggestion division flow
          return (
            !matter.self.hasConfirmedStatements ||
            !matter.other.hasConfirmedStatements
          );
        case SECTION_RELATIONSHIP:
          return !(
            hasSectionBeenStarted({
              section: SECTION_PROPERTY,
              checkBothParties: true,
            }) ||
            hasSectionBeenStarted({
              section: SECTION_PARENTING,
              checkBothParties: true,
            })
          );
        case SECTION_PARENTING:
        case SECTION_CONSENT_ORDERS:
          // we already have solid checks in the parenting and consent order app state to make sure users can't edit
          // cards at the wrong time so we want to allow editing if we get here
          return true;
        default:
          return false;
      }
    },
    flags: {
      appState: BANNER_GENERIC_CARDS_UNLOCKED,
      bannerTopState: BANNER_HIDE,
    },
  },
  generic: {
    id: 'bannerGeneric',
    description: 'Generic banner - doesnt render anything',
    check: () => true,
    flags: {
      appState: BANNER_GENERIC,
      bannerTopState: BANNER_HIDE,
    },
  },
};

export const generatePageAppStateMap = (
  section: Sections,
  page?: DashboardPages
) => {
  if (!SECTIONS.includes(section)) {
    return appStatesMap.allSectionsDefault;
  }

  const { pageBlock: sectionPageBlock = [], state: sectionState = [] } =
    appStatesMap[section] || {};

  if (!page) {
    return [
      ...sectionPageBlock,
      ...sectionState,
      ...appStatesMap.allSectionsDefault,
      appStatesMap.genericCardsUnlocked,
      appStatesMap.generic,
    ];
  }

  const { pageBlock: pagePageBlock = [], state: pageState = [] } =
    appStatesMap[page] || {};

  return [
    ...pagePageBlock,
    ...sectionPageBlock,
    ...pageState,
    ...sectionState,
    ...appStatesMap.allSectionsDefault,
    appStatesMap.genericCardsUnlocked,
    appStatesMap.generic,
  ];
};

export const defaultFlags: GeneratedAppState = {
  appState: BANNER_GENERIC,
  bannerTopState: BANNER_SHOW,
  bannerBottomState: BANNER_HIDE,
  contentState: CONTENT_SHOW,
};

/**
 * Generate app notification states
 *
 * This differs from generateAppState above as generateAppState returns maximum one app state depending on the section and page, whereas generateNotificationStates
 * returns any active states that are marked as being used as a notification state and is not dependent on the current section/page
 * @param matter
 * @returns array of app states
 */
export const generateNotificationStates = (matter: Matter): string[] => {
  const { generic, ...states } = appStatesMap;

  // discard the `pageBlock` bits and build a big array from all possible app states
  const appStateMap = Object.entries(states).reduce(
    (acc: AppState[], [_key, data]) => {
      if (data && data.state) {
        return [
          ...acc,
          ...data.state.filter(
            (state: AppState) => state.usedAsNotificationState
          ),
        ];
      }

      return acc;
    },
    []
  );

  const appData = appStateMap
    .filter(row => row.check(matter))
    .map(state => state.flags.appState);

  return appData;
};
