import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import MatterProps from 'Common/Utils/MatterProps';
import DiviProps from 'Common/Data/App/diviProperties';
import {
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_DIVISION_AGREE_ROUTE,
  ODRS_DIVISION_AGREE_WITH_OTHER_ROUTE,
  ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
} from 'Common/routes';
import { H1Conversational } from 'Common/UI/Text/Headings';

import { blackTheme } from 'Common/Utils/theme';
import FlexScroller from 'Common/UI/Layout/FlexScroller';
import Loader from 'Common/UI/Loader/Loader';
import {
  sendNegotiationAction,
  sendAssessmentEmailAction,
} from 'App/State/MatterActions';

import DiviSuggestion from './Components/DiviSuggestion';
import DivisionHelpAndSupport from './DivisionHelpAndSupport';

import { SCREEN_STATE_VIEW } from './constants';
import {
  DetailsViewHeader,
  StyledNavButton,
  DetailsViewWrapper,
} from '../DetailsView';
import DialogSingleButton from './Dialog/DialogSingleButton';
import DialogAgreeToOffer from './Dialog/DialogAgreeToOffer';
import buildExport from 'Common/Utils/exportBuilder';

const StyledDetailsViewHeader = styled(DetailsViewHeader)`
  border-bottom: 0;
  flex: 0;
  min-height: auto;

  background-color: ${({ state, theme }) =>
    state === SCREEN_STATE_VIEW
      ? theme.backgroundColour
      : theme.colours.offWhite};
`;

const StyledFlexScroller = styled(FlexScroller)`
  ${({ marginBottom }) => marginBottom && `margin-bottom:${marginBottom}px;`};
  padding: 16px 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: 40px;
  }
`;

const DivisionSuggested = ({
  navigate,
  matter,
  sendNegotiation,
  sendAssessmentEmail,
}) => {
  const { hasOtherPartyAcceptedSuggestion } = DiviProps();

  const [dialog, setDialog] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const acceptOfferFromDivi = () => {
    const handleSendAssessmentEmail = () => {
      // we need to get it here otherwise it wont get refreshed with the new data
      const { agreedToDivision } = DiviProps();

      if (agreedToDivision) {
        return sendAssessmentEmail(buildExport(matter, true)).then(
          () => agreedToDivision
        );
      }

      return Promise.resolve(agreedToDivision);
    };

    setIsLoading(true);

    sendNegotiation({ isAgreement: true })
      .then(() => handleSendAssessmentEmail())
      .then(agreed => {
        setIsLoading(false);
        navigate(
          agreed
            ? ODRS_DIVISION_AGREE_WITH_OTHER_ROUTE
            : ODRS_DIVISION_AGREE_ROUTE
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setDialog('DialogViewOffer');
    }, 1000);
  }, []);

  return (
    <DetailsViewWrapper>
      <StyledDetailsViewHeader state={SCREEN_STATE_VIEW}>
        <H1Conversational>Suggested division</H1Conversational>
        <StyledNavButton
          state={SCREEN_STATE_VIEW}
          type="close"
          onClick={() => navigate(ODRS_PROPERTY_ASSET_DIVISION_ROUTE)}
        />
      </StyledDetailsViewHeader>

      <StyledFlexScroller marginBottom={dialog === null ? 0 : 200}>
        <DiviSuggestion
          matter={matter}
          screenState={SCREEN_STATE_VIEW}
          onUpdateOffer={() => {}}
          showSuggested
        />

        {!hasOtherPartyAcceptedSuggestion && <DivisionHelpAndSupport />}

        {hasOtherPartyAcceptedSuggestion ? (
          <DialogAgreeToOffer
            isOfferFromDivi
            hasOtherPartyAcceptedSuggestion
            showDialog={dialog === 'DialogViewOffer'}
            onAccept={acceptOfferFromDivi}
            onNegotiate={() => navigate(ODRS_DIVISION_SPLIT_VIEW_ROUTE)}
          />
        ) : (
          <DialogSingleButton
            theme={blackTheme}
            showDialog={dialog === 'DialogViewOffer'}
            prompt={`${MatterProps(
              'other.firstname'
            )} has viewed this suggested offer and has negotiated their own offer. You will also be able to negotiate and make an offer.`}
            onClick={() => navigate(ODRS_DIVISION_SPLIT_VIEW_ROUTE)}
            buttonLabel={`View ${MatterProps('other.firstname')}'s offer`}
            dataCy="button-view-other-offer"
          />
        )}
      </StyledFlexScroller>
      {isLoading && <Loader floating opacity={1} />}
    </DetailsViewWrapper>
  );
};

DivisionSuggested.propTypes = {
  matter: PropTypes.object, // eslint-disable-line
  navigate: PropTypes.func.isRequired,
  sendNegotiation: PropTypes.func.isRequired,
  sendAssessmentEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
  sendNegotiation: details => dispatch(sendNegotiationAction(details)),
  sendAssessmentEmail: content => dispatch(sendAssessmentEmailAction(content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DivisionSuggested);
