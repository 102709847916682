import {
  FLAG_MATTER_OPTED_IN,
  FLAG_MATTER_OPTED_IN_DEFAULT,
  SECTION_STATUS_COMPLETED,
  SECTION_STATUS_IN_PROGRESS,
  SECTION_STATUS_LOCKED,
  SECTION_STATUS_NOT_STARTED,
  SECTION_STATUS_OPT_IN,
  SectionStatus,
  Agreements,
  AGREEMENT_CREATED,
  AGREEMENT_INCOMPLETE,
  PARENTING_DELETABLE_CARDS,
  STATUS_APPROVED,
  STATUS_REMOVED,
  Sections,
  DOC_TYPE_PROPERTY_AGREEMENT,
  DOC_TYPE_PARENTING_AGREEMENT,
  DOC_TYPE_PARENTING_PLAN,
  DOC_TYPE_CONSENT_ORDERS,
  AgreementStatus,
  DOC_TYPE_MINUTES_OF_CONSENT,
  DOC_TYPE_CONSENT_ORDERS_DRAFT,
  DOC_TYPE_MINUTES_OF_CONSENT_DRAFT,
  DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER,
} from 'Common/constants';
import {
  getSectionCardStats,
  SectionCardTally,
} from 'Common/Utils/cardHelpers';
import { getAppSections } from 'Common/Data/App/appSections';
import getDocumentExportStatus from 'Common/Utils/getDocumentExportStatus';
import {
  canExportConsentOrdersKey,
  notReadyToExportConsentOrdersKey,
  checkPageUnLocked,
} from 'Common/Utils/consentOrderPageChecks';
import {
  areSettlementAssetsAllAccountedFor,
  isParentingPlanBasicsAgreed,
  isParentingScheduleAgreed,
} from 'App/Utils/matterHelpers';
import diviProperties from 'Common/Data/App/diviProperties';

import MatterProps from 'Common/Utils/MatterProps';
import AssetProps from 'Common/Data/App/assetProperties';
import Matter from 'Common/Data/Types/matter';
import { isPaymentDue } from 'Common/Utils/Payments';

// to make this backwards compatible, we can't rely only on DB flags because old accounts wont have them
// so we need to also check if any cards have been started
export const hasOptedInToSection = (section: string) => {
  const optedIn = MatterProps(
    `flags.${FLAG_MATTER_OPTED_IN}`,
    FLAG_MATTER_OPTED_IN_DEFAULT
  );

  if (optedIn[section]) {
    return true;
  }

  const { stats } = getSectionCardStats({
    appSections: getAppSections(),
    section,
  });

  return stats.inProgress > 0 || stats.completed > 0;
};

// the `customChecks` props lets us pass in custom checks for some combinations of cards and status checks
// this is needed because some cards have different checks, e.g. property needs to check if asset split and
// suggested division have been completed as well as all of the cards
export const getSectionStatus = ({
  section,
  customChecks = {},
  checkBothParties,
}: {
  section: string;
  customChecks?: any;
  checkBothParties?: boolean;
}): {
  status: SectionStatus;
  stats: SectionCardTally;
  progress?: number;
} => {
  const { completed, stats } = getSectionCardStats({
    appSections: getAppSections(),
    section,
    checkBothParties,
  });

  const progress = (stats.completed / (stats.total - stats.removed)) * 100;

  if (
    !hasOptedInToSection(section) &&
    (customChecks.optIn ? customChecks.optIn() : true)
  ) {
    return {
      status: SECTION_STATUS_OPT_IN,
      stats,
    };
  }

  if (completed && (customChecks.completed ? customChecks.completed() : true)) {
    return {
      status: SECTION_STATUS_COMPLETED,
      stats,
      progress,
    };
  }

  if (customChecks.locked && customChecks.locked()) {
    return {
      status: SECTION_STATUS_LOCKED,
      stats,
      progress,
    };
  }

  if (stats.inProgress > 0 || stats.completed > 0) {
    return {
      status: SECTION_STATUS_IN_PROGRESS,
      stats,
      progress,
    };
  }

  return {
    status: SECTION_STATUS_NOT_STARTED,
    stats,
    progress,
  };
};

// checks if a section (dashboard) has been started (including if it's been completed)
export const hasSectionBeenStarted = ({
  section,
  customChecks = {},
  checkBothParties,
}: {
  section: string;
  customChecks?: any;
  checkBothParties?: boolean;
}): boolean => {
  const sectionStatus = getSectionStatus({
    section,
    customChecks,
    checkBothParties,
  });

  return (
    sectionStatus.status === SECTION_STATUS_COMPLETED ||
    sectionStatus.status === SECTION_STATUS_IN_PROGRESS
  );
};

export const hasSectionBeenCompleted = ({
  section,
  customChecks,
  checkBothParties = true,
}: {
  section: string;
  customChecks?: any;
  checkBothParties?: boolean;
}): boolean => {
  const sectionStatus = getSectionStatus({
    section,
    customChecks,
    checkBothParties,
  });

  return sectionStatus.status === SECTION_STATUS_COMPLETED;
};

export const getPageStatus = ({
  section,
  page,
}: {
  section: Sections;
  page: string;
}) =>
  getSectionCardStats({
    appSections: getAppSections(),
    section,
    page,
  });

export const getPropertyState = (): AgreementStatus => {
  const allPropertyComplete = areSettlementAssetsAllAccountedFor();
  const { agreedToDivision } = diviProperties();
  const { isAssetSplitCompleted } = AssetProps();

  return getDocumentExportStatus({
    canExport: allPropertyComplete && agreedToDivision && isAssetSplitCompleted,
    exportFlag: MatterProps('self.canExportProperty', false),
    otherExportFlag: MatterProps('other.canExportProperty', false),
    notReadyFlag: MatterProps('self.notReadyToExportProperty', false),
    otherNotReadyFlag: MatterProps('other.notReadyToExportProperty', false),
  });
};

export const getParentingState = ({
  matter,
}: {
  matter: Matter;
}): AgreementStatus => {
  const parentingBasicsAgreed = isParentingPlanBasicsAgreed();
  const parentingScheduleCompleted = isParentingScheduleAgreed(matter);
  // get all of the open optional cards
  const openOptionalCards = matter.items.filter(
    item =>
      PARENTING_DELETABLE_CARDS.includes(item.BaseSection) &&
      item.status !== STATUS_APPROVED &&
      item.status !== STATUS_REMOVED
  );
  return getDocumentExportStatus({
    canExport:
      parentingBasicsAgreed &&
      parentingScheduleCompleted &&
      openOptionalCards.length === 0,
    exportFlag: MatterProps('self.canExportParenting', false),
    otherExportFlag: MatterProps('other.canExportParenting', false),
    notReadyFlag: MatterProps('self.notReadyToExportParenting', false),
    otherNotReadyFlag: MatterProps('other.notReadyToExportParenting', false),
  });
};

export const getNewParentingPlanState = ({
  matter,
}: {
  matter: Matter;
}): AgreementStatus => {
  const parentingState = getParentingState({ matter });

  // if the parenting agreement has not been created yet then this one is incomplete
  if (
    parentingState !== AGREEMENT_CREATED ||
    isPaymentDue(matter, DOC_TYPE_PARENTING_AGREEMENT)
  ) {
    return AGREEMENT_INCOMPLETE;
  }

  return getDocumentExportStatus({
    canExport:
      MatterProps('self.canExportParenting', false) &&
      MatterProps('other.canExportParenting', false),
    exportFlag: MatterProps('self.canExportParentingPlan', false),
    otherExportFlag: MatterProps('other.canExportParentingPlan', false),
    notReadyFlag: MatterProps('self.notReadyToExportParentingPlan', false),
    otherNotReadyFlag: MatterProps(
      'other.notReadyToExportParentingPlan',
      false
    ),
  });
};

export const getNewParentingPlanSignedState = () =>
  MatterProps('self.canExportParentingPlan', false) &&
  MatterProps('other.canExportParentingPlan', false);

export const getConsentOrderExportState = (): AgreementStatus =>
  getDocumentExportStatus({
    canExport: checkPageUnLocked.completed.unlocked(),
    exportFlag: MatterProps(`self.${canExportConsentOrdersKey}`, false),
    otherExportFlag: MatterProps(`other.${canExportConsentOrdersKey}`, false),
    notReadyFlag: MatterProps(
      `self.${notReadyToExportConsentOrdersKey}`,
      false
    ),
    otherNotReadyFlag: MatterProps(
      `other.${notReadyToExportConsentOrdersKey}`,
      false
    ),
  });

export const getActiveAgreementState = (
  activeAgreement: Agreements,
  matter: Matter
) => {
  const activeAgreementState: {
    [key in Agreements]: (matter: { matter: Matter }) => AgreementStatus;
  } = {
    [DOC_TYPE_PROPERTY_AGREEMENT]: getPropertyState,
    [DOC_TYPE_PARENTING_AGREEMENT]: getParentingState,
    [DOC_TYPE_PARENTING_PLAN]: getNewParentingPlanState,
    [DOC_TYPE_CONSENT_ORDERS]: getConsentOrderExportState,
    [DOC_TYPE_MINUTES_OF_CONSENT]: getConsentOrderExportState,
    [DOC_TYPE_CONSENT_ORDERS_DRAFT]: getConsentOrderExportState,
    [DOC_TYPE_MINUTES_OF_CONSENT_DRAFT]: getConsentOrderExportState,
    [DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER]: getConsentOrderExportState,
  };
  return activeAgreementState[activeAgreement]({ matter }) || undefined;
};

export const hasExportParentingBeenInitiated = () =>
  MatterProps('self.canExportParenting', false) ||
  MatterProps('other.canExportParenting', false);
