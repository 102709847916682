import { combineReducers } from 'redux';
import { configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory, History } from 'history';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import OdieReducer from './OdieReducer';
import MatterReducer from './MatterReducer';
import AppReducer from './AppReducer';
import UserReducer from './UserReducer';

// eslint-disable-next-line import/no-mutable-exports
let history: History;

if (process.env.NODE_ENV as string === 'localdocgen') {
  history = createMemoryHistory();
} else {
  history = createBrowserHistory();
}

export { history };

const middleware = routerMiddleware(history);

const reducer = combineReducers({
  odie: OdieReducer,
  matter: MatterReducer,
  app: AppReducer,
  user: UserReducer,
  router: connectRouter(history),
});

export const reduxStore = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck:
        process.env.NODE_ENV === 'test'
          ? false
          : {
              ignoredPaths: ['odie', 'app'],
              ignoredActions: ['odie/updateOdie', 'app/updateAppState'],
            },
    }).concat(middleware),
});

export type RootState = ReturnType<typeof reducer>;

export type AppDispatch = typeof reduxStore.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const createAppAsyncThunk =
  createAsyncThunk.withTypes<{
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }>();
