import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ODRS_PROFILE_ROUTE } from 'Common/routes';

import { TopMessageDrawer } from 'Common/UI/Layout/Drawer';
import NudgeBackgroundShapes from 'Common/Assets/images/nudge-background-shapes.png';
import CloseIcon from 'Common/Assets/images/icon-close-orange.svg';
import ImageButton from 'Common/UI/Button/ImageButton';
import { localStorageLoad, localStorageSave } from 'Common/Utils/localStorage';
import Paragraph from 'Common/UI/Text/Paragraph';
import { orangeTheme } from 'Common/Utils/theme';
import GenericButton from 'Common/UI/Button/GenericButton';
import { getUserAttributesAction } from 'App/State/UserActions';

const StyledGenericButton = styled(GenericButton)`
  display: inline-block;
  text-decoration: none;
  flex: 0;
  margin-top: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin-top: 0;
  }
`;

const StyledTopMessageDrawer = styled(TopMessageDrawer)`
  background: ${({ theme }) => theme.topMessageColour}
    url('${NudgeBackgroundShapes}') no-repeat top right;
`;

const StyledParagraph = styled(Paragraph)`
  margin-right: ${({ theme }) => theme.spacing[12]};
  flex: 1;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    flex: auto 0 1;
  }
`;

const Wrapper = styled.div`
  height: ${props => props.height};
  transition: height 0.5s;
`;

const StyledCloseButton = styled(ImageButton)`
  position: absolute;
  top: 24px;
  right: 24px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  align-content: flex-start;
  margin-right: 80px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    flex-direction: row;
    align-items: center;
  }
`;

const VerifyEmailReminder = ({ user, getUserAttributes }) => {
  const [bannerHeight, setBannerHeight] = useState('auto');
  const localStorage_isVerifyEmailBannerDismissed = localStorageLoad(
    'DismissedVerifyEmailBanner'
  );
  const [isVerifyEmailBannerDismissed, setIsVerifyEmailBannerDismissed] =
    useState(localStorage_isVerifyEmailBannerDismissed);
  const ref = useCallback(node => {
    if (node !== null) {
      setBannerHeight(`${node.getBoundingClientRect().height}px`);
    }
  }, []);

  const hasUserAttributes = () =>
    user && user.userAttributes && user.userAttributes.sub;
  const isVerified = user.userAttributes.email_verified === 'true';

  useEffect(() => {
    // if we don't have the user attributes object already,
    if (!hasUserAttributes()) {
      getUserAttributes();
    }
  }, [user]);

  // do not show if user is user is already verified or has dismissed the banner
  if (!hasUserAttributes() || isVerified || isVerifyEmailBannerDismissed) {
    return null;
  }

  const onDismiss = () => {
    // we do NOT want to update the state variable `isVerifyEmailBannerDismissed` here as the check above will cause the element to immediately disappear from the page (the function will return null)
    // updating the localStorage variable will stop it from re-appearing in the future, and setting the banner height to 0 removes it during this lifecycle of the component while still letting it animate out
    localStorageSave('DismissedVerifyEmailBanner', true);
    setBannerHeight(0);
  };

  return (
    <Wrapper ref={ref} height={bannerHeight}>
      <StyledTopMessageDrawer
        hasFlex
        open
        zindex="1"
        theme={orangeTheme}
        data-cy="verify-email-notification"
      >
        <FlexWrapper>
          <StyledParagraph marginBottom="0" large>
            <strong>
              Please verify your email so you can be notified of updates.
            </strong>
          </StyledParagraph>
          <div>
            <StyledGenericButton
              as={Link}
              small
              to={{
                pathname: ODRS_PROFILE_ROUTE,
                state: { verifyEmail: true },
              }}
            >
              Verify&nbsp;my&nbsp;email
            </StyledGenericButton>
          </div>
        </FlexWrapper>
        <StyledCloseButton onClick={onDismiss}>
          <img src={CloseIcon} />
        </StyledCloseButton>
      </StyledTopMessageDrawer>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  getUserAttributes: () => dispatch(getUserAttributesAction()),
});

VerifyEmailReminder.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  getUserAttributes: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmailReminder);
