import React from 'react';

import {
  DIALOG_SUPER_CONFIRM_NO_CLAUSE_CHANGES,
  STATUS_APPROVED,
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_UPDATE_REQUESTED,
} from 'Common/constants';
import Card from 'Common/UI/Card';
import { QuestionStateCard, CardCustom } from 'Common/Data/Types/appSections';
import { CustomCardProps } from 'Common/Data/Types/types';
import AssetProperties from 'Common/Data/App/assetProperties';
import IconTextButton from 'Common/UI/Button/IconTextButton';
import IconSent from 'Common/Assets/images/icon-sent.svg';
import IconEdit from 'Common/Assets/images/icon-pencil-box.svg';
import IconConfirm from 'Common/Assets/images/icon-tick-circle.svg';
import { SuperSplittingFlags } from 'Common/Data/Types/matter';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { useAppDispatch } from 'App/State/Store';
import { setMatterFlagsAction } from 'App/State/MatterActions';
import GenericButton from 'Common/UI/Button/GenericButton';
import getNames from 'Common/Utils/getNames';
import { Heading1 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import push from 'Common/Utils/push';
import { ODRS_SUPERANNUATION_FUND_CLAUSES_SENT_PFL_SUCCESS_ROUTE } from 'Common/routes';
import { showDialog } from 'App/State/AppReducer';

export const CardContent: React.FC<{
  data: QuestionStateCard<CardCustom>;
  isActive: boolean;
}> = ({ data, isActive }) => {
  const { summaryComponent: SummaryComponent } = data;

  if (SummaryComponent) {
    return (
      <div style={{ flexGrow: 1 }}>
        <SummaryComponent isActive={isActive} />
      </div>
    );
  }

  return null;
};

const HelpText = (
  <>
    <Heading1>Superannuation review</Heading1>

    <Paragraph>
      Your superannuation fund will need to review your draft minutes of consent
      so they can approve or edit your superannuation splitting orders.
    </Paragraph>
    <Paragraph>
      You need to send the Procedural Fairness Letter to comply with the
      requirements of the superannuation trustee, so that they have sufficient
      notice that you intend to make a superannuation splitting order.
    </Paragraph>
    <Paragraph>
      You will need to print or download the following documents and send it to
      them:
    </Paragraph>
    <Paragraph as="ul">
      <li>Procedural Fairness Letter</li>
      <li>
        Proposed Minutes of Consent (<strong>Draft</strong>)
      </li>
    </Paragraph>
  </>
);

const SuperannuationFundClauseEditorCard: React.FC<CustomCardProps> = ({
  data,
  isActive,
  cardHeadingText,
  themeName,
}) => {
  const { otherName, navToQuestions, navToView, status, cardForParty } = data;

  const { superSplittingFlags, isPayer, isSuperIncludedInOffer } =
    AssetProperties();

  const {
    payerHasSubmittedProceduralFairnessLetter,
    payerHasConfirmedNoChangesToClauses,
  } = superSplittingFlags;

  const { withLoading } = usePromiseWithLoading();

  const dispatch = useAppDispatch();

  const navigate = (route: string) => dispatch(push(route));

  const names = getNames();

  const updateFlagAndMaybeNavigate = async (
    flag: keyof SuperSplittingFlags,
    route?: string
  ) =>
    withLoading(async () => {
      await dispatch(
        setMatterFlagsAction({
          superSplitting: {
            ...superSplittingFlags,
            [flag]: true,
          },
        })
      );

      if (route) {
        navigate(route);
      }
    });

  // hide the card if superannuation is not being split
  if (!isSuperIncludedInOffer) {
    return null;
  }

  // hide the card for both parties if the payer has confirmed no changes
  if (payerHasConfirmedNoChangesToClauses) {
    return null;
  }

  // hide the card if it's been approved
  if (status === STATUS_APPROVED) {
    return null;
  }
  if (isPayer) {
    if (!isActive) {
      return (
        <Card cardTheme={themeName} data={data} active={isActive}>
          <Card.MainHeading
            cardForParty={cardForParty}
            enableMoreButton
            showHelpIcon
            helpContent={HelpText}
          >
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            You will need to agree on all of your statements before you can
            confirm your superannuation splitting orders.
          </Card.Paragraph>
        </Card>
      );
    }
    if (payerHasSubmittedProceduralFairnessLetter) {
      // the clauses card has not been saved to the database yet so we need to let the user either confirm or edit their application
      if (status === STATUS_NEW_ONLY_SELF) {
        return (
          <Card
            cardTheme={themeName}
            data={data}
            active={isActive}
            dataCyStatus="requester-sent-application-to-fund"
          >
            <Card.MainHeading
              cardForParty={cardForParty}
              enableMoreButton
              showHelpIcon
              helpContent={HelpText}
            >
              {cardHeadingText}
            </Card.MainHeading>
            <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
              Once you hear back from your superannuation fund, you can confirm
              your application for consent orders or make changes based on their
              response.
            </Card.Paragraph>
            <IconTextButton
              mb={4}
              iconOnLeft
              icon={IconConfirm}
              onClick={() =>
                dispatch(
                  showDialog({ dialog: DIALOG_SUPER_CONFIRM_NO_CLAUSE_CHANGES })
                )
              }
              data-cy="confirm-application-button"
            >
              Confirm application
            </IconTextButton>

            <IconTextButton
              iconOnLeft
              icon={IconEdit}
              subtle
              onClick={navToQuestions}
              data-cy="edit-application-button"
            >
              Edit application
            </IconTextButton>
          </Card>
        );
      }

      // the other party has requested an update
      if (status === STATUS_UPDATE_REQUESTED) {
        return (
          <Card
            cardTheme={themeName}
            data={data}
            active={isActive}
            dataCyStatus="requester-other-party-requested-changes"
          >
            <Card.MainHeading
              cardForParty={cardForParty}
              enableMoreButton
              showHelpIcon
              helpContent={HelpText}
            >
              {cardHeadingText}
            </Card.MainHeading>
            <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
              {otherName} has requested an edit to your application. Once{' '}
              {otherName} has confirmed your updates, you can finalise your
              consent order application and file it with the court.
            </Card.Paragraph>
            <Card.LatestMessage />
            <IconTextButton
              mb={4}
              iconOnLeft
              icon={IconEdit}
              onClick={navToView}
              subtle
              data-cy="edit-application-button"
            >
              Edit application
            </IconTextButton>
          </Card>
        );
      }

      // this user has done the last action on the clauses card (either saved it or updated it) so we need to let them know that they need to wait for the other party to review it
      // NOTE: this logic should match the logic in `SuperannuationConsentOrderChangesWaitingForOtherPayer.tsx` as there is a matching banner state
      return (
        <Card
          cardTheme={themeName}
          data={data}
          active={isActive}
          dataCyStatus="requester-waiting-for-other"
        >
          <Card.MainHeading
            cardForParty={cardForParty}
            enableMoreButton
            showHelpIcon
            helpContent={HelpText}
          >
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            {otherName} will need to confirm your superannuation updates. Once{' '}
            {otherName} has confirmed your updates, you can finalise your
            consent order application and file it with the court.
          </Card.Paragraph>
          <Card.LatestMessage />
          <IconTextButton
            mb={4}
            iconOnLeft
            icon={IconEdit}
            onClick={navToView}
            subtle
          >
            Edit application
          </IconTextButton>
        </Card>
      );
    }

    return (
      <Card
        cardTheme={themeName}
        data={data}
        active={isActive}
        dataCyStatus="requester-initial"
      >
        <Card.MainHeading
          cardForParty={cardForParty}
          enableMoreButton
          showHelpIcon
          helpContent={HelpText}
        >
          {cardHeadingText}
        </Card.MainHeading>
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          You will need to send the following documents to your superannuation
          fund:
          <br />
          1. Draft Minutes of Consent
          <br />
          2. Procedural Fairness Letter
        </Card.Paragraph>
        <IconTextButton
          iconOnLeft
          icon={IconSent}
          $normalSize
          onClick={() =>
            updateFlagAndMaybeNavigate(
              'payerHasSubmittedProceduralFairnessLetter',
              ODRS_SUPERANNUATION_FUND_CLAUSES_SENT_PFL_SUCCESS_ROUTE
            )
          }
          data-cy="sent-application-button"
        >
          I have sent the application
        </IconTextButton>
      </Card>
    );
  }

  // everything below here is for the payee

  if (
    payerHasSubmittedProceduralFairnessLetter &&
    status !== STATUS_NEW_ONLY_THEM
  ) {
    if (status === STATUS_UPDATE_REQUESTED) {
      return (
        <Card
          cardTheme={themeName}
          data={data}
          active={isActive}
          dataCyStatus="requestee-change-requested"
        >
          <Card.MainHeading
            cardForParty={cardForParty}
            enableMoreButton
            showHelpIcon
            helpContent={HelpText}
          >
            {cardHeadingText}
          </Card.MainHeading>
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            You have sent {names.other} a message about the updates to the
            superannuation splitting section of your consent orders. Once they
            edit your application, you can finalise your consent order
            application and file it with the court.
          </Card.Paragraph>
          <Card.LatestMessage />
          <IconTextButton
            mb={4}
            iconOnLeft
            icon={IconEdit}
            onClick={navToView}
            reverse
          >
            View application
          </IconTextButton>
        </Card>
      );
    }

    return (
      <Card
        cardTheme={themeName}
        data={data}
        active={isActive}
        dataCyStatus="requestee-review-changes"
      >
        <Card.MainHeading enableMoreButton>{cardHeadingText}</Card.MainHeading>
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          {names.other} has made some updates to the superannuation splitting
          section of your draft minutes of consent.
        </Card.Paragraph>
        <Card.LatestMessage />
        <GenericButton
          subtle
          onClick={navToView}
          data-cy="review-changes-button"
        >
          View changes
        </GenericButton>
      </Card>
    );
  }

  // if the payer has not yet submitted the procedural fairness letter then we don't want to render the card at all for the payee
  return null;
};

export default SuperannuationFundClauseEditorCard;
