import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Theme, pastelPurpleTheme } from 'Common/Utils/theme';

import GenericButton from 'Common/UI/Button/GenericButton';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';

const Wrap = styled.div<{
  theme: Theme;
  thin: boolean;
}>`
  background-color: ${({ theme }) => theme.backgroundColour};
  padding: ${({ theme }) => `${theme.padding.small}px`};
  border-radius: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: ${({ thin }) => (thin ? '400px' : 'auto')};
`;

const TextWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const CardInformation: React.FC<{
  children: React.ReactNode;
  buttonLabel: string;
  buttonRoute: string;
  thin?: boolean;
  dataCyType?: string;
}> = ({ children, buttonLabel, buttonRoute, thin = false, dataCyType }) => {
  const dispatch = useAppDispatch();
  const navigate = (route: string) => dispatch(push(route));

  return (
    <ThemeProvider theme={pastelPurpleTheme}>
      <Wrap thin={thin} data-cy="info-message" data-cy-type={dataCyType}>
        <TextWrapper>{children}</TextWrapper>
        <GenericButton small onClick={() => navigate(buttonRoute)}>
          {buttonLabel}
        </GenericButton>
      </Wrap>
    </ThemeProvider>
  );
};

export default CardInformation;
