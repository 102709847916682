// import React from 'react';
import { QuestionAny } from 'Common/Data/Types/appSections';
import EvaluatePasswordStrength from 'Common/Utils/EvaluatePasswordStrength';
import isEmailAddress from 'Common/Utils/isEmailAddress';
import {
  triggerResetPasswordForUserAction,
  resetPasswordForUserAction,
} from 'App/State/UserActions';
import { reduxStore } from 'App/State/Store';

export const passwordResetQuestions: QuestionAny[] = [
  {
    name: 'email',
    label: 'What is your email address?',
    hint: 'Email address',
    placeholder: 'Enter email address',
    type: 'email',
    validate: email =>
      isEmailAddress(email) ? [true] : [false, 'Invalid email address'],
    index: 0,
    onBeforeNext: ({ email }) =>
      reduxStore.dispatch(triggerResetPasswordForUserAction({ email })),
  },
  {
    name: 'password',
    label: 'Reset password',
    hint: 'Your new password',
    type: 'password',
    defaultValue: '',
    showConfirmField: true,
    index: 1,
    validate: password => {
      const isPasswordValid = EvaluatePasswordStrength(password.password).pass;
      if (!isPasswordValid) {
        return [false];
      }

      return password.password === password.passwordconfirm
        ? [true]
        : [false, 'The passwords you entered do not match'];
    },
  },
  {
    name: 'verification',
    label: '',
    hint: 'Code',
    type: 'verify',
    defaultValue: '',
    index: 2,
    submitButtonLabel: 'Reset Password',
    onBeforeNext: ({ email, verification, password = { password: '' } }) =>
      reduxStore
        .dispatch(resetPasswordForUserAction({ email, verification, password }))
        .unwrap()
        .catch(() => {
          throw new Error(
            'Please check that you have entered all the fields correctly and try again.'
          );
        }),
  },
];
