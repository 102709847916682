import React, { Ref } from 'react';
import styled from 'styled-components';

import { Theme } from 'Common/Utils/theme';
import { Label } from 'Common/UI/Text/Label';
import Paragraph from 'Common/UI/Text/Paragraph';
import IconTextButton from 'Common/UI/Button/IconTextButton';
import IconEdit from 'Common/Assets/images/icon-edit.svg';
import IconDelete from 'Common/Assets/images/icon-delete.svg';
import { Row } from 'Common/UI/Layout/Flex';
import IconChevronUp from 'Common/Assets/images/icon-chevron-up.svg';
import IconChevronDown from 'Common/Assets/images/icon-chevron-down.svg';
import ImageButton from 'Common/UI/Button/ImageButton';
import { Heading4 } from 'Common/UI/Text/Headings';

const Wrapper = styled.div<{
  theme: Theme;
}>`
  padding-bottom: ${({ theme }) => theme.spacing[4]};
`;

const InnerWrapper = styled.div<{
  theme: Theme;
}>`
  background: ${({ theme }) => theme.colours.offWhite};
  padding: ${({ theme }) => theme.spacing[4]};
  border-top: 1px solid ${({ theme }) => theme.colours.lightGrey};

  max-width: 100%;
  width: 800px;
`;

type Props = {
  index: number;
  onEdit: () => void;
  onDelete: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  children: string;
};

const ClauseParagraph: React.FC<Props & { ref?: Ref<HTMLDivElement> }> =
  React.forwardRef<HTMLDivElement, Props>(
    ({ index, onEdit, onDelete, onMoveUp, onMoveDown, children }, ref) => (
      <Wrapper data-cy="clause-paragraph" ref={ref}>
        <InnerWrapper>
          <Row justifyContent="space-between" mb={8}>
            <Heading4 mt={0} mb={0}>
              Paragraph {index}
            </Heading4>
            <Row>
              {onMoveUp && (
                <ImageButton
                  onClick={(_e: any) => onMoveUp()}
                  data-cy="move-paragraph-up-button"
                >
                  <img src={IconChevronUp} alt="Move paragraph up" />
                </ImageButton>
              )}
              {onMoveDown && (
                <ImageButton
                  onClick={(_e: any) => onMoveDown()}
                  data-cy="move-paragraph-down-button"
                >
                  <img src={IconChevronDown} alt="Move paragraph down" />
                </ImageButton>
              )}
            </Row>
          </Row>
          <Label bold mb={4}>
            Only you can edit
          </Label>
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: children.replaceAll(
                /\s(?<string>\[.*?\])\s/g,
                ' <strong>$<string></strong> '
              ),
            }}
          />
          <Row gap={4}>
            <IconTextButton
              icon={IconEdit}
              iconOnLeft
              subtle
              onClick={onEdit}
              data-cy="edit-paragraph"
            >
              Edit paragraph
            </IconTextButton>
            <IconTextButton
              icon={IconDelete}
              iconOnLeft
              subtle
              onClick={onDelete}
              data-cy="delete-paragraph"
            >
              Delete
            </IconTextButton>
          </Row>
        </InnerWrapper>
      </Wrapper>
    )
  );

export default ClauseParagraph;
