import React, { useState } from 'react';

import formatCurrency from 'Common/Utils/formatCurrency';
import {
  TRANSFER_OPTION_SELL,
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
  TRANSFER_OPTION_SPLIT_EVENLY,
  AssetSplitOption,
} from 'Common/constants';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import { Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import { AssetCardContentType, AssetEnhanced } from 'Common/Data/Types/assets';
import MatterProps from 'Common/Utils/MatterProps';
import AssetProps from 'Common/Data/App/assetProperties';
import {
  AssetTitle,
  FlexHeadingContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  StyledCardWarning,
  Value,
} from './helpers';
import TransferrableAssetBase from './TransferrableAssetBase';

const TransferrableAssetProperty: React.FC<{
  asset: AssetEnhanced;
  isLoading: boolean;
  selectedOption?: AssetSplitOption;
  onTransferAsset: ({
    id,
    option,
    remove,
    options,
  }: {
    id: string;
    option: AssetSplitOption;
    remove: boolean;
    options: { propertyPercentage: number; propertyValue: number };
  }) => Promise<void>;
}> = ({ asset, isLoading = false, selectedOption, onTransferAsset }) => {
  const [selectedTransferOption, setSelectedTransferOption] =
    useState<AssetCardContentType>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    remainingAmountToTransfer,
    absoluteRemainingAmountToTransfer,
    isOwingParty,
  } = AssetProps();

  const {
    type,
    name,
    id,
    value,
    cards = [],
    isSelected,
    isJointlyOwned,
  } = asset;

  const showChangingValueWarning =
    isJointlyOwned && selectedTransferOption?.option === TRANSFER_OPTION_SELL;

  let propertyPercentage = 100;
  let selfPercentage = 0;
  let propertyValue = value;

  const doesAssetCoverRemaining =
    absoluteRemainingAmountToTransfer - value <= 0;

  // if this asset is a property and it's being sold, then we need to handle it differently
  // we need to pass through both a value and a percentage
  // both of these values are always in favour of the OWED party (as determined at the start of asset split, *not* who is _currently_ owed)
  // e.g. if percentage is 75, then that means the OWED party will receive 75% of the total value of the property, and the OWING party will receive 25%
  // if the value is positive, then that is what the OWED party will receive. if it is negative, that is what the OWING party will receive
  // if the percentage is above 50, the value should always be positive. if percentage is under 50, value should always be negative
  if (
    type === 'property' &&
    selectedTransferOption?.option === TRANSFER_OPTION_SELL
  ) {
    if (showChangingValueWarning) {
      if (doesAssetCoverRemaining) {
        propertyPercentage =
          ((value - absoluteRemainingAmountToTransfer) / (value * 2)) * 100;
        propertyValue = absoluteRemainingAmountToTransfer * -1;
        selfPercentage = propertyPercentage;

        if (remainingAmountToTransfer >= 0) {
          // (50-100)% to OWED
          propertyPercentage = 100 - propertyPercentage;
          propertyValue *= -1;
        }

        if (isOwingParty) {
          selfPercentage = 100 - propertyPercentage;
        } else {
          selfPercentage = propertyPercentage;
        }
      } else if (remainingAmountToTransfer > 0) {
        // 100% should be assigned to OWED
        propertyPercentage = 100;
        selfPercentage = isOwingParty ? 0 : 100;
      } else {
        // 100% should be assigned to OWING
        propertyPercentage = 0;
        propertyValue *= -1;
        selfPercentage = isOwingParty ? 100 : 0;
      }
    }
  }

  const handleTransferAsset = (option: AssetSplitOption) =>
    onTransferAsset({
      id,
      option,
      remove: !!isSelected,
      options: {
        propertyPercentage,
        propertyValue,
      },
    }).then(() => setIsModalVisible(false));

  const handleSelectCard = (option: AssetSplitOption) => {
    if (
      ![
        TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
        TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
        TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
        TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
        TRANSFER_OPTION_SELL,
        TRANSFER_OPTION_SPLIT_EVENLY,
      ].includes(option)
    ) {
      throw Error(`Invalid transfer option (${option})`);
    }

    if (isSelected) {
      handleTransferAsset(option).then(() =>
        setSelectedTransferOption(undefined)
      );
    } else {
      const selected = cards.find(card => card.option === option);

      if (selected) {
        setSelectedTransferOption(selected);

        setIsModalVisible(true);
      }
    }
  };

  return (
    <TransferrableAssetBase
      asset={asset}
      isLoading={isLoading}
      selectedOption={selectedOption}
      isModalVisible={isModalVisible}
      hideModal={() => setIsModalVisible(false)}
      handleSelectCard={handleSelectCard}
    >
      <ModalHeader>
        <FlexHeadingContainer>
          <AssetTitle>{name}</AssetTitle>
          <Value>{formatCurrency(value)}</Value>
        </FlexHeadingContainer>

        {showChangingValueWarning && (
          <StyledCardWarning>
            You will receive <strong>{Math.round(selfPercentage)}%</strong> of
            the sale proceeds of the {type} and {MatterProps('other.firstname')}{' '}
            will receive {Math.round(100 - selfPercentage)}
            %. The current net value of your half of the {type} is{' '}
            {formatCurrency(value)} but, remember, this amount could change
            depending on the sale price.
          </StyledCardWarning>
        )}
      </ModalHeader>
      {selectedTransferOption && (
        <>
          <ModalContent>
            <ResponsiveTextContainer>
              <Heading3>{selectedTransferOption.title}</Heading3>

              {React.isValidElement(selectedTransferOption.info) ? (
                selectedTransferOption.info
              ) : (
                <Paragraph large>{selectedTransferOption.info}</Paragraph>
              )}
            </ResponsiveTextContainer>
          </ModalContent>
          <ModalFooter>
            <GenericButton
              data-cy="asset-confirm-transfer"
              fullWidth
              onClick={() => handleTransferAsset(selectedTransferOption.option)}
            >
              Done
            </GenericButton>
          </ModalFooter>
        </>
      )}
    </TransferrableAssetBase>
  );
};

export default TransferrableAssetProperty;
