import React from 'react';

import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Success } from 'Common/UI/Layout/Completed';

const DivisionAgree = () => (
  <Success
    buttonLabel="Back to home"
    buttonRoute={ODRS_PROPERTY_ASSET_DIVISION_ROUTE}
  >
    <H1Conversational>Agreement reached</H1Conversational>
    <Paragraph large>
      Great! You have reached an agreement. When separating couples reach an
      agreement between themselves by using amica, this is an informal
      agreement, which works well for most people. If you want the agreement to
      be formalised, you can apply to the court, for it to be made into a
      consent order.
    </Paragraph>
  </Success>
);

export default DivisionAgree;
