import React from 'react';
import moment from 'moment';

import { DATE_FORMAT_LONG, PARTY_A, PARTY_B } from 'Common/constants';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
  getFullName,
} from 'Common/Utils/statementHelpers';

import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import countriesList from './countriesList';

export const aboutYouLabelContinue = 'About You';

// Note any disabled props will not be saved into the database
export const aboutYouQuestionsContinue: QuestionAny[] = [
  {
    name: 'yourAddress',
    label: 'What is your contact address (address for service) in Australia?',
    hint: 'Your Address',
    type: 'googleAddress',
    sensitive: true,
    defaultValue: '',
    index: 0,
  },
  {
    name: 'countryBorn',
    label: 'In what country were you born?',
    type: 'selectDropdown',
    options: () => countriesList.map(state => ({ label: state, value: state })),
    index: 1,
  },
  {
    name: 'gender',
    label: 'What is your gender?',
    hint: '',
    type: 'select',
    options: MatterPropsJS => {
      const jurisdiction = MatterPropsJS('consentOrders.jurisdiction', 'WA');

      if (jurisdiction === 'FCA') {
        return [
          { label: 'Male', value: 'Male' },
          { label: 'Female', value: 'Female' },
          { label: 'Other', value: 'Other' },
          { label: 'Prefer not to say', value: 'PreferNotToSay' },
        ];
      }

      return [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
      ];
    },
    defaultValue: '',
    index: 2,
  },
  {
    name: 'aboriginalAndOrTorreStraitIslander',
    label: 'Are you of Aboriginal and/or of Torres Strait Islander origin?',
    type: 'select',
    options: () => [
      { label: 'No', value: 'No' },
      { label: 'Yes, Aboriginal origin', value: 'Yes, Aboriginal origin' },
      {
        label: 'Yes, Torres Strait Islander',
        value: 'Yes, Torres Strait Islander',
      },
      {
        label: 'Yes, both Aboriginal and Torres Strait Islander',
        value: 'Yes, both Aboriginal and Torres Strait Islander',
      },
      { label: "I'd prefer not to say", value: "I'd prefer not to say" },
    ],
    help: {
      label: 'Why are you asking?',
      content: (
        <>
          <H1Conversational>Why are you asking?</H1Conversational>
          <Paragraph large>
            You are not required to answer this question, but it will greatly
            assist the Court if you do. The information sought is being
            collected to assist the Court in planning and delivering client
            services. It is possible that you may be contacted to participate in
            a review of a particular aspect of the Court’s services, although
            your right not to participate will be respected. The information you
            provide may be shared with researchers approved by the Court, and
            may be included in publications in statistical form in a way that
            does not identify you.
          </Paragraph>
        </>
      ),
    },
    index: 3,
  },
  {
    name: 'statement',
    type: 'statement',
    index: 4,
    label: 'You created a statement',
  },
];

export const aboutYouStatementContinue = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.CardIndex || matter.self.party;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'yourAddress',
    'birthday',
    'countryBorn',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    aboutYouQuestionsContinue,
    data,
    hideSensitive
  );

  let name;
  if (party === PARTY_A) {
    name = getFullName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    name = getFullName(matter.partyB, PARTY_B, hideSensitive);
  }

  const address = highlight`${name}'s contact address is ${updated(
    'yourAddress',
    redact('yourAddress', data.yourAddress)
  )}. `;

  let born: React.ReactNode = '';
  let gender: React.ReactNode = '';

  if (data.countryBorn) {
    born = highlight`${name} was born ${updated(
      'birthday',
      redact('birthday', moment(data.birthday).format(DATE_FORMAT_LONG))
    )} in ${updated('countryBorn', redact('countryBorn', data.countryBorn))}.`;
  }

  if (data.gender !== 'PreferNotToSay') {
    gender = highlight`${name} identifies as ${data.gender}.`;
  }

  if (plainText) {
    return `${address} ${born} ${gender}`;
  }

  return (
    <>
      {address} {born} {gender}
    </>
  );
};
