import styled from 'styled-components';

export const CloseButton = styled.div`
  position: absolute;
  top: ${props => props.theme.padding.medium}px;
  right: ${props => props.theme.padding.medium}px;
  cursor: pointer;
`;

export const FullyScrollable = styled.div`
  overflow-y: scroll;
  padding: 3em 1em 1em 1em;
`;
