import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Heading3 } from 'Common/UI/Text/Headings';
import Dialog from 'Common/UI/Layout/Dialog';
import defaultTheme from 'Common/Utils/theme';
import GenericButton from 'Common/UI/Button/GenericButton';

const StyledButton = styled(GenericButton)`
  width: 100%;
`;

const DialogSingleButton = ({
  heading,
  prompt,
  buttonLabel,
  onClick,
  buttonDisabled,
  dataCy,
  ...rest
}) => (
  <Dialog
    content={
      <ResponsiveTextContainer>
        {heading && <Heading3>{heading}</Heading3>}
        <Paragraph>{prompt}</Paragraph>
      </ResponsiveTextContainer>
    }
    buttons={
      <ResponsiveTextContainer>
        <StyledButton
          onClick={onClick}
          disabled={buttonDisabled}
          data-cy={dataCy}
        >
          {buttonLabel}
        </StyledButton>
      </ResponsiveTextContainer>
    }
    {...rest}
  />
);

DialogSingleButton.propTypes = {
  heading: PropTypes.string,
  prompt: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  showDialog: PropTypes.bool,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
  buttonDisabled: PropTypes.bool,
  dataCy: PropTypes.string,
};

DialogSingleButton.defaultProps = {
  heading: undefined,
  showDialog: false,
  onClick: () => {},
  theme: defaultTheme,
  buttonDisabled: false,
  dataCy: undefined,
};

export default DialogSingleButton;
