import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { ODRS_CONSENT_ORDERS_COMPLETE_ROUTE } from 'Common/routes';
import FullScreenModal from 'Common/UI/Layout/FullScreenModal';
import push from 'Common/Utils/push';
import AssetsTable from './Division/Components/AssetsTable';

const AssetTable = ({ navigate, location }) => {
  const { state: { previous } = ODRS_CONSENT_ORDERS_COMPLETE_ROUTE } = location;
  const onClose = () => {
    navigate(previous);
  };

  return (
    <FullScreenModal
      show
      title="Total asset pool"
      closeButtonType="icon"
      onClose={onClose}
    >
      <AssetsTable />
    </FullScreenModal>
  );
};

AssetTable.propTypes = {
  navigate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  navigate: push,
};

export default withRouter(connect(null, mapDispatchToProps)(AssetTable));
