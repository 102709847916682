/**
 * Checks to see if a string is a valid month
 */
const isNumericMonth = (str: string) => {
  const validMonths = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  return validMonths.includes(str);
};

export default isNumericMonth;
