export const PROFILE_STATE_VIEW = 'PROFILE_STATE_VIEW';
export const PROFILE_STATE_VERIFY_EMAIL = 'PROFILE_STATE_VERIFY_EMAIL';
export const PROFILE_STATE_VERIFY_PHONE = 'PROFILE_STATE_VERIFY_PHONE';
export const PROFILE_STATE_VERIFYING_EMAIL = 'PROFILE_STATE_VERIFYING_EMAIL';
export const PROFILE_STATE_VERIFYING_PHONE = 'PROFILE_STATE_VERIFYING_PHONE';
export const PROFILE_STATE_VERIFYING_EMAIL_ERROR =
  'PROFILE_STATE_VERIFYING_EMAIL_ERROR';
export const PROFILE_STATE_VERIFYING_PHONE_ERROR =
  'PROFILE_STATE_VERIFYING_PHONE_ERROR';
export const PROFILE_STATE_LIMIT_EXCEEDED = 'PROFILE_STATE_LIMIT_EXCEEDED';
