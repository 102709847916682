/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

import { checkPageUnLocked } from 'Common/Utils/consentOrderPageChecks';
import {
  AGREEMENT_PAYMENTS,
  DOC_TYPE_CONSENT_ORDERS,
  Agreements,
  DOC_TYPES_TO_KEYS,
  DOC_TYPE_CONSENT_ORDERS_DRAFT,
  DOC_TYPE_MINUTES_OF_CONSENT_DRAFT,
  DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER,
} from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import { isAmicaFree } from '../20230101/VERSIONED_Payments';

export const areBothPartiesReadyToExport = (
  { partyA, partyB }: Matter,
  item: Agreements
) => {
  if (!partyA || !partyB) {
    return false;
  }

  if (
    [
      DOC_TYPE_CONSENT_ORDERS,
      DOC_TYPE_CONSENT_ORDERS_DRAFT,
      DOC_TYPE_MINUTES_OF_CONSENT_DRAFT,
      DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER,
    ].includes(item)
  ) {
    return checkPageUnLocked.completed.unlocked();
  }

  return (
    partyA[`canExport${DOC_TYPES_TO_KEYS[item]}`] &&
    partyB[`canExport${DOC_TYPES_TO_KEYS[item]}`]
  );
};

export const hasItemBeenPaidFor = (matter: Matter, item: Agreements) => {
  const { payment: { orders = [] } = {} } = matter;

  return orders.some(order => order.skus.includes(item));
};

export const isPaymentDue = (matter: Matter, item: Agreements) => {
  if (isAmicaFree(matter)) {
    return false;
  }

  if (!AGREEMENT_PAYMENTS.find(i => i.type === item)) {
    return false;
  }

  return (
    areBothPartiesReadyToExport(matter, item) &&
    !hasItemBeenPaidFor(matter, item)
  );
};

export const getItemsAwaitingPayment = (matter: Matter) =>
  AGREEMENT_PAYMENTS.reduce((acc: Agreements[], item) => {
    if (isPaymentDue(matter, item.type)) {
      return [...acc, item.type];
    }

    return acc;
  }, []);

export const getCompletedOrders = (matter: Matter) => {
  const { payment: { orders = [] } = {} } = matter;

  return orders;
};

export const getOrderWithItems = (matter: Matter, item: Agreements) => {
  const { payment: { orders = [] } = {} } = matter;

  return orders.find(order => order.skus.every(sku => item.includes(sku)));
};

export const isPaymentActionNeeded = (matter: Matter) =>
  getItemsAwaitingPayment(matter).length > 0;

export const getTotalPrice = (items: Agreements[]) =>
  items
    .map(item => AGREEMENT_PAYMENTS.find(i => i.type === item))
    .reduce((acc, item) => acc + (item ? item.price : 0), 0);

export { isAmicaFree };
