import {
  AGREEMENT_CREATED,
  AGREEMENT_NEEDS_CONFIRMATION,
  AGREEMENT_OTHER_NOT_READY,
  AGREEMENT_WAITING_FOR_OTHER,
} from 'Common/constants';
import {
  currentStatusConsentOrders,
  currentStatusProperty,
} from 'Common/Utils/documentStatus';

const cardNames = {
  DOCUMENT: 'property agreement',
  CONSENTORDER: 'consent orders',
};

export const defaultCardsState = {
  [cardNames.DOCUMENT]: false,
  [cardNames.CONSENTORDER]: false,
};

export const generateCardsState = () => {
  const propertyState = currentStatusProperty();
  const consentOrdersState = currentStatusConsentOrders();
  return {
    [cardNames.DOCUMENT]: propertyState,
    [cardNames.CONSENTORDER]: consentOrdersState,
  };
};

// I am presuming here that you can only go one or the other or both
export const documentCheckKeys = {
  PROPERTY_AGREEMENT: 'PROPERTY_AGREEMENT',
  CONSENT_ORDERS: 'CONSENT_ORDERS',
  BOTH: 'BOTH',
  NONE: 'NONE',
};

export const checkDocumentsHaveStatus = (status: any) => {
  const cardsState = generateCardsState();
  const cardsWithStatus = Object.keys(cardsState).filter(
    key => cardsState[key] === status
  );
  const isPropertyDocumentStatus = cardsWithStatus.includes(cardNames.DOCUMENT);
  const isConsentOrdersStatus = cardsWithStatus.includes(
    cardNames.CONSENTORDER
  );

  if (isPropertyDocumentStatus && isConsentOrdersStatus) {
    return documentCheckKeys.BOTH;
  }
  if (isPropertyDocumentStatus) {
    return documentCheckKeys.PROPERTY_AGREEMENT;
  }
  if (isConsentOrdersStatus) {
    return documentCheckKeys.CONSENT_ORDERS;
  }
  return documentCheckKeys.NONE;
};

export const generateDocTypeCardsState = () => {
  const propertyState = currentStatusProperty();
  const consentOrdersState = currentStatusConsentOrders();
  const generateIsSelected = (state: any) =>
    [AGREEMENT_WAITING_FOR_OTHER, AGREEMENT_NEEDS_CONFIRMATION].includes(state);
  return {
    [cardNames.DOCUMENT]: generateIsSelected(propertyState),
    [cardNames.CONSENTORDER]: generateIsSelected(consentOrdersState),
  };
};

export const documentsWhereOtherNotReady = () =>
  checkDocumentsHaveStatus(AGREEMENT_OTHER_NOT_READY);
export const areThereDocumentsWhereOtherNotReady = () =>
  documentsWhereOtherNotReady() !== documentCheckKeys.NONE;

export const documentsWhereWaitingForOther = () =>
  checkDocumentsHaveStatus(AGREEMENT_WAITING_FOR_OTHER);
export const areThereDocumentsWhereWaitingForOther = () =>
  documentsWhereWaitingForOther() !== documentCheckKeys.NONE;

export const documentsNeedsConfirmation = () =>
  checkDocumentsHaveStatus(AGREEMENT_NEEDS_CONFIRMATION);
export const areThereDocumentsNeedsConfirmation = () =>
  documentsNeedsConfirmation() !== documentCheckKeys.NONE;

export const isPropertyDocumentComplete = () =>
  checkDocumentsHaveStatus(AGREEMENT_CREATED) ===
    documentCheckKeys.PROPERTY_AGREEMENT ||
  checkDocumentsHaveStatus(AGREEMENT_CREATED) === documentCheckKeys.BOTH;
export const areDocumentsAllCompleted = () =>
  checkDocumentsHaveStatus(AGREEMENT_CREATED) === documentCheckKeys.BOTH;
