import {
  STATUS_APPROVED,
  CARD_AGREEMENT,
  SECTION_RELATIONSHIP,
  SECTION_PROPERTY,
  SECTION_PARENTING,
  PAGE_PARENTING_ESSENTIALS,
  PARTY_A,
  PARTY_B,
} from 'Common/constants';
import MatterProps, { getMatter } from 'Common/Utils/MatterProps';
import { areSectionCardsCompleted } from 'Common/Utils/cardHelpers';
import { getAppSections } from 'Common/Data/App/appSections';

/**
 * Boolean
 * */

export const areRelationshipAssetsAllAccountedFor = () =>
  areSectionCardsCompleted({
    section: SECTION_RELATIONSHIP,
    appSections: getAppSections(),
    checkBothParties: true,
  });
export const areSettlementAssetsAllAccountedFor = () => {
  const { items } = getMatter();

  // check if both parties have selected either `yes` or `no` on the "Do you have superannuation?" card
  const hasSuperOrNoSuperBeenAddedPartyA = items.some(
    item =>
      (item.BaseSection === 'yoursuperannuation' ||
        item.BaseSection === 'nosuperannuation') &&
      item.creator === PARTY_A
  );

  const hasSuperOrNoSuperBeenAddedPartyB = items.some(
    item =>
      (item.BaseSection === 'yoursuperannuation' ||
        item.BaseSection === 'nosuperannuation') &&
      item.creator === PARTY_B
  );

  const isSectionComplete = areSectionCardsCompleted({
    section: SECTION_PROPERTY,
    appSections: getAppSections(),
    checkBothParties: true,
  });

  return (
    isSectionComplete &&
    hasSuperOrNoSuperBeenAddedPartyA &&
    hasSuperOrNoSuperBeenAddedPartyB
  );
};
export const areParentingPlanAssetsAllAccountedFor = () =>
  areSectionCardsCompleted({
    section: SECTION_PARENTING,
    appSections: getAppSections(),
    checkBothParties: true,
  });

// users only get a sub when they register, not when they are invited
// email will be set immediately after invite
export const isPartyBInvited = matter => Boolean(matter?.other?.email);

export const isPartyBSignedUp = matter => Boolean(matter?.other?.sub);

export const isParentingPlanBasicsAgreed = () =>
  areSectionCardsCompleted({
    appSections: getAppSections(),
    section: SECTION_PARENTING,
    page: PAGE_PARENTING_ESSENTIALS,
    party: MatterProps('self.party'),
    filter: [CARD_AGREEMENT],
  });

export const isParentingScheduleAgreed = ({ items }) => {
  const schedule = items.filter(i => i.BaseSection === 'schedule');
  const handovers = items.filter(i => i.BaseSection === 'handovers');
  return !!(
    schedule.length &&
    schedule[0].status === STATUS_APPROVED &&
    handovers.length &&
    handovers[0].status === STATUS_APPROVED
  );
};

export const hasValidSuperannuationForConsentOrders = () => {
  const matterItems = MatterProps('items', []);
  const superannuationItem = matterItems.find(
    item => item.SectionID === `yoursuperannuation${MatterProps('self.party')}`
  );
  // If you have not selected your super then default to true
  if (
    !superannuationItem ||
    (superannuationItem &&
      typeof superannuationItem.superannuationType === 'undefined')
  )
    return true;
  // Superannuation cannot be Other for consent ordes
  return superannuationItem.superannuationType !== 'Other';
};
