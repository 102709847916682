import React from 'react';

import Card from 'Common/UI/Card';
import { EphemeralStateCard, CardInfo } from 'Common/Data/Types/appSections';

type InfoCardProps = {
  data: EphemeralStateCard<CardInfo>;
};

const InfoCard: React.FC<InfoCardProps> = ({ data }) => {
  const { cardID, navToRoute = () => {}, title, description } = data;

  return (
    <Card key={cardID || 'infoCard'} data={data} cardTheme="self" active>
      <Card.MainHeading>{title}</Card.MainHeading>
      <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
        {description}
      </Card.Paragraph>
      <Card.Button spaced={{ marginBottom: 'xxsmall' }} onClick={navToRoute}>
        View
      </Card.Button>
    </Card>
  );
};

export default InfoCard;
