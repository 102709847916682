import React from 'react';

import Card from 'Common/UI/Card';
import { QuestionStateCard, CardView } from 'Common/Data/Types/appSections';

type ViewCardProps = {
  data: QuestionStateCard<CardView>;
  isActive?: boolean;
  themeName?: string;
};

const ViewCard: React.FC<ViewCardProps> = ({ data, themeName, isActive }) => {
  const { cardID, navToQuestions, description } = data;

  if ('matterCheck' in data) {
    const { selfComplete, otherComplete } = data.matterCheck();

    return selfComplete ? (
      <Card key={cardID} data={data} cardTheme="self" active={isActive}>
        <Card.MainHeading toAction="true">
          Requirements for applying for consent orders
        </Card.MainHeading>
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          {selfComplete &&
            otherComplete &&
            'You have both completed this section'}
          {selfComplete && !otherComplete && 'You have completed this section'}
        </Card.Paragraph>
        <Card.Button
          spaced={{ marginBottom: 'xxsmall' }}
          onClick={navToQuestions}
        >
          View
        </Card.Button>
      </Card>
    ) : (
      <Card key={cardID} data={data} cardTheme={themeName} active={isActive}>
        <Card.MainHeading toAction="true">
          Requirements for applying for consent orders
        </Card.MainHeading>
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          {description}
        </Card.Paragraph>
        <Card.Button
          spaced={{ marginBottom: 'xxsmall', marginTopAuto: true }}
          onClick={navToQuestions}
          dataCy="get-started-button"
        >
          Get Started
        </Card.Button>
      </Card>
    );
  }

  return null;
};

export default ViewCard;
