import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_DIVISION_VIEW_SUGGESTED,
} from 'Common/routes';
import diviProperties from 'Common/Data/App/diviProperties';
import { CarouselButton } from 'Common/UI/Carousel/ImageSlideWrapper';
import Carousel from 'Common/UI/Carousel';
import DivisionSlide1 from './DivisionSlide1';
import DivisionSlide2 from './DivisionSlide2';
import DivisionSlide3 from './DivisionSlide3';
import DivisionSlide4 from './DivisionSlide4';

const DivisionIntroCarousel = ({ navigate }) => {
  const { rounds } = diviProperties();
  const route =
    rounds.length > 0
      ? ODRS_DIVISION_VIEW_SUGGESTED
      : ODRS_DIVISION_SPLIT_VIEW_ROUTE;

  const LastSlideControls = () => (
    <CarouselButton
      desktopOnly
      onClick={() => navigate(route)}
      data-cy="carousel-finish-button"
    >
      View our suggestion
    </CarouselButton>
  );

  return (
    <Carousel
      LastSlideControls={LastSlideControls}
      onClose={() => navigate(ODRS_DASH_PROPERTY_ROUTE)}
    >
      <DivisionSlide1 />
      <DivisionSlide2 />
      <DivisionSlide3 />
      <DivisionSlide4 navigate={navigate} />
    </Carousel>
  );
};

DivisionIntroCarousel.propTypes = {
  navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
});

export default connect(null, mapDispatchToProps)(DivisionIntroCarousel);
