import React, { ReactNode, useContext } from 'react';
import styled from 'styled-components';
import { agreedDivisionText } from 'Common/Data/App/diviProperties';
import IconOpenLinkButton from 'Common/UI/Button/IconOpenLinkButton';

import { DashboardContext } from 'Common/UI/DashboardContext';

import Card from 'Common/UI/Card';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import Matter from 'Common/Data/Types/matter';
import {
  QuestionStateCard,
  EphemeralStateCard,
  CardButton,
  CardDividingAssets,
  CardContinue,
  QuestionCard,
  CardView,
  CardAgreedDivision,
  CardInfo,
  CardComponent,
  CardCustom,
} from 'Common/Data/Types/appSections';
import { Theme } from 'Common/Utils/theme';
import {
  ContinueCard,
  generateCardHeading,
  generateJurisdictionHeading,
  InfoCard,
  NormalCard,
  ViewCard,
} from 'App/UI/Dashboard/common/Cards';
import isPageContentsUnlocked from 'App/AppState/isPageContentsUnlocked';
import CustomCard from '../common/Cards/CustomCard';

export const generateNormalCard = (
  data: QuestionStateCard<QuestionCard>,
  matter: Matter
): ReactNode => {
  const { cardID } = data;

  const { section, tab } = useContext(DashboardContext);

  let checkDisabled = true;
  if ('disableCard' in data) {
    checkDisabled = data.disableCard();
  }

  const cardHeadingText =
    cardID === 'jurisdiction'
      ? generateJurisdictionHeading(data, matter)
      : generateCardHeading(data, matter);

  const isActive =
    isPageContentsUnlocked(matter, section, tab) && checkDisabled;

  return (
    <NormalCard
      key={cardID}
      data={data}
      matter={matter}
      isActive={isActive}
      themeName="greenTheme"
      cardHeadingText={cardHeadingText}
    />
  );
};

export const generateCustomCard = (
  data: QuestionStateCard<CardCustom>,
  matter: Matter
): ReactNode => {
  const { cardID, customCardComponent: CustomCardComponent } = data;

  const { section, tab } = useContext(DashboardContext);

  const isActive = isPageContentsUnlocked(matter, section, tab);

  const cardHeadingText = generateCardHeading(data, matter);

  const Component = CustomCardComponent ?? CustomCard;

  return (
    <Component
      key={cardID}
      data={data}
      matter={matter}
      cardHeadingText={cardHeadingText}
      themeName="greenTheme"
      isActive={isActive}
    />
  );
};

export const generateComponentCard = (
  data: EphemeralStateCard<CardComponent>
): ReactNode => {
  const { cardID } = data;

  if ('ComponentClass' in data) {
    const { ComponentClass } = data;

    return <ComponentClass key={cardID} />;
  }

  return null;
};

export const generateContinueCard = (
  data: QuestionStateCard<CardContinue>,
  matter: Matter
): ReactNode => {
  const { cardID } = data;
  const { section, tab } = useContext(DashboardContext);
  const cardHeadingText = generateCardHeading(data, matter);
  const isActive = isPageContentsUnlocked(matter, section, tab);

  return (
    <ContinueCard
      key={cardID}
      data={data}
      matter={matter}
      isActive={isActive}
      themeName="greenTheme"
      cardHeadingText={cardHeadingText}
    />
  );
};

export const generateViewCard = (
  data: QuestionStateCard<CardView>,
  matter: Matter
): ReactNode => {
  const { cardID } = data;

  const { section, tab } = useContext(DashboardContext);

  const isActive = isPageContentsUnlocked(matter, section, tab);

  return (
    <ViewCard
      key={cardID}
      data={data}
      isActive={isActive}
      themeName="greenTheme"
    />
  );
};

export const generateInfoCard = (
  data: EphemeralStateCard<CardInfo>
): ReactNode => {
  const { cardID } = data;

  return <InfoCard key={cardID} data={data} />;
};

export const generateAgreedDivisionCard = (
  data: EphemeralStateCard<CardAgreedDivision>
): ReactNode => {
  const { navToRoute = () => {} } = data;

  return (
    <Card key="agreedDivision" cardTheme="emptyOption">
      <Card.MainHeading>Your agreed division</Card.MainHeading>
      <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
        {agreedDivisionText()}
      </Card.Paragraph>
      <Card.Button subtle onClick={navToRoute}>
        View
      </Card.Button>
    </Card>
  );
};

export const generateDividingAssetsCard = (
  data: EphemeralStateCard<CardDividingAssets>
): ReactNode => {
  const { navToRoute = () => {} } = data;

  return (
    <Card key="dividingAsset" cardTheme="emptyOption">
      <Card.MainHeading>Dividing assets</Card.MainHeading>
      <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
        You have both reached an agreement on what you plan to do with your
        assets.
      </Card.Paragraph>
      <Card.Button subtle onClick={navToRoute}>
        View
      </Card.Button>
    </Card>
  );
};

const StyledOpenIconButton = styled(IconOpenLinkButton)<{
  theme: Theme;
}>`
  background: ${props => props.theme.colours.mediumPurple};
  color: ${props => props.theme.colours.white};
  margin-bottom: 16px;
`;

export const generateButtonCard = (
  data: CardButton,
  matter: Matter
): ReactNode => {
  const {
    cardID,
    onSubmit,
    getHref = () => '',
    title,
    buttonDisabled = () => false,
  } = data;

  if (onSubmit) {
    return (
      <StyledOpenIconButton
        key={cardID}
        disabled={buttonDisabled(matter)}
        onClick={onSubmit}
      >
        {title}
      </StyledOpenIconButton>
    );
  }

  return (
    <ReferralLink key={cardID} href={getHref()} target="_blank">
      <StyledOpenIconButton
        disabled={buttonDisabled(matter)}
        onClick={() => {}}
      >
        {title}
      </StyledOpenIconButton>
    </ReferralLink>
  );
};
