import { Theme } from 'Common/Utils/theme';
import styled from 'styled-components';

const AmicaLogoWrapper = styled.div<{
  theme: Theme;
}>`
  width: 92px;
  margin-bottom: ${({ theme }) => theme.padding.medium}px;
  color: ${({ theme }) => theme.logoColour};
`;

export default AmicaLogoWrapper;
