import React, { useState, useContext, useRef, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import MatterProps from 'Common/Utils/MatterProps';
import RoundButton from 'Common/UI/Button/RoundButton';
import { Label } from 'Common/UI/Text/Label';
import Paragraph from 'Common/UI/Text/Paragraph';
import { ScheduleContext } from '../ScheduleContext';
import NightsCounter from './NightsCounter';
import Week from './Week';
import { BlankPeriod } from './Period';
import Row from './Row';
import Day from './Day';

const ViewWrapper = styled.div`
  ${props =>
    !props.isScheduleEmpty &&
    `background-color: ${props.theme.colours.offWhite};`}
`;

const CalendarWrapper = styled.div`
  margin: 0 auto ${props => props.theme.padding.medium}px;
  width: 100%;
`;

const ViewCalendarWrapper = styled(CalendarWrapper)`
  margin-left: 0;
  margin-right: 0;
  width: 90%;
`;

const RowHeading = styled(Label)`
  margin: 0;
  font-weight: bold;
  width: 32px;
  text-align: center;
  color: ${props => props.theme.colours.midDarkGrey};

  ${props =>
    props.disabled &&
    css`
      color: ${props.isActive
        ? props.theme.colours.lightPurple
        : props.theme.colours.midLightGrey};
    `}
`;

const FlexRow = styled(Row)`
  justify-content: space-between;
`;

const NightsCounterRow = styled(FlexRow)`
  align-items: flex-end;
`;

const CounterLabelsRow = styled(FlexRow)`
  align-items: flex-start;
`;

const CounterLabel = styled(Paragraph)`
  margin: 0;
  color: ${props =>
    props.isScheduleEmpty
      ? props.theme.colours.midLightGrey
      : props.theme.colours.black};
`;

const AddWeekButton = ({ onClick }) => (
  <svg
    data-cy="scheduler-add-week-button"
    onClick={onClick}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="32"
      y="32"
      width="32"
      height="32"
      rx="16"
      transform="rotate(-180 32 32)"
      fill="#E4E4F0"
    />
    <path
      d="M16 20.6665L16 11.3332"
      stroke="#21202C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6667 16L11.3334 16"
      stroke="#21202C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Header = ({ disabled, isActive }) => (
  <Row>
    <RowHeading disabled={disabled} isActive={isActive}>
      M
    </RowHeading>
    <RowHeading disabled={disabled} isActive={isActive}>
      T
    </RowHeading>
    <RowHeading disabled={disabled} isActive={isActive}>
      W
    </RowHeading>
    <RowHeading disabled={disabled} isActive={isActive}>
      T
    </RowHeading>
    <RowHeading disabled={disabled} isActive={isActive}>
      F
    </RowHeading>
    <RowHeading disabled={disabled} isActive={isActive}>
      S
    </RowHeading>
    <RowHeading disabled={disabled} isActive={isActive}>
      S
    </RowHeading>
  </Row>
);

const NewWeek = ({ addWeek }) => (
  <Row>
    <Day disabled />
    <Day disabled />
    <Day disabled />
    <Day disabled />
    <Day disabled />
    <Day disabled />
    <AddWeekButton onClick={addWeek} />
  </Row>
);

const BlankWeek = ({ isActive }) => {
  const ref = useRef(null);
  const [offset, setOffset] = useState(0);

  useLayoutEffect(() => {
    setOffset(ref.current.children[0].offsetLeft);
  }, [ref]);

  return (
    <Row ref={ref}>
      <Day disabled blank isActive={isActive} />
      <Day disabled blank isActive={isActive} />
      <Day disabled blank isActive={isActive} />
      <Day disabled blank isActive={isActive} />
      <Day disabled blank isActive={isActive} />
      <Day disabled blank isActive={isActive} />
      <Day disabled blank isActive={isActive} />
      <BlankPeriod periodOffset={offset} isActive={isActive} />
    </Row>
  );
};

const Calendar = ({ viewOnly = false }) => {
  const context = useContext(ScheduleContext);
  const {
    weeks,
    toggleNight,
    addWeek,
    removeWeek,
    screen,
    isScheduleEmpty,
    numberOfWeeks,
    getNumberOfHandovers,
    isActive,
    canAddWeek,
  } = context;

  if ((!isActive || viewOnly) && isScheduleEmpty) {
    return (
      <ViewWrapper isScheduleEmpty={isScheduleEmpty}>
        <Paragraph>This is where you will create a schedule</Paragraph>
        <ViewCalendarWrapper>
          <Header disabled={!isActive} isActive={isActive} />
          <BlankWeek isActive={isActive} />
        </ViewCalendarWrapper>
      </ViewWrapper>
    );
  }

  return (
    <>
      <NightsCounterRow>
        <NightsCounter
          party={MatterProps('self.party')}
          highlight={!viewOnly && screen === 'edit'}
        />
        <NightsCounter party={MatterProps('other.party')} />
      </NightsCounterRow>
      <CalendarWrapper>
        <Header />
        {weeks.map((item, index) => (
          <Week
            key={index}
            weekNumber={weeks.indexOf(item)}
            weekData={item}
            onClick={toggleNight}
            context={context}
            viewOnly={viewOnly || screen === 'confirm'}
            isConfirmScreen={screen === 'confirm'}
          />
        ))}
        {!viewOnly && (
          <>
            {screen === 'edit' && canAddWeek && <NewWeek addWeek={addWeek} />}
            {weeks.length > 1 && screen === 'edit' && (
              <RoundButton small subtle onClick={removeWeek}>
                Remove last week
              </RoundButton>
            )}
          </>
        )}
      </CalendarWrapper>
      <CounterLabelsRow>
        <CounterLabel isScheduleEmpty={isScheduleEmpty}>
          <strong>{numberOfWeeks}</strong> Week cycles
        </CounterLabel>
        <CounterLabel isScheduleEmpty={isScheduleEmpty}>
          <strong>{getNumberOfHandovers()}</strong> Handovers
        </CounterLabel>
      </CounterLabelsRow>
    </>
  );
};

export default Calendar;
