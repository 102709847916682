import React from 'react';
import styled from 'styled-components';

import { Theme } from 'Common/Utils/theme';
import { Label } from 'Common/UI/Text/Label';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Row } from 'Common/UI/Layout/Flex';

const Wrapper = styled.div<{
  theme: Theme;
}>`
  padding-bottom: ${({ theme }) => theme.spacing[4]};
`;

type Props = {
  title: string;
  children: string;
};

const ClauseParagraphViewOnly: React.FC<Props> = ({ title, children }) => (
  <Wrapper data-cy="clause-paragraph">
    <Row justifyContent="space-between" mb={4}>
      <Label bold>{title}</Label>
    </Row>
    <Paragraph
      dangerouslySetInnerHTML={{
        __html: children.replaceAll(
          /\s(?<string>\[.*?\])\s/g,
          ' <strong>$<string></strong> '
        ),
      }}
    />
  </Wrapper>
);

export default ClauseParagraphViewOnly;
