import React from 'react';

import formatCurrency from 'Common/Utils/formatCurrency';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import { PARTY_A, PARTY_B, DEFAULT_OTHER_TEXT, Party } from 'Common/constants';
import capitaliseText from 'Common/Utils/capitaliseText';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import DocumentList from 'Common/UI/UploadedDocuments/DocumentList';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import isFileObject from 'Common/Utils/isFileObject';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';

export const vehiclesLabel = 'Vehicles';

export const vehiclesQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about the vehicles that you owned
        or shared with your former partner.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'vehicleType',
    label: 'What kind of vehicle is it?',
    hint: '',
    type: 'select',
    options: () => [
      { label: 'Car', value: 'Car' },
      { label: 'Truck', value: 'Truck' },
      { label: 'Motorbike', value: 'Motorbike' },
      { label: 'Scooter', value: 'Scooter' },
      { label: 'Boat', value: 'Boat' },
      { label: 'Jet Ski', value: 'Jet Ski' },
      { label: 'Campervan', value: 'Campervan' },
      { label: 'Caravan', value: 'Caravan' },
      { label: 'Tractor', value: 'Tractor' },
      { label: 'Trailer', value: 'Trailer' },
    ],
    defaultValue: '',
    index: 1,
    help: {
      label: 'What counts as a vehicle?',
      content: (
        <>
          <H1Conversational>What counts as a vehicle?</H1Conversational>
          <Paragraph large>
            Any vessel on land or on water that is able to be registered. For
            more information on what is a vehicle see the{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Help and Support page
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'vehicleMakeModel',
    label: 'What is the make and model?',
    hint: '',
    type: 'text',
    defaultValue: '',
    index: 2,
    help: {
      label: 'How do I tell?',
      content: (
        <>
          <H1Conversational>How do I tell?</H1Conversational>
          <Paragraph large>
            The make is brand name of who made the vehicle, and the model refers
            to the product or range of products that the vehicle is from. For
            example, for a Honda Civic, Honda is the brand name and Civic is the
            model.
          </Paragraph>
          <Paragraph large>
            Most vehicles have badges or permanent markings on them that give
            this information, or you can look in the vehicle manual.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'vehicleRego',
    label: 'What is the registration number?',
    hint: '',
    type: 'text',
    defaultValue: '',
    index: 3,
    optional: true,
    sensitive: true,
  },
  {
    name: 'vehicleOwner',
    label: 'Who is the owner of this vehicle?',
    hint: '',
    type: 'select',
    options: _MatterPropsJS => [
      { label: 'I am', value: _MatterPropsJS('self.party') },
      {
        label: `${capitaliseText(
          _MatterPropsJS('other.firstname', DEFAULT_OTHER_TEXT)
        )}`,
        value: _MatterPropsJS('other.party', PARTY_A),
      },
      { label: 'Both of us', value: 'both' },
    ],
    defaultValue: '',
    index: 4,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            The owner of the vehicle is the person who owns it or was given it
            as a gift.
          </Paragraph>
          <Paragraph large>
            This might be different to the person who is the registered keeper
            of the vehicle.
          </Paragraph>
          <Paragraph large>
            This person might be the main driver or the person responsible for
            taxing the vehicle.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'vehicleValue',
    label: 'How much is it worth?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 5,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This is the second-hand market value of your vehicle today, not the
            value it was when you originally purchased it.
          </Paragraph>
          <Paragraph large>
            You can check the market value of your car on
            <ReferralLink href="https://www.redbook.com.au">
              https://www.redbook.com.au
            </ReferralLink>
            .
          </Paragraph>
          <Paragraph large>
            You might need to get the vehicle formally valued by a professional,
            especially if it is a collector's, classic or speciality vehicle.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'vehiclesProof',
    label: () => 'Would you like to upload supporting documents?',
    hint: '',
    type: 'docupload',
    defaultValue: [],
    preSaveResponses: (value: any) =>
      value.map((v: Record<string, unknown>) => v.imagePath),
    index: 6,
    optional: true,
    help: {
      label: 'What should I provide?',
      content: (
        <>
          <H1Conversational>What kind of proof do I need?</H1Conversational>
          <Paragraph large>
            {`Remember: this is the second-hand resale value.
            you can look this up online on a service such as `}
            <ReferralLink href="https://www.redbook.com.au">
              https://www.redbook.com.au
            </ReferralLink>
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 7,
  },
];

export const vehiclesStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'vehicleOwner',
    'vehicleMakeModel',
    'vehicleValue',
    'vehicleType',
    'vehicleRego',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(vehiclesQuestions, data, hideSensitive);

  const partyNames = {
    A: getFirstName(matter.partyA, PARTY_A, hideSensitive),
    B: getFirstName(matter.partyB, PARTY_B, hideSensitive),
  };

  let owner;

  if (data.vehicleOwner === 'both') {
    owner = highlight`${updated('vehicleOwner', partyNames.A)} and ${updated(
      'vehicleOwner',
      partyNames.B
    )} jointly own a`;
  } else {
    owner = highlight`${updated(
      'vehicleOwner',
      partyNames[data.vehicleOwner as Party]
    )} owns a`;
  }

  let registration;

  if (data.vehicleRego) {
    registration = highlight`${updated(
      'vehicleRego',
      redact('vehicleRego', `(registration ${data.vehicleRego})`)
    )} `;
  }

  const vehicle = highlight`${updated(
    'vehicleMakeModel',
    redact('vehicleMakeModel')
  )} ${updated('vehicleType', redact('vehicleType'))} ${registration}that 
    is worth ${updated(
      'vehicleValue',
      redact('vehicleValue', formatCurrency(data.vehicleValue))
    )}.`;

  if (plainText) {
    return `${owner} ${vehicle}`;
  }

  let files = null;

  // Create a document list if they have been uploaded
  if (
    data.vehiclesProof &&
    data.vehiclesProof.length &&
    !isFileObject(data.vehiclesProof)
  ) {
    files = <DocumentList documents={data.vehiclesProof} />;
  }

  return (
    <>
      {owner}
      {vehicle}
      {files}
    </>
  );
};
