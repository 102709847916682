import React from 'react';

import { PARTY_A, DEFAULT_OTHER_TEXT } from 'Common/constants';
import MatterProps from 'Common/Utils/MatterProps';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

export default [
  {
    name: 'willThereBeHandovers',
    label: 'Do you need to agree on handovers?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 0,
    help: {
      label: 'I need more information',
      content: (
        <>
          <H1Conversational>
            How do I know if I need to agree on handovers?
          </H1Conversational>
          <Paragraph>
            If you are sharing time spent with your child, it can be helpful to
            agree on handover time and location.
          </Paragraph>
          <Paragraph>
            Parents who will have main care of their children 100% of the time
            do not need to agree on handovers and can skip this section.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'weekdayHandovers',
    label: 'Where would you like do handovers on week days?',
    hint: '',
    type: 'select',
    options: _MatterPropsJS => [
      { label: 'At my house', value: _MatterPropsJS('self.party') },
      {
        label: `At ${_MatterPropsJS(
          'other.firstname',
          DEFAULT_OTHER_TEXT
        )}'s house`,
        value: _MatterPropsJS('other.party', PARTY_A),
      },
      { label: 'At school', value: 'atSchool' },
      { label: 'We will agree later', value: 'organiseLater' },
      { label: 'I want to suggest a location', value: 'pickMeetingPoint' },
    ],
    defaultValue: '',
    index: 1,
    visible: answers => !!answers.willThereBeHandovers,
  },
  {
    name: 'weekdayHandoverCustomLocation',
    label:
      'Where would you like to meet for handovers on week days during the school term?',
    hint: 'Handover location',
    placeholder: 'E.g. at starbucks, botanical gardens...',
    type: 'text',
    index: 2,
    visible: answers =>
      !!answers.willThereBeHandovers &&
      answers.weekdayHandovers === 'pickMeetingPoint',
  },
  {
    name: 'weekdayHandoverCustomTime',
    label: 'What time would you like to do handovers?',
    hint: 'Handover time',
    placeholder: 'time',
    type: 'time',
    index: 3,
    visible: answers =>
      !!answers.willThereBeHandovers &&
      answers.weekdayHandovers !== 'organiseLater',
  },
  {
    name: 'weekendHandovers',
    label:
      'Where would you like to do handovers on weekends during the school term?',
    hint: '',
    type: 'select',
    options: _MatterPropsJS => [
      { label: 'At my house', value: _MatterPropsJS('self.party') },
      {
        label: `At ${_MatterPropsJS(
          'other.firstname',
          DEFAULT_OTHER_TEXT
        )}'s house`,
        value: _MatterPropsJS('other.party', PARTY_A),
      },
      { label: 'At school', value: 'atSchool' },
      { label: 'We will agree later', value: 'organiseLater' },
      { label: 'I want to suggest a location', value: 'pickMeetingPoint' },
    ],
    defaultValue: '',
    index: 4,
    visible: answers => !!answers.willThereBeHandovers,
  },
  {
    name: 'weekendHandoverCustomLocation',
    label: 'Where would you like to meet for handovers on weekends?',
    hint: 'Handover location',
    placeholder: 'E.g. at starbucks, botanical gardens...',
    type: 'text',
    index: 5,
    visible: answers =>
      !!answers.willThereBeHandovers &&
      answers.weekendHandovers === 'pickMeetingPoint',
  },
  {
    name: 'weekendHandoverCustomTime',
    label: 'What time would you like to do handovers?',
    hint: 'Handover time',
    placeholder: 'time',
    type: 'time',
    index: 6,
    visible: answers =>
      !!answers.willThereBeHandovers &&
      answers.weekendHandovers !== 'organiseLater',
  },
  {
    name: 'info',
    label: () =>
      `You and ${MatterProps(
        'other.firstname'
      )} agree that these arrangements can be altered from time to time, provided that you both agree beforehand, and you can return to these arrangements when the agreed change ends.`,
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 7,
    visible: answers => !!answers.willThereBeHandovers,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 8,
  },
];
