import {
  CARD_AGREEMENT,
  MESSAGE_STATUS_APPROVED,
  MESSAGE_STATUS_REOPENED,
  PAGE_PARENTING_ESSENTIALS,
  SECTION_PARENTING,
  STATUS_APPROVED,
  STATUS_REOPENED,
} from 'Common/constants';
import { getCard, getPage } from 'Common/Data/App/appSections';
import { isParentingPlanBasicsAgreed } from 'App/Utils/matterHelpers';
import MatterProps from 'Common/Utils/MatterProps';
import Matter from 'Common/Data/Types/matter';
import { useAppDispatch } from 'App/State/Store';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import { CardAgreement, CardIndex } from 'Common/Data/Types/appSections';
import { SyntheticEvent } from 'react';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';

export const getBasicsCardsState = (
  matter: Matter
): {
  checked: Record<string, boolean>;
  isAllChecked: boolean;
  handleChecked: (
    hasChecked: boolean,
    event: SyntheticEvent<HTMLInputElement>
  ) => void;
} => {
  const dispatch = useAppDispatch();

  const { withLoading } = usePromiseWithLoading();

  const updateMatterSection = (
    section: [string, CardIndex],
    sectionData: any,
    messageData: any
  ) =>
    dispatch(updateMatterSectionAction({ section, sectionData, messageData }));

  const essentials = getPage(SECTION_PARENTING, PAGE_PARENTING_ESSENTIALS);

  const {
    self: { party: selfParty },
  } = matter;

  const basicsCards: CardAgreement[] =
    (essentials?.children
      ?.find(group => group.id === 'basics')
      ?.children.filter(
        card => card.type === CARD_AGREEMENT
      ) as CardAgreement[]) || [];

  const checked = basicsCards
    .filter(card => card.index === selfParty)
    .reduce((obj, card) => {
      if (card.cardID) {
        return { ...obj, [card.cardID]: card.status === STATUS_APPROVED };
      }

      return obj;
    }, {} as Record<string, boolean>);

  return {
    checked,
    isAllChecked: isParentingPlanBasicsAgreed(),
    handleChecked: async (_hasChecked, event) =>
      withLoading(() => {
        const card = getCard(event.currentTarget.id);

        if ('cardID' in card && 'cardIDAsArray' in card) {
          const checkedUpdated = { ...checked };

          checkedUpdated[event.currentTarget.id] =
            !checkedUpdated[event.currentTarget.id];

          const cardData = {
            ...card.data,
            status: !checked[card.cardID] ? STATUS_APPROVED : STATUS_REOPENED,
          };

          const messageData = {
            section: card.cardIDAsArray,
            owner: MatterProps('self.party'),
            message: '',
            status: !checked[card.cardID]
              ? MESSAGE_STATUS_APPROVED
              : MESSAGE_STATUS_REOPENED,
            checkTone: false,
          };

          return updateMatterSection(card.cardIDAsArray, cardData, messageData);
        }

        return Promise.resolve();
      }),
  };
};
