import React from 'react';

import { SECTION_RELATIONSHIP } from 'Common/constants';
import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import DashboardHome from 'App/UI/Dashboard/Home/DashboardHome';
import { darkerNoBackground } from 'Common/Utils/theme';
import DiviRoute from 'Common/UI/DiviRoute';
import MatterGuard from '../Guard/MatterGuard';

const ODRSDashboardHome = () => (
  <MatterGuard>
    <DiviRoute
      path={ODRS_DASH_HOME_ROUTE}
      component={DashboardHome}
      theme={darkerNoBackground}
      tab={SECTION_RELATIONSHIP}
    />
  </MatterGuard>
);
export default ODRSDashboardHome;
