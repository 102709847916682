import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  STATUS_APPROVED,
  STATUS_REMOVED,
  SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import { reduxStore, useAppSelector } from 'App/State/Store';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { definitelyGetCard } from 'Common/Data/App/appSections';
import { CardYourSuperannuation } from 'Common/Data/Types/appSections';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';

export const superannuationBalanceRequestInProgressRequesteeFlags: AppState = {
  id: 'superannuationBalanceRequestInProgressRequestee',
  description: 'The other party has started the balance request flow',
  check: () => {
    const {
      matter: { items },
    } = reduxStore.getState();

    const selfParty = getPartyKeys().self;

    // get all open super cards created by you that the other party is requesting the balance for
    const superCardsWithCancelledBalanceRequest = items.filter(
      item =>
        ![STATUS_APPROVED, STATUS_REMOVED].includes(item.status) &&
        item.BaseSection === 'yoursuperannuation' &&
        item.__flags?.superBalanceRequested &&
        !item.__flags?.letterUploaded &&
        item.creator === selfParty
    );

    // if there are no cards found, don't show this banner
    if (!superCardsWithCancelledBalanceRequest) {
      return false;
    }

    const haveAllCardsBannersBeenDismissed =
      superCardsWithCancelledBalanceRequest.every(({ SectionID }) =>
        sessionStorageLoad(
          `SuperannuationBalanceRequestInProgressRequestee_${SectionID}_Dismissed`
        )
      );

    // if the banner for every card found has previously been dismissed, don't show this banner
    // note: we are putting this check in here rather than in the bannerTopState flag like normal because
    // the we want to skip this app state if it has been dismissed, not just trigger this app state but hide the banner
    return !haveAllCardsBannersBeenDismissed;
  },
  flags: {
    appState: SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE,
  },
  usedAsNotificationState: true,
};

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperannuationBalanceRequestInProgressRequestee: React.FC<Props> =
  ({ otherName, updateAppState }) => {
    const selfParty = getPartyKeys().self;

    const { items } = useAppSelector(state => state.matter);

    const allSuperCardsWithBalanceRequestInProgress = items.filter(
      item =>
        ![STATUS_APPROVED, STATUS_REMOVED].includes(item.status) &&
        item.BaseSection === 'yoursuperannuation' &&
        item.__flags?.superBalanceRequested &&
        !item.__flags?.letterUploaded &&
        item.creator === selfParty
    );

    // find the first card that has not been dismissed
    const superCardWithBalanceRequestInProgress =
      allSuperCardsWithBalanceRequestInProgress.find(
        ({ SectionID }) =>
          !sessionStorageLoad(
            `SuperannuationBalanceRequestInProgressRequestee_${SectionID}_Dismissed`
          )
      );

    if (!superCardWithBalanceRequestInProgress) {
      console.warn('No super card found with balance request in progress');

      return null;
    }

    const card = definitelyGetCard<CardYourSuperannuation>(
      superCardWithBalanceRequestInProgress.SectionID
    );

    const { data, cardID } = card;

    return (
      <div data-cy="banner-balance-request-in-progress-other-party">
        <SpacedBox marginBottom={8}>
          <Heading2MarginTopNone>
            {otherName} is requesting more information about your{' '}
            {data.superannuationName} balance.
          </Heading2MarginTopNone>
          <Paragraph>
            {otherName} is filling out a Superannuation Information Kit to send
            to your superannuation fund. {otherName} will upload the response
            they receive from your fund for you to view.
          </Paragraph>
        </SpacedBox>
        <GenericButton
          fullWidth
          onClick={() => {
            sessionStorageSave(
              `SuperannuationBalanceRequestInProgressRequestee_${cardID}_Dismissed`,
              true
            );
            updateAppState();
          }}
          small
          data-cy="dismiss-button"
        >
          Got it
        </GenericButton>
      </div>
    );
  };
