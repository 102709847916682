import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { signupPartyB } from 'Common/Data/App/20200101/Questions/signup';
import { INTRO_ROUTE, ODRS_INVITE_ROUTE, HOME_ROUTE } from 'Common/routes';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import theme, { darkerTheme } from 'Common/Utils/theme';
import AmicaLogo from 'App/UI/AmicaLogo';
import StepTransition from 'App/UI/Transitions/StepTransition';
import { RouteContext } from 'App/UI/Transitions/RouteContext';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';
import { clearMatterStore } from 'App/State/MatterReducer';
import ProgressBar from 'Common/UI/ProgressBar';
import QuestionsFlowWrapper from '../Questions/QuestionsFlowWrapper';

import SignupIntro from './Common/SignupIntro';
import SignupDone from './Common/SignupDone';
import VerifyCodeStep from './Common/VerifyCodeStep';
import SignupSignup from './Common/SignupSignup';

import {
  StyledPageContent,
  StyledPageFooter,
} from '../../../../../../common/UI/Signup/Layout';
import IsAmicaRightForYou from './Common/IsAmicaRightForYou';
import SignupQuestionFlowWrapper from './Common/SignupQuestionFlowWrapper';
import QuestionFlowHeader from './Common/QuestionFlowHeader';
import AmicaLogoWrapper from './Common/AmicaLogoWrapper';
import CloseButton from './Common/CloseButton';
import BackgroundShapes from './Common/BackgroundShapes';

class Join extends React.Component {
  constructor(props) {
    super(props);

    this.steps = [
      {
        step: 'intro',
        stepNumber: 0,
        showProgress: false,
        theme: darkerTheme,
        backgroundShapes: false,
      },
      {
        step: 'is-amica-right-for-you',
        stepNumber: 0,
        showProgress: true,
        backgroundShapes: true,
      },
      {
        step: 'questions',
        stepNumber: 1,
        showProgress: true,
        hideBackButton: true,
        backgroundShapes: true,
        noLeftPadding: true,
      },
      {
        step: 'signup',
        stepNumber: signupPartyB.length + 1,
        showProgress: true,
        backgroundShapes: true,
      },
      {
        step: 'verify',
        stepNumber: signupPartyB.length + 2,
        showProgress: true,
        hideBackButton: true,
        backgroundShapes: true,
      },
      {
        step: 'thanks',
        showProgress: true,
        hideBackButton: true,
        backgroundShapes: false,
      },
    ];

    // Props carried over from loading the invite on the link page
    const carriedState = props.location && props.location.state;
    const firstnameSupplied = carriedState && carriedState.firstname;
    const emailSupplied = carriedState && carriedState.email;
    const InviteID = carriedState && carriedState.InviteID;

    this.state = {
      step: false,
      verificationError: null,
      signupError: null,
      questionsInput: {},
      questions: signupPartyB,
      numberOfSteps: signupPartyB.length + 3,
      currentStep: 0,
      loadingRegisterUser: false,
      firstnameSupplied,
      emailSupplied,
      InviteID,
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.onQuestionsSubmit = this.onQuestionsSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    const { InviteID, firstnameSupplied, emailSupplied } = this.state;
    const { navigate } = this.props;

    if (!InviteID || !firstnameSupplied || !emailSupplied) {
      navigate(ODRS_INVITE_ROUTE);
    }

    const questionsDefaultValues = signupPartyB.reduce(
      (values, question) => ({
        ...values,
        [question.name]: question.defaultValue,
      }),
      {}
    );

    if (firstnameSupplied) {
      const legalname = { firstname: firstnameSupplied };
      questionsDefaultValues.legalname = legalname;
    }

    if (emailSupplied) {
      questionsDefaultValues.email = emailSupplied;
    }

    this.setState({
      questionsInput: {
        ...questionsDefaultValues,
        InviteID,
      },
      step: this.steps[0],
    });
  }

  // change the theme for the current step if needed
  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    const { setTheme } = this.context;
    if (this.steps.indexOf(prevState.step) !== this.steps.indexOf(step)) {
      setTheme(step.theme || theme);
    }
  }

  onInputChange(key, value) {
    const questionsInput = {
      ...this.state.questionsInput, // eslint-disable-line
      [key]: value,
    };

    this.setState({ questionsInput });
  }

  onQuestionsSubmit() {
    this.goNext();
    return Promise.resolve();
  }

  firstNameConfirmed() {
    return this.state.confirmfirstname; // eslint-disable-line
  }

  emailConfirmed() {
    return this.state.confirmemail; // eslint-disable-line
  }

  goNext() {
    const { currentStep, step } = this.state;
    const cur = this.steps.indexOf(step);
    if (this.steps[cur + 1]) {
      this.setState({
        step: this.steps[cur + 1],
        currentStep: currentStep + 1,
      });
    }
  }

  goBack() {
    const { navigate } = this.props;
    const { currentStep, step } = this.state;
    const cur = this.steps.indexOf(step);

    if (this.steps[cur - 1]) {
      this.setState({
        step: this.steps[cur - 1],
        currentStep: currentStep - 1,
      });
    } else {
      this.setState(
        {
          step: false,
        },
        () => {
          navigate(INTRO_ROUTE);
        }
      );
    }
  }

  render() {
    const {
      verificationError,
      signupError,
      step,
      questions,
      questionsInput,
      numberOfSteps,
      loadingRegisterUser,
      loadingVerification,
    } = this.state;

    const { navigate } = this.props;

    let stepComponent;

    switch (step.step) {
      case 'intro':
        stepComponent = <SignupIntro goNext={this.goNext} />;
        break;
      case 'is-amica-right-for-you':
        stepComponent = (
          <IsAmicaRightForYou
            goBack={this.goBack}
            goNext={this.goNext}
            onChange={(name, updateValue) => {
              this.onInputChange(name, updateValue);
            }}
          />
        );

        break;
      case 'questions':
        stepComponent = (
          <SignupQuestionFlowWrapper className="step-transition-fade-up">
            <QuestionsFlowWrapper
              questions={questions}
              onInputChange={(key, value, type) =>
                this.onInputChange(key, value, type)
              }
              values={questionsInput}
              onSubmit={this.onQuestionsSubmit}
              startingStepNumber={step.stepNumber}
              title="Sign up"
            />
          </SignupQuestionFlowWrapper>
        );

        break;
      case 'signup':
        stepComponent = (
          <SignupSignup
            goNext={this.goNext}
            setParentState={(s, f) => this.setState(s, f)}
            questionsInput={questionsInput}
            loadingRegisterUser={loadingRegisterUser}
            signupError={signupError}
            stepNumber={step.stepNumber}
          />
        );
        break;
      case 'verify':
        stepComponent = (
          <VerifyCodeStep
            loadingVerification={loadingVerification}
            parentState={this.state}
            setParentState={(s, f) => this.setState(s, f)}
            onChange={this.onInputChange}
            verificationError={verificationError}
            goNext={this.goNext}
            isFirstParty={false}
            startingStepNumber={step.stepNumber}
          />
        );
        break;
      case 'thanks':
        stepComponent = <SignupDone />;
        break;
      default:
        stepComponent = <p key="default">default</p>;
    }

    return (
      <ProgressBarProvider>
        {step.backgroundShapes && <BackgroundShapes />}
        <QuestionFlowHeader>
          <ReferralLink href="https://amica.gov.au">
            <AmicaLogoWrapper>
              <AmicaLogo />
            </AmicaLogoWrapper>
          </ReferralLink>

          <CloseButton onClick={() => navigate(HOME_ROUTE)}>
            &nbsp;&nbsp;Close
          </CloseButton>
        </QuestionFlowHeader>
        <StyledPageContent>
          <StepTransition
            pageKey={step.step}
            transitionTime={500}
            appear
            noLeftPadding={step.noLeftPadding}
          >
            {step.step ? stepComponent : null}
          </StepTransition>
        </StyledPageContent>

        <StyledPageFooter>
          <ProgressBar
            label="Sign up"
            currentStep={step.stepNumber}
            steps={numberOfSteps}
            hidden={!step.showProgress}
          />
        </StyledPageFooter>
      </ProgressBarProvider>
    );
  }
}

Join.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

Join.contextType = RouteContext;

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
  clearMatter: () => dispatch(clearMatterStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Join));
