import isSafari from './isSafari';

const openResolvedPromiseURL = promise => {
  // Problem
  //  Safari blocks any call to window.open inside a async.
  // Solution
  //  windowReference outside of the async and change the location in the async.
  // Note:
  //  Downside is you have a flash of white screen before the url is returned
  //  in from the server. Would like to prevent this from showing on other browsers.
  //  I added a isSafari condition so other browsers are not affected
  const isBrowserSafari = isSafari();
  let windowReference;
  if (isBrowserSafari) {
    windowReference = window.open();
  }
  return promise.then(result => {
    if (result && result.url) {
      if (isBrowserSafari) {
        windowReference.location = result.url;
      } else {
        window.open(result.url);
      }
    } else {
      // display an error message
    }
  });
};

export default openResolvedPromiseURL;
