import {
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

const getUserSession = () =>
  new Promise<CognitoUserSession>((resolve, reject) => {
    const userPool = new CognitoUserPool({
      UserPoolId: process.env.COGNITO_USER_POOL || '',
      ClientId: process.env.COGNITO_CLIENT_ID || '',
    });
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser === null) {
      reject();
    } else {
      cognitoUser.getSession(
        (err: Error | null, session: CognitoUserSession) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        }
      );
    }
  });

export const getUserSessionDetails = () =>
  getUserSession().then(session => session.getIdToken().payload);

export default getUserSession;
