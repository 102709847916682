import { ODRS_DIVISION_ASSETS_INFO } from 'Common/routes';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import React from 'react';
import { Content, Wrapper, Link } from 'Common/UI/ErrorBoundary';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import theme from 'Common/Utils/theme';

// If we need more error handling, we can make this generic
const DivisionAssetsAlreadyStarted = () => (
  <Wrapper>
    <Content>
      <ResponsiveTextContainer>
        <H1Conversational>
          Asset division offer already started
        </H1Conversational>
        <Paragraph marginBottom={`${theme.padding.medium2}px`}>
          It looks like your former partner has started an asset split offer,
          you will have to wait until they are done to make a counter offer.
        </Paragraph>
        <Link href={ODRS_DIVISION_ASSETS_INFO}>Back to Assets overview</Link>
      </ResponsiveTextContainer>
    </Content>
  </Wrapper>
);

export default DivisionAssetsAlreadyStarted;
