import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import PaymentReviewOrder from 'App/UI/Payment/PaymentReviewOrder';
import PaymentPayForm from 'App/UI/Payment/PaymentPayForm';
import PaymentConfirmation from 'App/UI/Payment/PaymentComplete';
import PaymentPastPayments from 'App/UI/Payment/PaymentPastPayments';
import PaymentViewPricing from 'App/UI/Payment/PaymentViewPricing';
import {
  ODRS_DASH_RELATIONSHIP_ROUTE,
  ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
  ODRS_PAYMENT_PAY_FORM_ROUTE,
  ODRS_PAYMENT_COMPLETE_ROUTE,
  ODRS_PAYMENT_PAST_PAYMENTS_ROUTE,
  ODRS_PAYMENT_VIEW_PRICING_ROUTE,
} from 'Common/routes';
import DiviRoute from 'Common/UI/DiviRoute';
import MatterGuard from '../Guard/MatterGuard';

const ODRSPayment = () => (
  <MatterGuard>
    <Switch>
      <DiviRoute
        path={ODRS_PAYMENT_REVIEW_ORDER_ROUTE}
        component={PaymentReviewOrder}
        noTransition
        exact
      />
      <DiviRoute
        path={ODRS_PAYMENT_PAY_FORM_ROUTE}
        component={PaymentPayForm}
        noTransition
        exact
      />
      <DiviRoute
        path={ODRS_PAYMENT_COMPLETE_ROUTE}
        component={PaymentConfirmation}
        noTransition
        exact
      />
      <DiviRoute
        path={ODRS_PAYMENT_PAST_PAYMENTS_ROUTE}
        component={PaymentPastPayments}
        noTransition
        exact
      />
      <DiviRoute
        path={ODRS_PAYMENT_VIEW_PRICING_ROUTE}
        component={PaymentViewPricing}
        noTransition
        exact
      />
      <Redirect to={ODRS_PAYMENT_VIEW_PRICING_ROUTE} />
    </Switch>
  </MatterGuard>
);
export default ODRSPayment;
