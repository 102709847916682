import React from 'react';
import PropTypes from 'prop-types';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSParentingPlanStatement = props => {
  const { baseID, ...rest } = props;
  return (
    <MatterGuard>
      <CardQuestions baseID={baseID} {...rest} />
    </MatterGuard>
  );
};

ODRSParentingPlanStatement.propTypes = {
  baseID: PropTypes.string.isRequired,
};

export default ODRSParentingPlanStatement;
