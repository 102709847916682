import React from 'react';
import styled, { ThemeProvider, css } from 'styled-components';

import PageContainer from 'Common/UI/Layout/PageContainer';
import push from 'Common/Utils/push';
import baseTheme, { greyHelpButtonTheme, Theme } from 'Common/Utils/theme';
import {
  DOC_TYPE_PROPERTY_AGREEMENT,
  DOC_TYPE_PARENTING_AGREEMENT,
  DOC_TYPE_CONSENT_ORDERS,
} from 'Common/constants';
import { isPaymentDue } from 'Common/Utils/Payments';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import IconPaymentEmpty from 'Common/Assets/images/payment-empty-background.svg';
import { Heading1 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import HelpExternalButton from 'Common/UI/Button/HelpExternalButton';
import { ParentingPriceCard, PropertyPriceCard } from './PriceCards';
import { PaymentDrawer, DrawerContent } from './common';
import { PaymentProvider } from './PaymentContext';
import PaymentHeader from './PaymentHeader';

const StyledPaymentDrawer = styled(PaymentDrawer)<{
  theme: Theme;
  withBackgroundImage: boolean;
}>`
  ${({ withBackgroundImage, theme }) =>
    withBackgroundImage &&
    css`
      background-image: url('${IconPaymentEmpty}');
      background-position: center 90%;
      background-repeat: no-repeat;
      background-size: 50% auto;

      @media ${theme.sizes.md.breakpoint} {
        background-position: center 65%;
        background-size: auto;
      }
    `}
`;

const PaymentViewPricing: React.FC = () => {
  const matter = useAppSelector(state => state.matter);
  const dispatch = useAppDispatch();

  const navigate = (route: string, state?: any, replace?: boolean) =>
    dispatch(push(route, state, replace));

  const isAnyPaymentDue =
    isPaymentDue(matter, DOC_TYPE_PROPERTY_AGREEMENT) ||
    isPaymentDue(matter, DOC_TYPE_PARENTING_AGREEMENT) ||
    isPaymentDue(matter, DOC_TYPE_CONSENT_ORDERS);

  return (
    <ThemeProvider theme={baseTheme}>
      <PaymentProvider navigate={navigate} matter={matter}>
        <PageContainer>
          <PaymentHeader matter={matter} />

          <StyledPaymentDrawer withBackgroundImage={!isAnyPaymentDue}>
            <DrawerContent>
              <ResponsiveContainer>
                <ResponsiveTextContainer>
                  <Heading1>
                    This is where you will pay for your documents
                  </Heading1>
                  <Paragraph>
                    You will only need to pay once you complete and agree on all
                    your information.
                  </Paragraph>
                </ResponsiveTextContainer>

                {isAnyPaymentDue && (
                  <FlexGrid medium={2} large={2}>
                    <PropertyPriceCard matter={matter} />
                    <ParentingPriceCard matter={matter} />
                  </FlexGrid>
                )}
                {!isAnyPaymentDue && (
                  <SpacedBox marginTop={10}>
                    <ThemeProvider theme={greyHelpButtonTheme}>
                      <HelpExternalButton href="https://www.amica.gov.au/how-it-works/pricing">
                        Learn about pricing
                      </HelpExternalButton>
                    </ThemeProvider>
                  </SpacedBox>
                )}
              </ResponsiveContainer>
            </DrawerContent>
          </StyledPaymentDrawer>
        </PageContainer>
      </PaymentProvider>
    </ThemeProvider>
  );
};

export default PaymentViewPricing;
