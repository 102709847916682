import React from 'react';
import { useAppSelector, useAppDispatch } from 'App/State/Store';
import {
  DOCUMENT_CARD_COMPLETE,
  DOCUMENT_CARD_PAYMENT_REQUIRED,
  DOC_TYPE_CONSENT_ORDERS,
  DOC_TYPE_CONSENT_ORDERS_DRAFT,
  DOC_TYPE_MINUTES_OF_CONSENT,
  DOC_TYPE_MINUTES_OF_CONSENT_DRAFT,
  DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER,
  PAGE_CONSENT_ORDERS_COMPLETED,
  SECTION_CONSENT_ORDERS,
  STATUS_APPROVED,
} from 'Common/constants';
import { hasItemBeenPaidFor, isAmicaFree } from 'Common/Utils/Payments';
import { getConsentOrderExportState } from 'App/Utils/sectionHelpers';
import { getAgreementDocURLAction } from 'App/State/MatterActions';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import openResolvedPromiseURL from 'Common/Utils/openResolvedPromiseURL';
import push from 'Common/Utils/push';
import {
  getConsentKitInstructionsURL,
  getFilingGuideURL,
} from 'Common/Utils/consentOrderLinks';
import AssetProperties from 'Common/Data/App/assetProperties';
import { checkPageUnLocked } from 'Common/Utils/consentOrderPageChecks';
import { getMatter } from 'Common/Utils/MatterProps';
import { getPage } from 'Common/Data/App/appSections';
import getDocumentCardStatus from '../getDocumentCardStatus';
import ApplicationForConsentOrders from './ApplicationForConsentOrders';

const ConsentOrdersDocumentCards: React.FC = () => {
  const matter = useAppSelector(state => state.matter);
  const dispatch = useAppDispatch();

  const isFree = isAmicaFree(matter);

  const { withLoading } = usePromiseWithLoading();

  const getProceduralFairnessLetterURL = () =>
    dispatch(
      getAgreementDocURLAction({
        name: DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER,
      })
    ).unwrap();

  const getConsentOrderDocURL = (isDraft = false) =>
    dispatch(
      getAgreementDocURLAction({
        name: isDraft ? DOC_TYPE_CONSENT_ORDERS_DRAFT : DOC_TYPE_CONSENT_ORDERS,
      })
    ).unwrap();

  const getMinutesOfConsentDocURL = (isDraft = false) =>
    dispatch(
      getAgreementDocURLAction({
        name: isDraft
          ? DOC_TYPE_MINUTES_OF_CONSENT_DRAFT
          : DOC_TYPE_MINUTES_OF_CONSENT,
      })
    ).unwrap();

  const openProceduralFairnessLetter = () =>
    openResolvedPromiseURL(
      withLoading(() => getProceduralFairnessLetterURL())
    );

  const openConsentOrderAgreement = (isDraft = false) =>
    openResolvedPromiseURL(
      withLoading(() => getConsentOrderDocURL(isDraft))
    );

  const openMinutesOfConsentAgreement = (isDraft = false) =>
    openResolvedPromiseURL(
      withLoading(() => getMinutesOfConsentDocURL(isDraft))
    );
    
  const navigate = (route: string, state?: any) => dispatch(push(route, state));

  const consentOrdersState = getDocumentCardStatus(
    DOC_TYPE_CONSENT_ORDERS,
    getConsentOrderExportState()
  );

  const { isSuperIncludedInOffer, superSplittingFlags } = AssetProperties();

  const { payerHasConfirmedNoChangesToClauses } = superSplittingFlags;

  const areMainDocumentsDisabled = !checkPageUnLocked.completed.unlocked();

  let areAdditionalDocumentsDisabled = false;
  let isInDraftMode = false;

  const { items = [] } = getMatter();

  const superannuationItem = items.find(
    item => item.SectionID === 'superannuationfundclauses'
  );

  if (isSuperIncludedInOffer) {
    if (
      !payerHasConfirmedNoChangesToClauses &&
      (!superannuationItem || superannuationItem.status !== STATUS_APPROVED)
    ) {
      isInDraftMode = true;
      areAdditionalDocumentsDisabled = true;
    }
  }

  let actualConsentOrdersState = consentOrdersState;

  // if the users are completing the final steps of superannuation splitting, we need to override the status of the
  // document card here so the users can access the draft documents before they have paid
  // all of these must be true for the status to be overridden:
  // - the card state must be in `payment required`
  // - we must be in draft mode
  // - the final page must be in a `not-completed` state (which happens when the superannuation fund clauses card is present but not approved)
  if (consentOrdersState === DOCUMENT_CARD_PAYMENT_REQUIRED && isInDraftMode) {
    const pageData = getPage(
      SECTION_CONSENT_ORDERS,
      PAGE_CONSENT_ORDERS_COMPLETED
    );

    if (pageData) {
      const { generateStatus } = pageData;

      if (generateStatus) {
        const status = generateStatus(matter);

        if (status === 'not-completed') {
          actualConsentOrdersState = DOCUMENT_CARD_COMPLETE;
        }
      }
    }
  }

  return (
    <ApplicationForConsentOrders
      isFree={isFree}
      isPaid={hasItemBeenPaidFor(matter, DOC_TYPE_CONSENT_ORDERS)}
      status={actualConsentOrdersState}
      openAgreementFunctions={{
        proceduralFairnessLetter: () => openProceduralFairnessLetter(),
        consentOrder: () => openConsentOrderAgreement(isInDraftMode),
        minutesOfConsent: () => openMinutesOfConsentAgreement(isInDraftMode),
      }}
      guideLinks={{
        applicationInstructions: getConsentKitInstructionsURL(),
        applicationGuide: getFilingGuideURL(),
      }}
      areMainDocumentsDisabled={areMainDocumentsDisabled}
      areAdditionalDocumentsDisabled={areAdditionalDocumentsDisabled}
      isInDraftMode={isInDraftMode}
      navigate={navigate}
    />
  );
};

export default ConsentOrdersDocumentCards;
