import {
  STATUS_APPROVED,
  STATUS_DISCUSSION,
  STATUS_NEW_BOTH_PARTIES,
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_REMOVED,
  STATUS_UPDATE_REQUESTED,
  STATUS_UPDATE_DONE,
  STATUS_REOPENED,
  STATUS_CREATED,
  PARTY_BOTH,
} from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import {
  youRemovedPreviousMessage,
  youReopenedPreviousMessage,
  youWereTheLastInDiscussion,
} from 'Common/Utils/getMessages';
import capitaliseText from 'Common/Utils/capitaliseText';
import { QuestionStateCard, QuestionCard } from 'Common/Data/Types/appSections';

export const generateCardHeading = (
  {
    label,
    otherName,
    cardID,
    isCreatedByYou,
    status,
    cardForParty,
    data: { owner },
  }: QuestionStateCard<QuestionCard>,
  { messages, self: { party: selfParty } }: Matter
) => {
  if (cardID === 'superannuationfundclauses') {
    return 'Your application';
  }

  switch (status) {
    case STATUS_NEW_ONLY_SELF:
    case STATUS_NEW_BOTH_PARTIES:
    case STATUS_NEW_ONLY_THEM:
    case STATUS_APPROVED:
      return label;
    case STATUS_REMOVED:
      return youRemovedPreviousMessage(messages, cardID)
        ? 'You removed this statement'
        : `${capitaliseText(otherName)} removed this statement`;
    case STATUS_DISCUSSION:
      return youWereTheLastInDiscussion(messages, cardID)
        ? `You responded to ${otherName}`
        : `${capitaliseText(otherName)} responded to you`;
    case STATUS_CREATED:
      return isCreatedByYou
        ? `Waiting for ${otherName} to view this statement`
        : `${capitaliseText(otherName)} created a statement`;
    case STATUS_REOPENED:
      return youReopenedPreviousMessage(messages, cardID)
        ? 'You reopened this statement'
        : `${capitaliseText(otherName)} reopened a statement`;
    case STATUS_UPDATE_REQUESTED:
      return isCreatedByYou
        ? `${capitaliseText(otherName)} requested an update to this statement`
        : `Waiting for ${otherName} to view your requested update`;
    case STATUS_UPDATE_DONE:
      if (cardForParty === PARTY_BOTH) {
        return owner !== selfParty
          ? `Waiting for ${otherName} to review your updated statement`
          : `${capitaliseText(otherName)} updated a statement`;
      }

      return isCreatedByYou
        ? `Waiting for ${otherName} to review your updated statement`
        : `${capitaliseText(otherName)} updated a statement`;
    default:
      return 'Status does not exist';
  }
};

export const generateJurisdictionHeading = (
  {
    label,
    otherName,
    cardID,
    isCreatedByYou,
    status,
    cardForParty,
    data: { owner },
  }: QuestionStateCard<QuestionCard>,
  { messages, self: { party: selfParty } }: Matter
) => {
  switch (status) {
    case STATUS_NEW_ONLY_SELF:
    case STATUS_NEW_BOTH_PARTIES:
    case STATUS_NEW_ONLY_THEM:
    case STATUS_APPROVED:
      return label;
    case STATUS_REMOVED:
      return youRemovedPreviousMessage(messages, cardID)
        ? 'You removed this selected jurisdiction'
        : `${capitaliseText(otherName)} removed this selected jurisdiction`;
    case STATUS_DISCUSSION:
      return youWereTheLastInDiscussion(messages, cardID)
        ? `You responded to ${otherName}`
        : `${capitaliseText(otherName)} responded to you`;
    case STATUS_CREATED:
      return isCreatedByYou
        ? `Waiting for ${otherName} to review the jurisdiction`
        : `${capitaliseText(otherName)} has selected a jurisdiction`;
    case STATUS_REOPENED:
      return youReopenedPreviousMessage(messages, cardID)
        ? 'You reopened the selected jurisdiction'
        : `${capitaliseText(otherName)} reopened the selected jurisdiction`;
    case STATUS_UPDATE_REQUESTED:
      return isCreatedByYou
        ? `${capitaliseText(
            otherName
          )} requested an update to this selected jurisdiction`
        : `Waiting for ${otherName} to view your requested update`;
    case STATUS_UPDATE_DONE:
      if (cardForParty === PARTY_BOTH) {
        return owner !== selfParty
          ? `Waiting for ${otherName} to review your updated jurisdiction selection`
          : `${capitaliseText(otherName)} updated the jurisdiction selection`;
      }

      return isCreatedByYou
        ? `Waiting for ${otherName} to review your updated jurisdiction selection`
        : `${capitaliseText(otherName)} updated the jurisdiction selection`;
    default:
      return 'Status does not exist';
  }
};
