import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ODRS_DIVISION_ASSETS_INFO,
  ODRS_DASH_PROPERTY_ROUTE,
} from 'Common/routes';
import { withRouter } from 'react-router';
import PageContent from 'Common/UI/Layout/PageContent';
import Loader from 'Common/UI/Loader/Loader';
import Carousel from 'Common/UI/Carousel';
import { CarouselButton } from 'Common/UI/Carousel/ImageSlideWrapper';
import { setPartyFlagsAction } from 'App/State/MatterActions';

import AssetTransferSlide1 from './AssetTransferSlide1';
import AssetTransferSlide2 from './AssetTransferSlide2';
import AssetTransferSlide3 from './AssetTransferSlide3';

const AssetTransferIntroCarousel = ({
  navigate,
  setHasSeenAssetTransferIntro,
  location,
}) => {
  const [loading, setLoading] = useState(false);

  const { state: { nextRoute = ODRS_DIVISION_ASSETS_INFO } = '' } = location;

  const handleClick = () => {
    setLoading(true);

    setHasSeenAssetTransferIntro()
      .then(() => {
        navigate(nextRoute);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };
  const LastSlideControls = () => (
    <CarouselButton onClick={handleClick} data-cy="carousel-finish-button">
      I understand
    </CarouselButton>
  );

  return (
    <PageContent>
      <Carousel
        LastSlideControls={LastSlideControls}
        onClose={() => navigate(ODRS_DASH_PROPERTY_ROUTE)}
      >
        <AssetTransferSlide1 />
        <AssetTransferSlide2 />
        <AssetTransferSlide3 />
      </Carousel>
      {loading && <Loader floating />}
    </PageContent>
  );
};

AssetTransferIntroCarousel.propTypes = {
  navigate: PropTypes.func.isRequired,
  setHasSeenAssetTransferIntro: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
  setHasSeenAssetTransferIntro: () =>
    dispatch(setPartyFlagsAction({ hasSeenAssetTransferIntro: true })),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(AssetTransferIntroCarousel));
