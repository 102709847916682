import React from 'react';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

export const relationshipLearnMoreContent = () => (
  <>
    <H1Conversational>About you and your relationship</H1Conversational>
    <Paragraph>
      {`You have chosen to apply for consent orders. The court requires a 
        few more details in order to make consent orders, so we will need to 
        ask you a few more questions in this section.`}
    </Paragraph>
  </>
);
