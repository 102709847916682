import React from 'react';
import formatCurrency from 'Common/Utils/formatCurrency';
import calculateMultiItemsValue from 'Common/Utils/calculateMultiItemsValue';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';
import Matter from 'Common/Data/Types/matter';

export const yourFinancesQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about your finances and personal
        belongings. These questions are just about you. We'll ask you about your
        shared finances in another section.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'intro2',
    label: 'Before you get started',
    content: () => (
      <Paragraph large>
        All the amounts you enter should use your most current values.
      </Paragraph>
    ),
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 1,
    submitButtonLabel: 'I understand',
  },
  {
    name: 'personalSavings',
    label:
      'Please list the name of your bank, the account name, and the value in each account.',
    hint: '',
    type: 'multiItems',
    defaultValue: '',
    index: 2,
    optional: true,
    formAttributes: {
      popupTitle: 'List accounts',
      textLabel: 'Bank name and account',
      hintText:
        'List the name of your bank, the account name, and the amount in each account.',
      textPlaceholder: 'E.g Westpac/Saver',
    },
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>

          <Paragraph large>
            This should be the amount you have in any bank, building society,
            credit union or other financial institution as at today&apos;s date
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'cash',
    label: 'How much do you have in cash?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 3,
  },
  {
    name: 'haveAnyShares',
    label: 'Do you have any shares?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 4,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>

          <Paragraph large>
            You can own multiple shares in different companies and you can trade
            or sell your shares to other people in the sharemarket.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'shares',
    label: 'Please list your shares and their value.',
    hint: '',
    type: 'multiItems',
    defaultValue: undefined,
    visible: answer => !!answer.haveAnyShares,
    formAttributes: {
      popupTitle: 'Shares',
      buttonText: 'Enter shares information',
      textLabel: 'Company',
      numberLabel: 'Value',
      textPlaceholder: 'Coles group',
      numberPlaceholder: 'Enter Amount',
    },
    optional: true,
    index: 5,
  },
  {
    name: 'creditCards',
    label:
      'Please list your credit cards and how much is owed on each credit card.',
    hint: '',
    type: 'multiItems',
    defaultValue: undefined,
    formAttributes: {
      popupTitle: 'Credit cards',
      buttonText: 'Enter credit cards',
      textLabel: 'Institution',
      numberLabel: 'Amount owing',
      textPlaceholder: 'e.g. Bank of SA',
      numberPlaceholder: 'Enter the current amount owing',
    },
    optional: true,
    index: 6,
  },
  {
    name: 'otherDebts',
    label:
      "Please list any other debts you had when you separated that you haven't already listed (not including mortgages).",
    hint: '',
    type: 'multiItems',
    defaultValue: undefined,
    formAttributes: {
      popupTitle: 'Other debts',
      buttonText: 'Enter other debts',
      textLabel: 'Debt type',
      numberLabel: 'Amount owing',
      textPlaceholder: 'e.g. car loan',
      numberPlaceholder: 'Enter the current amount owing',
    },
    optional: true,
    index: 7,
    help: {
      label: 'What should I list?',
      content: (
        <>
          <H1Conversational>What should I list?</H1Conversational>
          <Paragraph large>
            You should list any debts that you haven't already listed. For
            example:
          </Paragraph>
          <ul>
            <li>
              any hire-purchase agreements (please include the name of the
              lender, a description of the property or item and your share of
              the amount owned).
            </li>
            <li>
              any income tax that you owe for the current financial year and any
              unpaid amounts from previous financial years.
            </li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'otherAssets',
    label:
      "Please list any other major money or property assets you have that you haven't listed already.",
    hint: '',
    type: 'multiItems',
    defaultValue: undefined,
    formAttributes: {
      popupTitle: 'Other assets',
      buttonText: 'Enter other assets',
      textLabel: 'Description',
      numberLabel: 'Value',
      textPlaceholder: 'e.g. artwork',
    },
    optional: true,
    index: 8,
    help: {
      label: 'What should I list?',
      content: (
        <>
          <H1Conversational>What should I list?</H1Conversational>
          <Paragraph large>
            Please list any other major money or property assets you had when
            you separated that you haven't listed already.
          </Paragraph>
          <Paragraph large>
            You don't need to list any shared vehicles here. You can add those
            later in the Vehicles section.
          </Paragraph>
          <Paragraph large>
            You should list any other major money or property assets that you
            haven't already listed. For example:
          </Paragraph>
          <ul>
            <li>
              any life insurance policies that have a cash surrender value, this
              is the amount of money the policyholder will get if they cancel
              the policy. Please give the insurance company, policy number and
              the cash surrender value of your share in dollars.
            </li>
            <li>
              any interest in any other property, including leased property.
            </li>
            <li>
              any other financial resource, for example, are you expecting to
              get any money from a personal injury claim or court case or any
              property from an inheritance when someone passes away? If yes,
              please give details.
            </li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'householdItemsValue',
    label:
      'What is the total secondhand resale value of the household and personal items that you own?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 9,
    help: {
      label: 'What is a household item?',
      content: (
        <>
          <H1Conversational>What is a household item?</H1Conversational>
          <Paragraph large>A household item includes:</Paragraph>

          <ul>
            <li>pieces of furniture</li>
            <li>whitegoods</li>
            <li>kitchen appliances</li>
            <li>artwork</li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 10,
  },
];

export const yourFinancesStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.CardIndex || matter.self.party;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'cash',
    'personalSavings',
    'shares',
    'creditCards',
    'otherDebts',
    'otherAssets',
    'householdItemsValue',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(yourFinancesQuestions, data, hideSensitive);

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  // NAME has NUMBER in cash and NUMBER their personal bank accounts. They have NUMBER in shares / they do not have any shares.
  // They have a total balance owing of NUMBER on their credit cards / they do not have any credit cards.
  // The other debts NAME has are NAME (NUMBER), [repeat] / NAME does not have any other debts. The other assets NAME has are NAME (Number) they don't have any other assets.
  // They have household and personal items in their possession that have a total resale value of NUMBER.

  const personalSavingsItems = calculateMultiItemsValue(data.personalSavings);

  const personalSavings =
    (() => {
      const cashAmount = updated(
        'cash',
        redact('cash', formatCurrency(parseFloat(data.cash as string)))
      );
      if (!data.personalSavings) {
        return highlight`${firstName} has ${cashAmount} in cash and they ${updated(
          'personalSavings',
          redact('personalSavings', 'do not')
        )} have any other personal savings.`;
      }
      return highlight`${firstName} has ${cashAmount} in cash 
      and their personal saving accounts are ${updated(
        'personalSavings',
        redact('personalSavings', personalSavingsItems.string)
      )}.`;
    })() || '';

  const sharesItems = calculateMultiItemsValue(data.shares);

  const shares =
    (() => {
      if (!sharesItems.hasItems) {
        return highlight`They ${updated(
          'shares',
          redact('shares', 'do not')
        )} have any shares.`;
      }

      return highlight`Their shares at separation are ${updated(
        'shares',
        redact('shares', sharesItems.string)
      )}.`;
    })() || '';

  const creditCardsItems = calculateMultiItemsValue(data.creditCards);

  const creditCards =
    (() => {
      if (!creditCardsItems.hasItems) {
        return highlight`They ${updated(
          'creditCards',
          redact('creditCards', 'do not')
        )} have any credit cards.`;
      }

      return highlight`Their credit cards at separation are ${updated(
        'creditCards',
        redact('creditCards', creditCardsItems.string)
      )}.`;
    })() || '';

  const otherDebtsItems = calculateMultiItemsValue(data.otherDebts);

  const otherDebts =
    (() => {
      if (!otherDebtsItems.hasItems) {
        return highlight`${firstName} ${updated(
          'otherDebts',
          redact('otherDebts', 'does not')
        )} have any other debts.`;
      }

      return highlight`The other debts ${firstName} has are ${updated(
        'otherDebts',
        redact('otherDebts', otherDebtsItems.string)
      )}.`;
    })() || '';

  const otherAssetsItems = calculateMultiItemsValue(data.otherAssets);

  const otherAssets =
    (() => {
      if (!otherAssetsItems.hasItems) {
        return highlight`They ${updated(
          'otherAssets',
          redact('otherAssets', 'do not')
        )} have any other major assets.`;
      }

      return highlight`The other major assets ${firstName} has are ${updated(
        'otherAssets',
        redact('otherAssets', otherAssetsItems.string)
      )}.`;
    })() || '';

  const personalItems = highlight`They have household and personal items in their possession that 
    have a total resale value of ${updated(
      'householdItemsValue',
      redact('householdItemsValue', formatCurrency(data.householdItemsValue))
    )}.`;

  if (plainText) {
    return `${personalSavings} ${shares} ${creditCards} ${otherDebts} ${otherAssets} ${personalItems}`;
  }

  return (
    <>
      {personalSavings} {shares} {creditCards} {otherDebts} {otherAssets}{' '}
      {personalItems}
    </>
  );
};
