import React from 'react';

import NotificationsDrawer from 'Common/UI/Notifications/NotificationsDrawer';
import MatterGuard from '../Guard/MatterGuard';

const ODRSNotifications = () => (
  <MatterGuard>
    <NotificationsDrawer />
  </MatterGuard>
);

export default ODRSNotifications;
