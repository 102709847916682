import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';

import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import Odie from 'Common/UI/Odie/Odie';

import WelcomeCarousel from 'Common/UI/Welcome/WelcomeCarousel';
import AppLogo from 'App/UI/AppLogo';
import MatterGuard from './Guard/MatterGuard';

const Intro = ({ location }) => (
  <MatterGuard expectNone destination={ODRS_DASH_HOME_ROUTE}>
    <AppLogo />
    <WelcomeCarousel state={location.state} />
    <Odie />
  </MatterGuard>
);

Intro.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(Intro);
