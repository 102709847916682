import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MatterProps from 'Common/Utils/MatterProps';
import Paragraph from 'Common/UI/Text/Paragraph';

import { Heading2 } from 'Common/UI/Text/Headings';
import MobileFixedContainer from 'Common/UI/Layout/MobileFixedContainer';
import Dialog from 'Common/UI/Layout/Dialog';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import { blackTheme } from 'Common/Utils/theme';
import { MediumButton } from 'Common/UI/Button/GenericButton';
import { TwoButtonResponsiveGroup } from 'Common/UI/Button/ButtonGroup';

const StyledHeading3 = styled(Heading2)`
  margin-bottom: ${props => props.theme.padding.medium}px;
`;

const DialogSendOffer = ({ showDialog, onCancel, onSend, sendDisabled }) => (
  <MobileFixedContainer>
    <Dialog showDialog={showDialog} theme={blackTheme}>
      <FlexGrid medium={2}>
        <div>
          <StyledHeading3>
            Send your offer to {MatterProps('other.firstname')}?
          </StyledHeading3>
          <Paragraph>
            {MatterProps('other.firstname')} will either accept your offer or
            make a counter offer.
          </Paragraph>
        </div>
        <OffsetColumn>
          <TwoButtonResponsiveGroup>
            <MediumButton subtle onClick={onCancel}>
              Cancel
            </MediumButton>
            <MediumButton
              onClick={onSend}
              disabled={sendDisabled}
              data-cy="send-button"
            >
              Send
            </MediumButton>
          </TwoButtonResponsiveGroup>
        </OffsetColumn>
      </FlexGrid>
    </Dialog>
  </MobileFixedContainer>
);

DialogSendOffer.propTypes = {
  showDialog: PropTypes.bool,
  onCancel: PropTypes.func,
  onSend: PropTypes.func,
  sendDisabled: PropTypes.bool.isRequired,
};

DialogSendOffer.defaultProps = {
  showDialog: false,
  onCancel: () => {},
  onSend: () => {},
};

export default DialogSendOffer;
