import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import { Callout } from 'Common/UI/Text/Paragraph';
import {
  SlideInner,
  SlideText,
  SlideIcon,
} from 'Common/UI/Carousel/ImageSlideWrapper';
import SlideImage03 from './content-order-image-3.svg';

const ConsentOrdersSlide3 = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={SlideImage03} srcSet={`${SlideImage03} 2x`} />
    </SlideIcon>
    <SlideText data-cy="content-order-slide-1">
      <H2Conversational>
        Once you complete the questions amica will generate two documents: an
        Application for Consent Orders and Proposed Orders.
      </H2Conversational>
      <Callout>
        <b>Important</b>: Depending on your situation you may also need to
        provide the court with other documents to support your application. Keep
        note of any other documents required as you go through the questions.
      </Callout>
    </SlideText>
  </SlideInner>
);

export default ConsentOrdersSlide3;
