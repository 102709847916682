import {
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_NEW_BOTH_PARTIES,
  DATA_SECTION,
  DATA_GROUP,
  CARD_NORMAL,
  SECTION_RELATIONSHIP,
  DATA_PAGE,
  PAGE_RELATIONSHIP_MAIN,
  SECTION_PARENTING,
  FLAG_MATTER_OPTED_IN,
  MESSAGE_STATUS_STATEMENT_CREATED,
  STATUS_CREATED,
  SECTION_PROPERTY,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import {
  ODRS_RELATIONSHIP_ROUTE,
  ODRS_ABOUTYOU_ROUTE,
  ODRS_CHILDREN_ROUTE,
  ODRS_DASH_RELATIONSHIP_ROUTE,
  ODRS_BASE_ROUTE,
  ODRS_NOCHILDREN_ROUTE,
} from 'Common/routes';
import { getNextAvailableIndex } from 'Common/Utils/getNextAvailableIndex';
import IconRelationship from 'Common/Assets/images/icon-relationship.svg';
import IconChildren from 'Common/Assets/images/icon-children.svg';
import BackgroundQuestionFlowRelationship from 'Common/Assets/images/question-flow-bg-relationship.png';
import colours from 'Common/Utils/colours';
import {
  localStorageRemove,
  localStorageSave,
} from 'Common/Utils/localStorage';
import { history, reduxStore } from 'App/State/Store';
import {
  addEmptyItemAction,
  setMatterFlagsAction,
  setPartyFlagsAction,
  updateMatterSectionAction,
} from 'App/State/MatterActions';
import { getNavItemStatus } from 'Common/UI/Navigation';
import { getPageStatus, hasSectionBeenStarted } from 'App/Utils/sectionHelpers';
import getOtherParty from 'Common/Utils/getOtherParty';
import {
  aboutYouQuestions,
  aboutYouStatement,
  childrenQuestions,
  childrenStatement,
  noChildrenQuestions,
  noChildrenStatement,
  relationshipQuestions,
  relationshipStatement,
} from '../../Questions/Relationship';
import { buildCard } from '../../../buildCard';
import { CardNormal, DataSection } from '../../../../Types/appSections';
import { Item } from '../../../../Types/matter';

const generateRelationship = ({
  existingChildren,
}: {
  existingChildren?: any;
}): DataSection => ({
  type: DATA_SECTION,
  id: SECTION_RELATIONSHIP,
  title: 'About you and your relationship',
  route: ODRS_DASH_RELATIONSHIP_ROUTE,
  questionFlowBackgroundImage: BackgroundQuestionFlowRelationship,
  questionFlowBackgroundColour: colours.offWhite,
  children: [
    {
      type: DATA_PAGE,
      id: PAGE_RELATIONSHIP_MAIN,
      title: 'Your relationship',
      route: ODRS_DASH_RELATIONSHIP_ROUTE,
      generateStatus: () => {
        const { completed } = getPageStatus({
          section: SECTION_RELATIONSHIP,
          page: PAGE_RELATIONSHIP_MAIN,
        });

        return getNavItemStatus({
          isCompleted: completed,
          isLocked: false,
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'about',
          title: 'About you and your relationship',
          icon: IconRelationship,
          children: [
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'aboutyou',
              cardID: `aboutyou${MatterPropsJS('self.party', 'A')}`,
              title: 'About You',
              sensitiveTitle: `About Party ${MatterPropsJS('self.party', 'A')}`,
              questions: aboutYouQuestions,
              statement: aboutYouStatement,
              index: MatterPropsJS('self.party', 'A'),
              questionsRoute: ODRS_ABOUTYOU_ROUTE,
              updateRoute: `${ODRS_ABOUTYOU_ROUTE}/update`,
              status: STATUS_NEW_ONLY_SELF,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'relationship',
              cardID: 'relationship',
              title: 'Your relationship',
              questions: relationshipQuestions,
              statement: relationshipStatement,
              questionsRoute: ODRS_RELATIONSHIP_ROUTE,
              updateRoute: `${ODRS_RELATIONSHIP_ROUTE}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'aboutyou',
              cardID: `aboutyou${MatterPropsJS('other.party', 'B')}`,
              title: `About ${MatterPropsJS('other.firstname', 'Them')}`,
              sensitiveTitle: `About Party ${MatterPropsJS(
                'other.party',
                'B'
              )}`,
              questions: aboutYouQuestions,
              statement: aboutYouStatement,
              index: MatterPropsJS('other.party', 'B'),
              questionsRoute: ODRS_ABOUTYOU_ROUTE,
              status: STATUS_NEW_ONLY_THEM,
            }),
          ],
        },
        {
          type: DATA_GROUP,
          id: 'children',
          title: 'Children',
          isRepeatable: true,
          isIndividual: false,
          singular: 'child',
          plural: 'children',
          promptCardData: {
            // hide the `Do you have children?` card if users have started either the property or parenting dashboard (i.e. the relationship dashboard is locked)
            // this is to avoid the possibility of a broken app state in the old data model version of the app due to changes introduced in the update that deployed the 20211123 update
            visibilityCheck: () =>
              !(
                hasSectionBeenStarted({
                  section: SECTION_PROPERTY,
                  checkBothParties: true,
                }) ||
                hasSectionBeenStarted({
                  section: SECTION_PARENTING,
                  checkBothParties: true,
                })
              ),
            hasNoneState: false,
            initial: {
              title: 'Do you have children together?',
              content:
                'You will need to add any children that you and your former partner have together.',
              yesButton: 'Yes, we do',
              noButton: "We don't have children",
              onClickYes: async () => {
                const { dispatch } = reduxStore;

                await dispatch(addEmptyItemAction('children'));

                localStorageRemove(
                  `DismissedOverviewOptInCard_${SECTION_PARENTING}`
                );

                history.push(`${ODRS_BASE_ROUTE}/children/1`, {
                  previous: reduxStore.getState().router.location.pathname,
                });
              },
              onClickNo: async () => {
                const { dispatch } = reduxStore;
                const { matter } = reduxStore.getState();
                const {
                  self: { party },
                } = matter;

                const optedInFlags = matter?.flags[FLAG_MATTER_OPTED_IN] || {};

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsChildren: true })
                );

                const messageData = {
                  section: 'nochildren1',
                  owner: party,
                  message: '',
                  status: MESSAGE_STATUS_STATEMENT_CREATED,
                  checkTone: false,
                };

                const sectionData = {
                  owner: getOtherParty(party),
                  status: STATUS_CREATED,
                };

                await dispatch(
                  updateMatterSectionAction({
                    section: ['nochildren', 1],
                    sectionData,
                    messageData,
                  })
                );

                await dispatch(
                  setMatterFlagsAction({
                    [FLAG_MATTER_OPTED_IN]: {
                      ...optedInFlags,
                      SECTION_PARENTING: false,
                    },
                  })
                );

                localStorageSave(
                  `DismissedOverviewOptInCard_${SECTION_PARENTING}`,
                  true
                );
              },
            },
          },
          icon: IconChildren,
          defaultRoute: `${ODRS_CHILDREN_ROUTE}`,
          children: (() => {
            /**
             * This function builds the cards that are shown on the dashboard.
             * It includes both cards that already exist in the database, and new empty cards.
             *
             * setup.numChildren is always the number of cards we need to display, so we loop through an array of that length
             * We populate the first spots with the existing children until there are no more existing children, which can
             * happen if there are some empty children cards that haven't been completed yet. Then, we move on to generating
             * the empty cards. Cards that already exist have a known index, and this index is added to a list on each iteration.
             * When we generate the empty cards, we choose its index by selecting the lowest integer that is *not* in the list
             * of indexes that are already in use. This avoids problems of empty cards trying to re-use indexes of existing cards.
             */
            const takenIndexes: number[] = [];

            const items = MatterPropsJS('items');
            const setup =
              items.find((item: Item) => item.SectionID === 'setup') || {};

            const selfHasNoItemsChildren = MatterPropsJS(
              'self.hasNoItemsChildren',
              false
            );

            const otherHasNoItemsChildren = MatterPropsJS(
              'other.hasNoItemsChildren',
              false
            );

            if (selfHasNoItemsChildren || otherHasNoItemsChildren) {
              const noChildrenItem = items.find(
                (item: Item) => item.SectionID === 'nochildren1'
              );

              let index;
              let cardID;

              if (noChildrenItem) {
                index = noChildrenItem.CardIndex;
                cardID = noChildrenItem.SectionID;
              } else {
                index = getNextAvailableIndex(takenIndexes);
                cardID = 'nochildren1';
              }

              return [
                buildCard<CardNormal>({
                  type: CARD_NORMAL,
                  baseID: 'nochildren',
                  cardID,
                  title: 'Children',
                  sensitiveTitle: 'Children',
                  questions: noChildrenQuestions,
                  statement: noChildrenStatement,
                  index: 1,
                  isRepeatable: true,
                  questionsRoute: `${ODRS_NOCHILDREN_ROUTE}/1`,
                  updateRoute: `${ODRS_NOCHILDREN_ROUTE}/1/update`,
                  status: STATUS_NEW_BOTH_PARTIES,
                  removeCardText: 'We do not have children together',
                  removeCardConfirmText:
                    'Confirming you don’t have children together',
                }),
              ];
            }

            return Array.from(
              Array(setup.numChildren || 0),
              (_el, arrayIndex) => {
                const item = existingChildren[arrayIndex] || {};
                let index;
                let displayIndex;
                let cardID;

                if (item.SectionID) {
                  index = item.CardIndex;
                  displayIndex = index;
                  cardID = item.SectionID;
                } else {
                  index = getNextAvailableIndex(takenIndexes);
                  displayIndex = arrayIndex + 1;
                  cardID = `children${index}`;
                }

                takenIndexes.push(Number(index));

                return buildCard<CardNormal>({
                  type: CARD_NORMAL,
                  baseID: 'children',
                  cardID,
                  title: item.childName || `Child ${displayIndex}`,
                  sensitiveTitle: `Child ${displayIndex}`,
                  questions: childrenQuestions,
                  statement: childrenStatement,
                  index,
                  isRepeatable: true,
                  questionsRoute: `${ODRS_CHILDREN_ROUTE}/${index}`,
                  updateRoute: `${ODRS_CHILDREN_ROUTE}/${index}/update`,
                  status: STATUS_NEW_BOTH_PARTIES,
                });
              }
            );
          })(),
        },
      ],
    },
  ],
});

export default generateRelationship;
