import Analytics from 'Common/Utils/Analytics';

/**
 * EVENT TRACKING
 *
 * (All of these except for "back and forth / messages are managed in the GTM console.")
 * LINK: https://www.notion.so/portable/Evaluation-metrics-aafc8625e2424ee0955aea5884061c8b
 *
 * |Event description                            |Category      |Action             |Label              |Column                                                                                    |
 * |---------------------------------------------|--------------|-------------------|-------------------|------------------------------------------------------------------------------------------|
 * |Export document (parenting plan and property)|Export        |Request export     |Parenting, Property|                                                                                          |
 * |Clicking on referral links                   |External links|Click              |URL                |URL should be url being linked to eg "https://portable.com.au/foobar/baz.html"            |
 * |Email export to self                         |Export        |Click              |                   |                                                                                          |
 * |Other party confirming                       |Export        |Confirm export     |Parenting, Property|                                                                                          |
 * |Back and forth                               |Messages      |<Statement Section>|<number of rounds> |eg trackevent("Messages", "Your Finances", 1) or trackevent("Messages", "Relationship", 5)|
 *
 * */

const { trackEventGTM } = Analytics;

/**
 * MESSAGES
 * */

const MESSAGES_CAT = 'Messages';

// MESSAGES BACK AND FORTH
export const backAndForthMessagingTrackingEvent = async ({ section, rounds }) => {
  trackEventGTM({
    eventName: MESSAGES_CAT,
    cardname: section,
    // Must be string. Will break if number
    replies: `${rounds}`,
  });
}
  
