import isPageContentsUnlocked from 'App/AppState/isPageContentsUnlocked';
import { getPageAndSectionIdsContainingCard } from 'Common/Data/App/appSections';
import Matter from 'Common/Data/Types/matter';
import React, { useMemo } from 'react';
import { Redirect } from 'react-router';

const useCardCheckAccess = (matter: Matter, cardID: string) => {
  // Check to see if we can access this question flow
  const cardPageAndSection = useMemo(
    () => getPageAndSectionIdsContainingCard(cardID),
    [cardID]
  );

  if (!cardPageAndSection) return null;

  const canProceed = useMemo(
    () =>
      isPageContentsUnlocked(
        matter,
        cardPageAndSection.section.id,
        cardPageAndSection.page?.id
      ),
    [matter, cardPageAndSection]
  );
  if (!canProceed) {
    console.warn(
      `Not allowed to access ${cardID} yet, redirecting to ${
        cardPageAndSection.page?.route ?? cardPageAndSection.section.route
      }`
    );

    return (
      <Redirect
        to={cardPageAndSection.page?.route ?? cardPageAndSection.section.route}
      />
    );
  }
  return null;
};
export default useCardCheckAccess;
