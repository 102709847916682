import React from 'react';
import styled from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';
import { H2Conversational, Heading3 } from 'Common/UI/Text/Headings';
import IconExternalLink from 'Common/Assets/images/icon-external-link.svg';
import theme from 'Common/Utils/theme';
import HelpButton from 'Common/UI/Button/HelpButton';
import {
  SlideIcon,
  SlideInner,
  SlideText,
} from 'Common/UI/Carousel/ImageSlideWrapper';
import Carousel01 from './cards-image.svg';
import Carousel02 from './agreement-image.svg';
import Carousel03 from './help-you-need.svg';
import Carousel04 from './reach-an-agreement.svg';

const StyledNumber = styled(Heading3)`
  margin-bottom: ${theme.padding.xsmall}px;
  font-weight: 400;

  @media (min-width: 400px) {
    margin-bottom: ${theme.padding.small}px;
  }
`;

const StyledH2Conversational = styled(H2Conversational)`
  margin-bottom: ${theme.padding.xsmall}px;
  @media (min-width: 400px) {
    margin-bottom: ${theme.padding.xsmall}px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0;
`;

const ExternalIcon = styled.img`
  height: 1.2em;
  padding-left: 6px;
  vertical-align: text-bottom;
`;

export const WelcomeSlide01: React.FC = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={Carousel01} srcSet={`${Carousel01} 2x`} />
    </SlideIcon>
    <SlideText data-cy="welcome-slide-1">
      <StyledNumber>01</StyledNumber>
      <StyledH2Conversational>
        Agreeing on the important things
      </StyledH2Conversational>
      <StyledParagraph>
        amica asks you and your former partner questions about yourselves so you
        can create agreements that work for both of you. amica can guide you
        through separating your money and property and help you create parenting
        arrangements.
      </StyledParagraph>
    </SlideText>
  </SlideInner>
);

export const WelcomeSlide02: React.FC = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={Carousel02} srcSet={`${Carousel02} 2x`} />
    </SlideIcon>
    <SlideText>
      <StyledNumber>02</StyledNumber>
      <StyledH2Conversational>
        Working together, so you can move forward
      </StyledH2Conversational>
      <StyledParagraph>
        amica will help you work together with your former partner. You&apos;ll
        see each other&apos;s progress as you go, and you can use amica to
        message each other, and decide what&apos;s right for your situation.
      </StyledParagraph>
    </SlideText>
  </SlideInner>
);

export const WelcomeSlide03: React.FC = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={Carousel03} srcSet={`${Carousel03} 2x`} />
    </SlideIcon>
    <SlideText>
      <StyledNumber>03</StyledNumber>
      <StyledH2Conversational>
        Get the kind of help you need
      </StyledH2Conversational>
      <StyledParagraph>
        If amica isn&apos;t right for you, or you get stuck, there are options
        for you to get help.
      </StyledParagraph>

      <br />
      <br />

      <div>
        <HelpButton
          role="link"
          onClick={() => {
            window.open('https://www.amica.gov.au/help-and-support', '_blank');
          }}
          className="step-transition-fade-down"
        >
          <span>Help and support</span>{' '}
          <ExternalIcon src={IconExternalLink} alt="" />
        </HelpButton>
      </div>
    </SlideText>
  </SlideInner>
);

export const WelcomeSlide04: React.FC = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={Carousel04} srcSet={`${Carousel04} 2x`} />
    </SlideIcon>
    <SlideText>
      <StyledNumber>04</StyledNumber>
      <StyledH2Conversational>Reach an agreement</StyledH2Conversational>
      <StyledParagraph>
        Once you’re both happy, amica records what you’ve agreed about parenting
        or property and turns it into an agreement that you can download.
      </StyledParagraph>

      <br />
      <br />

      <div>
        <HelpButton
          role="link"
          onClick={() => {
            window.open('https://www.amica.gov.au/how-it-works', '_blank');
          }}
          className="step-transition-fade-down"
        >
          <span>How it works</span>{' '}
          <ExternalIcon src={IconExternalLink} alt="" />
        </HelpButton>
      </div>
    </SlideText>
  </SlideInner>
);
