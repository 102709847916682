import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import { H2Conversational } from 'Common/UI/Text/Headings';
import { Callout } from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import { ButtonsWrapper, InnerContentMiddle } from 'Common/UI/Signup/Layout';
import Actions from 'Common/UI/Actions';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

import SignupIniviteIntroAlt from './signup-inivite-intro-alt.svg';

const ImageContanier = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.padding.large}px;
  img {
    height: 147px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledHeading = styled(H2Conversational)`
  margin-bottom: ${({ theme }) => theme.padding.medium}px;
`;

const OnboardingResendInvite = ({
  inviteData: { otherName, email },
  goNext,
}) => (
  <>
    <InnerContentMiddle className="step-transition-fade-up">
      <ResponsiveTextContainer data-cy="welcome-info">
        <ImageContanier>
          <img
            alt=""
            src={SignupIniviteIntroAlt}
            srcSet={`${SignupIniviteIntroAlt} 2x`}
          />
        </ImageContanier>
        <StyledHeading>
          It looks like your former partner {otherName} has already invited you
          to use amica. Please check your email for an invite link.
        </StyledHeading>
        <Callout>
          {otherName} has already created an account and invited you to use
          amica. You will need to create your account using the invite link we
          sent to <b>{email}</b>
          .
          <br />
          <br />
          If you don’t know {otherName} or don’t want to use amica with them,
          please contact our support team at{' '}
          <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
            Ask Amica
          </ReferralLink>
        </Callout>
      </ResponsiveTextContainer>
    </InnerContentMiddle>
    <ButtonsWrapper>
      <Actions noPadding>
        <GenericButton
          reverse
          onClick={goNext}
          data-cy="invite-info-understand"
        >
          Resend Invite
        </GenericButton>
      </Actions>
    </ButtonsWrapper>
  </>
);

OnboardingResendInvite.propTypes = {
  inviteData: PropTypes.shape({
    email: PropTypes.string,
    otherName: PropTypes.string,
  }),
  goNext: PropTypes.func.isRequired,
};

OnboardingResendInvite.defaultProps = {
  inviteData: {
    otherName: 'Your Former Partner',
    email: 'your email address',
  },
};

export default OnboardingResendInvite;
