import React from 'react';
import {
  FormalInformalText,
  ParagraphNoMarginBottom,
} from 'Common/UI/Text/Paragraph';
import styled from 'styled-components';
import MatterProps from 'Common/Utils/MatterProps';
import CardWarning from './CardWarning';

const ConsentOrderDocumentUpdateWarning: React.FC = () => {
  const jurisdiction = MatterProps('consentOrders.jurisdiction', 'WA');

  if (jurisdiction === 'FCA') {
    return (
      <CardWarning>
        <ParagraphNoMarginBottom data-cy="consent-order-document-update-warning">
          The Application for Consent Orders document has recently been updated.
          <FormalInformalText as="span" marginBottom="0" large={false}>
            {' '}
            You will need to complete part A question 6 by hand before
            submitting to a court.
          </FormalInformalText>
        </ParagraphNoMarginBottom>
      </CardWarning>
    );
  }

  return null;
};

export { ConsentOrderDocumentUpdateWarning };

const Wrapper = styled.div`
  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    width: calc(100% - 16px) !important;
    max-width: calc(100% - 16px) !important;
    flex: 1 0 100% !important;
  }
`;

// when used as a card, we need to add the Wrapper so that it takes up both columns
const ConsentOrderDocumentUpdateWarningCard = () => {
  const WarningComponent = () => <ConsentOrderDocumentUpdateWarning />;

  if (WarningComponent) {
    return (
      <Wrapper>
        <WarningComponent />
      </Wrapper>
    );
  }

  return null;
};

export { ConsentOrderDocumentUpdateWarningCard };
