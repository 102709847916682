import React, { useEffect } from 'react';
import styled from 'styled-components';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2, Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { ODRS_DASH_PROPERTY_ROUTE, ODRS_PROFILE_ROUTE } from 'Common/routes';
import push from 'Common/Utils/push';
import {
  ASSET_SPLIT_OFFER_SENT,
  ASSET_SPLIT_OTHER_PARTY_COMPLETING,
  ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER,
  ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER,
  ASSET_SPLIT_VIEWED_BY_OWED_PARTY,
  SECTION_PROPERTY,
  SECTION_STATUS_COMPLETED,
  SECTION_STATUS_IN_PROGRESS,
  SECTION_STATUS_LOCKED,
  SECTION_STATUS_NOT_STARTED,
  SECTION_STATUS_OPT_IN,
  SUGGESTED_DIVISION_AGREED,
  SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER,
  SUGGESTED_DIVISION_OTHER_MADE_OFFER,
  SUGGESTED_DIVISION_READY,
  SUGGESTED_DIVISION_START,
  SUGGESTED_DIVISION_WAITING,
  SUGGESTED_DIVISION_YOU_MADE_OFFER,
} from 'Common/constants';
import { localStorageLoad, localStorageSave } from 'Common/Utils/localStorage';
import TextButton from 'Common/UI/Button/TextButton';
import getNames from 'Common/Utils/getNames';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import { generateAppStateAction } from 'App/State/AppActions';
import SectionCardWrapper from './SectionCardWrapper';
import BackgroundProperty from './bg-property.svg';
import BackgroundPropertyCompleted from './bg-property-completed.svg';

const OptedOutParagraph = styled(Paragraph)`
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const StatusParagraph = styled(Paragraph)`
  border-top: 1px solid ${({ theme }) => theme.colours.clickableGrey};
  margin-top: 0;
  padding-top: ${({ theme }) => theme.padding.small}px;
`;

type Props = {
  stats: {
    notStarted: number;
    waitingSelf: number;
    waitingOther: number;
  };
  progress?: number;
  navigate: (path: string, state?: any) => void;
  heading: JSX.Element;
};

const InProgressCard = ({
  stats: { notStarted, waitingSelf, waitingOther },
  progress,
  navigate,
  heading,
}: Props) => {
  const {
    app: {
      appState: { appState },
    },
    matter,
  } = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  const names = getNames();
  let content;

  useEffect(() => {
    dispatch(generateAppStateAction({ matter, section: SECTION_PROPERTY }));
  }, [matter]);

  switch (appState) {
    case SUGGESTED_DIVISION_START:
    case SUGGESTED_DIVISION_READY:
      content = (
        <StatusParagraph>
          You can now get started on the suggested division
        </StatusParagraph>
      );
      break;

    case SUGGESTED_DIVISION_WAITING:
      content = (
        <StatusParagraph>
          Waiting on {names.other} to also confirm they are ready to move to the
          suggested division
        </StatusParagraph>
      );
      break;

    case SUGGESTED_DIVISION_YOU_MADE_OFFER:
      content = (
        <StatusParagraph>
          Waiting on {names.other} to review your suggested division offer.
        </StatusParagraph>
      );
      break;

    case SUGGESTED_DIVISION_OTHER_MADE_OFFER:
      content = (
        <StatusParagraph>
          {names.other} has already viewed amica&apos;s suggested offer and has
          negotiated their own offer for you to respond to
        </StatusParagraph>
      );
      break;

    case SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER:
      content = (
        <StatusParagraph>
          {names.other} has viewed your offer and has made a counter offer for
          you to respond to
        </StatusParagraph>
      );
      break;

    case SUGGESTED_DIVISION_AGREED:
      content = (
        <StatusParagraph>
          You can now get started on dividing your assets
        </StatusParagraph>
      );
      break;

    case ASSET_SPLIT_OTHER_PARTY_COMPLETING:
      content = (
        <StatusParagraph>
          {names.other} is completing the asset division section
        </StatusParagraph>
      );
      break;

    case ASSET_SPLIT_VIEWED_BY_OWED_PARTY:
    case ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER:
      content = (
        <StatusParagraph>
          {names.other} has made a proposal for how you can divide your assets
          to reach your agreed percentages
        </StatusParagraph>
      );
      break;

    case ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER:
      content = (
        <StatusParagraph>
          {names.other} has made a counter proposal for how you can divide your
          assets to reach your agreed percentages
        </StatusParagraph>
      );
      break;

    case ASSET_SPLIT_OFFER_SENT:
      content = (
        <StatusParagraph>
          {names.other} is viewing your asset division proposal
        </StatusParagraph>
      );
      break;

    default: {
      const waitingOnOther = waitingOther > 0 && waitingSelf + notStarted === 0;

      content = (
        <>
          {waitingSelf > 0 && (
            <StatusParagraph>
              You have <strong>{waitingSelf}</strong> statements to review
            </StatusParagraph>
          )}
          {notStarted > 0 && (
            <StatusParagraph>
              You have <strong>{notStarted}</strong> required statements
              remaining
            </StatusParagraph>
          )}
          {waitingOnOther && (
            <StatusParagraph>
              Waiting on {names.other} to review your statements.
            </StatusParagraph>
          )}
        </>
      );

      break;
    }
  }

  return (
    <SectionCardWrapper
      progress={progress}
      route={ODRS_DASH_PROPERTY_ROUTE}
      headerImage={BackgroundProperty}
      dataCy="overview-card-property-in-progress"
      footer={
        <GenericButton
          fullWidth
          onClick={() => navigate(ODRS_DASH_PROPERTY_ROUTE)}
        >
          Review
        </GenericButton>
      }
    >
      {heading}
      {content}
    </SectionCardWrapper>
  );
};

type PropertySectionCardProps = {
  status: string;
  progress?: number;
  stats: {
    notStarted: number;
    waitingSelf: number;
    waitingOther: number;
  };
  isSectionReadyToBeStarted?: boolean;
  toggleOptIn: (toggle: boolean) => void;
};

const PropertySectionCard = ({
  status,
  progress,
  stats,
  toggleOptIn,
  isSectionReadyToBeStarted = true,
}: PropertySectionCardProps) => {
  const dispatch = useAppDispatch();

  const navigate = (to: string, state?: any) => dispatch(push(to, state));

  const heading = <Heading2>Dividing your money and property</Heading2>;

  switch (status) {
    case SECTION_STATUS_OPT_IN:
      if (localStorageLoad(`DismissedOverviewOptInCard_${SECTION_PROPERTY}`)) {
        return (
          <>
            <Heading3>Property</Heading3>
            <OptedOutParagraph>
              You have opted out of the property section. To opt back in, please
              visit your <Link to={ODRS_PROFILE_ROUTE}>account</Link> page.
            </OptedOutParagraph>
          </>
        );
      }

      return (
        <SectionCardWrapper
          hideProgressBar
          dataCy="overview-card-property-opt-in"
          footer={
            <>
              <GenericButton
                fullWidth
                onClick={() => toggleOptIn(true)}
                subtle={!isSectionReadyToBeStarted}
              >
                Create statements for your money and property
              </GenericButton>
              <TextButton
                fullWidth
                onClick={() => {
                  toggleOptIn(false);
                  localStorageSave(
                    `DismissedOverviewOptInCard_${SECTION_PROPERTY}`,
                    true
                  );
                }}
              >
                Decide later
              </TextButton>
            </>
          }
        >
          {heading}
          <Paragraph>
            You will need to reach agreement on statements about your assets,
            liabilities and agree to a division of your assets.
          </Paragraph>
        </SectionCardWrapper>
      );
    case SECTION_STATUS_NOT_STARTED:
      return (
        <SectionCardWrapper
          hideProgressBar
          route={ODRS_DASH_PROPERTY_ROUTE}
          headerImage={BackgroundProperty}
          dataCy="overview-card-property-not-started"
          footer={
            <GenericButton
              fullWidth
              onClick={() => navigate(ODRS_DASH_PROPERTY_ROUTE)}
            >
              Get started
            </GenericButton>
          }
        >
          {heading}
          <Paragraph>
            You have sections to fill out and statements to agree on.
          </Paragraph>
        </SectionCardWrapper>
      );
    case SECTION_STATUS_LOCKED:
      return (
        <SectionCardWrapper
          hideProgressBar
          route={ODRS_DASH_PROPERTY_ROUTE}
          headerImage={BackgroundProperty}
          dataCy="overview-card-property-not-started"
          footer={
            <GenericButton
              fullWidth
              onClick={() => navigate(ODRS_DASH_PROPERTY_ROUTE)}
              subtle
            >
              Create statements for your money and property
            </GenericButton>
          }
        >
          {heading}
        </SectionCardWrapper>
      );
    case SECTION_STATUS_IN_PROGRESS:
      return (
        <InProgressCard
          progress={progress}
          stats={stats}
          navigate={navigate}
          heading={heading}
        />
      );
    case SECTION_STATUS_COMPLETED:
      return (
        <SectionCardWrapper
          progress={100}
          route={ODRS_DASH_PROPERTY_ROUTE}
          headerImage={BackgroundPropertyCompleted}
          dataCy="overview-card-property-completed"
          footer={
            <GenericButton
              subtle
              fullWidth
              onClick={() => navigate(ODRS_DASH_PROPERTY_ROUTE)}
            >
              View
            </GenericButton>
          }
        >
          {heading}
          <Paragraph>Great! You have both completed this section.</Paragraph>
        </SectionCardWrapper>
      );
    default:
      return null;
  }
};

export default PropertySectionCard;
