import RelationshipSectionCard from './RelationshipSectionCard';
import ParentingSectionCard from './ParentingSectionCard';
import PropertySectionCard from './PropertySectionCard';
import ConsentOrderSectionCard from './ConsentOrderSectionCard';

export default {
  Relationship: RelationshipSectionCard,
  Parenting: ParentingSectionCard,
  Property: PropertySectionCard,
  ConsentOrder: ConsentOrderSectionCard,
};
