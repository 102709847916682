import React from 'react';
import PropTypes from 'prop-types';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

export const HelpLineContent = ({ legalHelpAbout = 'your situation' }) => (
  <>
    {`If you would like to get legal help about ${legalHelpAbout}, you 
    can call the free `}
    <ReferralLink href="https://www.amica.gov.au/legal-help-line.html">
      Legal Help Line
    </ReferralLink>{' '}
    in your state or territory.
  </>
);

HelpLineContent.propTypes = {
  legalHelpAbout: PropTypes.string,
};

HelpLineContent.defaultProps = {
  legalHelpAbout: 'your situation',
};

export const HelpLineAmicaHelpContent = ({
  legalHelpAbout = 'your situation',
}) => (
  <>
    {`If you would like to get legal help about ${legalHelpAbout}, you 
    can call the free `}
    <ReferralLink href="https://www.amica.gov.au/legal-help-line.html">
      Legal Help Line
    </ReferralLink>{' '}
    in your state or territory, or see the amica{' '}
    <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
      Help and Support
    </ReferralLink>{' '}
    page.
  </>
);

HelpLineAmicaHelpContent.propTypes = {
  legalHelpAbout: PropTypes.string,
};

HelpLineAmicaHelpContent.defaultProps = {
  legalHelpAbout: 'your situation',
};
