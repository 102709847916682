import { PARTY_A, Party } from 'Common/constants';

/** Converts the party into the legal name that we have to use in the Minutes of Consent document (with `the ` prepended) based on passed in party.
 *
 * Party A is always the Applicant, and party B is always the Respondent
 *
 * @example
 *
 * partyLabel(PARTY_A); // The Applicant
 * partyLabel(PARTY_B); // The Respondent
 */
export const partyLabel = (party: Party) =>
  party === PARTY_A ? 'the Applicant' : 'the Respondent';

/** Converts the party into the legal name that we have to use in the Minutes of Consent document based on passed in party.
 *
 * Party A is always the Applicant, and party B is always the Respondent
 *
 * @example
 *
 * partyLabelShort(PARTY_A); // Applicant
 * partyLabelShort(PARTY_B); // Respondent
 */
export const partyLabelShort = (party: Party) =>
  party === PARTY_A ? 'Applicant' : 'Respondent';
