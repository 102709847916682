import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  BANNER_HIDE,
  BANNER_SHOW,
  STATUS_APPROVED,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_COMPLETE_PAYEE,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import AssetProperties from 'Common/Data/App/assetProperties';
import { getMatter } from 'Common/Utils/MatterProps';

export const superannuationConsentOrderChangesCompletePayeeFlags: AppState = {
  id: 'superannuationConsentOrderChangesCompletePayee',
  description:
    'Notify the payee that now that they have agreed to the clause changes, the CO is now finalised',
  check: () => {
    const { isSuperIncludedInOffer, superSplittingFlags, isPayee } =
      AssetProperties();

    const { payerHasConfirmedNoChangesToClauses } = superSplittingFlags;

    if (
      !isSuperIncludedInOffer ||
      !isPayee ||
      payerHasConfirmedNoChangesToClauses
    ) {
      return false;
    }

    const { items = [] } = getMatter();

    const superannuationItem = items.find(
      item => item.SectionID === 'superannuationfundclauses'
    );

    if (!superannuationItem || superannuationItem.status !== STATUS_APPROVED) {
      return false;
    }

    return true;
  },
  flags: {
    appState: SUPER_SPLITTING_CONSENT_ORDER_CHANGES_COMPLETE_PAYEE,
    bannerTopState: () =>
      sessionStorageLoad(
        'superannuationConsentOrderChangesCompletePayeeDismissed'
      )
        ? BANNER_HIDE
        : BANNER_SHOW,
  },
};

type Props = {
  updateAppState: () => void;
};

export const SuperannuationConsentOrderChangesCompletePayee: React.FC<Props> =
  ({ updateAppState }) => (
    <>
      <SpacedBox marginBottom={8}>
        <Heading2MarginTopNone>
          Great, you have agreed to the changes
        </Heading2MarginTopNone>
        <Paragraph>
          You are now ready to file your application for consent orders.
        </Paragraph>
      </SpacedBox>
      <GenericButton
        fullWidth
        onClick={() => {
          sessionStorageSave(
            `superannuationConsentOrderChangesCompletePayeeDismissed`,
            true
          );
          updateAppState();
        }}
        small
      >
        Got it
      </GenericButton>
    </>
  );
