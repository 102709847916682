import React from 'react';

import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import Paragraph from 'Common/UI/Text/Paragraph';

import { getChildrenFromItems } from 'Common/Utils/getChildrenData';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';

// Note any disabled props will not be saved into the database
export const childrenQuestionsContinue: QuestionAny[] = [
  {
    index: 0,
    name: 'childFullName',
    label: (value: any) => `What is ${value.childName}'s full legal name?`,
    type: 'FirstLastName',
    sensitive: true,
    defaultValue: data => ({ firstname: data.childName, lastname: '' }),
  },
  {
    name: 'childGender',
    label: (values: any) => `What is ${values.childName}'s gender`,
    hint: '',
    type: 'select',
    options: MatterPropsJS => {
      const jurisdiction = MatterPropsJS('consentOrders.jurisdiction', 'WA');

      if (jurisdiction === 'FCA') {
        return [
          { label: 'Male', value: 'Male' },
          { label: 'Female', value: 'Female' },
          { label: 'Other', value: 'Other' },
          { label: 'Prefer not to say', value: 'PreferNotToSay' },
        ];
      }

      return [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
      ];
    },
    help: {
      label: 'Why are you asking?',
      content: (
        <Paragraph large>
          If you are filing in Western Australia we need to ask this question
          because it is required by the courts to fill out your consent order.
          Currently, the WA form does not provide options other than Male or
          Female. If you are filing in the FCFCOA, you do not have to specify
          gender or pronouns unless you want to. If you choose not to specify,
          we will use they/them in our statements.
        </Paragraph>
      ),
    },
    defaultValue: '',
    index: 1,
  },
  {
    index: 2,
    name: 'pronoun',
    label: 'What is their preferred pronoun?',
    type: 'select',
    options: MatterPropsJS => {
      const jurisdiction = MatterPropsJS('consentOrders.jurisdiction', 'WA');

      if (jurisdiction === 'FCA') {
        return [
          { label: 'He/him', value: 'He/him' },
          { label: 'She/her', value: 'She/her' },
          { label: 'They/them', value: 'They/them' },
        ];
      }

      return [
        { label: 'He/him', value: 'He/him' },
        { label: 'She/her', value: 'She/her' },
      ];
    },
    help: {
      label: 'Why are you asking?',
      content: (
        <Paragraph large>
          If you are filing in Western Australia we need to ask this question
          because it is required by the courts to fill out your consent order.
          Currently, the WA form does not provide options other than Male or
          Female. If you are filing in the FCFCOA, you do not have to specify
          gender or pronouns unless you want to. If you choose not to specify,
          we will use they/them in our statements.
        </Paragraph>
      ),
    },
  },
  {
    index: 3,
    name: 'fatherOrMother',
    label: 'Are you the father or mother?',
    type: 'select',
    options: () => [
      { label: 'Mother', value: 'Mother' },
      { label: 'Father', value: 'Father' },
    ],
  },
  {
    index: 4,
    name: 'otherCarer',
    label: 'Does anyone else live with the child when they are in your care?',
    type: 'yesno',
  },
  {
    index: 5,
    name: 'otherCarerName',
    label: 'What is their name?',
    type: 'FirstLastName',
    visible: answer => !!answer.otherCarer,
  },
  {
    index: 6,
    name: 'otherCarerRelationship',
    label: 'What is their relationship to the child?',
    type: 'text',
    visible: answer => !!answer.otherCarer,
  },
  {
    index: 7,
    name: 'otherCarerAge',
    label: "What's their age?",
    type: 'text',
    visible: answer => !!answer.otherCarer,
  },
  {
    index: 8,
    name: 'otherCarerGender',
    label: 'What is their gender?',
    type: 'select',
    options: MatterPropsJS => {
      const jurisdiction = MatterPropsJS('consentOrders.jurisdiction', 'WA');

      // LSC asked us to change this question from pronoun to gender
      // since changing this would break it for existing users, we instead opted to just change the question description and labels of the options to represent gender
      // it means the question name and values still represent pronouns, but we translate that into gender when generating the CO document anyway
      if (jurisdiction === 'FCA') {
        return [
          { label: 'Male', value: 'He/him' },
          { label: 'Female', value: 'She/her' },
          { label: 'Other', value: 'They/them' },
        ];
      }

      return [
        { label: 'Male', value: 'He/him' },
        { label: 'Female', value: 'She/her' },
      ];
    },
    visible: answer => !!answer.otherCarer,
  },
  {
    index: 9,
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
  },
];

export const childrenStatementContinue = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.itemNotInDatabase ? matter.self.party : data.creator;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'otherCarer',
    'fatherOrMother',
    'childFullName',
    'otherCarerName',
    'otherCarerAge',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    childrenQuestionsContinue,
    data,
    hideSensitive
  );

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  const {
    fatherOrMother,
    childFullName = { firstname: '', lastname: '' },
    otherCarerName = { firstname: '', lastname: '' },
    otherCarerAge,
    otherCarer,
  } = data;

  let parentalType;
  if (fatherOrMother === 'Father') {
    parentalType = 'father';
  } else if (fatherOrMother === 'Mother') {
    parentalType = 'mother';
  }

  let name;
  if (childFullName) {
    name = highlight`${updated(
      'childFullName',
      redact(
        'childFullName',
        `${childFullName.firstname} ${childFullName.lastname}`
      )
    )}`;
  }

  const childStatement = highlight`${firstName} is the ${updated(
    'fatherOrMother',
    parentalType
  )} of ${name}.`;

  const childPlural =
    getChildrenFromItems(matter.items).length > 1 ? 'children' : 'child';

  let otherCarerStatement;

  if (otherCarer) {
    otherCarerStatement = highlight`${updated(
      ['otherCarer', 'otherCarerName', 'otherCarerAge'],
      redact(
        'otherCarerName',
        `${otherCarerName.firstname} ${otherCarerName.lastname} (${otherCarerAge})`
      )
    )} lives with them when they are looking after the ${childPlural}. The relationship of this person to the child is ${updated(
      'otherCarerRelationship',
      redact('otherCarerRelationship')
    )}.`;
  } else {
    otherCarerStatement = highlight`${updated(
      'otherCarerName',
      'No one else'
    )} lives with them when they are looking after the ${childPlural}.`;
  }

  if (plainText) {
    return `${childStatement} ${otherCarerStatement}`;
  }

  return (
    <>
      {childStatement} {otherCarerStatement}
    </>
  );
};
