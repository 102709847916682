import React from 'react';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';

const DivisionInvalidAmountHelp = (
  <>
    <H1Conversational>
      Why may this not be considered fair and reasonable?
    </H1Conversational>
    <Callout>
      {`amica’s artificial intelligence is based on factors lawyers and courts 
      consider to achieve a fair and reasonable outcome.`}
    </Callout>
    <Paragraph>
      {`In proposing a suggested division, amica’s artificial intelligence 
        considers a number of factors including the length of the relationship, 
        age and health, how much each party earns, and the financial and 
        non-financial contributions made to the relationship.`}
    </Paragraph>
    <Paragraph>
      {`A significant change outside of amica’s suggestion may not be consistent 
      with how legal principles may be applied in your circumstances.`}
    </Paragraph>
  </>
);

export default DivisionInvalidAmountHelp;
