import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { orangeTheme } from 'Common/Utils/theme';
import IconConsentOrder from 'Common/Assets/images/icon-consent-order.svg';
import Paragraph from 'Common/UI/Text/Paragraph';
import HelpExternalButton from 'Common/UI/Button/HelpExternalButton';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import GenericButton from 'Common/UI/Button/GenericButton';
import { Label } from 'Common/UI/Text/Label';
import {
  DocumentCardStatus,
  DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  DOCUMENT_CARD_COMPLETE,
  DOCUMENT_CARD_PAYMENT_REQUIRED,
  DOC_TYPE_CONSENT_ORDERS,
  AGREEMENT_PAYMENTS_LUT,
} from 'Common/constants';
import DownloadButton from 'Common/UI/Button/DownloadButton';
import { ODRS_PAYMENT_REVIEW_ORDER_ROUTE } from 'Common/routes';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import isSafari from 'Common/Utils/isSafari';
import {
  CardWrapper,
  CardHeader,
  TickText,
  TickItem,
  CardContent,
  CardFooterWithDivider,
  SafariDownloadNotice,
} from '../common';

const ButtonGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  > * {
    margin-bottom: ${({ theme }) => theme.spacing[3]};
  }
`;

const StyledReferralLink = styled(ReferralLink)`
  display: flex;
`;

type Props = {
  isFree: boolean;
  isPaid: boolean;
  status: DocumentCardStatus;
  openAgreementFunctions: {
    proceduralFairnessLetter: () => Promise<void>;
    consentOrder: () => Promise<void>;
    minutesOfConsent: () => Promise<void>;
  };
  guideLinks: Record<string, string>;
  navigate: (route: string, state?: any) => void;
  areMainDocumentsDisabled: boolean;
  areAdditionalDocumentsDisabled: boolean;
  isInDraftMode: boolean;
};

const ApplicationForConsentOrders: React.FC<Props> = ({
  isFree = false,
  isPaid = false,
  status = DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  openAgreementFunctions,
  guideLinks,
  navigate,
  areMainDocumentsDisabled,
  areAdditionalDocumentsDisabled,
  isInDraftMode,
}) => {
  let price = `$${AGREEMENT_PAYMENTS_LUT[DOC_TYPE_CONSENT_ORDERS].priceExGST} +GST`;

  if (isFree) {
    price = 'Free';
  } else if (isPaid) {
    price = 'Paid';
  }

  const sharedContent = (
    <>
      <CardHeader
        icon={IconConsentOrder}
        subTitle="Court Application"
        price={price}
        title="Application for consent orders"
      />
      <CardContent>
        <Paragraph>
          When you generate an Application for Consent Orders on amica you will
          receive 4 documents.
        </Paragraph>
        <SpacedBox marginBottom={8}>
          <TickItem>
            <TickText>
              The completed court application form with the information that you
              entered on amica
            </TickText>
          </TickItem>
          <TickItem>
            <TickText>
              The orders document showing the orders that you&apos;re asking the
              Court to make about your money and property, completed with the
              information that you entered on amica
            </TickText>
          </TickItem>
          <TickItem>
            <TickText>
              Two guide documents with information about how to file the
              application at the Court.
            </TickText>
          </TickItem>
        </SpacedBox>
        <HelpExternalButton href="https://www.amica.gov.au/info-about-separating/money-and-property">
          Learn more
        </HelpExternalButton>
      </CardContent>
    </>
  );

  switch (status) {
    case DOCUMENT_CARD_PAYMENT_REQUIRED:
      return (
        <CardWrapper
          data-cy="document-card-consent-orders"
          data-cy-status="payment-required"
        >
          {sharedContent}
          <CardFooterWithDivider>
            <ThemeProvider theme={orangeTheme}>
              <GenericButton
                fullWidth
                onClick={() => navigate(ODRS_PAYMENT_REVIEW_ORDER_ROUTE)}
              >
                Pay for document
              </GenericButton>
            </ThemeProvider>

            <SpacedBox marginTop={4}>
              <Label>
                You will be able to download this document once either of you
                pay for it
              </Label>
            </SpacedBox>
          </CardFooterWithDivider>
        </CardWrapper>
      );
    case DOCUMENT_CARD_COMPLETE:
      return (
        <CardWrapper
          data-cy="document-card-consent-orders"
          data-cy-status={isInDraftMode ? 'draft' : 'complete'}
        >
          {sharedContent}
          <CardFooterWithDivider>
            <ButtonGroup data-cy="application-documents">
              <Paragraph>
                <strong>Your application documents</strong>
              </Paragraph>
              {isInDraftMode && (
                <DownloadButton
                  onClick={openAgreementFunctions.proceduralFairnessLetter}
                  disabled={areMainDocumentsDisabled}
                  subtle
                >
                  Procedural Fairness Letter
                </DownloadButton>
              )}
              <DownloadButton
                onClick={openAgreementFunctions.consentOrder}
                disabled={areMainDocumentsDisabled}
                subtle={isInDraftMode}
              >
                Application for Consent Orders{isInDraftMode && ' (Draft)'}
              </DownloadButton>
              <DownloadButton
                onClick={openAgreementFunctions.minutesOfConsent}
                disabled={areMainDocumentsDisabled}
                subtle={isInDraftMode}
              >
                Minute of Consent{isInDraftMode && ' (Draft)'}
              </DownloadButton>
            </ButtonGroup>

            <ButtonGroup data-cy="additional-information">
              <Paragraph>
                <strong>Additional information</strong>
              </Paragraph>
              <StyledReferralLink
                as={DownloadButton}
                forwardedAs="a"
                href={
                  areAdditionalDocumentsDisabled
                    ? undefined
                    : guideLinks.applicationInstructions
                }
                target="_blank"
                onClick={() => {}}
                disabled={areAdditionalDocumentsDisabled}
              >
                Court filing guide
              </StyledReferralLink>
              <StyledReferralLink
                as={DownloadButton}
                forwardedAs="a"
                href={
                  areAdditionalDocumentsDisabled
                    ? undefined
                    : guideLinks.applicationGuide
                }
                target="_blank"
                onClick={() => {}}
                disabled={areAdditionalDocumentsDisabled}
              >
                amica&apos;s filing guide
              </StyledReferralLink>
            </ButtonGroup>

            {isSafari() && (
              <SafariDownloadNotice>
                Downloading a document can sometimes take up to 15 seconds to
                load.
              </SafariDownloadNotice>
            )}
          </CardFooterWithDivider>
        </CardWrapper>
      );
    case DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE:
    default:
      return (
        <CardWrapper
          data-cy="document-card-consent-orders"
          data-cy-status="sections-incomplete"
        >
          {sharedContent}
        </CardWrapper>
      );
  }
};

export default ApplicationForConsentOrders;
