import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';

import IconAgreementDocument from 'Common/Assets/images/icon-agreement-document.svg';
import Paragraph from 'Common/UI/Text/Paragraph';
import HelpExternalButton from 'Common/UI/Button/HelpExternalButton';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import GenericButton from 'Common/UI/Button/GenericButton';
import { Label } from 'Common/UI/Text/Label';
import getNames from 'Common/Utils/getNames';
import {
  DocumentCardStatus,
  DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE,
  DOCUMENT_CARD_COMPLETE,
  DOCUMENT_CARD_SELF_REQUESTED,
  DOCUMENT_CARD_OTHER_REQUESTED,
} from 'Common/constants';
import IconClock from 'Common/Assets/images/icon-clock.svg';
import TextButton from 'Common/UI/Button/TextButton';
import { Column } from 'Common/UI/Layout/Flex';
import { orangeTheme, cardThemes } from 'Common/Utils/theme';
import DownloadButton from 'Common/UI/Button/DownloadButton';
import colours from 'Common/Utils/colours';
import isSafari from 'Common/Utils/isSafari';
import IconOther from 'Common/Assets/images/icon-users-other.svg';
import {
  CardWrapper,
  CardHeader,
  TickText,
  TickItem,
  CardContent,
  CardFooterWithDivider,
  CardFooterGrey,
  WaitingLabel,
  SafariDownloadNotice,
} from '../common';
import ParentingPlanConfirmPopup from './ParentingPlanConfirmPopup';

type Props = {
  status: DocumentCardStatus;
  setPartyFlags: (flags: Record<string, boolean>) => Promise<void>;
  openAgreementFunctions: Record<string, () => Promise<void>>;
};

const ParentingPlan: React.FC<Props> = ({
  status = DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  setPartyFlags,
  openAgreementFunctions,
}) => {
  const [isConfirmDrawerOpen, setIsConfirmDrawerOpen] = useState(false);
  const names = getNames();

  const sharedContent = (
    <>
      <CardHeader
        icon={IconAgreementDocument}
        subTitle="Plan"
        price="No extra cost"
        title="Parenting plan"
      />
      <CardContent>
        <Paragraph>
          Once you have created a Parenting Agreement, you have the option to
          turn your Parenting Agreement into a Parenting Plan by adding your
          digital signatures. It is not essential, but a Parenting Plan holds
          more weight than a Parenting Agreement.
        </Paragraph>
        <SpacedBox marginBottom={8}>
          <TickItem>
            <TickText>
              Covers the same things as your Parenting Agreement
            </TickText>
          </TickItem>
          <TickItem>
            <TickText>
              Includes digital signatures and date, to provide more legal weight
            </TickText>
          </TickItem>
          <TickItem>
            <TickText>No extra cost</TickText>
          </TickItem>
          <TickItem>
            <TickText>Gives a written record of your agreement</TickText>
          </TickItem>
        </SpacedBox>
        <HelpExternalButton href="https://www.amica.gov.au/info-about-separating/parenting-arrangements">
          Learn more
        </HelpExternalButton>
      </CardContent>
      <ParentingPlanConfirmPopup
        isVisible={isConfirmDrawerOpen}
        closeDrawer={() => setIsConfirmDrawerOpen(false)}
        setPartyFlags={setPartyFlags}
      />
    </>
  );

  switch (status) {
    case DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE:
    default:
      return <CardWrapper disabled>{sharedContent}</CardWrapper>;
    case DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE:
      return (
        <CardWrapper>
          {sharedContent}
          <CardFooterWithDivider>
            <ThemeProvider theme={orangeTheme}>
              <GenericButton
                fullWidth
                onClick={() => setIsConfirmDrawerOpen(true)}
              >
                Turn into parenting plan
              </GenericButton>
            </ThemeProvider>

            <SpacedBox marginTop={8}>
              <Label>
                {names.other} will also need to agree to creating a parenting
                plan
              </Label>
            </SpacedBox>
          </CardFooterWithDivider>
        </CardWrapper>
      );
    case DOCUMENT_CARD_SELF_REQUESTED:
      return (
        <CardWrapper borderColour={colours.midPurple}>
          {sharedContent}
          <CardFooterGrey>
            <WaitingLabel>
              <img src={IconClock} alt="" /> Waiting for {names.other} to agree
              to turning your parenting agreement into a parenting plan
            </WaitingLabel>
            <GenericButton
              subtle
              small
              onClick={() => setPartyFlags({ canExportParentingPlan: false })}
            >
              Undo
            </GenericButton>
          </CardFooterGrey>
        </CardWrapper>
      );
    case DOCUMENT_CARD_OTHER_REQUESTED:
      return (
        <CardWrapper borderColour={colours.midTeal}>
          {sharedContent}
          <CardFooterGrey>
            <WaitingLabel>
              <img src={IconOther} alt="" /> {names.other} has requested to turn
              your parenting agreement into a parenting plan
            </WaitingLabel>
            <Column>
              <ThemeProvider theme={cardThemes.other}>
                <GenericButton
                  fullWidth
                  onClick={() => setIsConfirmDrawerOpen(true)}
                >
                  Agree to create parenting plan
                </GenericButton>
                <TextButton
                  fullWidth
                  onClick={() =>
                    setPartyFlags({ notReadyToExportParentingPlan: true })
                  }
                >
                  I do not agree
                </TextButton>
              </ThemeProvider>
            </Column>
          </CardFooterGrey>
        </CardWrapper>
      );
    case DOCUMENT_CARD_COMPLETE:
      return (
        <CardWrapper>
          {sharedContent}
          <CardFooterWithDivider>
            <DownloadButton onClick={openAgreementFunctions.parentingPlan}>
              Download parenting plan
            </DownloadButton>

            {isSafari() && (
              <SafariDownloadNotice>
                Downloading a document can sometimes take up to 15 seconds to
                load.
              </SafariDownloadNotice>
            )}
          </CardFooterWithDivider>
        </CardWrapper>
      );
  }
};

export default ParentingPlan;
