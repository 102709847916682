/* USER ACTIONS */
export const ENABLEMFA_USER_SUCCESS = 'ENABLEMFA_USER_SUCCESS';
export const ENABLEMFA_USER_FAILURE = 'ENABLEMFA_USER_FAILURE';

export const REMEMBER_DEVICE_USER_SUCCESS = 'REMEMBER_DEVICE_USER_SUCCESS';
export const REMEMBER_DEVICE_USER_FAILURE = 'REMEMBER_DEVICE_USER_FAILURE';

/* MATTER ACTIONS */
export const LOAD_MATTER_SUCCESS = 'LOAD_MATTER_SUCCESS';

/* APPSTATE ACTIONS */
export const GENERATE_APP_STATE = 'GENERATE_APP_STATE';
