import React, { ReactNode } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Theme, greyTextTheme } from 'Common/Utils/theme';
import IconOrangeTick from 'Common/Assets/images/icon-tick-orange.svg';
import { Row } from 'Common/UI/Layout/Flex';
import { HighlightedLabel, Label } from 'Common/UI/Text/Label';
import { Heading2 } from 'Common/UI/Text/Headings';

export const CardWrapper = styled.div<{
  theme: Theme;
  borderColour?: string;
  disabled?: boolean;
}>`
  padding: ${({ theme }) => theme.spacing[4]};
  border-radius: 4px;
  border: 2px solid
    ${({ borderColour, theme }) => borderColour || theme.colours.lightGrey};
  background: ${({ disabled, theme }) =>
    disabled ? theme.colours.offWhite : theme.colours.white};
  max-width: 400px;
  display: inline-flex;
  flex-direction: column;
`;

const StyledLabel = styled(HighlightedLabel)`
  display: inline-block;
`;

const StyledCardHeader = styled.div<{
  theme: Theme;
}>`
  padding-bottom: ${({ theme }) => theme.spacing[4]};
`;

const StyledIcon = styled.img`
  margin-right: ${({ theme }) => theme.spacing[3]};
`;

const StyledHeading2 = styled(Heading2)<{
  theme: Theme;
}>`
  margin: ${({ theme }) => theme.spacing[4]} 0 0;
`;

export const CardHeader: React.FC<{
  icon: string;
  subTitle: ReactNode;
  price: ReactNode;
  title: ReactNode;
}> = ({ icon, title, subTitle, price }) => (
  <StyledCardHeader>
    <Row justifyContent="space-between">
      <Row>
        <StyledIcon src={icon} alt="" />
        <StyledLabel>{subTitle}</StyledLabel>
      </Row>
      <HighlightedLabel>{price}</HighlightedLabel>
    </Row>
    <Row>
      <StyledHeading2>{title}</StyledHeading2>
    </Row>
  </StyledCardHeader>
);

const TickRow = styled(Row)<{
  theme: Theme;
}>`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const TickItem: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <TickRow>
    <img alt="" aria-hidden src={IconOrangeTick} />
    {children}
  </TickRow>
);

export const TickText = styled(Label)<{
  theme: Theme;
}>`
  padding-left: ${({ theme }) => theme.spacing[4]};
`;

const StyledCardContent = styled.div`
  flex: 1;
`;

export const CardContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <ThemeProvider theme={greyTextTheme}>
    <StyledCardContent>{children}</StyledCardContent>
  </ThemeProvider>
);

export const CardFooterWithDivider = styled.div<{
  theme: Theme;
}>`
  margin-top: ${({ theme }) => theme.spacing[6]};
  padding-top: ${({ theme }) => theme.spacing[6]};
  border-top: 1px solid ${({ theme }) => theme.colours.lightGrey};
`;

export const CardFooterGrey = styled.div`
  margin: ${({ theme }) =>
    `${theme.spacing[4]} -${theme.spacing[4]} -${theme.spacing[4]}`};
  padding: ${({ theme }) => theme.spacing[4]};
  background: ${({ theme }) => theme.colours.offWhite};
`;

export const WaitingLabel = styled(Label)`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing[6]};

  img {
    margin-right: ${({ theme }) => theme.spacing[3]};
  }
`;

export const SafariDownloadNotice = styled(Label)<{
  theme: Theme;
}>`
  margin-top: ${({ theme }) => theme.spacing[6]};
  color: ${({ theme }) => theme.colours.midDarkGrey};
`;
