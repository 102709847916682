import { PARTY_A, PARTY_B } from 'Common/constants';
import { getFirstName } from 'Common/Utils/statementHelpers';

export default [
  {
    name: 'schedule',
    type: 'customComponent',
    index: 0,
  },
];

export const generateScheduleStatement = ({
  matter,
  data,
  plainText = false,
  hideSensitive = false,
}) => {
  const { schedule = [] } = data;
  const cycleLength = schedule.length / 7;
  const week = ['', 'weekly', 'two week', 'three week', 'four week'][
    cycleLength
  ];

  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const partyNames = {
    A: getFirstName(matter.partyA, PARTY_A, hideSensitive),
    B: getFirstName(matter.partyB, PARTY_B, hideSensitive),
  };

  const cycleContent = `On a repeating ${week} cycle, the children will spend: `;

  let lastParty = schedule[0];
  let currentChunk = [];
  let chunkContent = '';

  schedule.forEach((p, i) => {
    const partyChange = p !== lastParty;
    if (!partyChange) {
      currentChunk.push(days[i % 7]);
    } else {
      chunkContent += currentChunk.join(', ');
      chunkContent += ` with ${partyNames[lastParty]}; `;
      currentChunk = [days[i % 7]];
      lastParty = p;
    }
  });

  chunkContent += `then ${currentChunk.join(', ')}`;
  chunkContent += ` with ${partyNames[lastParty]}. `;

  const daysWithA = schedule.filter(p => p === PARTY_A).length;
  const daysWithB = schedule.filter(p => p === PARTY_B).length;

  const totalContent = `For a total of ${daysWithA} nights with ${partyNames.A} and ${daysWithB} nights with ${partyNames.B}`;

  if (plainText) {
    return `${cycleContent} ${chunkContent} ${totalContent}`;
  }
};
