import React from 'react';
import PropTypes from 'prop-types';

const AmicaLogo = ({width}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 24" width={width}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.855 19.43c-2.354 0-4.179-1.89-4.179-4.477 0-2.554 1.825-4.478 4.18-4.478 2.321 0 4.145 1.924 4.145 4.478 0 2.586-1.824 4.477-4.146 4.477zm6.468-13.267a2.36 2.36 0 00-2.255 1.658 8.082 8.082 0 00-4.876-1.658C3.516 6.163 0 10.043 0 14.953c0 4.908 3.516 8.789 8.192 8.789a7.875 7.875 0 004.876-1.659 2.36 2.36 0 002.255 1.659 2.308 2.308 0 002.322-2.322V8.485a2.307 2.307 0 00-2.322-2.322z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.855 19.43c-2.354 0-4.179-1.89-4.179-4.477 0-2.554 1.825-4.478 4.18-4.478 2.321 0 4.145 1.924 4.145 4.478 0 2.586-1.824 4.477-4.146 4.477zm6.468-13.267a2.36 2.36 0 00-2.255 1.658 8.082 8.082 0 00-4.876-1.658C3.516 6.163 0 10.043 0 14.953c0 4.908 3.516 8.789 8.192 8.789a7.875 7.875 0 004.876-1.659 2.36 2.36 0 002.255 1.659 2.308 2.308 0 002.322-2.322V8.485a2.307 2.307 0 00-2.322-2.322zM21.624 13.095c0-4.179 2.852-6.932 6.467-6.932 1.924 0 3.615.63 4.81 2.488 1.16-1.857 2.885-2.488 4.809-2.488 3.615 0 6.467 2.753 6.467 6.932v8.325a2.307 2.307 0 01-2.322 2.322 2.307 2.307 0 01-2.321-2.322v-8.391c0-1.725-.962-2.554-2.156-2.554-1.194 0-2.156.83-2.156 2.554v8.39a2.307 2.307 0 01-2.322 2.323 2.307 2.307 0 01-2.321-2.322v-8.391c0-1.725-.962-2.554-2.156-2.554-1.194 0-2.156.83-2.156 2.554v8.39a2.308 2.308 0 01-2.322 2.323 2.307 2.307 0 01-2.321-2.322v-8.325z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.624 13.095c0-4.179 2.852-6.932 6.467-6.932 1.924 0 3.615.63 4.81 2.488 1.16-1.857 2.885-2.488 4.809-2.488 3.615 0 6.467 2.753 6.467 6.932v8.325a2.307 2.307 0 01-2.322 2.322 2.307 2.307 0 01-2.321-2.322v-8.391c0-1.725-.962-2.554-2.156-2.554-1.194 0-2.156.83-2.156 2.554v8.39a2.307 2.307 0 01-2.322 2.323 2.307 2.307 0 01-2.321-2.322v-8.391c0-1.725-.962-2.554-2.156-2.554-1.194 0-2.156.83-2.156 2.554v8.39a2.308 2.308 0 01-2.322 2.323 2.307 2.307 0 01-2.321-2.322v-8.325zM56.114 14.953c0-4.91 3.715-8.79 8.856-8.79 2.786 0 5.14 1.194 6.268 2.554.431.498.663 1.094.663 1.559 0 1.293-.995 2.189-2.288 2.189-.929 0-1.393-.498-1.99-.995-.597-.498-1.393-.995-2.653-.995-2.355 0-4.18 1.99-4.18 4.478 0 2.487 1.825 4.477 4.18 4.477 1.16 0 2.023-.464 2.62-.962.597-.497 1.094-1.061 2.023-1.061 1.293 0 2.288.929 2.288 2.222 0 .464-.232 1.028-.663 1.526-1.128 1.36-3.416 2.587-6.268 2.587-5.141 0-8.856-3.881-8.856-8.79z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.114 14.953c0-4.91 3.715-8.79 8.856-8.79 2.786 0 5.14 1.194 6.268 2.554.431.498.663 1.094.663 1.559 0 1.293-.995 2.189-2.288 2.189-.929 0-1.393-.498-1.99-.995-.597-.498-1.393-.995-2.653-.995-2.355 0-4.18 1.99-4.18 4.478 0 2.487 1.825 4.477 4.18 4.477 1.16 0 2.023-.464 2.62-.962.597-.497 1.094-1.061 2.023-1.061 1.293 0 2.288.929 2.288 2.222 0 .464-.232 1.028-.663 1.526-1.128 1.36-3.416 2.587-6.268 2.587-5.141 0-8.856-3.881-8.856-8.79zM48.155 8.485a2.307 2.307 0 012.321-2.322 2.308 2.308 0 012.322 2.322V21.42a2.308 2.308 0 01-2.322 2.322 2.307 2.307 0 01-2.321-2.322V8.485z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.155 8.485a2.307 2.307 0 012.321-2.322 2.308 2.308 0 012.322 2.322V21.42a2.308 2.308 0 01-2.322 2.322 2.307 2.307 0 01-2.321-2.322V8.485zM45.212 2.252A2.259 2.259 0 0147.465 0a2.259 2.259 0 012.252 2.252 2.259 2.259 0 01-2.252 2.253 2.259 2.259 0 01-2.253-2.253z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.212 2.252A2.259 2.259 0 0147.465 0a2.259 2.259 0 012.252 2.252 2.259 2.259 0 01-2.252 2.253 2.259 2.259 0 01-2.253-2.253zM51.237 2.252A2.259 2.259 0 0153.489 0a2.259 2.259 0 012.252 2.252 2.259 2.259 0 01-2.252 2.253 2.259 2.259 0 01-2.252-2.253z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.237 2.252A2.259 2.259 0 0153.489 0a2.259 2.259 0 012.252 2.252 2.259 2.259 0 01-2.252 2.253 2.259 2.259 0 01-2.252-2.253zM83.21 19.43c-2.354 0-4.178-1.89-4.178-4.477 0-2.554 1.824-4.478 4.178-4.478 2.322 0 4.147 1.924 4.147 4.478 0 2.586-1.825 4.477-4.147 4.477zm6.468-13.267a2.36 2.36 0 00-2.255 1.658 8.082 8.082 0 00-4.876-1.658c-4.676 0-8.192 3.88-8.192 8.79 0 4.908 3.516 8.789 8.192 8.789a7.875 7.875 0 004.876-1.659 2.36 2.36 0 002.255 1.659A2.308 2.308 0 0092 21.42V8.485a2.307 2.307 0 00-2.322-2.322z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.21 19.43c-2.354 0-4.178-1.89-4.178-4.477 0-2.554 1.824-4.478 4.178-4.478 2.322 0 4.147 1.924 4.147 4.478 0 2.586-1.825 4.477-4.147 4.477zm6.468-13.267a2.36 2.36 0 00-2.255 1.658 8.082 8.082 0 00-4.876-1.658c-4.676 0-8.192 3.88-8.192 8.79 0 4.908 3.516 8.789 8.192 8.789a7.875 7.875 0 004.876-1.659 2.36 2.36 0 002.255 1.659A2.308 2.308 0 0092 21.42V8.485a2.307 2.307 0 00-2.322-2.322z"
      fill="currentColor"
    />
  </svg>
);

AmicaLogo.propTypes = {
  width: PropTypes.string,
};

AmicaLogo.defaultProps = {
  width: '92px',
};

export default AmicaLogo;
