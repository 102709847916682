import React from 'react';
import { useLocation } from 'react-router';

import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import { Sent } from 'Common/UI/Layout/Completed';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

const PartnerInviteSent = () => {
  const location = useLocation();

  const { state: { partnerName = '' } = {} } = location;

  return (
    <Sent buttonLabel="Continue" buttonRoute={ODRS_DASH_HOME_ROUTE}>
      <H1Conversational>
        Thanks, your invite has been sent to {partnerName}.
      </H1Conversational>
      <Paragraph large>
        We will let you know when {partnerName} has signed up.
      </Paragraph>
    </Sent>
  );
};

export default PartnerInviteSent;
