import {
  BANNER_HIDE,
  BANNER_SHOW,
  CONTENT_HIDE,
  SECTION_PARENTING,
  PARENTING_PLAN_NEEDS_CONFIRMATION,
  PARENTING_PLAN_OTHER_NOT_READY,
  PARENTING_PLAN_SELF_NOT_READY,
  PARENTING_PLAN_WAITING_FOR_OTHER,
  NEW_PARENTING_PLAN_SELF_NOT_READY,
  NEW_PARENTING_PLAN_OTHER_NOT_READY,
  NEW_PARENTING_PLAN_WAITING_FOR_OTHER,
  NEW_PARENTING_PLAN_NEEDS_CONFIRMATION,
  PARENTING_PLAN_AGREED,
  PARENTING_PLAN_HAVE_NO_CHILDREN,
  PARENTING_PLAN_START,
  PARENTING_PLAN_PAYMENT_REQUIRED,
  DOC_TYPE_PARENTING_AGREEMENT,
  AGREEMENT_CREATED,
  AGREEMENT_NEEDS_CONFIRMATION,
} from 'Common/constants';
import { isPaymentDue } from 'Common/Utils/Payments';
import {
  areRelationshipAssetsAllAccountedFor,
  areParentingPlanAssetsAllAccountedFor,
  isParentingPlanBasicsAgreed,
} from 'App/Utils/matterHelpers';
import getChildrenData from 'Common/Utils/getChildrenData';
import { sessionStorageLoad } from 'Common/Utils/sessionStorage';
import { relationshipDashboardNotCompletedFlags } from 'Common/UI/Banners/RelationshipDashboardNotCompleted';
import { dashboardTabLockedFlags } from 'Common/UI/Banners/DashboardTabLocked';
import Matter from 'Common/Data/Types/matter';
import { SectionState } from 'Common/Data/Types/appState';
import {
  getParentingState,
  getNewParentingPlanState,
} from 'App/Utils/sectionHelpers';

const data: SectionState = {
  [SECTION_PARENTING]: {
    pageBlock: [
      relationshipDashboardNotCompletedFlags,
      {
        id: 'parentingPlanRelationshipBlock',
        description:
          'Need to add children and finish relationship questions in parenting page',
        check: (matter: Matter) =>
          [
            !areRelationshipAssetsAllAccountedFor(),
            matter.childrenData
              ? !matter.childrenData.haveBeenConfirmed
              : !getChildrenData(matter.items).haveBeenConfirmed,
          ].some(condition => condition === true),
        flags: {
          appState: PARENTING_PLAN_HAVE_NO_CHILDREN,
        },
      },
    ],
    state: [
      dashboardTabLockedFlags,
      {
        id: 'parentingPlanFirstView',
        description: 'Start parenting plan with slider',
        check: ({ self }: Matter) => !self.hasSeenParentingPlanIntro,
        flags: {
          appState: PARENTING_PLAN_START,
          contentState: CONTENT_HIDE,
        },
      },
      {
        id: 'newParentingPlanOtherNotReady',
        description: 'Other is not ready for parenting plan export',
        check: ({ self, other }: Matter) =>
          !!(
            self.canExportParentingPlan && other.notReadyToExportParentingPlan
          ),
        flags: {
          appState: NEW_PARENTING_PLAN_OTHER_NOT_READY,
        },
        usedAsNotificationState: true,
      },
      {
        id: 'newParentingPlanWaitingOnOther',
        description: 'Waiting on other for parenting plan export',
        check: ({ self, other }: Matter) =>
          !!(self.canExportParentingPlan && !other.canExportParentingPlan),
        flags: {
          appState: NEW_PARENTING_PLAN_WAITING_FOR_OTHER,
        },
      },
      {
        id: 'newParentingPlanWatingOnYou',
        description: 'Waiting on you for parenting plan export',
        check: (matter: Matter) => {
          const parentingPlanState = getNewParentingPlanState({ matter });

          return parentingPlanState === AGREEMENT_NEEDS_CONFIRMATION;
        },
        flags: {
          appState: NEW_PARENTING_PLAN_NEEDS_CONFIRMATION,
        },
        usedAsNotificationState: true,
      },
      {
        id: 'parentingSelfNotReady',
        description: 'You are not ready for parenting export',
        check: ({ self }: Matter) => !!self.notReadyToExportParenting,
        flags: {
          appState: PARENTING_PLAN_SELF_NOT_READY,
          bannerTopState: () =>
            sessionStorageLoad(`${PARENTING_PLAN_AGREED}Dismissed`)
              ? BANNER_HIDE
              : BANNER_SHOW,
        },
      },
      {
        id: 'parentingOtherNotReady',
        description: 'Other is not ready for parenting export',
        check: ({ self, other }: Matter) =>
          !!(self.canExportParenting && other.notReadyToExportParenting),
        flags: {
          appState: PARENTING_PLAN_OTHER_NOT_READY,
        },
        usedAsNotificationState: true,
      },
      {
        id: 'parentingPaymentRequired',
        description: 'Payment is required for parenting plan',
        check: matter => isPaymentDue(matter, DOC_TYPE_PARENTING_AGREEMENT),
        flags: {
          appState: PARENTING_PLAN_PAYMENT_REQUIRED,
          bannerTopState: () =>
            sessionStorageLoad(`${PARENTING_PLAN_PAYMENT_REQUIRED}Dismissed`)
              ? BANNER_HIDE
              : BANNER_SHOW,
        },
        usedAsNotificationState: true,
      },
      {
        id: 'parentingWaitingOnOther',
        description: 'Waiting on other for parenting export',
        check: ({ self, other }: Matter) =>
          !!(self.canExportParenting && !other.canExportParenting),
        flags: {
          appState: PARENTING_PLAN_WAITING_FOR_OTHER,
        },
      },
      {
        id: 'parentingWaitingOnYou',
        description: 'Waiting on you for parenting export',
        check: ({ self, other }: Matter) =>
          !!(!self.canExportParenting && other.canExportParenting),
        flags: {
          appState: PARENTING_PLAN_NEEDS_CONFIRMATION,
        },
        usedAsNotificationState: true,
      },
      {
        id: 'parentingPlanRequiredFieldsComplete',
        description:
          'Both Parties have agreed to the required fields in parenting plan',
        check: (matter: Matter) => {
          const state = getParentingState({ matter });

          return (
            isParentingPlanBasicsAgreed() &&
            areParentingPlanAssetsAllAccountedFor() &&
            state !== AGREEMENT_CREATED
          );
        },
        flags: {
          appState: PARENTING_PLAN_AGREED,
          bannerTopState: () =>
            sessionStorageLoad(`${PARENTING_PLAN_AGREED}Dismissed`)
              ? BANNER_HIDE
              : BANNER_SHOW,
        },
      },
      {
        id: 'newParentingPlanSelfNotReady',
        description: 'You are not ready for parenting plan export',
        check: (matter: Matter) => {
          const { self } = matter;
          const parentingAgreementState = getParentingState({ matter });

          return (
            !!self.notReadyToExportParenting &&
            parentingAgreementState === AGREEMENT_CREATED
          );
        },
        flags: {
          appState: NEW_PARENTING_PLAN_SELF_NOT_READY,
          bannerTopState: () =>
            sessionStorageLoad(`${NEW_PARENTING_PLAN_SELF_NOT_READY}Dismissed`)
              ? BANNER_HIDE
              : BANNER_SHOW,
        },
      },
    ],
  },
};

export default data;
