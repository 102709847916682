/**
 * THIS IS A VERSIONING FILE - see readme for more info
 */

import getVersionedFunction from 'Common/Utils/getVersionedFunction';
import * as v20200101 from './20200101/VERSIONED_Payments';
import * as v20211123 from './20211123/VERSIONED_Payments';
import * as v20221124 from './20221124/VERSIONED_Payments';
import * as v20230101 from './20230101/VERSIONED_Payments';
import * as v20230215 from './20230215/VERSIONED_Payments';

const paymentsVersionGroups = {
  v20200101,
  v20211123,
  v20221124,
  v20230101,
  v20230215,
};

export const areBothPartiesReadyToExport = (
  ...args: Parameters<
    typeof paymentsVersionGroups.v20230101['areBothPartiesReadyToExport']
  >
) =>
  getVersionedFunction<
    typeof paymentsVersionGroups.v20230101['areBothPartiesReadyToExport']
  >(
    paymentsVersionGroups,
    'areBothPartiesReadyToExport'
  )(...args);

export const hasItemBeenPaidFor = (
  ...args: Parameters<
    typeof paymentsVersionGroups.v20230101['hasItemBeenPaidFor']
  >
) =>
  getVersionedFunction<
    typeof paymentsVersionGroups.v20230101['hasItemBeenPaidFor']
  >(
    paymentsVersionGroups,
    'hasItemBeenPaidFor'
  )(...args);

export const isAmicaFree = (
  ...args: Parameters<typeof paymentsVersionGroups.v20230101['isAmicaFree']>
) =>
  getVersionedFunction<typeof paymentsVersionGroups.v20230101['isAmicaFree']>(
    paymentsVersionGroups,
    'isAmicaFree'
  )(...args);

export const isPaymentDue = (
  ...args: Parameters<typeof paymentsVersionGroups.v20230101['isPaymentDue']>
) =>
  getVersionedFunction<
    typeof paymentsVersionGroups.v20230101['areBothPartiesReadyToExport']
  >(
    paymentsVersionGroups,
    'isPaymentDue'
  )(...args);

export const getItemsAwaitingPayment = (
  ...args: Parameters<
    typeof paymentsVersionGroups.v20230101['getItemsAwaitingPayment']
  >
) =>
  getVersionedFunction<
    typeof paymentsVersionGroups.v20230101['getItemsAwaitingPayment']
  >(
    paymentsVersionGroups,
    'getItemsAwaitingPayment'
  )(...args);

export const getCompletedOrders = (
  ...args: Parameters<
    typeof paymentsVersionGroups.v20230101['getCompletedOrders']
  >
) =>
  getVersionedFunction<
    typeof paymentsVersionGroups.v20230101['getCompletedOrders']
  >(
    paymentsVersionGroups,
    'getCompletedOrders'
  )(...args);

export const getOrderWithItems = (
  ...args: Parameters<
    typeof paymentsVersionGroups.v20230101['getOrderWithItems']
  >
) =>
  getVersionedFunction<
    typeof paymentsVersionGroups.v20230101['getOrderWithItems']
  >(
    paymentsVersionGroups,
    'getOrderWithItems'
  )(...args);

export const isPaymentActionNeeded = (
  ...args: Parameters<
    typeof paymentsVersionGroups.v20230101['isPaymentActionNeeded']
  >
) =>
  getVersionedFunction<
    typeof paymentsVersionGroups.v20230101['isPaymentActionNeeded']
  >(
    paymentsVersionGroups,
    'isPaymentActionNeeded'
  )(...args);

export const getTotalPrice = (
  ...args: Parameters<typeof paymentsVersionGroups.v20230101['getTotalPrice']>
) =>
  getVersionedFunction<typeof paymentsVersionGroups.v20230101['getTotalPrice']>(
    paymentsVersionGroups,
    'getTotalPrice'
  )(...args);
