import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';

import formatCurrency from 'Common/Utils/formatCurrency';
import { orangeTheme, Theme } from 'Common/Utils/theme';
import Paragraph from 'Common/UI/Text/Paragraph';
import InlineLoader from 'Common/UI/Loader/InlineLoader';
import GenericButton from 'Common/UI/Button/GenericButton';
import { AssetSplitOption } from 'Common/constants';

const Title = styled(Paragraph).attrs(() => ({
  large: true,
}))`
  margin: 0 0 ${({ theme }) => theme.spacing[6]};
  font-weight: bold;
`;

const Value = styled(Paragraph)`
  margin: 0;
`;

const Description = styled(Paragraph)`
  width: 100%;
`;

const AssetCardWrapper = styled.div<{
  theme: Theme;
  selected?: boolean;
}>`
  background: ${props => props.theme.colours.white};
  border: 2px solid ${props => props.theme.colours.clickableGrey};
  border-radius: 4px;
  padding: ${props => props.theme.spacing[4]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => props.theme.padding.small}px 0
    ${props => props.theme.padding.medium}px;

  ${props =>
    props.selected &&
    css`
      border-color: ${props.theme.colours.darkOrange};
      background: ${props.theme.colours.lightOrange2};
    `}

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    padding: ${({ theme }) => theme.spacing[6]};
  }
`;

export const AssetCard: React.FC<{
  selected: boolean;
  value?: number;
  title: React.ReactNode;
  description: React.ReactNode;
  option: AssetSplitOption;
  onButtonClick: (option: AssetSplitOption) => void;
  disabled: boolean;
  canInteract: boolean;
}> = ({
  selected,
  title,
  value,
  description,
  option,
  onButtonClick,
  disabled = false,
  canInteract = true,
}) => (
  <ThemeProvider theme={orangeTheme}>
    <AssetCardWrapper selected={selected} data-cy="asset-card">
      <Title>{title}</Title>
      {value && <Value>{formatCurrency(value)}</Value>}
      <Description>{description}</Description>
      {canInteract && (
        <GenericButton
          fullWidth
          onClick={() => onButtonClick(option)}
          disabled={disabled}
        >
          {selected ? 'Deselect' : 'Select'}
        </GenericButton>
      )}
    </AssetCardWrapper>
  </ThemeProvider>
);

export const OptionCard: React.FC<{
  title: string;
  description: string;
  onButtonClick: () => void;
  loading: boolean;
}> = ({ title, description, onButtonClick, loading }) => (
  <AssetCardWrapper>
    <Title>{title}</Title>
    <Description>{description}</Description>
    <GenericButton fullWidth onClick={onButtonClick}>
      <InlineLoader loading={loading}>Select</InlineLoader>
    </GenericButton>
  </AssetCardWrapper>
);
