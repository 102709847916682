import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  CONSENT_ORDERS_GET_STARTED_REQUIREMENTS,
  CONSENT_ORDERS_GET_STARTED_NOT_SUITABLE,
  CONSENT_ORDER_GET_STARTED_COMPLETE,
  CONSENT_ORDER_RELATIONSHIP_COMPLETE,
  CONSENT_ORDER_ASSETS_COMPLETE,
  CONSENT_ORDER_FINANCES_COMPLETE,
  CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
  DASHBOARD_TAB_LOCKED,
  CONSENT_ORDER_SUPERANNUATION_NOT_SUITABLE,
  CONSENT_ORDER_GET_STARTED_LOCKED,
  SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYEE,
  DEFAULT_OTHER_TEXT,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_COMPLETE_PAYEE,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_ACCEPTED_PAYER,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_OTHER_PAYER,
  SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYER,
  SUPER_SPLITTING_CONSENT_ORDER_WAITING_FOR_OTHER_TO_SEND_PAYEE,
  SUPER_SPLITTING_CONSENT_ORDER_VIEW_PFL_PAYER,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_SELF_PAYEE,
} from 'Common/constants';
import {
  ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE,
  ODRS_CONSENT_ORDERS_RELATIONSHIP_ROUTE,
  ODRS_CONSENT_ORDERS_ASSET_ROUTE,
  ODRS_CONSENT_ORDERS_FINANCES_ROUTE,
  ODRS_CONSENT_ORDERS_COMPLETE_ROUTE,
  ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
  ODRS_DASH_HOME_ROUTE,
} from 'Common/routes';
import { getSection } from 'Common/Data/App/appSections';
import { useAppSelector } from 'App/State/Store';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import GenericButton from 'Common/UI/Button/GenericButton';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

import DashboardTabLocked from 'Common/UI/Banners/DashboardTabLocked';
import { SuperannuationConsentOrderNoChangesPayee } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderNoChangesPayee';
import MatterProps from 'Common/Utils/MatterProps';
import { SuperannuationConsentOrderChangesCompletePayee } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderChangesCompletePayee';
import { SuperannuationConsentOrderChangesAcceptedPayer } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderChangesAcceptedPayer';
import { SuperannuationConsentOrderChangesWaitingForOtherPayer } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderChangesWaitingForOtherPayer';
import { DashboardContext } from 'Common/UI/DashboardContext';
import { SuperannuationConsentOrderNoChangesPayer } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderNoChangesPayer';
import { SuperannuationConsentOrderWaitingForOtherToSendPayee } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderWaitingForOtherToSendPayee';
import { SuperannuationConsentOrderViewPFLPayer } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderViewPFLPayer';
import { SuperannuationConsentOrderChangesWaitingForSelfPayee } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderChangesWaitingForSelfPayee';

const ButtonContainer = styled.div`
  ${GenericButton}:first-child {
    margin-bottom: ${({ theme }) => theme.padding.xsmall}px;
  }
`;

const NextMessage = ({ currentSection, nextSection, nextPath, navigate }) => (
  <>
    <SpacedBox marginBottom="medium2">
      <Heading2MarginTopNone>
        {`Great! You have finished the ${currentSection} section`}
      </Heading2MarginTopNone>
      <Paragraph large>
        {`You can now get started on the ${nextSection} section`}
      </Paragraph>
    </SpacedBox>
    <ButtonContainer>
      <GenericButton fullWidth onClick={() => navigate(nextPath)} small>
        {`Next up: ${nextSection}`}
      </GenericButton>
    </ButtonContainer>
  </>
);

let firstInProgressPageInSection;
let pages;

const ConsentOrdersContent = ({ appState, navigate, section }) => {
  const matter = useAppSelector(state => state.matter);

  const otherName = MatterProps('other.firstname', DEFAULT_OTHER_TEXT);

  const { updateAppState } = useContext(DashboardContext);

  switch (appState) {
    case CONSENT_ORDER_SUPERANNUATION_NOT_SUITABLE:
      return (
        <>
          <Heading2MarginTopNone>
            It seems that your situation is not suitable for seeking consent
            orders
          </Heading2MarginTopNone>
          <Paragraph>
            Because you answered “yes”, amica is not suitable for making an
            application for consent orders for your situation.
          </Paragraph>
          <Paragraph>
            If you would like help about your situation you can call the free{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Legal Help Line
            </ReferralLink>{' '}
            in your state or territory.
          </Paragraph>
        </>
      );
    case DASHBOARD_TAB_LOCKED:
      pages = getSection(section)?.children || [];
      firstInProgressPageInSection = pages.find(
        ({ generateStatus }) =>
          generateStatus && generateStatus(matter) === 'not-completed'
      );

      return (
        <DashboardTabLocked
          navigate={navigate}
          firstInProgressPageInSection={firstInProgressPageInSection}
        />
      );
    case CONSENT_ORDERS_GET_STARTED_REQUIREMENTS:
      return (
        <>
          <Heading2MarginTopNone>
            Check that your situation is suitable for applying for consent
            orders
          </Heading2MarginTopNone>
          <Paragraph>
            You will both need to check that your situation is suitable for
            applying for consent orders using amica.
          </Paragraph>
          <GenericButton
            small
            fullWidth
            onClick={() => navigate(ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE)}
          >
            Get started
          </GenericButton>
        </>
      );
    case CONSENT_ORDER_GET_STARTED_LOCKED:
      return (
        <>
          <Heading2MarginTopNone>
            Not ready to start consent orders yet
          </Heading2MarginTopNone>
          <Paragraph>
            You must complete the Relationship and Property dashboards first.
          </Paragraph>
          <GenericButton
            small
            fullWidth
            onClick={() => navigate(ODRS_DASH_HOME_ROUTE)}
          >
            Get started
          </GenericButton>
        </>
      );
    case CONSENT_ORDERS_GET_STARTED_NOT_SUITABLE:
      return (
        <>
          <Heading2MarginTopNone>
            It seems that your situation is not suitable for seeking consent
            orders
          </Heading2MarginTopNone>
          <Paragraph>
            Some of your answers suggest that amica is not right for your
            situation.
          </Paragraph>
          <Paragraph>
            {`Even if amica is not right for your situation you can use amica 
              to find help and support options, such as counselling, family 
              violence help and legal help.`}
          </Paragraph>
          <Paragraph>
            See our{' '}
            <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
              Help and Support page.
            </ReferralLink>
          </Paragraph>
        </>
      );
    case CONSENT_ORDER_GET_STARTED_COMPLETE:
      return (
        <NextMessage
          currentSection="Getting started"
          nextSection="Relationship"
          nextPath={ODRS_CONSENT_ORDERS_RELATIONSHIP_ROUTE}
          navigate={navigate}
        />
      );
    case CONSENT_ORDER_RELATIONSHIP_COMPLETE:
      return (
        <NextMessage
          currentSection="Relationship"
          nextSection="Assets"
          nextPath={ODRS_CONSENT_ORDERS_ASSET_ROUTE}
          navigate={navigate}
        />
      );
    case CONSENT_ORDER_ASSETS_COMPLETE:
      return (
        <NextMessage
          currentSection="Assets"
          nextSection="Finances"
          nextPath={ODRS_CONSENT_ORDERS_FINANCES_ROUTE}
          navigate={navigate}
        />
      );
    case CONSENT_ORDER_FINANCES_COMPLETE:
      return (
        <NextMessage
          currentSection="Finances"
          nextSection="Document Creation"
          nextPath={ODRS_CONSENT_ORDERS_COMPLETE_ROUTE}
          navigate={navigate}
        />
      );
    case CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED:
      return (
        <>
          <Heading2MarginTopNone>
            Great! you have completed your application for consent orders
          </Heading2MarginTopNone>
          <Paragraph large>
            You will need to pay to view or download your consent order
            application.
          </Paragraph>
          <ButtonContainer>
            <GenericButton
              fullWidth
              onClick={() => navigate(ODRS_PAYMENT_REVIEW_ORDER_ROUTE)}
              small
            >
              Pay now
            </GenericButton>
          </ButtonContainer>
        </>
      );
    case SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYEE:
      return (
        <SuperannuationConsentOrderNoChangesPayee
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    case SUPER_SPLITTING_CONSENT_ORDER_CHANGES_COMPLETE_PAYEE:
      return (
        <SuperannuationConsentOrderChangesCompletePayee
          updateAppState={updateAppState}
        />
      );
    case SUPER_SPLITTING_CONSENT_ORDER_CHANGES_ACCEPTED_PAYER:
      return (
        <SuperannuationConsentOrderChangesAcceptedPayer
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    case SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_OTHER_PAYER:
      return (
        <SuperannuationConsentOrderChangesWaitingForOtherPayer
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    case SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_SELF_PAYEE:
      return (
        <SuperannuationConsentOrderChangesWaitingForSelfPayee
          otherName={otherName}
        />
      );
    case SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYER:
      return (
        <SuperannuationConsentOrderNoChangesPayer
          otherName={otherName}
          updateAppState={updateAppState}
        />
      );
    case SUPER_SPLITTING_CONSENT_ORDER_WAITING_FOR_OTHER_TO_SEND_PAYEE:
      return (
        <SuperannuationConsentOrderWaitingForOtherToSendPayee
          otherName={otherName}
        />
      );
    case SUPER_SPLITTING_CONSENT_ORDER_VIEW_PFL_PAYER:
      return (
        <SuperannuationConsentOrderViewPFLPayer
          updateAppState={updateAppState}
        />
      );
    default:
      return null;
  }
};

export default ConsentOrdersContent;
