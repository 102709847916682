import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2, Heading4, Heading3 } from 'Common/UI/Text/Headings';
import formatCurrency from 'Common/Utils/formatCurrency';
import { DATE_FORMAT_SHORT } from 'Common/constants';
import getYouOrThem from 'Common/Utils/getYouOrThem';
import { Numbers1 } from 'Common/UI/Text/Numbers';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Label } from 'Common/UI/Text/Label';
import Drawer from 'Common/UI/Layout/Drawer';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import { Column } from 'Common/UI/Layout/Flex';
import IconOpenLinkButton from 'Common/UI/Button/IconOpenLinkButton';

export const PaymentDrawer = styled(Drawer).attrs(() => ({
  noPadding: true,
}))`
  position: relative;
  z-index: 10;
  background: ${({ theme, colour }) => colour || theme.colours.backgroundGrey};
  color: ${({ theme }) => theme.textColour};
  padding-top: ${({ theme }) => theme.spacing[4]};
  padding-bottom: 0;
  border: 0;
  flex: 1;
  display: inline-flex;
  flex-direction: column;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: ${({ theme }) => theme.spacing[16]};
  }
`;

export const DrawerContent = styled.div`
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  flex: 1;
`;

export const FlexColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const FlexRow = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-around;
`;

export const DoneButton = styled(GenericButton)``;

export const FixedFooter = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: ${props => props.theme.colours.white};
  padding: ${props => props.theme.padding.small}px;
  box-shadow: 8px 8px 24px rgba(30, 26, 78, 0.16);
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: space-around;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}

  ${DoneButton} {
    padding-top: 0;
    padding-bottom: 0;
    height: 35px;
  }
`;

export const FooterBigCTA = styled.div`
  background: ${props => props.theme.colours.offWhite};
  padding: ${props => props.theme.padding.small}px
    ${props => props.theme.padding.small}px
    ${props => props.paddingBottom || props.theme.padding.large}px;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: space-around;
  flex-wrap: wrap;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}

  ${DoneButton} {
    width: 100%;
  }
`;

const FooterAmount = styled(Paragraph).attrs(() => ({ large: true }))`
  color: ${props => props.theme.colours.darkGrey};
  margin: 0;
`;

const FooterAmountBig = styled(Numbers1)`
  margin-bottom: ${props => props.theme.padding.small}px;
`;

const FooterPayHeading = styled(Heading4)`
  margin: 0;
`;

const FullWidthFlexGrid = styled(FlexGrid)`
  width: 100%;
`;

export const PaymentFooter = ({
  onClick,
  bigButtonLabel,
  buttonLabel,
  buttonDisabled,
  showBigCTA,
  amount,
}) => (
  <>
    {showBigCTA && (
      <FooterBigCTA>
        <ResponsiveContainer>
          <FullWidthFlexGrid medium={2}>
            <Column alignItems="flex-start">
              <Heading2>You will need to pay:</Heading2>

              <FlexRow>
                <FlexColumn>
                  <FooterAmountBig>{amount}</FooterAmountBig>
                </FlexColumn>
              </FlexRow>
            </Column>
            <Column alignItems="flex-start" justifyContent="flex-end">
              <FlexRow>
                <DoneButton onClick={onClick} disabled={buttonDisabled}>
                  {bigButtonLabel}
                </DoneButton>
              </FlexRow>
            </Column>
          </FullWidthFlexGrid>
        </ResponsiveContainer>
      </FooterBigCTA>
    )}
    <FixedFooter>
      <FlexColumn>
        <FooterPayHeading>You will need to pay:</FooterPayHeading>
        <FooterAmount>{amount}</FooterAmount>
      </FlexColumn>
      <FlexColumn>
        {onClick && (
          <DoneButton onClick={onClick} disabled={buttonDisabled}>
            {buttonLabel}
          </DoneButton>
        )}
      </FlexColumn>
    </FixedFooter>
  </>
);

PaymentFooter.propTypes = {
  onClick: PropTypes.func,
  bigButtonLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  amount: PropTypes.string.isRequired,
  showBigCTA: PropTypes.bool,
};

PaymentFooter.defaultProps = {
  buttonDisabled: false,
  showBigCTA: false,
  bigButtonLabel: 'Next',
  onClick: null,
  buttonLabel: null,
};

const PaymentItemWrapper = styled.div`
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  padding: ${props => props.theme.padding.small}px;
  margin-bottom: ${props => props.theme.padding.medium}px;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      background: ${props.theme.colours.lightOrange2};
      border-color: ${props.theme.colours.darkOrange};
    `}

  ${props =>
    !props.selected &&
    css`
      background: ${props.theme.colours.offWhite};
      border-color: ${props.theme.colours.clickableGrey};
    `}
`;

const PaymentItemPrice = styled(Paragraph).attrs(() => ({ large: true }))`
  margin: 0;
`;

const PaymentItemSubtitle = styled(Heading4)`
  margin: 0;
  color: ${props => props.theme.colours.darkGrey};
`;

const PaymentItemTitle = styled(Heading3)`
  margin-top: 10px;
`;

export const PaymentItem = ({ item, selected, onClick }) => (
  <PaymentItemWrapper selected={selected} onClick={onClick}>
    <FlexRow>
      <PaymentItemSubtitle>{item.subtitle}</PaymentItemSubtitle>
      <PaymentItemPrice>{formatCurrency(item.price)}</PaymentItemPrice>
    </FlexRow>
    <PaymentItemTitle>{item.title}</PaymentItemTitle>
    {item.description.map((p, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Paragraph key={index} large>
        {p}
      </Paragraph>
    ))}
  </PaymentItemWrapper>
);

const PaidItemWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.colours.lightGrey};
  margin-bottom: ${props => props.theme.padding.small}px;
`;

const PaidItem = ({ item }) => (
  <PaidItemWrapper>
    <FlexRow>
      <PaymentItemSubtitle>{item.subtitle}</PaymentItemSubtitle>
      <PaymentItemPrice>{formatCurrency(item.price)}</PaymentItemPrice>
    </FlexRow>
    <PaymentItemTitle>{item.title}</PaymentItemTitle>
  </PaidItemWrapper>
);

const OrderSummaryBox = styled.div`
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: ${props => props.theme.padding.small}px;
  margin-top: ${props => props.theme.padding.small}px;
  background: ${props => props.theme.colours.offWhite};
  border-color: ${props => props.theme.colours.clickableGrey};
`;

const OrderNumberText = styled(Heading4)`
  margin: 0 0 ${props => props.theme.padding.xxsmall}px;
  color: ${props => props.theme.colours.midDarkGrey};
`;

const OrderNumber = styled(Heading3)`
  margin: 0 0 ${props => props.theme.padding.xxsmall}px;
`;

const OrderDate = styled(Label)`
  color: ${props => props.theme.colours.midDarkGrey};
`;

const OrderSummaryWrapper = styled.div`
  margin-bottom: ${props => props.theme.padding.large}px;
`;

const PaidText = styled(Label)`
  font-weight: bold;
`;

const StyledIconOpenLinkButton = styled(IconOpenLinkButton)`
  margin-top: ${props => props.theme.padding.small}px;
`;

export const OrderSummary = ({
  order,
  items,
  showTaxInvoiceLink,
  onClickTaxInvoice,
}) => {
  const total = items.reduce((acc, item) => acc + item.price, 0);
  return (
    <OrderSummaryWrapper>
      <FlexRow>
        <OrderNumberText>Order number:</OrderNumberText>
        <OrderDate>
          {moment(order.transactionDateTime).format(DATE_FORMAT_SHORT)}
        </OrderDate>
      </FlexRow>
      <FlexRow>
        <OrderNumber>{order.receiptNumber}</OrderNumber>
        <PaidText>{`Paid by ${getYouOrThem(order.payingParty)}`}</PaidText>
      </FlexRow>
      <OrderSummaryBox>
        {items.map(item => (
          <PaidItem item={item} key={item.type} />
        ))}
        <FlexRow>
          <PaymentItemSubtitle>Total</PaymentItemSubtitle>
          <PaymentItemPrice>{formatCurrency(total)}</PaymentItemPrice>
        </FlexRow>
        {showTaxInvoiceLink && (
          <StyledIconOpenLinkButton subtle onClick={onClickTaxInvoice}>
            Tax invoice
          </StyledIconOpenLinkButton>
        )}
      </OrderSummaryBox>
    </OrderSummaryWrapper>
  );
};

OrderSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
  showTaxInvoiceLink: PropTypes.bool,
  onClickTaxInvoice: PropTypes.func,
};

OrderSummary.defaultProps = {
  showTaxInvoiceLink: false,
  onClickTaxInvoice: undefined,
};
