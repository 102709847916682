import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import { ParagraphNoMarginBottom } from 'Common/UI/Text/Paragraph';
import {
  RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER,
  RESET_SUGGESTED_DIVISION_OTHER_NOT_READY,
  RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF,
  SUGGESTED_DIVISION,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import TextButton from 'Common/UI/Button/TextButton';
import { useAppDispatch } from 'App/State/Store';
import {
  resetAgreementStateAction,
  setPartyFlagsAction,
} from 'App/State/MatterActions';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import {
  isWaitingForOtherToResetSuggestedDivision,
  isWaitingForSelfToResetSuggestedDivision,
  isOtherNotReadyToResetSuggestedDivision,
} from 'Common/Utils/resetSuggestedDivisionHelpers';

export const resetSuggestedDivisionWaitingForOtherFlags: AppState = {
  id: 'resetSuggestedDivisionWaitingOnOther',
  description: 'Waiting on other to reset suggested division',
  check: () => isWaitingForOtherToResetSuggestedDivision(),
  flags: {
    appState: RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER,
  },
};

export const resetSuggestedDivisionOtherNotReadyFlags: AppState = {
  id: 'resetSuggestedDivisionOtherNotReady',
  description: 'Other party declined to reset suggested division',
  check: () => isOtherNotReadyToResetSuggestedDivision(),
  flags: {
    appState: RESET_SUGGESTED_DIVISION_OTHER_NOT_READY,
  },
  usedAsNotificationState: true,
};

export const resetSuggestedDivisionWaitingForSelfFlags: AppState = {
  id: 'resetSuggestedDivisionWaitingForSelf',
  description: 'Waiting on self to reset suggested division',
  check: () => isWaitingForSelfToResetSuggestedDivision(),
  flags: {
    appState: RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF,
  },
  usedAsNotificationState: true,
};

type Props = {
  otherName: string;
};

export const ResetSuggestedDivisionWaitingForOther: React.FC<Props> = ({
  otherName,
}) => (
  <SpacedBox marginBottom="xxsmall">
    <Heading2MarginTopNone>
      Waiting for {otherName} to agree to restart asset division
    </Heading2MarginTopNone>
    <ParagraphNoMarginBottom>
      You will both need to agree to restart the asset division section.
    </ParagraphNoMarginBottom>
  </SpacedBox>
);

export const ResetSuggestedDivisionOtherNotReady: React.FC<Props> = ({
  otherName,
}) => {
  const dispatch = useAppDispatch();
  const { withLoading } = usePromiseWithLoading();

  const resetSuggestedDivisionState = () =>
    dispatch(resetAgreementStateAction({ name: SUGGESTED_DIVISION }));

  return (
    <>
      <SpacedBox marginBottom={8}>
        <Heading2MarginTopNone>
          {otherName} has declined your request to restart asset division
        </Heading2MarginTopNone>
        <ParagraphNoMarginBottom>
          You both need to agree to restart the asset division section.
        </ParagraphNoMarginBottom>
      </SpacedBox>
      <GenericButton
        fullWidth
        onClick={() => withLoading(resetSuggestedDivisionState)}
        small
      >
        I understand
      </GenericButton>
    </>
  );
};

export const ResetSuggestedDivisionWaitingForSelf: React.FC<Props> = ({
  otherName,
}) => {
  const dispatch = useAppDispatch();
  const { withLoading } = usePromiseWithLoading();

  const confirmResetSuggestedDivision = () =>
    dispatch(setPartyFlagsAction({ readyToResetSuggestedDivision: true }));

  const setNotReadyToResetSuggestedDivision = () =>
    dispatch(setPartyFlagsAction({ notReadyToResetSuggestedDivision: true }));

  return (
    <>
      <SpacedBox marginBottom={8}>
        <Heading2MarginTopNone>
          {otherName} has requested to restart asset division
        </Heading2MarginTopNone>
        <ParagraphNoMarginBottom>
          If you both agree to restart the asset division section, you’ll be
          able to update your information and renegotiate how you divide your
          money and property
        </ParagraphNoMarginBottom>
      </SpacedBox>
      <GenericButton
        fullWidth
        onClick={() => withLoading(confirmResetSuggestedDivision)}
        small
      >
        Restart asset division
      </GenericButton>
      <TextButton
        fullWidth
        onClick={() => withLoading(setNotReadyToResetSuggestedDivision)}
        small
        color="#FFFFFF"
      >
        Do not restart asset division
      </TextButton>
    </>
  );
};
