import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import push from 'Common/Utils/push';
import {
  SECTION_CONSENT_ORDERS,
  SECTION_STATUS_COMPLETED,
  SECTION_STATUS_IN_PROGRESS,
  SECTION_STATUS_LOCKED,
  SECTION_STATUS_NOT_STARTED,
  SECTION_STATUS_OPT_IN,
} from 'Common/constants';
import TextButton from 'Common/UI/Button/TextButton';
import { localStorageLoad, localStorageSave } from 'Common/Utils/localStorage';
import { greenQuestionTheme } from 'Common/Utils/theme';
import { getConsentOrderRoute } from 'Common/Utils/getRoute';
import getNames from 'Common/Utils/getNames';
import { Link } from 'react-router-dom';
import { ODRS_PROFILE_ROUTE } from 'Common/routes';
import SectionCardWrapper from './SectionCardWrapper';
import BackgroundConsentOrder from './bg-consentorder.svg';
import BackgroundConsentOrderCompleted from './bg-consentorder-completed.svg';
import BackgroundConsentOrderLocked from './bg-consentorder-locked.svg';

const OptedOutParagraph = styled(Paragraph)`
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const StatusParagraph = styled(Paragraph)`
  border-top: 1px solid ${({ theme }) => theme.colours.clickableGrey};
  margin-top: 0;
  padding-top: ${({ theme }) => theme.padding.small}px;
`;

const ConsentOrderSectionCard = ({
  status,
  progress,
  navigate,
  stats: { notStarted, waitingSelf, waitingOther },
  toggleOptIn,
  isSectionReadyToBeStarted,
}) => {
  const names = getNames();

  const heading = (
    <Heading2>Creating an application for consent orders</Heading2>
  );

  const route = getConsentOrderRoute();

  switch (status) {
    case SECTION_STATUS_LOCKED:
      return (
        <ThemeProvider theme={greenQuestionTheme}>
          <SectionCardWrapper
            hideProgressBar
            headerImage={BackgroundConsentOrderLocked}
            withHeaderArrow={false}
            dataCy="overview-card-consent-order-locked"
            footer={
              <GenericButton fullWidth disabled onClick={() => {}}>
                  Get started
                </GenericButton>
            }
          >
            {heading}
            <Paragraph>
              Once you have completed 'dividing your money and property' you
              will both need to agree to create an application for consent
              orders in order to start this section. You can do this in the
              'dividing your money and property' section.
            </Paragraph>
          </SectionCardWrapper>
        </ThemeProvider>
      );
    case SECTION_STATUS_OPT_IN:
      if (
        localStorageLoad(`DismissedOverviewOptInCard_${SECTION_CONSENT_ORDERS}`)
      ) {
        return (
          <OptedOutParagraph>
            You have opted out of the application for consent orders section. To
            opt back in, please visit your{' '}
            <Link to={ODRS_PROFILE_ROUTE}>account</Link> page.
          </OptedOutParagraph>
        );
      }

      return (
        <ThemeProvider theme={greenQuestionTheme}>
          <SectionCardWrapper
            hideProgressBar
            dataCy="overview-card-consent-order-opt-in"
            footer={
              <>
                <GenericButton
                  fullWidth
                  onClick={() => toggleOptIn(true)}
                  subtle={!isSectionReadyToBeStarted}
                >
                  Create an application for consent orders
                </GenericButton>
                <TextButton
                  fullWidth
                  onClick={() => {
                    toggleOptIn(false);
                    localStorageSave(
                      `DismissedOverviewOptInCard_${SECTION_CONSENT_ORDERS}`,
                      true
                    );
                  }}
                >
                  Decide later
                </TextButton>
              </>
            }
          >
            {heading}
            <Paragraph>
              Once you have completed 'dividing your money and property' you
              will have the option to also continue on to create an application
              for consent orders.
            </Paragraph>
          </SectionCardWrapper>
        </ThemeProvider>
      );
    case SECTION_STATUS_NOT_STARTED:
      return (
        <ThemeProvider theme={greenQuestionTheme}>
          <SectionCardWrapper
            hideProgressBar
            route={route}
            headerImage={BackgroundConsentOrder}
            dataCy="overview-card-consent-order-not-started"
            footer={
              <GenericButton fullWidth onClick={() => navigate(route)}>
                  Get started
                </GenericButton>
            }
          >
            {heading}
            <Paragraph>
              A consent order is a formal document which is sealed by a court.
              It is legally binding. You will have further sections to complete
              here.
            </Paragraph>
          </SectionCardWrapper>
        </ThemeProvider>
      );
    case SECTION_STATUS_IN_PROGRESS: {
      const waitingOnOther = waitingOther > 0 && waitingSelf + notStarted === 0;

      return (
        <ThemeProvider theme={greenQuestionTheme}>
          <SectionCardWrapper
            progress={progress}
            route={route}
            headerImage={BackgroundConsentOrder}
            dataCy="overview-card-consent-order-in-progress"
            footer={
              <GenericButton fullWidth onClick={() => navigate(route)}>
                  Review
                </GenericButton>
            }
          >
            {heading}
            {waitingSelf > 0 && (
              <StatusParagraph>
                You have <strong>{waitingSelf}</strong> statements to review
              </StatusParagraph>
            )}
            {notStarted > 0 && (
              <StatusParagraph>
                You have <strong>{notStarted}</strong> required statements
                remaining
              </StatusParagraph>
            )}
            {waitingOnOther && (
              <StatusParagraph>
                Waiting on {names.other} to review your statements.
              </StatusParagraph>
            )}
          </SectionCardWrapper>
        </ThemeProvider>
      );
    }
    case SECTION_STATUS_COMPLETED:
      return (
        <ThemeProvider theme={greenQuestionTheme}>
          <SectionCardWrapper
            progress={100}
            route={route}
            headerImage={BackgroundConsentOrderCompleted}
            dataCy="overview-card-consent-order-completed"
            footer={
              <GenericButton subtle fullWidth onClick={() => navigate(route)}>
                  View
                </GenericButton>
            }
          >
            {heading}
            <Paragraph>Great! You have both completed this section.</Paragraph>
          </SectionCardWrapper>
        </ThemeProvider>
      );
    default:
      return null;
  }
};

ConsentOrderSectionCard.propTypes = {
  navigate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  stats: PropTypes.shape({
    notStarted: PropTypes.number,
    waitingSelf: PropTypes.number,
    waitingOther: PropTypes.number,
  }),
  progress: PropTypes.number,
  toggleOptIn: PropTypes.func,
  isSectionReadyToBeStarted: PropTypes.bool,
};

ConsentOrderSectionCard.defaultProps = {
  stats: {},
  progress: undefined,
  toggleOptIn: () => {},
  isSectionReadyToBeStarted: true,
};

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
});

export default connect(null, mapDispatchToProps)(ConsentOrderSectionCard);
