import React from 'react';
import styled from 'styled-components';

import CardWarning from 'Common/UI/Card/indicators/CardWarning';
import { Heading4 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import SpacedBox from 'Common/UI/Layout/SpacedBox';

export const modalPortal: HTMLElement = document.getElementById(
  'modal'
) as HTMLElement;

export const TransferrableAssetWrapper = styled.div`
  margin-bottom: ${props => props.theme.padding.large}px;
`;

export const AssetTitle = styled(Heading4)`
  margin: 0;
`;

export const FlexHeadingContainer = styled(SpacedBox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledValue = styled.div`
  padding: ${props => props.theme.padding.xsmall}px
    ${props => props.theme.padding.xsmall}px;
  margin-left: ${props => props.theme.padding.small}px;
  text-align: right;

  & > .share {
    display: block;
    font-size: 11px;
  }
`;

const ValueSmall = styled(Paragraph)`
  font-size: 14px;
  margin: 0;
`;

const ValueLarge = styled(Paragraph)`
  font-size: 16px;
  margin: 0;
`;

const ValueHighlight = styled.span`
  color: ${props => props.theme.colours.darkOrange};
`;

export const ModalHeader = styled.div`
  padding: ${props => props.theme.padding.medium}px
    ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  color: ${props => props.theme.textColour};
  border-bottom: 1px solid ${props => props.theme.colours.lightGrey};
`;

export const ModalContent = styled.div`
  padding: ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  color: ${props => props.theme.textColour};
  flex: 1;
`;

export const ModalFooter = styled.div`
  padding: ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  color: ${props => props.theme.textColour};
`;

export const StyledCardWarning = styled(CardWarning)`
  margin: ${props => props.theme.padding.small}px 0;
`;

export const Value: React.FC<{
  share?: number;
  children: React.ReactNode;
}> = ({ share, children }) => {
  let content;

  if (share) {
    content = (
      <>
        <ValueLarge>
          Your&nbsp;share:&nbsp;
          <ValueHighlight>{share}%</ValueHighlight>
        </ValueLarge>
        <ValueSmall>{children}</ValueSmall>
      </>
    );
  } else {
    content = <ValueLarge>{children}</ValueLarge>;
  }

  return <StyledValue>{content}</StyledValue>;
};
