import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import ScrollerContainer from 'Common/UI/Layout/ScrollerContainer';

const BannerWrap = styled(ScrollerContainer)`
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: ${props => props.theme.backgroundColour};
  padding: 24px 24px 120px;
`;

const BottomBannerWrapper = ({ theme: bannerTheme, children, position }) => (
  <ThemeProvider theme={bannerTheme}>
    <BannerWrap position={position}>{children}</BannerWrap>
  </ThemeProvider>
);

BottomBannerWrapper.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  children: PropTypes.element.isRequired,
  position: PropTypes.oneOf(['top', 'bottom']),
};

BottomBannerWrapper.defaultProps = {
  position: 'top',
};

export default BottomBannerWrapper;
