import {
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_NEW_BOTH_PARTIES,
  DATA_SECTION,
  DATA_GROUP,
  CARD_NORMAL,
  CARD_COMPONENT,
  SECTION_PROPERTY,
  DATA_PAGE,
  PAGE_PROPERTY_MONEY_AND_PROPERTY,
  PAGE_PROPERTY_CONTRIBUTIONS,
  PAGE_PROPERTY_ASSET_DIVISION,
  PAGE_PROPERTY_COMPLETE,
  PARTY_A,
  MESSAGE_STATUS_STATEMENT_CREATED,
  STATUS_CREATED,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import {
  ODRS_VEHICLES_ROUTE,
  ODRS_PROPERTIES_ROUTE,
  ODRS_YOURFINANCES_ROUTE,
  ODRS_YOURSUPERANNUATION_ROUTE,
  ODRS_SHAREDFINANCES_ROUTE,
  ODRS_CONTRIBUTIONS_ROUTE,
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
  ODRS_PROPERTY_CONTRIBUTIONS_ROUTE,
  ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
  ODRS_PROPERTY_COMPLETE_ROUTE,
  ODRS_BASE_ROUTE,
  ODRS_NOSUPERANNUATION_ROUTE,
} from 'Common/routes';
import {
  areRelationshipAssetsAllAccountedFor,
  areSettlementAssetsAllAccountedFor,
} from 'App/Utils/matterHelpers';
import { getNextAvailableIndex } from 'Common/Utils/getNextAvailableIndex';
import ConditionalSuperWarning from 'Common/UI/Card/indicators/ConditionalSuperWarning';
import { getNavItemStatus } from 'Common/UI/Navigation';
import IconProperty from 'Common/Assets/images/icon-property.svg';
import IconVehicles from 'Common/Assets/images/icon-vehicles.svg';
import IconFinances from 'Common/Assets/images/icon-finances.svg';
import IconSuperannuation from 'Common/Assets/images/icon-superannuation.svg';
import IconContributions from 'Common/Assets/images/icon-contributions.svg';
import BackgroundQuestionFlowProperty from 'Common/Assets/images/question-flow-bg-property.png';
import AssetDivisionCards from 'App/UI/Dashboard/Property/AssetDivisionCards';
import AssetProps from 'Common/Data/App/assetProperties';
import DiviProps from 'Common/Data/App/diviProperties';
import colours from 'Common/Utils/colours';
import { getPageStatus } from 'App/Utils/sectionHelpers';
import { history, reduxStore } from 'App/State/Store';
import {
  addEmptyItemAction,
  setPartyFlagsAction,
  updateMatterSectionAction,
} from 'App/State/MatterActions';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import getOtherParty from 'Common/Utils/getOtherParty';
import PropertyDocumentCards from 'App/UI/DocumentCard/Property/PropertyDocumentCards';
import {
  vehiclesQuestions,
  vehiclesStatement,
  propertiesQuestions,
  propertiesStatement,
  yourFinancesQuestions,
  yourFinancesStatement,
  sharedFinancesQuestions,
  sharedFinancesStatement,
} from '../../../20200101/Questions/Settlement';
import {
  contributionsQuestions,
  contributionsStatement,
  yourSuperannuationQuestions,
  yourSuperannuationStatement,
  noSuperannuationQuestions,
  noSuperannuationStatement,
} from '../../Questions/Settlement';
import { buildCard } from '../../../buildCard';
import {
  CardComponent,
  CardIndex,
  CardNormal,
  DataSection,
} from '../../../../Types/appSections';
import { Item } from '../../../../Types/matter';

const generateProperty = ({
  existingProperties = [],
  existingVehicles = [],
  existingSuperannuationA = [],
  existingSuperannuationB = [],
}: {
  existingProperties: Item[];
  existingVehicles: Item[];
  existingSuperannuationA: Item[];
  existingSuperannuationB: Item[];
}): DataSection => ({
  type: DATA_SECTION,
  id: SECTION_PROPERTY,
  title: 'Dividing your money and property',
  route: ODRS_DASH_PROPERTY_ROUTE,
  questionFlowBackgroundImage: BackgroundQuestionFlowProperty,
  questionFlowBackgroundColour: colours.offWhite,
  children: [
    {
      type: DATA_PAGE,
      id: PAGE_PROPERTY_MONEY_AND_PROPERTY,
      title: '1. Money and Property',
      route: ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
      subTitle: '1. Money and Property',
      description:
        'You will need to create and agree on statements about the different types of money and property you and your former partner owned or shared.',
      generateStatus: () => {
        const { completed } = getPageStatus({
          section: SECTION_PROPERTY,
          page: PAGE_PROPERTY_MONEY_AND_PROPERTY,
        });

        return getNavItemStatus({
          isCompleted: completed,
          isLocked: !areRelationshipAssetsAllAccountedFor(),
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'finances',
          title: 'Finances',
          description:
            'We are going to ask a few questions about your finances and personal belongings.',
          icon: IconFinances,
          children: [
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'yourfinances',
              cardID: `yourfinances${MatterPropsJS('self.party', 'A')}`,
              title: 'Your Finances',
              sensitiveTitle: `Party ${MatterPropsJS(
                'self.party',
                'A'
              )}'s Finances`,
              questions: yourFinancesQuestions,
              statement: yourFinancesStatement,
              index: MatterPropsJS('self.party', 'A'),
              questionsRoute: ODRS_YOURFINANCES_ROUTE,
              updateRoute: `${ODRS_YOURFINANCES_ROUTE}/update`,
              status: STATUS_NEW_ONLY_SELF,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'yourfinances',
              cardID: `yourfinances${MatterPropsJS('other.party', 'B')}`,
              title: `${MatterPropsJS('other.firstname', 'Them')}'s Finances`,
              sensitiveTitle: `Party ${MatterPropsJS(
                'other.party',
                'B'
              )}'s Finances`,
              questions: yourFinancesQuestions,
              statement: yourFinancesStatement,
              index: MatterPropsJS('other.party', 'B'),
              questionsRoute: ODRS_YOURFINANCES_ROUTE,
              status: STATUS_NEW_ONLY_THEM,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'sharedfinances',
              cardID: 'sharedfinances',
              title: 'Shared Finances',
              questions: sharedFinancesQuestions,
              statement: sharedFinancesStatement,
              questionsRoute: ODRS_SHAREDFINANCES_ROUTE,
              updateRoute: `${ODRS_SHAREDFINANCES_ROUTE}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
            }),
          ],
        },
        {
          type: DATA_GROUP,
          id: 'properties',
          title: 'Property',
          description:
            'You will need to add any properties that you solely or jointly own.',
          icon: IconProperty,
          isRepeatable: true,
          isIndividual: false,
          singular: 'property',
          plural: 'properties',
          promptCardData: {
            hasNoneState: true,
            initial: {
              title: 'Do you own any property?',
              content:
                'You will need to add any property that you and your former partner jointly and/or solely own.',
              yesButton: 'Yes, we do',
              noButton: "We don't own property",
              onClickYes: async () => {
                const { dispatch } = reduxStore;

                await dispatch(addEmptyItemAction('properties'));

                history.push(`${ODRS_BASE_ROUTE}/properties/1`, {
                  previous: reduxStore.getState().router.location.pathname,
                });
              },
              onClickNo: async () => {
                const { dispatch } = reduxStore;

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsProperties: true })
                );
              },
            },
            none: {
              title: 'You do not have any property',
              content:
                'You have selected that you and your former partner do not have any property.',
              onClickEdit: async () => {
                const { dispatch } = reduxStore;

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsProperties: false })
                );
              },
            },
          },
          defaultRoute: `${ODRS_PROPERTIES_ROUTE}`,
          children: (() => {
            /** See notes above about children card generation */
            const takenIndexes: number[] = [];

            const items = MatterPropsJS('items');
            const setup =
              items.find((item: Item) => item.SectionID === 'setup') || {};

            return Array.from(
              Array(setup.numProperties || 0),
              (_el, arrayIndex) => {
                const item = existingProperties[arrayIndex] || {};
                let index: CardIndex;
                let displayIndex;
                let cardID;

                if (item.SectionID) {
                  index = item.CardIndex;
                  displayIndex = index;
                  cardID = item.SectionID;
                } else {
                  index = getNextAvailableIndex(takenIndexes);
                  displayIndex = arrayIndex + 1;
                  cardID = `properties${index}`;
                }

                takenIndexes.push(Number(index));

                return buildCard<CardNormal>({
                  type: CARD_NORMAL,
                  baseID: 'properties',
                  cardID,
                  title: item.propertyAddress || `Property ${displayIndex}`,
                  sensitiveTitle: `Property ${displayIndex}`,
                  questions: propertiesQuestions,
                  statement: propertiesStatement,
                  index,
                  isRepeatable: true,
                  questionsRoute: `${ODRS_PROPERTIES_ROUTE}/${index}`,
                  updateRoute: `${ODRS_PROPERTIES_ROUTE}/${index}/update`,
                  status: STATUS_NEW_BOTH_PARTIES,
                });
              }
            );
          })(),
        },
        {
          type: DATA_GROUP,
          id: 'vehicles',
          title: 'Vehicles',
          description:
            'You will need to add any vehicles that you solely or jointly own.',
          icon: IconVehicles,
          isRepeatable: true,
          isIndividual: false,
          promptCardData: {
            hasNoneState: true,
            initial: {
              title: 'Do you own any vehicles?',
              content:
                'You will need to add any vehicles that you and your former partner jointly and/or solely own.',
              yesButton: 'Yes, we do',
              noButton: "We don't own a vehicle",
              onClickYes: async () => {
                const { dispatch } = reduxStore;

                await dispatch(addEmptyItemAction('vehicles'));

                history.push(`${ODRS_BASE_ROUTE}/vehicles/1`, {
                  previous: reduxStore.getState().router.location.pathname,
                });
              },
              onClickNo: async () => {
                const { dispatch } = reduxStore;

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsVehicles: true })
                );
              },
            },
            none: {
              title: 'You do not have any vehicles',
              content:
                'You have selected that you and your former partner do not have any vehicles.',
              onClickEdit: async () => {
                const { dispatch } = reduxStore;

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsVehicles: false })
                );
              },
            },
          },
          singular: 'vehicle',
          plural: 'vehicles',
          defaultRoute: `${ODRS_VEHICLES_ROUTE}`,

          children: (() => {
            /** See notes above about children card generation */
            const takenIndexes: number[] = [];

            const items = MatterPropsJS('items');
            const setup =
              items.find((item: Item) => item.SectionID === 'setup') || {};

            return Array.from(
              Array(setup.numVehicles || 0),
              (_el, arrayIndex) => {
                const item = existingVehicles[arrayIndex] || {};
                let index: CardIndex;
                let displayIndex;
                let cardID;

                if (item.SectionID) {
                  index = item.CardIndex;
                  displayIndex = index;
                  cardID = item.SectionID;
                } else {
                  index = getNextAvailableIndex(takenIndexes);
                  displayIndex = arrayIndex + 1;
                  cardID = `vehicles${index}`;
                }

                takenIndexes.push(Number(index));
                return buildCard<CardNormal>({
                  type: CARD_NORMAL,
                  baseID: 'vehicles',
                  cardID,
                  title: item.vehicleMakeModel || `Vehicle ${displayIndex}`,
                  sensitiveTitle: `Vehicle ${displayIndex}`,
                  questions: vehiclesQuestions,
                  statement: vehiclesStatement,
                  index,
                  isRepeatable: true,
                  questionsRoute: `${ODRS_VEHICLES_ROUTE}/${index}`,
                  updateRoute: `${ODRS_VEHICLES_ROUTE}/${index}/update`,
                  status: STATUS_NEW_BOTH_PARTIES,
                });
              }
            );
          })(),
        },
        {
          type: DATA_GROUP,
          id: 'superannuation',
          title: 'Superannuation',
          description:
            'We are going to ask you a few questions to help you value your superannuation. Before you get started, make sure you have the amount of your superannuation balance.',
          icon: IconSuperannuation,
          isRepeatable: true,
          isIndividual: true,
          promptCardData: {
            hasNoneState: false,
            initial: {
              title: 'Do you have any superannuation?',
              content: 'You will need to add any superannuation that you own.',
              yesButton: 'Yes, I do',
              noButton: "No, I don't have any superannuation",
              onClickYes: async () => {
                const { dispatch } = reduxStore;
                const {
                  matter: {
                    self: { party },
                  },
                } = reduxStore.getState();

                await dispatch(addEmptyItemAction(`superannuation${party}`));

                history.push(`${ODRS_YOURSUPERANNUATION_ROUTE}/1${party}`, {
                  previous: reduxStore.getState().router.location.pathname,
                });
              },
              onClickNo: async () => {
                const { dispatch } = reduxStore;
                const {
                  matter: {
                    self: { party },
                  },
                } = reduxStore.getState();

                const messageData = {
                  section: `nosuperannuation1${party}`,
                  owner: party,
                  message: '',
                  status: MESSAGE_STATUS_STATEMENT_CREATED,
                  checkTone: false,
                };

                const sectionData = {
                  owner: getOtherParty(party),
                  status: STATUS_CREATED,
                };

                await dispatch(
                  updateMatterSectionAction({
                    section: ['nosuperannuation', `1${party}`],
                    sectionData,
                    messageData,
                  })
                );

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsSuperannuation: true })
                );
              },
            },
          },
          singular: 'superannuation',
          plural: 'superannuation',
          defaultRoute: `${ODRS_YOURSUPERANNUATION_ROUTE}`,

          children: (() => {
            /** See notes above about children card generation */
            const takenIndexesA: number[] = [];
            const takenIndexesB: number[] = [];

            const items = MatterPropsJS('items');
            const setup =
              items.find((item: Item) => item.SectionID === 'setup') || {};

            const selfHasNoItemsSuperannuation = MatterPropsJS(
              'self.hasNoItemsSuperannuation',
              false
            );

            const otherHasNoItemsSuperannuation = MatterPropsJS(
              'other.hasNoItemsSuperannuation',
              false
            );

            const partyKeys = getPartyKeys();

            const [numSuperSelf, numSuperOther] =
              partyKeys.self === PARTY_A
                ? [setup.numSuperannuationA, setup.numSuperannuationB]
                : [setup.numSuperannuationB, setup.numSuperannuationA];

            return [
              buildCard<CardComponent>({
                type: CARD_COMPONENT,
                cardID: 'superwarningYOU',
                ComponentClass: ConditionalSuperWarning,
              }),
              ...(() => {
                if (selfHasNoItemsSuperannuation) {
                  const noSuperannuationItem = items.find(
                    (item: Item) =>
                      item.SectionID === `nosuperannuation1${partyKeys.self}`
                  );

                  let index;
                  let cardID;

                  if (noSuperannuationItem) {
                    index = noSuperannuationItem.CardIndex;
                    cardID = noSuperannuationItem.SectionID;
                  } else {
                    index = `${getNextAvailableIndex(takenIndexesA)}${
                      partyKeys.self
                    }`;
                    cardID = `nosuperannuation${index}${partyKeys.self}`;
                  }

                  return [
                    buildCard<CardNormal>({
                      type: CARD_NORMAL,
                      baseID: 'nosuperannuation',
                      cardID,
                      title: 'Superannuation',
                      sensitiveTitle: 'Superannuation',
                      questions: noSuperannuationQuestions,
                      statement: noSuperannuationStatement,
                      index,
                      notEditable: true,
                      isRepeatable: true,
                      questionsRoute: `${ODRS_NOSUPERANNUATION_ROUTE}/${index}`,
                      updateRoute: `${ODRS_NOSUPERANNUATION_ROUTE}/${index}/update`,
                      status: STATUS_NEW_ONLY_SELF,
                      removeCardText: 'I do have superannuation',
                      removeCardConfirmText:
                        'Confirming you do have superannuation',
                    }),
                  ];
                }

                return Array.from(
                  Array(numSuperSelf || 0),
                  (_el, arrayIndex) => {
                    const item = existingSuperannuationA[arrayIndex] || {};
                    let index;
                    let displayIndex;
                    let cardID;

                    if (item.SectionID) {
                      index = item.CardIndex;
                      displayIndex = index;
                      cardID = item.SectionID;
                    } else {
                      index = getNextAvailableIndex(takenIndexesA);
                      displayIndex = arrayIndex + 1;
                      cardID = `yoursuperannuation${index}${partyKeys.self}`;
                    }

                    takenIndexesA.push(Number(index));

                    return buildCard<CardNormal>({
                      type: CARD_NORMAL,
                      baseID: 'yoursuperannuation',
                      cardID,
                      title: `Superannuation ${displayIndex}`,
                      sensitiveTitle: `Superannuation ${displayIndex}`,
                      questions: yourSuperannuationQuestions,
                      statement: yourSuperannuationStatement,
                      index: `${index}${partyKeys.self}`,
                      isRepeatable: true,
                      questionsRoute: `${ODRS_YOURSUPERANNUATION_ROUTE}/${index}${partyKeys.self}`,
                      updateRoute: `${ODRS_YOURSUPERANNUATION_ROUTE}/${index}${partyKeys.self}/update`,
                      status: STATUS_NEW_ONLY_SELF,
                      removeCardText:
                        numSuperSelf === 1
                          ? 'I don’t have a superannuation account'
                          : undefined,
                      removeCardConfirmText:
                        numSuperSelf === 1
                          ? 'Confirming you don’t have a superannuation account'
                          : undefined,
                    });
                  }
                );
              })(),
              ...(() => {
                if (otherHasNoItemsSuperannuation) {
                  const noSuperannuationItem = items.find(
                    (item: Item) =>
                      item.SectionID === `nosuperannuation1${partyKeys.other}`
                  );

                  let index;
                  let cardID;

                  if (noSuperannuationItem) {
                    index = noSuperannuationItem.CardIndex;
                    cardID = noSuperannuationItem.SectionID;
                  } else {
                    index = `${getNextAvailableIndex(takenIndexesB)}${
                      partyKeys.other
                    }`;
                    cardID = `nosuperannuation${index}${partyKeys.other}`;
                  }

                  return [
                    buildCard<CardNormal>({
                      type: CARD_NORMAL,
                      baseID: 'nosuperannuation',
                      cardID,
                      title: 'Superannuation',
                      sensitiveTitle: 'Superannuation',
                      questions: noSuperannuationQuestions,
                      statement: noSuperannuationStatement,
                      index,
                      notEditable: true,
                      isRepeatable: true,
                      questionsRoute: `${ODRS_NOSUPERANNUATION_ROUTE}/${index}`,
                      updateRoute: `${ODRS_NOSUPERANNUATION_ROUTE}/${index}/update`,
                      status: STATUS_NEW_ONLY_THEM,
                      removeCardText: 'I do have superannuation',
                      removeCardConfirmText:
                        'Confirming you don’t have a superannuation account',
                    }),
                  ];
                }

                return Array.from(
                  Array(numSuperOther || 0),
                  (_el, arrayIndex) => {
                    const item = existingSuperannuationB[arrayIndex] || {};
                    let index;
                    let displayIndex;
                    let cardID;

                    if (item.SectionID) {
                      index = item.CardIndex;
                      displayIndex = index;
                      cardID = item.SectionID;
                    } else {
                      index = getNextAvailableIndex(takenIndexesB);
                      displayIndex = arrayIndex + 1;
                      cardID = `yoursuperannuation${index}${partyKeys.other}`;
                    }

                    takenIndexesB.push(Number(index));

                    return buildCard<CardNormal>({
                      type: CARD_NORMAL,
                      baseID: 'yoursuperannuation',
                      cardID,
                      title: `Superannuation ${displayIndex}`,
                      sensitiveTitle: `Superannuation ${displayIndex}`,
                      questions: yourSuperannuationQuestions,
                      statement: yourSuperannuationStatement,
                      index: `${index}${MatterPropsJS('other.party', 'B')}`,
                      isRepeatable: true,
                      questionsRoute: `${ODRS_YOURSUPERANNUATION_ROUTE}/${index}${partyKeys.other}`,
                      updateRoute: `${ODRS_YOURSUPERANNUATION_ROUTE}/${index}${partyKeys.other}/update`,
                      status: STATUS_NEW_ONLY_THEM,
                      removeCardText:
                        numSuperSelf === 1
                          ? 'I don’t have a superannuation account'
                          : undefined,
                      removeCardConfirmText:
                        numSuperSelf === 1
                          ? 'Confirming you don’t have a superannuation account'
                          : undefined,
                    });
                  }
                );
              })(),
            ];
          })(),
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PROPERTY_CONTRIBUTIONS,
      title: '2. Contributions',
      route: ODRS_PROPERTY_CONTRIBUTIONS_ROUTE,
      subTitle: '2. Contributions',
      description:
        'We are going to ask some questions about what contributions were made by each of you throughout the relationship.',
      generateStatus: () => {
        const { completed } = getPageStatus({
          section: SECTION_PROPERTY,
          page: PAGE_PROPERTY_CONTRIBUTIONS,
        });

        return getNavItemStatus({
          isCompleted: completed,
          isLocked: !areRelationshipAssetsAllAccountedFor(),
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'contributions2',
          title: 'Your contributions',
          icon: IconContributions,
          children: [
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'contributions',
              cardID: `contributions${MatterPropsJS('self.party', 'A')}`,
              title: 'Your Contributions',
              sensitiveTitle: `Party ${MatterPropsJS(
                'self.party',
                'A'
              )}'s Contributions`,
              questions: contributionsQuestions,
              statement: contributionsStatement,
              index: MatterPropsJS('self.party', 'A'),
              questionsRoute: ODRS_CONTRIBUTIONS_ROUTE,
              updateRoute: `${ODRS_CONTRIBUTIONS_ROUTE}/update`,
              status: STATUS_NEW_ONLY_SELF,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'contributions',
              cardID: `contributions${MatterPropsJS('other.party', 'B')}`,
              title: `${MatterPropsJS(
                'other.firstname',
                'Them'
              )}'s Contributions`,
              sensitiveTitle: `Party ${MatterPropsJS(
                'other.party',
                'B'
              )}'s Contributions`,
              questions: contributionsQuestions,
              statement: contributionsStatement,
              index: MatterPropsJS('other.party', 'B'),
              questionsRoute: ODRS_CONTRIBUTIONS_ROUTE,
              status: STATUS_NEW_ONLY_THEM,
            }),
          ],
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PROPERTY_ASSET_DIVISION,
      title: '3. Asset division',
      route: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
      subTitle: '3. Asset division',
      description:
        'You will have to agree on all your statements before you can get started on this section. amica will suggest a percentage division and each of you will have the chance to agree or make your own offer.',
      lockedTooltip:
        'You will have to agree on all your assets statements before you can get started on this section.',
      getPageButtonProps: () => {
        const { hasConfirmedStatement } = DiviProps();
        const { dispatch } = reduxStore;
        const { self, other } = reduxStore.getState().matter;

        const hasEitherPartyStartedSuggestedDivision =
          hasConfirmedStatement.self || hasConfirmedStatement.other;
        const hasEitherPartyStartedConsentOrders =
          self.canStartConsentOrders || other.canStartConsentOrders;
        const hasPropertyAgreementBeenGenerated = !!(
          self.propertyAgreementDate || other.propertyAgreementDate
        );

        if (
          hasEitherPartyStartedSuggestedDivision &&
          !hasEitherPartyStartedConsentOrders &&
          !hasPropertyAgreementBeenGenerated
        ) {
          return {
            showButton: true,
            onClick: async () =>
              dispatch(
                setPartyFlagsAction({ readyToResetSuggestedDivision: true })
              ),
            buttonText: 'Restart section',
          };
        }

        return {
          showButton: false,
          onClick: () => Promise.resolve(),
          buttonText: '',
        };
      },
      generateStatus: () => {
        const { agreedToDivision } = DiviProps();

        const { isAssetSplitCompleted } = AssetProps();

        return getNavItemStatus({
          isCompleted: agreedToDivision && isAssetSplitCompleted,
          isLocked: !areSettlementAssetsAllAccountedFor(),
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'assetDivision',
          children: [
            buildCard<CardComponent>({
              type: CARD_COMPONENT,
              cardID: 'assetDivisionCards',
              ComponentClass: AssetDivisionCards,
            }),
          ],
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PROPERTY_COMPLETE,
      title: '4. Document selection',
      route: ODRS_PROPERTY_COMPLETE_ROUTE,
      subTitle: '4. Document selection',
      description:
        'Once you complete Dividing your Money and Property, you will have several options about how you will keep a record of what you have agreed and the way you’d like to put your agreement into effect.',
      lockedTooltip:
        'You will have to agree on all your assets statements before you can get started on this section.',
      generateStatus: () => {
        const { agreedToDivision } = DiviProps();

        const { isAssetSplitCompleted } = AssetProps();

        return getNavItemStatus({
          isCompleted:
            areSettlementAssetsAllAccountedFor() &&
            agreedToDivision &&
            isAssetSplitCompleted,
          isLocked:
            !areSettlementAssetsAllAccountedFor() ||
            !agreedToDivision ||
            !isAssetSplitCompleted,
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'complete',
          children: [
            buildCard<CardComponent>({
              type: CARD_COMPONENT,
              cardID: 'propertyDocumentCards',
              ComponentClass: PropertyDocumentCards,
            }),
          ],
        },
      ],
    },
  ],
});

export default generateProperty;
