import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router';
import styled, { css } from 'styled-components';
import { useTransition, animated } from 'react-spring/web.cjs';
import moment from 'moment';
import { TransitionGroup, Transition } from 'react-transition-group';

import {
  MESSAGE_STATUS_UPDATE_REQUESTED,
  MESSAGE_STATUS_MESSAGE,
  STATUS_CREATED,
  STATUS_UPDATE_REQUESTED,
  STATUS_UPDATE_DONE,
  STATUS_DISCUSSION,
  STATUS_APPROVED,
  PARTY_BOTH,
  CardStatus,
  CARD_STATEMENT,
  CARD_CUSTOM,
} from 'Common/constants';
import { getCard, getCardDashboardRoute } from 'Common/Data/App/appSections';
import { JurisdictionListPopUpContent } from 'Common/UI/Card/indicators/JurisdictionListPopUpContent';
import {
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_EDIT_STATEMENT_ROUTE,
} from 'Common/routes';
import MatterProps from 'Common/Utils/MatterProps';
import IconEdit from 'Common/Assets/images/icon-edit.svg';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import { CardIndex, QuestionCard } from 'Common/Data/Types/appSections';
import { Item, Message as TypeMessage } from 'Common/Data/Types/matter';
import getNames from 'Common/Utils/getNames';
import push from 'Common/Utils/push';
import IconTickSelf from 'Common/Assets/images/icon-tick-self.svg';
import IconTickOther from 'Common/Assets/images/icon-tick-other.svg';
import capitaliseText from 'Common/Utils/capitaliseText';
import defaultTheme, { blueGreyMobileTheme, Theme } from 'Common/Utils/theme';
import { Heading2, HeadingSpacedContainer } from 'Common/UI/Text/Headings';
import { Label, HighlightedLabel } from 'Common/UI/Text/Label';
import Appears from 'Common/UI/Layout/Appears';
import IconTextButton from 'Common/UI/Button/IconTextButton';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import { getNumberOfBackAndForth } from 'Common/Utils/getMessages';
import getYouOrThem from 'Common/Utils/getYouOrThem';
import StatementPopUp from 'Common/UI/StatementPopUp';
import Message from 'Common/UI/Statements/Message';
import ToneWarning from 'Common/UI/Messaging/ToneWarning';
import SoftNudge from 'Common/UI/Messaging/SoftNudge';
import StatementActionBar from 'App/UI/Statements/StatementActionBar';

import {
  BackButton,
  StyledNavButton,
  DetailsViewHeader,
} from 'Common/UI/DetailsView';
import {
  SCREEN_STATE_VIEW,
  SCREEN_STATE_ACTION,
} from 'Common/UI/Division/constants';
import { StatementParagraph } from 'Common/UI/Questions/common';
import {
  openOdie as openOdieAction,
  closeOdie as closeOdieAction,
  updateOdie as updateOdieAction,
} from 'App/State/OdieReducer';
import useCardCheckAccess from 'Common/Utils/Hooks/useCardCheckAccess';

const StyledDetailsViewHeader = styled(DetailsViewHeader)<{
  state: string;
  theme: Theme;
  inOdie: boolean;
  children?: React.ReactNode;
}>`
  padding: ${({ theme }) => `${theme.spacing[2]} 0 ${theme.spacing[8]}`};

  ${({ inOdie, theme }) =>
    !inOdie &&
    css`
      padding: ${theme.spacing[8]};
    `}

  min-height: auto;
  ${Heading2} {
    margin: 0;
  }

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) => `0 0 ${theme.spacing[6]}`};

    ${({ inOdie, theme }) =>
      !inOdie &&
      css`
        padding: ${theme.spacing[8]};
      `}
  }
`;

const StatementWrapper = styled.div`
  overflow: auto;
`;

const Statement = styled.div<{
  theme: Theme;
}>`
  padding: ${props => props.theme.padding.medium}px 40px;
  border-radius: 4px;
  z-index: 100;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  display: inline-flex;
  flex-direction: column;
  max-height: 45%;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    max-height: 35%;
  }

  @media ${({ theme }) => theme.sizes.xxl.breakpoint} {
    max-height: 50%;
  }
`;

const Owner = styled(Label)<{
  theme: Theme;
}>`
  font-weight: bold;
  color: ${props => props.theme.colours.darkGrey};
  margin-bottom: 8px;
`;

const AgreedInfo = styled.div`
  position: relative;
`;

const IncludeNote = styled(Label)<{
  theme: Theme;
}>`
  font-weight: normal;
  color: ${props => props.theme.colours.darkGrey};
  margin: ${props => props.theme.padding.small}px 0 0;
`;

const BothPartiesAgreed = styled(HeadingSpacedContainer)<{
  theme: Theme;
}>`
  display: flex;
  height: 52px;
  position: relative;
  top: 15px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid transparent;
  margin-bottom: 0;

  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    top: 0;
    left: -16px;
    right: -16px;
    background-color: ${props => props.theme.colours.clickableGrey};
  }
`;

const BothPartiesAgreedTicksWrap = styled.span`
  display: flex;
  justify-content: space-between;
  width: 60px;
`;

const MessagesBody = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
  background-color: ${defaultTheme.colours.white};
`;

const MessagesPositionWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 20px;
`;

const StyledStatementDetails = styled.div<{
  inOdie?: boolean;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props =>
    props.inOdie &&
    `
    ${Statement} {
      padding-left: 0;
      padding-right: 0;
    }
  `};
`;

const StyledIconTextButton = styled(IconTextButton)`
  justify-content: start;
`;

const transitionDuration = 250;
const transitionDelay = 20;

const StatementDetails: React.FC = () => {
  const { section, index } = useParams<{ section?: string; index?: string }>();

  const cardID = `${section}${index || ''}`;
  const messageListRef = useRef<HTMLDivElement>(null);

  const { state: { background } = {} } = useLocation<{ background?: any }>();

  const matter = useAppSelector(state => state.matter);

  const dispatch = useAppDispatch();

  const updateMatterSection = (
    section: [string, CardIndex],
    sectionData: Item,
    messageData: any
  ) =>
    dispatch(updateMatterSectionAction({ section, sectionData, messageData }));
  const navigate = (to: string, state?: any) => dispatch(push(to, state));
  const closeOdie = () => dispatch(closeOdieAction());
  const openOdie = () => dispatch(openOdieAction());
  const updateOdie = (data: any) => dispatch(updateOdieAction(data));

  const scrollToBottom = () => {
    const { current } = messageListRef;

    if (current) {
      current.scrollTop = current.scrollHeight - current.clientHeight;
    }
  };

  const [softNudge, updateSoftNudge] = useState(false);

  useEffect(scrollToBottom, []);

  // On third round of conversation to get soft nudge message
  useEffect(() => {
    updateSoftNudge(getNumberOfBackAndForth(matter.messages, cardID) >= 4);
  }, [matter]);

  const [state, updateState] = useState(SCREEN_STATE_VIEW); // statement state - view, typing message, waiting, etc
  const [isMessageSending, updateMessageSending] = useState(false);
  const [toneWarning, updateToneWarning] = useState(false);
  const [hasSentMessage, updateHasMessageSent] = useState(false);
  const [hasDismissedToneWarning, updateDismissedToneWarning] = useState(false);
  const [loadInOdie, setOdieOpen] = useState(false);
  const card = getCard(cardID) as QuestionCard;
  const previousRoute =
    getCardDashboardRoute(cardID) || ODRS_DASH_PROPERTY_ROUTE;

  const statement = card.statement({ matter, data: card.data, card });

  const names = getNames();

  // redirect back to dashboard if this card doesn't exist
  if (!card.data || !card.data.created) {
    // it gets stuck in an infinite loop without setTimeout - not sure why
    setTimeout(() => navigate(previousRoute));
  }

  const messages = matter.messages.filter(
    (message: TypeMessage) =>
      message.MessageID.split('|')[0] === `${section}${index || ''}`
  );

  let pageTitle;

  switch (state) {
    case SCREEN_STATE_VIEW:
      pageTitle = card.title || '';

      break;
    case SCREEN_STATE_ACTION:
    default:
      pageTitle = 'Send a message';

      break;
  }

  let agreedState = 'empty';

  if (card.status === STATUS_APPROVED) {
    agreedState = 'agreed';
  } else {
    agreedState = 'note';
  }

  const [agreedInfoItem, updateAgreedInfoItem] = useState(agreedState);

  useEffect(() => {
    if (card.status === STATUS_APPROVED) {
      updateAgreedInfoItem('agreed');
    } else {
      updateAgreedInfoItem('note');
    }
  }, [card.status]);

  const {
    cardForParty,
    questionsRoute = '',
    typeName,
    notEditable,
    type,
  } = card;

  let beforeStatementContent;
  let afterStatementContent;

  const editButton = notEditable ? null : (
    <StyledIconTextButton
      subtle
      small
      iconOnLeft
      icon={IconEdit}
      onClick={() => {
        if (background) {
          closeOdie();
        }

        // if the card has a summary component or is a statement type,
        // skip the normal edit statement screen and go straight into editing it
        if (type === CARD_CUSTOM || type === CARD_STATEMENT) {
          navigate(questionsRoute);
        } else {
          navigate(`${ODRS_EDIT_STATEMENT_ROUTE}/${cardID}`);
        }
      }}
      data-cy="edit-statement-button"
    >
      Edit {typeName}
    </StyledIconTextButton>
  );

  if (!notEditable) {
    switch (cardForParty) {
      case 'other':
        beforeStatementContent = `Only ${names.other} can edit`;
        afterStatementContent =
          'If anything is incorrect, you can send them a message on amica, explaining what you would like them to change.';
        break;

      case 'self':
        beforeStatementContent = 'Only you can edit';
        afterStatementContent = editButton;
        break;

      case PARTY_BOTH:
      default:
        beforeStatementContent = `You both can edit`;
        afterStatementContent = editButton;
        break;
    }
  }

  const agreedItems = {
    empty: null,
    note: <IncludeNote>{afterStatementContent}</IncludeNote>,
    agreed: (
      <BothPartiesAgreed>
        <HighlightedLabel>{`You and ${MatterProps(
          'other.firstname'
        )} agreed`}</HighlightedLabel>
        <BothPartiesAgreedTicksWrap>
          <img src={IconTickSelf} alt="Tick icon" />
          <img src={IconTickOther} alt="Tick icon" />
        </BothPartiesAgreedTicksWrap>
      </BothPartiesAgreed>
    ),
  };

  const transitions = useTransition(agreedInfoItem, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });

  const onSendMessage = useCallback(
    (text: string) => {
      const messageData = {
        section: card.cardIDAsArray,
        owner: MatterProps('self.party'),
        message: text,
        checkTone: !hasDismissedToneWarning,
        status: MESSAGE_STATUS_MESSAGE,
      };

      if (
        card.data.creator === MatterProps('other.party') &&
        (card.status === STATUS_CREATED || card.status === STATUS_UPDATE_DONE)
      ) {
        messageData.status = MESSAGE_STATUS_UPDATE_REQUESTED;
      }

      let cardStatus: CardStatus = STATUS_DISCUSSION;

      if (messageData.status === MESSAGE_STATUS_UPDATE_REQUESTED) {
        cardStatus = STATUS_UPDATE_REQUESTED;
      }
      updateMessageSending(true);

      return updateMatterSection(
        card.cardIDAsArray,
        { ...card.data, status: cardStatus },
        messageData
      )
        .then(() => {
          const { current } = messageListRef;

          if (current) {
            current.scrollTop = current.scrollHeight - current.clientHeight;
          }

          updateMessageSending(false);
          updateHasMessageSent(true);
        })
        .catch((err: any) => {
          if (err.type && err.type === 'ToneError') {
            updateToneWarning(true);
            updateMessageSending(false);
            scrollToBottom();
          } else {
            throw err;
          }
        });
    },
    [
      card.cardIDAsArray,
      card.data,
      card.status,
      hasDismissedToneWarning,
      updateMatterSection,
    ]
  );

  const navigateAndCloseOdie = (route: string) => {
    if (background) {
      closeOdie();
    }

    navigate(route);
  };

  const buildMessages = () => {
    if (messages.length === 0) {
      return null;
    }
    return messages.map((message: TypeMessage, messageIndex: number) => (
      <Transition timeout={transitionDuration} key={message.MessageID}>
        {transitionState => (
          <Message
            owner={
              MatterProps('self.party') === message.owner ? 'self' : 'other'
            }
            youOrThem={capitaliseText(getYouOrThem(message.owner))}
            date={moment(message.date).calendar()}
            message={message.message}
            status={message.status}
            transitionState={transitionState}
            transitionDuration={transitionDuration}
            transitionDelay={transitionDelay * messageIndex + 500}
            cardTypeName={card.typeName}
          />
        )}
      </Transition>
    ));
  };

  const isStateAction = state === SCREEN_STATE_ACTION;

  let summary;

  if (type === CARD_CUSTOM) {
    summary = (
      <StatementWrapper>
        {card.summaryComponentGenerator({
          values: card.data,
          isActive: true,
        })}
      </StatementWrapper>
    );
  } else if (state === SCREEN_STATE_VIEW) {
    summary = (
      <StatementWrapper>
        <StatementParagraph>{statement}</StatementParagraph>
        {section === 'jurisdiction' && (
          <StatementPopUp
            popUpTitle="Jurisdiction List"
            buttonText="View all jurisdiction locations"
            content={JurisdictionListPopUpContent}
          />
        )}
      </StatementWrapper>
    );
  } else {
    summary = <StatementParagraph>{statement}</StatementParagraph>;
  }

  // Load in Odie
  useEffect(() => {
    // if we have a background location set, render content in the odie
    if (!loadInOdie) {
      if (background) {
        setOdieOpen(true);
        updateOdie({
          hideFooter: true,
          theme: blueGreyMobileTheme,
          onClose: () => {
            navigate(previousRoute);
          },
        });
        openOdie();
      }
    }
  }, [updateOdie, openOdie, background, loadInOdie, navigate, previousRoute]);

  const cardStatusActioned = [STATUS_APPROVED, STATUS_UPDATE_DONE].includes(
    card.status
  );

  // Check to see if we can access this question flow
  const ShouldRedirectComponent = useCardCheckAccess(matter, cardID);

  if (ShouldRedirectComponent) {
    return ShouldRedirectComponent;
  }

  return (
    <StyledStatementDetails
      inOdie={!!background}
      data-cy="statement-details-wrapper"
    >
      <StyledDetailsViewHeader
        className="transition-fade-up"
        state={state}
        inOdie={!!background}
      >
        <BackButton
          state={state}
          type="back"
          onClick={() => updateState(SCREEN_STATE_VIEW)}
        />
        <Heading2>{pageTitle}</Heading2>
        {state === SCREEN_STATE_VIEW && (
          <StyledNavButton
            data-cy={
              cardStatusActioned || hasSentMessage
                ? 'button-done'
                : 'button-close'
            }
            state={state}
            type={cardStatusActioned || hasSentMessage ? 'done' : 'close'}
            onClick={() => navigateAndCloseOdie(previousRoute)}
          />
        )}
      </StyledDetailsViewHeader>
      {statement && (
        <Statement>
          <>
            {!isStateAction && <Owner>{beforeStatementContent}</Owner>}
            {summary}
            {!isStateAction && (
              <AgreedInfo>
                {transitions.map(({ item, key, props }) => (
                  <animated.div key={key} style={props}>
                    {(agreedItems as any)[item]}
                  </animated.div>
                ))}
              </AgreedInfo>
            )}
          </>
        </Statement>
      )}
      <MessagesBody className="transition-fade">
        {card.status !== STATUS_APPROVED && (
          <MessagesPositionWrap ref={messageListRef}>
            <TransitionGroup component={null} appear mountOnEnter>
              {buildMessages()}
            </TransitionGroup>
            <Appears on={toneWarning}>
              <ToneWarning
                onClick={() => {
                  updateToneWarning(false);
                  updateDismissedToneWarning(true);
                  scrollToBottom();
                }}
              />
              <br />
            </Appears>
            <Appears on={softNudge}>
              <SoftNudge
                onClick={() => {
                  updateSoftNudge(false);
                  scrollToBottom();
                }}
              />
              <br />
            </Appears>
          </MessagesPositionWrap>
        )}
      </MessagesBody>

      <StatementActionBar
        state={state}
        card={card}
        isMessageSending={isMessageSending}
        updateMessageSending={updateMessageSending}
        onSendMessage={onSendMessage}
        updateState={updateState}
        inOdie={!!background}
        onClickDone={() => navigateAndCloseOdie(previousRoute)}
      />
    </StyledStatementDetails>
  );
};

export default StatementDetails;
