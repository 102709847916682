import styled, { css } from 'styled-components';

import ResponsiveButtonContainer from 'Common/UI/Layout/ResponsiveButtonContainer';
import PageContent from 'Common/UI/Layout/PageContent';
import PageFooter from 'Common/UI/Layout/PageFooter';
import TextButton from 'Common/UI/Button/TextButton';
import Textfield from 'Common/UI/Form/Textfield';

export const InnerContent = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9);

  opacity: 0;
  transition: all 0.5s;

  ${props =>
    (props.transitionState === 'entering' ||
      props.transitionState === 'entered') &&
    css`
      opacity: 1;
    `}
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledTextfield = styled(Textfield)`
  margin-bottom: ${props => props.theme.padding.medium}px;
`;

export const ButtonsContainerInner = styled(ResponsiveButtonContainer)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledTextButton = styled(TextButton)`
  font-family: ${props => props.theme.typography.fonts.default};
  color: ${props => props.theme.colours.darkGrey};
  font-weight: 500;
  text-align: center;
  padding: 12px 20px;
`;

export const InnerContentMiddle = styled(InnerContent)`
  justify-content: center;
`;

export const StyledPageContent = styled(PageContent)`
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
`;

export const StyledPageFooter = styled(PageFooter)`
  overflow: hidden;
`;

export const InnerContentMiddleCenter = styled(InnerContent)`
  justify-content: center;
  text-align: center;
  overflow: visible;
`;

export const Image = styled.img`
  width: auto;
  margin: ${props => props.theme.padding.medium}px auto;
`;

export const ButtonsWrapper = styled.div`
  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  }
`;

export const ButtonContainer = styled.div`
  width: 260px;
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    width: 266px;
    display: inline-block;
  }
`;
