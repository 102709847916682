import styled from 'styled-components';

export const Period = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  opacity: ${props => (props.visible ? '0.2' : '0')};
  transition: opacity 0.5;
  border-radius: 32px;

  left: ${props => props.start || '0'}px;
  width: ${props => props.width || '0'}px;

  background-color: ${props =>
    props.self ? props.theme.colours.midLightPurple : props.theme.colours.aqua};
`;

export const BlankPeriod = styled(Period)`
  opacity: 0.4;
  background-color: ${props =>
    props.isActive
      ? props.theme.colours.backgroundGrey
      : props.theme.colours.midLightGrey};
  border-radius: 24px;
  width: auto;
  left: ${props => props.periodOffset}px;
  right: ${props => props.periodOffset}px;
`;
