import BackgroundShapes from 'Common/Assets/images/bg-signup-questions-shapes.png';
import styled from 'styled-components';

const SignupQuestionFlowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default SignupQuestionFlowWrapper;
