import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  BANNER_HIDE,
  BANNER_SHOW,
  SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYER,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import AssetProperties from 'Common/Data/App/assetProperties';

export const superannuationConsentOrderNoChangesPayerFlags: AppState = {
  id: 'superannuationConsentOrderNoChangesPayer',
  description:
    'Notify the payer that they have confirmed that no changes are required to superannuation, CO is now finalised',
  check: () => {
    const { isSuperIncludedInOffer, superSplittingFlags, isPayer } =
      AssetProperties();

    const { payerHasConfirmedNoChangesToClauses } = superSplittingFlags;

    if (!isSuperIncludedInOffer || !isPayer) {
      return false;
    }

    return !!payerHasConfirmedNoChangesToClauses;
  },
  flags: {
    appState: SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYER,
    bannerTopState: () =>
      sessionStorageLoad('superannuationConsentOrderNoChangesPayerDismissed')
        ? BANNER_HIDE
        : BANNER_SHOW,
  },
};

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperannuationConsentOrderNoChangesPayer: React.FC<Props> = ({
  otherName: _otherName,
  updateAppState,
}) => (
  <div data-cy="banner-consent-order-no-changes-payer">
    <SpacedBox marginBottom={8}>
      <Heading2MarginTopNone>Congratulations!</Heading2MarginTopNone>
      <Paragraph>
        You are now ready to file your application for consent orders.
      </Paragraph>
    </SpacedBox>
    <GenericButton
      fullWidth
      onClick={() => {
        sessionStorageSave(
          `superannuationConsentOrderNoChangesPayerDismissed`,
          true
        );
        updateAppState();
      }}
      small
      data-cy="dismiss-button"
    >
      Got it
    </GenericButton>
  </div>
);
