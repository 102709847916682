import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MatterPropsJS from 'Common/Utils/MatterProps';
import { Theme } from 'Common/Utils/theme';
import Textfield from 'Common/UI/Form/Textfield';
import TextAreafield from 'Common/UI/Form/TextAreafield';
import FirstLastNameField from 'Common/UI/Form/FirstLastNameField';
import GoogleAddressAutoCompleteTextfield from 'Common/UI/Form/GoogleAddressAutoCompleteTextfield';
import PasswordField from 'Common/UI/Form/Passwordfield';
import MobileField from 'Common/UI/Form/MobileField';
import YesNo from 'Common/UI/Form/YesNo';
import StateCity from 'Common/UI/Form/StateCity';
import Date from 'Common/UI/Form/Date';
import Time from 'Common/UI/Form/Time';
import DocumentUploader from 'Common/UI/Form/DocumentUploader';
import NumberStepper from 'Common/UI/Form/NumberStepper';
import { Heading2, H2Conversational } from 'Common/UI/Text/Headings';
import ButtonsRadioSelect from 'Common/UI/Form/ButtonsRadioSelect';
import SelectDropdown from 'Common/UI/Form/SelectDropdown';
import IncludeStatement from 'Common/UI/Form/IncludeStatement';
import FormDescription from 'Common/UI/Form/FormDescription';
import Paragraph from 'Common/UI/Text/Paragraph';
import MultiSelect from 'Common/UI/Form/MultiSelect';
import MultiItems from 'Common/UI/Form/MultiItems/MultiItems';
import { QuestionAny } from 'Common/Data/Types/appSections';
import { EditableStatement } from '../../../../../../common/UI/Questions/common';
import Pathway from '../../../../../../common/UI/Form/Pathway';

const Inner = styled.div`
  flex-grow: 0;
  position: relative;
`;

const StyledH2Conversational = styled(H2Conversational)<{
  theme: Theme;
}>`
  margin-bottom: 16px;
  a {
    ${props => props.theme.typography.headings.h2Conversational};
  }
`;

const StyledQuestion = styled.div<{
  theme: Theme;
}>``;

const DrawerSpacer = styled.div`
  height: 100px;
`;

const getLabel = (label: any, values: any) =>
  label && {}.toString.call(label) === '[object Function]'
    ? label(values)
    : label;

// TODO update the `any` prop types
type Props = {
  focus?: boolean;
  onBlur: any;
  onFocus: any;
  onInput: any;
  sectionID?: string;
  state: any;
  statement: any;
  validationMessage: any;
  values: any;
  question: QuestionAny;
  isUpdating: boolean;
};

const Question: React.FC<Props> = ({
  focus = false,
  onBlur,
  onFocus,
  onInput,
  sectionID = '',
  state,
  statement,
  validationMessage,
  values,
  question,
  isUpdating,
}) => {
  const [defaultValue, setDefaultValue] = useState(undefined);

  const {
    description,
    defaultValue: dValue,
    hint,
    label: labelData,
    disabled,
    placeholder,
    type,
    content: contentBeforeField,
    name,
  } = question;

  const label = getLabel(labelData, values);
  const value = values[question.name];

  const isDisabled = disabled || state !== 'active';

  // Cleanup
  useEffect(() => {
    setDefaultValue(() =>
      typeof dValue === 'function' ? dValue(values) : dValue
    );
  }, [values, dValue]);

  const fixedDefaultValue = defaultValue || undefined;
  // Note: Yes/No question value is saved as true or false. need to remove from this
  // logic
  const fixedValue = !value && value !== false ? fixedDefaultValue : value;

  let field;

  let content;

  if (type === 'statement') {
    content = (
      <>
        <Heading2>
          {isUpdating ? 'Statement' : 'You created a statement'}
        </Heading2>

        <EditableStatement statement={statement} />

        <DrawerSpacer />
      </>
    );
  } else {
    switch (type) {
      case 'FirstLastName':
        field = (
          <>
            <FirstLastNameField
              onChange={(val: any) => onInput(name, val)}
              name={name}
              label={hint}
              value={fixedValue}
              placeholder={placeholder}
              disabled={isDisabled}
              onBlur={onBlur}
              onFocus={onFocus}
              inputmode="text"
            />
            {description && <FormDescription>{description}</FormDescription>}
          </>
        );
        break;
      case 'freetext':
        field = (
          <>
            <TextAreafield
              onChange={(val: any) => onInput(name, val)}
              name={name}
              label={hint}
              value={fixedValue}
              placeholder={placeholder}
              disabled={isDisabled}
              onBlur={onBlur}
              onFocus={onFocus}
              rows={3}
              inputmode="text"
            />
            {description && <FormDescription>{description}</FormDescription>}
          </>
        );
        break;
      case 'info':
        field = '';
        break;

      case 'multiItems': {
        const { formAttributes } = question;

        field = (
          <MultiItems
            key={name}
            onChange={(val: any) => onInput(name, val)}
            name={name}
            label={hint}
            value={fixedValue}
            placeholder={placeholder}
            disabled={isDisabled}
            onBlur={onBlur}
            onFocus={onFocus}
            formAttributes={formAttributes}
          />
        );

        break;
      }
      case 'googleAddress':
        field = (
          <GoogleAddressAutoCompleteTextfield
            // Need for forwardRef but using onSelect with Google Places Autocomplete Component
            key={name}
            name={name}
            onBlur={onBlur}
            onFocus={onFocus}
            onInput={onInput}
            value={fixedValue}
          />
        );

        break;

      case 'state_city':
        field = (
          <StateCity
            onChange={(val: any) => onInput(name, val)}
            name={name}
            value={fixedValue}
            disabled={isDisabled}
          />
        );

        break;

      case 'email':
        field = (
          <Textfield
            onChange={(val: any) => onInput(name, val)}
            name={name}
            label={hint}
            value={fixedValue}
            placeholder={placeholder}
            disabled={isDisabled}
            onBlur={onBlur}
            onFocus={onFocus}
            type="email"
            inputmode="email"
          />
        );

        break;

      case 'password': {
        const { showConfirmField } = question;

        field = (
          <PasswordField
            showStrength
            value={fixedValue}
            name={name}
            label={hint}
            onChange={onInput}
            disabled={isDisabled}
            onBlur={onBlur}
            showConfirmField={showConfirmField}
          />
        );

        break;
      }
      case 'phone_number':
        field = (
          <MobileField
            value={fixedValue}
            name={name}
            label={label}
            onChange={(val: any) => onInput(name, val)}
            disabled={isDisabled}
            onBlur={onBlur}
            hint={hint}
          />
        );

        break;

      case 'verify':
        field = (
          <>
            <div>
              <H2Conversational style={{ marginBottom: '0' }}>
                Thanks, we’ve sent a verification code to your mobile number
              </H2Conversational>
              <Paragraph>
                Check your phone and then enter the verification code below.
              </Paragraph>
            </div>
            <br />
            <Textfield
              value={fixedValue}
              name={name}
              label={hint}
              placeholder="000000"
              onChange={(val: any) => onInput(name, val)}
              onFocus={onFocus}
              disabled={isDisabled}
              onBlur={onBlur}
              inputmode="numeric"
            />
          </>
        );

        break;

      case 'yesno':
        field = (
          <>
            <YesNo
              value={fixedValue}
              name={name}
              label={hint}
              onChange={(val: any) => onInput(name, val)}
              disabled={isDisabled}
            />
            {description && <FormDescription>{description}</FormDescription>}
          </>
        );

        break;

      case 'currency':
        field = (
          <Textfield
            currency
            mobile
            onChange={(val: any) => onInput(name, val, 'currency')}
            name={name}
            label={hint}
            value={fixedValue}
            placeholder={placeholder}
            disabled={isDisabled}
            onBlur={onBlur}
            onFocus={onFocus}
            inputmode="numeric"
          />
        );

        break;

      case 'percentage':
        field = (
          <Textfield
            percentage
            mobile
            onChange={(val: any) => onInput(name, val)}
            name={name}
            label={hint}
            value={fixedValue}
            placeholder={placeholder}
            disabled={isDisabled}
            onBlur={onBlur}
            onFocus={onFocus}
            inputmode="numeric"
          />
        );

        break;

      case 'number':
        field = (
          <NumberStepper
            onChange={(val: any) => onInput(name, val)}
            name={name}
            label={hint}
            value={fixedValue}
            min={0}
            max={10}
          />
        );

        break;

      case 'date': {
        const { formAttributes } = question;

        field = (
          <Date
            value={fixedValue}
            name={name}
            label={hint}
            onChange={(val: any) => onInput(name, val)}
            disabled={isDisabled}
            onBlur={onBlur}
            formAttributes={formAttributes}
          />
        );

        break;
      }
      case 'time': {
        const { formAttributes } = question;

        field = (
          <Time
            value={fixedValue}
            name={name}
            label={hint}
            onChange={(val: any) => onInput(name, val)}
            disabled={isDisabled}
            onBlur={onBlur}
            formAttributes={formAttributes}
          />
        );

        break;
      }
      case 'includeStatement':
        field = (
          <IncludeStatement
            value={fixedValue}
            name={name}
            label={hint}
            onChange={(val: any) => onInput(name, val)}
            type={type}
          />
        );

        break;

      case 'selectDropdown': {
        const { options } = question;

        field = (
          <SelectDropdown
            value={fixedValue}
            name={name}
            label={hint}
            options={options ? options(values) : undefined}
            onChange={(val: any) => onInput(name, val)}
            disabled={isDisabled}
            type={type}
          />
        );

        break;
      }
      case 'select':
        {
          const { options } = question;

          field = (
            <ButtonsRadioSelect
              value={fixedValue}
              name={name}
              label={hint}
              options={options ? options(MatterPropsJS) : []}
              onChange={(val: any) => onInput(name, val)}
              disabled={isDisabled}
              type={type}
            />
          );
        }
        break;

      case 'multiselect': {
        const { options } = question;

        field = (
          <MultiSelect
            value={fixedValue}
            name={name}
            label={hint}
            options={options ? options(MatterPropsJS) : undefined}
            onChange={(val: any) => onInput(name, val)}
            type={type}
          />
        );

        break;
      }
      case 'blocker':
        field = <br />;

        break;

      case 'docupload':
        field = (
          <DocumentUploader
            sectionID={sectionID}
            onChange={(val: any) => onInput(name, val)}
            value={fixedValue}
            focus={focus}
          />
        );

        break;

      case 'legalname': {
        const { firstname, middlename, lastname } = fixedValue || {};

        field = (
          <>
            <Textfield
              onChange={(val: any) =>
                onInput('legalname', { firstname: val, middlename, lastname })
              }
              name="firstname"
              label="First name"
              value={firstname}
              placeholder={placeholder}
              disabled={isDisabled}
              onBlur={onBlur}
              onFocus={onFocus}
              inputmode="text"
            />

            <br />

            <Textfield
              onChange={(val: any) =>
                onInput('legalname', { firstname, middlename: val, lastname })
              }
              name="middlename"
              label="Middle name (optional)"
              value={middlename}
              placeholder={placeholder}
              disabled={isDisabled}
              onBlur={onBlur}
              onFocus={onFocus}
              inputmode="text"
            />

            <br />

            <Textfield
              onChange={(val: any) =>
                onInput('legalname', { firstname, middlename, lastname: val })
              }
              name="lastname"
              label="Last name"
              value={lastname}
              placeholder={placeholder}
              disabled={isDisabled}
              onBlur={onBlur}
              onFocus={onFocus}
              inputmode="text"
            />
            {description && <FormDescription>{description}</FormDescription>}
          </>
        );

        break;
      }
      case 'pathway': {
        const { options } = question;

        field = (
          <Pathway
            options={options(MatterPropsJS)}
            onChange={(val: any) => onInput(name, val)}
          />
        );

        break;
      }
      case 'text':
      default:
        field = (
          <>
            <Textfield
              onChange={(val: any) => onInput(name, val)}
              name={name}
              label={hint}
              value={fixedValue}
              placeholder={placeholder}
              disabled={isDisabled}
              onBlur={onBlur}
              onFocus={onFocus}
              inputmode="text"
            />
            {description && <FormDescription>{description}</FormDescription>}
          </>
        );

        break;
    }

    content = (
      <>
        <StyledH2Conversational>{label}</StyledH2Conversational>
        {typeof contentBeforeField === 'function'
          ? contentBeforeField(values)
          : contentBeforeField}
        {field}
        {validationMessage && (
          <>
            <br />
            {validationMessage}
          </>
        )}
      </>
    );
  }

  return (
    <StyledQuestion data-cy={name}>
      <Inner>{content}</Inner>
    </StyledQuestion>
  );
};

export default Question;
