import React from 'react';
import CardQuestionsFlow from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSNoSuperannuation: React.FC = props => (
  <MatterGuard>
    <CardQuestionsFlow baseID="nosuperannuation" indexFromParams {...props} />
  </MatterGuard>
);

export default ODRSNoSuperannuation;
