import React from 'react';
import formatCurrency from 'Common/Utils/formatCurrency';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
  checkStartsWithVowel,
} from 'Common/Utils/statementHelpers';

import DocumentList from 'Common/UI/UploadedDocuments/DocumentList';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import Paragraph from 'Common/UI/Text/Paragraph';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import isFileObject from 'Common/Utils/isFileObject';
import { Party, PARTY_A, PARTY_B } from 'Common/constants';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Matter from 'Common/Data/Types/matter';
import isNumeric from 'Common/Utils/isNumeric';

export const yourSuperannuationLabel = 'Your Superannuation';

// eslint-disable-next-line import/prefer-default-export
export const yourSuperannuationQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about your superannuation.
      </H1Conversational>
    ),
    content: () => (
      <>
        <Paragraph>
          These questions are important because they will help us to understand
          the value of your superannuation.
        </Paragraph>
        <Paragraph>
          Before you start, contact your superannuation fund to ask for a
          letter/statement detailing your current balance. You will need to
          upload this at a later stage in amica.
        </Paragraph>
      </>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'carousel',
    type: 'carousel',
    index: 1,
    slides: [
      {
        title: 'Splitting your Superannuation',
        content: (
          <>
            <Paragraph>
              First, there are some things we need to tell you about
              superannuation using amica. Superannuation is considered an asset
              for division of money and property after separation. It is treated
              differently because it is an asset that is held on trust.
            </Paragraph>

            <Paragraph>
              To get more info about superannuation see our{' '}
              <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
                Money and Property
              </ReferralLink>{' '}
              page, or contact your local free{' '}
              <ReferralLink href="https://www.amica.gov.au/help-and-support">
                Legal Help Line
              </ReferralLink>
            </Paragraph>
            <Paragraph>
              When there is a difference in your superannuation balances, you
              have two options to work out a fair division of your money and
              property in amica. You can both agree to keep your own
              superannuation or one of you can divide your superannuation
              interests or entitlements.
            </Paragraph>
          </>
        ),
        showHelpButton: true,
        helpButtonText: 'Learn more',
        helpContent: (
          <>
            <H1Conversational>Learn more</H1Conversational>
            <Paragraph>
              Splitting your superannuation involves giving some of your
              superannuation to your former partner.
            </Paragraph>
            <Paragraph>
              In amica you can agree to transfer a portion of your
              superannuation to your former partner as part of the asset
              division. You can offset some of the cash settlement sum as
              superannuation. This is not a cash payment but a transfer of
              superannuation funds from one fund to the other, so it has
              different rules and a different legal process. In some cases the
              superannuation fund will open a new account on your behalf.
            </Paragraph>
            <Paragraph>
              <strong>Note:</strong> In amica you can only split one
              accumulation type fund which must have a withdrawal benefit of
              more than $5000.
            </Paragraph>
            <Paragraph>
              You may want to get some legal and/or financial advice or contact
              your local free{' '}
              <ReferralLink href="https://www.amica.gov.au/help-and-support">
                Legal Help Line
              </ReferralLink>
              .
            </Paragraph>
          </>
        ),
      },
      {
        title: 'Types of superannuation',
        content: (
          <>
            <Paragraph>
              There are many types of superannuation funds. If you want to
              create a property agreement or an application for consent orders
              using amica, you will be able to do this if your superannuation
              account is one of these types:
            </Paragraph>
            <Paragraph as="ul">
              <li>accumulation interest</li>
              <li>partially vested accumulation interest</li>
              <li>defined benefit interest</li>
            </Paragraph>
            <Paragraph>
              If you are planning to split your superannuation you can only
              choose one accumulation type fund at the superannuation splitting
              stage on amica. If you are unsure what type of fund you have, you
              should check with your superannuation fund.
            </Paragraph>
          </>
        ),
        showHelpButton: true,
        helpButtonText: 'Learn more',
        helpContent: (
          <>
            <H1Conversational>Learn more</H1Conversational>
            <Paragraph>
              You can only split one accumulation type superannuation fund with
              amica.{' '}
            </Paragraph>
            <Paragraph>
              To get more info about superannuation types see our Money and
              Property page, or contact your local free{' '}
              <ReferralLink href="https://www.amica.gov.au/help-and-support">
                Legal Help Line
              </ReferralLink>
            </Paragraph>
          </>
        ),
      },
      {
        title: 'Multiple superannuation funds',
        content: (
          <Paragraph>
            If you have more than one superannuation account, you will need to
            enter details of each account separately.
          </Paragraph>
        ),
        showHelpButton: true,
        helpButtonText: 'Learn more',
        helpContent: (
          <>
            <H1Conversational>Learn more</H1Conversational>
            <Paragraph>
              You need to include all of your superannuation balances as part of
              your full and frank disclosure requirements.
            </Paragraph>
            <Paragraph>
              If you are considering splitting superannuation you can only split
              one fund with amica.
            </Paragraph>
          </>
        ),
      },
    ],
  },
  {
    name: 'superannuationName',
    label: "What's the name of your fund?",
    hint: '',
    content: (
      <Paragraph>
        Please enter the name of the fund exactly as it appears on your
        superannuation statement.
      </Paragraph>
    ),
    type: 'text',
    defaultValue: '',
    index: 2,
  },
  {
    name: 'superannuationType',
    label: 'What type of superannuation fund do you have?',
    hint: '',
    type: 'select',
    options: () => [
      { label: 'Accumulation interest', value: 'Accumulation interest' },
      {
        label: 'Partially vested accumulation interest',
        value: 'Partially vested accumulation interest',
      },
      { label: 'Defined benefit interest', value: 'Defined benefit interest' },
      { label: 'Other', value: 'Other' },
    ],
    defaultValue: '',
    index: 3,
    checkShowDrawerOnInput: (value: any) => {
      if (value === 'Other') {
        return {
          showDrawer: true,
          drawerContent: (
            <>
              <H1Conversational>
                Selecting ‘other’ as your super type means you won’t be able to
                turn this into a consent order
              </H1Conversational>
              <Paragraph>
                You have chosen ‘other’. You will be able to make a property
                agreement on amica, but you won’t be able to make consent order
                documents on amica.
              </Paragraph>

              <Paragraph>
                <strong>Why?</strong>
              </Paragraph>
              <Paragraph>
                Because you can only make consent order documents on amica if
                your superannuation is one of these types:
              </Paragraph>

              <ul>
                <li>Accumulation interest</li>
                <li>Partially vested accumulation interest</li>
                <li>Defined benefit interest</li>
              </ul>
              <Paragraph>
                For more information on superannuation types see our{' '}
                <ReferralLink href="https://amica.gov.au/how-it-works/money-and-property">
                  Money and Property
                </ReferralLink>
                . page, or contact your local free{' '}
                <ReferralLink href="https://amica.gov.au/help-and-support">
                  Legal Help Line
                </ReferralLink>
                .
              </Paragraph>
            </>
          ),
          closeButton: 'Got it',
        };
      }

      return {
        showDrawer: false,
      };
    },
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>

          <Paragraph large>
            There are different types of superannuation accounts. For more
            information see our{' '}
            <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
              Money and Property
            </ReferralLink>{' '}
            page.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'superannuationValue',
    label: 'What is the current value of your superannuation?',
    hint: '',
    content: (
      <Paragraph>
        You can contact your superannuation fund to get a copy of your latest
        statement/letter with your current balance to upload.
      </Paragraph>
    ),
    type: 'currency',
    defaultValue: '',
    validate: value =>
      isNumeric(value) && Number(value) > 0
        ? [true]
        : [false, 'Please enter a value of at least $1'],
    index: 4,
  },
  {
    name: 'superannuationProof',
    label: 'Please upload supporting documents',
    content: (
      <Paragraph>
        Upload your latest superannuation account statement/letter from your
        superannuation fund.
      </Paragraph>
    ),
    hint: '',
    type: 'docupload',
    defaultValue: '',
    preSaveResponses: (value: any) =>
      value
        .filter((v: any) => v)
        .map((v: Record<string, unknown>) => v.imagePath),
    index: 5,
    help: {
      label: 'What kind of documents should I upload?',
      content: (
        <>
          <H1Conversational>
            What kind of documents should I upload?
          </H1Conversational>

          <Paragraph large>
            Upload your latest superannuation account statement/letter from your
            superannuation fund.
          </Paragraph>
          <Paragraph large>
            For more information see our{' '}
            <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
              Money and Property
            </ReferralLink>{' '}
            page.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 6,
  },
];

export const yourSuperannuationStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const party: Party = data.creator || matter.self.party;
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'superannuationName',
    'superTypeDescription',
    'superValue',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    yourSuperannuationQuestions,
    data,
    hideSensitive
  );

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  // Data
  const {
    superannuationType: superType,
    superannuationValue,
    superannuationProof,
    form6Response,
  } = data;

  const superTypeDescription =
    superType === 'Other'
      ? 'other superannuation type'
      : `${superType} superannuation account`;

  const aOrAn = checkStartsWithVowel(superType) ? 'an' : 'a';

  // Currency format
  const superValue = formatCurrency(parseFloat(superannuationValue as string));

  // Sensitive Data that should not appear on LSC email data collection
  const superName = updated('superannuationName', redact('superannuationName'));

  if (plainText) {
    return `${firstName} has ${aOrAn} ${superTypeDescription} with ${superName} valued at ${superValue}.`;
  }

  const highlightedText = highlight`${firstName} has ${aOrAn} ${updated(
    'superTypeDescription',
    redact('superTypeDescription', superTypeDescription)
  )} with ${superName} valued at ${updated(
    'superValue',
    redact('superValue', superValue)
  )}.`;

  let filesSuperannuationProof = null;

  // Create a document list for superannuation proof if they have been uploaded
  if (
    superannuationProof &&
    superannuationProof.length &&
    !isFileObject(superannuationProof)
  ) {
    filesSuperannuationProof = <DocumentList documents={superannuationProof} />;
  }

  return (
    <>
      {highlightedText}
      {filesSuperannuationProof}
    </>
  );
};

export const noSuperannuationQuestions: QuestionAny[] = [
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 0,
  },
];

export const noSuperannuationStatement = ({
  matter,
  data,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party: Party = data.creator || matter.self.party;
  const highlight = getHighlightStatementValuesFunction(plainText);

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  const statement = `${firstName} does not have any superannuation accounts.`;

  if (plainText) {
    return statement;
  }

  const highlightedText = highlight`${statement}`;

  return <>{highlightedText}</>;
};
