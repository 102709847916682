import React from 'react';
import { Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

const DisputeResolution = (
  <>
    <Heading3>Dispute Resolution</Heading3>
    <Paragraph>
      Family dispute resolution can help you to try and resolve issues where you
      can&apos;t agree on something. It gives you a way of moving forward if
      that happens.
    </Paragraph>

    <Paragraph>
      For further information see our{' '}
      <ReferralLink href="https://www.amica.gov.au/family-dispute-resolution.html">
        Family Dispute Resolution
      </ReferralLink>{' '}
      page.
    </Paragraph>
  </>
);

export default DisputeResolution;
