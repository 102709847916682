import React from 'react';
import Paragraph from 'Common/UI/Text/Paragraph';
import styled from 'styled-components';
import MatterProps from 'Common/Utils/MatterProps';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import CardWarning from './CardWarning';


const FCFCOACourtMergerWarning: React.FC = () => {
  const jurisdiction = MatterProps('consentOrders.jurisdiction', 'WA');

  if (jurisdiction === 'FCA') {
    return (
      <CardWarning>
        <Paragraph>
          From 11 November 2021, amica will be using the new Federal Circuit and
          Family Court of Australia Consent Order forms.
        </Paragraph>
        <Paragraph>
          Up until 1 December 2021 the Court will accept both the old forms and
          the new forms. After 1 December 2021, the Court will only accept the
          new forms.
        </Paragraph>
        <Paragraph>
          If you created your Consent Order documents on amica before 11
          November and you are intending to file them with the Court after 1
          December, contact{' '}
          <ReferralLink href="https://amica.gov.au/help-and-support">
            ask amica
          </ReferralLink>{' '}
          so we can provide you with the updated format, at no extra cost.
        </Paragraph>
      </CardWarning>
    );
  }

  return null;
};

export { FCFCOACourtMergerWarning };

const Wrapper = styled.div`
  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    width: calc(100% - 16px) !important;
    max-width: calc(100% - 16px) !important;
    flex: 1 0 100% !important;
  }
`;

// when used as a card, we need to add the Wrapper so that it takes up both columns
const FCFCOACourtMergerWarningCard = () => {
  const WarningComponent = () => <FCFCOACourtMergerWarning />;

  if (WarningComponent) {
    return (
      <Wrapper>
        <WarningComponent />
      </Wrapper>
    );
  }

  return null;
};

export { FCFCOACourtMergerWarningCard };
