import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPage } from 'Common/Data/App/appSections';
import {
  SECTION_PARENTING,
  PAGE_PARENTING_COMPLETE,
  CARD_COMPONENT,
} from 'Common/constants';
import { ODRS_PARENTING_COMPLETE_ROUTE } from 'Common/routes';

import { generateComponentCard } from 'App/UI/Dashboard/Parenting/StateCards';
import DashboardContent from 'App/UI/Dashboard/common/DashboardContent';
import { DashboardPageProps } from 'Common/Data/Types/types';
import {
  CardComponent,
  EphemeralStateCard,
} from 'Common/Data/Types/appSections';

const Complete: React.FC<DashboardPageProps> = ({
  matter,
  navigate,
  appState,
}) => {
  const location = useLocation();

  const customNavigate = (route: string) =>
    navigate(route, { backRoute: ODRS_PARENTING_COMPLETE_ROUTE, location });

  const complete = getPage(SECTION_PARENTING, PAGE_PARENTING_COMPLETE);

  return (
    <DashboardContent
      pageData={complete}
      navigate={customNavigate}
      matter={matter}
      cardHandlers={{
        [CARD_COMPONENT]: data =>
          generateComponentCard(data as EphemeralStateCard<CardComponent>),
      }}
      appState={appState}
    />
  );
};

export default Complete;
