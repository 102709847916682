/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

import React from 'react';
import styled from 'styled-components';
import formatCurrency from 'Common/Utils/formatCurrency';
import MatterProps from 'Common/Utils/MatterProps';
import AssetProps from 'Common/Data/App/assetProperties';

const TableRow = styled.tr``;

const TableHeading = styled.th`
  font-weight: bold;
  font-size: 15px;
  padding: ${props => props.theme.padding.small}px;
`;

const TableCell = styled.td`
  width: 33.33%;
  padding: ${props => props.theme.padding.small}px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colours.darkGrey};

  ${props =>
    props.owner === 'self' &&
    `
    background-color: ${props.theme.colours.offWhite};
    color: ${props.theme.colours.black};
  `}

  ${props =>
    props.highlight &&
    `
    background-color: ${props.theme.colours.pastelPurple};
  `}
`;

const TableHead = styled.thead`
  border-top: 1px solid ${props => props.theme.colours.lightGrey};
`;

const TableBody = styled.tbody`
  ${TableHeading}, ${TableCell} {
    border: 1px solid ${props => props.theme.colours.lightGrey};
  }
`;

const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  overflow: hidden; // needed to fix horizontal scrollbars due to table being 1px too wide from collapsed borders
`;

const TableHeadingMinor = styled(TableHeading)`
  font-size: 13px;
  font-weight: normal;
`;

const AssetsTable = () => {
  const otherName = MatterProps('other.firstname');
  const party = {
    self: MatterProps('self.party'),
    other: MatterProps('other.party'),
  };

  const {
    getAssetData,
    assetCategories,
    debtCategories,
    owingParty,
    owedParty,
    isOwingParty,
  } = AssetProps();

  const data = getAssetData({
    parties: { owing: owingParty, owed: owedParty },
    isOwingParty,
  });
  const { assets, debts } = data;

  const generateAssetRows = () =>
    assetCategories.map(category => {
      const categoryAssets = assets
        .filter(asset => asset.type === category.type)
        .filter(asset => asset.A > 0 || asset.B > 0);

      if (categoryAssets.length > 0) {
        return (
          <React.Fragment key={category.type}>
            <TableRow key={category.type}>
              <TableHeading>{category.title}</TableHeading>
              <TableCell />
              <TableCell />
            </TableRow>

            {categoryAssets.map(asset => (
              <TableRow key={asset.id}>
                <TableHeadingMinor>{asset.name}</TableHeadingMinor>
                <TableCell owner="self">
                  {formatCurrency(asset[party.self])}
                </TableCell>
                <TableCell>{formatCurrency(asset[party.other])}</TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        );
      }
    });

  const generateDebtRows = () =>
    debtCategories.map(category => {
      const categoryDebts = debts
        .filter(debt => debt.type === category.type)
        .filter(debt => debt.A > 0 || debt.B > 0);

      if (categoryDebts.length > 0) {
        return (
          <React.Fragment key={category.type}>
            <TableRow key={category.type}>
              <TableHeading>{category.title}</TableHeading>
              <TableCell />
              <TableCell />
            </TableRow>

            {categoryDebts.map(debt => (
              <TableRow key={debt.id}>
                <TableHeadingMinor>{debt.name}</TableHeadingMinor>
                <TableCell owner="self">
                  {formatCurrency(debt[party.self])}
                </TableCell>
                <TableCell>{formatCurrency(debt[party.other])}</TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        );
      }
    });

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeading>Assets</TableHeading>
            <TableHeading>You</TableHeading>
            <TableHeading>{otherName}</TableHeading>
          </TableRow>
        </TableHead>
        <TableBody>{generateAssetRows()}</TableBody>
      </Table>

      <br />

      <Table>
        <TableHead>
          <TableRow>
            <TableHeading colSpan="3">Liabilities</TableHeading>
          </TableRow>
        </TableHead>

        <TableBody>{generateDebtRows()}</TableBody>
      </Table>

      <br />

      <Table>
        <TableBody>
          <TableRow>
            <TableHeading colSpan="3">Total</TableHeading>
            <TableCell owner="self" highlight>
              {formatCurrency(data.total[party.self])}
            </TableCell>
            <TableCell>{formatCurrency(data.total[party.other])}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default AssetsTable;
