import ConfirmRequestSuperannuationBalance from 'App/UI/Dashboard/common/Cards/YourSuperannuationCard/ConfirmRequestSuperannuationBalance';
import React from 'react';
import MatterGuard from '../Guard/MatterGuard';

const ODRSYourSuperannuationConfirmRequestBalance: React.FC = props => (
  <MatterGuard>
    <ConfirmRequestSuperannuationBalance {...props} />
  </MatterGuard>
);

export default ODRSYourSuperannuationConfirmRequestBalance;
