import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Label } from 'Common/UI/Text/Label';

const ItemValue = styled(Paragraph)`
  font-size: 15px;
  line-height: 1.3;
  margin: 8px 0 0;
  flex: 1;
`;

const StyledItem = styled.div`
  padding: ${props => props.theme.padding.medium}px 0;
  position: relative;

  border-bottom: 1px solid ${props => props.theme.colours.clickableGrey};

  &:first-of-type {
    border-top: 1px solid ${props => props.theme.colours.clickableGrey};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

const Actions = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    width: auto;
    margin-top: 0;
  }
`;

export const Item = ({ label, value, children }) => (
  <StyledItem>
    <Label bold>{label}</Label>
    <Wrapper>
      <ItemValue>{value}</ItemValue>
      <Actions>{children}</Actions>
    </Wrapper>
  </StyledItem>
);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Item.defaultProps = {
  children: null,
};
