import React from 'react';

import { ODRS_CONSENT_ORDERS_COMPLETE_ROUTE } from 'Common/routes';
import { Success } from 'Common/UI/Layout/Completed';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import getNames from 'Common/Utils/getNames';

const NoChangesToClausesConfirmation: React.FC = () => {
  const otherName = getNames().other;

  return (
    <Success
      buttonRoute={ODRS_CONSENT_ORDERS_COMPLETE_ROUTE}
      buttonLabel="Done"
    >
      <H1Conversational>
        Great, you’ve confirmed your draft consent minutes of order
      </H1Conversational>

      <Paragraph>
        Now that your superannuation fund has approved your draft consent
        minutes of order, you can print and sign all of your documents with{' '}
        {otherName}.
      </Paragraph>
      <Paragraph>This includes the following documents:</Paragraph>

      <Paragraph as="ul">
        <li>Procedural Fairness Letter</li>
        <li>Proposed Minutes of Consent (Draft)</li>
        <li>Application for Consent Orders (Draft)</li>
      </Paragraph>
    </Success>
  );
};

export default NoChangesToClausesConfirmation;
