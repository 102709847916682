import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import moment from 'moment';

import { MATTER_REFRESH_PERIOD } from 'Common/constants';
import { ODRS_LOGIN_ROUTE } from 'Common/routes';
import { signOutAction } from 'App/State/UserActions';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import Loader from 'Common/UI/Loader/Loader';
import { loadMatterAction } from 'App/State/MatterActions';

const STATE_UNCHECKED = 'STATE_UNCHECKED';
const STATE_CHECK_SUCCESS = 'STATE_CHECK_SUCCESS';
const STATE_CHECK_FAILURE = 'STATE_CHECK_FAILURE';

type BaseProps = {
  children: React.ReactNode;
};

type PropsMatterExpected = {
  destination?: string;
  expectNone?: false;
  signOutAndContinueOnFailure?: false;
};

type PropsMatterNotExpected = {
  destination?: string;
  expectNone: true;
  signOutAndContinueOnFailure?: boolean;
};

const MatterGuard: React.FC<BaseProps &
  (PropsMatterExpected | PropsMatterNotExpected)> = ({
  destination = ODRS_LOGIN_ROUTE,
  expectNone = false,
  signOutAndContinueOnFailure = false,
  children,
}) => {
  const [matterStatus, setMatterStatus] = useState(STATE_UNCHECKED);
  const dispatch = useAppDispatch();
  const loadMatter = () => dispatch(loadMatterAction());
  const navigate = (to: string) => dispatch(push(to));
  const signOut = () => dispatch(signOutAction());

  const matter = useAppSelector(state => state.matter);

  const { MatterID, lastRefreshed } = matter;

  useEffect(() => {
    if (
      MatterID &&
      moment().diff(lastRefreshed, 'seconds') < MATTER_REFRESH_PERIOD
    ) {
      setMatterStatus(STATE_CHECK_SUCCESS);
    } else if (matterStatus === STATE_UNCHECKED) {
      loadMatter()
        .unwrap()
        .then(async () => {
          if (expectNone) {
            // if this is true, then let them continue to the route but sign the user out first
            if (signOutAndContinueOnFailure) {
              await signOut();

              setMatterStatus(STATE_CHECK_SUCCESS);
            } else {
              // we didn't want a matter so this is a failure state.
              setMatterStatus(STATE_CHECK_FAILURE);
              navigate(destination);
            }
          } else {
            setMatterStatus(STATE_CHECK_SUCCESS);
          }
        })
        .catch(() => {
          if (expectNone) {
            // we didn't want a matter so this is a success state.
            setMatterStatus(STATE_CHECK_SUCCESS);
          } else {
            setMatterStatus(STATE_CHECK_FAILURE);
            navigate(destination);
          }
        });
    }
  }, []);

  if (matterStatus === STATE_CHECK_SUCCESS) {
    return <>{children}</>;
  }

  return <Loader />;
};

export default MatterGuard;
