import React from 'react';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

export const contributionsLearnMoreContent = () => (
  <>
    <H1Conversational>Contributions</H1Conversational>
    <Paragraph>
      {`Contributions can include financial contributions made directly or 
      indirectly (such as your income) and non-financial contributions made 
      directly or indirectly such as caring for children and maintenance 
      performed on the family home.`}
    </Paragraph>
  </>
);
