import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import { Callout } from 'Common/UI/Text/Paragraph';
import { SlideInner, SlideText } from 'Common/UI/Carousel/ImageSlideWrapper';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

const ParentingPlanSlide2 = () => (
  <SlideInner>
    <SlideText paddingTop data-cy="parent-slide-2">
      <H2Conversational>Your child’s best interests</H2Conversational>
      <Callout>
        You should consider the benefit of each child having a meaningful
        relationship with both parents. If a relationship with either parent
        would involve any risk of physical or psychological harm to the child,
        (by being exposed to abuse, neglect, or violence) then amica may not be
        right for your situation. See the{' '}
        <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
          Help and Support page
        </ReferralLink>{' '}
        for further information.
      </Callout>
    </SlideText>
  </SlideInner>
);

export default ParentingPlanSlide2;
