import React, { useContext } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { useLocation, Link } from 'react-router-dom';

import {
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_CONSENT_ORDERS_ASSET_ROUTE,
} from 'Common/routes';
import { NavHeading3 } from 'Common/UI/Layout/SwipeNav';
import { Theme, blackTheme } from 'Common/Utils/theme';
import { Heading2 } from 'Common/UI/Text/Headings';
import IconClose from 'Common/Assets/images/icon-close-darkgrey.svg';
import Navigation from '../../Navigation';
import { DivisionAssetsContext } from './DivisionAssetsContext';

export const StyledHeader = styled.header<{
  theme: Theme;
  small?: boolean;
}>`
  position: relative;
  padding: ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  transition: all 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  background: ${props => props.theme.backgroundColour};
  color: ${props => props.theme.textColour};

  ${NavHeading3} {
    transition: font-size 0.5s;
    margin-bottom: 0;
  }

  ${props =>
    props.small &&
    css`
      padding: ${props.theme.padding.medium}px ${props.theme.padding.medium}px
        ${props.theme.padding.small}px;
    `}
`;

const CloseButton = styled.div<{
  theme: Theme;
}>`
  position: absolute;
  top: 24px;
  ${({ theme }) => theme.tokens.closeButtonPosition}
  z-index: 10;
`;

const Header: React.FC<{ className?: string }> = ({ className }) => {
  const location = useLocation<{ previous?: string }>();

  const { pages } = useContext(DivisionAssetsContext);

  let closeLink = ODRS_DASH_PROPERTY_ROUTE;
  if (
    location.state &&
    location.state.previous === ODRS_CONSENT_ORDERS_ASSET_ROUTE
  ) {
    closeLink = ODRS_CONSENT_ORDERS_ASSET_ROUTE;
  }

  return (
    <ThemeProvider theme={blackTheme}>
      <StyledHeader className={className}>
        <Heading2>Dividing property</Heading2>

        <CloseButton>
          <Link to={closeLink}>
            <img src={IconClose} alt="Close icon" />
          </Link>
        </CloseButton>

        <Navigation
          tabs={pages.map(({ title, route, id: testId = '' }, index) => ({
            label: `${index + 1}. ${title}`,
            to: route,
            testId,
          }))}
          dataCyId="asset-split"
        />
      </StyledHeader>
    </ThemeProvider>
  );
};

export default Header;
