import React, { useState } from 'react';
import styled from 'styled-components';

import AssetProps from 'Common/Data/App/assetProperties';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import capitaliseText from 'Common/Utils/capitaliseText';
import { Heading2, Heading4 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { transferAssetAction } from 'App/State/MatterActions';
import { useAppDispatch } from 'App/State/Store';
import { AssetEnhanced } from 'Common/Data/Types/assets';
import { AssetSplitAsset } from 'Common/Data/Types/matter';
import { AssetSplitOption } from 'Common/constants';
import getNames from 'Common/Utils/getNames';
import OptionalRequiredText from './OptionalRequiredText';
import TransferrableAsset from './TransferrableAssets/TransferrableAsset';
import TransferrableAssetProperty from './TransferrableAssets/TransferrableAssetProperty';
import TransferrableAssetSuperannuation from './TransferrableAssets/TransferrableAssetSuperannuation';
import push from '../../../Utils/push';
import { ODRS_DIVISION_ASSETS_ALREADY_STARTED } from '../../../routes';

const AssetType = styled(Heading4)`
  margin-bottom: ${props => props.theme.padding.xxsmall}px;
  color: ${props => props.theme.colours.midDarkGrey};
`;

const StyledOptionalRequiredText = styled(OptionalRequiredText)`
  line-height: 140%;
`;

const CategoryHeading = styled(Paragraph)`
  color: ${props => props.theme.colours.darkGrey};
`;

const TransferAssetsList: React.FC<{
  assets?: AssetEnhanced[];
  debts?: AssetEnhanced[];
  isRequired: boolean;
}> = ({ assets = [], debts: debtsRaw, isRequired = false }) => {
  const dispatch = useAppDispatch();
  const navigate = (to: string) => dispatch(push(to));

  const transferAsset = (
    asset: (AssetSplitAsset & { remove: boolean; editing: boolean })[]
  ) => dispatch(transferAssetAction(asset)).unwrap();

  const { assetCategories, owingParty, isOwingParty } = AssetProps();

  const [cardsLoading, setCardsLoading] = useState<
    Record<string, AssetSplitOption>
  >({});

  const names = getNames();

  const shouldShowDebts = !!debtsRaw;
  const debts = debtsRaw || [];

  const handleTransferItem = async ({
    id,
    option,
    remove = false,
    options = {
      propertyPercentage: 0,
      propertyValue: 0,
      superAmountToTransfer: 0,
    },
    editing = false,
  }: {
    id: string;
    option: AssetSplitOption;
    remove: boolean;
    options?: {
      propertyPercentage?: number;
      propertyValue?: number;
      superAmountToTransfer?: number;
    };
    editing?: boolean;
  }) => {
    setCardsLoading({ ...cardsLoading, [id]: option });

    const item = [...assets, ...debts].find(i => i.id === id);

    if (!item) {
      return Promise.resolve();
    }

    const afterTransferAsset = async () => {
      const newCardsLoading = { ...cardsLoading };
      delete newCardsLoading[id];
      setCardsLoading(newCardsLoading);
    };

    try {
      let assetTransferOptions: AssetSplitAsset & {
        remove: boolean;
        editing: boolean;
      } = {
        id,
        assetId: item.assetId,
        option,
        value: Number(item.value),
        isLiability: item.isLiability,
        remove,
        editing,
      };

      if (item.type === 'property') {
        const { propertyPercentage = 0, propertyValue = 0 } = options;
        // special case for handling mortgages
        assetTransferOptions = {
          ...assetTransferOptions,
          percentage: Math.abs(propertyPercentage),
          value: propertyValue,
        };
      }

      if (item.type === 'superannuation') {
        const { superAmountToTransfer = 0 } = options;
        assetTransferOptions = {
          ...assetTransferOptions,
          value: superAmountToTransfer,
        };
      }
      await transferAsset([assetTransferOptions]);
      afterTransferAsset();
    } catch (e) {
      // Offer already started
      navigate(ODRS_DIVISION_ASSETS_ALREADY_STARTED);
    }
  };

  const getAsset = (asset: AssetEnhanced) => {
    if (asset.type === 'property') {
      return (
        <TransferrableAssetProperty
          key={asset.id}
          asset={asset}
          onTransferAsset={handleTransferItem}
          selectedOption={asset.selectedOption}
          isLoading={!!cardsLoading[asset.id]}
        />
      );
    }

    if (asset.type === 'superannuation') {
      return (
        <TransferrableAssetSuperannuation
          key={asset.id}
          asset={asset}
          onTransferAsset={handleTransferItem}
          selectedOption={asset.selectedOption}
          isLoading={!!cardsLoading[asset.id]}
        />
      );
    }

    return (
      <TransferrableAsset
        key={asset.id}
        asset={asset}
        onTransferAsset={handleTransferItem}
        selectedOption={asset.selectedOption}
        isLoading={!!cardsLoading[asset.id]}
      />
    );
  };

  return (
    <ResponsiveContainer>
      {assetCategories.map(({ type, title, heading }) => {
        const categoryAssets = assets.filter(asset => asset.type === type);

        if (categoryAssets.length > 0) {
          return (
            <React.Fragment key={type}>
              <AssetType>
                {capitaliseText(title)}
                <StyledOptionalRequiredText>
                  {isRequired ? 'Required' : 'Optional'}
                </StyledOptionalRequiredText>
              </AssetType>
              {heading && (
                <CategoryHeading>
                  {heading({
                    owingName: names[owingParty],
                    isOwingParty,
                  })}
                </CategoryHeading>
              )}
              <FlexGrid medium={2}>
                {categoryAssets.map(asset => getAsset(asset))}
              </FlexGrid>
            </React.Fragment>
          );
        }

        return null;
      })}

      {assets
        .filter(
          asset =>
            !assetCategories.map(category => category.type).includes(asset.type)
        )
        .map(asset => getAsset(asset))}

      {debts && debts.length > 0 && (
        <>
          <Heading2>Liabilities</Heading2>

          {debts.map(debt => (
            <TransferrableAsset
              key={debt.id}
              asset={debt}
              onTransferAsset={handleTransferItem}
              selectedOption={debt.selectedOption}
              isLoading={!!cardsLoading[debt.id]}
            />
          ))}
        </>
      )}

      {shouldShowDebts && debts && debts.length === 0 && (
        <Paragraph>You do not have any jointly owned debts.</Paragraph>
      )}
    </ResponsiveContainer>
  );
};

export default TransferAssetsList;
