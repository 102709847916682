import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  ODRS_PASSWORDRESET_ROUTE,
  ODRS_PROFILE_ROUTE,
  ODRS_PROFILE_UPDATE_ROUTE,
} from 'Common/routes';

import { orangeTheme } from 'Common/Utils/theme';
import { Heading2 } from 'Common/UI/Text/Headings';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  PROFILE_STATE_VERIFY_EMAIL,
  PROFILE_STATE_VERIFY_PHONE,
} from './constants';
import IconTick from 'Common/Assets/images/icon-checkbox-tick-black.svg';
import { Item } from './common';

const EditButton = styled(GenericButton).attrs(() => ({
  as: Link,
  subtle: 'true', // need to pass a string here to avoid a proptypes warning from react-router
  small: true,
}))`
  flex: 0;
  position: relative;
  right: auto;
  bottom: auto;
  flex-basis: auto;
  align-self: flex-end;
  text-decoration: none;
`;

const VerifyButton = styled(GenericButton).attrs(props => ({
  ...props,
  small: true,
  theme: orangeTheme,
}))`
  color: ${props => props.theme.buttonTextPrimary};
  background-color: ${props => props.theme.buttonBackgroundPrimary};
`;

const VerifiedBadge = styled(GenericButton).attrs(() => ({
  as: 'span',
  small: true,
}))`
  font-weight: 400;
  color: ${props => props.theme.buttonTextReverse};
  background-color: white;
  background-image: url(${IconTick});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 10px 8px;

  &:hover {
    border-color: transparent;
    background-color: white;
  }
`;

const Wrapper = styled.div`
  margin: ${({ theme }) => theme.padding.xlarge}px 0;
`;

const YourDetails = ({
  handleVerifyClick,
  sessionDetails: {
    given_name: firstname,
    middle_name: middlename,
    family_name: lastname,
    email,
    phone_number, // eslint-disable-line camelcase
    email_verified, // eslint-disable-line camelcase
    phone_number_verified, // eslint-disable-line camelcase
    'custom:notifications': notifications, // eslint-disable-line camelcase
  },
  matter: {
    self: {
      receiveIncomeSupport = false,
      incomeSupportPaymentType,
      incomeSupportPaymentCRN,
    } = {},
  },
}) => {
  let notificationsText = 'Not enabled';

  if (notifications === '1') {
    // eslint-disable-next-line camelcase
    if (email_verified === 'true') {
      notificationsText = 'Enabled';
    } else {
      notificationsText = 'Verify email to enable notifications';
    }
  }

  const EditProfileButton = (
    <EditButton
      to={{
        pathname: ODRS_PROFILE_UPDATE_ROUTE,
        state: {
          legalname: {
            firstname,
            middlename,
            lastname,
          },
          email,
          phone_number,
          notifications: notifications === '1',
          receiveIncomeSupport,
          incomeSupportPaymentType,
          incomeSupportPaymentCRN,
        },
      }}
    >
      Edit
    </EditButton>
  );

  return (
    <Wrapper>
      <Heading2>Your details</Heading2>
      <Item label="Name" value={`${firstname} ${middlename || ''} ${lastname}`}>
        {EditProfileButton}
      </Item>
      <Item label="Email" value={email}>
        {
          email_verified === 'true' && <VerifiedBadge>Verified</VerifiedBadge> // eslint-disable-line camelcase
        }
        {email_verified === 'false' && ( // eslint-disable-line camelcase
          <VerifyButton
            onClick={() => handleVerifyClick(PROFILE_STATE_VERIFY_EMAIL)}
          >
            Verify
          </VerifyButton>
        )}
        &nbsp;&nbsp;&nbsp;
        {EditProfileButton}
      </Item>
      <Item label="Notifications" value={notificationsText} />
      <Item label="Phone" value={phone_number}>
        {
          phone_number_verified === 'true' && (
            <VerifiedBadge>Verified</VerifiedBadge>
          ) // eslint-disable-line camelcase
        }
        {phone_number_verified === 'false' && ( // eslint-disable-line camelcase
          <VerifyButton
            onClick={() => handleVerifyClick(PROFILE_STATE_VERIFY_PHONE)}
          >
            Verify
          </VerifyButton>
        )}
        &nbsp;&nbsp;&nbsp;
        {EditProfileButton}
      </Item>
      <Item label="Password" value="********">
        <EditButton
          to={{
            pathname: ODRS_PASSWORDRESET_ROUTE,
            state: {
              redirect: ODRS_PROFILE_ROUTE,
            },
          }}
        >
          Change Password
        </EditButton>
      </Item>
    </Wrapper>
  );
};

YourDetails.propTypes = {
  handleVerifyClick: PropTypes.func.isRequired,
  sessionDetails: PropTypes.shape({}).isRequired,
  matter: PropTypes.shape({}).isRequired,
};

export default YourDetails;
