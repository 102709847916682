import React from 'react';
import { Callout } from 'Common/UI/Text/Paragraph';
import { SlideInner, SlideText } from 'Common/UI/Carousel/ImageSlideWrapper';

const AssetTransferSlide3 = () => (
  <SlideInner>
    <SlideText paddingTop data-cy="asset-transfer-slide-1">
      <Callout>
        Once you reach agreement about dividing your assets the details will be
        transferred into your property agreement or your consent order
        application (depending which option you choose).
      </Callout>
    </SlideText>
  </SlideInner>
);

export default AssetTransferSlide3;
