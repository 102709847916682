import React from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';

import diviProperties from 'Common/Data/App/diviProperties';
import {
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_DIVISION_VIEW_SUGGESTED,
} from 'Common/routes';
import { H2Conversational } from 'Common/UI/Text/Headings';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  SlideInner,
  SlideText,
  SlideIcon,
  AboveNavigation,
} from 'Common/UI/Carousel/ImageSlideWrapper';
import DivisionImage04 from './division-image-4.svg';

const StyledButton = styled(GenericButton)`
  display: block;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    display: none;
  }
`;

const DivisionSlide4 = ({ navigate }) => {
  const { rounds } = diviProperties();

  // get the next page that the user should go to
  // if the other party has already made an offer, we need to see the suggested division page
  const route =
    rounds.length > 0
      ? ODRS_DIVISION_VIEW_SUGGESTED
      : ODRS_DIVISION_SPLIT_VIEW_ROUTE;

  return (
    <SlideInner>
      <SlideIcon className="slide-icon" backgroundImage={DivisionImage04}>
        <img alt="" src={DivisionImage04} srcSet={`${DivisionImage04} 2x`} />
      </SlideIcon>
      <SlideText withButton data-cy="suggested-division-slide-1">
        <H2Conversational>
          Financial and non-financial contributions
        </H2Conversational>
        <Paragraph as="div">
          <Callout>Contributions can include:</Callout>
          <ul>
            <li>
              Financial contributions made directly or indirectly such as your
              income or any gift of money (eg by your parents)
            </li>
            <li>
              Non-financial contributions made directly or indirectly by you,
              which can include care that you provided for the children and
              maintenance performed on the family home
            </li>
          </ul>
        </Paragraph>
        <AboveNavigation>
          <StyledButton
            fullWidth
            onClick={() => navigate(route)}
            data-cy="carousel-finish-button"
          >
            View our suggestion
          </StyledButton>
        </AboveNavigation>
      </SlideText>
    </SlideInner>
  );
};

DivisionSlide4.propTypes = {
  navigate: PropType.func.isRequired,
};

export default DivisionSlide4;
