import React from 'react';

import {
  FullComponentGenerator,
  SummaryComponentGenerator,
} from 'Common/Data/Types/appSections';

import SuperannuationFundClauseEditor from './SuperannuationFundClauseEditor';
import SuperannuationFundClauseViewer from './SuperannuationFundClauseViewer';

export const summarySuperannuationFundClauseGenerator = ({
  values,
  isActive,
}: SummaryComponentGenerator) => (
  <SuperannuationFundClauseViewer values={values} isActive={isActive} />
);

export const fullSuperannuationFundClauseGenerator = ({
  values,
  isActive,
  onInputChange,
  onSubmit,
}: FullComponentGenerator) => (
  <SuperannuationFundClauseEditor
    values={values}
    isActive={isActive}
    onInputChange={onInputChange}
    onSubmit={onSubmit}
  />
);
