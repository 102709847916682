import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import {
  STATUS_APPROVED,
  STATUS_REMOVED,
  SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import { reduxStore, useAppDispatch, useAppSelector } from 'App/State/Store';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { definitelyGetCard } from 'Common/Data/App/appSections';
import { CardYourSuperannuation } from 'Common/Data/Types/appSections';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import colours from 'Common/Utils/colours';
import { ODRS_YOURSUPERANNUATION_UPLOAD_LETTER_ROUTE } from 'Common/routes';
import push from 'Common/Utils/push';
import TextButton from '../Button/TextButton';

export const superannuationRequestBalanceUploadLetterRequesterFlags: AppState =
  {
    id: 'superannuationRequestBalanceUploadLetterRequester',
    description:
      'This party needs to upload the form 6 response letter they have received',
    check: () => {
      const {
        matter: { items },
      } = reduxStore.getState();

      const selfParty = getPartyKeys().self;

      // get all open super cards that are not created by me that are waiting for a response letter to be uploaded
      const superCardsWithLetterReceived = items.filter(
        item =>
          ![STATUS_APPROVED, STATUS_REMOVED].includes(item.status) &&
          item.BaseSection === 'yoursuperannuation' &&
          item.__flags?.superBalanceRequested &&
          item.__flags?.letterReceived &&
          !item.__flags?.letterUploaded &&
          item.creator !== selfParty
      );

      // if there are no cards found, don't show this banner
      if (!superCardsWithLetterReceived) {
        return false;
      }

      const haveAllCardsBannersBeenDismissed =
        superCardsWithLetterReceived.every(({ SectionID }) =>
          sessionStorageLoad(
            `SuperannuationRequestBalanceUploadLetterRequester_${SectionID}_Dismissed`
          )
        );

      // if the banner for every card found has previously been dismissed, don't show this banner
      // note: we are putting this check in here rather than in the bannerTopState flag like normal because
      // the we want to skip this app state if it has been dismissed, not just trigger this app state but hide the banner
      return !haveAllCardsBannersBeenDismissed;
    },
    flags: {
      appState: SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER,
    },
    usedAsNotificationState: true,
  };

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperannuationRequestBalanceUploadLetterRequester: React.FC<Props> =
  ({ otherName, updateAppState }) => {
    const dispatch = useAppDispatch();

    const navigate = (route: string) => dispatch(push(route));

    const selfParty = getPartyKeys().self;

    const { items } = useAppSelector(state => state.matter);

    const allSuperCardWithLetterReceived = items.filter(
      item =>
        ![STATUS_APPROVED, STATUS_REMOVED].includes(item.status) &&
        item.BaseSection === 'yoursuperannuation' &&
        item.__flags?.superBalanceRequested &&
        item.__flags?.letterReceived &&
        !item.__flags?.letterUploaded &&
        item.creator !== selfParty
    );

    // find the first card that has not been dismissed
    const superCardWithLetterReceived = allSuperCardWithLetterReceived.find(
      ({ SectionID }) =>
        !sessionStorageLoad(
          `SuperannuationRequestBalanceUploadLetterRequester_${SectionID}_Dismissed`
        )
    );

    if (!superCardWithLetterReceived) {
      console.warn(
        'No super card found with letter received and ready to upload'
      );

      return null;
    }

    const card = definitelyGetCard<CardYourSuperannuation>(
      superCardWithLetterReceived.SectionID
    );

    const { data, index, cardID } = card;

    return (
      <div data-cy="banner-request-balance-upload-letter">
        <SpacedBox marginBottom={8}>
          <Heading2MarginTopNone>
            Upload the letter you received about {otherName}'s account with{' '}
            {data.superannuationName}
          </Heading2MarginTopNone>
        </SpacedBox>
        <SpacedBox marginBottom={4}>
          <GenericButton
            fullWidth
            onClick={() =>
              navigate(
                `${ODRS_YOURSUPERANNUATION_UPLOAD_LETTER_ROUTE}/${index}`
              )
            }
            small
            data-cy="upload-button"
          >
            Upload
          </GenericButton>
        </SpacedBox>
        <TextButton
          fullWidth
          onClick={() => {
            sessionStorageSave(
              `SuperannuationRequestBalanceUploadLetterRequester_${cardID}_Dismissed`,
              true
            );
            updateAppState();
          }}
          small
          color={colours.clickableGrey}
          data-cy="dismiss-button"
        >
          Dismiss
        </TextButton>
      </div>
    );
  };
