import React from 'react';
import { setPartyFlagsAction } from 'App/State/MatterActions';
import { reduxStore } from 'App/State/Store';
import Paragraph from 'Common/UI/Text/Paragraph';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { isDefacto as checkDefacto } from 'Common/Utils/consentOrderPageChecks';
import { QuestionAny } from 'Common/Data/Types/appSections';
import { HelpLineContent, HelpLineAmicaHelpContent } from './HelpLineContent';

// Note any disabled props will not be saved into the database
export const consentOrderRequiredQuestions = (): QuestionAny[] => {
  // Async calls
  const setAsyncFlag = (flag: any) =>
    new Promise((resolve, reject) => {
      reduxStore
        .dispatch(setPartyFlagsAction(flag))
        .then(resolve)
        .catch((error: any) => reject(new Error(error)));
    });
  const suitableAsync = () => setAsyncFlag({ isSuitableConsentOrders: true });
  const notSuitableAsync = () =>
    setAsyncFlag({ isSuitableConsentOrders: false });

  // Start Questions
  const startQuestions: QuestionAny[] = [
    {
      name: 'startIntro',
      label: `
        The questions in this section are about checking that your 
        situation is suitable to apply for consent orders using amica 
        and collecting information that the court requires in an application 
        for consent orders about money and property (financial orders).
      `,
      hint: '',
      type: 'info',
      defaultValue: '',
      submitButtonLabel: 'Continue',
      index: 0,
    },
    {
      name: 'startViolenceYesNo',
      label: `Are there any ongoing cases in any court about family law, 
      child support, family violence or child welfare that involve any of the 
      parties or any of the children listed on this form?`,
      hint: '',
      type: 'yesno',
      help: {
        label: 'Learn more',
        content: (
          <>
            <Paragraph large>
              {`The court requires all parties to inform the court if there are 
              any ongoing cases involving these issues.`}
            </Paragraph>
            <Paragraph large>
              {`This includes ongoing cases about natural or adopted children of 
              either or both of you, who are under 18, regardless of whether the 
              child has lived with either or both of you.`}
            </Paragraph>
          </>
        ),
      },
      index: 1,
    },
    {
      name: 'startViolenceYes',
      label: 'amica is not suitable for your situation',
      type: 'info',
      content: (
        <>
          <Paragraph large>
            {`amica is not suitable for situations where there are ongoing 
            cases about any of these issues.`}
          </Paragraph>
          <Paragraph large>
            {`This includes ongoing cases about natural or adopted children 
            of either or both of you, who are under 18, regardless of whether 
            the child has lived with either or both of you.`}
          </Paragraph>
          <Paragraph large>
            <HelpLineContent />
          </Paragraph>
        </>
      ),
      visible: (data: any) => !!data.startViolenceYesNo,
      onBeforeNext: notSuitableAsync,
      exitOnSubmit: true,
      index: 2,
    },
    {
      name: 'startExistingParentingYesNo',
      label: `
          Are there any existing parenting agreements, or parenting 
          plans concerning any of the parties or children listed on this form?
      `,
      type: 'yesno',
      help: {
        label: 'Learn more',
        content: (
          <Paragraph large>
            {`
                This question applies to agreements or parenting plans concerning 
                natural or adopted children of either or both of you, who are 
                under 18, regardless of whether the child has lived with either 
                or both of you.
            `}
          </Paragraph>
        ),
      },
      index: 3,
    },
    {
      name: 'selectParentingMultiSelect',
      label: `
        Please select which agreement you have completed (you will need to 
        attach this to your consent order application)
      `,
      hint: '',
      type: 'multiselect',
      options: () => [
        { label: 'Parenting Plan', value: 'Parenting Plan' },
        { label: 'Parenting Agreement', value: 'Parenting Agreement' },
      ],
      visible: (data: any) => !!data.startExistingParentingYesNo,
      help: {
        label: 'Learn more',
        content: (
          <Paragraph large>
            {`
              If you have a parenting plan,attach it to your application. If 
              you don’t have a parenting plan but you have a parenting agreement, 
              attach the agreement.(This includes a plan or agreement made on 
              amica.)
              `}
          </Paragraph>
        ),
      },
      index: 4,
    },
    {
      name: 'startExistingOrdersYesNo',
      label: `
        Are there any existing orders, agreements (other than parenting 
        agreements/plans), or undertakings to a court  about family law, child 
        support, family violence or abuse in relation to a child (including 
        orders which have applied to a child or a member of the child’s family), 
        or child welfare, concerning any of the parties or children listed on 
        this form?
      `,
      type: 'yesno',
      help: {
        label: 'Learn more',
        content: (
          <Paragraph large>
            {`
              The court requires party to disclose all of these matters. This 
              question applies to natural or adopted children of either of both 
              of you, who are under 18, regardless of whether the child has lived 
              with either or both of you.
              `}
          </Paragraph>
        ),
      },
      index: 5,
    },
    {
      name: 'startExistingOrdersYes',
      label: 'amica is not suitable for your situation',
      type: 'info',
      content: (
        <>
          <Paragraph large>
            {`amica is not suitable for situations where there are any
            existing orders, agreements (other than parenting agreements/
            plans), or undertakings to a court about family law, child
            support, family violence or abuse in relation to a child
            (including orders which have applied to a child or a member of 
            the child’s family), or child welfare, concerning any of the 
            parties or any children of the relationship.`}
          </Paragraph>
          <Paragraph large>
            <HelpLineContent />
          </Paragraph>
        </>
      ),
      visible: (data: any) => !!data.startExistingOrdersYesNo,
      onBeforeNext: notSuitableAsync,
      exitOnSubmit: true,
      index: 6,
    },
    {
      name: 'startFamilyViolenceYesNo',
      label: 'Is there a family violence order (e.g. domestic violence order, intervention order, family violence order) that applies or has applied to the child or a member of the child’s family?',
      type: 'yesno',
      index: 7,
    },
    {
      name: 'startFamilyViolenceYes',
      label: 'amica is not suitable for your situation',
      type: 'info',
      content: (
        <>
          <Paragraph large>
            {`amica is not suitable for situations where there is already a 
            family violence order in place that names either of the parties or 
            any children of the relationship.`}
          </Paragraph>
          <Paragraph large>
            <HelpLineAmicaHelpContent />
          </Paragraph>
        </>
      ),
      visible: (data: any) => !!data.startFamilyViolenceYesNo,
      onBeforeNext: notSuitableAsync,
      exitOnSubmit: true,
      index: 8,
    },
    {
      name: 'startChildSafteyYesNo',
      label: `Has there been any contact with the department responsible for 
              child safety with respect to the children named in this 
              application or any child of the household?`,
      hint: `
        * If you are filing in Western Australia: Has the department for 
        Child Protection or any other child welfare authority had any 
        involvement with you or the other party or the child/ren or a 
        member of the child/ren’s family?
      `,
      help: {
        label: 'Learn more',
        content: (
          <Paragraph large>
            {`
              This question is about child protection issues and applies to 
              natural or adopted children of either or both of you, who are 
              under 18, regardless of whether the child has lived with either 
              or both of you.
              `}
          </Paragraph>
        ),
      },
      type: 'yesno',
      index: 9,
    },
    {
      name: 'startChildSafteyYes',
      label: 'amica is not suitable for your situation',
      type: 'info',
      content: (
        <>
          <Paragraph large>
            {`amica is not suitable for situations where there has been any 
            contact with the department responsible for child safety (for 
            example, where children of the relationship have been subject 
            to care and protection orders, child welfare agencies have removed 
            the children from your care or child protection services are 
            involved with your family) with respect to the children of the 
            relationship or any other child of the household.`}
          </Paragraph>
          <Paragraph large>
            <HelpLineContent />
          </Paragraph>
        </>
      ),
      visible: (data: any) => !!data.startChildSafteyYesNo,
      onBeforeNext: notSuitableAsync,
      exitOnSubmit: true,
      index: 10,
    },
    {
      name: 'startDefactoWAYesNo',
      label: `
        Is there any person who may be entitled to become a party to the case under:
        subsection 79(10) or Subsection 90SM(10) of the Family Law Act? 
        Or if you were in a de facto relationship in Western Australia, under 
        the equivalent sections in the Family Court Act of WA?
      `,
      hint: '',
      type: 'yesno',
      help: {
        label: 'Learn more',
        content: (
          <>
            <Paragraph large>
              {`Certain persons are entitled to become a party to proceedings 
              between parties to a marriage (section 79(10)), and parties to 
              a de facto relationship which has broken down (section 90SM(10).
              `}
            </Paragraph>
            <Paragraph large>
              {`For example, any person whose interests would be directly 
              affected by the orders, any unpaid creditors who may not be able 
              to get paid if the order was made and any current partner/spouse 
              of one of the parties who could apply, or has an application 
              pending, for financial orders with one of the parties to the 
              application.`}
            </Paragraph>
          </>
        ),
      },
      index: 11,
    },
    {
      name: 'startDefactoWAYes',
      label: 'amica is not suitable for your situation',
      type: 'info',
      content: (
        <>
          <Paragraph large>
            {`amica is not suitable for situations where someone is entitled 
            to become a party to the proceedings`}
          </Paragraph>
          <Paragraph large>
            <HelpLineContent />
          </Paragraph>
        </>
      ),
      visible: (data: any) => !!data.startDefactoWAYesNo,
      onBeforeNext: notSuitableAsync,
      exitOnSubmit: true,
      index: 12,
    },
  ];

  // Defacto Route
  const isDefacto = checkDefacto();
  const isDefactoQuestions: QuestionAny[] = [
    {
      name: 'isDefactoAfterMarch2009SA',
      label: `Did your de facto relationship break down on or after 1 March 
      2009 or if resident in South Australia on or after 1 July 2010?`,
      hint: '',
      help: {
        label: 'Learn more',
        content: (
          <>
            <Paragraph large>
              {`If you relationship broke down before these dates, 
              amica is not right for your situation.`}
            </Paragraph>
            <Paragraph large>
              <HelpLineContent legalHelpAbout="applications by de facto couples" />
            </Paragraph>
          </>
        ),
      },
      type: 'yesno',
      visible: () => isDefacto,
      index: 13,
    },
    {
      name: 'isDefactoAfterMarch2009SANo',
      label: 'amica is not suitable for your situation',
      content: () => (
        <>
          <Paragraph large>
            {`amica is not suitable for your situation unless 
              your de facto relationship broke down on or after 1 March 2009 
              or if resident in South Australia on or after 1 July 2010. You 
              will not be able to create an application for consent orders 
              using amica.`}
          </Paragraph>
          <Paragraph large>
            <HelpLineContent />
          </Paragraph>
        </>
      ),
      hint: '',
      type: 'info',
      onBeforeNext: notSuitableAsync,
      exitOnSubmit: true,
      // If No to first question then cant continue
      visible: (data: any) => isDefacto && !data.isDefactoAfterMarch2009SA,
      index: 14,
    },
    {
      name: 'isDefactoLongerThan2Years',
      label:
        'Is the period or the total of the periods of the de facto relationship at least 2 years?',
      hint: '',
      type: 'yesno',
      visible: () => isDefacto,
      index: 15,
    },
    {
      name: 'isDefactoHaveChild',
      label: 'Is there a child of the de facto relationship?',
      hint: '',
      type: 'yesno',
      visible: () => isDefacto,
      index: 16,
    },
    {
      name: 'isDefactoHasApplicantMadeContributions',
      label: `Has the applicant made substantial contributions of 
          the kind mentioned in paragraph 90SM(4)(a), (b) or (c) of the 
          Family Law Act (or if you are applying in WA under section 
          205ZG(4)(a), (b) or (c)) of the Family Court Act of WA) and a 
          failure to make an order or declaration would result in serious 
          injustice to the applicant?`,
      hint: '',
      type: 'yesno',
      help: {
        label: 'Learn more',
        content: (
          <>
            <Paragraph large>This question is about fairness.</Paragraph>
            <Paragraph large>
              {`This question is asking whether: you, or someone on 
              your behalf, or someone on behalf of a child of the relationship 
              made contributions towards acquiring, maintaining or improving 
              any property of the relationship (whether it was owned jointly 
              or individually) or the welfare of the family, for example, as a 
              parent or homemaker to the extent that a serious injustice would 
              result (that is, it would be unfair on you) if the orders were 
              not made.`}
            </Paragraph>
          </>
        ),
      },
      visible: () => isDefacto,
      index: 17,
    },
    {
      name: 'isDefactoUnderLaw',
      label: `Is, or was, the relationship registered under a prescribed 
      law of a State or Territory of Australia?`,
      hint: '',
      type: 'yesno',
      visible: () => isDefacto,
      index: 18,
    },
    {
      name: 'isNotSuitableDefacto',
      label: 'amica is not suitable for your situation',
      content: (
        <>
          <Paragraph large>
            {`Based on your answers, it seems that your relationship does 
            not meet the Court's eligibility requirements for de facto 
            couples. amica is not suitable for your situation if you do not 
            meet these criteria.`}
          </Paragraph>
          <Paragraph large>
            <HelpLineContent />
          </Paragraph>
        </>
      ),
      hint: '',
      type: 'info',
      onBeforeNext: notSuitableAsync,
      exitOnSubmit: true,
      // If NO to ALL de facto questions, can't continue. If yes to first card PLUS yes to any of the subsequent cards, can progress
      visible: (data: any) =>
        isDefacto &&
        ![
          data.isDefactoLongerThan2Years,
          data.isDefactoHaveChild,
          data.isDefactoHasApplicantMadeContributions,
          data.isDefactoUnderLaw,
        ].some(Boolean),
      index: 19,
    },
    {
      name: 'startIntro',
      label: `You must file a copy of the Certificate of Registration 
      or other proof`,
      type: 'info',
      submitButtonLabel: 'Continue',
      visible: (data: any) => isDefacto && data.isDefactoUnderLaw,
      index: 20,
    },
  ];

  const finalQuestions: QuestionAny[] = [
    {
      name: 'finalIsSuitableQuestion',
      label: `Great, thank you. There are a few things we want to remind 
      you before you proceed.`,
      type: 'info',
      content: (
        <>
          <Paragraph large>
            {`When you finish your creating application, you will see that 
            some answers in the Application document (Q14 - Q18) have been
            pre-filled by amica as “no”.`}
          </Paragraph>
          <Paragraph large>
            {`These answers have been pre-filled because when you signed up to 
            amica, you acknowledged that you had checked whether amica is right 
            for your situation. `}
            (
            <ReferralLink href="https://www.amica.gov.au/screening-step-1.html">
              Is amica right for me?
            </ReferralLink>
            )
          </Paragraph>
          <Paragraph large>
            <HelpLineContent />
          </Paragraph>
        </>
      ),
      help: {
        label: 'Learn more',
        content: (
          <>
            <Paragraph large>
              {`The pre-filled answers on the Application for Consent Orders 
              document are : Q 14 (previous financial agreements under 
              legislation), Q15 (superannuation splitting requirements), Q16 
              (superannuation splitting requirements) , Q17 (bankruptcy), and 
              Q18 (proceeds of crime).`}
            </Paragraph>
            <Paragraph large>
              <b>IMPORTANT</b>
              {`: If the answers to any of the pre-filled questions 
              (Q14-Q18) should be ‘yes’, then amica is not right for your 
              situation and you cannot apply for consent orders using amica. 
              Do not proceed if this is the case.`}
            </Paragraph>
            <Paragraph large>
              <HelpLineContent />
            </Paragraph>
          </>
        ),
      },
      onBeforeNext: suitableAsync,
      index: 21,
    },
  ];

  // Combine and add index
  const questions = [
    ...startQuestions,
    ...isDefactoQuestions,
    ...finalQuestions,
  ].map((question, index) => {
    const newQuestion = { ...question };
    return { ...newQuestion, index };
  });

  return questions;
};
