import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getItemsAwaitingPayment, getTotalPrice } from 'Common/Utils/Payments';
import { ODRS_PAYMENT_PAY_FORM_ROUTE } from 'Common/routes';
import PageContainer from 'Common/UI/Layout/PageContainer';
import { AGREEMENT_PAYMENTS } from 'Common/constants';
import formatCurrency from 'Common/Utils/formatCurrency';
import {
  sessionStorageSave,
  sessionStorageLoad,
} from 'Common/Utils/sessionStorage';
import push from 'Common/Utils/push';
import baseTheme from 'Common/Utils/theme';
import styled, { ThemeProvider } from 'styled-components';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import { Heading1 } from 'Common/UI/Text/Headings';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import PaymentHeader from './PaymentHeader';
import {
  DrawerContent,
  PaymentDrawer,
  PaymentFooter,
  PaymentItem,
} from './common';
import { PaymentProvider } from './PaymentContext';

const StyledHeading1 = styled(Heading1)`
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const PaymentReviewOrder = ({ matter, navigate }) => {
  const previouslySelected =
    sessionStorageLoad('PaymentSelectedAgreementTypes') || [];
  const [items, setItems] = useState(getItemsAwaitingPayment(matter));
  const [selected, setSelected] = useState(
    previouslySelected.length > 0
      ? previouslySelected.filter(sel => items.includes(sel))
      : items
  );

  sessionStorageSave('PaymentSelectedAgreementTypes', selected);

  useEffect(() => setItems(getItemsAwaitingPayment(matter)), [matter]);

  const toggleSelected = item => {
    let newSelected = [];

    if (selected.includes(item)) {
      newSelected = selected.filter(s => s !== item);
    } else {
      newSelected = [...selected, item];
    }

    setSelected(newSelected);
    sessionStorageSave('PaymentSelectedAgreementTypes', newSelected);
  };

  return (
    <ThemeProvider theme={baseTheme}>
      <PaymentProvider navigate={navigate} matter={matter}>
        <PageContainer>
          <PaymentHeader matter={matter} />

          <PaymentDrawer>
            <DrawerContent>
              <ResponsiveContainer>
                <StyledHeading1>Review your order</StyledHeading1>
                <FlexGrid medium={2}>
                  {items.map(item => (
                    <PaymentItem
                      key={item}
                      item={AGREEMENT_PAYMENTS.find(i => i.type === item)}
                      selected={selected.includes(item)}
                      onClick={() => toggleSelected(item)}
                    />
                  ))}
                </FlexGrid>
              </ResponsiveContainer>
            </DrawerContent>

            <PaymentFooter
              onClick={() => navigate(ODRS_PAYMENT_PAY_FORM_ROUTE)}
              buttonLabel="Next"
              bigButtonLabel="Next: Payment"
              amount={formatCurrency(getTotalPrice(selected))}
              showBigCTA
              buttonDisabled={getTotalPrice(selected) <= 0}
            />
          </PaymentDrawer>
        </PageContainer>
      </PaymentProvider>
    </ThemeProvider>
  );
};

PaymentReviewOrder.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (route, state, replace) => dispatch(push(route, state, replace)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReviewOrder);
