/* eslint-disable camelcase */
import { createAction } from '@reduxjs/toolkit';

import {
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import fetch from 'cross-fetch';

import sanitiseMobile from 'Common/Utils/sanitiseMobile';
import Matter from 'Common/Data/Types/matter';
import {
  REMEMBER_DEVICE_USER_SUCCESS,
  REMEMBER_DEVICE_USER_FAILURE,
  ENABLEMFA_USER_SUCCESS,
  ENABLEMFA_USER_FAILURE,
} from './constants';
import {
  clearMatterStore,
  matterLoadMatterSuccess,
  matterUpdatePartyDetails,
} from './MatterReducer';
import { createAppAsyncThunk } from './Store';
import { getUserAttributesSuccess } from './UserReducer';

export const enableMFAForUserSuccessAction = createAction(
  ENABLEMFA_USER_SUCCESS
);
export const enableMFAForUserFailureAction = createAction(
  ENABLEMFA_USER_FAILURE
);

export const rememberDeviceForUserSuccessAction = createAction(
  REMEMBER_DEVICE_USER_SUCCESS
);
export const rememberDeviceForUserFailureAction = createAction(
  REMEMBER_DEVICE_USER_FAILURE
);

let cognitoUser: CognitoUser | undefined;

export const getUserSession = () =>
  new Promise<CognitoUserSession>((resolve, reject) => {
    const userPool = new CognitoUserPool({
      UserPoolId: process.env.COGNITO_USER_POOL || '',
      ClientId: process.env.COGNITO_CLIENT_ID || '',
    });
    cognitoUser = userPool.getCurrentUser() ?? undefined;

    if (cognitoUser) {
      cognitoUser.getSession(
        (err: Error | null, session: CognitoUserSession) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        }
      );
    } else {
      reject(new Error('User not found when loading session'));
    }
  });

// Manage User Auth with cognito.
// ref:
// https://github.com/aws/aws-amplify/tree/master/packages/amazon-cognito-identity-js
// https://docs.aws.amazon.com/cognito/latest/developerguide/tutorial-integrating-user-pools-javascript.html#tutorial-integrating-user-pools-sign-up-users-javascript

type RegisterUserActionProps = {
  email: string;
  password: { password: string };
  phone_number: string;
  legalname: { firstname: string; middlename: string; lastname: string };
  InviteID: string;
};

export const registerUserAction = createAppAsyncThunk(
  'user/registerUser',
  ({
    email,
    password: { password },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    phone_number,
    legalname: { firstname, middlename, lastname },
    InviteID,
  }: RegisterUserActionProps) => {
    const poolData = {
      UserPoolId: process.env.COGNITO_USER_POOL || '',
      ClientId: process.env.COGNITO_CLIENT_ID || '',
    };

    const userPool = new CognitoUserPool(poolData);
    const sanitisedPhoneNumber = sanitiseMobile(phone_number);

    const attributeList = [
      new CognitoUserAttribute({
        Name: 'phone_number',
        Value: sanitisedPhoneNumber,
      }),
      new CognitoUserAttribute({
        Name: 'email',
        Value: email.toLowerCase(),
      }),
      new CognitoUserAttribute({
        Name: 'given_name',
        Value: firstname,
      }),
      new CognitoUserAttribute({
        Name: 'family_name',
        Value: lastname,
      }),
      new CognitoUserAttribute({
        Name: 'middle_name',
        Value: middlename,
      }),
      new CognitoUserAttribute({
        Name: 'custom:InviteID',
        Value: InviteID,
      }),
      new CognitoUserAttribute({
        Name: 'custom:notifications',
        Value: '1',
      }),
    ];

    return new Promise<void>((resolve, reject) => {
      userPool.signUp(
        email.toLowerCase(),
        password,
        attributeList,
        [],
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            cognitoUser = result?.user;
            resolve();
          }
        }
      );
    });
  }
);

export const verifyUserAction = createAppAsyncThunk(
  'user/verifyUser',
  ({ verification }: { verification: string }) =>
    new Promise<void>((resolve, reject) => {
      const sanitisedVerificationCode = verification.trim();

      if (cognitoUser) {
        cognitoUser.confirmRegistration(
          sanitisedVerificationCode,
          true,
          err => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          }
        );
      } else {
        resolve();
      }
    })
);

export const resendVerificationAction = createAppAsyncThunk(
  'user/resendVerification',
  () =>
    new Promise<void>(resolve => {
      if (cognitoUser) {
        resolve(cognitoUser.resendConfirmationCode(() => {}));
      } else {
        resolve();
      }
    })
);

export const authenticateUserAction = createAppAsyncThunk(
  'user/authenticateUser',
  ({
    email,
    password: { password },
  }: {
    email: string;
    password: { password: string };
  }) => {
    const poolData = {
      UserPoolId: process.env.COGNITO_USER_POOL || '',
      ClientId: process.env.COGNITO_CLIENT_ID || '',
    };

    const userPool = new CognitoUserPool(poolData);

    const authenticationDetails = new AuthenticationDetails({
      Username: email.toLowerCase(),
      Password: password,
    });

    const userData = {
      Username: email.toLowerCase(),
      Pool: userPool,
    };

    cognitoUser = new CognitoUser(userData);

    return new Promise<void>((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.authenticateUser(authenticationDetails, {
          mfaRequired: props => {
            resolve(props);
          },
          onSuccess: () => {
            resolve();
          },
          onFailure: err => {
            reject(err);
          },
        });
      } else {
        reject(new Error('No user found'));
      }
    });
  }
);

export const sendMFAcodeAction = createAppAsyncThunk(
  'user/sendMFAcode',
  ({ verification }: { verification: string }) =>
    new Promise<void>((resolve, reject) => {
      const sanitisedVerificationCode = verification.trim();

      if (cognitoUser) {
        cognitoUser.sendMFACode(sanitisedVerificationCode, {
          onSuccess: () => {
            resolve();
          },
          onFailure: err => {
            reject(err);
          },
        });
      } else {
        resolve();
      }
    })
);

export const enableMFAForUserAction = createAppAsyncThunk(
  'user/enableMFAForUser',
  () =>
    new Promise<void>((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.setUserMfaPreference(
          {
            PreferredMfa: true,
            Enabled: true,
          },
          null,
          err => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          }
        );
      } else {
        resolve();
      }
    })
);

export const forgetDeviceForUserAction = createAppAsyncThunk(
  'user/forgetDeviceForUser',
  () =>
    new Promise<void>((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.setDeviceStatusNotRemembered({
          onSuccess: () => {
            resolve();
          },
          onFailure: err => {
            reject(err);
          },
        });
      } else {
        resolve();
      }
    })
);

export const rememberDeviceForUserAction = createAppAsyncThunk(
  'user/rememberDeviceForUser',
  () =>
    new Promise<void>((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.setDeviceStatusRemembered({
          onSuccess: () => {
            resolve();
          },
          onFailure: err => {
            reject(err);
          },
        });
      } else {
        resolve();
      }
    })
);

export const invitePartnerAction = createAppAsyncThunk(
  'user/invitePartner',
  (
    {
      partnerName,
      partnerEmail,
    }: { partnerName: string; partnerEmail: string },
    { dispatch, getState }
  ) => {
    // Update Party B details in the matter so we can refer to them going forward.
    dispatch(
      matterUpdatePartyDetails({
        party: 'B',
        details: {
          firstname: partnerName,
          email: partnerEmail,
        },
      })
    );

    return getUserSession()
      .then(session =>
        fetch(`${process.env.API_ENDPOINT_BASE}/matter/invite`, {
          method: 'POST',
          body: JSON.stringify({
            MatterID: getState().matter.MatterID,
            firstname: partnerName,
            email: partnerEmail,
            otherfirstname: getState().matter.self.firstname,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(matterLoadMatterSuccess(matter)));
  }
);

export const resendInviteAction = createAppAsyncThunk(
  'user/resendInvite',
  (InviteID: string) =>
    fetch(`${process.env.API_ENDPOINT_BASE}/invite`, {
      method: 'POST',
      body: JSON.stringify({ APIAction: 'sendInviteByInviteID', InviteID }),
    })
);

export const resendInvitePartnerAction = createAppAsyncThunk(
  'user/resendInvitePartner',
  (_, { dispatch, getState }) => {
    const { firstname, email } = getState().matter.partyB;
    return dispatch(
      invitePartnerAction({ partnerName: firstname, partnerEmail: email })
    );
  }
);

export const signOutAction = createAppAsyncThunk(
  'user/signOut',
  async (_, { dispatch }) =>
    new Promise<void>(resolve => {
      const userPool = new CognitoUserPool({
        UserPoolId: process.env.COGNITO_USER_POOL || '',
        ClientId: process.env.COGNITO_CLIENT_ID || '',
      });
      const user = userPool.getCurrentUser();

      if (user) {
        user.signOut();
      }

      dispatch(clearMatterStore());
      resolve();
    })
);

export const triggerResetPasswordForUserAction = createAppAsyncThunk(
  'user/triggerResetPasswordForUser',
  ({ email }: { email: string }) =>
    new Promise((resolve, reject) => {
      const userPool = new CognitoUserPool({
        UserPoolId: process.env.COGNITO_USER_POOL || '',
        ClientId: process.env.COGNITO_CLIENT_ID || '',
      });
      const userData = {
        Username: email.toLowerCase(),
        Pool: userPool,
      };
      cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => {
          reject(err);
        },
      });
    })
);

export const userCheckByEmail = createAppAsyncThunk(
  'user/userCheckByEmail',
  (email: string) =>
    getUserSession()
      .then(session =>
        fetch(`${process.env.API_ENDPOINT_BASE}/party/usercheck`, {
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response =>
        response.json().then(result => ({
          status: response.status,
          result,
        }))
      )
);

export const resetPasswordForUserAction = createAppAsyncThunk(
  'user/resetPasswordForUser',
  ({
    email,
    verification,
    password: { password },
  }: {
    email: string;
    verification: string;
    password: { password: string };
  }) => {
    const userPool = new CognitoUserPool({
      UserPoolId: process.env.COGNITO_USER_POOL || '',
      ClientId: process.env.COGNITO_CLIENT_ID || '',
    });

    const userData = {
      Username: email.toLowerCase(),
      Pool: userPool,
    };

    const sanitisedVerificationCode = verification.trim();

    cognitoUser = new CognitoUser(userData);

    return new Promise<void>((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.confirmPassword(sanitisedVerificationCode, password, {
          onFailure: err => {
            reject(err);
          },
          onSuccess: () => {
            resolve();
          },
        });
      } else {
        reject(new Error('No user found'));
      }
    });
  }
);

export const getUserAttributesAction = createAppAsyncThunk(
  'user/getUserAttributes',
  async (_, { dispatch }) =>
    getUserSession()
      .then(
        () =>
          new Promise<Record<string, unknown>>((resolve, reject) => {
            if (!cognitoUser) {
              reject(new Error('No user found'));
            } else {
              cognitoUser.getUserAttributes((err, result) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(
                    (result || []).reduce(
                      (acc, curr) => ({
                        ...acc,
                        [curr.Name]: curr.Value,
                      }),
                      {}
                    )
                  );
                }
              });
            }
          })
      )
      .then(result => {
        dispatch(getUserAttributesSuccess(result));
        return result;
      })
);

export const updateUserAttributeSetAction = createAppAsyncThunk(
  'user/updateUserAttributeSet',
  ({
    legalname: { firstname, middlename, lastname },
    email,
    phone_number,
    notifications,
  }: {
    legalname: { firstname: string; middlename: string; lastname: string };
    email: string;
    phone_number: string;
    notifications: boolean;
  }) =>
    getUserSession().then(() => {
      if (!cognitoUser) {
        throw new Error('No user found');
      } else {
        const attributeList = [
          new CognitoUserAttribute({
            Name: 'phone_number',
            Value: sanitiseMobile(phone_number),
          }),
          new CognitoUserAttribute({
            Name: 'email',
            Value: email.toLowerCase(),
          }),
          new CognitoUserAttribute({
            Name: 'given_name',
            Value: firstname,
          }),
          new CognitoUserAttribute({
            Name: 'family_name',
            Value: lastname,
          }),
          new CognitoUserAttribute({
            Name: 'middle_name',
            Value: middlename,
          }),
          new CognitoUserAttribute({
            Name: 'custom:notifications',
            Value: notifications ? '1' : '0',
          }),
        ];

        return new Promise<void>((resolve, reject) => {
          if (cognitoUser) {
            cognitoUser.updateAttributes(attributeList, err => {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            });
          } else {
            reject('BAD');
          }
        });
      }
    })
);

export const getAttributeVerificationCodeAction = createAppAsyncThunk(
  'user/getAttributeVerificationCode',
  (attribute: string) =>
    new Promise<void>((resolve, reject) => {
      if (!cognitoUser) {
        reject(new Error('No user found'));
      } else {
        cognitoUser.getAttributeVerificationCode(attribute, {
          onSuccess: () => {
            resolve();
          },
          onFailure: err => {
            reject(err);
          },
          inputVerificationCode: () => {
            resolve();
          },
        });
      }
    })
);

export const verifyAttributeAction = createAppAsyncThunk(
  'user/verifyAttribute',
  ({ attribute, code }: { attribute: string; code: string }) =>
    new Promise<void>((resolve, reject) => {
      getUserSession().then(() => {
        if (cognitoUser) {
          cognitoUser.verifyAttribute(attribute, code, {
            onSuccess: () => {
              resolve();
            },
            onFailure: err => {
              reject(err);
            },
          });
        } else {
          reject(new Error('No user found'));
        }
      });
    })
);
