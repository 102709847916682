import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { ThemeProvider } from 'styled-components';

import FullScreenModal from 'Common/UI/Layout/FullScreenModal';
import GenericButton from 'Common/UI/Button/GenericButton';
import { Column } from 'Common/UI/Layout/Flex';
import { greenTheme, Theme } from 'Common/Utils/theme';
import TextAreafield from '../../TextAreafield';

export const modalPortal: HTMLElement = document.getElementById(
  'modal'
) as HTMLElement;

const Wrapper = styled(Column)`
  height: 100%;
  align-items: stretch;
`;

const FlexContent = styled.div<{
  theme: Theme;
}>`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing[4]};
`;

const ModalFooter = styled.div<{
  theme: Theme;
}>`
  flex-grow: 0;
  justify-self: flex-end;
  padding: ${({ theme }) => theme.spacing[4]};
`;

type Props = {
  show: boolean;
  onClose: () => void;
  onSaveParagraph: (paragraph: string) => void;
  text: string;
  title: string;
};

const EditModal: React.FC<Props> = ({
  show,
  onClose,
  onSaveParagraph,
  text,
  title,
}) => {
  const [textValue, setTextValue] = useState<string>(text);

  useEffect(() => setTextValue(text), [text]);

  const portal = createPortal(
    <FullScreenModal
      data-cy="modal-superannuation-clause-edit-paragraph"
      show={show}
      onClose={onClose}
      title={title}
      closeButtonType="icon"
    >
      <Wrapper>
        <FlexContent>
          <TextAreafield
            onChange={(val: string) => setTextValue(val)}
            value={textValue}
            name="paragraph"
            onBlur={() => {}}
            onFocus={() => {}}
            rows={16}
            inputmode="text"
            placeholder="Copy/paste or type the new paragraph from your superannuation fund here"
            autoFocus
          />
        </FlexContent>
        <ModalFooter>
          <ThemeProvider theme={greenTheme}>
            <GenericButton
              fullWidth
              onClick={() => onSaveParagraph(textValue)}
              data-cy="save-paragraph-button"
            >
              Save
            </GenericButton>
          </ThemeProvider>
        </ModalFooter>
      </Wrapper>
    </FullScreenModal>,
    modalPortal
  );

  return portal;
};

export default EditModal;
