/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

/** NOTE: IF A FUNCTION CALLS ANOTHER FUNCTION IN THIS SAME FILE THEN YOU MUST NOT IMPORT THAT FUNCTION INTO LATER VERSIONS, EVEN IF THERE ARE NO CHANGES */

import {
  CARD_NORMAL,
  CARD_AGREEMENT,
  CARD_STATEMENT,
  DATA_SECTION,
  STATUS_APPROVED,
  DATA_GROUP,
  DATA_PAGE,
  PARTY_A,
  PARTY_B,
  Sections,
} from 'Common/constants';
import MatterPropsJS, { getMatter } from 'Common/Utils/MatterProps';
import {
  CardAny,
  DataGroup,
  DataPage,
  DataSection,
} from '../../../Types/appSections.d';

import generateRelationship from '../../20211123/Cards/Sections/relationship';
import generateProperty from './Sections/property';
import generateParenting from '../../20200101/Cards/Sections/parenting';
import generateConsentOrders from './Sections/consentOrders';

import generateParentingPlanSectionData from '../../20200101/Questions/ParentingPlan';
import { Item } from '../../../Types/matter';

export {
  isConsentOrdersDashboardLocation,
  isConsentOrdersQuestionLocation,
} from '../../20200101/Cards/VERSIONED_appSections';

// return an array of existing children cards, property cards, and vehicle cards respectively
const getExistingRepeatableCards = (items: Item[]) => [
  items.filter(item => item.BaseSection === 'children'),
  items.filter(item => item.BaseSection === 'properties'),
  items.filter(item => item.BaseSection === 'vehicles'),
];

export const getAppSections = (): DataSection[] => {
  const { items } = getMatter();

  let parentingPlanBasics;
  let parentingPlanScheduling;
  let parentingPlanAdditions;

/////////////////////////////
///////////////////////////
///////////////////////////////
//////////////////////////////
///////////
  // Need to pass children data to generate questions
  ({
    basics: parentingPlanBasics,
    scheduling: parentingPlanScheduling,
    additions: parentingPlanAdditions,
  } = generateParentingPlanSectionData(MatterPropsJS('childrenData')));
////////////

  const [existingChildren, existingProperties, existingVehicles] =
    getExistingRepeatableCards(items);

  const existingAgreedChildren: Item[] = existingChildren.filter(
    (item: Item) => item.status === STATUS_APPROVED && item.SectionID
  );
  const existingAgreedProperties: Item[] = existingProperties.filter(
    (item: Item) => item.status === STATUS_APPROVED && item.SectionID
  );

  const existingSuperannuationA = items.filter(
    (item: Item) =>
      item.BaseSection === 'superannuation' && item.index === PARTY_A
  );
  const existingSuperannuationB = items.filter(
    (item: Item) =>
      item.BaseSection === 'superannuation' && item.index === PARTY_B
  );

  const propertySection: any = generateProperty({
    existingProperties,
    existingVehicles,
    existingSuperannuationA,
    existingSuperannuationB,
  });

  const superA = items.filter(
    (item: Item) =>
      item.BaseSection === 'yoursuperannuation' && item.creator === PARTY_A
  );
  const superB = items.filter(
    (item: Item) =>
      item.BaseSection === 'yoursuperannuation' && item.creator === PARTY_B
  );

  const doesSuperExist = {
    [PARTY_A]: superA && superA.length > 0,
    [PARTY_B]: superB && superB.length > 0,
  };

  const existingAgreedSuperannuationA = superA.filter(
    (item: Item) =>
      item.status === STATUS_APPROVED &&
      item.SectionID &&
      item.creator === PARTY_A
  );

  const existingAgreedSuperannuationB = superB.filter(
    (item: Item) =>
      item.status === STATUS_APPROVED &&
      item.SectionID &&
      item.creator === PARTY_B
  );

  return [
    propertySection,
    generateRelationship({ existingChildren }),
    generateParenting({
      parentingPlanBasics,
      parentingPlanScheduling,
      parentingPlanAdditions,
    }),
    generateConsentOrders({
      existingAgreedChildren,
      existingAgreedProperties,
      doesSuperExist,
      existingAgreedSuperannuationA,
      existingAgreedSuperannuationB,
    }),
  ];
};

const getIDAsArray = (id: string) =>
  Array.isArray(id) ? `${id[0]}${id[1] || ''}` : id;

const sectionsFlattened = (sections: DataSection[]): [] => {
  const reducer = (array: any, el: any) => {
    const { children } = el;
    // eslint-disable-next-line no-param-reassign
    delete el.children;
    array.push(el);

    if (children) {
      return children.reduce(reducer, array);
    }

    return array;
  };

  return sections.reduce(reducer, []);
};

export const getSection = (sectionID: Sections): DataSection | undefined =>
  getAppSections().find((section: DataSection) => section.id === sectionID);

export const getPage = (
  sectionID: Sections,
  pageID: string
): DataPage | undefined => {
  try {
    return getSection(sectionID)?.children.find(
      (page: DataPage) => page.id === pageID
    );
  } catch (error) {
    throw Error(`Page does not exist (${sectionID} / ${pageID})`);
  }
};

export const getOptionalCardIDsInSection = (sectionID: Sections) => {
  const section = getSection(sectionID);

  if (section) {
    return sectionsFlattened([])
      .filter(
        (item: Item) =>
          [CARD_AGREEMENT, CARD_STATEMENT, CARD_NORMAL].includes(item.type) &&
          item.optional
      )
      .map((item: Item) => item.cardID);
  }

  return [];
};

export const getCard = (id: string) => {
  const cardID = getIDAsArray(id);

  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);

  return sectionsFlat.find((el: any) => el.cardID === cardID);
};

/** Gets a card and returns it as the provided card type
 *
 * @throws if the card is undefined
 *
 * @param cardID - card id
 * @returns returns the card as the provided type
 *
 * @example
 *
 * // card is now definitely defined and of type CardYourSuperannuation
 * const card = definitelyGetCard<CardYourSuperannuation>('yoursuperannuation1A');
 */
export const definitelyGetCard = <T extends CardAny>(cardID: string) => {
  const cardMaybe = getCard(cardID);

  if (cardMaybe === undefined) {
    throw Error(`Card is undefined (${cardID})`);
  }

  return cardMaybe as T;
};

export const getGroupContainingCard = (id: string) => {
  const cardID = getIDAsArray(id);

  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);

  const index = sectionsFlat.findIndex((el: any) => el.cardID === cardID);

  const partialReversed = sectionsFlat.slice(0, index).reverse();

  const group = partialReversed.find((el: any) => el.type === DATA_GROUP);

  if (!group) {
    throw new Error(`Card '${id}' does not have a group`);
  }

  return group;
};

export const getCardDashboardRoute = (id: string) => {
  const cardID = getIDAsArray(id);

  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);

  const index = sectionsFlat.findIndex((item: any) => item.cardID === cardID);

  const partialReversed = sectionsFlat.slice(0, index).reverse();

  return (
    (
      partialReversed.find((el: any) => el.type === DATA_PAGE) ||
      ({} as DataPage)
    ).route || undefined
  );
};

export const getCardQuestionFlowMetadata = (id: string) => {
  const cardID = getIDAsArray(id);

  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);

  const index = sectionsFlat.findIndex((item: any) => item.cardID === cardID);

  const partialReversed = sectionsFlat.slice(0, index).reverse();

  const group = (partialReversed.find((el: any) => el.type === DATA_GROUP) ||
    {}) as DataGroup;

  const section = (partialReversed.find(
    (el: any) => el.type === DATA_SECTION
  ) || {}) as DataSection;

  return {
    backgroundImage: section.questionFlowBackgroundImage,
    backgroundColour: section.questionFlowBackgroundColour,
    icon: group.icon || undefined,
  };
};

/**
 * Gets the page and section for a card ID
 * @param id - card id
 * @returns - returns the section and page for the card
 */
export const getPageAndSectionIdsContainingCard = (id: string) => {
  const cardID = getIDAsArray(id);
  const sections = getAppSections();
  const sectionsFlat = sectionsFlattened(sections);
  const index = sectionsFlat.findIndex((el: any) => el.cardID === cardID);
  const partialReversed = sectionsFlat.slice(0, index).reverse();

  const section: any = partialReversed.find(
    (el: any) => el.type === DATA_SECTION
  );
  const page: any = partialReversed.find((el: any) => el.type === DATA_PAGE);
  if (!section || !page) return {};
  return {
    section,
    page,
  };
};
