import MatterProps from 'Common/Utils/MatterProps';
import { reduxStore } from 'App/State/Store';
import { getAgreementDocURLAction } from 'App/State/MatterActions';
import {
  DOC_TYPE_CONSENT_ORDERS,
  DOC_TYPE_MINUTES_OF_CONSENT,
} from 'Common/constants';
import openResolvedPromiseURL from './openResolvedPromiseURL';

export const openConsentOrder = () =>
  openResolvedPromiseURL(
    reduxStore
      .dispatch(getAgreementDocURLAction({ name: DOC_TYPE_CONSENT_ORDERS }))
      .unwrap()
  );

export const openMinutesOfConsent = () =>
  openResolvedPromiseURL(
    reduxStore
      .dispatch(getAgreementDocURLAction({ name: DOC_TYPE_MINUTES_OF_CONSENT }))
      .unwrap()
  );

const isWA = () => {
  const jurisdiction =
    MatterProps('items', []).find(item => item.SectionID === 'jurisdiction') ||
    {};

  if (jurisdiction.stateReside === 'Western Australia') {
    return true;
  }

  return false;
};

export const getFilingGuideURL = () =>
  isWA()
    ? 'https://amica-documents.s3-ap-southeast-2.amazonaws.com/Guide-for-filing-consent-order-applications-WA.pdf'
    : 'https://amica-documents.s3.ap-southeast-2.amazonaws.com/Guide-for-filing-consent-order-applications.FCFCOA.pdf';

export const getConsentKitInstructionsURL = () =>
  isWA()
    ? 'https://amica-documents.s3-ap-southeast-2.amazonaws.com/Kit_Consent_Orders-WA.pdf'
    : 'https://amica-documents.s3.ap-southeast-2.amazonaws.com/Consent_Order_Kit_Application_Instructions.FCFCOA.pdf';
