import React from 'react';
import CardQuestionsFlow from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSNoChildren: React.FC = props => (
  <MatterGuard>
    <CardQuestionsFlow baseID="nochildren" indexFromParams {...props} />
  </MatterGuard>
);

export default ODRSNoChildren;
