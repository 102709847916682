import React from 'react';
import { APP_SECTION_ID_CONSENT_ORDER } from 'Common/constants';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSPropertiesContinue = props => (
  <MatterGuard>
    <CardQuestions
      baseID="properties-continue"
      appSectionID={APP_SECTION_ID_CONSENT_ORDER}
      extendID="properties"
      indexFromParams
      {...props}
    />
  </MatterGuard>
);

export default ODRSPropertiesContinue;
