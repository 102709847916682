import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
  PAGE_PARENTING_ADDITIONS,
  PAGE_PARENTING_COMPLETE,
  PAGE_PARENTING_ESSENTIALS,
  PAGE_PARENTING_SCHEDULE,
} from 'Common/constants';
import {
  ODRS_DASH_PARENTING_PLAN_INTRO_ROUTE,
  ODRS_PARENTING_ADDITIONS_ROUTE,
  ODRS_PARENTING_COMPLETE_ROUTE,
  ODRS_PARENTING_ESSENTIALS_ROUTE,
  ODRS_PARENTING_SCHEDULE_ROUTE,
} from 'Common/routes';
import DashboardParenting from 'App/Pages/DashboardParenting';
import { blueGreyMobileTheme, darkerNoBackground } from 'Common/Utils/theme';
import ParentingPlanSlider from 'App/UI/Dashboard/Parenting/ParentingPlanSlider/index';
import DiviRoute from 'Common/UI/DiviRoute';
import MatterGuard from '../Guard/MatterGuard';

const ODRSDashboardParenting = () => (
  <MatterGuard>
    <Switch>
      <DiviRoute
        path={ODRS_DASH_PARENTING_PLAN_INTRO_ROUTE}
        component={ParentingPlanSlider}
        theme={blueGreyMobileTheme}
      />
      <DiviRoute
        path={ODRS_PARENTING_ESSENTIALS_ROUTE}
        component={DashboardParenting}
        theme={darkerNoBackground}
        tab={PAGE_PARENTING_ESSENTIALS}
      />
      <DiviRoute
        path={ODRS_PARENTING_SCHEDULE_ROUTE}
        component={DashboardParenting}
        theme={darkerNoBackground}
        tab={PAGE_PARENTING_SCHEDULE}
      />
      <DiviRoute
        path={ODRS_PARENTING_ADDITIONS_ROUTE}
        component={DashboardParenting}
        theme={darkerNoBackground}
        tab={PAGE_PARENTING_ADDITIONS}
      />
      <DiviRoute
        path={ODRS_PARENTING_COMPLETE_ROUTE}
        component={DashboardParenting}
        theme={darkerNoBackground}
        tab={PAGE_PARENTING_COMPLETE}
      />
      <Redirect to={ODRS_DASH_PARENTING_PLAN_INTRO_ROUTE} />
    </Switch>
  </MatterGuard>
);
export default ODRSDashboardParenting;
