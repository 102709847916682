import React from 'react';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

const DivisionHelpAndSupport = (
  <>
    <H1Conversational>Help and support</H1Conversational>
    <Callout>We understand that separating can be an emotional time.</Callout>
    <Paragraph>
      {`It is important to take care of yourself during this period. You can 
      find a range of help and support options, including information about 
      someone to talk to, on our `}
      <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
        Help and Support page.
      </ReferralLink>
    </Paragraph>
  </>
);

export default DivisionHelpAndSupport;
