import React, { useState } from 'react';
import styled from 'styled-components';

import {
  STATUS_NEW_BOTH_PARTIES,
  STATUS_NEW_ONLY_SELF,
} from 'Common/constants';
import { useAppDispatch } from 'App/State/Store';
import { Item } from 'Common/Data/Types/matter';
import {
  QuestionCard,
  DataGroupRepeatable,
} from 'Common/Data/Types/appSections';
import { getGroupContainingCard } from 'Common/Data/App/appSections';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import Loader from 'Common/UI/Loader/Loader';
import {
  reopenItemAction,
  removeItemAction,
  removeEmptyItemAction,
} from 'App/State/MatterActions';
import {
  SCREEN_STATE_INITIAL,
  SCREEN_STATE_CONFIRMING,
  REOPEN_STATEMENT,
  REMOVE_STATEMENT,
} from './constants';

import OptionsConfirmationScreen from './screens/OptionsConfirmationScreen';
import OptionsInitialScreen from './screens/OptionsInitialScreen';

import OptionsDrawerPortal from './OptionsDrawerPortal';

const CloseButton = styled.div`
  float: right;
  padding: 16px;
`;

type Props = {
  isOpen: boolean;
  optionItems: string[];
  handleClose: () => void;
  card: QuestionCard;
};

const OptionsDrawer: React.FC<Props> = ({
  optionItems,
  handleClose,
  card,
  isOpen,
}) => {
  const [screenState, setScreenState] = useState(SCREEN_STATE_INITIAL);
  const [selectedOption, setSelectedOption] =
    useState<'reopen-statement' | 'remove-statement' | undefined>();
  const [isLoading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const removeEmptyItem = (id: string) => dispatch(removeEmptyItemAction(id));
  const reopenItem = (item: Item) => dispatch(reopenItemAction(item));
  const removeItem = (item: Item) => dispatch(removeItemAction(item));

  const handleOptionsDrawerClick = () => {
    switch (selectedOption) {
      case REOPEN_STATEMENT:
        return reopenItem(card.data);

      case REMOVE_STATEMENT:
        if (
          card.status === STATUS_NEW_BOTH_PARTIES ||
          card.status === STATUS_NEW_ONLY_SELF
        ) {
          let incrementID;

          const group = getGroupContainingCard(card.cardID);

          if ((group as DataGroupRepeatable).isIndividual) {
            const { self } = getPartyKeys();

            incrementID = `${group.id}${self}`;
          } else {
            incrementID = group.id;
          }
          return removeEmptyItem(incrementID);
        }

        return removeItem(card.data);

      default:
        console.warn('No key for option found');
        return Promise.reject();
    }
  };

  const closeDrawer = () => {
    setScreenState(SCREEN_STATE_INITIAL);
    handleClose();
  };

  const acceptSelection = () => {
    setLoading(true);

    handleOptionsDrawerClick().then(() => {
      setLoading(false);
      setScreenState(SCREEN_STATE_INITIAL);
      setSelectedOption(undefined);
      handleClose();
    });
  };

  return (
    <OptionsDrawerPortal isOpen={isOpen} onBackgroundClick={closeDrawer}>
      <CloseButton onClick={closeDrawer} />
      <OptionsInitialScreen
        optionItems={optionItems}
        card={card}
        visible={screenState === SCREEN_STATE_INITIAL}
        onAccept={item => {
          setScreenState(SCREEN_STATE_CONFIRMING);
          setSelectedOption(item);
        }}
        // onCancel={closeDrawer}
      />
      <OptionsConfirmationScreen
        visible={screenState === SCREEN_STATE_CONFIRMING}
        selectedOption={selectedOption}
        onAccept={acceptSelection}
        onCancel={closeDrawer}
        card={card}
      />
      {isLoading && <Loader floating />}
    </OptionsDrawerPortal>
  );
};

export default OptionsDrawer;
