import RoundButton from 'Common/UI/Button/RoundButton';
import Input from 'Common/UI/Form/Input';
import { Row } from 'Common/UI/Layout/Flex';
import isNumeric from 'Common/Utils/isNumeric';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isNumericMonth from 'Common/Utils/isNumericMonth';
import Paragraph from 'Common/UI/Text/Paragraph';

const CYBERSOURCE_SCRIPT_ID = 'cybersource-script';

// Declare our Flex variable
declare let Flex: any;

type CybersourceFieldChangeData = {
  couldBeValid: true;
  empty: false;
  valid: false;
};

const CybersourceFieldWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  background-color: #f7f7fc;
  padding: 0 16px;
  border: 1px solid #e4e4f0;
  border-radius: 4px;
`;

const SecurityCodeWrapper = styled(CybersourceFieldWrapper)`
  width: 120px;
`;

const Form = styled.form`
  max-width: 100%;
  width: 500px;

  input {
    width: 100%;
  }
`;

const FormControl = styled.div`
  margin-bottom: 20px;
`;

const FormControlInline = styled(FormControl)`
  margin-right: 10px;
`;

const CybersourcePaymentForm = ({
  captureContext,
  handlePayment,
}: {
  captureContext: string;
  handlePayment: (token: string) => void;
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [microForm, setMicroForm] = useState<any>(null);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [error, setError] = useState('');
  const [isCardNumberValid, setCardValid] = useState(false);
  const [isSecurityCodeValid, setSecurityCodeValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Load Cybersource script
  useEffect(() => {
    const cybersourceScriptTag = document.getElementById(CYBERSOURCE_SCRIPT_ID);
    const cybersourceFormScript = process.env.CYBERSOURCE_FORM_SCRIPT || '';

    if (!cybersourceScriptTag) {
      const script = document.createElement('script');
      script.setAttribute('id', CYBERSOURCE_SCRIPT_ID);

      script.src = cybersourceFormScript;
      script.async = true;
      document.body.appendChild(script);
    }

    const checkAPITimeout = setInterval(() => {
      try {
        // This gets loaded from Cybersource,
        // NOTE: it will throw an error in the console
        const checkFlex = Flex || undefined;
        if (checkFlex !== 'undefined') {
          clearInterval(checkAPITimeout);
          setScriptLoaded(true);
        }
      } catch (e) {
        console.error(e);
        setError('Error loading Cybersource');
      }
    }, 100);

    return () => clearInterval(checkAPITimeout);
  }, []);

  // load form from cybersource script
  useEffect(() => {
    if (scriptLoaded && captureContext) {
      // Flex is loaded from the Cybersource script
      const flex = new Flex(captureContext);

      setError('');

      const myStyles = {
        input: {
          color: '#000',
          'font-size': '16px',
          'font-family': 'sans-serif',
        },
      };

      const microform = flex.microform({ styles: myStyles });

      setMicroForm(microform);

      const number = microform.createField('number', {
        placeholder: 'Enter card number',
        styles: myStyles,
      });
      const securityCode = microform.createField('securityCode', {
        placeholder: 'CVV',
      });
      number.load('#number-container');
      securityCode.load('#securityCode-container');

      // Form validation events

      number.on('change', (data: CybersourceFieldChangeData) => {
        setCardValid(data.valid);
      });

      securityCode.on('change', (data: CybersourceFieldChangeData) => {
        setSecurityCodeValid(data.valid);
      });
    }
  }, [scriptLoaded, captureContext]);

  const onSubmit = () => {
    if (month && year) {
      const options = {
        expirationMonth: month,
        expirationYear: year,
      };
      setError('');
      setIsSubmitting(true);

      // creates a transient token
      microForm?.createToken(options, (err: any, token: any) => {
        if (err) {
          console.error(err);
          const errorMessage = err?.details[0]?.message || 'error unknown';
          setError(`Error submitting form: ${errorMessage}`);
          setIsSubmitting(false);
        } else {
          // submit the payment with our validated token
          handlePayment(token);
        }
      });
    }
  };

  const isFormValid =
    month &&
    isNumericMonth(month) &&
    year &&
    year.length === 4 &&
    isCardNumberValid &&
    isSecurityCodeValid;

  return (
    <Form
      action={process.env.CYBERSOURCE_PAYMENT_URL || ''}
      id="cybersource-form"
      method="post"
    >
      <FormControl>
        <Input
          id="cardholderName"
          className="form-control"
          name="cardholderName"
          placeholder="Name on the card"
        />
      </FormControl>
      <CybersourceFieldWrapper>
        <div
          id="number-container"
          className="form-control"
          style={{ height: '48px' }}
        >
          {/* Cybersource number field gets loaded here */}
        </div>
      </CybersourceFieldWrapper>
      <SecurityCodeWrapper>
        <div
          id="securityCode-container"
          className="form-control"
          style={{ height: '48px' }}
        >
          {/* Cybersource CVV gets loaded here */}
        </div>
      </SecurityCodeWrapper>
      <Row>
        <FormControlInline>
          <Input
            type="text"
            value={month}
            onChange={e => {
              if (error) setError('');
              if (isNumeric(e.target.value) || e.target.value === '')
                setMonth(e.target.value);
            }}
            placeholder="MM"
            id="expMonth"
            name="expMonth"
            maxLength={2}
          />
        </FormControlInline>
        <FormControlInline>
          <Input
            id="expYear"
            name="expYear"
            type="text"
            value={year}
            onChange={e => {
              if (error) setError('');
              if (isNumeric(e.target.value) || e.target.value === '')
                setYear(e.target.value);
            }}
            placeholder="YYYY"
            maxLength={4}
          />
        </FormControlInline>
      </Row>
      <FormControl>
        <RoundButton
          onClick={onSubmit}
          type="button"
          id="pay-button"
          className="btn btn-primary"
          disabled={!isFormValid || isSubmitting}
        >
          Pay
        </RoundButton>
      </FormControl>
      {error && <Paragraph color="red">{error}</Paragraph>}
    </Form>
  );
};

export default CybersourcePaymentForm;
