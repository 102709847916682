import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import formatCurrency from 'Common/Utils/formatCurrency';
import MatterProps from 'Common/Utils/MatterProps';
import { Row, Column } from 'Common/UI/Layout/Flex';
import defaultTheme from 'Common/Utils/theme';
import Indicator from './Indicator';
import Amounts from './Amounts';

const Wrapper = styled.div`
  padding-bottom: 24px;
  width: 100%;
`;

const StyledRow = styled(Row)`
  display: ${({ display }) => display || 'inline-flex'};
  margin: ${({ margin }) => margin || '0'};
  color: ${({ textColour, theme }) => textColour || theme.textColour};
  width: 100%;
`;

const StyledAssetPoolRow = styled(StyledRow)`
  font-size: ${({ theme }) => theme.typography.text.paragraph.fontSize};
`;

const SplitIndicator = React.forwardRef(
  (
    {
      primary,
      secondary,
      totalAssetPool,
      indicatorTextColour,
      className,
      ...rest
    },
    ref
  ) => {
    const percentage = Math.round(
      (typeof rest.displayValue === 'number' ? rest.displayValue : primary) *
        100
    );

    return (
      <Wrapper className={className} data-cy="split-indicator">
        <Column>
          <Amounts
            percentage={percentage}
            selfLabel="You would receive"
            otherLabel={`${MatterProps('other.firstname')} would receive`}
          />
          <StyledRow justifyContent="center" margin="24px 0">
            <Indicator
              ref={ref}
              size={40}
              primary={primary}
              secondary={secondary}
              {...rest}
            />
          </StyledRow>
          <StyledAssetPoolRow display="block" alignItems="flex-start">
            Total asset pool:{' '}
            <strong data-cy="indicator-tap">
              {formatCurrency(totalAssetPool)}
            </strong>
          </StyledAssetPoolRow>
        </Column>
      </Wrapper>
    );
  }
);

SplitIndicator.propTypes = {
  ...Indicator.propTypes,
  displayValue: PropTypes.number,
  textColour: PropTypes.string,
  totalAssetPool: PropTypes.number.isRequired,
  indicatorColour: PropTypes.string,
  secondaryIndicatorColour: PropTypes.string,
};

SplitIndicator.defaultProps = {
  textColour: undefined,
  indicatorColour: defaultTheme.colours.orange,
  secondaryIndicatorColour: defaultTheme.colours.darkPurple,
  displayValue: undefined,
};

export default SplitIndicator;
