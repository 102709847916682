import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Period } from './Period';
import Row from './Row';
import Day from './Day';

const Week = ({
  weekNumber,
  weekData,
  onClick,
  viewOnly,
  isConfirmScreen,
  context: { screen },
}) => {
  const ref = useRef(null);
  const [periods, setPeriods] = useState([]);

  useEffect(() => {
    // builds an array that contains the start and end day for each period, plus who owns the period
    const getPeriodEnds = () => {
      const ends = [];
      let lastStart;
      let last;

      weekData.forEach((day, dayNumber) => {
        if (dayNumber === 0) {
          lastStart = 0;
        } else if (day !== last) {
          ends.push({ start: lastStart, end: dayNumber - 1, self: last });
          lastStart = dayNumber;
        }

        if (dayNumber === 6) {
          ends.push({ start: lastStart, end: 6, self: last });
        }

        last = day;
      });

      return ends;
    };
    if (ref.current) {
      const ends = getPeriodEnds();
      const newPeriods = [];

      // loops through our period ends array and gets the start and width for each period
      ends.forEach(({ start, end, self }, index) => {
        const startX = ref.current.children[start].offsetLeft;
        const width =
          ref.current.children[end].offsetLeft +
          ref.current.children[end].offsetWidth -
          startX;

        newPeriods.push({ self, start: startX, width });
      });

      setPeriods(newPeriods);
    }
  }, [weekData]);

  return (
    <Row ref={ref} data-cy={`scheduler-row-${weekNumber}`}>
      {weekData.map((day, dayNumber) => (
        <Day
          key={dayNumber}
          self={day}
          onClick={() => (!viewOnly ? onClick(weekNumber, dayNumber) : null)}
          screen={screen}
          viewOnly={viewOnly}
          isConfirmScreen={isConfirmScreen}
          data-cy={`scheduler-day-${dayNumber}`}
        />
      ))}
      {periods.map((period, index) => (
        <Period
          self={period.self}
          visible={viewOnly}
          start={period.start}
          width={period.width}
          key={index}
        />
      ))}
    </Row>
  );
};

Week.propTypes = {
  weekNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  weekData: PropTypes.array.isRequired,
  context: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool,
};

Week.defaultProps = {
  viewOnly: false,
};

export default Week;
