import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconArrowRight from 'Common/Assets/images/icon-arrow-right-transparent.svg';
import { Column } from 'Common/UI/Layout/Flex';

const HeaderImage = styled.img`
  width: 100%;
`;

const CardCompletedMeter = styled.div`
  width: ${({ percentage }) => percentage || 2}%;
  background-color: ${({ theme, colour }) => colour || theme.progressBarColour};
  display: block;
  height: 4px;
`;

const StyledSectionCardWrapper = styled(Column).attrs(() => ({
  alignItems: 'left',
}))`
  margin-top: ${({ theme }) => theme.padding.small}px;
  margin-bottom: ${({ theme }) => theme.padding.large}px;
  background: ${({ theme }) => theme.colours.white};
  border-radius: 4px;
  overflow: hidden;
`;

const Header = styled.div`
  flex: 0;
  position: relative;
`;

const Content = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.padding.medium}px;

  & > *:first-child {
    margin-top: 0;
  }
`;

const Footer = styled.div`
  flex: 0;
  padding: ${({ theme }) => theme.padding.medium}px;
`;

const ArrowIcon = styled.img`
  position: absolute;
  top: ${({ theme }) => theme.padding.small}px;
  right: ${({ theme }) => theme.padding.small}px;
  width: 24px;
  height: 24px;
`;

const SectionCardWrapper = ({
  headerImage,
  route,
  progress,
  footer,
  hideProgressBar,
  children,
  withHeaderArrow,
  dataCy,
}) => (
  <StyledSectionCardWrapper data-cy={dataCy}>
    {headerImage && (
      <Header>
        {route ? (
          <Link to={route}>
            {withHeaderArrow && <ArrowIcon src={IconArrowRight} />}
            <HeaderImage src={headerImage} alt="" />
          </Link>
        ) : (
          <>
            {withHeaderArrow && <ArrowIcon src={IconArrowRight} />}
            <HeaderImage src={headerImage} alt="" />
          </>
        )}
      </Header>
    )}
    <Content>{children}</Content>
    <Footer>{footer}</Footer>
    {!hideProgressBar && <CardCompletedMeter percentage={progress} />}
  </StyledSectionCardWrapper>
);

SectionCardWrapper.propTypes = {
  headerImage: PropTypes.string,
  route: PropTypes.string,
  progress: PropTypes.number,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  hideProgressBar: PropTypes.bool,
  withHeaderArrow: PropTypes.bool,
  dataCy: PropTypes.string,
};

SectionCardWrapper.defaultProps = {
  headerImage: undefined,
  progress: undefined,
  hideProgressBar: false,
  route: undefined,
  withHeaderArrow: true,
  dataCy: undefined,
};

export default SectionCardWrapper;
