import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import {
  ODRS_DASH_HOME_ROUTE,
  HOME_ROUTE,
  ODRS_PASSWORDRESET_ROUTE,
  ODRS_START_ROUTE,
} from 'Common/routes';
import * as Sentry from '@sentry/browser';

import MainDrawer from 'Common/UI/Layout/MainDrawer';
import colours from 'Common/Utils/colours';
import defaultTheme from 'Common/Utils/theme';
import Loader from 'Common/UI/Loader/Loader';
import { TopMessageDrawer } from 'Common/UI/Layout/Drawer';
import { Heading1, Heading4 } from 'Common/UI/Text/Headings';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import ResponsiveButtonContainer from 'Common/UI/Layout/ResponsiveButtonContainer';
import GenericButton from 'Common/UI/Button/GenericButton';
import TextButton from 'Common/UI/Button/TextButton';
import Textfield from 'Common/UI/Form/Textfield';
import Passwordfield from 'Common/UI/Form/Passwordfield';
import CheckboxField from 'Common/UI/Form/CheckboxField';
import Actions from 'Common/UI/Actions';
import {
  authenticateUserAction,
  rememberDeviceForUserAction,
  sendMFAcodeAction,
  forgetDeviceForUserAction,
  resendVerificationAction,
  verifyUserAction,
} from 'App/State/UserActions';
import {
  loadMatterAction,
  recoverMatterPostSignupAction,
} from 'App/State/MatterActions';
import isEmailAddress from 'Common/Utils/isEmailAddress';
import NotEmptyString from 'Common/Utils/NotEmptyString';
import LoginHeader from './LoginHeader';

const LoginHeading = styled(Heading1)`
  font-size: ${props => props.theme.typography.headings.h2.fontSize};
`;

const BodyContent = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  display: inline-flex;
  flex-direction: column;
  z-index: 1;

  input[type='email'],
  input[type='password'] {
    background-color: ${({ theme }) => theme.colours.offWhite};
  }
`;

const StyledPasswordfield = styled(Passwordfield)`
  max-width: 400px;
`;

const DrawerContent = styled.div`
  flex: 1;
`;

const StyledTextfield = styled(Textfield)`
  margin-bottom: 48px;
  max-width: 400px;
`;

const EnterCodeWrap = styled.div`
  padding-top: 12 px;
`;

const LoginButton = styled(GenericButton)`
  margin-top: 48px !important;
`;

const ResetResendButton = styled(TextButton)`
  text-align: left;
  padding-left: 0;
  color: ${props => props.theme.colours.purple};
`;

const StyledCallout = styled(Callout)`
  line-height: 160%;
  margin-left: ${({ theme }) => theme.padding.xxxsmall}px;
`;

// Keeping track of distinct states the login screen can get into.
const LOGIN_STATE_LOGIN = 'LOGIN_STATE_LOGIN';
const LOGIN_STATE_PASSWORD_ERROR = 'LOGIN_STATE_PASSWORD_ERROR';

const LOGIN_STATE_CODE_SENT = 'LOGIN_STATE_CODE_SENT';
const LOGIN_STATE_CODE_RESENT = 'LOGIN_STATE_CODE_RESENT';
const LOGIN_STATE_AUTHCODE_ERROR = 'LOGIN_STATE_AUTHCODE_ERROR';

const LOGIN_STATE_NOT_FOUND_ERROR = 'LOGIN_STATE_NOT_FOUND_ERROR';
const LOGIN_STATE_NOT_CONFIRMED_ERROR = 'LOGIN_STATE_NOT_CONFIRMED_ERROR';

// message or error(if any) to display for each state
const messageForState = state =>
  ({
    LOGIN_STATE_PASSWORD_ERROR: (
      <>
        <Heading4 data-cy="login-error">
          Incorrect username or password.
        </Heading4>
        <Paragraph>
          Looks like these details are not correct. Please try again or{' '}
          <Link to={ODRS_START_ROUTE}>sign up for an account</Link>.
        </Paragraph>
      </>
    ),
    LOGIN_STATE_NOT_FOUND_ERROR: (
      <>
        <Heading4 data-cy="login-error">
          Incorrect username or password.
        </Heading4>
        <Paragraph>
          Looks like these details are not correct. Please try again or{' '}
          <Link to={ODRS_START_ROUTE}>sign up for an account</Link>.
        </Paragraph>
      </>
    ),
    LOGIN_STATE_NOT_CONFIRMED_ERROR: (
      <>
        <Heading4 data-cy="login-error">
          You have not verified your account.
        </Heading4>
        <Paragraph>Please check your mobile for a verification code.</Paragraph>
      </>
    ),
    LOGIN_STATE_CODE_SENT: (
      <>
        <Heading4>Check your phone</Heading4>
        <Paragraph>We have sent you a text with a code to login.</Paragraph>
      </>
    ),
    LOGIN_STATE_AUTHCODE_ERROR: (
      <>
        <Heading4 data-cy="login-error">Something went wrong</Heading4>
        <Paragraph>Please check the code you entered.</Paragraph>
      </>
    ),
    LOGIN_STATE_RESET_SENT: (
      <>
        <Heading4>Your password has been updated</Heading4>
        <Paragraph>Please log in</Paragraph>
      </>
    ),
  }[state]);

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationError: null,
      error: false,
      loginState: LOGIN_STATE_LOGIN,
      remember: false,
      isLoading: false,
    };

    this.doLogin = this.doLogin.bind(this);
    this.handleAuthError = this.handleAuthError.bind(this);
    this.handleMFA = this.handleMFA.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  handleAuthError(err) {
    const { resendVerification } = this.props;
    const { email = '' } = this.state;

    this.setState({ isLoading: false });

    const errorsToSkipLogging = [
      'Need verification code.',
      'Incorrect username or password.',
      'Invalid code or auth state for the user.',
      'User is not confirmed.',
    ];

    if (!errorsToSkipLogging.includes(err.message)) {
      // this should never happen
      Sentry.withScope(scope => {
        scope.setUser({ providedEmail: email });
        Sentry.captureException(err.message);
      });
    }

    if (err.code === 'NotAuthorizedException') {
      this.setState({ loginState: LOGIN_STATE_NOT_FOUND_ERROR, password: '' });
    } else if (err.code === 'CodeMismatchException') {
      this.setState({ loginState: LOGIN_STATE_AUTHCODE_ERROR });
    } else if (err.message === 'User is not confirmed.') {
      resendVerification();
      this.setState({ loginState: LOGIN_STATE_NOT_CONFIRMED_ERROR });
    } else if (err.message === 'Need verification code.') {
      this.setState({ loginState: LOGIN_STATE_CODE_SENT });
    } else {
      this.setState({ loginState: LOGIN_STATE_PASSWORD_ERROR, password: '' });
    }

    return Promise.resolve();
  }

  handleMFA(response, remember) {
    const { rememberDeviceForUser, forgetDeviceForUser } = this.props;

    if (response === 'SMS_MFA') {
      const error = new Error('Need verification code.');
      throw error;
    }

    if (remember) {
      return rememberDeviceForUser();
    }
    return forgetDeviceForUser();
  }

  doLogin(e) {
    const {
      navigate,
      authenticateUser,
      loadMatter,
      sendMFAcode,
      recoverMatterPostSignup,
      verifyUser,
    } = this.props;

    const { loginState, remember } = this.state;

    this.setState({ isLoading: true });

    e.preventDefault();

    if (
      loginState === LOGIN_STATE_CODE_SENT ||
      loginState === LOGIN_STATE_AUTHCODE_ERROR
    ) {
      sendMFAcode(this.state)
        .then(response => this.handleMFA(response, remember))
        .then(() => loadMatter())
        .then(() => navigate(ODRS_DASH_HOME_ROUTE))
        .catch(err => {
          if (err.message === '404') {
            // No matter yet.
            return recoverMatterPostSignup(this.state).then(() =>
              navigate(ODRS_DASH_HOME_ROUTE)
            );
          }
          return this.handleAuthError(err);
        });
    } else if (loginState === LOGIN_STATE_NOT_CONFIRMED_ERROR) {
      // User has attempted to login where they have not confirmed their
      // account at signup time. This means we are asking them for a
      // verification code now, then having to create the matter as it won't exist yet.
      verifyUser(this.state)
        .then(() => authenticateUser(this.state))
        .then(response => this.handleMFA(response, remember))
        .then(() => loadMatter())
        .then(() => navigate(ODRS_DASH_HOME_ROUTE))
        .catch(err => {
          if (err.message === '404') {
            // No matter yet.
            return recoverMatterPostSignup(this.state).then(() =>
              navigate(ODRS_DASH_HOME_ROUTE)
            );
          }
          return this.handleAuthError(err);
        });
    } else {
      authenticateUser(this.state)
        .then(response => this.handleMFA(response, remember))
        .then(() => loadMatter())
        .then(() => navigate(ODRS_DASH_HOME_ROUTE))
        .catch(err => {
          if (err.message === '404') {
            // No matter yet.
            return recoverMatterPostSignup(this.state).then(() =>
              navigate(ODRS_DASH_HOME_ROUTE)
            );
          }
          return this.handleAuthError(err);
        });
    }
  }

  render() {
    const { email, verification, password, loginState, remember, isLoading } =
      this.state;

    const { navigate, authenticateUser } = this.props;

    return (
      <>
        <LoginHeader showLogo toOnClose={HOME_ROUTE}>
          <LoginHeading>Login</LoginHeading>
        </LoginHeader>

        <BodyContent className="transition-fade-down">
          <TopMessageDrawer open={messageForState(loginState)}>
            {messageForState(loginState)}
          </TopMessageDrawer>
          <MainDrawer colour={colours.white}>
            <ResponsiveContainer>
              <ThemeProvider theme={defaultTheme}>
                <DrawerContent>
                  <form onSubmit={e => this.doLogin(e)}>
                    <ResponsiveTextContainer>
                      {loginState !== LOGIN_STATE_CODE_SENT &&
                        loginState !== LOGIN_STATE_AUTHCODE_ERROR &&
                        loginState !== LOGIN_STATE_NOT_CONFIRMED_ERROR &&
                        loginState !== LOGIN_STATE_CODE_RESENT && (
                          <>
                            <StyledTextfield
                              value={email}
                              name="email"
                              label="Your email"
                              type="email"
                              placeholder="Enter your email"
                              onChange={updateValue =>
                                this.onChange('email', updateValue)
                              }
                            />

                            <StyledPasswordfield
                              value={password}
                              name="password"
                              label="Your password"
                              placeholder="Password"
                              onChange={this.onChange}
                              onBlur={() => {}}
                            />

                            <ResetResendButton
                              type="button"
                              onClick={e => {
                                e.preventDefault();
                                navigate(ODRS_PASSWORDRESET_ROUTE);
                              }}
                            >
                              Reset my password
                            </ResetResendButton>
                          </>
                        )}
                      {(loginState === LOGIN_STATE_CODE_SENT ||
                        loginState === LOGIN_STATE_CODE_RESENT ||
                        loginState === LOGIN_STATE_AUTHCODE_ERROR ||
                        loginState === LOGIN_STATE_NOT_CONFIRMED_ERROR) && (
                        <EnterCodeWrap>
                          <Textfield
                            value={verification}
                            name="code"
                            label="6 Digit Verification code"
                            placeholder=""
                            onChange={updateValue =>
                              this.onChange('verification', updateValue)
                            }
                          />
                          <ResetResendButton
                            type="button"
                            onClick={() => {
                              authenticateUser(this.state);
                            }}
                          >
                            Resend code
                          </ResetResendButton>
                        </EnterCodeWrap>
                      )}
                    </ResponsiveTextContainer>
                    <CheckboxField
                      value={remember}
                      name="remember"
                      label={
                        <StyledCallout>
                          Remember this device (we won&apos;t send you a
                          verification code the next time you login.)
                        </StyledCallout>
                      }
                      onChange={updateValue =>
                        this.onChange('remember', updateValue)
                      }
                    />
                    <ResponsiveButtonContainer>
                      <Actions noPadding>
                        <LoginButton
                          data-cy="login-button"
                          id="login-button"
                          type="submit"
                          onClick={() => {}}
                          disabled={
                            !isEmailAddress(email) ||
                            !NotEmptyString(password ? password.password : '')
                          }
                        >
                          Login
                        </LoginButton>
                      </Actions>
                    </ResponsiveButtonContainer>
                  </form>
                </DrawerContent>
              </ThemeProvider>
            </ResponsiveContainer>
          </MainDrawer>
          {isLoading && <Loader floating />}
        </BodyContent>
      </>
    );
  }
}

Login.propTypes = {
  navigate: PropTypes.func.isRequired,
  authenticateUser: PropTypes.func.isRequired,
  rememberDeviceForUser: PropTypes.func.isRequired,
  resendVerification: PropTypes.func.isRequired,
  forgetDeviceForUser: PropTypes.func.isRequired,
  sendMFAcode: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
  loadMatter: PropTypes.func.isRequired,
  recoverMatterPostSignup: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
  authenticateUser: userProps =>
    dispatch(authenticateUserAction(userProps)).unwrap(),
  resendVerification: () => dispatch(resendVerificationAction()),
  rememberDeviceForUser: () => dispatch(rememberDeviceForUserAction()),
  forgetDeviceForUser: () => dispatch(forgetDeviceForUserAction()),
  sendMFAcode: userProps => dispatch(sendMFAcodeAction(userProps)),
  verifyUser: userProps => dispatch(verifyUserAction(userProps)),
  loadMatter: () => dispatch(loadMatterAction()).unwrap(),
  recoverMatterPostSignup: () =>
    dispatch(recoverMatterPostSignupAction()).unwrap(),
});

export default connect(null, mapDispatchToProps)(Login);
