import getDocumentExportStatus from 'Common/Utils/getDocumentExportStatus';
import {
  areParentingPlanAssetsAllAccountedFor,
  hasValidSuperannuationForConsentOrders,
} from 'App/Utils/matterHelpers';
import AssetProps from 'Common/Data/App/assetProperties';
import MatterProps from 'Common/Utils/MatterProps';

export const partyFlagConsentOrders = 'canStartConsentOrders';
export const partyFlagConsentOrdersNotReady = 'notReadyToStartConsentOrders';

export const currentStatusConsentOrders = () => {
  const { isAssetSplitCompleted } = AssetProps();
  return getDocumentExportStatus({
    warningFlag: !hasValidSuperannuationForConsentOrders(),
    canExport: isAssetSplitCompleted,
    exportFlag: MatterProps(`self.${partyFlagConsentOrders}`, false),
    otherExportFlag: MatterProps(`other.${partyFlagConsentOrders}`, false),
    notReadyFlag: MatterProps(`self.${partyFlagConsentOrdersNotReady}`, false),
    otherNotReadyFlag: MatterProps(
      `other.${partyFlagConsentOrdersNotReady}`,
      false
    ),
  });
};

export const partyFlagExportProperty = 'canExportProperty';
export const partyFlagExportPropertyNotReady = 'notReadyToExportProperty';
export const currentStatusProperty = () => {
  const { isAssetSplitCompleted } = AssetProps();
  return getDocumentExportStatus({
    canExport: isAssetSplitCompleted,
    exportFlag: MatterProps(`self.${partyFlagExportProperty}`, false),
    otherExportFlag: MatterProps(`other.${partyFlagExportProperty}`, false),
    notReadyFlag: MatterProps(`self.${partyFlagExportPropertyNotReady}`, false),
    otherNotReadyFlag: MatterProps(
      `other.${partyFlagExportPropertyNotReady}`,
      false
    ),
  });
};

export const partyFlagExportParenting = 'canExportParenting';
export const partyFlagExportParentingNotReady = 'notReadyToExportParenting';
export const currentStatusParenting = () =>
  getDocumentExportStatus({
    canExport: areParentingPlanAssetsAllAccountedFor(),
    exportFlag: MatterProps(`self.${partyFlagExportParenting}`, false),
    otherExportFlag: MatterProps(`other.${partyFlagExportParenting}`, false),
    notReadyFlag: MatterProps(
      `self.${partyFlagExportParentingNotReady}`,
      false
    ),
    otherNotReadyFlag: MatterProps(
      `other.${partyFlagExportParentingNotReady}`,
      false
    ),
  });
