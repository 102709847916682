import React from 'react';
import { Switch } from 'react-router-dom';

import {
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_DIVISION_SPLIT_INTRO_ROUTE,
  ODRS_DIVISION_AGREE_ROUTE,
  ODRS_DIVISION_MESSAGE_ROUTE,
  ODRS_DIVISION_MESSAGE_SENT_ROUTE,
  ODRS_DIVISION_AGREE_WITH_OTHER_ROUTE,
  ODRS_DIVISION_VIEW_SUGGESTED,
  ODRS_DIVISION_ASSETS_INFO_INTRO,
  ODRS_DIVISION_ASSETS_INFO,
  ODRS_DIVISION_ASSETS_TRANSFER_JOINT,
  ODRS_DIVISION_ASSETS_TRANSFER_SELF,
  ODRS_DIVISION_ASSETS_TRANSFER_OTHER,
  ODRS_DIVISION_ASSETS_CONFIRM,
  ODRS_DIVISION_ASSETS_SENT,
  ODRS_DIVISION_ASSETS_TRANSFER_JOINT_PROPERTY,
  ODRS_DIVISION_ASSETS_MESSAGING,
  ODRS_DIVISION_ASSETS_VIEW_OFFER,
  ODRS_DIVISION_ASSETS_AGREED,
  ODRS_DIVISION_ASSETS_ALREADY_STARTED,
} from 'Common/routes';
import theme, { blackTheme, blueGreyMobileTheme } from 'Common/Utils/theme';

import DiviRoute from 'Common/UI/DiviRoute';
import DivisionSplitIntro from 'Common/UI/Division/DivisionIntroPage';
import DivisionAgreeWithDivi from 'Common/UI/Division/DivisionAgreeWithDivi';
import DivisionAgreeWithOther from 'Common/UI/Division/DivisionAgreeWithOther';
import DivisionMessaging from 'Common/UI/Division/DivisionMessaging';
import DivisionMessagingSent from 'Common/UI/Division/DivisionMessagingSent';
import AssetTransferSlider from 'Common/UI/Division/AssetTransfer/AssetTransferSlider';
import DivisionAssetsAlreadyStarted from 'Common/UI/Division/AssetTransfer/DivisionAssetsAlreadyStarted';
import DivisionView from 'Common/UI/Division/DivisionView';
import DivisionSuggested from 'Common/UI/Division/DivisionSuggested';
import DivisionRedirecter from 'Common/UI/Division/DivisionRedirecter';
import DivisionAssetsInfo from 'Common/UI/Division/AssetTransfer/DivisionAssetsInfo';
import DivisionAssetsTransferJoint from 'Common/UI/Division/AssetTransfer/DivisionAssetsTransferJoint';
import DivisionAssetsTransferSelf from 'Common/UI/Division/AssetTransfer/DivisionAssetsTransferSelf';
import DivisionAssetsTransferOther from 'Common/UI/Division/AssetTransfer/DivisionAssetsTransferOther';
import DivisionAssetsConfirm from 'Common/UI/Division/AssetTransfer/DivisionAssetsConfirm';
import DivisionAssetsSentTransfer from 'Common/UI/Division/AssetTransfer/DivisionAssetsSentTransfer';
import DivisionAssetsTransferJointProperty from 'Common/UI/Division/AssetTransfer/DivisionAssetsTransferJointProperty';
import DivisionAssetsMessaging from 'Common/UI/Division/AssetTransfer/DivisionAssetsMessaging';
import DivisionAssetsViewOffer from 'Common/UI/Division/AssetTransfer/DivisionAssetsViewOffer';
import DivisionAssetsSuccess from 'Common/UI/Division/AssetTransfer/DivisionAssetsSuccess';
import MatterGuard from '../Guard/MatterGuard';

const ODRSDivision = () => (
  <MatterGuard>
    <Switch>
      <DiviRoute
        theme={blueGreyMobileTheme}
        exact
        path={ODRS_DIVISION_SPLIT_INTRO_ROUTE}
        component={DivisionSplitIntro}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_VIEW_SUGGESTED}
        component={DivisionSuggested}
        theme={blackTheme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_SPLIT_VIEW_ROUTE}
        component={DivisionView}
        theme={blackTheme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_AGREE_ROUTE}
        component={DivisionAgreeWithDivi}
        theme={blackTheme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_AGREE_WITH_OTHER_ROUTE}
        component={DivisionAgreeWithOther}
        theme={blackTheme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_MESSAGE_ROUTE}
        component={DivisionMessaging}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_MESSAGE_SENT_ROUTE}
        component={DivisionMessagingSent}
        theme={blackTheme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_INFO_INTRO}
        component={AssetTransferSlider}
        theme={blueGreyMobileTheme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_INFO}
        component={DivisionAssetsInfo}
        theme={theme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_VIEW_OFFER}
        component={DivisionAssetsViewOffer}
        theme={theme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_TRANSFER_JOINT}
        component={DivisionAssetsTransferJoint}
        theme={theme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_TRANSFER_JOINT_PROPERTY}
        component={DivisionAssetsTransferJointProperty}
        theme={theme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_TRANSFER_SELF}
        component={DivisionAssetsTransferSelf}
        theme={theme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_TRANSFER_OTHER}
        component={DivisionAssetsTransferOther}
        theme={theme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_CONFIRM}
        component={DivisionAssetsConfirm}
        theme={theme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_MESSAGING}
        component={DivisionAssetsMessaging}
        theme={theme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_SENT}
        component={DivisionAssetsSentTransfer}
        theme={blackTheme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_AGREED}
        component={DivisionAssetsSuccess}
        theme={blackTheme}
      />
      <DiviRoute
        exact
        path={ODRS_DIVISION_ASSETS_ALREADY_STARTED}
        component={DivisionAssetsAlreadyStarted}
        theme={blackTheme}
      />

      <DiviRoute component={DivisionRedirecter} />
    </Switch>
  </MatterGuard>
);

export default ODRSDivision;
