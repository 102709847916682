import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import MatterProps from 'Common/Utils/MatterProps';
import getNames from 'Common/Utils/getNames';
import AssetProps from 'Common/Data/App/assetProperties';
import {
  confirmAssetTransferAction,
  transferAssetAction,
} from 'App/State/MatterActions';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import {
  ODRS_DIVISION_ASSETS_TRANSFER_SELF,
  ODRS_DIVISION_ASSETS_AGREED,
  ODRS_DIVISION_ASSETS_INFO,
} from 'Common/routes';
import PageContainer from 'Common/UI/Layout/PageContainer';
import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import Dialog from 'Common/UI/Layout/Dialog';
import { blackTheme } from 'Common/Utils/theme';
import Loader from 'Common/UI/Loader/Loader';
import { Redirect } from 'react-router';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import DialogConfirmAssetTransfer from '../Dialog/DialogConfirmAssetTransfer';
import SelectedAssetsList from '../Components/SelectedAssetsList';
import { AssetSplitPageContent, TransferFooter } from './common';
import TransferIntro from '../Components/TransferIntro';
import { DivisionAssetsProvider } from './DivisionAssetsContext';
import Header from './Header';
import SuperannuationInfoMessage from '../Components/SuperannuationInfoMessage';

const StyledSelectedAssetsList = styled(SelectedAssetsList)`
  background: ${props => props.theme.colours.offWhite};
  padding: 0 ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
`;

const FixedFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const DivisionAssetsViewOffer = ({
  confirmAssetTransfer,
  startCounterOffer,
  navigate,
}) => {
  const [dialog, setDialog] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    lastCompletedOffer,
    lastSentOfferBy,
    remainingAmountToTransfer,
    getSelectedItems,
    isAssetSplitCompleted,
    isOfferInProgress,
    absoluteRemainingAmountToTransfer,
    isOwingParty,
    isSuperIncludedInOffer,
  } = AssetProps();

  const names = getNames();
  const party = getPartyKeys();

  useEffect(() => {
    if (!isAssetSplitCompleted) {
      setTimeout(() => {
        setDialog('DialogSendToOther');
      }, 1000);
    }
  }, []);

  const handleConfirmAssetTransfer = () => {
    setIsLoading(true);

    return confirmAssetTransfer(remainingAmountToTransfer)
      .then(() => setIsLoading(false))
      .then(() => navigate(ODRS_DIVISION_ASSETS_AGREED));
  };

  // copy assets from previous round in to new round
  const handleStartCounterOffer = () => {
    setIsLoading(true);

    const selectedAssets = getSelectedItems(lastCompletedOffer)
      .filter(item => item.id !== 'shortfall') // remove the shortfall as that is added when an offer is sent
      .map(item => ({ ...item, remove: false })); // make Joi validation happy

    return startCounterOffer(selectedAssets)
      .then(() => setIsLoading(false))
      .then(() => navigate(ODRS_DIVISION_ASSETS_TRANSFER_SELF));
  };

  const showConfirmDialog = () => {
    setDialog('DialogConfirmAssetTransfer');
  };

  const shouldShowAcceptDialog =
    !isAssetSplitCompleted &&
    lastSentOfferBy === MatterProps('other.party') &&
    !isOfferInProgress;
  const shouldShowNextFooter =
    !isAssetSplitCompleted &&
    lastSentOfferBy === MatterProps('other.party') &&
    isOfferInProgress;

  // No current offer, redirect back to assets
  if (!isAssetSplitCompleted && !lastCompletedOffer) {
    return <Redirect to={ODRS_DIVISION_ASSETS_INFO} />;
  }

  return (
    <DivisionAssetsProvider>
      <PageContainer>
        <Header tab="viewOffer" />
        <TransferIntro hideOptionalRequired>
          {!isAssetSplitCompleted && lastSentOfferBy === party.other && (
            <Paragraph large>
              {names.other} has suggested to divide your assets by doing the
              following:
            </Paragraph>
          )}
          {!isAssetSplitCompleted && lastSentOfferBy === party.self && (
            <Paragraph large>
              You have suggested to divide your assets by doing the following:
            </Paragraph>
          )}
          {isAssetSplitCompleted && (
            <Paragraph large>
              You and {names.other} have agreed to split your assets by doing
              the following:
            </Paragraph>
          )}
        </TransferIntro>

        <StyledSelectedAssetsList
          withMessage={!isAssetSplitCompleted}
          offer={lastCompletedOffer}
        />

        <AssetSplitPageContent bottomPadding={shouldShowAcceptDialog} />

        {shouldShowNextFooter && (
          <TransferFooter
            nextRoute={ODRS_DIVISION_ASSETS_TRANSFER_SELF}
            buttonLabel="Next: Your assets"
            remainingAmountToTransfer={remainingAmountToTransfer}
            absoluteRemainingAmountToTransfer={
              absoluteRemainingAmountToTransfer
            }
            isAssetSplitCompleted={isAssetSplitCompleted}
            isOwingParty={isOwingParty}
          />
        )}

        {shouldShowAcceptDialog && (
          <FixedFooter>
            <Dialog
              theme={blackTheme}
              showDialog={dialog === 'DialogSendToOther'}
              content={
                <>
                  <Heading3>
                    How would you like to respond to {names.other}?
                  </Heading3>
                  <Paragraph>
                    Your asset division will only be included in the property
                    agreement document if you both agree to it.
                  </Paragraph>
                </>
              }
              buttons={
                <>
                  <GenericButton
                    fullWidth
                    onClick={showConfirmDialog}
                    data-cy="button-accept-offer"
                  >
                    Accept {names.other}
                    &apos;s proposal
                  </GenericButton>
                  <br />
                  <GenericButton
                    fullWidth
                    subtle
                    onClick={handleStartCounterOffer}
                    data-cy="button-make-own-proposal"
                  >
                    Make your own proposal
                  </GenericButton>
                </>
              }
              verticalButtons
            />
            <DialogConfirmAssetTransfer
              showDialog={dialog === 'DialogConfirmAssetTransfer'}
              onConfirm={handleConfirmAssetTransfer}
              onCancel={() => setDialog('DialogSendToOther')}
            />
          </FixedFooter>
        )}
      </PageContainer>
      {isLoading && <Loader floating />}
    </DivisionAssetsProvider>
  );
};

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
  confirmAssetTransfer: () => dispatch(confirmAssetTransferAction()),
  startCounterOffer: previousAssets =>
    dispatch(transferAssetAction(previousAssets)),
});

DivisionAssetsViewOffer.propTypes = {
  navigate: PropTypes.func.isRequired,
  confirmAssetTransfer: PropTypes.func.isRequired,
  startCounterOffer: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(DivisionAssetsViewOffer);
