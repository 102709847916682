import React from 'react';
import Loadable from 'react-loadable';

import Loader from 'Common/UI/Loader/Loader';
import IconClose from 'Common/Assets/images/icon-close-lightgrey.svg';
import { CloseButton, FullyScrollable } from '../../Static/common';

const LoadablePrivacy = Loadable({
  loader: () => import('../../Static/Privacy'),
  loading: () => (
    <div>
      <Loader />
    </div>
  ),
});

const ODRSPrivacy = () => (
  <>
    <CloseButton
      onClick={() => {
        window.close();
      }}
    >
      <img src={IconClose} alt="Close icon" />
    </CloseButton>
    <FullyScrollable>
      <LoadablePrivacy />
    </FullyScrollable>
  </>
);

export default ODRSPrivacy;
