import React, { useState, useEffect } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';

import MatterPropsJS from 'Common/Utils/MatterProps';
import { useAppDispatch } from 'App/State/Store';
import Matter from 'Common/Data/Types/matter';
import { dataExportTheme, Theme } from 'Common/Utils/theme';
import Loader from 'Common/UI/Loader/Loader';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import { H2Conversational } from 'Common/UI/Text/Headings';
import { exportEmailAction } from 'App/State/MatterActions';
import buildExport from 'Common/Utils/exportBuilder';

import iconMail from 'Common/Assets/images/icon-mail.svg';

const EXPORT_INIT = 'EXPORT_INIT';
const EXPORT_UNVERIFIED = 'EXPORT_UNVERIFIED';
const EXPORT_UNSENT = 'EXPORT_UNSENT';
const EXPORT_SENDING = 'EXPORT_SENDING';
const EXPORT_ERROR = 'EXPORT_ERROR';
const EXPORT_SENT = 'EXPORT_SENT';

const Wrapper = styled.div<{
  theme: Theme;
}>`
  background-color: ${props => props.theme.backgroundColour};
  ${props =>
    props.theme.backgroundImage &&
    css`
      background-image: url(${props.theme.backgroundImage});
      background-repeat: no-repeat;
    `}
  ${props =>
    props.theme.backgroundPosition &&
    css`
      background-position: ${props.theme.backgroundPosition};
    `}
  padding: ${props => props.theme.padding.medium}px;
  border-radius: 4px;
`;

const StyledExportButton = styled(GenericButton)`
  margin-top: ${props => props.theme.padding.medium}px;

  img {
    margin-left: ${props => props.theme.padding.medium2}px;
  }
`;

/**
 * Heading
 * */

const DataExportHeading: React.FC<{ children?: React.ReactNode }> = ({
  children = 'Exporting your progress',
}) => <H2Conversational>{children}</H2Conversational>;

type Props = {
  matter: Matter;
  emailNotVerifiedContent?: JSX.Element;
  readyToExportContent?: JSX.Element;
  heading?: string;
  userAttributes: Record<string, any>;
};

const DataExport: React.FC<Props> = ({
  matter,
  emailNotVerifiedContent = null,
  readyToExportContent = null,
  heading = 'Exporting your progress',
  userAttributes,
}) => {
  const dispatch = useAppDispatch();

  const exportEmail = (content: string[]) =>
    dispatch(exportEmailAction(content));

  const [exportState, setExportState] = useState(EXPORT_INIT);
  // Need to check if the user has a verified email address before
  // we let them export / send anything to that email.
  useEffect(() => {
    if (userAttributes.email_verified === 'false') {
      setExportState(EXPORT_UNVERIFIED);
    } else {
      setExportState(EXPORT_UNSENT);
    }
  }, [userAttributes]);

  /**
   * Button
   * */

  const ExportButton: React.FC<{ children?: React.ReactNode }> = ({
    children: buttonText = 'Export Progress',
  }) => {
    const handleExport = () => {
      setExportState(EXPORT_SENDING);
      exportEmail(buildExport(matter))
        .then(() => {
          setExportState(EXPORT_SENT);
        })
        .catch(() => {
          setExportState(EXPORT_ERROR);
        });
    };

    const isButtonDisabled = () =>
      [EXPORT_SENDING, EXPORT_UNVERIFIED, EXPORT_INIT].indexOf(exportState) !==
      -1;

    return (
      <StyledExportButton disabled={isButtonDisabled()} onClick={handleExport}>
        {buttonText}{' '}
        <img
          style={{ verticalAlign: 'middle' }}
          alt=""
          aria-hidden
          src={iconMail}
        />
      </StyledExportButton>
    );
  };

  /**
   * State Switch
   * */

  const { default: theme, resendEmail } = dataExportTheme;

  switch (exportState) {
    case EXPORT_SENT:
      return (
        <ThemeProvider theme={resendEmail}>
          <Wrapper>
            <DataExportHeading>Check your email</DataExportHeading>
            <Paragraph>
              We have sent an email to <b>{MatterPropsJS('self.email')}</b> with
              a summary of your progress so far.
            </Paragraph>
            <ExportButton>Resend email</ExportButton>
          </Wrapper>
        </ThemeProvider>
      );
    case EXPORT_SENDING:
      return (
        <ThemeProvider theme={theme}>
          <Wrapper>
            <Loader floating />
            <DataExportHeading>Sending</DataExportHeading>
            <Paragraph>Sending your summary of your progress so far</Paragraph>
            <ExportButton />
          </Wrapper>
        </ThemeProvider>
      );
    case EXPORT_UNVERIFIED:
      return (
        <ThemeProvider theme={theme}>
          <Wrapper>
            <DataExportHeading>{heading}</DataExportHeading>
            {emailNotVerifiedContent || (
              <Paragraph>
                <em>
                  You need to verify your email address above before you can
                  export your progress.
                </em>
              </Paragraph>
            )}
            <ExportButton />
          </Wrapper>
        </ThemeProvider>
      );
    case EXPORT_UNSENT:
      return (
        <ThemeProvider theme={theme}>
          <Wrapper>
            <DataExportHeading>{heading}</DataExportHeading>
            {readyToExportContent || (
              <Paragraph>
                You can export your progress on amica whenever you like. It will
                be sent to you via email. The email does not comprise an
                agreement document. The email will have more helpful
                information. If you can&apos;t reach agreement using amica, you
                can download the information that each of you entered, and use
                it to help you move forward with your separation.
              </Paragraph>
            )}
            <ExportButton />
          </Wrapper>
        </ThemeProvider>
      );
    case EXPORT_ERROR:
      return (
        <ThemeProvider theme={theme}>
          <Wrapper>
            <DataExportHeading>{heading}</DataExportHeading>
            <ExportButton />
            <Paragraph>
              <em>
                There was a problem exporting your data. Please try again later.
              </em>
            </Paragraph>
          </Wrapper>
        </ThemeProvider>
      );
    case EXPORT_INIT:
    default:
      return (
        <ThemeProvider theme={theme}>
          <Wrapper>
            <DataExportHeading>{heading}</DataExportHeading>
            <ExportButton />
            <Paragraph>
              <em>Exporting...</em>
            </Paragraph>
          </Wrapper>
        </ThemeProvider>
      );
  }
};

export default DataExport;
