import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import getNames from 'Common/Utils/getNames';
import AssetProps from 'Common/Data/App/assetProperties';
import {
  ODRS_DIVISION_ASSETS_SENT,
  ODRS_DIVISION_ASSETS_CONFIRM,
} from 'Common/routes';
import { Heading3 } from 'Common/UI/Text/Headings';
import Loader from 'Common/UI/Loader/Loader';
import CloseButton from 'Common/UI/Button/CloseButton';
import { sendAssetSplitOfferAction } from 'App/State/MatterActions';
import push from 'Common/Utils/push';
import Messaging from '../Components/Messaging';
import {
  DetailsViewWrapper,
  DetailsViewHeader,
  BackButton,
} from '../../DetailsView';
import { SCREEN_STATE_ACTION } from '../constants';

const StyledDetailsViewHeader = styled(DetailsViewHeader)`
  border-bottom: 0;
  flex: 0;
  min-height: 72px;
  display: flex;
  background-color: ${props => props.theme.colours.offWhite};

  ${Heading3} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const FlexWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.colours.white};
  display: inline-flex;
  flex: 1;
  flex-direction: column;
`;

const DivisionAssetsMessaging = ({ sendAssetSplitOffer, navigate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { remainingAmountToTransfer } = AssetProps();

  const names = getNames();

  const handleSendAssetSplitOffer = message => {
    setIsLoading(true);

    return sendAssetSplitOffer({
      message,
      shortfall: remainingAmountToTransfer,
    })
      .then(() => {
        navigate(ODRS_DIVISION_ASSETS_SENT);
      })
      .finally(() => setIsLoading(false));
  };

  const back = () => navigate(ODRS_DIVISION_ASSETS_CONFIRM);

  return (
    <DetailsViewWrapper>
      <StyledDetailsViewHeader state={SCREEN_STATE_ACTION}>
        <BackButton state={SCREEN_STATE_ACTION} type="back" onClick={back} />
        <Heading3>Your reasoning</Heading3>
        <CloseButton state={SCREEN_STATE_ACTION} onClick={back} />
      </StyledDetailsViewHeader>
      <FlexWrapper>
        <Messaging
          onSubmit={handleSendAssetSplitOffer}
          onCancel={back}
          inputCaption={`Please explain to ${names.other} why you have proposed to split the assets in this way`}
        />
      </FlexWrapper>
      {isLoading && <Loader floating />}
    </DetailsViewWrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  sendAssetSplitOffer: content => dispatch(sendAssetSplitOfferAction(content)),
  navigate: to => dispatch(push(to)),
});

DivisionAssetsMessaging.propTypes = {
  sendAssetSplitOffer: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(DivisionAssetsMessaging);
