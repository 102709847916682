import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPage } from 'Common/Data/App/appSections';
import {
  CARD_NORMAL,
  CARD_STATEMENT,
  CARD_SUBHEADING,
  SECTION_PARENTING,
  PAGE_PARENTING_SCHEDULE,
  CARD_CUSTOM,
} from 'Common/constants';
import { ODRS_PARENTING_SCHEDULE_ROUTE } from 'Common/routes';
import { generateSubHeading } from 'Common/UI/Dashboard/common';

import {
  generateStatementCard,
  generateNormalCard,
  generateCustomCard,
} from 'App/UI/Dashboard/Parenting/StateCards';
import DashboardContent from 'App/UI/Dashboard/common/DashboardContent';
import { DashboardPageProps } from 'Common/Data/Types/types';
import {
  CardCustom,
  CardNormal,
  CardStatement,
  QuestionStateCard,
} from 'Common/Data/Types/appSections';

const Schedule: React.FC<DashboardPageProps> = ({
  matter,
  navigate,
  appState,
}) => {
  const location = useLocation();

  const customNavigate = (route: string) =>
    navigate(route, { backRoute: ODRS_PARENTING_SCHEDULE_ROUTE, location });

  const schedule = getPage(SECTION_PARENTING, PAGE_PARENTING_SCHEDULE);

  return (
    <DashboardContent
      pageData={schedule}
      navigate={customNavigate}
      matter={matter}
      cardHandlers={{
        [CARD_NORMAL]: data =>
          generateNormalCard(data as QuestionStateCard<CardNormal>, matter),
        [CARD_CUSTOM]: data =>
          generateCustomCard(data as QuestionStateCard<CardCustom>, matter),
        [CARD_STATEMENT]: data =>
          generateStatementCard(
            data as QuestionStateCard<CardStatement>,
            matter
          ),
        [CARD_SUBHEADING]: data => generateSubHeading(data),
      }}
      appState={appState}
    />
  );
};

export default Schedule;
