import {
  AGREEMENT_WARNING,
  AGREEMENT_INCOMPLETE,
  AGREEMENT_READY_TO_EXPORT,
  AGREEMENT_OTHER_NOT_READY,
  AGREEMENT_CREATED,
  AGREEMENT_WAITING_FOR_OTHER,
  AGREEMENT_NEEDS_CONFIRMATION,
} from 'Common/constants';

// generate the export status based on app and party state
const getDocumentExportStatus = ({
  warningFlag = false,
  canExport,
  exportFlag,
  otherExportFlag,
  notReadyFlag,
  otherNotReadyFlag,
}) => {
  if (warningFlag) {
    return AGREEMENT_WARNING;
  }

  // requirements to export not met yet
  if (!canExport) {
    return AGREEMENT_INCOMPLETE;
  }

  // self has clicked `I'm not ready`
  if (notReadyFlag) {
    return AGREEMENT_READY_TO_EXPORT;
  }

  // self is ready but other party has said no
  if (exportFlag && otherNotReadyFlag) {
    return AGREEMENT_OTHER_NOT_READY;
  }

  // both parties have agreed to export
  if (exportFlag && otherExportFlag) {
    return AGREEMENT_CREATED;
  }

  // only self has agreed to export
  if (exportFlag && !otherExportFlag) {
    return AGREEMENT_WAITING_FOR_OTHER;
  }

  // only other has agreed to export
  if (!exportFlag && otherExportFlag) {
    return AGREEMENT_NEEDS_CONFIRMATION;
  }

  // all cards have been completed etc but neither party has started the document flow yet
  return AGREEMENT_READY_TO_EXPORT;
};

export default getDocumentExportStatus;
