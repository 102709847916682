import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  BANNER_HIDE,
  BANNER_SHOW,
  STATUS_APPROVED,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_ACCEPTED_PAYER,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import AssetProperties from 'Common/Data/App/assetProperties';
import { getMatter } from 'Common/Utils/MatterProps';

export const superannuationConsentOrderChangesAcceptedPayerFlags: AppState = {
  id: 'superannuationConsentOrderChangesAcceptedPayer',
  description:
    'Notify payer that the payee has accepted the superannuation clause changes, CO is now finalised',
  check: () => {
    const { isSuperIncludedInOffer, superSplittingFlags, isPayer } =
      AssetProperties();

    const { payerHasConfirmedNoChangesToClauses } = superSplittingFlags;

    if (
      !isSuperIncludedInOffer ||
      !isPayer ||
      payerHasConfirmedNoChangesToClauses
    ) {
      return false;
    }

    const { items = [] } = getMatter();

    const superannuationItem = items.find(
      item => item.SectionID === 'superannuationfundclauses'
    );

    if (!superannuationItem || superannuationItem.status !== STATUS_APPROVED) {
      return false;
    }

    return true;
  },
  flags: {
    appState: SUPER_SPLITTING_CONSENT_ORDER_CHANGES_ACCEPTED_PAYER,
    bannerTopState: () =>
      sessionStorageLoad(
        'superannuationConsentOrderChangesAcceptedPayerDismissed'
      )
        ? BANNER_HIDE
        : BANNER_SHOW,
  },
};

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperannuationConsentOrderChangesAcceptedPayer: React.FC<Props> =
  ({ otherName, updateAppState }) => (
    <div data-cy="banner-consent-order-changes-accepted-payer">
      <SpacedBox marginBottom={8}>
        <Heading2MarginTopNone>
          {otherName} has agreed to the updates to your draft minutes of consent
        </Heading2MarginTopNone>
        <Paragraph>
          You are now ready to file your application for consent orders.
        </Paragraph>
      </SpacedBox>
      <GenericButton
        fullWidth
        onClick={() => {
          sessionStorageSave(
            `superannuationConsentOrderChangesAcceptedPayerDismissed`,
            true
          );
          updateAppState();
        }}
        small
        data-cy="dismiss-button"
      >
        Got it
      </GenericButton>
    </div>
  );
