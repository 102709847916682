import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ODRS_PARENTING_ESSENTIALS_ROUTE } from 'Common/routes';
import Carousel from 'Common/UI/Carousel';
import Loader from 'Common/UI/Loader/Loader';
import { CarouselButton } from 'Common/UI/Carousel/ImageSlideWrapper';
import { setPartyFlagsAction } from 'App/State/MatterActions';

import ParentingPlanSlide1 from './ParentingPlanSlide1';
import ParentingPlanSlide2 from './ParentingPlanSlide2';
import ParentingPlanSlide3 from './ParentingPlanSlide3';

const ParentingPlanIntroCarousel = ({
  navigate,
  setHasSeenParentingPlanIntro,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    setHasSeenParentingPlanIntro()
      .then(() => {
        navigate(ODRS_PARENTING_ESSENTIALS_ROUTE);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const LastSlideControls = () => (
    <CarouselButton onClick={handleClick} data-cy="carousel-finish-button">
      I understand
    </CarouselButton>
  );

  return (
    <>
      <Carousel
        LastSlideControls={LastSlideControls}
        onClose={() => navigate(ODRS_PARENTING_ESSENTIALS_ROUTE)}
      >
        <ParentingPlanSlide1 />
        <ParentingPlanSlide2 />
        <ParentingPlanSlide3 />
      </Carousel>

      {loading && <Loader floating />}
    </>
  );
};

ParentingPlanIntroCarousel.propTypes = {
  navigate: PropTypes.func.isRequired,
  setHasSeenParentingPlanIntro: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
  setHasSeenParentingPlanIntro: () =>
    dispatch(setPartyFlagsAction({ hasSeenParentingPlanIntro: true })),
});

export default connect(null, mapDispatchToProps)(ParentingPlanIntroCarousel);
