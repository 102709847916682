import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { animated } from 'react-spring/web.cjs';

import { isConsentOrdersQuestionLocation } from 'Common/Data/App/appSections';
import defaultTheme, { greenQuestionTheme } from 'Common/Utils/theme';
import { RouteContext } from './RouteContext';

const StyledRouteWrapper = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 5;
  background-size: cover;
  color: ${props => props.theme.textColour};
  ${({ theme }) =>
    theme.backgroundImage &&
    `
    background-image: url(${theme.backgroundImage});
    background-repeat: no-repeat;
  `};
  background-color: ${props => props.theme.backgroundColour};
`;

const RouteWrapper = ({ children, location, ...rest }) => {
  const { theme = defaultTheme } = useContext(RouteContext);
  let pageTheme = theme;

  if (isConsentOrdersQuestionLocation(location)) {
    pageTheme = greenQuestionTheme;
  }

  return (
    <ThemeProvider theme={pageTheme}>
      <StyledRouteWrapper {...rest}>{children}</StyledRouteWrapper>
    </ThemeProvider>
  );
};

RouteWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default RouteWrapper;
