import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { APP_ID } from 'App/config';

ReactModal.setAppElement(`#${APP_ID}`);

const Modal = ({ isOpen, closeModal, children }) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={{
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 1000,
      },
      content: {
        position: 'absolute',
        border: 'none',
        background: '#fff',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '2px',
        outline: 'none',
        padding: '0',
        width: 'calc(100vw - 48px)',
        height: 'calc(100vh - 48px)',
        maxWidth: '400px',
        maxHeight: '600px',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
      },
    }}
  >
    {children}
  </ReactModal>
);

export const ModalPropTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

Modal.propTypes = {
  ...ModalPropTypes,
  children: PropTypes.node.isRequired,
};

export default Modal;
