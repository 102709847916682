import { Link } from 'react-router-dom';

import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { blackTheme } from 'Common/Utils/theme';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import LogoNLA from 'Common/Assets/images/logo-nla.png';
import {
  ODRS_DASH_HOME_ROUTE,
  ODRS_PROFILE_ROUTE,
  ODRS_PAYMENT_VIEW_PRICING_ROUTE,
  ODRS_DOCUMENTS_ROUTE,
} from 'Common/routes';
import AmicaLogo from 'App/UI/AmicaLogo';
import Paragraph from 'Common/UI/Text/Paragraph';

const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.altBackgroundColour};
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  padding-top: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  color: ${({ theme }) => theme.textColour};

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 48px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 24px;
  }

  @media ${({ theme }) => theme.sizes.xl.breakpoint} {
    grid-row-gap: 128px;
  }
`;

const Acknowledgement = styled.div`
  order: 2;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    grid-column: 1 / span 6;
    grid-row-start: 1;
    order: initial;
  }

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    grid-column: 1 / span 4;
  }
`;

const Menu = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  order: 4;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    grid-column: 8 / span 5;
    grid-row-start: 1;
    order: initial;
  }

  a {
    display: block;
    color: ${({ theme }) => theme.textColour};
    margin-bottom: ${({ theme }) => theme.padding.medium}px;
  }
`;

const LeftMenu = styled.div`
  grid-column-start: 1;
`;

const RightMenu = styled.div`
  grid-column-start: 2;
`;

const Amica = styled.div`
  order: 1;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    grid-column: 1 / span 8;
    grid-row-start: 2;
    order: initial;
    align-self: center;
  }

  a {
    color: ${({ theme }) => theme.textColour};
  }
`;

const NationalLegalAid = styled.div`
  order: 2;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    grid-column: 8 / span 5;
    grid-row-start: 2;
    order: initial;
    align-self: center;
  }

  @media ${({ theme }) => theme.sizes.lg.breakpoint} {
    grid-column: 8 / span 4;
  }
`;

const AmicaLogoWrapper = styled.div`
  width: 85px;
  margin-bottom: ${({ theme }) => theme.padding.medium}px;
  color: ${({ theme }) => theme.colours.lightPurple};
`;

const NLALogo = styled.img`
  display: block;
  margin-bottom: ${({ theme }) => theme.padding.medium}px;
`;

const AppFooter = () => (
  <ThemeProvider theme={blackTheme}>
    <Wrapper>
      <Acknowledgement>
        <Paragraph>
          National Legal Aid acknowledges the Traditional Custodians of country
          throughout Australia and their connections to land, sea and community.
          We pay our respect to their elders past and present and extend that
          respect to all Aboriginal and Torres Strait Islander peoples today.
        </Paragraph>
      </Acknowledgement>
      <Menu>
        <LeftMenu>
          <Link to={ODRS_DASH_HOME_ROUTE}>Home</Link>
          <Link to={ODRS_DOCUMENTS_ROUTE}>Documents</Link>
          <Link to={ODRS_PROFILE_ROUTE}>Account</Link>
          <Link to={ODRS_PAYMENT_VIEW_PRICING_ROUTE}>Payment</Link>
        </LeftMenu>
        <RightMenu>
          <ReferralLink href="https://amica.gov.au" showExternalIcon>
            About amica
          </ReferralLink>
          <ReferralLink
            href="https://amica.gov.au/help-and-support.html"
            showExternalIcon
          >
            Help and support
          </ReferralLink>
        </RightMenu>
      </Menu>

      <Amica>
        <AmicaLogoWrapper>
          <AmicaLogo />
        </AmicaLogoWrapper>
        <ReferralLink href="https://amica.gov.au">
          www.amica.gov.au
        </ReferralLink>
      </Amica>

      <NationalLegalAid>
        <NLALogo src={LogoNLA} alt="" />
        amica is an online service provided by <br />
        National&nbsp;Legal&nbsp;Aid
      </NationalLegalAid>
    </Wrapper>
  </ThemeProvider>
);

export default AppFooter;
