import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FormLabel from 'Common/UI/Form/FormLabel';
import Input from './Input';

const StyledSelect = styled(Input).attrs({
  as: 'select',
})`
  min-width: 210px;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 12px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
`;

const FormGroup = styled.div``;

const FormGroupContainer = styled.div`
  ${FormGroup}:first-child {
    margin-bottom: ${props => props.theme.padding.medium}px;
  }
`;

// State
const states = {
  ACT: 'ACT',
  NT: 'NT',
  NSW: 'NSW',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
};

const defaultState = states.SA;

const StateCity = ({ onChange, value, disabled, name }) => {
  // City
  const selectedCity = value.city || '';
  const selectedState = value.state || defaultState;

  const onCityChange = event => {
    const newValue = !value.state
      ? { ...{ state: defaultState }, ...{ city: event.target.value } }
      : { ...value, ...{ city: event.target.value } };
    onChange(newValue);
  };

  const onStateChange = event => {
    const newValue = { ...value, ...{ state: event.target.value } };
    onChange(newValue);
  };

  return (
    <FormGroupContainer>
      <FormGroup>
        <FormLabel label="City" />
        <Input
          placeholder="Enter city"
          value={selectedCity}
          onChange={onCityChange}
          onBlur={onCityChange}
          disabled={disabled}
          id={`${name}_city`}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel label="State" />
        <StyledSelect
          value={selectedState}
          onChange={onStateChange}
          disabled={disabled}
          id={`${name}_state`}
        >
          {Object.keys(states).map(key => (
            <option key={states[key]} value={states[key]}>
              {states[key]}
            </option>
          ))}
        </StyledSelect>
      </FormGroup>
    </FormGroupContainer>
  );
};

StateCity.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
  }),
};

StateCity.defaultProps = {
  disabled: false,
  value: {},
};

export default StateCity;
