import styled, { css } from 'styled-components';

const Day = styled.div`
  width: ${props =>
    props.blank || (props.viewOnly && !props.isConfirmScreen)
      ? '24px'
      : '32px'};
  height: ${props =>
    props.blank || (props.viewOnly && !props.isConfirmScreen)
      ? '24px'
      : '32px'};
  border-radius: 50%;
  transition: all 0.5s;

  ${props =>
    !props.disabled &&
    css`
      border: 4px solid ${props.theme.colours.clickableGrey};

      ${!props.viewOnly &&
      !props.self &&
      `
      &:hover {
        background: ${props.theme.colours.lightPurple};
      }
    `}

      ${props.self &&
      css`
        background-color: ${props.self
          ? props.theme.colours.midLightPurple
          : 'none'};
      `}

    ${!props.self &&
      props.viewOnly &&
      !props.blank &&
      `background-color: ${props.theme.colours.aqua};`}

    ${props.viewOnly &&
      css`
        border-width: 0px;

        ${!props.self &&
        css`
          background-color: ${props.theme.colours.aqua};
        `}
      `}
    `}

  ${props =>
    props.disabled &&
    css`
      background: ${props.theme.colours.offWhite};
    `}

  ${props =>
    props.blank &&
    css`
      background-color: ${props.isActive
        ? props.theme.colours.lighterPurple
        : props.theme.colours.midLightGrey};
    `}
`;

export default Day;
