import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSHandover = props => (
  <MatterGuard>
    <CardQuestions baseID="handovers" {...props} />
  </MatterGuard>
);

export default ODRSHandover;
