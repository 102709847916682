import React from 'react';

import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  getFirstName,
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
} from 'Common/Utils/statementHelpers';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';

export const yourContributionsQuestions: QuestionAny[] = [
  {
    name: 'sameFinancialContributions',
    label: 'Were the financial contributions of the parties the same?',
    hint: '(see s79(4)(a) or if a de facto relationship s90SM(4)(a) of the Family Law Act)',
    type: 'yesno',
    index: 0,
    help: {
      label: 'I need more information',
      content: (
        <>
          <H1Conversational>I need more information</H1Conversational>
          <Paragraph large>
            This question is asking whether the financial contributions to the
            relationship were the same. That is, financial contributions to the
            relationship that were made by: you, or someone on your behalf, or
            someone on behalf of a child of the relationship ... towards
            acquiring, maintaining or improving any property of the relationship
            (whether it was owned jointly or individually). If no, give brief
            details.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'describeFinancialContributions',
    label:
      'Please describe how financial contributions to the relationship were not the same',
    type: 'freetext',
    hint: 'A brief description',
    placeholder: 'Type something...',
    index: 1,
    visible: answers => !answers.sameFinancialContributions,
  },
  {
    name: 'sameNonFinancialContributions',
    label:
      'Were the non-financial contributions from each of the parties the same?',
    hint: '(see s79(4)(b) or if a de facto relationship s90SM(4)(b) of the Family Law Act)',
    type: 'yesno',
    index: 2,
    help: {
      label: 'I need more information',
      content: (
        <>
          <H1Conversational>I need more information</H1Conversational>
          <Paragraph large>
            This question is asking whether the non-financial contributions to
            the relationship were the same. That is, non-financial contributions
            to the relationship that were made by: you, or someone on your
            behalf, or someone on behalf of a child of the relationship ...
            towards acquiring, maintaining or improving any property of the
            relationship (whether it was owned jointly or individually). If no,
            give brief details.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'describeNonFinancialContributions',
    label:
      'Please describe how the non-financial contributions to the relationship were not the same',
    type: 'freetext',
    hint: 'A brief description',
    placeholder: 'Type something...',
    index: 3,
    visible: answers => !answers.sameNonFinancialContributions,
  },
  {
    name: 'sameHomeMakerAndParentContributions',
    label:
      'Were the contributions from each of the parties as homemaker and parent the same?',
    hint: '(see s79(4)(c) or if a de facto relationship s90SM(4)(c) of the Family Law Act)',
    type: 'yesno',
    index: 4,
    help: {
      label: 'I need more information',
      content: (
        <>
          <H1Conversational>I need more information</H1Conversational>
          <Paragraph large>
            This question is asking whether contributions as a homemaker and or
            parent were the same. It is asking whether the contributions made by
            each of you towards the welfare of your family (including any
            children of the relationship), for example, as a parent or
            homemaker, were the same. If no, give brief details.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'describeHomeMakerAndParentContributions',
    label:
      'Please describe how contributions as a homemaker and/or parent were not the same',
    type: 'freetext',
    hint: 'A brief description',
    placeholder: 'Type something...',
    index: 5,
    visible: answers => !answers.sameHomeMakerAndParentContributions,
  },
  {
    name: 'anyOtherRelevantFacts',
    label:
      'Are there any other relevant matters or facts in relation to the division of the property (e.g. health, financial resources, income earning ability)?',
    hint: '(see s75(2) or if a de facto relationship s90SF(3) of the Family Law Act)',
    type: 'yesno',
    index: 6,
    help: {
      label: 'I need more information',
      content: (
        <>
          <H1Conversational>I need more information</H1Conversational>
          <Paragraph large>
            This question is asking if there are any other relevant matters in
            relation to the division of your property. For example, health
            issues, your ability to earn income in the future or your
            responsibilities to support other people (amongst other things).
            Give brief details.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'describeOtherRelevantFacts',
    label:
      'Please describe the relevant matters in relation to the division of your property',
    type: 'freetext',
    hint: 'A brief description',
    placeholder: 'Type something...',
    index: 7,
    visible: answers => !!answers.anyOtherRelevantFacts,
  },
  {
    name: 'statement',
    type: 'statement',
    index: 8,
    label: 'You created a statement',
  },
];

export const yourContributionsStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.creator || matter.self.party;
  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  const {
    sameFinancialContributions,
    describeFinancialContributions,
    sameNonFinancialContributions,
    describeNonFinancialContributions,
    sameHomeMakerAndParentContributions,
    describeHomeMakerAndParentContributions,
    anyOtherRelevantFacts,
    describeOtherRelevantFacts,
  } = data;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'describeFinancialContributions',
    'describeNonFinancialContributions',
    'describeHomeMakerAndParentContributions',
    'describeOtherRelevantFacts',
    'sameFinancialContributions',
    'sameNonFinancialContributions',
    'sameHomeMakerAndParentContributions',
    'anyOtherRelevantFacts',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );

  if (
    sameFinancialContributions &&
    sameNonFinancialContributions &&
    sameHomeMakerAndParentContributions &&
    !anyOtherRelevantFacts
  ) {
    const statement = highlight`${firstName} ${updated(
      [
        'sameFinancialContributions',
        'sameNonFinancialContributions',
        'sameHomeMakerAndParentContributions',
        'anyOtherRelevantFacts',
      ],
      'did not'
    )} give further details about their contributions to the relationship.`;

    if (plainText) {
      return statement;
    }

    return <>{statement}</>;
  }

  let statement = `${firstName} provided further details about their contributions to the relationship.`;
  const statementArray: React.ReactNode[] = [statement];

  if (describeFinancialContributions) {
    const s = highlight`${updated(
      'describeFinancialContributions',
      `${describeFinancialContributions}.`
    )}`;
    statement += s;
    statementArray.push(s);
  }

  if (describeNonFinancialContributions) {
    const s = highlight`${updated(
      'describeNonFinancialContributions',
      `${describeNonFinancialContributions}.`
    )}`;
    statement += s;
    statementArray.push(s);
  }

  if (describeHomeMakerAndParentContributions) {
    const s = highlight`${updated(
      'describeHomeMakerAndParentContributions',
      `${describeHomeMakerAndParentContributions}.`
    )}`;
    statement += s;
    statementArray.push(s);
  }

  if (describeOtherRelevantFacts) {
    const s = highlight`${updated(
      'describeOtherRelevantFacts',
      `${describeOtherRelevantFacts}.`
    )}`;
    statement += s;
    statementArray.push(s);
  }

  if (plainText) {
    return statement;
  }

  return (
    <>
      {statementArray.map((s, key) => (
        <p key={key}>{s}</p>
      ))}
    </>
  );
};
