import React from 'react';
import MatterGuard from '../Guard/MatterGuard';
import ODRSYourSuperannuationContinueVersionAware from './ODRSYourSuperannuationContinueVersionAware';

const ODRSYourSuperannuationContinue = props => (
  <MatterGuard>
    <ODRSYourSuperannuationContinueVersionAware {...props} />
  </MatterGuard>
);

export default ODRSYourSuperannuationContinue;
