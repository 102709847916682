import React from 'react';

import IconExternalLink from 'Common/Assets/images/icon-external-link.svg';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import IconTextButton from './IconTextButton';

type Props = {
  disabled?: boolean;
  href: string;
  children: React.ReactNode;
};

// Open Icon Button
const HelpExternalButton: React.FC<Props> = ({
  children,
  disabled = false,
  href,
  ...props
}) => (
  <IconTextButton
    disabled={disabled}
    help
    icon={IconExternalLink}
    as={ReferralLink}
    href={href}
    display="inline-flex"
    {...props}
  >
    {children}
  </IconTextButton>
);

export default HelpExternalButton;
