import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';

import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import { Heading2 } from 'Common/UI/Text/Headings';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import theme from 'Common/Utils/theme';
import { TwoButtonResponsiveGroup } from 'Common/UI/Button/ButtonGroup';
import { MediumButton } from 'Common/UI/Button/GenericButton';
import OptionsDrawerPortal from 'Common/UI/OptionsDrawer/OptionsDrawerPortal';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { hideDialog } from 'App/State/AppReducer';
import { removeSuperannuationAction } from 'App/State/MatterActions';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';
import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';
import { REMOVE_SUPERANNUATION_START_FLOW } from 'Common/constants';
import DialogRemoveSuperContent from './DialogRemoveSuperannuation.content';

const StyledHeading = styled(Heading2)`
  margin-top: 0;
`;

const StyledFlexDrawer = styled(FlexDrawer)`
  position: fixed;
`;

const DialogRemoveSuperannuation: React.FC = () => {
  const { withLoading } = usePromiseWithLoading();

  const dispatch = useAppDispatch();

  const closeDrawer = () => dispatch(hideDialog());

  const onRemove = () =>
    withLoading(async () => {
      await dispatch(
        removeSuperannuationAction(REMOVE_SUPERANNUATION_START_FLOW)
      );

      closeDrawer();
      dispatch(push(ODRS_PROPERTY_ASSET_DIVISION_ROUTE));
    });

  return (
    <OptionsDrawerPortal isOpen onBackgroundClick={closeDrawer}>
      <ThemeProvider theme={theme}>
        <StyledFlexDrawer isVisible>
          <FlexGrid align="center" medium={2}>
            <div>
              <StyledHeading>{DialogRemoveSuperContent.heading}</StyledHeading>
              <Paragraph>{DialogRemoveSuperContent.paragraph}</Paragraph>
            </div>
            <OffsetColumn>
              <TwoButtonResponsiveGroup>
                <MediumButton
                  onClick={onRemove}
                  data-cy="button-confirm-agree-accept"
                >
                  Remove
                </MediumButton>
                <MediumButton
                  subtle
                  onClick={closeDrawer}
                  data-cy="button-confirm-agree-cancel"
                >
                  Cancel
                </MediumButton>
              </TwoButtonResponsiveGroup>
            </OffsetColumn>
          </FlexGrid>
        </StyledFlexDrawer>
      </ThemeProvider>
    </OptionsDrawerPortal>
  );
};

export default DialogRemoveSuperannuation;
