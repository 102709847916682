import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSYourFinances = props => (
  <MatterGuard>
    <CardQuestions baseID="yourfinances" index="self" {...props} />
  </MatterGuard>
);

export default ODRSYourFinances;
