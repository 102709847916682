import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPage } from 'Common/Data/App/appSections';
import {
  SECTION_PROPERTY,
  PAGE_PROPERTY_ASSET_DIVISION,
  CARD_COMPONENT,
  CARD_NORMAL,
} from 'Common/constants';
import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';
import DashboardContent from 'App/UI/Dashboard/common/DashboardContent';
import {
  generateComponentCard,
  generateNormalCard,
} from 'App/UI/Dashboard/Property/StateCards';
import {
  CardComponent,
  CardNormal,
  QuestionStateCard,
  EphemeralStateCard,
} from 'Common/Data/Types/appSections';
import { DashboardPageProps } from 'Common/Data/Types/types';

const AssetDivision: React.FC<DashboardPageProps> = ({
  matter,
  navigate,
  appState,
}) => {
  const location = useLocation();

  const customNavigate = (route: string) =>
    navigate(route, {
      backRoute: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
      location,
    });

  const pageData = getPage(SECTION_PROPERTY, PAGE_PROPERTY_ASSET_DIVISION);

  return (
    <DashboardContent
      pageData={pageData}
      navigate={customNavigate}
      matter={matter}
      cardHandlers={{
        [CARD_COMPONENT]: data =>
          generateComponentCard(data as EphemeralStateCard<CardComponent>),
        [CARD_NORMAL]: data =>
          generateNormalCard(data as QuestionStateCard<CardNormal>, matter),
      }}
      appState={appState}
    />
  );
};

export default AssetDivision;
