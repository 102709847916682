import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  PROPERTY_DASHBOARD_COMPLETED,
  DashboardPages,
  PAGE_PROPERTY_COMPLETE,
  AGREEMENT_READY_TO_EXPORT,
} from 'Common/constants';
import AssetProps from 'Common/Data/App/assetProperties';

import GenericButton from 'Common/UI/Button/GenericButton';
import { AppState } from 'Common/Data/Types/appState';
import { ODRS_PROPERTY_COMPLETE_ROUTE } from 'Common/routes';
import {
  checkDocumentsHaveStatus,
  documentCheckKeys,
} from 'Common/Utils/documentHelpers';

export const propertyDashboardCompletedFlags: AppState = {
  id: 'settlementDashboardCompleted',
  description:
    'Property dashboard is completed including suggested division, asset split, and super splitting',
  check: () => {
    const { isAssetSplitCompleted } = AssetProps();

    const hasPropertyOrCODocGenerationStarted =
      checkDocumentsHaveStatus(AGREEMENT_READY_TO_EXPORT) !==
      documentCheckKeys.BOTH;

    return isAssetSplitCompleted && !hasPropertyOrCODocGenerationStarted;
  },
  flags: {
    appState: PROPERTY_DASHBOARD_COMPLETED,
  },
  usedAsNotificationState: true,
};

type Props = {
  page: DashboardPages;
  navigate: (route: string) => void;
};

const PropertyDashboardCompleted: React.FC<Props> = ({ page, navigate }) => {
  if (page === PAGE_PROPERTY_COMPLETE) {
    return (
      <>
        <Heading2MarginTopNone>
          You can now create an agreement document or court application
        </Heading2MarginTopNone>
        <Paragraph>
          You will both need to agree before you can create your document.
        </Paragraph>
      </>
    );
  }

  return (
    <>
      <Heading2MarginTopNone>
        Congratulations! You have finished the required sections
      </Heading2MarginTopNone>
      <Paragraph>
        You can now create an agreement document or court application. You will
        both need to agree before you can create your document. You can learn
        about and choose your documents.
      </Paragraph>
      <GenericButton
        fullWidth
        onClick={() => navigate(ODRS_PROPERTY_COMPLETE_ROUTE)}
        small
      >
        Select document
      </GenericButton>
    </>
  );
};

export default PropertyDashboardCompleted;
