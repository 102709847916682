import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';

import {
  BANNER_HIDE,
  SECTION_STATUS_COMPLETED,
  SECTION_RELATIONSHIP,
  SECTION_PROPERTY,
  SECTION_PARENTING,
  SECTION_HOME,
  SECTION_CONSENT_ORDERS,
  FLAG_MATTER_OPTED_IN,
} from 'Common/constants';
import { isPartyBSignedUp } from 'App/Utils/matterHelpers';
import getNames from 'Common/Utils/getNames';
import { getSectionStatus } from 'App/Utils/sectionHelpers';
import DiviProps from 'Common/Data/App/diviProperties';
import {
  setMatterFlagsAction,
  setPartyFlagsAction,
} from 'App/State/MatterActions';
import AppFooter from 'Common/UI/AppFooter/AppFooter';
import MainDrawer from 'Common/UI/Layout/MainDrawer';
import { isConsentOrdersAgreedToStart } from 'Common/Utils/consentOrders';
import Tooltip from 'Common/UI/Tooltip/Tooltip';
import FeedbackModal from 'Common/UI/Feedback/FeedbackModal';
import SectionCompletedTick from 'Common/Assets/images/section-completed-tick.svg';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import GenericButton from 'Common/UI/Button/GenericButton';
import PageContainer from 'Common/UI/Layout/PageContainer';
import ScrollerContainer from 'Common/UI/Layout/ScrollerContainer';
import baseTheme, { blackTheme } from 'Common/Utils/theme';
import Loader from 'Common/UI/Loader/Loader';
import { TopMessageDrawer } from 'Common/UI/Layout/Drawer';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import { Heading2 } from 'Common/UI/Text/Headings';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import Paragraph from 'Common/UI/Text/Paragraph';
import getBannerTheme from 'App/UI/Banner/getBannerTheme';
import Banner from 'App/UI/Banner';
import AppHeader from 'App/UI/AppHeader';

import { useAppDispatch, useAppSelector } from 'App/State/Store';
import { generateAppStateAction } from 'App/State/AppActions';
import AssetProperties from 'Common/Data/App/assetProperties';
import push from 'Common/Utils/push';
import {
  DashboardProvider,
  DashboardConsumer,
} from 'Common/UI/DashboardContext';

import { DrawerContent, HeaderContainer } from 'Common/UI/Dashboard/common';
import VerifyEmailReminder from 'Common/UI/Dashboard/common/VerifyEmailReminder';
import InviteYourFormerPartner from './InviteYourFormerPartner';
import SectionCard from './SectionCard/SectionCard';
import { WhatIsAStatement } from './WhatIsAStatement';

const StyledFlexGrid = styled(FlexGrid)`
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const SectionText = styled.div`
  margin-bottom: ${({ theme }) => theme.padding.xlarge}px;
  padding-left: ${({ theme }) => theme.spacing[12]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-left: 0;
  }
`;

const SectionWrapper = styled.div`
  position: relative;
  max-width: 720px;
  margin: 0 0 ${({ theme }) => theme.spacing[24]} 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-left: ${({ theme }) => theme.spacing[20]};
    margin: 0 0 ${({ theme }) => theme.spacing[32]} 0;
  }
`;

const SectionStepIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ theme, unlocked }) =>
    unlocked ? theme.colours.pastelPurple : theme.colours.inactiveGrey};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    width: 48px;
    height: 48px;
  }

  ${({ theme, completed, step, unlocked }) =>
    !completed &&
    `
    &:before {
      content: '${step}';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      color: ${unlocked ? theme.colours.purple : theme.colours.darkGrey};
      font-size: 19px;
      font-weight: bold;
    }

    @media ${theme.sizes.md.breakpoint} {
      &:before {
        font-size: 24px;
      }
    }
  `}

  ${props =>
    props.completed &&
    `
      content: '';
      background: transparent url('${SectionCompletedTick}') no-repeat top left;
      background-size: contain;
  `}
`;

const WhatIsAStatementButton = styled(GenericButton).attrs(() => ({
  subtle: true,
  small: true,
}))`
  margin-top: ${({ theme }) => theme.spacing[4]};
`;

const isSuggestedDivisionAndAssetSplitCompleted = () => {
  const { agreedToDivision } = DiviProps();

  const { isAssetSplitCompleted } = AssetProperties();

  return agreedToDivision && isAssetSplitCompleted;
};

const DashboardHome = ({
  navigate,
  setMatterFlags,
  setFeedbackupPopupDismissedFlag,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const matter = useAppSelector(state => state.matter);
  const newAppState = useAppSelector(state => state.app.appState);
  const dispatch = useAppDispatch();

  const updateAppState = () =>
    dispatch(generateAppStateAction({ matter, section: SECTION_HOME }));

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(true);

  // We load the app state whenever the matter gets updated
  useEffect(() => {
    dispatch(generateAppStateAction({ matter, section: SECTION_HOME }));
  }, [matter]);

  const { bannerTopState, appState } = newAppState;

  const relationshipStatusData = getSectionStatus({
    section: SECTION_RELATIONSHIP,
    customChecks: { optIn: () => false },
  });

  const isRelationshipSectionCompleted =
    relationshipStatusData.status === SECTION_STATUS_COMPLETED;

  const sections = {
    [SECTION_RELATIONSHIP]: relationshipStatusData,
    [SECTION_PROPERTY]: getSectionStatus({
      section: SECTION_PROPERTY,
      customChecks: {
        completed: () => isSuggestedDivisionAndAssetSplitCompleted(),
        locked: () => !isRelationshipSectionCompleted,
      },
    }),
    [SECTION_PARENTING]: getSectionStatus({
      section: SECTION_PARENTING,
      customChecks: { locked: () => !isRelationshipSectionCompleted },
    }),
    [SECTION_CONSENT_ORDERS]: getSectionStatus({
      section: SECTION_CONSENT_ORDERS,
      customChecks: {
        locked: () =>
          !isSuggestedDivisionAndAssetSplitCompleted() ||
          !isConsentOrdersAgreedToStart(),
      },
    }),
  };

  const isPropertySectionCompleted =
    sections[SECTION_PROPERTY].status === SECTION_STATUS_COMPLETED;
  const isParentingSectionCompleted =
    sections[SECTION_PARENTING].status === SECTION_STATUS_COMPLETED;
  const isConsentOrdersSectionCompleted =
    sections[SECTION_CONSENT_ORDERS].status === SECTION_STATUS_COMPLETED;

  const showTopBanner =
    typeof bannerTopState === 'function' ? bannerTopState() : bannerTopState;

  const { items = [], flags = {}, self = {} } = matter;
  const optedInFlags = flags[FLAG_MATTER_OPTED_IN];

  // all accounts start with a `setup` item which is why we're checking for greater than 1
  const headerTitle =
    items.length > 1
      ? `Welcome back, ${getNames().self}`
      : `Welcome to amica, ${getNames().self}`;

  const withLoading = promise => {
    setIsLoading(true);

    return promise.then(() => setIsLoading(false));
  };

  const { agreedToDivision } = DiviProps();

  const closeModal = () =>
    withLoading(
      setFeedbackupPopupDismissedFlag().then(() =>
        setIsFeedbackModalOpen(false)
      )
    );

  const StatementButton = (
    <LearnMoreButton
      popUpContent={WhatIsAStatement}
      popupButtonText="Got it"
      ui={WhatIsAStatementButton}
    >
      What is a statement?
    </LearnMoreButton>
  );

  return (
    <ThemeProvider theme={baseTheme}>
      <DashboardProvider appState={appState} updateAppState={updateAppState}>
        <DashboardConsumer>
          {() => (
            <PageContainer id="scrollMemory">
              <HeaderContainer className="transition-fade-up">
                <AppHeader
                  withProfile
                  matter={matter}
                  theme={blackTheme}
                  title={headerTitle}
                  canShowVerifyEmailNotification
                />
              </HeaderContainer>
              <VerifyEmailReminder />
              {showTopBanner !== BANNER_HIDE && (
                <TopMessageDrawer
                  hasFlex
                  open
                  zindex="1"
                  theme={getBannerTheme(appState)}
                >
                  <Banner
                    appState={appState}
                    updateAppState={updateAppState}
                    section={SECTION_HOME}
                    navigate={navigate}
                  />
                </TopMessageDrawer>
              )}
              <MainDrawer>
                <ScrollerContainer>
                  <ResponsiveContainer>
                    <DrawerContent>
                      {!isPartyBSignedUp(matter) && (
                        <StyledFlexGrid medium={2} large={2}>
                          <InviteYourFormerPartner navigate={navigate} />
                        </StyledFlexGrid>
                      )}

                      <SectionWrapper>
                        <SectionStepIndicator
                          step={1}
                          completed={isRelationshipSectionCompleted}
                          unlocked
                        />
                        <SectionText>
                          {isRelationshipSectionCompleted ? (
                            <Heading2>
                              About you and your relationship completed
                            </Heading2>
                          ) : (
                            <>
                              <Heading2>
                                Tell us about you and your relationship
                              </Heading2>
                              <ResponsiveTextContainer>
                                <Paragraph large>
                                  Answer questions about yourself to create
                                  statements for you and your former partner to
                                  agree on.
                                </Paragraph>
                              </ResponsiveTextContainer>

                              {StatementButton}
                            </>
                          )}
                        </SectionText>

                        <SectionCard.Relationship
                          status={sections.SECTION_RELATIONSHIP.status}
                          stats={sections.SECTION_RELATIONSHIP.stats}
                          progress={sections.SECTION_RELATIONSHIP.progress}
                        />
                      </SectionWrapper>

                      <SectionWrapper>
                        <SectionStepIndicator
                          step={2}
                          completed={
                            isPropertySectionCompleted &&
                            isParentingSectionCompleted
                          }
                          unlocked={isRelationshipSectionCompleted}
                        />
                        <SectionText>
                          {isPropertySectionCompleted &&
                          isParentingSectionCompleted ? (
                            <Heading2>
                              Money, property and parenting completed
                            </Heading2>
                          ) : (
                            <>
                              <Heading2>
                                Tell us about your money, property, and
                                parenting arrangements
                              </Heading2>
                              <ResponsiveTextContainer>
                                <Paragraph large>
                                  Agree on statements in order to create your
                                  property agreements and parenting
                                  arrangements.
                                </Paragraph>
                              </ResponsiveTextContainer>

                              {StatementButton}
                            </>
                          )}
                        </SectionText>

                        <SectionCard.Property
                          status={sections.SECTION_PROPERTY.status}
                          stats={sections.SECTION_PROPERTY.stats}
                          progress={sections.SECTION_PROPERTY.progress}
                          isSectionReadyToBeStarted={
                            isRelationshipSectionCompleted
                          }
                          toggleOptIn={value =>
                            withLoading(
                              setMatterFlags({
                                [FLAG_MATTER_OPTED_IN]: {
                                  ...optedInFlags,
                                  SECTION_PROPERTY: value,
                                },
                              })
                            )
                          }
                        />

                        <SectionCard.Parenting
                          status={sections.SECTION_PARENTING.status}
                          stats={sections.SECTION_PARENTING.stats}
                          progress={sections.SECTION_PARENTING.progress}
                          isSectionReadyToBeStarted={
                            isRelationshipSectionCompleted
                          }
                          toggleOptIn={value =>
                            withLoading(
                              setMatterFlags({
                                [FLAG_MATTER_OPTED_IN]: {
                                  ...optedInFlags,
                                  SECTION_PARENTING: value,
                                },
                              })
                            )
                          }
                        />
                      </SectionWrapper>

                      <SectionWrapper>
                        <SectionStepIndicator
                          step={3}
                          completed={isConsentOrdersSectionCompleted}
                          unlocked={isPropertySectionCompleted}
                        />
                        <SectionText>
                          {isConsentOrdersSectionCompleted ? (
                            <Heading2>Consent orders completed</Heading2>
                          ) : (
                            <>
                              <Heading2>
                                Create an application for consent orders
                              </Heading2>
                              <ResponsiveTextContainer>
                                <Paragraph large>
                                  Answer questions about your situation to
                                  create a document you can file in court
                                </Paragraph>
                              </ResponsiveTextContainer>

                              {StatementButton}
                            </>
                          )}
                        </SectionText>

                        <SectionCard.ConsentOrder
                          status={sections.SECTION_CONSENT_ORDERS.status}
                          stats={sections.SECTION_CONSENT_ORDERS.stats}
                          progress={sections.SECTION_CONSENT_ORDERS.progress}
                          isSectionReadyToBeStarted={isPropertySectionCompleted}
                          toggleOptIn={value =>
                            withLoading(
                              setMatterFlags({
                                [FLAG_MATTER_OPTED_IN]: {
                                  ...optedInFlags,
                                  SECTION_CONSENT_ORDERS: value,
                                },
                              })
                            )
                          }
                        />
                      </SectionWrapper>
                    </DrawerContent>
                  </ResponsiveContainer>
                </ScrollerContainer>
              </MainDrawer>
              <AppFooter />
              {isLoading && <Loader floating />}
              {(agreedToDivision || isParentingSectionCompleted) &&
                !self.feedbackPopupDismissed && (
                  <FeedbackModal
                    isOpen={isFeedbackModalOpen}
                    closeModal={closeModal}
                  />
                )}
            </PageContainer>
          )}
        </DashboardConsumer>
      </DashboardProvider>
      <Tooltip />
    </ThemeProvider>
  );
};

DashboardHome.propTypes = {
  matter: PropTypes.object, // eslint-disable-line
  navigate: PropTypes.func.isRequired,
  setMatterFlags: PropTypes.func.isRequired,
  setFeedbackupPopupDismissedFlag: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
  setMatterFlags: flags => dispatch(setMatterFlagsAction(flags)),
  setFeedbackupPopupDismissedFlag: () =>
    dispatch(setPartyFlagsAction({ feedbackPopupDismissed: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome);
