import React from 'react';
import styled from 'styled-components';

import MatterPropsJS from 'Common/Utils/MatterProps';
import { H2Conversational, Heading4 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  SlideInner,
  SlideText,
  SlideIcon,
} from 'Common/UI/Carousel/ImageSlideWrapper';

import DivisionImage01 from './division-image-1.svg';

const Confidentiality = styled.div`
  margin-top: ${props => props.theme.padding.xxxsmall}px;
  background: ${props => props.theme.colours.beige};
  border-radius: 4px;
  padding: ${props => props.theme.padding.small}px;

  ${Heading4} {
    margin: 0 0 ${props => props.theme.padding.medium}px;
  }

  ${Paragraph} {
    margin: 0;
  }

  ${Heading4}, ${Paragraph} {
    color: ${props => props.theme.colours.black};
    margin-bottom: 0;
  }
`;

export default function() {
  return <SlideInner>
    <SlideIcon className="slide-icon" backgroundImage={DivisionImage01}>
      <img alt="" src={DivisionImage01} srcSet={`${DivisionImage01} 2x`} />
    </SlideIcon>
    <SlideText data-cy="suggested-division-slide-1">
      <H2Conversational>
        amica has come up with a suggested division of money and property for
        you and {MatterPropsJS('other.firstname')}
      </H2Conversational>

      <Paragraph>
        This suggestion takes into account the length of the relationship, both
        party&apos;s age and health, how much you both earn, future needs, and
        how much you each contributed to the relationship — (in terms of
        financial and non-financial contributions).
      </Paragraph>
      <Confidentiality>
        <Heading4>CONFIDENTIALITY</Heading4>
        <Paragraph>
          Remember it is a term of using amica that you agreed not to disclose
          the information you and your former partner share on amica in any
          court proceedings, unless it is by consent. For example, if you both
          want to seek consent orders.
        </Paragraph>
      </Confidentiality>
    </SlideText>
  </SlideInner>
};
