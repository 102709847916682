import React from 'react';

import getNames from 'Common/Utils/getNames';
import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Sent } from 'Common/UI/Layout/Completed';

const DivisionAssetsSentTransfer = () => {
  const names = getNames();

  return (
    <Sent
      buttonLabel="Back to home"
      buttonRoute={ODRS_PROPERTY_ASSET_DIVISION_ROUTE}
    >
      <H1Conversational>
        Your proposed asset split has been sent
      </H1Conversational>
      <Paragraph large>
        {names.other} will view your proposal and either agree or send you a
        response.
      </Paragraph>
    </Sent>
  );
};

export default DivisionAssetsSentTransfer;
