import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useSpring, animated } from 'react-spring/web.cjs';

import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import appTheme, { orangeTheme } from 'Common/Utils/theme';
import { FlexWrapper, BubbleWrap, BubbleButton } from './BubbleNotification';

const BubbleLearnMoreButton = styled(BubbleButton)`
  background-color: ${props => props.theme.colours.beige};
  color: ${props => props.theme.black};
  margin-left: ${props => props.theme.padding.small}px;
`;

const ButtonWrap = styled.div`
  margin: 16px 0 0;
`;

const BubbleNotificationLearnMore = ({
  children,
  theme,
  button: {
    onClick: buttonOnClick,
    label: buttonLabel,
    style: buttonStyle,
  } = {},
  learnMore: { label: learnMoreText, popUpContent: learnMorePopUpContent } = {},
  dataCy,
}) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <FlexWrapper>
      <ThemeProvider theme={theme}>
        <BubbleWrap
          data-cy={dataCy}
          fullWidth
          borderRadius={appTheme.padding.xxxsmall}
          padding="0"
        >
          <animated.div style={props}>
            {children}
            <ButtonWrap>
              <BubbleButton
                data-cy={`${dataCy}-got-it-button`}
                onClick={buttonOnClick}
                buttonStyle={buttonStyle}
              >
                {buttonLabel || 'Got it'}
              </BubbleButton>
              {learnMorePopUpContent && (
                <LearnMoreButton
                  popUpContent={learnMorePopUpContent}
                  ui={BubbleLearnMoreButton}
                  help
                >
                  {learnMoreText || 'Learn more'}
                </LearnMoreButton>
              )}
            </ButtonWrap>
          </animated.div>
        </BubbleWrap>
      </ThemeProvider>
    </FlexWrapper>
  );
};

BubbleNotificationLearnMore.propTypes = {
  children: PropTypes.element.isRequired,
  theme: PropTypes.shape({}),
  button: PropTypes.shape({
    onClick: PropTypes.func,
    label: PropTypes.string,
    style: PropTypes.string,
  }),
  learnMore: PropTypes.shape({
    label: PropTypes.string,
    popUpContent: PropTypes.func,
  }),
  dataCy: PropTypes.string,
};

BubbleNotificationLearnMore.defaultProps = {
  button: { style: '', label: 'Got it' },
  theme: orangeTheme,
  learnMore: undefined,
  dataCy: undefined,
};

export default BubbleNotificationLearnMore;
