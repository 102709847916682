import { Theme } from 'Common/Utils/theme';
import styled from 'styled-components';

const QuestionFlowHeader = styled.div<{
  theme: Theme;
}>`
  margin: ${({ theme }) =>
    `${theme.spacing[8]} ${theme.spacing[6]} ${theme.spacing[8]} ${theme.spacing[6]}`};
  position: relative;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin: ${({ theme }) =>
      `${theme.spacing[16]} ${theme.spacing[16]} 0 ${theme.spacing[16]}`};
  }
`;

export default QuestionFlowHeader;
