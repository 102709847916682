import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import FeedbackBackground from 'Common/Assets/images/feedback-background-modal.svg';
import theme from 'Common/Utils/theme';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { Column } from 'Common/UI/Layout/Flex';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import TextButton from 'Common/UI/Button/TextButton';
import IconClose from 'Common/Assets/images/icon-close-black.svg';
import Modal, { ModalPropTypes } from '../Modal/Modal';

const StyledColumn = styled(Column)`
  height: 100%;
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
`;

const StyledHeading = styled(Heading2)`
  margin-top: 0;
`;

const StyledButton = styled(GenericButton)`
  margin-top: ${props => props.theme.spacing[6]};
`;

const Content = styled.div`
  text-align: left;
  width: 100%;
  padding: ${props => props.theme.spacing[6]};
  flex-grow: 1;
`;

const Footer = styled.div`
  text-align: left;
  width: 100%;
  padding: 24px;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const NoThanksButton = styled(TextButton)`
  margin-top: ${props => props.theme.padding.xsmall}px;
  img {
    position: relative;
    top: 3px;
    left: -16px;
    width: 18px;
    height: 18px;
  }
`;

const StyledReferralLink = styled(ReferralLink)`
  text-decoration: none;
  display: block;
  text-align: center;
`;

const FeedbackModal = ({ isOpen, closeModal }) => (
  <Modal isOpen={isOpen} closeModal={closeModal}>
    <ThemeProvider theme={theme}>
      <StyledColumn>
        <CloseButton src={IconClose} onClick={closeModal} />
        <HeaderImage src={FeedbackBackground} alt="" />
        <Content>
          <StyledHeading>Share your feedback with us</StyledHeading>

          <Paragraph large>
            Thanks for using amica. Help us improve this service by giving your
            feedback.
          </Paragraph>
        </Content>
        <Footer>
          <StyledButton
            as={StyledReferralLink}
            fullWidth
            target="_blank"
            href="https://www.surveymonkey.com/r/6HQ9GDG"
            onClick={closeModal}
          >
            Give feedback
          </StyledButton>
          <NoThanksButton onClick={closeModal}>
            <img src={IconClose} alt="" /> No thanks
          </NoThanksButton>
        </Footer>
      </StyledColumn>
    </ThemeProvider>
  </Modal>
);

FeedbackModal.propTypes = ModalPropTypes;

export default FeedbackModal;
