import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import { ParagraphNoMarginBottom } from 'Common/UI/Text/Paragraph';
import {
  RELATIONSHIP_DASHBOARD_NOT_COMPLETED,
  SECTION_RELATIONSHIP,
} from 'Common/constants';

import { areRelationshipAssetsAllAccountedFor } from 'App/Utils/matterHelpers';
import { AppState } from 'Common/Data/Types/appState';
import { getAppSections } from 'Common/Data/App/appSections';
import { areSectionCardsCompleted } from 'Common/Utils/cardHelpers';

export const relationshipDashboardNotCompletedFlags: AppState = {
  id: 'relationshipDashboardNotCompleted',
  description: 'Relationship dashboard not completed',
  check: () => !areRelationshipAssetsAllAccountedFor(),
  flags: {
    appState: RELATIONSHIP_DASHBOARD_NOT_COMPLETED,
  },
};

type Props = {
  dashboardName: string;
  otherName: string;
};

const RelationshipDashboardNotCompleted: React.FC<Props> = ({
  dashboardName,
  otherName,
}) => {
  // check if all of this users cards are completed or not - if they are but we've still triggered this app state then it means it is the other party that has not completed their cards
  const areAllMyCardsCompleted = areSectionCardsCompleted({
    section: SECTION_RELATIONSHIP,
    appSections: getAppSections(),
    checkBothParties: false,
  });

  return (
    <div data-cy="relationship-dashboard-not-completed-banner">
      {!areAllMyCardsCompleted && (
        <>
          <Heading2MarginTopNone>
            You will need to fill out the relationship section first
          </Heading2MarginTopNone>
          <ParagraphNoMarginBottom>
            You need to agree to all statements in the relationship section in
            order to complete the {dashboardName} section.
          </ParagraphNoMarginBottom>
        </>
      )}
      {areAllMyCardsCompleted && (
        <>
          <Heading2MarginTopNone>
            You will need to wait until {otherName} finishes the relationship
            section first
          </Heading2MarginTopNone>
          <ParagraphNoMarginBottom>
            You and {otherName} need to complete and agree to all statements in
            the relationship section in order to complete the {dashboardName}{' '}
            section.
          </ParagraphNoMarginBottom>
        </>
      )}
    </div>
  );
};

export const RelationshipDashboardNotCompletedProperty: React.FC<{
  otherName: string;
}> = ({ otherName }) => (
  <RelationshipDashboardNotCompleted
    dashboardName="property"
    otherName={otherName}
  />
);

export const RelationshipDashboardNotCompletedParenting: React.FC<{
  otherName: string;
}> = ({ otherName }) => (
  <RelationshipDashboardNotCompleted
    dashboardName="parenting"
    otherName={otherName}
  />
);
