import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF,
  REMOVE_SUPERANNUATION_REQUESTEE_APPROVE,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import AssetProperties from 'Common/Data/App/assetProperties';
import { useAppDispatch } from 'App/State/Store';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import {
  removeSuperannuationAction,
  setMatterFlagsAction,
} from 'App/State/MatterActions';
import getPartyKeys from 'Common/Utils/getPartyKeys';

export const superSplittingRemoveSuperannuationWaitingForSelfFlags: AppState = {
  id: 'superSplittingRemoveSuperannuationWaitingForSelf',
  description:
    'Other party has requested to remove superannuation and this party needs to confirm or deny',
  check: () => {
    const {
      isAssetSplitCompleted,
      isSuperIncludedInOffer,
      superSplittingFlags,
    } = AssetProperties();

    const {
      removeSuperannuationInProgress,
      removeSuperannuationRequestedBy,
      removeSuperannuationRequestStatus,
    } = superSplittingFlags;

    // if asset split isn't completed, super isn't included, or the remove super flow has not been started, then this app state is not relevant
    if (
      !isSuperIncludedInOffer ||
      !isAssetSplitCompleted ||
      !removeSuperannuationInProgress
    ) {
      return false;
    }

    const partyKeys = getPartyKeys();

    const otherRequestedRemoveSuper =
      removeSuperannuationRequestedBy === partyKeys.other;

    // other party requested to remove super and this party has not yet responded
    if (otherRequestedRemoveSuper && !removeSuperannuationRequestStatus) {
      return true;
    }

    return false;
  },
  flags: {
    appState: SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF,
  },
  usedAsNotificationState: true,
};

type Props = {
  otherName: string;
};

export const SuperSplittingRemoveSuperannuationWaitingForSelf: React.FC<Props> =
  ({ otherName }) => {
    const dispatch = useAppDispatch();
    const { withLoading } = usePromiseWithLoading();

    const { superSplittingFlags } = AssetProperties();

    const onAgree = () =>
      withLoading(async () => {
        await dispatch(
          removeSuperannuationAction(REMOVE_SUPERANNUATION_REQUESTEE_APPROVE)
        );
      });

    const onReject = () =>
      withLoading(async () => {
        await dispatch(
          setMatterFlagsAction({
            superSplitting: {
              ...superSplittingFlags,
              removeSuperannuationRequestStatus: 'REJECTED',
            },
          })
        );
      });

    return (
      <>
        <SpacedBox marginBottom={8}>
          <Heading2MarginTopNone>
            {otherName} no longer wants to include their superannuation in their
            offer.
          </Heading2MarginTopNone>
          <Paragraph>
            {otherName} wants to remove their superannuation from their offer.
            If you are OK with this, {otherName} will create a new proposed
            division of assets.
          </Paragraph>
        </SpacedBox>
        <SpacedBox marginBottom={4}>
          <GenericButton fullWidth onClick={onAgree} small>
            Agree
          </GenericButton>
        </SpacedBox>
        <GenericButton fullWidth onClick={onReject} reverse small>
          Do not agree
        </GenericButton>
      </>
    );
  };
