import React from 'react';
import { useAppDispatch } from 'App/State/Store';
import Card from 'Common/UI/Card';
import GenericButton from 'Common/UI/Button/GenericButton';
import { showDialog } from 'App/State/AppReducer';
import {
  DIALOG_REMOVE_SUPERANNUATION,
  DOCUMENT_CARD_COMPLETE,
  DOC_TYPE_CONSENT_ORDERS,
} from 'Common/constants';
import AssetProperties from 'Common/Data/App/assetProperties';
import { currentStatusConsentOrders } from 'Common/Utils/documentStatus';
import getDocumentCardStatus from 'App/UI/DocumentCard/getDocumentCardStatus';
import getNames from 'Common/Utils/getNames';

const RemoveSuperannuationCard: React.FC = () => {
  const { isAssetSplitCompleted, isSuperIncludedInOffer } = AssetProperties();
  const dispatch = useAppDispatch();

  const consentOrdersStatus = getDocumentCardStatus(
    DOC_TYPE_CONSENT_ORDERS,
    currentStatusConsentOrders()
  );

  // if superannuation is not included in the asset split, asset split has not been completed or the users have agreed to create an application for consent orders, hide this card
  if (
    !isSuperIncludedInOffer ||
    !isAssetSplitCompleted ||
    consentOrdersStatus === DOCUMENT_CARD_COMPLETE
  ) {
    return null;
  }

  const otherName = getNames().other;

  return (
    <Card cardTheme="emptyOption">
      <Card.MainHeading>Remove superannuation</Card.MainHeading>
      <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
        You can choose to remove your superannuation as a splittable asset. If{' '}
        {otherName} agrees, this will mean you have to restart dividing your
        assets.
      </Card.Paragraph>
      <GenericButton
        subtle
        onClick={() =>
          dispatch(showDialog({ dialog: DIALOG_REMOVE_SUPERANNUATION }))
        }
      >
        Remove superannuation
      </GenericButton>
    </Card>
  );
};

export default RemoveSuperannuationCard;
