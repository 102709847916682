import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.padding.small}px;
  position: relative;
  z-index: 1;
`;

export default Row;
