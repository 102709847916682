import React from 'react';
import PropTypes from 'prop-types';

import MatterProps from 'Common/Utils/MatterProps';

import Paragraph from 'Common/UI/Text/Paragraph';
import { HeadingSpacedContainer } from 'Common/UI/Text/Headings';

const TextSummary = ({ viewer }) => {
  const messageForFirstViewer = `If you think the suggested division is fair, you can accept it and send it to ${MatterProps(
    'other.firstname'
  )} to ask if they agree. If you don’t agree with the suggested division, you can change it and explain to them why you feel this way.`;
  const messageForSecondViewer = `${MatterProps(
    'other.firstname'
  )} has already viewed the suggested division and either accepted it or made another offer. You can view what they think on the next screen and either accept their offer or make your own offer and explain why you think it’s better.`;

  return (
    <HeadingSpacedContainer marginBottom="25px">
      <Paragraph>
        {viewer === 'first' ? messageForFirstViewer : messageForSecondViewer}
      </Paragraph>
    </HeadingSpacedContainer>
  );
};

TextSummary.propTypes = {
  viewer: PropTypes.string,
};

TextSummary.defaultProps = {
  viewer: '',
};

export default TextSummary;
