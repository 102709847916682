import React from 'react';
import styled from 'styled-components';

import { PathwayOption } from 'Common/Data/Types/appSections';
import GenericButton from '../Button/GenericButton';
import ResponsiveButtonContainer from '../Layout/ResponsiveButtonContainer';

const StyledResponsiveButtonContainer = styled(ResponsiveButtonContainer)`
  margin-top: ${({ theme }) => theme.spacing[12]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;

type Props = {
  onChange: (value: unknown) => void;
  options: PathwayOption[];
};

const Pathway: React.FC<Props> = ({ onChange, options }) => {
  const onOptionClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    option: PathwayOption
  ) => {
    onChange(option.value);
    option.onClick(e);
  };

  return (
    <StyledResponsiveButtonContainer>
      {options.map(o => (
        <GenericButton
          key={o.value}
          onClick={e => onOptionClick(e, o)}
          small
          subtle={o.type === 'secondary'}
        >
          {o.label}
        </GenericButton>
      ))}
    </StyledResponsiveButtonContainer>
  );
};

export default Pathway;
