import styled from 'styled-components';
import { Label } from 'Common/UI/Text/Label';

const OptionalRequiredText = styled(Label)`
  float: right;
  font-weight: bold;
  line-height: 2;
  color: ${props =>
    props.isRequired
      ? props.theme.colours.black
      : props.theme.colours.midDarkGrey};
`;

export default OptionalRequiredText;
