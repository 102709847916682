/* eslint no-restricted-globals: 0 */
export default (mobileNumber: string): boolean => {
  let isMatched = false;
  const checkNumber = mobileNumber.replace(/\s/g, '');

  if (checkNumber) {
    const mobileMatches = [
      /^(\+614)+\d{8}$/,
      /^(\+6104)+\d{8}$/,
      /^(04)+\d{8}$/,
      /^(4)+\d{8}$/,
      /^(\+639)+\d{9}$/,
    ];

    if (process.env.ADDITIONAL_MOBILE_MATCHER){
      mobileMatches.push(
        new RegExp(process.env.ADDITIONAL_MOBILE_MATCHER)
      );
    }

    const match = mobileMatches.find(matchType => checkNumber.match(matchType));
    isMatched = match !== undefined;
  }
  return isMatched;
};
