import React from 'react';

import Paragraph from 'Common/UI/Text/Paragraph';
import push from 'Common/Utils/push';
import DiviProps, { agreedDivisionText } from 'Common/Data/App/diviProperties';
import { areSettlementAssetsAllAccountedFor } from 'App/Utils/matterHelpers';
import { useAppDispatch } from 'App/State/Store';
import { isInResetSuggestedDivisionFlow } from 'Common/Utils/resetSuggestedDivisionHelpers';
import AssetProps from 'Common/Data/App/assetProperties';
import { CARD_STATEMENT } from 'Common/constants';
import MatterProps from 'Common/Utils/MatterProps';
import {
  ODRS_DIVISION_SPLIT_VIEW_ROUTE,
  ODRS_DIVISION_ASSETS_INFO,
  ODRS_DIVISION_ASSETS_INFO_INTRO,
  ODRS_DIVISION_SPLIT_INTRO_ROUTE,
  ODRS_DIVISION_ROUTE,
} from 'Common/routes';
import Card from 'Common/UI/Card';

const IconArrowRight: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <g clipPath="url(#clip0)">
      <circle
        cx="14"
        cy="14"
        r="14"
        transform="rotate(-180 14 14)"
        fill="#E4E4F0"
      />
      <path
        d="M9.67667 14L18.3237 14"
        stroke="#21202C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 9.67716L18.3237 14.0007L14.0002 18.3242"
        stroke="#21202C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          x="28"
          y="28"
          width="28"
          height="28"
          transform="rotate(-180 28 28)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SuggestedDivisionNotReadyCard: React.FC<{ locked?: boolean }> = ({
  locked = false,
}) => (
  <Card cardTheme={locked ? `${CARD_STATEMENT}Inactive` : 'emptyOption'}>
    <Card.MainHeading>Suggested division</Card.MainHeading>
    <div style={{ flexGrow: 1 }}>
      <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
        You will have to agree on all your assets statements before you can get
        started on this section.
      </Card.Paragraph>
    </div>
  </Card>
);

export const SuggestedDivisionInResetFlow: React.FC = () => (
  <Card cardTheme={`${CARD_STATEMENT}Inactive`}>
    <Card.MainHeading>Suggested division</Card.MainHeading>
    <div style={{ flexGrow: 1 }}>
      <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
        You have requested to restart this section.
      </Card.Paragraph>
    </div>
  </Card>
);

export const AgreedDivisionCard: React.FC<{
  navigate: (route: string) => void;
}> = ({ navigate }) => (
  <Card cardTheme="emptyOption">
    <Card.MainHeading>Your agreed division</Card.MainHeading>
    <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
      {agreedDivisionText()}
    </Card.Paragraph>
    <Card.Button
      subtle
      onClick={() => navigate(ODRS_DIVISION_SPLIT_VIEW_ROUTE)}
    >
      View
    </Card.Button>
  </Card>
);

export const DivisionNotStartedCard: React.FC<{
  navigate: (route: string, state?: any) => void;
}> = ({ navigate }) => (
  <Card cardTheme="default">
    <Card.MainHeading>Suggested division</Card.MainHeading>
    <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
      You can now get started on the suggested division
    </Card.Paragraph>
    <Card.Button
      onClick={() => navigate(ODRS_DIVISION_SPLIT_INTRO_ROUTE, { first: true })}
    >
      Get started
    </Card.Button>
  </Card>
);

export const DivisionInProgressCard: React.FC<{
  navigate: (route: string, state?: any) => void;
}> = ({ navigate }) => {
  const { agreedToDivision, lastOfferBy, rounds } = DiviProps();

  const notStarted = !agreedToDivision && rounds.length === 0;

  if (notStarted) {
    <Card cardTheme="default">
      <Card.HeadingWithIcon
        icon={
          <IconArrowRight
            onClick={() =>
              navigate(ODRS_DIVISION_SPLIT_INTRO_ROUTE, { first: true })
            }
          />
        }
      >
        Suggested division
      </Card.HeadingWithIcon>
      <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
        You can now get started on the suggested division
      </Card.Paragraph>
      <Card.Button
        onClick={() =>
          navigate(ODRS_DIVISION_SPLIT_INTRO_ROUTE, { first: true })
        }
      >
        Get started
      </Card.Button>
    </Card>;
  }

  return (
    <Card cardTheme="default">
      <Card.HeadingWithIcon
        icon={<IconArrowRight onClick={() => navigate(ODRS_DIVISION_ROUTE)} />}
      >
        Suggested division
      </Card.HeadingWithIcon>
      {!agreedToDivision && lastOfferBy('other') && (
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          {MatterProps('other.firstname')} has sent you an offer.
        </Card.Paragraph>
      )}
      {!agreedToDivision && lastOfferBy('self') && (
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          Your offer has been sent to {MatterProps('other.firstname')}. We'll
          let you know once they have reviewed your offer.
        </Card.Paragraph>
      )}
      <Card.Button
        onClick={() => navigate(ODRS_DIVISION_ROUTE)}
        subtle={!lastOfferBy('other')}
      >
        {!lastOfferBy('other') ? 'View' : 'Get started'}
      </Card.Button>
    </Card>
  );
};

export const AssetSplitCard: React.FC<{
  navigate: (route: string) => void;
  locked?: boolean;
}> = ({ navigate, locked }) => {
  const {
    isAssetSplitCompleted,
    otherPartyIsMakingAnOffer,
    isExactlyOneOfferAndMadeByOtherParty,
    hasOtherPartyMadeCounterOffer,
    isWaitingForOtherPartyToViewOffer,
    hasAnyOfferBeenStarted,
    canStartOrWorkOnOffer,
    isOfferInProgress,
  } = AssetProps();

  const { agreedToDivision } = DiviProps();

  const isResettingSuggestedDivision = isInResetSuggestedDivisionFlow();

  const handleClick = () => {
    if (agreedToDivision) {
      if (MatterProps('self.hasSeenAssetTransferIntro', false)) {
        navigate(ODRS_DIVISION_ASSETS_INFO);
      } else {
        navigate(ODRS_DIVISION_ASSETS_INFO_INTRO);
      }
    }
  };

  let buttonText = 'View';

  // current party is making an offer
  if (isOfferInProgress) {
    buttonText = 'Continue';
  }

  // other party is making an offer
  if (!hasAnyOfferBeenStarted) {
    buttonText = 'Get started';
  }

  return (
    <Card cardTheme={locked ? `${CARD_STATEMENT}Inactive` : 'default'}>
      <Card.HeadingWithIcon icon={<IconArrowRight onClick={handleClick} />}>
        Dividing assets
      </Card.HeadingWithIcon>
      {!agreedToDivision && !isAssetSplitCompleted && (
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          You will both be required to agree on a suggested division before you
          get started on this section.
        </Card.Paragraph>
      )}
      {agreedToDivision && isResettingSuggestedDivision && (
        <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
          You have requested to restart this section.
        </Card.Paragraph>
      )}
      {agreedToDivision &&
        !isResettingSuggestedDivision &&
        !hasAnyOfferBeenStarted && ( // no one has started an asset split offer yet
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            You will need to select what assets and or money you will transfer
            to {MatterProps('other.firstname')} to make up the difference.
          </Card.Paragraph>
        )}
      {isAssetSplitCompleted &&
        !isResettingSuggestedDivision && ( // asset split has been agreed by both parties
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            You have both reached an agreement on what you plan to do with you
            assets.
          </Card.Paragraph>
        )}
      {agreedToDivision &&
        !isResettingSuggestedDivision &&
        !isAssetSplitCompleted &&
        otherPartyIsMakingAnOffer && (
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            {MatterProps('other.firstname')} is working out a proposal for how
            you should divide your assets. Once they send it to you, you can
            agree or respond with your own proposal.
          </Card.Paragraph>
        )}
      {agreedToDivision &&
        !isResettingSuggestedDivision &&
        !isAssetSplitCompleted &&
        isExactlyOneOfferAndMadeByOtherParty && (
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            {MatterProps('other.firstname')} has made a proposal for how you can
            divide your assets to reach your agreed percentages.
          </Card.Paragraph>
        )}
      {agreedToDivision &&
        !isResettingSuggestedDivision &&
        !isAssetSplitCompleted &&
        hasOtherPartyMadeCounterOffer && (
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            {MatterProps('other.firstname')} has made a counter proposal for how
            you can divide your assets to reach your agreed percentages.
          </Card.Paragraph>
        )}
      {agreedToDivision &&
        !isResettingSuggestedDivision &&
        !isAssetSplitCompleted &&
        isWaitingForOtherPartyToViewOffer && (
          <Card.Paragraph spaced={{ marginBottom: 'medium2' }}>
            {MatterProps('other.firstname')} needs to view your proposal and
            either accept or make a counter offer.
          </Card.Paragraph>
        )}
      {agreedToDivision && !isResettingSuggestedDivision && (
        <Card.Button onClick={handleClick} subtle={!canStartOrWorkOnOffer}>
          {buttonText}
        </Card.Button>
      )}
    </Card>
  );
};

const AssetDivisionCards = () => {
  const {
    agreedToDivision,
    bothPartiesReadyForSuggestedDivision,
    isMatterInDivision,
  } = DiviProps();

  const { isTAPNegative } = AssetProps();

  const locked = !bothPartiesReadyForSuggestedDivision;
  const cantContinue = isTAPNegative && areSettlementAssetsAllAccountedFor();

  const dispatch = useAppDispatch();

  const navigate = (to: string, state?: any) => dispatch(push(to, state));

  const isResettingSuggestedDivision = isInResetSuggestedDivisionFlow();

  if (isResettingSuggestedDivision) {
    return (
      <>
        <SuggestedDivisionInResetFlow />
        <AssetSplitCard locked navigate={navigate} />
      </>
    );
  }

  if (locked) {
    return (
      <>
        <SuggestedDivisionNotReadyCard locked />
        <AssetSplitCard locked navigate={navigate} />
      </>
    );
  }
  if (cantContinue) {
    return (
      <Paragraph large>
        Currently amica can only help you divide your assets if your total asset
        pool is not negative. You can still complete the parenting part of the
        app and export a copy of your progress via your profile.
      </Paragraph>
    );
  }
  if (agreedToDivision) {
    return (
      <>
        <AgreedDivisionCard navigate={navigate} />
        <AssetSplitCard navigate={navigate} />
      </>
    );
  }
  if (bothPartiesReadyForSuggestedDivision) {
    return (
      <>
        {isMatterInDivision && <DivisionInProgressCard navigate={navigate} />}
        {!isMatterInDivision && <DivisionNotStartedCard navigate={navigate} />}
        <AssetSplitCard locked navigate={navigate} />
      </>
    );
  }
  return null;
};

export default AssetDivisionCards;
