import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { connect } from 'react-redux';

import BubbleNotification from 'Common/UI/BubbleNotification';
import BubbleNotificationLearnMore from 'Common/UI/BubbleNotificationLearnMore';
import {
  SUGGESTED_DIVISION_DIFF_WARNING_PERCENT,
  SUGGESTED_DIVISION_DIFF_INVALID_PERCENT,
} from 'Common/constants';

import DiviProps from 'Common/Data/App/diviProperties';
import sign from 'Common/Utils/sign';

import { redTheme } from 'Common/Utils/theme';
import Appears from 'Common/UI/Layout/Appears';

import { H2Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Column } from 'Common/UI/Layout/Flex';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import {
  openOdie as openOdieAction,
  updateOdie as updateOdieAction,
} from 'App/State/OdieReducer';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import InteractiveSplitIndicator from '../Indicator/InteractiveSplitIndicator';
import DivisionInvalidAmountHelp from '../Components/DivisionInvalidAmountHelp';

const StyledColumn = styled(Column)`
  padding-bottom: 0px;
`;

const MakeOffer = ({ onUpdateOffer, invalidOffer, onInvalidOffer }) => {
  const { division, TAP, rounds } = DiviProps();

  let startingAmount;

  const [lastRound] = rounds.concat().reverse();

  switch (rounds.length) {
    case 0:
      // No negotiation has taken place, can only show the DIVI offer
      startingAmount = division;
      break;
    default:
      // One round of negotiation - show last round offer compared to divi
      startingAmount = parseFloat(Number(lastRound.split).toFixed(2));
      break;
  }

  // so we can update parts other UI
  const [offer, setOffer] = useState(division);
  const [dismissedSplitWarning, setDismissedSplitWarning] = useState(false);
  const [override, setOverride] = useState(undefined);

  // Suggested division to offer difference
  const offerDivisionDiff = Number(Math.abs(division - offer)).toFixed(2);

  const setBackToValidOffer = () => {
    const invalidBackTo =
      division +
      sign(offer - division) * SUGGESTED_DIVISION_DIFF_INVALID_PERCENT;
    setOverride(invalidBackTo);
    setOffer(invalidBackTo);
    onUpdateOffer(invalidBackTo);
  };

  useEffect(() => {
    onInvalidOffer(
      parseFloat(offer) === 0 ||
        // If offer is startingAmount
        parseFloat(startingAmount) === parseFloat(offer) ||
        // If diff from suggested division by more than invalid percent
        offerDivisionDiff > SUGGESTED_DIVISION_DIFF_INVALID_PERCENT
    );
  }, [offer]);

  return (
    <StyledColumn alignItems="center">
      <FlexGrid medium={2} large={2}>
        <InteractiveSplitIndicator
          startingAmount={startingAmount}
          totalAssetPool={TAP}
          isInvalid={invalidOffer}
          override={override}
          setOverride={setOverride}
          onUpdate={value => {
            onInvalidOffer(invalidOffer);
            setOffer(value);
            onUpdateOffer(value);
          }}
        />
        <div>
          <OffsetColumn>
            <Appears
              on={
                offerDivisionDiff >= SUGGESTED_DIVISION_DIFF_WARNING_PERCENT &&
                offerDivisionDiff <= SUGGESTED_DIVISION_DIFF_INVALID_PERCENT &&
                !dismissedSplitWarning
              }
            >
              <BubbleNotification
                onClick={() => setDismissedSplitWarning(true)}
                buttonLabel="Got it"
                dataCy="division-split-notification-warning"
              >
                <Paragraph>
                  The offer is outside our suggested division.
                </Paragraph>
              </BubbleNotification>
            </Appears>
          </OffsetColumn>
          <OffsetColumn>
            <Appears
              on={offerDivisionDiff > SUGGESTED_DIVISION_DIFF_INVALID_PERCENT}
              fullWidth
            >
              <BubbleNotificationLearnMore
                button={{ onClick: setBackToValidOffer }}
                theme={redTheme}
                learnMore={{ popUpContent: DivisionInvalidAmountHelp }}
                dataCy="division-split-notification-invalid"
              >
                <>
                  <H2Conversational>
                    You are proposing to divide your property by more than 20%
                    of amica&apos;s suggestion
                  </H2Conversational>
                  <Paragraph large>
                    amica does not allow this as it may not be considered fair
                    and reasonable. For further information and legal help,
                    please refer to amica’s{' '}
                    <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
                      help and support.
                    </ReferralLink>
                  </Paragraph>
                </>
              </BubbleNotificationLearnMore>
            </Appears>
          </OffsetColumn>
        </div>
      </FlexGrid>
    </StyledColumn>
  );
};

MakeOffer.propTypes = {
  onUpdateOffer: PropTypes.func.isRequired,
  onInvalidOffer: PropTypes.func,
  invalidOffer: PropTypes.bool,
};

MakeOffer.defaultProps = {
  invalidOffer: false,
  onInvalidOffer: () => {},
};

const mapDispatchToProps = dispatch => ({
  openOdie: () => dispatch(openOdieAction()),
  updateOdie: data => dispatch(updateOdieAction(data)),
});

export default connect(null, mapDispatchToProps)(MakeOffer);
