import React from 'react';

import {
  getFirstName,
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
} from 'Common/Utils/statementHelpers';
import { getCard } from 'Common/Data/App/appSections';
import {
  Statement,
  QuestionAny,
  CardContinue,
} from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';

export const bankAccountQuestions = (card: CardContinue): QuestionAny[] => {
  const { extendID, subIndex } = card;

  let accountName;
  const keyName =
    extendID === 'yourfinancesA' || extendID === 'yourfinancesB'
      ? 'personalSavings'
      : 'sharedSavings';
  const item = getCard(extendID) as CardContinue;
  const { data } = item;
  if (Array.isArray(data[keyName]) && subIndex !== undefined) {
    accountName = data[keyName][subIndex].text;
  }

  return [
    {
      name: 'intro',
      type: 'info',
      label:
        'The court requires parties to provide the bank account number for each of their bank accounts. Please list the account number of the bank account you previously entered.',
      index: 0,
    },
    {
      name: 'accountNumber',
      label: `What is the bank account number for ${accountName}?`,
      type: 'text',
      hint: 'Bank account number',
      placeholder: 'Enter the account number',
      index: 1,
    },
    {
      name: 'statement',
      type: 'statement',
      index: 2,
      label: 'You created a statement',
    },
  ];
};

export const bankAccountStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  if (!('extendID' in card)) {
    return;
  }

  const { extendID, subIndex } = card;

  if (!extendID || subIndex === undefined) {
    return;
  }

  const { itemHistory } = matter;

  const party = data.creator || matter.self.party;

  let firstName;
  let partnerFirstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
    partnerFirstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
    partnerFirstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  }

  const itemDiff = getUpdatedItemValues(data, itemHistory, ['accountNumber']);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    bankAccountQuestions(card),
    data,
    hideSensitive
  );

  let statement;
  if (data.personalSavings) {
    const accountName = data.personalSavings[subIndex].text;
    statement = highlight`${firstName} has a ${accountName} account. The account number is: `;
  } else if (data.sharedSavings) {
    const accountName = data.sharedSavings[subIndex].text;
    statement = highlight`${firstName} and ${partnerFirstName} have a shared ${accountName} account. The account number is: `;
  }

  const numberText = highlight`${updated(
    'accountNumber',
    redact('accountNumber')
  )}`;

  if (plainText) {
    return `${statement}${numberText}`;
  }

  return (
    <>
      {statement} {numberText}
    </>
  );
};
