import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import formatCurrency from 'Common/Utils/formatCurrency';
import DiviProps from 'Common/Data/App/diviProperties';
import { Column, Row } from 'Common/UI/Layout/Flex';
import Paragraph from 'Common/UI/Text/Paragraph';

const PrimaryColumn = styled(Column)`
  flex: 1;
  color: ${({ theme }) => theme.textColour};
  text-align: left;
`;

const SecondaryColumn = styled(Column)`
  flex: 1;
  color: ${({ theme }) => theme.textColour};
  text-align: right;
`;

const PersonLabel = styled(Paragraph)`
  margin: 0;
`;

export const PercentageLabel = styled.p(({ textColour, theme }) => ({
  ...theme.typography.number.regularNumber,
  color: textColour || theme.textColour,
  margin: `${theme.padding.small}px 0 ${theme.padding.xxsmall}px 0`,
}));

export const AmountLabel = styled.p(({ textColour, theme }) => ({
  ...theme.typography.text.paragraph,
  color: textColour || theme.textColour,
  marginTop: 0,
  marginBottom: 0,
  fontWeight: 'bold',
}));

const StyledRow = styled(Row)`
  display: ${({ display }) => display || 'inline-flex'};
  margin: ${({ margin }) => margin || '0'};
  color: ${({ textColour, theme }) => textColour || theme.textColour};
  width: 100%;
`;

const Amounts = ({ percentage, selfLabel, otherLabel }) => {
  const { TAP, indicatorTextColour } = DiviProps();

  return (
    <StyledRow justifyContent="stretch">
      <PrimaryColumn alignItems="flex-start">
        <PersonLabel>
          <strong>{selfLabel}</strong>
        </PersonLabel>
        <PercentageLabel
          textColour={indicatorTextColour}
          data-cy="self-percentage"
        >
          {Math.round(percentage)}%
        </PercentageLabel>
        <AmountLabel textColour={indicatorTextColour}>
          {formatCurrency((TAP * percentage) / 100)}
        </AmountLabel>
      </PrimaryColumn>
      <SecondaryColumn alignItems="flex-end">
        <PersonLabel>{otherLabel}</PersonLabel>
        <PercentageLabel data-cy="other-percentage">
          {Math.round(100 - percentage)}%
        </PercentageLabel>
        <AmountLabel>
          {formatCurrency((TAP * (100 - percentage)) / 100)}
        </AmountLabel>
      </SecondaryColumn>
    </StyledRow>
  );
};

Amounts.propTypes = {
  percentage: PropTypes.number.isRequired,
  selfLabel: PropTypes.string.isRequired,
  otherLabel: PropTypes.string.isRequired,
};
export default Amounts;
