import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ODRS_CONSENT_ORDERS_ROUTE } from 'Common/routes';
import PageContent from 'Common/UI/Layout/PageContent';
import Loader from 'Common/UI/Loader/Loader';
import Carousel from 'Common/UI/Carousel';
import { CarouselButton } from 'Common/UI/Carousel/ImageSlideWrapper';
import { setPartyFlagsAction } from 'App/State/MatterActions';

import ConsentOrdersSlide1 from './ConsentOrdersSlide1';
import ConsentOrdersSlide2 from './ConsentOrdersSlide2';
import ConsentOrdersSlide3 from './ConsentOrdersSlide3';

const ConsentOrdersIntroCarousel = ({
  navigate,
  setHasSeenConsentOrdersIntro,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);

    setHasSeenConsentOrdersIntro()
      .then(() => {
        navigate(ODRS_CONSENT_ORDERS_ROUTE);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const LastSlideControls = () => (
    <CarouselButton onClick={handleClick} data-cy="carousel-finish-button">
      Get started
    </CarouselButton>
  );

  return (
    <PageContent>
      <Carousel
        LastSlideControls={LastSlideControls}
        onClose={() => navigate(ODRS_CONSENT_ORDERS_ROUTE)}
      >
        <ConsentOrdersSlide1 />
        <ConsentOrdersSlide2 />
        <ConsentOrdersSlide3 />
      </Carousel>
      {loading && <Loader floating />}
    </PageContent>
  );
};

ConsentOrdersIntroCarousel.propTypes = {
  navigate: PropTypes.func.isRequired,
  setHasSeenConsentOrdersIntro: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
  setHasSeenConsentOrdersIntro: () =>
    dispatch(setPartyFlagsAction({ hasSeenConsentOrdersIntro: true })),
});

export default connect(null, mapDispatchToProps)(ConsentOrdersIntroCarousel);
