import React from 'react';

import ProfileUpdate from 'App/UI/Profile/ProfileUpdate';
import MatterGuard from '../Guard/MatterGuard';

const ODRSProfileUpdate = props => (
  <MatterGuard>
    <ProfileUpdate {...props} />
  </MatterGuard>
);

export default ODRSProfileUpdate;
