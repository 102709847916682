import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { Spinner, SpinnerBall } from './Loader';

const InlineSpinner = () => (
  <Spinner data-testid="inline-loader">
    {[-0.32, -0.16, 0].map(i => (
      <SpinnerBall
        // size="10px"   <----- this makes the whole page re-render???
        key={i}
        style={{
          animationDelay: `${i}s`,
        }}
      />
    ))}
  </Spinner>
);

const StyledInlineLoader = styled.div`
  display: flex;
  justify-content: center;
`;

const InlineLoader = ({ children, loading, ...rest }) => {
  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });

  return (
    <StyledInlineLoader {...rest}>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div style={props} key={key}>
            <InlineSpinner />
          </animated.div>
        ) : (
          <animated.div style={props} key={key}>
            {children}
          </animated.div>
        )
      )}
    </StyledInlineLoader>
  );
};

InlineLoader.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InlineLoader;
