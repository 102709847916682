import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  BANNER_HIDE,
  BANNER_SHOW,
  DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER,
  PAGE_CONSENT_ORDERS_COMPLETED,
  SECTION_CONSENT_ORDERS,
  SUPER_SPLITTING_CONSENT_ORDER_VIEW_PFL_PAYER,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import TextButton from 'Common/UI/Button/TextButton';
import AssetProperties from 'Common/Data/App/assetProperties';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { useAppDispatch } from 'App/State/Store';
import openResolvedPromiseURL from 'Common/Utils/openResolvedPromiseURL';
import { getAgreementDocURLAction } from 'App/State/MatterActions';
import { getPage } from 'Common/Data/App/appSections';
import { getMatter } from 'Common/Utils/MatterProps';

export const superannuationConsentOrderViewPFLPayerFlags: AppState = {
  id: 'superannuationConsentOrderViewPFLPayer',
  description:
    'Let the payer view the Procedural Fairness Letter (PFL) that they need to send to their fund',
  check: () => {
    const { isPayer, isSuperIncludedInOffer, superSplittingFlags } =
      AssetProperties();

    const { payerHasSubmittedProceduralFairnessLetter } = superSplittingFlags;

    if (!isPayer || !isSuperIncludedInOffer) {
      return false;
    }

    const pageData = getPage(
      SECTION_CONSENT_ORDERS,
      PAGE_CONSENT_ORDERS_COMPLETED
    );

    if (!pageData) {
      return false;
    }

    const { generateStatus } = pageData;

    if (!generateStatus) {
      return false;
    }

    const matter = getMatter();

    const status = generateStatus(matter);

    if (status !== 'not-completed') {
      return false;
    }

    return !payerHasSubmittedProceduralFairnessLetter;
  },
  flags: {
    appState: SUPER_SPLITTING_CONSENT_ORDER_VIEW_PFL_PAYER,
    bannerTopState: () =>
      sessionStorageLoad('superannuationConsentOrderViewPFLPayerDismissed')
        ? BANNER_HIDE
        : BANNER_SHOW,
  },
};

type Props = {
  updateAppState: () => void;
};

export const SuperannuationConsentOrderViewPFLPayer: React.FC<Props> = ({
  updateAppState,
}) => {
  const dispatch = useAppDispatch();
  const { withLoading } = usePromiseWithLoading();

  const getProceduralFairnessLetterURL = () =>
    dispatch(
      getAgreementDocURLAction({
        name: DOC_TYPE_PROCEDURAL_FAIRNESS_LETTER,
      })
    ).unwrap();

  return (
    <div data-cy="banner-send-draft-minutes-to-fund">
      <Heading2MarginTopNone>
        You will need to send your draft minutes of consent to your
        superannuation fund
      </Heading2MarginTopNone>
      <Paragraph>
        The trustee of your superannuation fund will need to review the draft
        minutes of consent so they can approve or propose edits to the
        superannuation splitting orders. You will also need to download and send
        the Procedural Fairness Letter to the fund as well as your draft minutes
        of consent.
      </Paragraph>
      <SpacedBox marginBottom={4}>
        <GenericButton
          fullWidth
          onClick={() =>
            openResolvedPromiseURL(
              withLoading(() => getProceduralFairnessLetterURL())
            )
          }
          small
          data-cy="view-pfl-button"
        >
          View Procedural Fairness Letter
        </GenericButton>
      </SpacedBox>
      <TextButton
        fullWidth
        onClick={() => {
          sessionStorageSave(
            `superannuationConsentOrderViewPFLPayerDismissed`,
            true
          );
          updateAppState();
        }}
        small
        data-cy="dismiss-button"
      >
        Dismiss
      </TextButton>
    </div>
  );
};
