import React from 'react';
import { useAppSelector, useAppDispatch } from 'App/State/Store';
import {
  DOC_TYPE_PARENTING_AGREEMENT,
  DOC_TYPE_PARENTING_PLAN,
  DOCUMENT_CARD_COMPLETE,
} from 'Common/constants';
import { hasItemBeenPaidFor, isAmicaFree } from 'Common/Utils/Payments';
import {
  getParentingState,
  getNewParentingPlanState,
} from 'App/Utils/sectionHelpers';
import {
  setPartyFlagsAction,
  getAgreementDocURLAction,
} from 'App/State/MatterActions';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import openResolvedPromiseURL from 'Common/Utils/openResolvedPromiseURL';
import push from 'Common/Utils/push';
import ParentingPlan from './ParentingPlan';
import getDocumentCardStatus from '../getDocumentCardStatus';
import ParentingAgreement from './ParentingAgreement';

const ParentingDocumentCards: React.FC = () => {
  const matter = useAppSelector(state => state.matter);
  const dispatch = useAppDispatch();

  const isFree = isAmicaFree(matter);

  const { withLoading } = usePromiseWithLoading();

  const setPartyFlags = (flags: Record<string, boolean>) =>
    withLoading(() => dispatch(setPartyFlagsAction(flags)));
  const getParentingAgreementDocURL = () =>
    dispatch(
      getAgreementDocURLAction({ name: DOC_TYPE_PARENTING_AGREEMENT })
    ).unwrap();
  const getNewParentingPlanAgreementDocURL = () =>
    dispatch(
      getAgreementDocURLAction({ name: DOC_TYPE_PARENTING_PLAN })
    ).unwrap();
  const openParentingAgreement = () =>
    openResolvedPromiseURL(withLoading(getParentingAgreementDocURL));
  const openParentingPlan = () =>
    openResolvedPromiseURL(
      withLoading(getNewParentingPlanAgreementDocURL)
    );
  const navigate = (route: string, state?: any) => dispatch(push(route, state));

  const parentingAgreementState = getDocumentCardStatus(
    DOC_TYPE_PARENTING_AGREEMENT,
    getParentingState({ matter })
  );

  const parentingPlanState = getDocumentCardStatus(
    DOC_TYPE_PARENTING_PLAN,
    getNewParentingPlanState({ matter })
  );

  // if a parenting plan has been agreed to by both parties then we should hide the parenting agreement card
  const shouldHideParentingAgreementCard =
    parentingPlanState === DOCUMENT_CARD_COMPLETE;

  return (
    <>
      {!shouldHideParentingAgreementCard && (
        <ParentingAgreement
          isFree={isFree}
          isPaid={hasItemBeenPaidFor(matter, DOC_TYPE_PARENTING_AGREEMENT)}
          status={parentingAgreementState}
          setPartyFlags={setPartyFlags}
          openAgreementFunctions={{
            parentingAgreement: openParentingAgreement,
          }}
          navigate={navigate}
        />
      )}
      <ParentingPlan
        status={parentingPlanState}
        setPartyFlags={setPartyFlags}
        openAgreementFunctions={{ parentingPlan: openParentingPlan }}
      />
    </>
  );
};

export default ParentingDocumentCards;
