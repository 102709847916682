import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MatterProps from 'Common/Utils/MatterProps';
import { backAndForthMessagingTrackingEvent } from 'Common/UI/Analytics/EventTracking';
import YouReceiveIndicator from './Indicator/YouReceiveIndicator';
import Messaging from './Components/Messaging';

const FlexWrapper = styled.div`
  display: inline-flex;
  flex: 1;
  flex-direction: column;
`;

const Offer = styled.div`
  background: ${props => props.theme.backgroundColor};
  padding: 0 ${({ theme }) => theme.padding.small}px;
  width: 100%;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) => `${theme.padding.medium2}px 40px`};
    width: 50%;
  }
`;

const DivisionMessaging = ({ offer, TAP, onSubmit, onCancel }) => {
  // Analytics
  const afterMessageSend = () =>
    backAndForthMessagingTrackingEvent({
      section: 'Suggested Division',
      rounds: MatterProps('divi.rounds', []).length,
    });

  return (
    <FlexWrapper>
      <Offer>
        <YouReceiveIndicator
          makingOffer
          ratio={offer}
          totalAssetPool={TAP}
          party={MatterProps('self.party')}
        />
      </Offer>
      <Messaging
        onSubmit={onSubmit}
        onCancel={onCancel}
        afterMessageSend={afterMessageSend}
        inputCaption="Explain why your offer is fair"
      />
    </FlexWrapper>
  );
};

DivisionMessaging.propTypes = {
  offer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  TAP: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DivisionMessaging;
