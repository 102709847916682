import React, { useContext } from 'react';

import { blackTheme } from 'Common/Utils/theme';
import Matter from 'Common/Data/Types/matter';
import { PaymentContext } from './PaymentContext';
import AppHeader from '../AppHeader';

const PaymentHeader: React.FC<{ matter: Matter }> = ({ matter }) => {
  const context = useContext(PaymentContext);
  const { pages, getPageTitle, withNav } = context;

  return (
    <AppHeader
      withProfile
      withNav={withNav}
      matter={matter}
      theme={blackTheme}
      title="Payment"
      tabs={
        withNav
          ? pages.map(
              ({ route, disabled }: { route: string; disabled: boolean }) => ({
                label: getPageTitle(route),
                to: route,
                testId: 'payment-review-tab',
                disabled,
              })
            )
          : undefined
      }
    />
  );
};

export default PaymentHeader;
