import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2, Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import push from 'Common/Utils/push';
import {
  SECTION_PARENTING,
  SECTION_STATUS_COMPLETED,
  SECTION_STATUS_IN_PROGRESS,
  SECTION_STATUS_LOCKED,
  SECTION_STATUS_NOT_STARTED,
  SECTION_STATUS_OPT_IN,
} from 'Common/constants';
import TextButton from 'Common/UI/Button/TextButton';
import { localStorageLoad, localStorageSave } from 'Common/Utils/localStorage';
import { getParentingRoute } from 'Common/Utils/getRoute';
import getNames from 'Common/Utils/getNames';
import { ODRS_PROFILE_ROUTE } from 'Common/routes';
import { Link } from 'react-router-dom';
import SectionCardWrapper from './SectionCardWrapper';
import BackgroundParenting from './bg-parenting.svg';
import BackgroundParentingCompleted from './bg-parenting-completed.svg';

const OptedOutParagraph = styled(Paragraph)`
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

const StatusParagraph = styled(Paragraph)`
  border-top: 1px solid ${({ theme }) => theme.colours.clickableGrey};
  margin-top: 0;
  padding-top: ${({ theme }) => theme.padding.small}px;
`;

const ParentingSectionCard = ({
  status,
  progress,
  navigate,
  stats: { notStarted, waitingSelf, waitingOther },
  toggleOptIn,
  isSectionReadyToBeStarted,
}) => {
  const names = getNames();

  const heading = <Heading2>Creating parenting arrangements</Heading2>;

  const route = getParentingRoute();

  switch (status) {
    case SECTION_STATUS_OPT_IN:
      if (localStorageLoad(`DismissedOverviewOptInCard_${SECTION_PARENTING}`)) {
        return (
          <>
            <Heading3>Parenting</Heading3>
            <OptedOutParagraph>
              You have opted out of the parenting section. To opt back in,
              please visit your <Link to={ODRS_PROFILE_ROUTE}>account</Link>{' '}
              page.
            </OptedOutParagraph>
          </>
        );
      }

      return (
        <SectionCardWrapper
          hideProgressBar
          dataCy="overview-card-parenting-opt-in"
          footer={
            <>
              <GenericButton
                fullWidth
                onClick={() => toggleOptIn(true)}
                subtle={!isSectionReadyToBeStarted}
              >
                Create statements for your parenting arrangement
              </GenericButton>
              <TextButton
                fullWidth
                onClick={() => {
                  toggleOptIn(false);
                  localStorageSave(
                    `DismissedOverviewOptInCard_${SECTION_PARENTING}`,
                    true
                  );
                }}
              >
                Decide later
              </TextButton>
            </>
          }
        >
          {heading}
          <Paragraph>
            You will need to reach agreement on a parenting schedule, how you
            will handle handovers and other additional statements.
          </Paragraph>
        </SectionCardWrapper>
      );
    case SECTION_STATUS_NOT_STARTED:
      return (
        <SectionCardWrapper
          hideProgressBar
          route={route}
          headerImage={BackgroundParenting}
          dataCy="overview-card-parenting-not-started"
          footer={
            <GenericButton fullWidth onClick={() => navigate(route)}>
                Get started
              </GenericButton>
          }
        >
          {heading}
          <Paragraph>
            You have sections to fill out and statements to agree on.
          </Paragraph>
        </SectionCardWrapper>
      );
    case SECTION_STATUS_LOCKED:
      return (
        <SectionCardWrapper
          hideProgressBar
          route={route}
          headerImage={BackgroundParenting}
          dataCy="overview-card-parenting-not-started"
          footer={
            <GenericButton fullWidth subtle onClick={() => navigate(route)}>
                Create statements for your parenting arrangement
              </GenericButton>
          }
        >
          {heading}
        </SectionCardWrapper>
      );
    case SECTION_STATUS_IN_PROGRESS: {
      const waitingOnOther = waitingOther > 0 && waitingSelf + notStarted === 0;

      return (
        <SectionCardWrapper
          progress={progress}
          route={route}
          headerImage={BackgroundParenting}
          dataCy="overview-card-parenting-in-progress"
          footer={
            <GenericButton fullWidth onClick={() => navigate(route)}>
                {waitingOnOther ? 'View' : 'Review'}
              </GenericButton>
          }
        >
          {heading}
          {waitingSelf > 0 && (
            <StatusParagraph>
              You have <strong>{waitingSelf}</strong> statements to review
            </StatusParagraph>
          )}
          {notStarted > 0 && (
            <StatusParagraph>
              You have <strong>{notStarted}</strong> required statements
              remaining
            </StatusParagraph>
          )}
          {waitingOnOther && (
            <StatusParagraph>
              Waiting on {names.other} to review your statements.
            </StatusParagraph>
          )}
        </SectionCardWrapper>
      );
    }
    case SECTION_STATUS_COMPLETED:
      return (
        <SectionCardWrapper
          progress={100}
          route={route}
          headerImage={BackgroundParentingCompleted}
          dataCy="overview-card-parenting-completed"
          footer={
            <GenericButton subtle fullWidth onClick={() => navigate(route)}>
                View
              </GenericButton>
          }
        >
          {heading}
          <Paragraph>Great! You have both completed this section.</Paragraph>
        </SectionCardWrapper>
      );
    default:
      return null;
  }
};

ParentingSectionCard.propTypes = {
  navigate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  stats: PropTypes.shape({
    notStarted: PropTypes.number,
    waitingSelf: PropTypes.number,
    waitingOther: PropTypes.number,
  }),
  progress: PropTypes.number,
  toggleOptIn: PropTypes.func,
  isSectionReadyToBeStarted: PropTypes.bool,
};

ParentingSectionCard.defaultProps = {
  stats: {},
  progress: undefined,
  toggleOptIn: () => {},
  isSectionReadyToBeStarted: true,
};

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
});

export default connect(null, mapDispatchToProps)(ParentingSectionCard);
