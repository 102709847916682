import React, { useEffect, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { CustomCardComponentProps } from 'Common/Data/Types/types';
import GenericButton, { MediumButton } from 'Common/UI/Button/GenericButton';
import { Column } from 'Common/UI/Layout/Flex';
import { blackTheme, greenTheme, Theme } from 'Common/Utils/theme';
import Dialog from 'Common/UI/Layout/Dialog';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import { Heading1, Heading2, H1Conversational } from 'Common/UI/Text/Headings';
import { TwoButtonResponsiveGroup } from 'Common/UI/Button/ButtonGroup';
import Paragraph from 'Common/UI/Text/Paragraph';
import { animated, AnimatedValue, useTransition } from 'react-spring';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';
import { ODRS_CONSENT_ORDERS_COMPLETE_ROUTE } from 'Common/routes';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import CloseButton from 'Common/UI/Button/CloseButton';
import { partyLabel } from 'Common/Utils/partyLabel';
import formatCurrency from 'Common/Utils/formatCurrency';
import AssetProperties from 'Common/Data/App/assetProperties';
import ClauseParagraph from './Components/ClauseParagraph';
import EditModal from './Components/EditModal';
import ClauseAddNewParagraph from './Components/ClauseAddNewParagraph';
import Intro from '../Intro';

const Wrapper = styled(Column)`
  position: relative;
  align-items: stretch;
  height: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
`;

const ButtonGroup = styled(Column)`
  background: ${({ theme }) => theme.colours.offWhite};
  padding: ${({ theme }) => theme.spacing[4]};
  justify-self: flex-end;
  flex-grow: 0;
`;

const CardQuestionFlowHeader = styled.div<{
  theme: Theme;
}>`
  position: relative;
  background-size: contain;
  height: 82px;
  background: ${({ theme }) => theme.colours.offWhite};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[4]}
    0;

  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    height: 104px;
  }
`;

const StyledCloseButton = styled(CloseButton)<{
  theme: Theme;
}>`
  right: 12px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: 24px;
    right: 24px;
  }
`;

const generateDefaultParagraphs = () => {
  const { owingParty, owedParty, superannuationAsset } = AssetProperties();

  if (!superannuationAsset) {
    throw new Error('Superannuation asset not defined');
  }

  return [
    {
      text: `Whenever a splittable payment becomes payable in respect of ${partyLabel(
        owingParty
      )}'s interest in the Fund, the trustee of the Fund shall using the base amount of ${formatCurrency(
        superannuationAsset.value
      )} which has been allocated to ${partyLabel(
        owedParty
      )}, calculate ${partyLabel(
        owedParty
      )}'s entitlement, and pay that entitlement to ${partyLabel(
        owedParty
      )} from ${partyLabel(
        owingParty
      )}'s entitlement in the Fund, and there be a corresponding reduction of ${partyLabel(
        owingParty
      )}'s entitlement in the Fund.`,
    },
    {
      text: 'These orders have effect from the operative time. The operative time is four full business days after the day these orders are served on the trustee of the Fund.',
    },
    {
      text: 'These orders are binding on the trustee of the Fund.',
    },
  ];
};

const SuperannuationFundClauseEditor: React.FC<CustomCardComponentProps> = ({
  onSubmit,
  onInputChange,
  values,
}) => {
  // onSubmit and onInputChange must be defined when we render this component
  if (!onSubmit || !onInputChange) {
    throw new Error(
      'No onSubmit function provided to SuperannuationFundClauseEditor'
    );
  }

  const [isEditingParagraph, setIsEditingParagraph] = useState(false);
  const [editingParagraphIndex, setEditingParagraphIndex] = useState(0);

  const [isDeletingParagraph, setIsDeletingParagraph] = useState(false);
  const [deletingParagraphIndex, setDeletingParagraphIndex] = useState(0);
  const [editParagraphTitle, setEditParagraphTitle] = useState('Add paragraph');
  const [needToConfirmCloseWithoutSaving, setNeedToConfirmCloseWithoutSaving] =
    useState(false);

  const defaultParagraphs = generateDefaultParagraphs();

  const [paragraphs, setParagraphs] =
    useState<{ text: string }[]>(defaultParagraphs);

  // We need to keep track of the paragraphs at the start so we can compare them to the current paragraphs to see if we need to prompt the user to save
  const [paragraphsAtStart, setParagraphsAtStart] =
    useState<{ text: string }[]>(defaultParagraphs);

  const paragraphRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [pHeights, setPHeights] = useState<number[]>([]);

  const [dismissedIntro, setDismissedIntro] = useState(true);

  const { withLoading } = usePromiseWithLoading();

  const dispatch = useAppDispatch();

  const navigate = (route: string) => dispatch(push(route));

  const updateParagraph = (index: number, text: string) => {
    paragraphRefs.current[index] = null;
    const newParagraphs = [...paragraphs];
    newParagraphs[index] = { ...newParagraphs[index], text };

    setParagraphs(newParagraphs);
    setIsEditingParagraph(false);
  };

  const onEditParagraph = (index: number) => {
    setEditParagraphTitle(`Edit paragraph ${index + 1}`);
    setEditingParagraphIndex(index);
    setIsEditingParagraph(true);
    setDismissedIntro(false);
  };

  const onStartDeleteParagraph = (index: number) => {
    setDeletingParagraphIndex(index);
    setIsDeletingParagraph(true);
  };

  const onDeleteParagraph = (index: number) => {
    const newParagraphs = [...paragraphs];
    newParagraphs.splice(index, 1);
    setParagraphs(newParagraphs);

    setIsDeletingParagraph(false);
  };

  const onAddNewParagraph = () => {
    onEditParagraph(paragraphs.length);

    setEditParagraphTitle('Add paragraph');
  };

  const onClose = () => {
    if (JSON.stringify(paragraphs) !== JSON.stringify(paragraphsAtStart)) {
      setNeedToConfirmCloseWithoutSaving(true);
    } else {
      navigate(ODRS_CONSENT_ORDERS_COMPLETE_ROUTE);
    }
  };

  const moveElementInArray = (from: number, to: number) => {
    if (to === from || to < 0 || to >= paragraphs.length) {
      return;
    }

    const newParagraphs = [...paragraphs];
    const element = newParagraphs[from];
    newParagraphs.splice(from, 1);
    newParagraphs.splice(to, 0, element);
    setParagraphs(newParagraphs);
  };

  const onMoveUp = (index: number) => moveElementInArray(index, index - 1);

  const onMoveDown = (index: number) => moveElementInArray(index, index + 1);

  // recalculates the display heights of the paragraph elements any time they change
  useEffect(() => {
    const newPHeights = paragraphRefs.current
      .filter(ref => ref?.offsetHeight && ref?.offsetHeight > 0)
      .map(ref => ref?.offsetHeight || 0);

    setPHeights(newPHeights);
  }, [paragraphs]);

  // save the paragraphs to the parent component any time they change
  useEffect(() => {
    onInputChange('clauses', paragraphs);
  }, [paragraphs]);

  // update the paragraphs if new values are passed into the component
  useEffect(() => {
    const { clauses } = values;

    if (clauses && JSON.stringify(clauses) !== JSON.stringify(paragraphs)) {
      setParagraphs(clauses);
      setParagraphsAtStart(clauses);
    }
  }, [values]);

  const transitions = useTransition(
    [
      ...paragraphs.map((data, index) => {
        const yVal = pHeights
          .slice(0, index)
          .reduce((acc, height) => acc + height, 0);

        return {
          ...data,
          type: 'paragraph',
          y: yVal,
          height: pHeights[index] ?? 0,
        };
      }),
      {
        text: 'Add new paragraph',
        type: 'add-new',
        y: pHeights.reduce((acc, height) => acc + height, 0),
        height: 0,
      },
    ],
    d => d.text,
    {
      from: { position: 'absolute', height: 200, opacity: 0, width: '100%' },
      leave: { height: 0, opacity: 0 },
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height, opacity: 1 }),
    }
  );

  return (
    <>
      {!dismissedIntro && (
        <Intro
          goNext={() => setDismissedIntro(true)}
          heading={<H1Conversational>Editing instructions</H1Conversational>}
          content={
            <Paragraph>
              You will need to use the same wording suggested in the procedural
              fairness letter received from the trustee of the superannuation
              fund.
            </Paragraph>
          }
        />
      )}
      <Wrapper data-cy="superannuation-fund-clause-editor">
        <CardQuestionFlowHeader>
          <Heading1 mt={4} mb={0}>
            Your Application
          </Heading1>
          <StyledCloseButton
            onClick={onClose}
            data-cy="superannuation-fund-clause-editor-close"
          >
            &nbsp;&nbsp;Close
          </StyledCloseButton>
        </CardQuestionFlowHeader>
        <Content>
          <ResponsiveTextContainer />
          {transitions.map(({ item, props, key, state }, index) => {
            const { y, height, ...rest } = props as AnimatedValue<{
              text: string;
              height: number;
              y: number;
            }>;

            if (item.type === 'add-new') {
              return (
                <animated.div
                  key={key}
                  className="card"
                  style={{
                    zIndex: paragraphs.length - index,
                    transform: y.interpolate(
                      (val: number) => `translate3d(0,${val}px,0)`
                    ),
                    height,
                    ...rest,
                  }}
                >
                  <ClauseAddNewParagraph
                    index={paragraphs.length + 1}
                    onAddParagraph={onAddNewParagraph}
                  />
                </animated.div>
              );
            }

            return (
              <animated.div
                key={key}
                className="card"
                style={{
                  zIndex: paragraphs.length - index,
                  transform: y.interpolate(
                    (val: number) => `translate3d(0,${val}px,0)`
                  ),
                  height,
                  ...rest,
                }}
              >
                <ClauseParagraph
                  index={index + 1}
                  onEdit={() => onEditParagraph(index)}
                  onDelete={() => onStartDeleteParagraph(index)}
                  onMoveUp={index !== 0 ? () => onMoveUp(index) : undefined}
                  onMoveDown={
                    index !== paragraphs.length - 1
                      ? () => onMoveDown(index)
                      : undefined
                  }
                  ref={ref => {
                    if (state !== 'leave') {
                      paragraphRefs.current[index] = ref;
                    }
                  }}
                >
                  {item.text}
                </ClauseParagraph>
              </animated.div>
            );
          })}
        </Content>
        <ButtonGroup alignItems="stretch" gap={4}>
          <ThemeProvider theme={greenTheme}>
            <GenericButton
              onClick={() => withLoading(onSubmit)}
              disabled={
                JSON.stringify(paragraphs) === JSON.stringify(paragraphsAtStart)
              }
              data-cy="superannuation-fund-clause-editor-done"
            >
              Done
            </GenericButton>
            <GenericButton
              reverse
              onClick={onClose}
              data-cy="superannuation-fund-clause-editor-cancel"
            >
              Cancel
            </GenericButton>
          </ThemeProvider>
        </ButtonGroup>
        <EditModal
          show={isEditingParagraph && dismissedIntro}
          onClose={() => {
            setIsEditingParagraph(false);
          }}
          onSaveParagraph={(text: string) =>
            updateParagraph(editingParagraphIndex, text)
          }
          text={paragraphs[editingParagraphIndex]?.text ?? ''}
          title={editParagraphTitle}
        />
      </Wrapper>
      <Dialog showDialog={isDeletingParagraph} theme={blackTheme}>
        <FlexGrid medium={2} data-cy="dialog-remove-paragraph">
          <ResponsiveTextContainer>
            <Heading2 mb={4}>
              Are you sure you want to remove the paragraph?
            </Heading2>
          </ResponsiveTextContainer>
          <OffsetColumn>
            <TwoButtonResponsiveGroup>
              <MediumButton
                subtle
                onClick={() => setIsDeletingParagraph(false)}
                data-cy="cancel-button"
              >
                Cancel
              </MediumButton>
              <MediumButton
                onClick={() => onDeleteParagraph(deletingParagraphIndex)}
                data-cy="remove-paragraph-button"
              >
                Remove
              </MediumButton>
            </TwoButtonResponsiveGroup>
          </OffsetColumn>
        </FlexGrid>
      </Dialog>
      <Dialog showDialog={needToConfirmCloseWithoutSaving} theme={blackTheme}>
        <FlexGrid medium={2}>
          <ResponsiveTextContainer>
            <Heading2 mb={4}>
              Are you sure you want to go back to the dashboard without saving?
            </Heading2>
            <Paragraph>
              You will lose any changes you have made to your superannuation
              clauses.
            </Paragraph>
          </ResponsiveTextContainer>
          <OffsetColumn>
            <TwoButtonResponsiveGroup>
              <MediumButton
                subtle
                onClick={() => setNeedToConfirmCloseWithoutSaving(false)}
              >
                Continue editing
              </MediumButton>
              <MediumButton
                onClick={() => navigate(ODRS_CONSENT_ORDERS_COMPLETE_ROUTE)}
              >
                Exit without saving
              </MediumButton>
            </TwoButtonResponsiveGroup>
          </OffsetColumn>
        </FlexGrid>
      </Dialog>
    </>
  );
};

export default SuperannuationFundClauseEditor;
