/**
 * THIS IS A VERSIONED FILE - see readme for more info
 */

import React, { useEffect } from 'react';

import MatterProps from 'Common/Utils/MatterProps';
import AssetProps from 'Common/Data/App/assetProperties';
import {
  ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
  ODRS_DIVISION_ASSETS_TRANSFER_JOINT,
  ODRS_DIVISION_ASSETS_VIEW_OFFER,
} from 'Common/routes';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import PageContainer from 'Common/UI/Layout/PageContainer';
import PageContent from 'Common/UI/Layout/PageContent';
import push from 'Common/Utils/push';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import Header from '../Header';
import TransferIntro from '../../Components/TransferIntro';
import { TransferFooter, DialogViewProposal } from '../common';
import TransferAssetsList from '../../Components/TransferAssetsList';
import { DivisionAssetsProvider } from '../DivisionAssetsContext';

const DivisionAssetsTransferOther = () => {
  const matter = useAppSelector(state => state.matter);
  const dispatch = useAppDispatch();

  const navigate = route => dispatch(push(route));

  const {
    transferrableAssets,
    remainingAmountToTransfer,
    absoluteRemainingAmountToTransfer,
    isAssetSplitCompleted,
    isOwingParty,
    otherPartyIsMakingAnOffer,
    shouldShowViewProposalDialog,
  } = AssetProps();

  const {
    other: { party: other },
  } = matter;

  useEffect(() => {
    if (isAssetSplitCompleted) {
      navigate(ODRS_PROPERTY_ASSET_DIVISION_ROUTE);
    }

    if (otherPartyIsMakingAnOffer) {
      navigate(ODRS_DIVISION_ASSETS_VIEW_OFFER);
    }
  }, []);

  const assets = transferrableAssets.filter(
    item => !item.isJointlyOwned && item[other] > 0
  );

  return (
    <DivisionAssetsProvider>
      <PageContainer>
        <Header tab="transfer-sole" />
        <TransferIntro>
          <Paragraph large>
            You can choose how {MatterProps('other.firstname')}
            &apos;s assets will be assigned.
          </Paragraph>
        </TransferIntro>

        <PageContent>
          <Heading2>Assets available</Heading2>

          {assets.length > 0 && (
            <>
              <Paragraph>
                Please select the assets you would like to transfer.
              </Paragraph>

              <TransferAssetsList assets={assets} />
            </>
          )}

          {assets.length === 0 && (
            <Paragraph large>
              {MatterProps('other.firstname')} does not have any assets to
              transfer.
            </Paragraph>
          )}
        </PageContent>
        <TransferFooter
          nextRoute={ODRS_DIVISION_ASSETS_TRANSFER_JOINT}
          buttonLabel="Next: Joint assets"
          remainingAmountToTransfer={remainingAmountToTransfer}
          absoluteRemainingAmountToTransfer={absoluteRemainingAmountToTransfer}
          isAssetSplitCompleted={isAssetSplitCompleted}
          isOwingParty={isOwingParty}
        />
      </PageContainer>
      <DialogViewProposal showDialog={shouldShowViewProposalDialog} />
    </DivisionAssetsProvider>
  );
};

export default DivisionAssetsTransferOther;
