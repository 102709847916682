import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DEFAULT_OTHER_TEXT } from 'Common/constants';
import MatterPropJS from 'Common/Utils/MatterProps';
import {
  ODRS_JOIN_ROUTE,
  INTRO_ROUTE,
  ODRS_LOGIN_ROUTE,
  ODRS_INVITE_ROUTE,
} from 'Common/routes';

import AmicaLogo from 'App/UI/AmicaLogo';
import Loader from 'Common/UI/Loader/Loader';
import capitaliseText from 'Common/Utils/capitaliseText';

import GenericButton from 'Common/UI/Button/GenericButton';
import PageHeader from 'Common/UI/Layout/PageHeader';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { getParameterByName } from 'Common/Utils/queryString';
import { checkInviteAction } from 'App/State/MatterActions';
import {
  ButtonContainer,
  ButtonsWrapper,
  InnerContentMiddle,
  StyledPageContent,
} from '../../../../../../common/UI/Signup/Layout';

const LogoWrapper = styled.div`
  color: white;
  width: 85px;
  transition: all 0.3s 0.1s;
  position: absolute;
  top: ${({ theme }) => theme.spacing[8]};
  left: ${({ theme }) => theme.spacing[6]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: ${({ theme }) => theme.spacing[12]};
    left: ${({ theme }) => theme.spacing[8]};
  }
`;

const SecondaryButton = styled(GenericButton)`
  background: white;
  color: ${props => props.theme.colours.darkPurple};
  margin-left: 0;
  margin-top: ${({ theme }) => theme.spacing[6]};
  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin-left: ${({ theme }) => theme.spacing[6]};
    margin-top: 0;
  }
  &:hover {
    color: white;
  }
`;

const StyledPageContentWithPadding = styled(StyledPageContent)`
  padding: 0 0 24px;

  ${({ theme }) => theme.tokens.questionsHorizontalSpacing}
`;

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  max-width: 600px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    display: flex;
  }
`;

const STATE_LOADING = 'STATE_LOADING';
const STATE_LOADED = 'STATE_LOADED';
const STATE_ERROR = 'STATE_ERROR';

class Invite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inviteCode: getParameterByName('invite'),
      state: STATE_LOADING,
    };
  }

  componentDidMount() {
    const { inviteCode } = this.state;
    const { loadInvite } = this.props;

    loadInvite(inviteCode)
      .then(() => this.setState({ state: STATE_LOADED }))
      .catch(() => this.setState({ state: STATE_ERROR }));
  }

  render() {
    const { navigate, matter } = this.props;
    const { inviteCode, state } = this.state;

    if (state === STATE_LOADING) {
      return (
        <PageHeader>
          <LogoWrapper>
            <AmicaLogo />
            <Loader floating />
          </LogoWrapper>
        </PageHeader>
      );
    }

    const hasValidInvite =
      matter.invite &&
      matter.invite.InviteID === inviteCode &&
      !matter.invite.claimed;

    return (
      <>
        <PageHeader>
          <LogoWrapper>
            <AmicaLogo />
          </LogoWrapper>
        </PageHeader>
        <StyledPageContentWithPadding>
          <InnerContentMiddle>
            <ResponsiveTextContainer>
              <H1Conversational data-cy="landing-heading">
                Welcome to amica
              </H1Conversational>
              {hasValidInvite && (
                <Paragraph large>
                  {`Hi ${MatterPropJS(
                    'self.firstname',
                    DEFAULT_OTHER_TEXT
                  )}, ${capitaliseText(
                    MatterPropJS('other.firstname', DEFAULT_OTHER_TEXT)
                  )} has invited you to use amica so you can work together to divide your money and property and reach parenting agreements.`}
                </Paragraph>
              )}
              {!hasValidInvite && (
                <>
                  <Paragraph>
                    This invite link has expired or is invalid. Check the link
                    you followed in your email.
                  </Paragraph>
                  <Paragraph>
                    If you already have an account you can log in
                  </Paragraph>
                </>
              )}
            </ResponsiveTextContainer>
          </InnerContentMiddle>
          <StyledButtonsWrapper>
            {!hasValidInvite && (
              <ButtonContainer>
                <GenericButton
                  fullWidth
                  onClick={() => navigate(ODRS_LOGIN_ROUTE)}
                >
                  Login
                </GenericButton>
              </ButtonContainer>
            )}
            {hasValidInvite && (
              <>
                <GenericButton
                  fullWidth
                  onClick={() =>
                    navigate(INTRO_ROUTE, {
                      next: ODRS_JOIN_ROUTE,
                      back: `${ODRS_INVITE_ROUTE}?invite=${inviteCode}`,
                      ...matter.invite,
                    })
                  }
                >
                  Get started
                </GenericButton>
                <ReferralLink fullWidth href="https://www.amica.gov.au">
                  <SecondaryButton fullWidth>Learn more</SecondaryButton>
                </ReferralLink>
              </>
            )}
          </StyledButtonsWrapper>
        </StyledPageContentWithPadding>
      </>
    );
  }
}

Invite.propTypes = {
  navigate: PropTypes.func.isRequired,
  loadInvite: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object,
};

Invite.defaultProps = {
  matter: {},
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
  loadInvite: code => dispatch(checkInviteAction(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
