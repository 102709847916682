import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import PageContainer from 'Common/UI/Layout/PageContainer';
import baseTheme, { blackTheme, greyHelpButtonTheme } from 'Common/Utils/theme';
import { useAppSelector, useAppDispatch } from 'App/State/Store';
import { Link } from 'react-router-dom';
import { ODRS_PROFILE_ROUTE, ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import { getAgreementDocURLAction } from 'App/State/MatterActions';
import {
  DOC_TYPE_MINUTES_OF_CONSENT,
  DOC_TYPE_CONSENT_ORDERS,
  DOC_TYPE_PARENTING_PLAN,
  DOC_TYPE_PARENTING_AGREEMENT,
  DOC_TYPE_PROPERTY_AGREEMENT,
  DOCUMENT_CARD_PAYMENT_REQUIRED,
  DOCUMENT_CARD_COMPLETE,
  DocumentCardStatus,
  STATUS_APPROVED,
} from 'Common/constants';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { isAmicaFree, isPaymentDue } from 'Common/Utils/Payments';
import push from 'Common/Utils/push';
import openResolvedPromiseURL from 'Common/Utils/openResolvedPromiseURL';
import {
  getFilingGuideURL,
  getConsentKitInstructionsURL,
} from 'Common/Utils/consentOrderLinks';
import {
  getNewParentingPlanState,
  getConsentOrderExportState,
  getParentingState,
  getPropertyState,
} from 'App/Utils/sectionHelpers';
import { getUserAttributesAction } from 'App/State/UserActions';
import { Heading1 } from 'Common/UI/Text/Headings';
import MainDrawer from 'Common/UI/Layout/MainDrawer';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import Paragraph from 'Common/UI/Text/Paragraph';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import HelpButton from 'Common/UI/Button/HelpButton';
import AppHeader from 'App/UI/AppHeader';
import AssetProperties from 'Common/Data/App/assetProperties';
import { checkPageUnLocked } from 'Common/Utils/consentOrderPageChecks';
import { getMatter } from 'Common/Utils/MatterProps';
import getDocumentCardStatus from 'App/UI/DocumentCard/getDocumentCardStatus';
import ParentingAgreement from 'App/UI/DocumentCard/Parenting/ParentingAgreement';
import ParentingPlan from 'App/UI/DocumentCard/Parenting/ParentingPlan';
import PropertyAgreement from 'App/UI/DocumentCard/Property/PropertyAgreement';
import ApplicationForConsentOrders from 'App/UI/DocumentCard/ConsentOrders/ApplicationForConsentOrders';
import DataExport from '../Profile/DataExport';

const StyledHeading1 = styled(Heading1)`
  margin-top: 0;
`;

const StyledMainDrawer = styled(MainDrawer)`
  padding-bottom: ${({ theme }) => theme.spacing[24]};
`;

const Documents = () => {
  const [userAttributes, setUserAttributes] = useState({});

  const matter = useAppSelector(state => state.matter);

  const exportContent = (
    <>
      <Paragraph>
        If you are not ready to create your documents but you would like to see
        a summary of the information you&apos;ve both entered on amica, you can
        export your progress in an email to yourself at any time.
      </Paragraph>
      <Paragraph>
        The email will provide a summary of the information that you have both
        entered so far, but it is not an agreement document.
      </Paragraph>
      <Paragraph>
        If you can&apos;t reach an agreement using amica, you can use the
        information in the email to move forward with your separation in other
        ways.
      </Paragraph>
    </>
  );

  const isFree = isAmicaFree(matter);

  const { withLoading } = usePromiseWithLoading();

  const dispatch = useAppDispatch();

  const getUserAttributes = () => dispatch(getUserAttributesAction()).unwrap();

  useEffect(() => {
    getUserAttributes().then((attrs: Record<string, unknown>) => {
      setUserAttributes(attrs);
    });
  }, []);

  const getParentingAgreementDocURL = () =>
    dispatch(
      getAgreementDocURLAction({ name: DOC_TYPE_PARENTING_AGREEMENT })
    ).unwrap();
  const getNewParentingPlanAgreementDocURL = () =>
    dispatch(
      getAgreementDocURLAction({ name: DOC_TYPE_PARENTING_PLAN })
    ).unwrap();
  const openParentingAgreement = () =>
    openResolvedPromiseURL(withLoading(getParentingAgreementDocURL));
  const openParentingPlan = () =>
    openResolvedPromiseURL(
      withLoading(getNewParentingPlanAgreementDocURL)
    );

  const getPropertyAgreementDocURL = () =>
    dispatch(
      getAgreementDocURLAction({ name: DOC_TYPE_PROPERTY_AGREEMENT })
    ).unwrap();
  const openPropertyAgreement = () =>
    openResolvedPromiseURL(withLoading(getPropertyAgreementDocURL));

  const getConsentOrderDocURL = () =>
    dispatch(
      getAgreementDocURLAction({ name: DOC_TYPE_CONSENT_ORDERS })
    ).unwrap();
  const getMinutesOfConsentDocURL = () =>
    dispatch(
      getAgreementDocURLAction({ name: DOC_TYPE_MINUTES_OF_CONSENT })
    ).unwrap();
  const openConsentOrderAgreement = () =>
    openResolvedPromiseURL(withLoading(getConsentOrderDocURL));
  const openMinutesOfConsentAgreement = () =>
    openResolvedPromiseURL(withLoading(getMinutesOfConsentDocURL));
  const navigate = (route: string, state?: any) => dispatch(push(route, state));

  const parentingAgreementState = getDocumentCardStatus(
    DOC_TYPE_PARENTING_AGREEMENT,
    getParentingState({ matter })
  );

  const parentingPlanState = getDocumentCardStatus(
    DOC_TYPE_PARENTING_PLAN,
    getNewParentingPlanState({ matter })
  );

  const propertyAgreementState = getDocumentCardStatus(
    DOC_TYPE_PROPERTY_AGREEMENT,
    getPropertyState()
  );

  const consentOrdersState = getDocumentCardStatus(
    DOC_TYPE_CONSENT_ORDERS,
    getConsentOrderExportState()
  );

  const documentCards: JSX.Element[] = [];

  const hasAgreementBeenAgreed = (docCardStatus: DocumentCardStatus) =>
    docCardStatus === DOCUMENT_CARD_PAYMENT_REQUIRED ||
    docCardStatus === DOCUMENT_CARD_COMPLETE;

  // if a parenting plan has been agreed to by both parties then we should hide the parenting agreement card
  const shouldHideParentingAgreementCard =
    parentingPlanState === DOCUMENT_CARD_COMPLETE;

  if (
    !shouldHideParentingAgreementCard &&
    hasAgreementBeenAgreed(parentingAgreementState)
  ) {
    documentCards.push(
      <ParentingAgreement
        key="parentingAgreement"
        isFree={isFree}
        isPaid={!isPaymentDue(matter, DOC_TYPE_PARENTING_AGREEMENT)}
        status={parentingAgreementState}
        setPartyFlags={() => Promise.resolve()}
        openAgreementFunctions={{ parentingAgreement: openParentingAgreement }}
        navigate={navigate}
      />
    );
  }

  if (hasAgreementBeenAgreed(parentingPlanState)) {
    documentCards.push(
      <ParentingPlan
        key="parentingPlan"
        status={parentingAgreementState}
        setPartyFlags={() => Promise.resolve()}
        openAgreementFunctions={{ parentingPlan: openParentingPlan }}
      />
    );
  }

  if (hasAgreementBeenAgreed(propertyAgreementState)) {
    const { isSuperIncludedInOffer } = AssetProperties();

    documentCards.push(
      <PropertyAgreement
        key="property"
        isFree={isFree}
        isPaid={!isPaymentDue(matter, DOC_TYPE_PROPERTY_AGREEMENT)}
        status={propertyAgreementState}
        setPartyFlags={() => Promise.resolve()}
        openAgreementFunctions={{ propertyAgreement: openPropertyAgreement }}
        navigate={navigate}
        isDisabled={isSuperIncludedInOffer}
      />
    );
  }

  if (hasAgreementBeenAgreed(consentOrdersState)) {
    const { isSuperIncludedInOffer, superSplittingFlags } = AssetProperties();

    const { payerHasConfirmedNoChangesToClauses } = superSplittingFlags;

    const areMainDocumentsDisabled = !checkPageUnLocked.completed.unlocked();

    let areAdditionalDocumentsDisabled = false;
    let isInDraftMode = false;

    const { items = [] } = getMatter();

    const superannuationItem = items.find(
      item => item.SectionID === 'superannuationfundclauses'
    );

    if (isSuperIncludedInOffer) {
      if (
        !payerHasConfirmedNoChangesToClauses &&
        (!superannuationItem || superannuationItem.status !== STATUS_APPROVED)
      ) {
        isInDraftMode = true;
        areAdditionalDocumentsDisabled = true;
      }
    }

    documentCards.push(
      <ApplicationForConsentOrders
        isFree={isFree}
        isPaid={!isPaymentDue(matter, DOC_TYPE_CONSENT_ORDERS)}
        status={consentOrdersState}
        openAgreementFunctions={{
          proceduralFairnessLetter: () => Promise.resolve(),
          consentOrder: openConsentOrderAgreement,
          minutesOfConsent: openMinutesOfConsentAgreement,
        }}
        guideLinks={{
          applicationInstructions: getConsentKitInstructionsURL(),
          applicationGuide: getFilingGuideURL(),
        }}
        navigate={navigate}
        areMainDocumentsDisabled={areMainDocumentsDisabled}
        areAdditionalDocumentsDisabled={areAdditionalDocumentsDisabled}
        isInDraftMode={isInDraftMode}
      />
    );
  }

  return (
    <ThemeProvider theme={baseTheme}>
      <PageContainer>
        <AppHeader
          withProfile
          matter={matter}
          theme={blackTheme}
          title="Documents"
        />

        <StyledMainDrawer>
          <ResponsiveTextContainer>
            <StyledHeading1>
              {documentCards.length > 0
                ? 'View and download your documents'
                : 'This is where you will be able to view and download your documents'}
            </StyledHeading1>

            <Paragraph large>
              Once you complete the required sections and agree to create a
              document, you will able to download it here.
            </Paragraph>

            <SpacedBox marginTop={10}>
              <ThemeProvider theme={greyHelpButtonTheme}>
                <HelpButton onClick={() => navigate(ODRS_DASH_HOME_ROUTE)}>
                  Continue filling out sections
                </HelpButton>
              </ThemeProvider>
            </SpacedBox>
          </ResponsiveTextContainer>

          <SpacedBox marginTop={24}>
            {documentCards.length > 0 && (
              <ResponsiveContainer>
                <FlexGrid medium={2} large={2}>
                  {documentCards}
                </FlexGrid>
              </ResponsiveContainer>
            )}

            {documentCards.length === 0 && (
              <ResponsiveTextContainer>
                <DataExport
                  heading="If you can’t reach agreement using amica, you can export your progress"
                  matter={matter}
                  userAttributes={userAttributes}
                  emailNotVerifiedContent={
                    <>
                      {exportContent}
                      <Paragraph>
                        You need to verify your email address above before you
                        can export your progress.
                      </Paragraph>
                      <Paragraph>
                        <Link
                          to={{
                            pathname: ODRS_PROFILE_ROUTE,
                            state: { verifyEmail: true },
                          }}
                        >
                          Verify your email now
                        </Link>
                      </Paragraph>
                    </>
                  }
                  readyToExportContent={exportContent}
                />
              </ResponsiveTextContainer>
            )}
          </SpacedBox>
        </StyledMainDrawer>
      </PageContainer>
    </ThemeProvider>
  );
};

export default Documents;
