/*
 * Return either a positive or negative
 * Polyfill for Math.sign() which is not supported in IE
 * */

const sign = x => {
  const xNumber = +x;
  if (xNumber === 0 || isNaN(xNumber)) {
    return Number(xNumber);
  }
  return xNumber > 0 ? 1 : -1;
};

export default sign;
