import moment from 'moment';

import {
  STATUS_APPROVED,
  PARTY_A,
  PARTY_B,
  PARTY_DIVI,
  STATUS_REMOVED,
  CARD_COMPONENT,
  CARD_AGREEMENT,
  SECTION_PROPERTY,
  SECTION_PARENTING,
  SECTION_RELATIONSHIP,
  DATA_SECTION,
  DATA_GROUP,
  DATA_PAGE,
} from 'Common/constants';
import { getAppSections } from 'Common/Data/App/appSections';
import formatCurrency from 'Common/Utils/formatCurrency';
import diviProperties from 'Common/Data/App/diviProperties';
import { getFlattenedSections } from 'Common/Utils/cardHelpers';
import { getFirstName } from './statementHelpers';

const isEmpty = (obj = {}) => {
  if (typeof obj !== 'object') {
    return true;
  }

  return Object.keys(obj).length === 0;
};

/**
 * Basic party information
 */
export default (matter, hideSensitive = false) => {
  const aboutYouA =
    matter.items.find(item => item.SectionID === 'aboutyouA') || {};
  const aboutYouB =
    matter.items.find(item => item.SectionID === 'aboutyouB') || {};
  const children = matter.items.filter(
    item => item.BaseSection === 'children' && item.status !== STATUS_REMOVED
  );

  const sections = getAppSections();

  const excludedCardTypes = [
    DATA_SECTION,
    DATA_GROUP,
    DATA_PAGE,
    CARD_COMPONENT,
  ];

  const partyNames = {
    A: getFirstName(matter.partyA, PARTY_A, hideSensitive),
    B: getFirstName(matter.partyB, PARTY_B, hideSensitive),
  };

  const parties = [
    {
      heading: 'Party A',
      content: `${partyNames.A} (aged ${moment().diff(
        moment(aboutYouA.birthday || undefined),
        'years'
      )})`,
    },
    {
      heading: 'Party B',
      content: `${partyNames.B} (aged ${moment().diff(
        moment(aboutYouB.birthday || undefined),
        'years'
      )})`,
    },
  ];

  let dependantsDetails = ['None'];

  if (children.length > 0) {
    dependantsDetails = children.map(child => {
      const childAge = moment().diff(moment(child.childAge), 'years');
      const name = hideSensitive ? '[redacted]' : child.childName;

      return `${name} (aged ${childAge})`;
    });
  }

  const dependants = [
    {
      heading: `Dependants (${
        children.length > 0 ? dependantsDetails.length : '0'
      })`,
      content: dependantsDetails,
    },
  ];

  /**
   * Relationship
   */
  const relationshipItems = getFlattenedSections({
    appSections: sections,
    section: SECTION_RELATIONSHIP,
  })
    .filter(item => item.status !== STATUS_REMOVED)
    .filter(item => !excludedCardTypes.includes(item.type));

  // Relationship cards

  // Used for mapping groups of relationship cards.
  const relationshipCardFormatter = child => {
    if (!isEmpty(child.data)) {
      const title =
        child.sensitiveTitle && hideSensitive
          ? child.sensitiveTitle
          : `${child.title}`;

      return {
        heading: `${title} ${
          child.data.status === STATUS_APPROVED ? '(Agreed)' : '(Not Agreed)'
        }`,
        content: child.statement({
          matter,
          card: child,
          data: child.data,
          withUpdateLink: false,
          plainText: true,
          hideSensitive,
        }),
        files: child.questions
          .filter(q => q.type === 'docupload')
          .map(q => child.data[q.name])
          .filter(i => i !== null && i !== undefined)
          .reduce((allFiles, theseFiles) => allFiles.concat(theseFiles), []),
      };
    }
    return null;
  };

  const yourRelationshipInfo = relationshipItems
    .filter(item => item.cardID === 'relationship')
    .map(relationshipCardFormatter)
    .filter(s => s !== null);

  const yourChildrenInfo = relationshipItems
    .filter(item => item.cardID.indexOf('children') === 0)
    .map(relationshipCardFormatter)
    .filter(s => s !== null);

  const aboutUsInfo = relationshipItems
    .filter(item => ['aboutyouA', 'aboutyouB'].includes(item.cardID))
    .map(relationshipCardFormatter)
    .filter(s => s !== null);

  /**
   * Property
   */
  const propertySettlementFormatter = child => {
    if (!isEmpty(child.data)) {
      const title =
        child.sensitiveTitle && hideSensitive
          ? child.sensitiveTitle
          : `${child.title}`;

      return {
        heading: `${title} ${
          child.data.status === STATUS_APPROVED ? '(Agreed)' : '(Not Agreed)'
        }`,
        content: child.statement({
          matter,
          card: child,
          data: child.data,
          plainText: true,
          withUpdateLink: false,
          hideSensitive,
        }),
      };
    }
    return null;
  };

  const settlementItems = getFlattenedSections({
    appSections: sections,
    section: SECTION_PROPERTY,
  })
    .filter(item => item.status !== STATUS_REMOVED)
    .filter(item => !excludedCardTypes.includes(item.type));

  const financesInOrder = [
    /^yourfinances[AB]$/,
    /^sharedfinances$/,
    /^properties[\d]+$/,
    /^vehicles[\d]+$/,
    /^yoursuperannuation[AB]$/,
    /^contributions[AB]$/,
  ]
    .reduce((acc, IDMatch) => {
      // Find each item matching the ID REGEXP
      // Sort should account for A->B as well as
      // acending digits in property and vehicles.
      const matchingItems = settlementItems
        .filter(card => card.cardID.match(IDMatch))
        .sort();
      return [...acc, ...matchingItems];
    }, [])
    .map(propertySettlementFormatter)
    .filter(s => s !== null);

  /**
   * Suggested Division
   */
  const { agreedToDivision, isMatterInDivision, TAP, getOffers } =
    diviProperties();

  const suggestedDivision = [];

  if (!isMatterInDivision) {
    suggestedDivision.push({
      heading: 'Asset settlement',
      content: 'Not started.',
    });
  } else {
    const offers = getOffers();
    const offersContent = offers.map(offer => {
      if (offer.party === PARTY_DIVI) {
        return `Amica suggested that the split of assets (${formatCurrency(
          TAP
        )}) should be ${offer.partyA.percentage}% (${offer.partyA.amount}) to ${
          partyNames.A
        } and ${offer.partyB.percentage}% (${offer.partyB.amount}) to ${
          partyNames.B
        }.`;
      }

      return `${
        partyNames[offer.party]
      } suggested that the split of assets (${formatCurrency(TAP)}) should be ${
        offer.partyA.percentage
      }% (${offer.partyA.amount}) to ${partyNames.A} and ${
        offer.partyB.percentage
      }% (${offer.partyB.amount}) to ${partyNames.B}.`;
    });

    offersContent.push('');

    if (agreedToDivision) {
      const agreedOffer = offers[offers.length - 1];
      offersContent.push(
        `The final agreed split of assets (${formatCurrency(TAP)}) is ${
          agreedOffer.partyA.percentage
        }% (${agreedOffer.partyA.amount}) to ${partyNames.A} and ${
          agreedOffer.partyB.percentage
        }% (${agreedOffer.partyB.amount}) to ${partyNames.B}.`
      );
    } else {
      offersContent.push('No final asset settlement has been agreed to.');
    }

    suggestedDivision.push({
      heading: 'Asset settlement',
      content: offersContent,
    });
  }

  /**
   * Parenting
   */
  const parentingSection = getFlattenedSections({
    appSections: sections,
    section: SECTION_PARENTING,
  });

  const parenting = parentingSection
    .filter(item => item.status !== STATUS_REMOVED)
    .filter(item => !excludedCardTypes.includes(item.type))
    .filter(
      item =>
        !(item.type === CARD_AGREEMENT && item.index === matter.other.party)
    )
    .map(child => {
      if (!isEmpty(child.data)) {
        const title =
          child.sensitiveTitle && hideSensitive
            ? child.sensitiveTitle
            : `${child.title}`;

        return {
          heading: title,
          content: child.statement({
            matter,
            card: child,
            data: child.data,
            plainText: true,
            withUpdateLink: false,
            hideSensitive,
          }),
        };
      }
      return null;
    })
    .filter(s => s !== null);

  const content = [
    ...parties,
    ...yourRelationshipInfo,
    ...dependants,
    ...yourChildrenInfo,
    ...aboutUsInfo,
    ...financesInOrder,
    ...suggestedDivision,
    ...parenting,
  ];
  return content;
};
