import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  STATUS_CREATED,
  STATUS_UPDATE_DONE,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_SELF_PAYEE,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import AssetProperties from 'Common/Data/App/assetProperties';
import { getCard } from 'Common/Data/App/appSections';
import { getMatter } from 'Common/Utils/MatterProps';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';

export const superannuationConsentOrderChangesWaitingForSelfPayeeFlags: AppState =
  {
    id: 'superannuationConsentOrderChangesWaitingForSelfPayee',
    description:
      'Notify payee that they need to review and accept the superannuation clause changes',
    check: () => {
      const { isSuperIncludedInOffer, isPayee, superSplittingFlags } =
        AssetProperties();

      const {
        payerHasSubmittedProceduralFairnessLetter,
        payerHasConfirmedNoChangesToClauses,
      } = superSplittingFlags;

      if (
        !isSuperIncludedInOffer ||
        !isPayee ||
        !payerHasSubmittedProceduralFairnessLetter ||
        payerHasConfirmedNoChangesToClauses
      ) {
        return false;
      }

      const { items = [] } = getMatter();

      const superannuationItem = items.find(
        item => item.SectionID === 'superannuationfundclauses'
      );

      // if the superannuation item doesn't exist, then this app state is not relevant so return false
      if (!superannuationItem) {
        return false;
      }

      const { status } = superannuationItem;

      // if the card has just been created or updated by the payer then the payee needs to review so return true
      // NOTE: this logic should match the logic in `SuperannuationFundClauseEditorCard.tsx` as there is a matching card state
      if (status === STATUS_CREATED || status === STATUS_UPDATE_DONE) {
        return true;
      }

      return false;
    },
    flags: {
      appState: SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_SELF_PAYEE,
    },
  };

type Props = {
  otherName: string;
};

export const SuperannuationConsentOrderChangesWaitingForSelfPayee: React.FC<Props> =
  ({ otherName }) => {
    const card = getCard('superannuationfundclauses');

    const dispatch = useAppDispatch();

    if (!card || !('viewRoute' in card)) {
      return null;
    }

    return (
      <div data-cy="banner-consent-order-changes-waiting-for-self">
        <SpacedBox marginBottom={8}>
          <Heading2MarginTopNone>
            {otherName} has made some updates to the draft minutes of consent
          </Heading2MarginTopNone>
          <Paragraph>
            {otherName} sent your draft minutes of consent to the trustee of
            their superannuation fund. The trustee has requested some changes.
            If you agree to these changes, your application for consent orders
            is ready to be filed with the court.
          </Paragraph>
        </SpacedBox>
        <GenericButton
          fullWidth
          onClick={() => dispatch(push(card.viewRoute))}
          small
          data-cy="view-changes-button"
        >
          View changes
        </GenericButton>
      </div>
    );
  };
