import React from 'react';
import styled from 'styled-components';

import AmicaLogo from 'App/UI/AmicaLogo';

const LogoContainer = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.padding.medium}px;
  top: ${({ theme }) => theme.padding.medium}px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    left: ${({ theme }) => theme.padding.xlarge}px;
    top: ${({ theme }) => theme.padding.xlarge}px;
  }

  svg {
    height: auto;
    width: 77px;
    transition: color 0.2s 0.1s;

    color: ${props => props.logoColor || props.theme.logoColour};

    @media ${({ theme }) => theme.sizes.md.breakpoint} {
      width: 92px;
    }
  }
`;
const AppLogo = () => (
  <LogoContainer>
    <AmicaLogo />
  </LogoContainer>
);

export default AppLogo;
