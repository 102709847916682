import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import useWindowSize from '../../Utils/Hooks/useWindowSize';
import useMobileDetect from '../../Utils/Hooks/useDeviceDetect';

const StyledContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.sizes.md.container}) {
    ${({ mobileHeight, isMobileDevice, heightAdjustment }) =>
      isMobileDevice &&
      css`
        position: fixed;
        left: 0;
        width: 100%;
        top: ${mobileHeight - heightAdjustment}px;
        transition: none;
      `}
  }
`;

const MobileFixedContainer = ({ children, heightAdjustment, ...props }) => {
  const { isMobile } = useMobileDetect();
  const isMobileDevice = isMobile();
  const [width, height] = useWindowSize(0);
  const [initialHeight, setInitialHeight] = useState(0);
  useEffect(() => {
    if (isMobileDevice && height && !initialHeight) {
      setInitialHeight(height);
    }
  }, [height]);
  return (
    <StyledContainer
      isMobileDevice={isMobileDevice}
      mobileHeight={initialHeight > height ? initialHeight : height}
      heightAdjustment={heightAdjustment}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};

MobileFixedContainer.propTypes = {
  children: PropTypes.node.isRequired,
  heightAdjustment: PropTypes.number,
};

MobileFixedContainer.defaultProps = {
  heightAdjustment: 0,
};

export default MobileFixedContainer;
