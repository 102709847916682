import {
  getParentingState,
  hasSectionBeenCompleted,
  hasSectionBeenStarted,
} from 'App/Utils/sectionHelpers';
import {
  AGREEMENT_INCOMPLETE,
  AGREEMENT_READY_TO_EXPORT,
  DashboardPages,
  PAGE_CONSENT_ORDERS_COMPLETED,
  Sections,
  SECTION_CONSENT_ORDERS,
  SECTION_PARENTING,
  SECTION_PROPERTY,
  SECTION_RELATIONSHIP,
} from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import {
  areRelationshipAssetsAllAccountedFor,
  areSettlementAssetsAllAccountedFor,
} from 'App/Utils/matterHelpers';
import AssetProps from 'Common/Data/App/assetProperties';
import getChildrenData from 'Common/Utils/getChildrenData';
import { getPage } from 'Common/Data/App/appSections';
import {
  checkPageUnLocked,
  getStartedCheck,
} from 'Common/Utils/consentOrderPageChecks';
import { isConsentOrdersAgreedToStart } from 'Common/Utils/consentOrders';

/**
 * Checks if the cards on a given page/section are locked or unlocked
 *
 * @param matter              the matter object
 * @param section             the section to check
 * @param page (optional)     the page to check
 * @returns boolean           true if the page contents are unlocked, false otherwise
 */
const isPageContentsUnlocked = (
  matter: Matter,
  section?: Sections,
  page?: DashboardPages
) => {
  switch (section) {
    case SECTION_PROPERTY: {
      // section is locked if the relationship section has not been completed
      if (
        !hasSectionBeenCompleted({
          section: SECTION_RELATIONSHIP,
          checkBothParties: true,
        })
      ) {
        return false;
      }

      // section is locked if either party has started the suggested division flow
      if (
        matter.self.hasConfirmedStatements ||
        matter.other.hasConfirmedStatements
      ) {
        return false;
      }

      const { isTAPNegative } = AssetProps();

      // section is locked if all cards have been completed and approved but the users have a negative total asset pool
      if (areSettlementAssetsAllAccountedFor() && isTAPNegative) {
        return true;
      }

      // section is unlocked
      return true;
    }

    case SECTION_RELATIONSHIP:
      // section is locked if either the parenting or property sections have been started
      // otherwise, section is unlocked
      return !(
        hasSectionBeenStarted({
          section: SECTION_PROPERTY,
          checkBothParties: true,
        }) ||
        hasSectionBeenStarted({
          section: SECTION_PARENTING,
          checkBothParties: true,
        })
      );
    case SECTION_PARENTING: {
      // section is locked either of these are true:
      // - the relationship section has not been completed
      // - there are no children in the matter
      if (
        [
          !areRelationshipAssetsAllAccountedFor(),
          matter.childrenData
            ? !matter.childrenData.haveBeenConfirmed
            : !getChildrenData(matter.items).haveBeenConfirmed,
        ].some(condition => condition === true)
      ) {
        return false;
      }

      const state = getParentingState({ matter });

      // section is locked if the document generation flow has been started
      if (![AGREEMENT_INCOMPLETE, AGREEMENT_READY_TO_EXPORT].includes(state)) {
        return false;
      }

      // section is unlocked
      return true;
    }
    case SECTION_CONSENT_ORDERS: {
      // section is locked if the relationship or property sections have not been completed
      if (
        !hasSectionBeenCompleted({
          section: SECTION_RELATIONSHIP,
          checkBothParties: true,
        }) ||
        !hasSectionBeenCompleted({
          section: SECTION_PROPERTY,
          checkBothParties: true,
        })
      ) {
        return false;
      }

      const {
        isAssetSplitCompleted,
        isSuperIncludedInOffer,
        superSplittingFlags: { payerHasSubmittedProceduralFairnessLetter },
      } = AssetProps();

      // section is locked if the asset split flow has not been completed
      if (!isAssetSplitCompleted) {
        return false;
      }

      // users have started the super splitting steps at the end of the CO dashboard so we need to lock all cards except for the completed page
      if (isSuperIncludedInOffer && payerHasSubmittedProceduralFairnessLetter) {
        return page === PAGE_CONSENT_ORDERS_COMPLETED;
      }

      const {
        flags: { superannuationNotSuitable = false },
      } = matter;

      // section is locked if the superannuation is not suitable
      if (superannuationNotSuitable) {
        return false;
      }

      // section is locked if the users have not agreed to start the consent orders flow
      if (!isConsentOrdersAgreedToStart()) {
        return false;
      }

      // section is locked if they are not suitable after completing the requirements and jurisdiction cards
      if (
        checkPageUnLocked.getStarted.completed() &&
        (getStartedCheck.requiredNotSuited() ||
          (typeof getStartedCheck.jurisdictionSuited() !== 'undefined' &&
            !getStartedCheck.jurisdictionSuited()))
      ) {
        return false;
      }

      // section is unlocked if we're not checking a specific page
      if (!page) {
        return true;
      }

      const pageData = getPage(section, page);

      // section is unlocked if the page data is not found
      if (!pageData) {
        return true;
      }

      const { generateStatus } = pageData;

      // section is unlocked if the generateStatus function is not found
      if (!generateStatus) {
        return true;
      }

      const status = generateStatus(matter);

      // section is unlocked if the status is not locked
      if (status !== 'locked') {
        return true;
      }

      // section is locked
      return false;
    }
    // should never hit this case
    default:
      return false;
  }
};

export default isPageContentsUnlocked;
