import React from 'react';
import { Callout } from 'Common/UI/Text/Paragraph';

import { SlideInner, SlideText } from 'Common/UI/Carousel/ImageSlideWrapper';

const AssetTransferSlide2 = () => (
  <SlideInner>
    <SlideText paddingTop data-cy="asset-transfer-slide-2">
      <Callout>
        You have the flexibility to work out an arrangement that suits you both.
        For jointly owned assets, you can suggest whether to keep or transfer
        each asset.
      </Callout>
      <Callout>
        *Note: if any finance arrangements are in place you may require approval
        from the lender before any change in ownership can be put into effect.
      </Callout>
      <Callout>
        Once you have suggested how you want to divide assets, amica can
        calculate whether one of you also needs to pay a lump sum payment to
        achieve the Agreed Percentages.
      </Callout>
    </SlideText>
  </SlideInner>
);

export default AssetTransferSlide2;
