const DialogCancelSuperannuationBalanceRequestContent = {
  heading:
    'Do you want to remove your request for more information about their superannuation balance?',
  paragraph:
    'You will be able to review their superannuation statement and approve or send them a message.',
  noButton: 'No, thanks',
  yesButton: 'Yes, remove',
};

export default DialogCancelSuperannuationBalanceRequestContent;
