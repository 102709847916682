import React, { useState } from 'react';

import {
  ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
  ODRS_YOURSUPERANNUATION_LETTER_UPLOAD_SUCCESS_ROUTE,
} from 'Common/routes';
import ConfirmSuperHeader from 'Common/Assets/images/confirmsuper-header-image.png';
import Paragraph from 'Common/UI/Text/Paragraph';
import CardQuestionFlowHeader from 'Common/UI/Questions/QuestionFlowHeader';
import { StyledPageContent } from 'Common/UI/Questions/common';
import QuestionsFlowWrapper from 'App/UI/Questions/QuestionsFlowWrapper';
import colours from 'Common/Utils/colours';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import { useAppDispatch } from 'App/State/Store';
import { MESSAGE_STATUS_UPDATE_DONE } from 'Common/constants';
import push from 'Common/Utils/push';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { useParams } from 'react-router';
import { CardYourSuperannuation } from 'Common/Data/Types/appSections';
import { definitelyGetCard } from 'Common/Data/App/appSections';
import getNames from 'Common/Utils/getNames';

const UploadReceivedLetter: React.FC = () => {
  const [values, setValues] = useState<Record<string, any>>({});
  const { withLoading } = usePromiseWithLoading();
  const dispatch = useAppDispatch();

  // get the card index from the url
  const { index: paramsIndex } = useParams<{ index?: string }>();

  const cardID = `yoursuperannuation${paramsIndex}`;

  const card = definitelyGetCard<CardYourSuperannuation>(cardID);

  const navigate = (route: string) => dispatch(push(route));

  const selfParty = getPartyKeys().self;
  const otherName = getNames().other;

  const onSubmit = () =>
    withLoading(async () => {
      const messageData = {
        section: cardID,
        owner: selfParty,
        message: '',
        status: MESSAGE_STATUS_UPDATE_DONE,
        checkTone: false,
      };

      const finalValues = { ...values };

      // if the value is form6Response, we need to extract the image path
      // this is usually handled by a `preSaveResponses` function but that is handled by CardQuestionsFlow which isn't used here
      if (finalValues.form6Response) {
        finalValues.form6Response = values.form6Response.map(
          (v: Record<string, unknown>) => v.imagePath
        );
      }

      await dispatch(
        updateMatterSectionAction({
          section: card.cardIDAsArray,
          sectionData: {
            ...card.data,
            ...finalValues,
            __flags: {
              ...card.data.__flags,
              letterUploaded: true,
            },
          },
          messageData,
        })
      );

      navigate(ODRS_YOURSUPERANNUATION_LETTER_UPLOAD_SUCCESS_ROUTE);
    });

  return (
    <>
      <CardQuestionFlowHeader
        backgroundImage={ConfirmSuperHeader}
        backgroundColour={colours.offWhite}
        previousRoute={ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE}
      />
      <StyledPageContent>
        <QuestionsFlowWrapper
          questions={[
            {
              name: 'form6Response',
              label: () => 'Please upload supporting documents',
              content: (
                <Paragraph>
                  Upload the letter you received from {otherName}’s
                  superannuation fund
                </Paragraph>
              ),
              preSaveResponses: vals => vals,
              type: 'docupload',
              defaultValue: [],
              index: 0,
            },
          ]}
          onInputChange={(key: string, value: unknown) =>
            setValues({ ...values, [key]: value })
          }
          values={values}
          onSubmit={onSubmit}
          title="Upload document"
        />
      </StyledPageContent>
    </>
  );
};

export default UploadReceivedLetter;
