import React from 'react';
import moment from 'moment';

import MatterPropsJS from 'Common/Utils/MatterProps';
import formatCurrency from 'Common/Utils/formatCurrency';
import { DATE_FORMAT_LONG, PARTY_A, PARTY_B, Party } from 'Common/constants';
import isDate from 'Common/Utils/isDate';
import isNumeric from 'Common/Utils/isNumeric';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';

export const contributionsLabel = 'Contributions';

export const contributionsQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about the contributions you made
        during the relationship.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    help: {
      label: 'Why is this important?',
      content: (
        <>
          <H1Conversational>Why is this important?</H1Conversational>

          <Paragraph large>
            Because the contributions that you made throughout the relationship
            can affect how to divide the money and property.
          </Paragraph>
          <Paragraph large>Contributions can include:</Paragraph>
          <ul>
            <li>
              Financial contributions made directly or indirectly such as your
              income or any gift of money (for example, from your parents).
            </li>
            <li>
              Non-financial contributions made directly or indirectly by you,
              which can include care that you provided for the children and
              looking after the family home.
            </li>
          </ul>
        </>
      ),
    },
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'initialPersonalAssetsValue',
    label: () =>
      `How much were your personal money and property assets worth when you and ${MatterPropsJS(
        'other.firstname'
      )} started living together?`,
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 1,
    validate: value =>
      isNumeric(value)
        ? [true]
        : [
            false,
            'Please tell us what your personal money and property assets were worth when you and your partner got together',
          ],
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This means the total of your own money and property at the start of
            the relationship. It includes how much you had in savings and the
            value of any property you owned.
          </Paragraph>
          <Paragraph large>
            Tell us how much this was worth when your relationship started, not
            how much it is worth now. It will not necessarily mean you will be
            able to keep the things that you owned when you got together but
            they will be considered as part of your contributions by amica when
            assessing the division of property.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'otherContributionsReceived',
    label:
      'Did you receive any inheritance, redundancy payouts or large gifts during the relationship?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 2,
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>
          <Paragraph large>
            This includes any large payouts you received from an employer,
            inheritance or valuable gifts, such as sums of money or property.
            Inheritance, large payouts and gifts are counted as part of your
            financial contribution to the relationship.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'otherContributionsValue',
    label: 'How much was this worth in total?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 3,
    visible: answers => !!answers.otherContributionsReceived,
    validate: value =>
      isNumeric(value) ? [true] : [false, 'Please enter the value'],
  },
  {
    name: 'otherContributionWhenReceive',
    label: 'When did you receive it?',
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 4,
    visible: answers => !!answers.otherContributionsReceived,
    validate: value =>
      isDate(value) ? [true] : [false, 'Please tell us when you received it'],
    formAttributes: { max: new Date() },
  },
  {
    name: 'paidOthersDebt',
    label: () =>
      'Did you make any individual financial contributions during the relationship? (e.g. towards the mortgage or any other type of debt)?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 5,
  },
  {
    name: 'paidOthersDebtValue',
    label: 'How much?',
    hint: '',
    type: 'currency',
    defaultValue: '',
    index: 6,
    visible: answers => !!answers.paidOthersDebt,
    validate: value =>
      isNumeric(value)
        ? [true]
        : [
            false,
            'Please enter the value of the debts that you paid off for your partner during the relationship',
          ],
  },
  {
    name: 'primaryCarer',
    label: 'Were you the primary carer for any child of the relationship?',
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 7,
    help: {
      label: 'Why is this important?',
      content: (
        <>
          <H1Conversational>Why is this important?</H1Conversational>
          <Paragraph large>
            The contributions made as a home maker and looking after the
            children are taken into consideration when dividing money and
            property after separation.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'primaryCarerLength',
    label: 'How long were you the primary carer?',
    hint: '',
    type: 'select',
    options: () => [
      { label: 'Less than 12 months', value: 'Less than 12 months' },
      { label: '1 to 2 years', value: '1 to 2 years' },
      { label: '3 to 5 years', value: '3 to 5 years' },
      { label: 'More than 5 years', value: 'More than 5 years' },
    ],
    defaultValue: '',
    index: 8,
    visible: answers => !!answers.primaryCarer,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 9,
  },
];

export const contributionsStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const party: Party = data.creator || matter.self.party;

  const { itemHistory } = matter;
  const itemDiff = getUpdatedItemValues(data, itemHistory, [
    'initialPersonalAssetsValue',
    'otherContributionsValue',
    'otherContributionsReceived',
    'otherContributionWhenReceive',
    'paidOthersDebtValue',
    'paidOthersDebt',
    'primaryCarer',
    'primaryCarerLength',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(contributionsQuestions, data, hideSensitive);

  let yourName;
  let partnerName;
  if (party === PARTY_A) {
    yourName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
    partnerName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  } else {
    yourName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
    partnerName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  }

  const assets = highlight`${yourName} had personal assets that were worth 
    ${updated(
      'initialPersonalAssetsValue',
      redact(
        'initialPersonalAssetsValue',
        formatCurrency(data.initialPersonalAssetsValue)
      )
    )} at the start of the relationship.`;

  let otherContributions;

  if (data.otherContributionsReceived) {
    otherContributions = highlight`They received inheritance, redundancy payouts, or large gifts during the relationship worth 
      ${updated(
        ['otherContributionsValue', 'otherContributionsReceived'],
        redact(
          'otherContributionsValue',
          formatCurrency(data.otherContributionsValue)
        )
      )} 
      on ${updated(
        ['otherContributionsValue', 'otherContributionWhenReceive'],
        redact(
          'otherContributionWhenReceive',
          moment(data.otherContributionWhenReceive).format(DATE_FORMAT_LONG)
        )
      )}.`;
  } else {
    otherContributions = highlight`They ${updated(
      'otherContributionsReceived',
      'did not'
    )} 
      receive any inheritance, redundancy payouts, or large gifts during the relationship.`;
  }

  let debts;

  if (data.paidOthersDebt) {
    debts = highlight`${yourName} made individual financial contributions during the relationship with ${partnerName} worth ${updated(
      ['paidOthersDebtValue', 'paidOthersDebt'],
      redact('paidOthersDebtValue', formatCurrency(data.paidOthersDebtValue))
    )}.`;
  } else {
    debts = highlight`${yourName} ${updated(
      'paidOthersDebt',
      'has not'
    )} made individual financial contributions.`;
  }

  let children;

  if (data.primaryCarer) {
    children = highlight`They took a total of ${updated(
      ['primaryCarer', 'primaryCarerLength'],
      redact('primaryCarerLength', data.primaryCarerLength as string)
    )} 
      off to look after their children.`;
  } else {
    children = highlight`They ${updated(
      'primaryCarer',
      'did not'
    )} take time off to look after their children / do not have any children.`;
  }

  if (plainText) {
    return `${assets} ${otherContributions} ${debts} ${children}`;
  }

  return (
    <>
      {assets} {otherContributions} {debts} {children}
    </>
  );
};
