import React from 'react';
import CardQuestionsFlow from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../../Guard/MatterGuard';

const ODRSYourSuperannuationVersionAware: React.FC = props => (
  <MatterGuard>
    <CardQuestionsFlow baseID="yoursuperannuation" indexFromParams {...props} />
  </MatterGuard>
);

export default ODRSYourSuperannuationVersionAware;
