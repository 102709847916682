import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { ODRS_INVITE_PARTNER_ROUTE } from 'Common/routes';
import BackButton from 'Common/UI/Button/BackButton';
import PageContent from 'Common/UI/Layout/PageContent';
import { Callout } from 'Common/UI/Text/Paragraph';
import { H2Conversational } from 'Common/UI/Text/Headings';
import PageHeader from 'Common/UI/Layout/PageHeader';
import theme from 'Common/Utils/theme';
import StepTransition from 'App/UI/Transitions/StepTransition';
import { RouteContext } from 'App/UI/Transitions/RouteContext';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';
import ProgressBar from 'Common/UI/ProgressBar';
import { StyledPageFooter } from '../../../../../../common/UI/Signup/Layout';

const StyledPageContent = styled(PageContent)`
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  overflow: auto;
`;

const InnerContent = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  overflow-y: auto;
`;

const InnerContentMiddleCenter = styled(InnerContent)`
  justify-content: center;
  text-align: left;
  overflow: visible;
`;

class InvitePartner extends React.Component {
  constructor(props) {
    super(props);

    this.steps = [
      {
        step: 'info',
        stepNumber: 1,
        showProgress: true,
        hideBackButton: false,
      },
    ];

    this.state = {
      step: false,
      currentStep: 0,
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.onQuestionsSubmit = this.onQuestionsSubmit.bind(this);
  }

  componentDidMount() {
    // Must have passed email and otherName to route or redirect to start
    const { location, navigate } = this.props;
    if (
      (location && !location.state.email) ||
      (location && !location.state.otherName)
    ) {
      navigate(ODRS_INVITE_PARTNER_ROUTE);
    }
    this.setState({ step: this.steps[0] });
  }

  // change the theme for the current step if needed
  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    const { setTheme } = this.context;
    if (this.steps.indexOf(prevState.step) !== this.steps.indexOf(step)) {
      setTheme(step.theme || theme);
    }
  }

  onQuestionsSubmit() {
    this.goNext();
  }

  goNext() {
    const { step, currentStep } = this.state;
    const cur = this.steps.indexOf(step);

    if (this.steps[cur + 1]) {
      this.setState({
        step: this.steps[cur + 1],
        currentStep: currentStep + 1,
      });
    }
  }

  goBack() {
    const { step, currentStep } = this.state;
    const { navigate } = this.props;
    const cur = this.steps.indexOf(step);

    if (this.steps[cur - 1]) {
      this.setState({
        step: this.steps[cur - 1],
        currentStep: currentStep - 1,
      });
    } else {
      this.setState(
        {
          step: false,
        },
        () => {
          navigate(ODRS_INVITE_PARTNER_ROUTE);
        }
      );
    }
  }

  render() {
    const { step } = this.state;

    const {
      location: {
        state: { otherName },
      },
    } = this.props;
    let stepComponent;

    switch (step.step) {
      case 'info':
        stepComponent = (
          <InnerContentMiddleCenter className="step-transition-fade-up">
            <H2Conversational>
              Your former partner {otherName} has already created an account
            </H2Conversational>
            <Callout>
              You will need to contact our support team for more assistance at:{' '}
              <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
                Ask Amica
              </ReferralLink>
            </Callout>
          </InnerContentMiddleCenter>
        );
        break;
      default:
        stepComponent = <p key="default">default</p>;
    }

    return (
      <>
        <ProgressBarProvider>
          <PageHeader show={!step.hideBackButton}>
            <BackButton onClick={this.goBack} subtle />
          </PageHeader>
          <StyledPageContent>
            <StepTransition pageKey={step.step} transitionTime={500} appear>
              {step.step ? stepComponent : null}
            </StepTransition>
          </StyledPageContent>
        </ProgressBarProvider>
        <StyledPageFooter>
          {/* Note: steps are hardcoded to match design */}
          <ProgressBar
            label="Invite former partner"
            currentStep={9}
            steps={10}
            hidden={!step.showProgress}
          />
        </StyledPageFooter>
      </>
    );
  }
}

InvitePartner.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      InviteID: PropTypes.string,
      otherName: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  matter: PropTypes.object, // eslint-disable-line
};

InvitePartner.defaultProps = {
  location: {},
};
InvitePartner.contextType = RouteContext;

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvitePartner));
