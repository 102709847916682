import React from 'react';

import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import Login from 'App/UI/Signup/Login';
import MatterGuard from '../Guard/MatterGuard';

const ODRSLogin = () => (
  <MatterGuard expectNone destination={ODRS_DASH_HOME_ROUTE}>
    <Login />
  </MatterGuard>
);

export default ODRSLogin;
