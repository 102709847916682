import React from 'react';
import moment from 'moment';
import { DATE_FORMAT_LONG, Party, PARTY_A, PARTY_B } from 'Common/constants';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import isDate from 'Common/Utils/isDate';
import isString from 'Common/Utils/isString';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import { Statement, QuestionAny } from 'Common/Data/Types/appSections';

export const childrenQuestions: QuestionAny[] = [
  {
    name: 'intro',
    label: () =>
      'We are going to ask you a few questions about your child. You will be able to work out a more detailed parenting agreement in the parenting section.',
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'childName',
    label: "What is your child's name",
    hint: '',
    type: 'text',
    defaultValue: '',
    index: 1,
    sensitive: true,
    validate: value =>
      isString(value) ? [true] : [false, "Please enter your child's name"],
    help: {
      label: 'What does this mean?',
      content: (
        <>
          <H1Conversational>What does this mean?</H1Conversational>

          <Paragraph large>
            This means the full name of the child as it appears on their Birth
            Certificate or Change of Name Certificate.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'childAge',
    label: (values: Record<string, unknown>) =>
      `When was ${values.childName} born?`,
    hint: '',
    type: 'date',
    defaultValue: '',
    index: 2,
    sensitive: true,
    validate: value =>
      isDate(value) ? [true] : [false, 'Please tell us how old your child is'],
    formAttributes: { max: new Date() },
  },
  {
    name: 'b1',
    label: () => (
      <>
        You only need to include children under 18 in amica. If you need help{' '}
        making parenting orders for children over 18, seek help and support{' '}
        <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
          here
        </ReferralLink>
      </>
    ),
    hint: '',
    type: 'blocker',
    defaultValue: '',
    index: 3,
    visible: answers => isDate(answers.childAge, { atLeast: 18 }),
  },
  {
    name: 'childHealthIssues',
    label: (values: Record<string, unknown>) =>
      `Does ${values.childName} have any additional needs?`,
    hint: '',
    type: 'yesno',
    defaultValue: '',
    index: 4,
    help: {
      label: 'What is an additional need?',
      content: (
        <>
          <H1Conversational>What is an additional need?</H1Conversational>
          <Paragraph large>
            An additional need is a health issue or anything that means your
            child requires extra support or care. This might be a physical
            disability, a learning difficulty or a mental health issue. This is
            important because it can affect how much you or your former partner
            can earn, or what you may need, in the future.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 5,
  },
];

export const childrenStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'childName',
    'childAge',
    'childHealthIssues',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(childrenQuestions, data, hideSensitive);

  const childInfo = highlight`${updated(
    'childName',
    redact('childName')
  )} was born 
    ${updated(
      'childAge',
      redact('childAge', moment(data.childAge).format(DATE_FORMAT_LONG))
    )}. They 
    ${updated(
      'childHealthIssues',
      data.childHealthIssues ? 'have' : "don't have"
    )} additional needs.`;

  if (plainText) {
    return childInfo;
  }

  return <>{childInfo}</>;
};

export const noChildrenQuestions: QuestionAny[] = [
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 0,
  },
];

export const noChildrenStatement = ({
  matter,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const highlight = getHighlightStatementValuesFunction(plainText);

  const partyAName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  const partyBName = getFirstName(matter.partyB, PARTY_B, hideSensitive);

  const statement = `${partyAName} and ${partyBName} do not have any children together.`;

  if (plainText) {
    return statement;
  }

  const highlightedText = highlight`${statement}`;

  return <>{highlightedText}</>;
};
