/* eslint no-restricted-globals: 0 */
export default mobileNumber => {
  if (!mobileNumber) {
    return '';
  }

  let sanitisedMobileNumber;
  const noSpacesMobile = mobileNumber.replace(/\s/g, '');

  if (noSpacesMobile[0] === '+') {
    sanitisedMobileNumber = noSpacesMobile;
  }

  if (noSpacesMobile[0] === '4') {
    sanitisedMobileNumber = `+61${noSpacesMobile}`;
  }

  if (noSpacesMobile[0] === '0') {
    const newMobileNumber = noSpacesMobile.slice(1);
    sanitisedMobileNumber = `+61${newMobileNumber}`;
  }

  return sanitisedMobileNumber;
};
