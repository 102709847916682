import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPage } from 'Common/Data/App/appSections';
import {
  SECTION_PROPERTY,
  PAGE_PROPERTY_CONTRIBUTIONS,
  CARD_NORMAL,
} from 'Common/constants';
import { ODRS_PROPERTY_CONTRIBUTIONS_ROUTE } from 'Common/routes';
import DashboardContent from 'App/UI/Dashboard/common/DashboardContent';
import { generateNormalCard } from 'App/UI/Dashboard/Property/StateCards';
import { DashboardPageProps } from 'Common/Data/Types/types';
import { CardNormal, QuestionStateCard } from 'Common/Data/Types/appSections';

const Contributions: React.FC<DashboardPageProps> = ({
  matter,
  navigate,
  appState,
}) => {
  const location = useLocation();
  const customNavigate = (route: string) =>
    navigate(route, { backRoute: ODRS_PROPERTY_CONTRIBUTIONS_ROUTE, location });

  const pageData = getPage(SECTION_PROPERTY, PAGE_PROPERTY_CONTRIBUTIONS);

  return (
    <DashboardContent
      pageData={pageData}
      navigate={customNavigate}
      matter={matter}
      cardHandlers={{
        [CARD_NORMAL]: data =>
          generateNormalCard(data as QuestionStateCard<CardNormal>, matter),
      }}
      appState={appState}
    />
  );
};

export default Contributions;
