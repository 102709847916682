import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';

import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';

import { ButtonsWrapper, InnerContentMiddle } from 'Common/UI/Signup/Layout';
import SignupIniviteIntro from './signup-inivite-intro.svg';

const ImageContainer = styled.div`
  width: 100%;
  /* margin-top: ${({ theme }) => theme.padding.small}px; */
  margin-bottom: ${({ theme }) => theme.padding.large}px;
  img {
    height: 147px;
    display: block;
  }
`;

const StyledHeading = styled(H1Conversational)`
  margin-bottom: ${({ theme }) => theme.padding.medium}px;
`;

const UnderstandButton = styled(GenericButton)`
  margin-top: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin-top: 0;
    margin-left: ${({ theme }) => theme.spacing[6]};
  }
`;

const StyledButtonsWrapper = styled(ButtonsWrapper)`
  max-width: 600px;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    display: flex;
  }
`;

const OnboardingInfoNeedToInvitePartner = ({ goBack, goNext }) => (
  <>
    <InnerContentMiddle className="step-transition-fade-up">
      <ResponsiveTextContainer data-cy="welcome-info">
        <ImageContainer>
          <img
            alt=""
            src={SignupIniviteIntro}
            srcSet={`${SignupIniviteIntro} 2x`}
          />
        </ImageContainer>
        <StyledHeading>
          Once you have signed up, you will need to invite your former partner
          to use amica with you
        </StyledHeading>
        <Paragraph large>
          You will need to enter your former partner&apos;s email address to
          send them an invitation.
        </Paragraph>
        <Paragraph large>
          If your former partner has already sent you an invitation, use the
          sign up link in their email invitation so that we can link your info
          with theirs on amica.
        </Paragraph>
        <Paragraph large>
          If you can&apos;t find that email, check your spam folder.
        </Paragraph>
      </ResponsiveTextContainer>
    </InnerContentMiddle>
    <StyledButtonsWrapper className="step-transition-fade-down">
      <GenericButton fullWidth reverse onClick={goBack}>
        Back
      </GenericButton>
      <UnderstandButton
        fullWidth
        onClick={goNext}
        data-cy="invite-info-understand"
      >
        I understand
      </UnderstandButton>
    </StyledButtonsWrapper>
  </>
);

OnboardingInfoNeedToInvitePartner.propTypes = {
  goNext: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default OnboardingInfoNeedToInvitePartner;
