import React from 'react';

import { H2Conversational, H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import HelpButton from 'Common/UI/Button/HelpButton';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import { SlideInner, SlideText } from 'Common/UI/Carousel/ImageSlideWrapper';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';


const help = (
  <>
    <H1Conversational>Best interests: Factors to consider</H1Conversational>
    <Paragraph>
      Many considerations can be taken into account when working out a
      child&apos;s best interests. For example, when deciding parenting
      arrangements, you should consider:
    </Paragraph>

    <ul>
      <li>the child&apos;s age</li>
      <li>who is best placed to provide day-to-day care for the child</li>
      <li>any special needs e.g. medical and schooling</li>
      <li>
        practical considerations e.g. housing, transport, unexpected expenses
      </li>
      <li>
        the cultural needs of the child, especially where the child is
        Aboriginal or Torres Strait Islander
      </li>
      <li>the child&apos;s own views</li>
      <li>the safety of the child.</li>
    </ul>

    <Paragraph>
      For more information see the{' '}
      <ReferralLink href="https://www.amica.gov.au/parenting-arrangements.html">
        Parenting Arrangements
      </ReferralLink>{' '}
      page.
    </Paragraph>
  </>
);

const ParentingPlanSlide3 = () => (
  <SlideInner>
    <SlideText paddingTop data-cy="parent-slide-3">
      <H2Conversational>
        Working out your child&apos;s best interests
      </H2Conversational>
      <Callout>
        Every family&apos;s situation is different. What works for some might
        not work for others. When working out the best interests of your child,
        think about what has worked for your children.
      </Callout>

      <br />
      <br />
      <LearnMoreButton
        popUpContent={help}
        className="step-transition-fade-down"
        ui={HelpButton}
      >
        Best interests: Factors to consider
      </LearnMoreButton>
    </SlideText>
  </SlideInner>
);

export default ParentingPlanSlide3;
