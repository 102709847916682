import React from 'react';

import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ODRS_START_ROUTE, HOME_ROUTE } from 'Common/routes';
import Carousel from 'Common/UI/Carousel';

import { CarouselButton } from 'Common/UI/Carousel/ImageSlideWrapper';
import {
  WelcomeSlide01,
  WelcomeSlide02,
  WelcomeSlide03,
  WelcomeSlide04,
} from './WelcomeSlides';


const WelcomeCarousel = ({ state, navigate }) => {
  const LastSlideControls = () => (
    <CarouselButton
      onClick={() =>
        navigate(state && state.next ? state.next : ODRS_START_ROUTE, state)
      }
      data-cy="carousel-finish-button"
    >
      Sign up
    </CarouselButton>
  );

  const handleClose = () =>
    navigate(state && state.back ? state.back : HOME_ROUTE);

  return (
    <Carousel onClose={handleClose} LastSlideControls={LastSlideControls}>
      <WelcomeSlide01 />
      <WelcomeSlide02 />
      <WelcomeSlide03 />
      <WelcomeSlide04 />
    </Carousel>
  );
};

WelcomeCarousel.propTypes = {
  navigate: PropTypes.func.isRequired,
  state: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

WelcomeCarousel.defaultProps = {
  state: null,
};

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
});

export default connect(null, mapDispatchToProps)(WelcomeCarousel);
