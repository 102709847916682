import React from 'react';
import MatterProps from 'Common/Utils/MatterProps';
import { Callout } from 'Common/UI/Text/Paragraph';

import { SlideInner, SlideText } from 'Common/UI/Carousel/ImageSlideWrapper';

const AssetTransferSlide1 = () => (
  <SlideInner>
    <SlideText paddingTop data-cy="asset-transfer-slide-1">
      <Callout>
        Great! {'You and '}
        {MatterProps('other.firstname')} have agreed on your suggested division
        (your Agreed Percentages).
      </Callout>
      <Callout>
        You can now suggest how you would like to divide your assets to achieve
        your Agreed Percentages.
      </Callout>
      <Callout>
        amica enables both of you to make suggestions and send messages about
        them.
      </Callout>
    </SlideText>
  </SlideInner>
);

export default AssetTransferSlide1;
