import {
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  PARTY_BOTH,
  PartyKeys,
  CARD_CONTINUE,
  CardType,
} from 'Common/constants';
import omit from 'Common/Utils/omit';

import { ODRS_STATEMENT_DETAILS } from 'Common/routes';
import MatterProps from 'Common/Utils/MatterProps';
import capitaliseText from 'Common/Utils/capitaliseText';
import { CardAny, CardContinue, CardInputProps } from '../Types/appSections';
import { Item } from '../Types/matter';

const buildCardGeneric = <T extends CardAny>({
  ...props
}: CardInputProps<T>): T =>
  ({
    ...props,
  } as T);

export const buildCardContinue = ({ ...props }: CardContinue): CardContinue => {
  const { cardID, extendID } = props;

  let data;

  const items = MatterProps('items');
  const item = items.find((i: Item) => i.SectionID === cardID);

  const extendCard = items.find(
    (extendItem: Item) => extendItem.SectionID === extendID
  );
  const extendData = extendCard ? omit(extendCard, ['status']) : {};

  if (item) {
    data = { ...extendData, ...item };
  } else {
    data = { ...extendData, itemNotInDatabase: true };
  }

  return {
    ...props,
    data,
  };
};

// type guard to check what type our card is
const isCardOfType = <T extends CardInputProps<CardAny>>(
  card: CardInputProps<CardAny>,
  type: CardType
): card is T => (card as T).type === type;

export const buildCard = <T extends CardAny>({
  ...props
}: CardInputProps<T>): CardAny => {
  const { status, cardID, baseID, index, typeName = 'statement' } = props;

  const items = MatterProps('items');
  const item = items.find((i: Item) => i.SectionID === cardID);
  const data: Record<string, unknown> = item || {};

  // track who the card is for (self, other or both)
  let cardForParty: PartyKeys = PARTY_BOTH;

  if (status === STATUS_NEW_ONLY_SELF) {
    cardForParty = 'self';
  } else if (status === STATUS_NEW_ONLY_THEM) {
    cardForParty = 'other';
  }

  let cardIDAsArray;
  let viewRoute;

  if (baseID) {
    cardIDAsArray = [baseID, index];
    viewRoute = `${ODRS_STATEMENT_DETAILS}/${baseID}${
      index ? `/${index}` : ''
    }`;
  }

  let title;

  if ((props as any).title) {
    title = capitaliseText((props as any).title);
  }

  // add data that is shared between all card types
  const cardProps = {
    ...props,
    status: item ? item.status : status,
    title,
    data,
    cardForParty,
    cardIDAsArray,
    typeName,
    viewRoute,
  } as T;

  // send the props off to be processed by the specific card type function if it exists
  // this is to add extra data that is specific only to that card type
  if (isCardOfType<CardContinue>(cardProps, CARD_CONTINUE)) {
    return buildCardContinue(cardProps);
  }

  return buildCardGeneric<T>(cardProps);
};
