import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { signupPartyA } from 'Common/Data/App/20200101/Questions/signup';
import {
  HOME_ROUTE,
  INTRO_ROUTE,
  ODRS_INVITE_PARTNER_ROUTE,
} from 'Common/routes';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import theme, { darkerTheme } from 'Common/Utils/theme';
import AmicaLogo from 'App/UI/AmicaLogo';
import StepTransition from 'App/UI/Transitions/StepTransition';
import { RouteContext } from 'App/UI/Transitions/RouteContext';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';
import QuestionsFlowWrapper from 'App/UI/Questions/QuestionsFlowWrapper';
import ProgressBar from 'Common/UI/ProgressBar';

import { StyledPageContent, StyledPageFooter } from 'Common/UI/Signup/Layout';

import VerifyCodeStep from 'App/UI/Signup/Common/VerifyCodeStep';
import SignupIntro from 'App/UI/Signup/Common/SignupIntro';
import SignupSignup from 'App/UI/Signup/Common/SignupSignup';
import IsAmicaRightForYou from 'App/UI/Signup/Common/IsAmicaRightForYou';
import OnboardingInfoNeedToInvitePartner from 'App/UI/Signup/Common/OnboardingInfoNeedToInvitePartner';
import SignupDone from 'App/UI/Signup/Common/SignupDone';
import SignupQuestionFlowWrapper from 'App/UI/Signup/Common/SignupQuestionFlowWrapper';
import QuestionFlowHeader from 'App/UI/Signup/Common/QuestionFlowHeader';
import AmicaLogoWrapper from 'App/UI/Signup/Common/AmicaLogoWrapper';
import CloseButton from 'App/UI/Signup/Common/CloseButton';
import BackgroundShapes from 'App/UI/Signup/Common/BackgroundShapes';

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.steps = [
      {
        step: 'intro',
        showProgress: false,
        theme: darkerTheme,
        backgroundShapes: false,
      },
      {
        step: 'onboarding-info-need-to-invite-partner',
        showProgress: false,
        theme: darkerTheme,
        backgroundShapes: false,
      },
      {
        step: 'is-amica-right-for-you',
        showProgress: true,
        backgroundShapes: true,
        stepNumber: 0,
      },
      {
        step: 'questions',
        showProgress: true,
        stepNumber: 1,
        noLeftPadding: true,
        backgroundShapes: true,
      },
      {
        step: 'signup',
        stepNumber: signupPartyA.length + 1,
        showProgress: true,
        backgroundShapes: true,
      },
      {
        step: 'verify',
        stepNumber: signupPartyA.length + 2,
        showProgress: true,
        hideBackButton: true,
        backgroundShapes: true,
      },
      {
        step: 'signedup',
        showProgress: false,
        theme: darkerTheme,
        hideBackButton: true,
        backgroundShapes: false,
      },
    ];

    this.state = {
      step: false,
      verificationError: null,
      signupError: null,
      signupQuestionsInput: { remember: false },
      signupQuestions: signupPartyA,
      numberOfSteps: signupPartyA.length + 3,
      currentStep: 0,
      loadingRegisterUser: false,
      loadingVerification: false,
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.onQuestionsSubmit = this.onQuestionsSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    const signupQuestionsDefaultValues = signupPartyA.reduce(
      (values, question) => ({
        ...values,
        [question.name]: question.defaultValue,
      }),
      {}
    );
    this.setState({
      signupQuestionsInput: signupQuestionsDefaultValues,
      step: this.steps[0],
    });
  }

  // change the theme for the current step if needed
  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    const { setTheme } = this.context;
    if (this.steps.indexOf(prevState.step) !== this.steps.indexOf(step)) {
      setTheme(step.theme || theme);
    }
  }

  onInputChange(key, value) {
    const signupQuestionsInput = {
      ...this.state.signupQuestionsInput, // eslint-disable-line
      [key]: value,
    };

    this.setState({ signupQuestionsInput });
  }

  onQuestionsSubmit() {
    this.goNext();
    return Promise.resolve();
  }

  goNext() {
    const { step, currentStep } = this.state;
    const { navigate } = this.props;

    const cur = this.steps.indexOf(step);

    if (this.steps[cur + 1]) {
      this.setState({
        step: this.steps[cur + 1],
        currentStep: currentStep + 1,
      });
    } else {
      navigate(ODRS_INVITE_PARTNER_ROUTE);
    }
  }

  goBack() {
    const { step, currentStep } = this.state;
    const { navigate } = this.props;
    const cur = this.steps.indexOf(step);

    if (this.steps[cur - 1]) {
      this.setState({
        step: this.steps[cur - 1],
        currentStep: currentStep - 1,
      });
    } else {
      this.setState(
        {
          step: false,
        },
        () => {
          navigate(INTRO_ROUTE);
        }
      );
    }
  }

  render() {
    const {
      step,
      signupError,
      verificationError,
      signupQuestions,
      signupQuestionsInput,
      numberOfSteps,
      loadingRegisterUser,
      loadingVerification,
    } = this.state;

    const { navigate } = this.props;

    let stepComponent;

    switch (step.step) {
      case 'intro':
        stepComponent = <SignupIntro goNext={this.goNext} />;
        break;
      case 'is-amica-right-for-you':
        stepComponent = (
          <IsAmicaRightForYou
            goBack={this.goBack}
            goNext={this.goNext}
            onChange={(name, updateValue) => {
              this.onInputChange(name, updateValue);
            }}
          />
        );
        break;
      case 'onboarding-info-need-to-invite-partner':
        stepComponent = (
          <OnboardingInfoNeedToInvitePartner
            goBack={this.goBack}
            goNext={this.goNext}
            onChange={(name, updateValue) => {
              this.onInputChange(name, updateValue);
            }}
          />
        );
        break;
      case 'questions':
        stepComponent = (
          <SignupQuestionFlowWrapper className="step-transition-fade-up">
            <QuestionsFlowWrapper
              questions={signupQuestions}
              onInputChange={(key, value) => this.onInputChange(key, value)}
              values={signupQuestionsInput}
              onSubmit={this.onQuestionsSubmit}
              startingStepNumber={step.stepNumber}
              title="Sign up"
            />
          </SignupQuestionFlowWrapper>
        );

        break;
      case 'signup':
        stepComponent = (
          <SignupSignup
            goNext={this.goNext}
            setParentState={(s, f) => this.setState(s, f)}
            questionsInput={signupQuestionsInput}
            loadingRegisterUser={loadingRegisterUser}
            stepNumber={step.stepNumber}
            signupError={signupError}
          />
        );
        break;
      case 'verify':
        stepComponent = (
          <VerifyCodeStep
            loadingVerification={loadingVerification}
            parentState={this.state}
            setParentState={(s, f) => this.setState(s, f)}
            onChange={this.onInputChange}
            verificationError={verificationError}
            goNext={this.goNext}
            startingStepNumber={step.stepNumber}
            isFirstParty
          />
        );
        break;

      case 'signedup':
        stepComponent = <SignupDone />;
        break;

      default:
        stepComponent = <p key="default">default</p>;
    }

    return (
      <ProgressBarProvider>
        {step.backgroundShapes && <BackgroundShapes />}
        <QuestionFlowHeader>
          <ReferralLink href="https://amica.gov.au">
            <AmicaLogoWrapper>
              <AmicaLogo />
            </AmicaLogoWrapper>
          </ReferralLink>

          {!step.hideBackButton && (
            <CloseButton onClick={() => navigate(HOME_ROUTE)}>
              &nbsp;&nbsp;Close
            </CloseButton>
          )}
        </QuestionFlowHeader>
        <StyledPageContent>
          <StepTransition
            noLeftPadding={step.noLeftPadding}
            pageKey={step.step}
            transitionTime={500}
            appear
          >
            {step.step ? stepComponent : null}
          </StepTransition>
        </StyledPageContent>

        <StyledPageFooter>
          <ProgressBar
            label="Sign up"
            currentStep={step.stepNumber || 0}
            steps={numberOfSteps}
            hidden={!step.showProgress}
          />
        </StyledPageFooter>
      </ProgressBarProvider>
    );
  }
}

Signup.propTypes = {
  navigate: PropTypes.func.isRequired,
};

Signup.contextType = RouteContext;

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
});

export default connect(null, mapDispatchToProps)(Signup);
