import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

import { editProfile as questions } from 'Common/Data/App/20200101/Questions/signup';
import { ODRS_PROFILE_ROUTE } from 'Common/routes';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';
import PageContent from 'Common/UI/Layout/PageContent';
import PageFooter from 'Common/UI/Layout/PageFooter';
import { CloseButtonQuestionsHeader } from 'Common/UI/Button/CloseButton';
import { updateProfileInformationAction } from 'App/State/MatterActions';
import sanitiseMobile from 'Common/Utils/sanitiseMobile';
import { updateUserAttributeSetAction } from 'App/State/UserActions';
import ProgressBar from 'Common/UI/ProgressBar';
import QuestionsFlowWrapper from 'App/UI/Questions/QuestionsFlowWrapper';
import { QuestionFlowHeader } from 'Common/UI/Questions/QuestionFlowHeader';

const StyledPageContent = styled(PageContent)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-left: 0;
  padding-right: 0;
`;

class ProfileUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questionsInput: props.location.state,
      initialInput: props.location.state, // keep a copy of the initial values, used on submit to check if phone number is changing
    };

    this.onQuestionsSubmit = this.onQuestionsSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onQuestionsSubmit() {
    const { navigate, updateUserAttributeSet, updateProfileInformation } =
      this.props;

    const { questionsInput: answers, initialInput } = this.state;

    const { phone_number: initialPhoneNumber } = initialInput;

    const willChangePhoneNumber =
      sanitiseMobile(initialPhoneNumber) !==
      sanitiseMobile(answers.phone_number);

    const trimmedAnswers = {};

    Object.entries(answers).forEach(([key, value]) => {
      if (typeof answers[key] === 'string') {
        trimmedAnswers[key] = value.trim();
      } else {
        trimmedAnswers[key] = value;
      }
    });

    return updateUserAttributeSet(trimmedAnswers)
      .then(() => updateProfileInformation(trimmedAnswers))
      .then(() => {
        navigate(ODRS_PROFILE_ROUTE, { willChangePhoneNumber });
      });
  }

  onInputChange(key, value) {
    const questionsInput = {
      ...this.state.questionsInput, // eslint-disable-line
      [key]: value,
    };

    this.setState({ questionsInput });
  }

  render() {
    return (
      <ProgressBarProvider>
        <QuestionFlowHeader>
          <CloseButtonQuestionsHeader as={Link} to={ODRS_PROFILE_ROUTE}>
            &nbsp;&nbsp;Close
          </CloseButtonQuestionsHeader>
        </QuestionFlowHeader>
        <StyledPageContent>
          <QuestionsFlowWrapper
            title="Edit profile"
            questions={questions}
            onSubmit={this.onQuestionsSubmit}
            onInputChange={(key, value) => this.onInputChange(key, value)}
            backRoute={ODRS_PROFILE_ROUTE}
            values={this.state.questionsInput}
            statement={() => {}}
          />
        </StyledPageContent>
        <PageFooter>
          <ProgressBar
            label="Edit profile"
            steps={questions.length}
            currentStep={0}
          />
        </PageFooter>
      </ProgressBarProvider>
    );
  }
}

ProfileUpdate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  updateUserAttributeSet: PropTypes.func.isRequired,
  updateProfileInformation: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
  updateUserAttributeSet: userProps =>
    dispatch(updateUserAttributeSetAction(userProps)),
  updateProfileInformation: userProps =>
    dispatch(updateProfileInformationAction(userProps)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileUpdate));
