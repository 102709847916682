import React from 'react';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import HelpButton from 'Common/UI/Button/HelpButton';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';

const help = (
  <>
    <H1Conversational>What is full and frank disclosure?</H1Conversational>
    <Paragraph>
      By law, when you want to divide money and property after the end of a
      relationship, it is a requirement that you provide to your former partner
      information about all assets and liabilities of the relationship,
      regardless of how they are held.
    </Paragraph>
  </>
);

const WhatIsFullAndFrankDisclosureButton: React.FC = () => (
  <LearnMoreButton
    ui={HelpButton}
    popUpContent={help}
    className="step-transition-fade-down"
  >
    What is full and frank disclosure?
  </LearnMoreButton>
);

export default WhatIsFullAndFrankDisclosureButton;
