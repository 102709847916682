import React from 'react';
import PropTypes from 'prop-types';

import {
  SECTION_CONSENT_ORDERS,
  PAGE_CONSENT_ORDERS_GET_STARTED,
  PAGE_CONSENT_ORDERS_RELATIONSHIP,
  PAGE_CONSENT_ORDERS_ASSETS,
  PAGE_CONSENT_ORDERS_FINANCES,
  PAGE_CONSENT_ORDERS_COMPLETED,
} from 'Common/constants';
import Dashboard from 'App/UI/Dashboard/Dashboard';
import GetStarted from './DashboardConsentOrders/GetStarted';
import Relationship from './DashboardConsentOrders/Relationship';
import Assets from './DashboardConsentOrders/Assets';
import Finances from './DashboardConsentOrders/Finances';
import Complete from './DashboardConsentOrders/Complete';

const DashboardConsentOrders = ({ tab }) => (
  <Dashboard
    tab={tab}
    section={SECTION_CONSENT_ORDERS}
    pages={{
      [PAGE_CONSENT_ORDERS_GET_STARTED]: GetStarted,
      [PAGE_CONSENT_ORDERS_RELATIONSHIP]: Relationship,
      [PAGE_CONSENT_ORDERS_ASSETS]: Assets,
      [PAGE_CONSENT_ORDERS_FINANCES]: Finances,
      [PAGE_CONSENT_ORDERS_COMPLETED]: Complete,
    }}
  />
);

DashboardConsentOrders.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default DashboardConsentOrders;
