import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import { Callout } from 'Common/UI/Text/Paragraph';
import {
  SlideInner,
  SlideText,
  SlideIcon,
} from 'Common/UI/Carousel/ImageSlideWrapper';
import SlideImage02 from './content-order-image-2.svg';

const ConsentOrdersSlide2 = () => (
  <SlideInner>
    <SlideIcon className="slide-icon">
      <img alt="" src={SlideImage02} srcSet={`${SlideImage02} 2x`} />
    </SlideIcon>
    <SlideText data-cy="content-order-slide-2">
      <H2Conversational>
        amica needs to ask you some questions to check that your situation is
        suitable to create consent orders using amica.
      </H2Conversational>
      <Callout>
        If your situation is suitable, amica will use your answers to fill in
        the application documents.
      </Callout>
    </SlideText>
  </SlideInner>
);

export default ConsentOrdersSlide2;
