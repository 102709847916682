import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import useCardCheckAccess from 'Common/Utils/Hooks/useCardCheckAccess';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import {
  getCardQuestionFlowMetadata,
  getCardDashboardRoute,
  definitelyGetCard,
} from 'Common/Data/App/appSections';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import getOtherParty from 'Common/Utils/getOtherParty';
import {
  MESSAGE_STATUS_STATEMENT_CREATED,
  MESSAGE_STATUS_UPDATE_DONE,
  STATUS_APPROVED,
  STATUS_CREATED,
  STATUS_INCOMPLETE,
  STATUS_NEW_BOTH_PARTIES,
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_UPDATE_DONE,
  CARD_STATEMENT,
  PARTY_SELF,
  PARTY_BOTH,
  CARD_JURISDICTION,
  CARD_CUSTOM,
} from 'Common/constants';

import { formatCurrencyAsNumber } from 'Common/Utils/formatCurrency';
import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import { QuestionCard, QuestionAny } from 'Common/Data/Types/appSections';
import getNames from 'Common/Utils/getNames';
import { Item } from 'Common/Data/Types/matter';
import push from 'Common/Utils/push';
import { Sent } from 'Common/UI/Layout/Completed';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  StyledPageContent,
  StatementSubmitDrawer,
  CardQuestionsFlowFooter,
} from 'Common/UI/Questions/common';
import QuestionsFlowWrapper from 'App/UI/Questions/QuestionsFlowWrapper';
import { CardQuestionFlowProvider } from 'Common/UI/Questions/CardQuestionsFlowContext';
import CardQuestionFlowHeader from 'Common/UI/Questions/QuestionFlowHeader';
import styled from 'styled-components';

const StyledStyledPageContent = styled(StyledPageContent)<{
  hideQuestionFlowHeader: boolean;
}>`
  ${({ hideQuestionFlowHeader }) => hideQuestionFlowHeader && `padding-top: 0;`}
`;

const filterOutDisabledInputs = (
  questions: QuestionAny[],
  questionInput: Record<string, any>
) => {
  const disabledQuestionNames = questions
    .filter(question => question.disabled)
    .map(question => question.name);

  const newQuestionInput = { ...questionInput };

  return Object.keys(newQuestionInput)
    .filter(input => !disabledQuestionNames.includes(input))
    .reduce<Record<string, any>>((acc, key) => {
      acc[key] = newQuestionInput[key];
      return acc;
    }, {});
};

type Props = {
  baseID: string;
  index?: string | number;
  indexFromParams?: boolean;
};

type AnswersOptions = Record<string, any>;

const CardQuestionsFlow: React.FC<Props> = ({
  baseID,
  index = undefined,
  indexFromParams = false,
}) => {
  const [questionsInput, setQuestionsInput] = useState<Record<string, any>>({});
  const [showSentScreen, setShowSentScreen] = useState(false);
  const { state: { previous: previousRoute = ODRS_DASH_HOME_ROUTE } = {} } =
    useLocation<{ previous?: string }>();
  const { index: paramsIndex } = useParams<{ index?: string }>();
  const [isUpdating, setIsUpdating] = useState(false);

  const matter = useAppSelector(state => state.matter);
  const dispatch = useAppDispatch();

  const navigate = (route: string) => dispatch(push(route));

  const selfParty = getPartyKeys().self;

  let arrayIndex;

  if (indexFromParams) {
    arrayIndex = paramsIndex || 0;
  } else {
    arrayIndex = index === 'self' ? selfParty : index;
  }

  const cardID = arrayIndex ? `${baseID}${arrayIndex}` : baseID;

  const card = definitelyGetCard<QuestionCard>(cardID);

  useEffect(() => {
    setIsUpdating(
      ![
        STATUS_NEW_BOTH_PARTIES,
        STATUS_NEW_ONLY_SELF,
        STATUS_NEW_ONLY_THEM,
      ].includes(card.status)
    );
  }, []);

  const { backgroundImage, backgroundColour, icon } =
    getCardQuestionFlowMetadata(card.cardID);

  const shouldHideProgressBar =
    card.type === CARD_JURISDICTION || card.type === CARD_CUSTOM;

  let questions: QuestionAny[] = [];

  if (typeof card.questions === 'function') {
    questions = card.questions(card);
  } else {
    questions = card.questions ?? [];
  }

  // `intro` and `carousel` question types are only supposed to show if this is the first time going through the card
  // this means that if this card is repeatable and there is at least 1 of this type of card in the database,
  // or if we are editing an existing card, we want to remove any intro and carousel questions
  if (
    isUpdating ||
    (card.isRepeatable &&
      matter.items.filter(
        (item: Item) =>
          item.BaseSection === card.baseID &&
          (card.cardForParty === PARTY_SELF || card.cardForParty === PARTY_BOTH)
      ).length > 0)
  ) {
    questions = questions.filter(
      (question: QuestionAny) =>
        question.type !== 'intro' && question.type !== 'carousel'
    );
  }

  const onChange = (key: string, value: unknown) => {
    const newInput = {
      ...questionsInput,
      [key]: value,
      status: STATUS_INCOMPLETE,
    };

    setQuestionsInput(newInput);
  };

  useEffect(() => {
    const defaultValues = { ...card.data } || {};

    // force all currency fields to a default of 0
    questions.forEach((question: QuestionAny) => {
      if (question.type === 'currency' && !defaultValues[question.name]) {
        defaultValues[question.name] = '0';
      }
    });

    setQuestionsInput(defaultValues);
  }, []);

  // Check to see if we can access this question flow
  const ShouldRedirectComponent = useCardCheckAccess(matter, cardID);

  if (ShouldRedirectComponent) {
    return ShouldRedirectComponent;
  }

  const onQuestionsSubmit = (answers: Record<string, unknown>) => {
    const filteredAnswers = filterOutDisabledInputs(questions, answers);
    // eslint-disable-next-line no-param-reassign
    delete filteredAnswers.message; // when updating question values, we don't want to update the message as that is handled elsewhere

    const data: Partial<Item> = {
      owner: getOtherParty(selfParty),
      modified: filteredAnswers.modified as string,
    };

    if (card.autoApprove) {
      data.status = STATUS_APPROVED;
    } else if (!isUpdating) {
      data.status = STATUS_CREATED;
    } else {
      data.status = STATUS_UPDATE_DONE;
    }

    // Sanitize out some of the items we don't want to pass through JSON serialization
    const cleanAnswers: AnswersOptions = {};

    questions.forEach(question => {
      // If there is a presave function for this question, and a response has been given
      // then call the function to clean the data, otherwise let it go through as-is
      if (
        'preSaveResponses' in question &&
        question.preSaveResponses &&
        filteredAnswers[question.name] !== undefined
      ) {
        cleanAnswers[question.name] = question.preSaveResponses(
          filteredAnswers[question.name]
        );
      } else if (
        question.type === 'currency' &&
        filteredAnswers[question.name] !== undefined
      ) {
        cleanAnswers[question.name] = formatCurrencyAsNumber(
          filteredAnswers[question.name]
        );
      } else if (filteredAnswers[question.name] !== undefined) {
        if (typeof filteredAnswers[question.name] === 'string') {
          cleanAnswers[question.name] = (
            filteredAnswers[question.name] as string
          ).trim();
        } else {
          cleanAnswers[question.name] = filteredAnswers[question.name];
        }
      }
    });

    const cleanData = { ...data, ...cleanAnswers };

    const messageData = {
      section: card.cardIDAsArray,
      owner: selfParty,
      message: '',
      status: isUpdating
        ? MESSAGE_STATUS_UPDATE_DONE
        : MESSAGE_STATUS_STATEMENT_CREATED,
      checkTone: false,
    };

    return dispatch(
      updateMatterSectionAction({
        section: card.cardIDAsArray,
        sectionData: cleanData,
        messageData,
      })
    )
      .then(() => {
        if (card.afterSubmitAction) {
          return card.afterSubmitAction({ isUpdating });
        }

        return null;
      })
      .then(() => {
        // if it's a statement card, skip the `sent` screen
        if (card.type === CARD_STATEMENT) {
          const dashboardRoute = getCardDashboardRoute(cardID);
          navigate(dashboardRoute);
        } else {
          setShowSentScreen(true);
        }
      });
  };

  if (showSentScreen) {
    const names = getNames();

    const dashboardRoute = getCardDashboardRoute(cardID);

    if (isUpdating) {
      return (
        <Sent buttonRoute={dashboardRoute} buttonLabel="Done">
          <H1Conversational>
            Your updated statement has been sent to {names.other}
          </H1Conversational>
          <Paragraph large>
            {names.other} can review your update and approve it, or send you a
            message if they want you to change something.
          </Paragraph>
        </Sent>
      );
    }

    return (
      <Sent buttonRoute={dashboardRoute} buttonLabel="Done">
        <H1Conversational>
          Your statement has been sent to {names.other} to review
        </H1Conversational>
      </Sent>
    );
  }

  const { hideQuestionFlowHeader = false } = card;

  return (
    <CardQuestionFlowProvider>
      <ProgressBarProvider>
        {!hideQuestionFlowHeader && (
          <CardQuestionFlowHeader
            backgroundImage={backgroundImage}
            backgroundColour={backgroundColour}
            previousRoute={previousRoute}
          />
        )}
        <StyledStyledPageContent hideQuestionFlowHeader>
          <QuestionsFlowWrapper
            questions={questions}
            onInputChange={(key: string, value: any) => onChange(key, value)}
            values={questionsInput}
            onSubmit={() => onQuestionsSubmit(questionsInput)}
            statement={card.statement}
            sectionID={cardID}
            card={card}
            title={card.title}
            titleIcon={icon}
            isUpdating={isUpdating}
          />
        </StyledStyledPageContent>
        {!shouldHideProgressBar && (
          <CardQuestionsFlowFooter card={card} questions={questions} />
        )}
        <StatementSubmitDrawer
          onSubmit={() => onQuestionsSubmit(questionsInput)}
          isUpdating={isUpdating}
        />
      </ProgressBarProvider>
    </CardQuestionFlowProvider>
  );
};

export default CardQuestionsFlow;
