import React from 'react';
import { Switch } from 'react-router';

import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import DiviRoute from 'Common/UI/DiviRoute';
import {
  ODRS_SUPERANNUATION_FUND_CLAUSES_NO_CHANGES_SUCCESS_ROUTE,
  ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE,
  ODRS_SUPERANNUATION_FUND_CLAUSES_SENT_PFL_SUCCESS_ROUTE,
} from 'Common/routes';
import PFLSentConfirmation from 'Common/UI/Form/SuperannuationFundClauseEditor/PFLSentConfirmation';
import NoChangesToClausesConfirmation from 'Common/UI/Form/SuperannuationFundClauseEditor/NoChangesToClausesConfirmation';
import MatterGuard from '../Guard/MatterGuard';

const SuperannuationFundClausesQuestionFlow: React.FC = (props: any) => (
  <CardQuestions baseID="superannuationfundclauses" {...props} />
);

const ODRSSuperannuationFundClauses: React.FC = () => (
  <MatterGuard>
    <Switch>
      <DiviRoute
        path={ODRS_SUPERANNUATION_FUND_CLAUSES_SENT_PFL_SUCCESS_ROUTE}
        component={PFLSentConfirmation}
      />
      <DiviRoute
        path={ODRS_SUPERANNUATION_FUND_CLAUSES_NO_CHANGES_SUCCESS_ROUTE}
        component={NoChangesToClausesConfirmation}
      />
      <DiviRoute
        path={`${ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE}/:updating?`}
        component={SuperannuationFundClausesQuestionFlow}
      />
    </Switch>
  </MatterGuard>
);

export default ODRSSuperannuationFundClauses;
