import React, { useState } from 'react';
import styled from 'styled-components';

import formatCurrency from 'Common/Utils/formatCurrency';
import {
  TRANSFER_OPTION_SELL,
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
  TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
  TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
  TRANSFER_OPTION_SPLIT_EVENLY,
  AssetSplitOption,
} from 'Common/constants';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import { H1Conversational, Heading3 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import { AssetCardContentType, AssetEnhanced } from 'Common/Data/Types/assets';
import CardWarning from 'Common/UI/Card/indicators/CardWarning';
import Textfield from 'Common/UI/Form/Textfield';
import AssetProperties from 'Common/Data/App/assetProperties';
import ValidationMessage from 'Common/UI/Form/ValidationMessage';
import getNames from 'Common/Utils/getNames';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import {
  AssetTitle,
  FlexHeadingContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Value,
} from './helpers';
import TransferrableAssetBase from './TransferrableAssetBase';
import Disclaimer from '../Disclaimer';
import { FlexColumn } from '../../AssetTransfer/common';

const StyledTextfield = styled(Textfield)`
  width: 75%;
  align-self: flex-end;
`;

const TransferrableAssetSuperannuation: React.FC<{
  asset: AssetEnhanced;
  isLoading: boolean;
  selectedOption?: AssetSplitOption;
  onTransferAsset: ({
    id,
    option,
    remove,
    editing,
    options,
  }: {
    id: string;
    option: AssetSplitOption;
    remove: boolean;
    editing?: boolean;
    options: { superAmountToTransfer: number };
  }) => Promise<void>;
}> = ({ asset, isLoading = false, selectedOption, onTransferAsset }) => {
  const [selectedTransferOption, setSelectedTransferOption] =
    useState<AssetCardContentType>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const { name, id, value, cards = [], isSelected, selectedValue } = asset;

  const [amountToTransfer, setAmountToTransfer] = useState(
    selectedValue ?? value
  );

  const { isOwingParty } = AssetProperties();
  const names = getNames();

  const handleTransferAsset = ({
    option,
    options: { remove = false, editing = false },
  }: {
    option: AssetSplitOption;
    options: { remove: boolean; editing?: boolean };
  }) =>
    onTransferAsset({
      id,
      option,
      remove,
      editing,
      options: { superAmountToTransfer: amountToTransfer },
    }).then(() => setIsModalVisible(false));

  const handleSelectCard = (
    option: AssetSplitOption,
    options: { isEditing: boolean } = { isEditing: false }
  ) => {
    if (
      ![
        TRANSFER_OPTION_TRANSFER_SOLE_TO_OWING,
        TRANSFER_OPTION_TRANSFER_SOLE_TO_OWED,
        TRANSFER_OPTION_TRANSFER_JOINT_TO_OWING,
        TRANSFER_OPTION_TRANSFER_JOINT_TO_OWED,
        TRANSFER_OPTION_SELL,
        TRANSFER_OPTION_SPLIT_EVENLY,
      ].includes(option)
    ) {
      throw Error(`Invalid transfer option (${option})`);
    }

    const { isEditing: isEditingFromOptions } = options;

    setIsEditing(isEditingFromOptions);

    if (isSelected && !isEditingFromOptions) {
      handleTransferAsset({
        option,
        options: { remove: true },
      }).then(() => {
        setSelectedTransferOption(undefined);
        setAmountToTransfer(0);
      });
    } else {
      const selected = cards.find(card => card.option === option);

      if (selected) {
        setSelectedTransferOption(selected);
        setIsDisclaimerVisible(!isEditingFromOptions);
        setIsModalVisible(true);
      }
    }
  };

  return (
    <TransferrableAssetBase
      asset={asset}
      isLoading={isLoading}
      selectedOption={selectedOption}
      isModalVisible={isModalVisible}
      hideModal={() => setIsModalVisible(false)}
      handleSelectCard={handleSelectCard}
    >
      <ModalHeader>
        <ResponsiveTextContainer>
          <CardWarning>
            {isOwingParty
              ? `Consider an appropriate amount based on your age, situation and
            income. Contact your superannuation fund directly if you have any
            questions about your superannuation and/or get some financial
            advice.`
              : `Consider an appropriate amount based on age, situation and income. ${names.other} may need to contact their superannuation fund directly if they have any questions about their superannuation and/or get some financial advice.`}
          </CardWarning>
        </ResponsiveTextContainer>
        <FlexHeadingContainer>
          <AssetTitle>Your {name} balance</AssetTitle>
          <Value>{formatCurrency(value)}</Value>
        </FlexHeadingContainer>
      </ModalHeader>
      {selectedTransferOption && (
        <>
          <ModalContent>
            {isDisclaimerVisible && (
              <Disclaimer
                heading={
                  <H1Conversational>
                    Transferring superannuation
                  </H1Conversational>
                }
                content={
                  isOwingParty ? (
                    <>
                      <Paragraph>
                        You are choosing to transfer an amount of your
                        superannuation to {names.other}.
                      </Paragraph>
                      <Paragraph>
                        If you include superannuation in your offer and{' '}
                        {names.other} accepts it, you’ll have to complete some
                        additional paperwork asking your superannuation fund if
                        they will let you transfer some of it to a former
                        partner.
                      </Paragraph>
                    </>
                  ) : (
                    <>
                      <Paragraph>
                        You are choosing to request an amount of superannuation
                        from {names.other}.
                      </Paragraph>
                      <Paragraph>
                        If you include superannuation in your offer and{' '}
                        {names.other} accepts it, {names.other} will have to
                        complete some additional paperwork asking their
                        superannuation fund if they will let them transfer some
                        of it to you.
                      </Paragraph>
                    </>
                  )
                }
                onClose={() => setIsModalVisible(false)}
                onContinue={() => setIsDisclaimerVisible(false)}
              />
            )}
            <FlexHeadingContainer marginBottom={8}>
              <AssetTitle>
                {isOwingParty
                  ? `How much of your superannuation would you like to transfer to ${names.other}?`
                  : `How much of ${names.other}'s superannuation would you like to transfer?`}
              </AssetTitle>
              <FlexColumn>
                <StyledTextfield
                  currency
                  data-cy="superannuation-amount-input"
                  onChange={(val: string) => setAmountToTransfer(Number(val))}
                  name="value"
                  value={`${amountToTransfer}`}
                  onFocus={() => {
                    setIsInputValid(true);
                  }}
                  onBlur={() => {
                    if (amountToTransfer > value || amountToTransfer <= 0) {
                      setIsInputValid(false);
                    } else {
                      setIsInputValid(true);
                    }
                  }}
                  disabled={false}
                  inputmode="numeric"
                />
              </FlexColumn>
            </FlexHeadingContainer>
            {!isInputValid && (
              <SpacedBox marginTop={4} marginBottom={8}>
                <ValidationMessage>
                  Please enter a value between $1 and {formatCurrency(value)}
                </ValidationMessage>
              </SpacedBox>
            )}
          </ModalContent>
          <ModalFooter>
            <GenericButton
              data-cy="asset-confirm-transfer"
              fullWidth
              disabled={!isInputValid}
              onClick={() =>
                handleTransferAsset({
                  option: selectedTransferOption.option,
                  options: { remove: false, editing: isEditing },
                })
              }
            >
              Done
            </GenericButton>
          </ModalFooter>
        </>
      )}
    </TransferrableAssetBase>
  );
};

export default TransferrableAssetSuperannuation;
