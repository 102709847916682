import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  SECTION_PARENTING,
  SECTION_PROPERTY,
  SECTION_CONSENT_ORDERS,
  SECTION_STATUS_OPT_IN,
  SECTION_STATUS_COMPLETED,
  FLAG_MATTER_OPTED_IN,
  SECTION_STATUS_NOT_STARTED,
  SECTION_STATUS_LOCKED,
} from 'Common/constants';
import {
  getSectionStatus,
  hasOptedInToSection,
} from 'App/Utils/sectionHelpers';
import { connect } from 'react-redux';
import { setMatterFlagsAction } from 'App/State/MatterActions';
import { localStorageRemove } from 'Common/Utils/localStorage';
import { Heading2 } from 'Common/UI/Text/Headings';
import Loader from 'Common/UI/Loader/Loader';
import Toggle from 'Common/UI/Form/Toggle';
import { Item } from './common';

const Wrapper = styled.div`
  margin: ${({ theme }) => theme.padding.xlarge}px 0;
`;

const AgreementItem = ({ section, title, status, optedIn, onChange }) => {
  let statusText = 'In progress';
  let showToggle = false;

  if (
    status === SECTION_STATUS_OPT_IN ||
    status === SECTION_STATUS_NOT_STARTED ||
    status === SECTION_STATUS_LOCKED
  ) {
    showToggle = true;

    if (optedIn) {
      statusText = 'Included';
    } else {
      statusText = 'Not included';
    }
  } else if (status === SECTION_STATUS_COMPLETED) {
    statusText = 'Completed';
  }

  return (
    <Item label={title} value={statusText}>
      {showToggle && (
        <Toggle id={section} onChange={onChange} value={optedIn} />
      )}
    </Item>
  );
};

AgreementItem.propTypes = {
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  optedIn: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

AgreementItem.defaultProps = {
  onChange: () => {},
};

const AgreementDocuments = ({ setMatterFlags, matter: { flags = {} } }) => {
  const [isLoading, setIsLoading] = useState(false);

  const optedInFlags = flags[FLAG_MATTER_OPTED_IN];

  const sections = {
    SECTION_PROPERTY: getSectionStatus({ section: SECTION_PROPERTY }),
    SECTION_PARENTING: getSectionStatus({ section: SECTION_PARENTING }),
    SECTION_CONSENT_ORDERS: getSectionStatus({
      section: SECTION_CONSENT_ORDERS,
    }),
  };

  const withLoading = promise => {
    setIsLoading(true);

    return promise.then(() => setIsLoading(false));
  };

  const optInOut = (section, optIn) => {
    if (optIn) {
      localStorageRemove(`DismissedOverviewOptInCard_${section}`);
    }

    return withLoading(
      setMatterFlags({
        [FLAG_MATTER_OPTED_IN]: { ...optedInFlags, [section]: optIn },
      })
    );
  };

  return (
    <Wrapper>
      <Heading2>Agreement documents</Heading2>

      <AgreementItem
        section={SECTION_PARENTING}
        title="Creating parenting arrangements"
        status={sections.SECTION_PARENTING.status}
        optedIn={hasOptedInToSection(SECTION_PARENTING)}
        onChange={value => optInOut(SECTION_PARENTING, value)}
      />
      <AgreementItem
        section={SECTION_PROPERTY}
        title="Dividing your money and property"
        status={sections.SECTION_PROPERTY.status}
        optedIn={hasOptedInToSection(SECTION_PROPERTY)}
        onChange={value => optInOut(SECTION_PROPERTY, value)}
      />
      <AgreementItem
        section={SECTION_CONSENT_ORDERS}
        title="Creating an application for consent order"
        status={sections.SECTION_CONSENT_ORDERS.status}
        optedIn={hasOptedInToSection(SECTION_CONSENT_ORDERS)}
        onChange={value => optInOut(SECTION_CONSENT_ORDERS, value)}
      />

      {isLoading && <Loader floating />}
    </Wrapper>
  );
};

AgreementDocuments.propTypes = {
  setMatterFlags: PropTypes.func.isRequired,
  matter: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  setMatterFlags: flags => dispatch(setMatterFlagsAction(flags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementDocuments);
