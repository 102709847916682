import React from 'react';
import AssetTable from 'Common/UI/AssetTable';
import MatterGuard from '../Guard/MatterGuard';

const ODRSAssetTable = () => (
  <MatterGuard>
    <AssetTable />
  </MatterGuard>
);

export default ODRSAssetTable;
