import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../Guard/MatterGuard';

const ODRSParentingPlanSchedule = props => (
  <MatterGuard>
    <CardQuestions baseID="schedule" {...props} />
  </MatterGuard>
);

export default ODRSParentingPlanSchedule;
