import React from 'react';
import { Switch } from 'react-router-dom';

import { ODRS_INVITE_ROUTE, ODRS_JOIN_ROUTE } from 'Common/routes';
import Invite from 'App/UI/Signup/Invite';
import Join from 'App/UI/Signup/Join';
import DiviRoute from 'Common/UI/DiviRoute';
import MatterGuard from '../Guard/MatterGuard';

const ODRSInvite = props => (
  <MatterGuard expectNone signOutAndContinueOnFailure>
    <Invite {...props} />
  </MatterGuard>
);

const ODRSJoin = props => (
  <MatterGuard expectNone>
    <Join {...props} />
  </MatterGuard>
);

const ODRSPartnerInvite = () => (
  <Switch>
    <DiviRoute exact path={ODRS_INVITE_ROUTE} component={ODRSInvite} />
    <DiviRoute exact path={ODRS_JOIN_ROUTE} component={ODRSJoin} />
  </Switch>
);

export default ODRSPartnerInvite;
