import React from 'react';
import styled from 'styled-components';

import { SECTION_RELATIONSHIP, PAGE_RELATIONSHIP_MAIN } from 'Common/constants';
import { areRelationshipAssetsAllAccountedFor } from 'App/Utils/matterHelpers';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import Paragraph from 'Common/UI/Text/Paragraph';
import Dashboard from 'App/UI/Dashboard/Dashboard';
import Main from './DashboardRelationship/Main';

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 0;
`;

const DashboardRelationship: React.FC = () => (
  <Dashboard
    tab={PAGE_RELATIONSHIP_MAIN}
    section={SECTION_RELATIONSHIP}
    pages={{
      [PAGE_RELATIONSHIP_MAIN]: Main,
    }}
    topContent={
      <ResponsiveTextContainer>
        {!areRelationshipAssetsAllAccountedFor() && (
          <StyledParagraph large>
            You will need to reach agreement on statements about you and your
            relationship.
          </StyledParagraph>
        )}
        {areRelationshipAssetsAllAccountedFor() && (
          <StyledParagraph large>
            You have reached agreement on statements about you and your
            relationship.
          </StyledParagraph>
        )}
      </ResponsiveTextContainer>
    }
  />
);

export default DashboardRelationship;
