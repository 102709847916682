import * as React from 'react';
import styled from 'styled-components';
import BackgroundShapesImage from 'Common/Assets/images/bg-signup-questions-shapes.png';

const StyledBackgroundShapes = styled.img`
  display: none;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
  }
`;

const BackgroundShapes: React.FC = () => (
  <StyledBackgroundShapes src={BackgroundShapesImage} />
);

export default BackgroundShapes;
