import React from 'react';
import { DEFAULT_OTHER_TEXT } from 'Common/constants';
import MatterProps from 'Common/Utils/MatterProps';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import { Heading4, H1Conversational } from 'Common/UI/Text/Headings';

const DivisionCalculationHelp = () => (
  <>
    <H1Conversational>
      How was this suggested division calculated?
    </H1Conversational>
    <Callout>
      amica works out suggested divisions based on how legal principles are
      applied in a situation like yours.
    </Callout>
    <Paragraph>
      {`If you think the suggested division is fair,
      you can accept it and send it to ${MatterProps(
        'other.firstname',
        DEFAULT_OTHER_TEXT
      )}.
      If you don’t agree with the suggested division, you can suggest something different 
      and explain your suggestion to ${MatterProps(
        'other.firstname',
        DEFAULT_OTHER_TEXT
      )}.`}
    </Paragraph>
    <Paragraph>
      Remember that any offers you or your former partner make are confidential and cannot be used in any later proceedings.
    </Paragraph>
    <Heading4>Age and health</Heading4>
    <Paragraph>
      This suggestion takes into account things like your age and health.
    </Paragraph>
    <Heading4>Income</Heading4>
    <Paragraph>
      amica also considered how much you and your former partner earn. This is a
      good indicator of how much you may earn in the future.
    </Paragraph>
    <Heading4>Contributions and future needs</Heading4>
    <Paragraph>
      And finally, amica has taken into account your contributions and future needs. 
      If you have children, this can include how you will share the care of the children. 
      Contributions can be both financial, like paying
      the mortgage or credit card debts, or non-financial, like looking after
      the kids or doing work around the house.
    </Paragraph>
  </>
);

export default DivisionCalculationHelp;
