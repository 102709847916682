import React from 'react';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph, { Callout } from 'Common/UI/Text/Paragraph';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

export const getStartedLearnMoreContent = (
  <>
    <H1Conversational>About you and your relationship</H1Conversational>
    <Paragraph>
      {`You have chosen to apply for consent orders. The court requires a 
        few more details in order to make consent orders, so we will need to 
        ask you a few more questions in this section.`}
    </Paragraph>
  </>
);

export const jurisdictionPopContent = (
  <>
    <H1Conversational>Jurisdiction</H1Conversational>
    <Callout>
      {`One way that you can select where to file your application, is to select 
      the state where you “ordinarily reside” as this is one of the grounds of 
      eligibility in all family law jurisdictions in Australia.`}
    </Callout>
    <Paragraph>
      {`In some situations there are reasons why an application might be eligible 
      to be filed in a different jurisdiction. For example, where you were ordinarily 
      resident in another jurisdiction for at least one third of the relationship.`}
    </Paragraph>
    <Paragraph>
      {`If you would like to find out more about selecting a jurisdiction, you 
        can call the free `}
      <ReferralLink href="https://www.amica.gov.au/legal-help-line.html">
        Legal Help Line
      </ReferralLink>{' '}
      in your state.
    </Paragraph>
    <Paragraph>
      {`Family law in Australia is federal law, which means that the law is the 
      same in all states of Australia, except in relation to de facto relationships 
      in Western Australia which are covered by Western Australian law.`}
    </Paragraph>
  </>
);
