import React from 'react';
import styled from 'styled-components';

import GenericButton from 'Common/UI/Button/GenericButton';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';
import { ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE } from 'Common/routes';
import { useParams } from 'react-router';
import { CardYourSuperannuation } from 'Common/Data/Types/appSections';
import { definitelyGetCard } from 'Common/Data/App/appSections';
import { MESSAGE_STATUS_UPDATE_DONE } from 'Common/constants';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import getNames from 'Common/Utils/getNames';
import PageContent from 'Common/UI/Layout/PageContent';
import CloseButton from 'Common/UI/Button/CloseButton';

const StyledPageContent = styled(PageContent)`
  position: relative;
  display: inline-flex;
  justify-content: center;
`;

const PageColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    max-width: 450px;
  }
`;

const FooterActions = styled.div`
  flex: 0;
  width: 100%;
`;

const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing[16]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    height: auto;
    padding-top: 0;
    justify-content: center;
  }
`;

const Body = styled.div`
  flex: 1;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: 0;
    padding-bottom: ${({ theme }) => theme.spacing[64]};
  }
`;

export const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: ${({ theme }) => theme.spacing[16]};
    right: ${({ theme }) => theme.spacing[16]};
  }
`;

const ConfirmRequestSuperannuationBalance: React.FC = () => {
  const { withLoading } = usePromiseWithLoading();
  // get the card index from the url
  const { index: paramsIndex } = useParams<{ index?: string }>();

  const dispatch = useAppDispatch();

  const navigate = (route: string) => dispatch(push(route));

  const cardID = `yoursuperannuation${paramsIndex}`;

  const card = definitelyGetCard<CardYourSuperannuation>(cardID);

  const selfParty = getPartyKeys().self;

  const otherName = getNames().other;

  const setFlagAndNavigate = () =>
    withLoading(async () => {
      const cardData = {
        ...card.data,
        __flags: {
          ...(card.data?.__flags || {}),
          superBalanceRequested: true,
          cancelledRequest: false, // reset the flag that indicates the request has been cancelled (used to display a banner to the other party)
        },
      };

      const messageData = {
        section: card.cardIDAsArray,
        owner: selfParty,
        message: '',
        status: MESSAGE_STATUS_UPDATE_DONE,
        checkTone: false,
      };

      await dispatch(
        updateMatterSectionAction({
          section: card.cardIDAsArray,
          sectionData: cardData,
          messageData,
        })
      );

      navigate(ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE);
    });

  return (
    <StyledPageContent>
      <StyledCloseButton
        onClick={() => navigate(ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE)}
      >
        <span>Close</span>
      </StyledCloseButton>
      <PageColumn>
        <Content>
          <Body>
            <H1Conversational>Request superannuation balance</H1Conversational>
            <Paragraph>
              If you do not agree with {otherName}'s superannuation balance, you
              will need to confirm the amount by completing a Superannuation
              Information Kit which you can get from {otherName}'s
              superannuation fund.
            </Paragraph>
            <Paragraph>
              You can continue once you have received the letter back and agreed
              on this statement.
            </Paragraph>
          </Body>
          <FooterActions>
            <GenericButton
              onClick={setFlagAndNavigate}
              fullWidth
              data-cy="request-balance-button"
            >
              Request superannuation balance
            </GenericButton>
          </FooterActions>
        </Content>
      </PageColumn>
    </StyledPageContent>
  );
};

export default ConfirmRequestSuperannuationBalance;
