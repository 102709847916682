import {
  relationshipDashboardCompletedAndLockedFlags,
  relationshipDashboardCompletedFlags,
} from 'Common/UI/Banners/RelationshipDashboardCompleted';
import {
  BANNER_HIDE,
  RELATIONSHIP_AGREEMENT_MADE,
  SECTION_RELATIONSHIP,
} from 'Common/constants';
import { SectionState } from 'Common/Data/Types/appState';
import Matter from 'Common/Data/Types/matter';

const data: SectionState = {
  [SECTION_RELATIONSHIP]: {
    pageBlock: [
      {
        id: 'relationshipAgreementMade',
        description: 'Parenting or Property Agreement has been made',
        check: ({ self, other }: Matter) =>
          !!(
            (self.canExportParenting && other.canExportParenting) ||
            (self.canExportProperty && other.canExportProperty)
          ),
        flags: {
          appState: RELATIONSHIP_AGREEMENT_MADE,
          bannerTopState: BANNER_HIDE,
        },
      },
    ],
    state: [
      relationshipDashboardCompletedAndLockedFlags,
      relationshipDashboardCompletedFlags,
    ],
  },
};

export default data;
