/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment';

import * as Sentry from '@sentry/browser';
import fetch from 'cross-fetch';

import {
  MESSAGE_STATUS_MESSAGE,
  MESSAGE_STATUS_REOPENED,
  STATUS_REOPENED,
  STATUS_REMOVED,
  MESSAGE_STATUS_REMOVED,
  Agreements,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import Matter, {
  AssetSplitAsset,
  InviteRecord,
  Item,
} from 'Common/Data/Types/matter';
import {
  MessageData,
  RemoveSuperannuationFlowActions,
  SectionIDArray,
} from 'Common/Data/Types/types';
import getUserSession from './getUserSession';
import { createAppAsyncThunk } from './Store';
import {
  matterLoadInviteSuccess,
  matterLoadMatterSuccess as loadMatterSuccessAction,
} from './MatterReducer';

// can't use destructuring here because of a bug in instanbul
// eslint-disable-next-line prefer-destructuring
const API_ENDPOINT_BASE = process.env.API_ENDPOINT_BASE;

const handleErrors = (response: Response, endpoint = ''): Promise<Response> => {
  if (response.status >= 400) {
    return response.json().then((data: any) => {
      Sentry.setContext('API Error Info', {
        endpoint,
        statusCode: response.status,
        message: data && data.message ? data.message : undefined,
      });

      throw data;
    });
  }

  return Promise.resolve(response);
};

export const loadMatterAction = createAppAsyncThunk(
  'matter/loadMatter',
  (_, { dispatch }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/matter/load`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => {
        if (response.ok) return response;
        throw new Error(`${response.status}`);
      })
      .then(response => handleErrors(response, 'loadMatter'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

type CreateMatterActionProps = {
  legalname: { firstname: string; middlename: string; lastname: string };
  readTerms: boolean;
  email: string;
  receiveIncomeSupport?: boolean;
  incomeSupportPaymentType?: string;
  incomeSupportPaymentCRN?: string;
};

export const createMatterAction = createAppAsyncThunk(
  'matter/createMatter',
  (
    {
      email,
      readTerms,
      legalname: { firstname, middlename, lastname },
      receiveIncomeSupport = false,
      incomeSupportPaymentType,
      incomeSupportPaymentCRN,
    }: CreateMatterActionProps,
    { dispatch }
  ) =>
    getUserSession()
      .then(session => {
        const matterProps = {
          email,
          readTerms,
          lastname,
          firstname,
          middlename,
          receiveIncomeSupport,
          incomeSupportPaymentType,
          incomeSupportPaymentCRN,
        };

        return fetch(`${API_ENDPOINT_BASE}/matter/new`, {
          method: 'POST',
          body: JSON.stringify({ user: matterProps }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        });
      })
      .then(response => response.json() as PromiseLike<Matter>)
      .then(matter => dispatch(loadMatterSuccessAction({ matter })))
);

export const updateMatterSectionAction = createAppAsyncThunk(
  'matter/updateMatterSection',
  async (
    {
      section,
      sectionData,
      messageData,
    }: {
      section: SectionIDArray;
      sectionData: Record<string, unknown>;
      messageData: MessageData;
    },
    { dispatch }
  ) => {
    if (!messageData) {
      throw Error('Message is required');
    }

    if (!Array.isArray(section)) {
      throw Error('`section` must be an array, strings no longer work');
    }

    return getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/matter/update`, {
          method: 'POST',
          body: JSON.stringify({
            section,
            data: { ...sectionData },
            message: messageData,
            user: MatterPropsJS('self'),
            MatterID: MatterPropsJS('MatterID'),
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'updateMatterSection'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)));
  }
);

export const reopenItemAction = createAppAsyncThunk(
  'matter/reopenItem',
  (item: Item, { dispatch }) => {
    const section: SectionIDArray = item.CardIndex
      ? [item.BaseSection, item.CardIndex]
      : [item.BaseSection];

    const messageData: MessageData = {
      owner: MatterPropsJS('self.party'),
      section,
      status: MESSAGE_STATUS_REOPENED,
      checkTone: false,
    };

    return dispatch(
      updateMatterSectionAction({
        section,
        sectionData: { ...item, status: STATUS_REOPENED },
        messageData,
      })
    );
  }
);

export const removeItemAction = createAppAsyncThunk(
  'matter/removeItem',
  (item: Item, { dispatch }) => {
    const section: SectionIDArray = item.CardIndex
      ? [item.BaseSection, item.CardIndex]
      : [item.BaseSection];

    const messageData: MessageData = {
      owner: MatterPropsJS('self.party'),
      section,
      status: MESSAGE_STATUS_REMOVED,
      checkTone: false,
    };

    return dispatch(
      updateMatterSectionAction({
        section,
        sectionData: { ...item, status: STATUS_REMOVED },
        messageData,
      })
    );
  }
);

export const sendMessageAction = createAppAsyncThunk(
  'matter/sendMessage',
  (messageData: MessageData, { dispatch }) =>
    getUserSession()
      .then(session => {
        if (!messageData.status) {
          // eslint-disable-next-line no-param-reassign
          messageData.status = MESSAGE_STATUS_MESSAGE;
        }

        return fetch(`${API_ENDPOINT_BASE}/matter/sendMessage`, {
          method: 'POST',
          body: JSON.stringify({
            message: messageData,
            MatterID: MatterPropsJS('MatterID'),
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        });
      })
      .then(response => handleErrors(response, 'sendMessage'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const checkInviteAction = createAppAsyncThunk(
  'matter/checkInvite',
  (code: string, { dispatch }) =>
    fetch(`${API_ENDPOINT_BASE}/invite`, {
      method: 'POST',
      body: JSON.stringify({ APIAction: 'loadByID', InviteID: code }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json() as PromiseLike<InviteRecord>)
      .then(invite => {
        if (invite.InviteID === code) {
          dispatch(matterLoadInviteSuccess(invite));
        }
      })
);

export const checkInviteEmailAction = (email: string) =>
  fetch(`${API_ENDPOINT_BASE}/invite`, {
    method: 'POST',
    body: JSON.stringify({ APIAction: 'loadByEmail', email }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(response =>
    response.json().then(result => ({
      status: response.status,
      result,
    }))
  );

export const joinMatterAction = createAppAsyncThunk(
  'matter/joinMatter',
  (
    {
      data: {
        legalname: { firstname, middlename, lastname },
        ...rest
      },
      InviteID,
    }: { data: any; InviteID: string },
    { dispatch }
  ) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/matter/join`, {
          method: 'POST',
          body: JSON.stringify({
            user: { firstname, middlename, lastname, ...rest },
            InviteID,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const finalizeCaptureAction = createAppAsyncThunk(
  'matter/finalizeCapture',
  (_, { dispatch }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/division/finalize`, {
          method: 'POST',
          body: '',
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'finalizeCapture'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const setPartyFlagsAction = createAppAsyncThunk('matter/setPartyFlags', (
  flags: any, // @TODO type this
  { dispatch }
) =>
  getUserSession()
    .then(session =>
      fetch(`${API_ENDPOINT_BASE}/matter/setFlags`, {
        method: 'POST',
        body: JSON.stringify({ APIAction: 'party', flags }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.getIdToken().getJwtToken(),
        },
      })
    )
    .then(response => response.json() as PromiseLike<{ matter: Matter }>)
    .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const setMatterFlagsAction = createAppAsyncThunk(
  'matter/setMatterFlags',
  (flags: Record<string, unknown>, { dispatch }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/matter/setFlags`, {
          method: 'POST',
          body: JSON.stringify({ APIAction: 'matter', flags }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'setMatterFlags'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const resetAgreementStateAction = createAppAsyncThunk(
  'matter/resetAgreementState',
  (name: { name: string }, { dispatch }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/party/resetAgreementState`, {
          method: 'POST',
          body: JSON.stringify(name),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const addEmptyItemAction = createAppAsyncThunk(
  'matter/addEmptyItem',
  (section: string, { dispatch }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/matter/item/add`, {
          method: 'POST',
          body: JSON.stringify({ section, direction: 'increment' }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'addEmptyItem'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const removeEmptyItemAction = createAppAsyncThunk(
  'matter/removeEmptyItem',
  (section: string, { dispatch }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/matter/item/add`, {
          method: 'POST',
          body: JSON.stringify({ section, direction: 'decrement' }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'removeEmptyItem'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const sendNegotiationAction = createAppAsyncThunk(
  'matter/sendNegotiation',
  (
    offer: any, // @TODO type this
    { dispatch }
  ) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/division/negotiate`, {
          method: 'POST',
          body: JSON.stringify({ offer }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'sendNegotiation'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const getDocumentUploadURLAction = (filename: string) => () =>
  getUserSession()
    .then(session =>
      fetch(`${API_ENDPOINT_BASE}/presignedurl/create`, {
        method: 'POST',
        body: JSON.stringify({ FilePath: filename }),
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
        },
      })
    )
    .then(response => response.json());

export const getDocumentViewURL = (filename: string) =>
  getUserSession()
    .then(session =>
      fetch(`${API_ENDPOINT_BASE}/presignedurl/loadwiththumbnail`, {
        method: 'POST',
        body: JSON.stringify({ FilePath: filename }),
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
        },
      })
    )
    .then(response => response.json());

/**
 * If a user authenticates and the system cannot find a matter for them it has
 * to assume that they didn't complete verification etc.
 * If there is an InviteID in their profile (set at registration) then they
 * need to be joined to an existing matter. If not we assume they are party A
 * and create a new matter.
 */
export const recoverMatterPostSignupAction = createAppAsyncThunk(
  'matter/recoverMatterPostSignup',
  async (_, { dispatch }) => {
    const session = await getUserSession();
    const {
      email,
      family_name,
      given_name,
      middle_name,
      'custom:InviteID': InviteID,
    } = session.getIdToken().payload;

    const matterProps = {
      email,
      readTerms: true,
      legalname: {
        lastname: family_name,
        firstname: given_name,
        middlename: middle_name || '',
      },
    };

    if (InviteID) {
      return dispatch(joinMatterAction({ data: matterProps, InviteID }));
    }

    return dispatch(createMatterAction(matterProps));
  }
);

export const getDocumentViewURLAction = (filename: string) => () =>
  getDocumentViewURL(filename);

/**
 * Update the details for a user stored in the Matter.
 */
type UpdateProfileInformationActionProps = {
  legalname: { firstname: string; middlename: string; lastname: string };
  email: string;
  receiveIncomeSupport: boolean;
  incomeSupportPaymentType?: string;
  incomeSupportPaymentCRN?: string;
};
export const updateProfileInformationAction = createAppAsyncThunk(
  'matter/updateProfileInformation',
  (
    {
      legalname: { firstname, middlename, lastname },
      email,
      receiveIncomeSupport = false,
      incomeSupportPaymentType,
      incomeSupportPaymentCRN,
    }: UpdateProfileInformationActionProps,
    { dispatch }
  ) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/party/update`, {
          method: 'POST',
          body: JSON.stringify({
            user: {
              firstname,
              middlename,
              lastname,
              email,
              receiveIncomeSupport,
              incomeSupportPaymentType,
              incomeSupportPaymentCRN,
            },
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const sendAssessmentEmailAction = createAppAsyncThunk(
  'matter/sendAssessmentEmail',
  (
    content: any // @TODO type this
  ) =>
    getUserSession().then(session =>
      fetch(`${API_ENDPOINT_BASE}/matter/sendAssessmentEmail`, {
        method: 'POST',
        body: JSON.stringify({
          content,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.getIdToken().getJwtToken(),
        },
      })
    )
);

export const exportEmailAction = createAppAsyncThunk('matter/exportEmail', (
  content: any, // @TODO type this
  { dispatch }
) =>
  getUserSession()
    .then(session =>
      fetch(`${API_ENDPOINT_BASE}/matter/emailToSelf`, {
        method: 'POST',
        body: JSON.stringify({
          content,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.getIdToken().getJwtToken(),
        },
      })
    )
    .then(response => response.json() as PromiseLike<{ matter: Matter }>)
    .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const getAgreementDocURLAction = createAppAsyncThunk(
  'matter/getAgreementDocURL',
  (props: { name: Agreements; forceRegeneration?: boolean }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/matter/exportPDF`, {
          method: 'POST',
          body: JSON.stringify({
            ...props,
            time: moment().toISOString(true),
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => response.json())
);

export const transferAssetAction = createAppAsyncThunk(
  'matter/transferAsset',
  (
    assets: (AssetSplitAsset & { remove: boolean; editing: boolean })[],
    { dispatch }
  ) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/division/transferAsset`, {
          method: 'POST',
          body: JSON.stringify(assets),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'transferAsset'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const sendAssetSplitOfferAction = createAppAsyncThunk(
  'matter/sendAssetSplitOffer',
  (
    content: {
      message: string;
      shortfall: number;
    },
    { dispatch }
  ) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/division/sendAssetSplitOffer`, {
          method: 'POST',
          body: JSON.stringify(content),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'sendAssetSplitOffer'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const confirmAssetTransferAction = createAppAsyncThunk(
  'matter/confirmAssetTransfer',
  (_, { dispatch }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/division/confirmAssetTransfer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => handleErrors(response, 'confirmAssetTransfer'))
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const requestPaymentTokenAction = createAppAsyncThunk(
  'matter/requestPaymentToken',
  () =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/payment`, {
          method: 'POST',
          body: JSON.stringify({ APIAction: 'createPaymentToken' }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => response.json() as PromiseLike<{ token: string }>)
);

export const requestPaymentAction = createAppAsyncThunk(
  'matter/requestPayment',
  (
    data: {
      token: string;
      skus: Agreements[];
    },
    { dispatch }
  ) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/payment`, {
          method: 'POST',
          body: JSON.stringify({ APIAction: 'submit', ...data }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(
        response => response.json() as PromiseLike<{ receiptNumber: string }>
      )
      .then(response => {
        if (response.receiptNumber) return response;
        throw response;
      })
      .then(({ receiptNumber }) =>
        getUserSession().then(session =>
          fetch(`${API_ENDPOINT_BASE}/payment`, {
            method: 'POST',
            body: JSON.stringify({ APIAction: 'postProcess', receiptNumber }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: session.getIdToken().getJwtToken(),
            },
          })
        )
      )
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);

export const getInvoiceAction = createAppAsyncThunk(
  'matter/getInvoice',
  (receiptNumber: string) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/payment`, {
          method: 'POST',
          body: JSON.stringify({ APIAction: 'getInvoice', receiptNumber }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => response.json())
);

export const removeSuperannuationAction = createAppAsyncThunk(
  'matter/removeSuperannuation',
  (action: RemoveSuperannuationFlowActions, { dispatch }) =>
    getUserSession()
      .then(session =>
        fetch(`${API_ENDPOINT_BASE}/matter/removeSuperannuation`, {
          method: 'POST',
          body: JSON.stringify({ action }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
      )
      .then(response => response.json() as PromiseLike<{ matter: Matter }>)
      .then(matter => dispatch(loadMatterSuccessAction(matter)))
);
