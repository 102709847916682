import React from 'react';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

export const completedLearnMoreContent = () => (
  <>
    <H1Conversational>File it with the court</H1Conversational>
    <Paragraph>
      Once your documents are ready, you can file them at the relevant court.
      There a few important things to note:
    </Paragraph>
    <Paragraph>
      1. You must file the documents within 90 days of the date of the first
      Statement of Truth otherwise the consent orders may not be made.
      <br />
      <br />
      2. When you download your consent orders we will also provide a checklist
      for filing the documents with the court.
      <br />
      <br />
      3. The court charges a fee for filing an application for consent orders.
      If you think you will have difficulty paying the court fee contact the
      court to ask about options for fee help.
    </Paragraph>
  </>
);
