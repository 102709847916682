import { dashboardTabLockedFlags } from 'Common/UI/Banners/DashboardTabLocked';
import {
  PAGE_CONSENT_ORDERS_GET_STARTED,
  PAGE_CONSENT_ORDERS_RELATIONSHIP,
  PAGE_CONSENT_ORDERS_ASSETS,
  PAGE_CONSENT_ORDERS_FINANCES,
  PAGE_CONSENT_ORDERS_COMPLETED,
  CONSENT_ORDERS_GET_STARTED_REQUIREMENTS,
  CONSENT_ORDERS_GET_STARTED_NOT_SUITABLE,
  CONSENT_ORDER_GET_STARTED_COMPLETE,
  CONSENT_ORDER_RELATIONSHIP_COMPLETE,
  CONSENT_ORDER_FINANCES_COMPLETE,
  CONSENT_ORDER_ASSETS_COMPLETE,
  CONSENT_ORDER_COMPLETED_COMPLETE,
  CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
  DOC_TYPE_CONSENT_ORDERS,
  SECTION_CONSENT_ORDERS,
  CONSENT_ORDER_SUPERANNUATION_NOT_SUITABLE,
  BANNER_HIDE,
  CONSENT_ORDER_GET_STARTED_LOCKED,
} from 'Common/constants';
import { SectionState } from 'Common/Data/Types/appState';
import Matter from 'Common/Data/Types/matter';
import AssetProps from 'Common/Data/App/assetProperties';
import { superannuationConsentOrderNoChangesPayeeFlags } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderNoChangesPayee';
import { superannuationConsentOrderChangesCompletePayeeFlags } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderChangesCompletePayee';

import {
  getStartedCheck,
  checkPageUnLocked,
} from 'Common/Utils/consentOrderPageChecks';

import { isPaymentDue } from 'Common/Utils/Payments';
import { superannuationConsentOrderChangesAcceptedPayerFlags } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderChangesAcceptedPayer';
import { superannuationConsentOrderChangesWaitingForOtherPayerFlags } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderChangesWaitingForOtherPayer';
import { superannuationConsentOrderNoChangesPayerFlags } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderNoChangesPayer';
import { superannuationConsentOrderWaitingForOtherToSendPayeeFlags } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderWaitingForOtherToSendPayee';
import { superannuationConsentOrderViewPFLPayerFlags } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderViewPFLPayer';
import { superannuationConsentOrderChangesWaitingForSelfPayeeFlags } from 'Common/UI/Banners/SuperSplitting/SuperannuationConsentOrderChangesWaitingForSelfPayee';
import AssetProperties from 'Common/Data/App/assetProperties';

const data: SectionState = {
  [SECTION_CONSENT_ORDERS]: {
    pageBlock: [
      {
        id: 'consentOrdersPrereqsNotCompleted',
        description: 'Consent orders pre-requisites not completed',
        check: () => {
          const { isAssetSplitCompleted } = AssetProps();

          return !isAssetSplitCompleted;
        },
        flags: {
          appState: CONSENT_ORDER_GET_STARTED_LOCKED,
        },
      },
    ],
    state: [
      {
        id: 'consentOrdersSuperannuationNotSuitable',
        description: 'Superannuation is not suitable for consent orders',
        check: (matter: Matter) => {
          const {
            flags: { superannuationNotSuitable = false },
          } = matter;

          return superannuationNotSuitable;
        },
        flags: {
          appState: CONSENT_ORDER_SUPERANNUATION_NOT_SUITABLE,
        },
      },
      dashboardTabLockedFlags,
      {
        id: 'consentOrderCompletedComplete',
        description: 'Consent Order - COMPLETED - Complete',
        check: () => checkPageUnLocked.completed.completed(),
        flags: {
          appState: CONSENT_ORDER_COMPLETED_COMPLETE,
          bannerTopState: BANNER_HIDE,
        },
      },
    ],
  },
  [PAGE_CONSENT_ORDERS_GET_STARTED]: {
    pageBlock: [],
    state: [
      {
        id: 'consentOrderGetStartedRequirements',
        description: 'Consent Order - Get Started - Requirements - Start',
        check: (matter: Matter) =>
          typeof matter.self.isSuitableConsentOrders === 'undefined',
        flags: {
          appState: CONSENT_ORDERS_GET_STARTED_REQUIREMENTS,
        },
      },
      {
        id: 'consentOrderGetStartedRequirementsNotSuitable',
        description:
          'Consent Order - Get Started - Requirements - Not Suitable',
        check: () =>
          getStartedCheck.requiredNotSuited() ||
          (typeof getStartedCheck.jurisdictionSuited() !== 'undefined' &&
            !getStartedCheck.jurisdictionSuited()),
        flags: {
          appState: CONSENT_ORDERS_GET_STARTED_NOT_SUITABLE,
        },
      },
      {
        id: 'consentOrderGetStartedComplete',
        description: 'Consent Order - Get Started - Complete',
        check: () =>
          !!(
            checkPageUnLocked.getStarted.completed() &&
            !checkPageUnLocked.relationship.completed()
          ),
        flags: {
          appState: CONSENT_ORDER_GET_STARTED_COMPLETE,
        },
      },
    ],
  },
  [PAGE_CONSENT_ORDERS_RELATIONSHIP]: {
    pageBlock: [],
    state: [
      {
        id: 'consentOrderRelationshipNext',
        description: 'Consent Order - Realtionship - Complete',
        check: () =>
          checkPageUnLocked.relationship.completed() &&
          !checkPageUnLocked.assets.completed(),
        flags: {
          appState: CONSENT_ORDER_RELATIONSHIP_COMPLETE,
        },
      },
    ],
  },
  [PAGE_CONSENT_ORDERS_ASSETS]: {
    pageBlock: [],
    state: [
      {
        id: 'consentOrderAssetNext',
        description: 'Consent Order - Assets - Complete',
        check: () =>
          checkPageUnLocked.assets.completed() &&
          !checkPageUnLocked.finances.completed(),
        flags: {
          appState: CONSENT_ORDER_ASSETS_COMPLETE,
        },
      },
    ],
  },
  [PAGE_CONSENT_ORDERS_FINANCES]: {
    pageBlock: [],
    state: [
      {
        id: 'consentOrderFinancesNext',
        description: 'Consent Order - Finances - Complete',
        check: () =>
          checkPageUnLocked.finances.completed() &&
          !checkPageUnLocked.completed.completed(),
        flags: {
          appState: CONSENT_ORDER_FINANCES_COMPLETE,
        },
      },
    ],
  },
  [PAGE_CONSENT_ORDERS_COMPLETED]: {
    pageBlock: [],
    state: [
      superannuationConsentOrderViewPFLPayerFlags,
      superannuationConsentOrderNoChangesPayeeFlags,
      superannuationConsentOrderChangesCompletePayeeFlags,
      superannuationConsentOrderChangesAcceptedPayerFlags,
      superannuationConsentOrderChangesWaitingForOtherPayerFlags,
      superannuationConsentOrderChangesWaitingForSelfPayeeFlags,
      superannuationConsentOrderNoChangesPayerFlags,
      superannuationConsentOrderWaitingForOtherToSendPayeeFlags,
      {
        id: 'docTypesConsentOrderPaymentRequired',
        description: 'Payment required for consent order',
        check: (matter: Matter) => {
          const { isSuperIncludedInOffer, superSplittingFlags } =
            AssetProperties();

          const {
            payerHasSubmittedProceduralFairnessLetter,
            payerHasConfirmedNoChangesToClauses,
          } = superSplittingFlags;

          // if super splitting is not included, show this banner based on whether payment is due or not
          if (!isSuperIncludedInOffer) {
            return isPaymentDue(matter, DOC_TYPE_CONSENT_ORDERS);
          }

          // otherwise, hide the banner if payment is due but the final steps of super splitting is in progress
          return (
            isPaymentDue(matter, DOC_TYPE_CONSENT_ORDERS) &&
            payerHasSubmittedProceduralFairnessLetter &&
            payerHasConfirmedNoChangesToClauses
          );
        },
        flags: {
          appState: CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
        },
        usedAsNotificationState: true,
      },
    ],
  },
};

export default data;
