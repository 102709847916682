import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveBannerContainer from 'Common/UI/Banners/ResponsiveBannerContainer';
import BannerContainer from 'Common/UI/Banners/BannerContainer';

import {
  SECTION_RELATIONSHIP,
  SECTION_PROPERTY,
  SECTION_PARENTING,
  SECTION_HOME,
  SECTION_CONSENT_ORDERS,
} from 'Common/constants';

// Page Banner
import {
  Relationship,
  ParentingPlan,
  Settlement,
  Home,
  ConsentOrders,
} from './Content';

const getBannerContent = ({ section }) =>
  ({
    [SECTION_HOME]: Home,
    [SECTION_RELATIONSHIP]: Relationship,
    [SECTION_PROPERTY]: Settlement,
    [SECTION_PARENTING]: ParentingPlan,
    [SECTION_CONSENT_ORDERS]: ConsentOrders,
  }[section]);

const Banner = ({ section = SECTION_HOME, page, navigate, appState }) => {
  const BannerContent = getBannerContent({ section });
  return (
    <BannerContainer data-cy="banner-top">
      <ResponsiveBannerContainer>
        <BannerContent
          appState={appState}
          navigate={navigate}
          section={section}
          page={page}
        />
      </ResponsiveBannerContainer>
    </BannerContainer>
  );
};

Banner.propTypes = {
  section: PropTypes.string,
  page: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  appState: PropTypes.string.isRequired,
};

Banner.defaultProps = {
  section: SECTION_PROPERTY,
  page: undefined,
};

export default Banner;
