import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import FeedbackBackground from 'Common/Assets/images/feedback-background.svg';
import theme from 'Common/Utils/theme';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { Column } from 'Common/UI/Layout/Flex';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';

const FeedbackWrapper = styled.div`
  margin-top: ${props => props.theme.spacing[12]};
  margin-bottom: ${props => props.theme.spacing[12]};
  background: ${props => props.theme.colours.white};
`;

const StyledColumn = styled(Column)`
  img {
    width: 100%;
    height: auto;
  }
`;

const StyledHeading = styled(Heading2)`
  margin-top: 0;
`;

const StyledButton = styled(GenericButton)`
  margin-top: ${props => props.theme.spacing[6]};
`;

const Content = styled.div`
  text-align: left;
  width: 100%;
  padding: ${props => props.theme.spacing[6]};
`;

const StyledReferralLink = styled(ReferralLink)`
  text-decoration: none;
  display: block;
  text-align: center;
`;

const FeedbackInline = () => (
  <ThemeProvider theme={theme}>
    <FeedbackWrapper>
      <StyledColumn>
        <img src={FeedbackBackground} alt="" />
        <Content>
          <StyledHeading>Share your feedback with us</StyledHeading>

          <Paragraph small>
            Thanks for using amica. Help us improve this service by giving your
            feedback.
          </Paragraph>

          <StyledButton
            as={StyledReferralLink}
            fullWidth
            target="_blank"
            href="https://www.surveymonkey.com/r/6HQ9GDG"
          >
            Give feedback
          </StyledButton>
        </Content>
      </StyledColumn>
    </FeedbackWrapper>
  </ThemeProvider>
);

export default FeedbackInline;
