import React from 'react';

import { H2Conversational } from 'Common/UI/Text/Headings';
import { Callout } from 'Common/UI/Text/Paragraph';
import { SlideInner, SlideText } from 'Common/UI/Carousel/ImageSlideWrapper';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';

const ParentingPlanSlide1 = () => (
  <SlideInner>
    <SlideText paddingTop data-cy="parent-slide-1">
      <H2Conversational>How your parenting agreement works</H2Conversational>
      <Callout>
        amica can help you reach a parenting agreement. Working it out between
        you means that you can both have a say. You don’t need lawyers or courts
        to make a parenting agreement. For further information see our{' '}
        <ReferralLink href="https://www.amica.gov.au/parenting-arrangements.html">
          Parenting arrangements
        </ReferralLink>{' '}
        page.
      </Callout>
    </SlideText>
  </SlideInner>
);

export default ParentingPlanSlide1;
