import React from 'react';
import EditStatement from 'App/UI/Questions/EditStatement';
import MatterGuard from '../Guard/MatterGuard';

const ODRSEditStatement = () => (
  <MatterGuard>
    <EditStatement />
  </MatterGuard>
);

export default ODRSEditStatement;
