import React, { ReactNode, useContext } from 'react';
import styled from 'styled-components';

import { STATUS_NEW_BOTH_PARTIES } from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import {
  EphemeralStateCard,
  QuestionStateCard,
  CardAgreement,
  CardStatement,
  CardNormal,
  CardComponent,
  CardCustom,
} from 'Common/Data/Types/appSections';
import Card from 'Common/UI/Card';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  MajorDecisions,
  DisputeResolution,
} from 'App/UI/Dashboard/Parenting/LearnMore';
import { generateCardHeading, NormalCard } from 'App/UI/Dashboard/common/Cards';
import isPageContentsUnlocked from 'App/AppState/isPageContentsUnlocked';
import { DashboardContext } from 'Common/UI/DashboardContext';
import { getBasicsCardsState } from './helpers';
import CustomCard from '../common/Cards/CustomCard';

const StyledCheckboxLabel = styled(Paragraph)`
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 0 6px;
  line-height: 24px;
`;

// Group
export const generateNormalCard = (
  data: QuestionStateCard<CardNormal>,
  matter: Matter
): ReactNode => {
  const { cardID } = data;

  const { section, tab } = useContext(DashboardContext);

  const { isAllChecked } = getBasicsCardsState(matter);

  const isActive = isPageContentsUnlocked(matter, section, tab) && isAllChecked;

  const cardHeadingText = generateCardHeading(data, matter);

  return (
    <NormalCard
      key={cardID}
      data={data}
      matter={matter}
      cardHeadingText={cardHeadingText}
      isActive={isActive}
    />
  );
};

export const generateCustomCard = (
  data: QuestionStateCard<CardCustom>,
  matter: Matter
): ReactNode => {
  const { cardID, customCardComponent: CustomCardComponent } = data;

  const { section, tab } = useContext(DashboardContext);

  const { isAllChecked } = getBasicsCardsState(matter);

  const isActive = isPageContentsUnlocked(matter, section, tab) && isAllChecked;

  const cardHeadingText = generateCardHeading(data, matter);

  const Component = CustomCardComponent ?? CustomCard;

  return (
    <Component
      key={cardID}
      data={data}
      matter={matter}
      cardHeadingText={cardHeadingText}
      isActive={isActive}
    />
  );
};

export const generateComponentCard = (
  data: EphemeralStateCard<CardComponent>
): ReactNode => {
  const { cardID } = data;

  if ('ComponentClass' in data) {
    const { ComponentClass } = data;

    return <ComponentClass key={cardID} />;
  }

  return null;
};

export const generateStatementCard = (
  data: QuestionStateCard<CardStatement>,
  matter: Matter
): ReactNode => {
  const { section, tab } = useContext(DashboardContext);

  const { isAllChecked } = getBasicsCardsState(matter);

  const isActive = isPageContentsUnlocked(matter, section, tab) && isAllChecked;

  const { cardID, label, navToQuestions = () => {}, status } = data;

  const cardHeadingText = generateCardHeading(data, matter);

  switch (status) {
    case STATUS_NEW_BOTH_PARTIES:
      return (
        <Card key={cardID} data={data} active={isActive} cardTheme="self">
          <Card.HeadingWithSideText heading={label} text="Optional" />
          <div style={{ flexGrow: 1 }}>
            <Card.Statement noBox spaced={{ marginBottom: 'small' }} />
          </div>
          <Card.Button onClick={navToQuestions}>View</Card.Button>
        </Card>
      );
    default:
      return (
        <NormalCard
          key={cardID}
          data={data}
          matter={matter}
          cardHeadingText={cardHeadingText}
          isActive={isActive}
        />
      );
  }
};

export const generateAgreementCard = (
  data: QuestionStateCard<CardAgreement>,
  matter: Matter
): ReactNode => {
  const { cardID, baseID, label } = data;

  const { section, tab } = useContext(DashboardContext);

  const { checked, isAllChecked, handleChecked } = getBasicsCardsState(matter);

  const isActive =
    isPageContentsUnlocked(matter, section, tab) && !isAllChecked;

  let popupContent: ReactNode = null;

  if (baseID === 'majorDecisions') {
    popupContent = MajorDecisions;
  } else if (baseID === 'disputeResolution') {
    popupContent = DisputeResolution;
  }

  return (
    <Card cardTheme="agreement" data={data} key={cardID} active={isActive}>
      <Card.Heading spaced={{ marginBottom: 'medium' }}>{label}</Card.Heading>
      <Card.Statement spaced={{ marginBottom: 'small' }} />
      <Card.LearnMoreButton
        spaced={{ marginBottom: 'xxsmall' }}
        popUpContent={popupContent}
      >
        I need more information
      </Card.LearnMoreButton>
      <Card.CheckBox
        value={checked[cardID]}
        onChange={isActive ? handleChecked : () => {}}
        name={cardID}
        label={<StyledCheckboxLabel>I agree</StyledCheckboxLabel>}
        disabled={!isActive}
      />
    </Card>
  );
};
