import React from 'react';
import styled from 'styled-components';

import { CustomCardComponentProps } from 'Common/Data/Types/types';
import { Column } from 'Common/UI/Layout/Flex';
import ClauseParagraphViewOnly from './Components/ClauseParagraphViewOnly';

const Wrapper = styled(Column)`
  position: relative;
  align-items: stretch;
  height: 100%;
`;

const SuperannuationFundClauseViewer: React.FC<CustomCardComponentProps> = ({
  values,
}) => {
  let clauses: { text: string }[] = [];

  if (values.clauses) {
    clauses = values.clauses;
  }

  return (
    <>
      {clauses.map(({ text }, index) => (
        <ClauseParagraphViewOnly key={text} title={`Paragraph ${index + 1}`}>
          {text}
        </ClauseParagraphViewOnly>
      ))}
    </>
  );
};

export default SuperannuationFundClauseViewer;
