import React from 'react';
import { APP_SECTION_ID_CONSENT_ORDER } from 'Common/constants';
import CardQuestionsFlow from 'App/UI/Questions/CardQuestionsFlow';
import MatterGuard from '../../Guard/MatterGuard';

const ODRSYourSuperannuationContinueVersionAware = (props: any) => (
  <MatterGuard>
    <CardQuestionsFlow
      appSectionID={APP_SECTION_ID_CONSENT_ORDER}
      extendID="yoursuperannuation"
      baseID="yoursuperannuationcontinue"
      indexFromParams
      {...props}
    />
  </MatterGuard>
);

export default ODRSYourSuperannuationContinueVersionAware;
