import React from 'react';

import IconDownload from 'Common/Assets/images/icon-download.svg';
import IconTextButton from './IconTextButton';

type Props = {
  disabled?: boolean;
  subtle?: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

// Open Icon Button
const DownloadButton: React.FC<Props> = ({
  children,
  disabled = false,
  subtle = false,
  ...props
}) => (
  <IconTextButton
    disabled={disabled}
    fullWidth
    subtle={subtle}
    icon={IconDownload}
    iconOnLeft
    small
    {...props}
  >
    {children}
  </IconTextButton>
);

export default DownloadButton;
