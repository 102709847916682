import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MatterProps from 'Common/Utils/MatterProps';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

import Dialog from 'Common/UI/Layout/Dialog';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import { blackTheme } from 'Common/Utils/theme';
import { TwoButtonResponsiveGroup } from 'Common/UI/Button/ButtonGroup';
import { MediumButton } from 'Common/UI/Button/GenericButton';

const StyledHeading2 = styled(Heading2)`
  margin-bottom: ${props => props.theme.padding.medium}px;
`;

const DialogAddMessage = ({ showDialog, onCancel, onNext, onNextDisabled }) => (
  <Dialog showDialog={showDialog} theme={blackTheme}>
    <FlexGrid medium={2}>
      <div>
        <StyledHeading2>Go to the next step to add a message</StyledHeading2>
        <Paragraph>
          Add a message to let {MatterProps('other.firstname')} know why you
          think this is more fair.
        </Paragraph>
      </div>
      <OffsetColumn>
        <TwoButtonResponsiveGroup>
          <MediumButton
            subtle
            onClick={onCancel}
            data-cy="button-cancel-add-message"
          >
            Cancel
          </MediumButton>
          <MediumButton
            onClick={onNext}
            data-cy="button-add-message"
            disabled={onNextDisabled}
          >
            Next
          </MediumButton>
        </TwoButtonResponsiveGroup>
      </OffsetColumn>
    </FlexGrid>
  </Dialog>
);

DialogAddMessage.propTypes = {
  showDialog: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onNextDisabled: PropTypes.bool,
};

DialogAddMessage.defaultProps = {
  showDialog: false,
  onNextDisabled: false,
};

export default DialogAddMessage;
