import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import MatterProps from 'Common/Utils/MatterProps';
import AssetProps from 'Common/Data/App/assetProperties';
import DiviProps from 'Common/Data/App/diviProperties';
import { ODRS_DIVISION_ASSETS_TRANSFER_SELF } from 'Common/routes';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Heading2 } from 'Common/UI/Text/Headings';
import GenericButton from 'Common/UI/Button/GenericButton';
import PageContainer from 'Common/UI/Layout/PageContainer';
import FullScreenModal from 'Common/UI/Layout/FullScreenModal';
import useOdie from 'Common/Utils/Hooks/useOdie';
import {
  TransferFooter,
  AssetSplitPageContent,
  DialogViewProposal,
} from './common';
import AssetsTable from '../Components/AssetsTable';
import Header from './Header';
import BubbleNotification from '../../BubbleNotification';
import Amounts from '../Indicator/Amounts';
import TransferIntro from '../Components/TransferIntro';

import DivisionCalculationHelp from '../Components/DivisionCalculationHelp';
import { DivisionAssetsProvider } from './DivisionAssetsContext';

const AmountsWrapper = styled.div`
  background: ${props => props.theme.colours.offWhite};
  padding: ${props => props.theme.padding.small}px;
  border: 1px solid ${props => props.theme.colours.lightGrey};
  border-radius: 6px;
  margin: ${props => props.theme.padding.small}px 0
    ${props => props.theme.padding.medium}px;
`;

const Heading2WithTopMargin = styled(Heading2)`
  margin-top: ${props => props.theme.padding.xlarge}px;
`;

const modalPortal = document.getElementById('modal');

const DivisionAssetsInfo = () => {
  const { handleOpenOdie: onLearnMore } = useOdie({
    message: <DivisionCalculationHelp />,
  });

  const {
    selfAssetSplit,
    isOwingParty,
    canStartOrWorkOnOffer,
    absoluteRemainingAmountToTransfer,
    remainingAmountToTransfer,
    isAssetSplitCompleted,
    shouldShowViewProposalDialog,
  } = AssetProps();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  const { agreedSplit } = DiviProps();

  const portal = createPortal(
    <FullScreenModal
      show={isModalVisible}
      onClose={hideModal}
      title="Assets table"
      closeButtonType="icon"
    >
      <AssetsTable />
    </FullScreenModal>,
    modalPortal
  );

  return (
    <DivisionAssetsProvider>
      <PageContainer>
        <Header tab="info" />
        <TransferIntro showSplitInfo hideOptionalRequired />

        <AssetSplitPageContent bottomPadding={shouldShowViewProposalDialog}>
          <ResponsiveTextContainer>
            <Heading2>Asset pool</Heading2>

            <Paragraph large>
              Your assets of the relationship are currently divided as follows.
            </Paragraph>

            <AmountsWrapper data-cy="split-asset-pool">
              <Amounts
                percentage={selfAssetSplit * 100}
                selfLabel="Your asset pool"
                otherLabel={`${MatterProps('other.firstname')}'s asset pool`}
              />
            </AmountsWrapper>

            <GenericButton subtle small onClick={showModal}>
              View total asset pool
            </GenericButton>

            <Heading2WithTopMargin>Agreed division</Heading2WithTopMargin>

            <Paragraph large>
              {'You and '}
              {MatterProps('other.firstname')} have agreed to the following
              split
            </Paragraph>

            <AmountsWrapper data-cy="split-agreed">
              <Amounts
                percentage={agreedSplit * 100}
                selfLabel="You receive"
                otherLabel={`${MatterProps('other.firstname')} receives`}
              />
            </AmountsWrapper>

            <BubbleNotification onClick={onLearnMore} buttonLabel="Learn more">
              <Paragraph>
                This suggested division is based on how legal principles are
                applied in your circumstances.
              </Paragraph>
            </BubbleNotification>
          </ResponsiveTextContainer>
        </AssetSplitPageContent>
        {canStartOrWorkOnOffer && (
          <TransferFooter
            nextRoute={ODRS_DIVISION_ASSETS_TRANSFER_SELF}
            buttonLabel="Next: Your Assets"
            buttonDisabled={false}
            remainingAmountToTransfer={remainingAmountToTransfer}
            absoluteRemainingAmountToTransfer={
              absoluteRemainingAmountToTransfer
            }
            isAssetSplitCompleted={isAssetSplitCompleted}
            isOwingParty={isOwingParty}
          />
        )}
        {portal}
      </PageContainer>
      <DialogViewProposal showDialog={shouldShowViewProposalDialog} />
    </DivisionAssetsProvider>
  );
};

export default DivisionAssetsInfo;
