import {
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_NEW_BOTH_PARTIES,
  DATA_SECTION,
  DATA_GROUP,
  CARD_NORMAL,
  CARD_COMPONENT,
  SECTION_PROPERTY,
  DATA_PAGE,
  PAGE_PROPERTY_MONEY_AND_PROPERTY,
  PAGE_PROPERTY_CONTRIBUTIONS,
  PAGE_PROPERTY_ASSET_DIVISION,
  PAGE_PROPERTY_COMPLETE,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import {
  ODRS_VEHICLES_ROUTE,
  ODRS_PROPERTIES_ROUTE,
  ODRS_YOURFINANCES_ROUTE,
  ODRS_YOURSUPERANNUATION_ROUTE,
  ODRS_SHAREDFINANCES_ROUTE,
  ODRS_CONTRIBUTIONS_ROUTE,
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
  ODRS_PROPERTY_CONTRIBUTIONS_ROUTE,
  ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
  ODRS_PROPERTY_COMPLETE_ROUTE,
  ODRS_BASE_ROUTE,
} from 'Common/routes';
import {
  areRelationshipAssetsAllAccountedFor,
  areSettlementAssetsAllAccountedFor,
} from 'App/Utils/matterHelpers';
import { getNextAvailableIndex } from 'Common/Utils/getNextAvailableIndex';
import ConditionalSuperWarning from 'Common/UI/Card/indicators/ConditionalSuperWarning';
import { getNavItemStatus } from 'Common/UI/Navigation';
import IconProperty from 'Common/Assets/images/icon-property.svg';
import IconVehicles from 'Common/Assets/images/icon-vehicles.svg';
import IconFinances from 'Common/Assets/images/icon-finances.svg';
import IconSuperannuation from 'Common/Assets/images/icon-superannuation.svg';
import IconContributions from 'Common/Assets/images/icon-contributions.svg';
import BackgroundQuestionFlowProperty from 'Common/Assets/images/question-flow-bg-property.png';
import AssetDivisionCards from 'App/UI/Dashboard/Property/AssetDivisionCards';
import AssetProps from 'Common/Data/App/assetProperties';
import DiviProps from 'Common/Data/App/diviProperties';
import PropertyDocumentCards from 'App/UI/DocumentCard/Property/PropertyDocumentCards';
import colours from 'Common/Utils/colours';
import { getPageStatus } from 'App/Utils/sectionHelpers';
import {
  addEmptyItemAction,
  setPartyFlagsAction,
} from 'App/State/MatterActions';
import { history, reduxStore } from 'App/State/Store';
import {
  vehiclesQuestions,
  vehiclesStatement,
  propertiesQuestions,
  propertiesStatement,
  yourFinancesQuestions,
  yourFinancesStatement,
  yourSuperannuationQuestions,
  yourSuperannuationStatement,
  contributionsQuestions,
  contributionsStatement,
  sharedFinancesQuestions,
  sharedFinancesStatement,
} from '../../Questions/Settlement';
import { buildCard } from '../../../buildCard';
import {
  CardComponent,
  CardIndex,
  CardNormal,
  DataSection,
} from '../../../../Types/appSections';
import { Item } from '../../../../Types/matter';

const generateProperty = ({
  existingProperties = [],
  existingVehicles = [],
}: {
  existingProperties?: Item[];
  existingVehicles?: Item[];
}): DataSection => ({
  type: DATA_SECTION,
  id: SECTION_PROPERTY,
  title: 'Dividing your money and property',
  route: ODRS_DASH_PROPERTY_ROUTE,
  questionFlowBackgroundImage: BackgroundQuestionFlowProperty,
  questionFlowBackgroundColour: colours.offWhite,
  children: [
    {
      type: DATA_PAGE,
      id: PAGE_PROPERTY_MONEY_AND_PROPERTY,
      title: '1. Money and Property',
      route: ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE,
      subTitle: '1. Money and Property',
      description:
        'You will need to reach agreement on statements about your assets and liabilities.',
      generateStatus: () => {
        const { completed } = getPageStatus({
          section: SECTION_PROPERTY,
          page: PAGE_PROPERTY_MONEY_AND_PROPERTY,
        });

        return getNavItemStatus({
          isCompleted: completed,
          isLocked: !areRelationshipAssetsAllAccountedFor(),
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'properties',
          title: 'Property',
          description:
            'You will need to add any properties that you solely or jointly own.',
          icon: IconProperty,
          isRepeatable: true,
          singular: 'property',
          plural: 'properties',
          promptCardData: {
            // hides the `Do you have any properties?` card if users have not entered any properties and they have started suggested division, which locks this section
            visibilityCheck: () => {
              const { created } = DiviProps();

              return !created;
            },
            hasNoneState: true,
            initial: {
              title: 'Do you own any property?',
              content:
                'You will need to add any property that you and your former partner jointly and/or solely own.',
              yesButton: 'Yes, we do',
              noButton: "We don't own property",
              onClickYes: async () => {
                const { dispatch } = reduxStore;

                await dispatch(addEmptyItemAction('properties'));

                history.push(`${ODRS_BASE_ROUTE}/properties/1`, {
                  previous: reduxStore.getState().router.location.pathname,
                });
              },
              onClickNo: async () => {
                const { dispatch } = reduxStore;

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsProperties: true })
                );
              },
            },
            none: {
              title: 'You do not have any property',
              content:
                'You have selected that you and your former partner do not have any property.',
              onClickEdit: async () => {
                const { dispatch } = reduxStore;

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsProperties: false })
                );
              },
            },
          },
          defaultRoute: `${ODRS_PROPERTIES_ROUTE}`,
          children: (() => {
            /** See notes above about children card generation */
            const takenIndexes: number[] = [];

            const items = MatterPropsJS('items');
            const setup =
              items.find((item: Item) => item.SectionID === 'setup') || {};

            return Array.from(
              Array(setup.numProperties || 0),
              (_el, arrayIndex) => {
                const item = existingProperties[arrayIndex] || {};
                let index: CardIndex;
                let displayIndex;
                let cardID;

                if (item.SectionID) {
                  index = item.CardIndex;
                  displayIndex = index;
                  cardID = item.SectionID;
                } else {
                  index = getNextAvailableIndex(takenIndexes);
                  displayIndex = arrayIndex + 1;
                  cardID = `properties${index}`;
                }

                takenIndexes.push(Number(index));

                return buildCard<CardNormal>({
                  type: CARD_NORMAL,
                  baseID: 'properties',
                  cardID,
                  title: item.propertyAddress || `Property ${displayIndex}`,
                  sensitiveTitle: `Property ${displayIndex}`,
                  questions: propertiesQuestions,
                  statement: propertiesStatement,
                  index,
                  isRepeatable: true,
                  questionsRoute: `${ODRS_PROPERTIES_ROUTE}/${index}`,
                  updateRoute: `${ODRS_PROPERTIES_ROUTE}/${index}/update`,
                  status: STATUS_NEW_BOTH_PARTIES,
                });
              }
            );
          })(),
        },
        {
          type: DATA_GROUP,
          id: 'vehicles',
          title: 'Vehicles',
          description:
            'You will need to add any vehicles that you solely or jointly own.',
          icon: IconVehicles,
          isRepeatable: true,
          promptCardData: {
            // hides the `Do you have any vehicles?` card if users have not entered any properties and they have started suggested division, which locks this section
            visibilityCheck: () => {
              const { created } = DiviProps();

              return !created;
            },
            hasNoneState: true,
            initial: {
              title: 'Do you own any vehicles?',
              content:
                'You will need to add any vehicles that you and your former partner jointly and/or solely own.',
              yesButton: 'Yes, we do',
              noButton: "We don't own a vehicle",
              onClickYes: async () => {
                const { dispatch } = reduxStore;

                await dispatch(addEmptyItemAction('vehicles'));

                history.push(`${ODRS_BASE_ROUTE}/vehicles/1`, {
                  previous: reduxStore.getState().router.location.pathname,
                });
              },
              onClickNo: async () => {
                const { dispatch } = reduxStore;

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsVehicles: true })
                );
              },
            },
            none: {
              title: 'You do not have any vehicles',
              content:
                'You have selected that you and your former partner do not have any vehicles.',
              onClickEdit: async () => {
                const { dispatch } = reduxStore;

                await dispatch(
                  setPartyFlagsAction({ hasNoItemsVehicles: false })
                );
              },
            },
          },
          singular: 'vehicle',
          plural: 'vehicles',
          defaultRoute: `${ODRS_VEHICLES_ROUTE}`,

          children: (() => {
            /** See notes above about children card generation */
            const takenIndexes: number[] = [];

            const items = MatterPropsJS('items');
            const setup =
              items.find((item: Item) => item.SectionID === 'setup') || {};

            return Array.from(
              Array(setup.numVehicles || 0),
              (_el, arrayIndex) => {
                const item = existingVehicles[arrayIndex] || {};
                let index: CardIndex;
                let displayIndex;
                let cardID;

                if (item.SectionID) {
                  index = item.CardIndex;
                  displayIndex = index;
                  cardID = item.SectionID;
                } else {
                  index = getNextAvailableIndex(takenIndexes);
                  displayIndex = arrayIndex + 1;
                  cardID = `vehicles${index}`;
                }

                takenIndexes.push(Number(index));
                return buildCard<CardNormal>({
                  type: CARD_NORMAL,
                  baseID: 'vehicles',
                  cardID,
                  title: item.vehicleMakeModel || `Vehicle ${displayIndex}`,
                  sensitiveTitle: `Vehicle ${displayIndex}`,
                  questions: vehiclesQuestions,
                  statement: vehiclesStatement,
                  index,
                  isRepeatable: true,
                  questionsRoute: `${ODRS_VEHICLES_ROUTE}/${index}`,
                  updateRoute: `${ODRS_VEHICLES_ROUTE}/${index}/update`,
                  status: STATUS_NEW_BOTH_PARTIES,
                });
              }
            );
          })(),
        },
        {
          type: DATA_GROUP,
          id: 'finances',
          title: 'Finances',
          description:
            'We are going to ask a few questions about your finances and personal belongings.',
          icon: IconFinances,
          children: [
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'yourfinances',
              cardID: `yourfinances${MatterPropsJS('self.party', 'A')}`,
              title: 'Your Finances',
              sensitiveTitle: `Party ${MatterPropsJS(
                'self.party',
                'A'
              )}'s Finances`,
              questions: yourFinancesQuestions,
              statement: yourFinancesStatement,
              index: MatterPropsJS('self.party', 'A'),
              questionsRoute: ODRS_YOURFINANCES_ROUTE,
              updateRoute: `${ODRS_YOURFINANCES_ROUTE}/update`,
              status: STATUS_NEW_ONLY_SELF,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'yourfinances',
              cardID: `yourfinances${MatterPropsJS('other.party', 'B')}`,
              title: `${MatterPropsJS('other.firstname', 'Them')}'s Finances`,
              sensitiveTitle: `Party ${MatterPropsJS(
                'other.party',
                'B'
              )}'s Finances`,
              questions: yourFinancesQuestions,
              statement: yourFinancesStatement,
              index: MatterPropsJS('other.party', 'B'),
              questionsRoute: ODRS_YOURFINANCES_ROUTE,
              status: STATUS_NEW_ONLY_THEM,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'sharedfinances',
              cardID: 'sharedfinances',
              title: 'Shared Finances',
              questions: sharedFinancesQuestions,
              statement: sharedFinancesStatement,
              questionsRoute: ODRS_SHAREDFINANCES_ROUTE,
              updateRoute: `${ODRS_SHAREDFINANCES_ROUTE}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
            }),
          ],
        },
        {
          type: DATA_GROUP,
          id: 'superannuation',
          title: 'Superannuation',
          description:
            'We are going to ask you a few questions to help you value your superannuation. Before you get started, make sure you have the amount of your superannuation balance.',
          icon: IconSuperannuation,
          children: [
            buildCard<CardComponent>({
              type: CARD_COMPONENT,
              cardID: 'superwarningYOU',
              ComponentClass: ConditionalSuperWarning,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'yoursuperannuation',
              cardID: `yoursuperannuation${MatterPropsJS('self.party', 'A')}`,
              title: 'Your Superannuation',
              sensitiveTitle: `Party ${MatterPropsJS(
                'self.party',
                'A'
              )}'s Superannuation`,
              questions: yourSuperannuationQuestions,
              statement: yourSuperannuationStatement,
              index: MatterPropsJS('self.party', 'A'),
              questionsRoute: ODRS_YOURSUPERANNUATION_ROUTE,
              updateRoute: `${ODRS_YOURSUPERANNUATION_ROUTE}/update`,
              status: STATUS_NEW_ONLY_SELF,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'yoursuperannuation',
              cardID: `yoursuperannuation${MatterPropsJS('other.party', 'B')}`,
              title: `${MatterPropsJS(
                'other.firstname',
                'Them'
              )}'s Superannuation`,
              sensitiveTitle: `Party ${MatterPropsJS(
                'other.party',
                'B'
              )}'s Superannuation`,
              questions: yourSuperannuationQuestions,
              statement: yourSuperannuationStatement,
              index: MatterPropsJS('other.party', 'B'),
              questionsRoute: ODRS_YOURSUPERANNUATION_ROUTE,
              status: STATUS_NEW_ONLY_THEM,
            }),
          ],
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PROPERTY_CONTRIBUTIONS,
      title: '2. Contributions',
      route: ODRS_PROPERTY_CONTRIBUTIONS_ROUTE,
      subTitle: '2. Contributions',
      description:
        'We are going to ask some questions about what contributions were made by each of you throughout the relationship.',
      generateStatus: () => {
        const { completed } = getPageStatus({
          section: SECTION_PROPERTY,
          page: PAGE_PROPERTY_CONTRIBUTIONS,
        });

        return getNavItemStatus({
          isCompleted: completed,
          isLocked: !areRelationshipAssetsAllAccountedFor(),
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'contributions2',
          title: 'Your contributions',
          icon: IconContributions,
          children: [
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'contributions',
              cardID: `contributions${MatterPropsJS('self.party', 'A')}`,
              title: 'Your Contributions',
              sensitiveTitle: `Party ${MatterPropsJS(
                'self.party',
                'A'
              )}'s Contributions`,
              questions: contributionsQuestions,
              statement: contributionsStatement,
              index: MatterPropsJS('self.party', 'A'),
              questionsRoute: ODRS_CONTRIBUTIONS_ROUTE,
              updateRoute: `${ODRS_CONTRIBUTIONS_ROUTE}/update`,
              status: STATUS_NEW_ONLY_SELF,
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'contributions',
              cardID: `contributions${MatterPropsJS('other.party', 'B')}`,
              title: `${MatterPropsJS(
                'other.firstname',
                'Them'
              )}'s Contributions`,
              sensitiveTitle: `Party ${MatterPropsJS(
                'other.party',
                'B'
              )}'s Contributions`,
              questions: contributionsQuestions,
              statement: contributionsStatement,
              index: MatterPropsJS('other.party', 'B'),
              questionsRoute: ODRS_CONTRIBUTIONS_ROUTE,
              status: STATUS_NEW_ONLY_THEM,
            }),
          ],
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PROPERTY_ASSET_DIVISION,
      title: '3. Asset division',
      route: ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
      subTitle: '3. Asset division',
      description:
        'You will have to agree on all your statements before you can get started on this section. amica will suggest a percentage division and each of you will have the chance to agree or make your own offer.',
      lockedTooltip:
        'You will have to agree on all your assets statements before you can get started on this section.',
      getPageButtonProps: () => {
        const { hasConfirmedStatement } = DiviProps();
        const { dispatch } = reduxStore;
        const { self, other } = reduxStore.getState().matter;

        const hasEitherPartyStartedSuggestedDivision =
          hasConfirmedStatement.self || hasConfirmedStatement.other;
        const hasEitherPartyStartedConsentOrders =
          self.canStartConsentOrders || other.canStartConsentOrders;
        const hasPropertyAgreementBeenGenerated = !!(
          self.propertyAgreementDate || other.propertyAgreementDate
        );

        if (
          hasEitherPartyStartedSuggestedDivision &&
          !hasEitherPartyStartedConsentOrders &&
          !hasPropertyAgreementBeenGenerated
        ) {
          return {
            showButton: true,
            onClick: async () =>
              dispatch(
                setPartyFlagsAction({ readyToResetSuggestedDivision: true })
              ),
            buttonText: 'Restart section',
          };
        }

        return {
          showButton: false,
          onClick: () => Promise.resolve(),
          buttonText: '',
        };
      },
      generateStatus: () => {
        const { agreedToDivision } = DiviProps();

        const { isAssetSplitCompleted } = AssetProps();

        return getNavItemStatus({
          isCompleted: agreedToDivision && isAssetSplitCompleted,
          isLocked: !areSettlementAssetsAllAccountedFor(),
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'assetDivision',
          children: [
            buildCard<CardComponent>({
              type: CARD_COMPONENT,
              cardID: 'assetDivisionCards',
              ComponentClass: AssetDivisionCards,
            }),
          ],
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PROPERTY_COMPLETE,
      title: '4. Document selection',
      route: ODRS_PROPERTY_COMPLETE_ROUTE,
      subTitle: '4. Document selection',
      description:
        'Once you complete Dividing your Money and Property, you will have several options about how you will keep a record of what you have agreed and the way you’d like to put your agreement into effect.',
      lockedTooltip:
        'You will have to agree on all your assets statements before you can get started on this section.',
      generateStatus: () => {
        const { agreedToDivision } = DiviProps();

        const { isAssetSplitCompleted } = AssetProps();

        return getNavItemStatus({
          isCompleted:
            areSettlementAssetsAllAccountedFor() &&
            agreedToDivision &&
            isAssetSplitCompleted,
          isLocked:
            !areSettlementAssetsAllAccountedFor() ||
            !agreedToDivision ||
            !isAssetSplitCompleted,
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'complete',
          children: [
            buildCard<CardComponent>({
              type: CARD_COMPONENT,
              cardID: 'propertyDocumentCards',
              ComponentClass: PropertyDocumentCards,
            }),
          ],
        },
      ],
    },
  ],
});

export default generateProperty;
