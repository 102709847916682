import React from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';

import {
  ODRS_START_ROUTE,
  ODRS_DASH_HOME_ROUTE,
  ODRS_CONSENT_ORDERS_ROUTE,
  ODRS_LOGIN_ROUTE,
  ODRS_PASSWORDRESET_ROUTE,
  ODRS_INVITE_ROUTE,
  ODRS_CHILDREN_ROUTE,
  ODRS_CHILDREN_CONTINUE_ROUTE,
  ODRS_ABOUTYOU_ROUTE,
  ODRS_ABOUTYOU_CONTINUE_ROUTE,
  ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE,
  ODRS_CONSENT_ORDERS_JURISDICTION_ROUTE,
  ODRS_RELATIONSHIP_ROUTE,
  ODRS_RELATIONSHIP_CONTINUE_ROUTE,
  ODRS_VEHICLES_ROUTE,
  ODRS_PROPERTIES_ROUTE,
  ODRS_DIVISION_ROUTE,
  ODRS_PROFILE_ROUTE,
  ODRS_YOURSUPERANNUATION_ROUTE,
  ODRS_YOURFINANCES_ROUTE,
  ODRS_SHAREDFINANCES_ROUTE,
  ODRS_CONTRIBUTIONS_ROUTE,
  ODRS_PROFILE_UPDATE_ROUTE,
  ODRS_TERMS_ROUTE,
  ODRS_PRIVACY_ROUTE,
  ODRS_STATEMENT_DETAILS,
  ODRS_PARENTING_PLAN_SCHEDULE,
  ODRS_PARENTING_PLAN_HANDOVERS,
  ODRS_PARENTING_PLAN_BIRTHDAYS,
  ODRS_PARENTING_PLAN_CHRISTMAS,
  ODRS_PARENTING_PLAN_EASTER,
  ODRS_PARENTING_PLAN_MOTHERS_FATHERS_DAY,
  ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_SHORT,
  ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_LONG,
  ODRS_PARENTING_PLAN_EXTRACURRICULAR,
  ODRS_PARENTING_PLAN_PARENT_TEACHER_INTERVIEWS,
  ODRS_PARENTING_PLAN_DOCTORS_APPOINTMENTS,
  ODRS_PARENTING_PLAN_MEDICATION,
  ODRS_PARENTING_PLAN_TRAVELLING,
  ODRS_PARENTING_PLAN_EXPENSES,
  ODRS_PARENTING_PLAN_COOPERATING,
  ODRS_PARENTING_PLAN_COMMUNICATION,
  ODRS_PARENTING_PLAN_RELIGION_AND_CULTURE,
  ODRS_PARENTING_PLAN_COMMUNICATION_METHOD,
  ODRS_PARENTING_PLAN_CONTACT_DETAILS,
  ODRS_DASH_RELATIONSHIP_ROUTE,
  ODRS_RETURN_SPECIFIC_ITEMS_ROUTE,
  ODRS_YOURSUPERANNUATION_CONTINUE_ROUTE,
  ODRS_ASSET_TABLE,
  ODRS_PROPERTIES_CONTINUE_ROUTE,
  ODRS_YOUR_CONTRIBUTIONS_ROUTE,
  ODRS_YOURINCOME_ROUTE,
  ODRS_BANKACCOUNT_ROUTE,
  ODRS_ACQUIRED_SOLD_ITEMS_ROUTE,
  ODRS_PAYMENT_ROUTE,
  ODRS_DASH_PARENTING_ROUTE,
  ODRS_DASH_PROPERTY_ROUTE,
  ODRS_NOTIFICATIONS_ROUTE,
  ODRS_EDIT_STATEMENT_ROUTE,
  ODRS_NOSUPERANNUATION_ROUTE,
  ODRS_NOCHILDREN_ROUTE,
  ODRS_DOCUMENTS_ROUTE,
  ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE,
} from 'Common/routes';
import Odie from 'Common/UI/Odie/Odie';
import theme, {
  darkerTheme,
  darkerNoBackground,
  blueGreyMobileTheme,
} from 'Common/Utils/theme';
import DiviRoute from 'Common/UI/DiviRoute';
import PasswordReset from 'App/UI/PasswordReset';
import ODRSStart from './ODRSStart';
import ODRSDashConsentOrders from './ODRSDashboardConsentOrders';
import ODRSPartnerInvite from './ODRSPartnerInvite';
import ODRSDivision from './ODRSDivision';

import ODRSChildren from './ODRSChildren';
import ODRSChildrenContinue from './ODRSChildrenContinue';
import ODRSLogin from './ODRSLogin';
import ODRSAboutYou from './ODRSAboutYou';
import ODRSAboutYouContinue from './ODRSAboutYouContinue';
import ODRSConsentOrdersRequirement from './ODRSConsentOrdersRequirement';
import ODRSConsentOrdersJurisdiction from './ODRSConsentOrdersJurisdiction';
import ODRSProperties from './ODRSProperties';
import ODRSVehicles from './ODRSVehicles';
import ODRSProfile from './ODRSProfile';
import ODRSYourFinances from './ODRSYourFinances';
import ODRSYourSuperannuation from './ODRSYourSuperannuation';
import ODRSSharedFinances from './ODRSSharedFinances';
import ODRSContributions from './ODRSContributions';
import ODRSProfileUpdate from './ODRSProfileUpdate';
import ODRSRelationship from './ODRSRelationship';
import ODRSRelationshipContinue from './ODRSRelationshipContinue';
import ODRSStatementDetails from './ODRSStatementDetails';
import ODRSYourSuperannuationContinue from './ODRSYourSuperannuationContinue';
import ODRSYourContributions from './ODRSYourContributions';
import ODRSYourIncome from './ODRSYourIncome';
import ODRSBankAccount from './ODRSBankAccount';
import ODRSAcquiredSoldItems from './ODRSAcquiredSoldItems';

// Parenting Plan
import ODRSParentingPlanStatement from './ODRSParentingPlanStatement';
import ODRSParentingPlanSchedule from './ODRSParentingPlanSchedule';
import ODRSParentingPlanHandover from './ODRSParentingPlanHandover';

import ODRSTerms from './ODRSTerms';
import ODRSPrivacy from './ODRSPrivacy';
import ODRSReturnSpecificItems from './ODRSReturnSpecificItems';
import ODRSAssetTable from './ODRSAssetsTable';
import ODRSPropertiesContinue from './ODRSPropertiesContinue';
import ODRSPayment from './ODRSPayment';
import ODRSDashboardHome from './ODRSDashboardHome';
import ODRSDashboardRelationship from './ODRSDashboardRelationship';
import ODRSDashboardParenting from './ODRSDashboardParenting';
import ODRSDashboardProperty from './ODRSDashboardProperty';
import ODRSNotifications from './ODRSNotifications';
import ODRSEditStatement from './ODRSEditStatement';
import ODRSNoSuperannuation from './ODRSNoSuperannuation';
import ODRSNoChildren from './ODRSNoChildren';
import ODRSDocuments from './ODRSDocuments';
import ODRSSuperannuationFundClauses from './ODRSSuperannuationFundClauses';

export default function () {
  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <DiviRoute
          path={ODRS_START_ROUTE}
          component={ODRSStart}
          theme={darkerTheme}
        />
        <DiviRoute
          path={ODRS_LOGIN_ROUTE}
          component={ODRSLogin}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_TERMS_ROUTE}
          component={ODRSTerms}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_PRIVACY_ROUTE}
          component={ODRSPrivacy}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_PASSWORDRESET_ROUTE}
          component={PasswordReset}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_NOTIFICATIONS_ROUTE}
          component={ODRSNotifications}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_DOCUMENTS_ROUTE}
          component={ODRSDocuments}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_DASH_RELATIONSHIP_ROUTE}
          component={ODRSDashboardRelationship}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_DASH_PARENTING_ROUTE}
          component={ODRSDashboardParenting}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_DASH_PROPERTY_ROUTE}
          component={ODRSDashboardProperty}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_DASH_HOME_ROUTE}
          component={ODRSDashboardHome}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_CONSENT_ORDERS_ROUTE}
          component={ODRSDashConsentOrders}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_PAYMENT_ROUTE}
          component={ODRSPayment}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={`${ODRS_EDIT_STATEMENT_ROUTE}/:cardID`}
          component={ODRSEditStatement}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_NOCHILDREN_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSNoChildren}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_CHILDREN_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSChildren}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_CHILDREN_CONTINUE_ROUTE}/:index/:updating?`}
          component={ODRSChildrenContinue}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_CONSENT_ORDERS_REQUIREMENTS_ROUTE}/:updating?`}
          component={ODRSConsentOrdersRequirement}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_CONSENT_ORDERS_JURISDICTION_ROUTE}/:updating?`}
          component={ODRSConsentOrdersJurisdiction}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_ABOUTYOU_CONTINUE_ROUTE}/:updating?`}
          component={ODRSAboutYouContinue}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_SUPERANNUATION_FUND_CLAUSES_ROUTE}
          component={ODRSSuperannuationFundClauses}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_ABOUTYOU_ROUTE}/:updating?`}
          component={ODRSAboutYou}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_RELATIONSHIP_ROUTE}/:updating?`}
          component={ODRSRelationship}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_RELATIONSHIP_CONTINUE_ROUTE}/:updating?`}
          component={ODRSRelationshipContinue}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_VEHICLES_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSVehicles}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_PROPERTIES_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSProperties}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_STATEMENT_DETAILS}/:section/:index?`}
          component={ODRSStatementDetails}
          theme={blueGreyMobileTheme}
        />
        <DiviRoute
          path={`${ODRS_YOURSUPERANNUATION_ROUTE}`}
          component={ODRSYourSuperannuation}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_NOSUPERANNUATION_ROUTE}/:index/:updating?`}
          component={ODRSNoSuperannuation}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_YOURFINANCES_ROUTE}/:updating?`}
          component={ODRSYourFinances}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_SHAREDFINANCES_ROUTE}/:updating?`}
          component={ODRSSharedFinances}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_CONTRIBUTIONS_ROUTE}/:updating?`}
          component={ODRSContributions}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_YOURSUPERANNUATION_CONTINUE_ROUTE}/:index?/:updating?`}
          component={ODRSYourSuperannuationContinue}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_RETURN_SPECIFIC_ITEMS_ROUTE}/:updating?`}
          component={ODRSReturnSpecificItems}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_ASSET_TABLE}`}
          component={ODRSAssetTable}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_PROPERTIES_CONTINUE_ROUTE}/:index(\\d+)/:updating?`}
          component={ODRSPropertiesContinue}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PROFILE_UPDATE_ROUTE}
          component={ODRSProfileUpdate}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PROFILE_ROUTE}
          component={ODRSProfile}
          theme={darkerNoBackground}
        />
        <DiviRoute
          path={ODRS_INVITE_ROUTE}
          component={ODRSPartnerInvite}
          theme={darkerTheme}
        />
        <DiviRoute
          path={ODRS_DIVISION_ROUTE}
          component={ODRSDivision}
          theme={darkerTheme}
        />
        <DiviRoute
          path={ODRS_YOUR_CONTRIBUTIONS_ROUTE}
          component={ODRSYourContributions}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_YOURINCOME_ROUTE}
          component={ODRSYourIncome}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_BANKACCOUNT_ROUTE}/:index/:updating?`}
          component={ODRSBankAccount}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_ACQUIRED_SOLD_ITEMS_ROUTE}/:updating?`}
          component={ODRSAcquiredSoldItems}
          theme={theme}
        />

        {/* Parenting Plan */}
        <DiviRoute
          path={`${ODRS_PARENTING_PLAN_SCHEDULE}/:updating?`}
          component={ODRSParentingPlanSchedule}
          theme={theme}
        />
        <DiviRoute
          path={`${ODRS_PARENTING_PLAN_HANDOVERS}/:updating?`}
          component={ODRSParentingPlanHandover}
          theme={theme}
        />
        {/* Parenting Plan Statements */}
        <DiviRoute
          path={ODRS_PARENTING_PLAN_BIRTHDAYS}
          component={props => (
            <ODRSParentingPlanStatement baseID="birthdays" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_MOTHERS_FATHERS_DAY}
          component={props => (
            <ODRSParentingPlanStatement baseID="mothersFathersDay" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_SHORT}
          component={props => (
            <ODRSParentingPlanStatement
              baseID="schoolHolidayShort"
              {...props}
            />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_LONG}
          component={props => (
            <ODRSParentingPlanStatement baseID="schoolHolidayLong" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_CHRISTMAS}
          component={props => (
            <ODRSParentingPlanStatement baseID="christmas" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_EASTER}
          component={props => (
            <ODRSParentingPlanStatement baseID="easter" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_EXTRACURRICULAR}
          component={props => (
            <ODRSParentingPlanStatement baseID="extracurricular" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_PARENT_TEACHER_INTERVIEWS}
          component={props => (
            <ODRSParentingPlanStatement
              baseID="parentTeacherInterviews"
              {...props}
            />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_DOCTORS_APPOINTMENTS}
          component={props => (
            <ODRSParentingPlanStatement
              baseID="doctorsAppointments"
              {...props}
            />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_MEDICATION}
          component={props => (
            <ODRSParentingPlanStatement baseID="medication" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_TRAVELLING}
          component={props => (
            <ODRSParentingPlanStatement baseID="travelling" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_EXPENSES}
          component={props => (
            <ODRSParentingPlanStatement baseID="expenses" {...props} />
          )}
          theme={theme}
        />

        <DiviRoute
          path={ODRS_PARENTING_PLAN_COMMUNICATION}
          component={props => (
            <ODRSParentingPlanStatement baseID="communication" {...props} />
          )}
          theme={theme}
        />

        <DiviRoute
          path={ODRS_PARENTING_PLAN_RELIGION_AND_CULTURE}
          component={props => (
            <ODRSParentingPlanStatement
              baseID="religionAndCulture"
              {...props}
            />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_COMMUNICATION_METHOD}
          component={props => (
            <ODRSParentingPlanStatement
              baseID="communicationMethod"
              {...props}
            />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_COOPERATING}
          component={props => (
            <ODRSParentingPlanStatement baseID="cooperating" {...props} />
          )}
          theme={theme}
        />
        <DiviRoute
          path={ODRS_PARENTING_PLAN_CONTACT_DETAILS}
          component={props => (
            <ODRSParentingPlanStatement baseID="contactDetails" {...props} />
          )}
          theme={theme}
        />
        <Redirect to={ODRS_DASH_RELATIONSHIP_ROUTE} />
      </Switch>

      {/* This allows us to open the drawer with the dashboard in the background */}

      <Odie>
        {background && (
          <>
            <DiviRoute
              path={`${ODRS_STATEMENT_DETAILS}/:section/:index?`}
              component={ODRSStatementDetails}
              theme={blueGreyMobileTheme}
            />
            <DiviRoute
              path={ODRS_NOTIFICATIONS_ROUTE}
              component={ODRSNotifications}
              theme={darkerNoBackground}
            />
          </>
        )}
      </Odie>
    </>
  );
}
