import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment';

import { DATE_FORMAT_SHORT_WITH_TIME } from 'Common/constants';
import formatCurrency from 'Common/Utils/formatCurrency';
import { getOfferDiff } from 'Common/Data/App/assetHelpers';
import getNames from 'Common/Utils/getNames';
import AssetProps from 'Common/Data/App/assetProperties';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import Paragraph from 'Common/UI/Text/Paragraph';

import { Heading3 } from 'Common/UI/Text/Headings';
import { UpdatedTextCSS } from 'Common/UI/Text/UpdatedText';
import Message from 'Common/UI/Statements/Message';
import capitaliseText from 'Common/Utils/capitaliseText';

const SelectedAssetsListWrapper = styled.div``;

const RemovedListWrapper = styled.div`
  margin-top: ${props => props.theme.padding.medium}px;

  ${Heading3} {
    margin: 0;
  }
`;

const Item = styled(Paragraph).attrs(() => ({
  large: true,
}))`
  margin: 0;
  padding: ${props => props.theme.padding.small}px 20px
    ${props => props.theme.padding.small}px 0;
  position: relative;

  ${props =>
    props.type !== 'removed' &&
    css`
      border-bottom: 1px solid ${props.theme.colours.lightGrey};
    `}

  ${props =>
    props.type === 'removed' &&
    css`
      color: ${props.theme.colours.midDarkGrey};
    `}

  ${props =>
    props.type === 'added' &&
    css`
      &:after {
        content: '';
        background: ${props.theme.colours.purple};
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: ${props.theme.padding.small + 4}px;
        right: 0;
      }
    `}

  ${props =>
    props.type === 'updated' &&
    css`
      & > strong[data-isaction='true'] {
        ${UpdatedTextCSS}
      }
    `}
`;

const SelectedAssetsList = ({ className, offer, withMessage }) => {
  const {
    getTransferrableAssets,
    getTransferrableDebts,
    shortfallAmount,
    shortfallPayParty,
    isOwingParty,
    isOwedParty,
    numberOfOffers,
    offers,
    lastCompletedOffer,
    isAssetSplitCompleted,
  } = AssetProps();

  const names = getNames();
  const party = getPartyKeys();

  const offersReversed = [...offers].reverse();

  const offerAssets = [
    ...getTransferrableAssets(offer),
    ...getTransferrableDebts(offer),
  ].filter(asset => asset.isSelected);
  let lastOffer;
  let lastOfferAssets = [];

  if (numberOfOffers > 1) {
    // find the previous offer
    if (offer && offer.created) {
      const i = offersReversed.findIndex(o => o.created === offer.created);
      lastOffer = offersReversed[i + 1];
    } else {
      lastOffer = lastCompletedOffer;
    }

    if (lastOffer) {
      lastOfferAssets = [
        ...getTransferrableAssets(lastOffer),
        ...getTransferrableDebts(lastOffer),
      ].filter(asset => asset.isSelected);
    }
  }

  const assets = getOfferDiff(offerAssets, lastOfferAssets, {
    isAssetSplitCompleted,
  });

  return (
    <SelectedAssetsListWrapper
      className={className}
      data-cy="selected-assets-list"
    >
      {assets.added.map(asset => (
        <Item type="added" key={asset.id}>
          {isOwedParty &&
            asset.cards.find(card => card.option === asset.selectedOption)
              .statementOwed}
          {isOwingParty &&
            asset.cards.find(card => card.option === asset.selectedOption)
              .statement}
        </Item>
      ))}
      {assets.updated.map(asset => (
        <Item type="updated" key={asset.id}>
          {isOwedParty &&
            asset.cards.find(card => card.option === asset.selectedOption)
              .statementOwed}
          {isOwingParty &&
            asset.cards.find(card => card.option === asset.selectedOption)
              .statement}
        </Item>
      ))}
      {assets.unchanged.map(asset => (
        <Item type="unchanged" key={asset.id}>
          {isOwedParty &&
            asset.cards.find(card => card.option === asset.selectedOption)
              .statementOwed}
          {isOwingParty &&
            asset.cards.find(card => card.option === asset.selectedOption)
              .statement}
        </Item>
      ))}

      {shortfallAmount !== 0 && (
        <Item type="updated" data-cy="offer-shortfall">
          {shortfallPayParty === party.self && (
            <>
              You will <strong>transfer</strong>{' '}
              {formatCurrency(Math.abs(shortfallAmount))} to{' '}
              {names[party.other]}
            </>
          )}
          {shortfallPayParty === party.other && (
            <>
              {capitaliseText(names[party.other])} will{' '}
              <strong>transfer</strong>{' '}
              {formatCurrency(Math.abs(shortfallAmount))} to you.
            </>
          )}
        </Item>
      )}

      {withMessage && (
        <Message
          message={lastCompletedOffer.message}
          youOrThem={names[lastCompletedOffer.owner]}
          date={moment(lastCompletedOffer.sent).format(
            DATE_FORMAT_SHORT_WITH_TIME
          )}
          owner={lastCompletedOffer.owner}
          showLabel
          fullWidth
          dataCy="message"
        />
      )}
      {assets.removed.length > 0 && (
        <RemovedListWrapper>
          <Heading3>
            The following assets have been removed from the offer:
          </Heading3>

          {assets.removed.map(asset => (
            <Item type="removed" key={asset.id}>
              {isOwedParty &&
                asset.cards.find(card => card.option === asset.selectedOption)
                  .statementOwed}
              {isOwingParty &&
                asset.cards.find(card => card.option === asset.selectedOption)
                  .statement}
            </Item>
          ))}
        </RemovedListWrapper>
      )}
    </SelectedAssetsListWrapper>
  );

  // return (
  //   <SelectedAssetsListWrapper className={className}>
  //     {offerAssets
  //       .map(asset => (
  //         <Paragraph key={asset.id}>
  //           {isOwedParty && asset.cards.find(card => card.option === asset.selectedOption).statementOwed}
  //           {isOwingParty && asset.cards.find(card => card.option === asset.selectedOption).statement}
  //         </Paragraph>
  //       ))}
  //     {shortfallAmount !== 0 && shortfallPayParty === MatterProps('self.party') && (
  //     <Paragraph>
  //       You will
  //       {' '}
  //       <strong>transfer</strong>
  //       {' '}
  //       <strong>{formatCurrency(Math.abs(shortfallAmount))}</strong>
  //       {' '}
  //       to your former partner.
  //     </Paragraph>
  //     )}
  //     {shortfallAmount !== 0 && shortfallPayParty === MatterProps('other.party') && (
  //     <Paragraph>
  //       Your former partner will
  //       {' '}
  //       <strong>transfer</strong>
  //       {' '}
  //       <strong>{formatCurrency(Math.abs(shortfallAmount))}</strong>
  //       {' '}
  //       to you.
  //     </Paragraph>
  //     )}
  //   </SelectedAssetsListWrapper>
  // );
};

SelectedAssetsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  offer: PropTypes.object,
  className: PropTypes.string,
  withMessage: PropTypes.bool,
};

SelectedAssetsList.defaultProps = {
  offer: undefined,
  className: '',
  withMessage: false,
};

export default SelectedAssetsList;
