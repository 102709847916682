import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  signupPartyA,
  signupPartnerInvite,
} from 'Common/Data/App/20200101/Questions/signup';
import { isPartyBInvited } from 'App/Utils/matterHelpers';
import {
  INTRO_ROUTE,
  ODRS_DASH_HOME_ROUTE,
  ODRS_INVITE_PARTNER_SENT_ROUTE,
} from 'Common/routes';
import Loader from 'Common/UI/Loader/Loader';
import { CloseButtonQuestionsHeader } from 'Common/UI/Button/CloseButton';
import PageContent from 'Common/UI/Layout/PageContent';
import theme, { darkerTheme } from 'Common/Utils/theme';
import StepTransition from 'App/UI/Transitions/StepTransition';
import { RouteContext } from 'App/UI/Transitions/RouteContext';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';
import { invitePartnerAction } from 'App/State/UserActions';
import { QuestionFlowHeader } from 'Common/UI/Questions/QuestionFlowHeader';
import Nude from 'Common/UI/Nude';
import QuestionsFlowWrapper from '../Questions/QuestionsFlowWrapper';

const StyledPageContent = styled(PageContent)`
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  overflow: auto;
`;

const InnerContent = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  overflow-y: auto;
`;

class InvitePartner extends React.Component {
  constructor(props) {
    super(props);

    this.steps = [
      {
        step: 'partneremail',
        stepNumber: signupPartyA.length + 6,
        showProgress: true,
      },
      {
        step: 'invite',
        stepNumber: signupPartyA.length + signupPartnerInvite.length + 7,
        showProgress: true,
        theme: darkerTheme,
      },
    ];

    this.state = {
      step: false,
      inviteQuestionsInput: {},
      inviteQuestions: signupPartnerInvite,
      currentStep: 0,
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.onQuestionsSubmit = this.onQuestionsSubmit.bind(this);
  }

  componentDidMount() {
    const { navigate, matter } = this.props;
    if (isPartyBInvited(matter)) {
      navigate(ODRS_DASH_HOME_ROUTE);
    } else {
      this.setState({ step: this.steps[0] });
    }
  }

  // change the theme for the current step if needed
  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    const { setTheme } = this.context;
    if (this.steps.indexOf(prevState.step) !== this.steps.indexOf(step)) {
      setTheme(step.theme || theme);
    }
  }

  onInviteInputChange(key, value) {
    const inviteQuestionsInput = {
      ...this.state.inviteQuestionsInput,
      [key]: value,
    };

    this.setState({ inviteQuestionsInput });
  }

  onQuestionsSubmit() {
    this.goNext();
    return Promise.resolve();
  }

  goNext() {
    const { step, currentStep } = this.state;
    const cur = this.steps.indexOf(step);

    if (this.steps[cur + 1]) {
      this.setState({
        step: this.steps[cur + 1],
        currentStep: currentStep + 1,
      });
    }
  }

  goBack() {
    const { step, currentStep } = this.state;
    const { navigate } = this.props;
    const cur = this.steps.indexOf(step);

    if (this.steps[cur - 1]) {
      this.setState({
        step: this.steps[cur - 1],
        currentStep: currentStep - 1,
      });
    } else {
      this.setState(
        {
          step: false,
        },
        () => {
          navigate(INTRO_ROUTE);
        }
      );
    }
  }

  render() {
    const { step, inviteQuestions, inviteQuestionsInput } = this.state;

    const { invitePartner, navigate } = this.props;

    let stepComponent;

    switch (step.step) {
      case 'partneremail':
        stepComponent = (
          <InnerContent className="step-transition-fade-up">
            <QuestionsFlowWrapper
              questions={inviteQuestions}
              onInputChange={(key, value) =>
                this.onInviteInputChange(key, value)
              }
              values={inviteQuestionsInput}
              onSubmit={this.onQuestionsSubmit}
              startingStepNumber={step.stepNumber}
              hideBack
            />
          </InnerContent>
        );
        break;
      case 'invite':
        stepComponent = (
          <Nude
            key={step}
            componentDidMount={() => {
              invitePartner(inviteQuestionsInput).then(() => {
                navigate(ODRS_INVITE_PARTNER_SENT_ROUTE, {
                  partnerName: inviteQuestionsInput.partnerName,
                });
              });
            }}
          >
            <Loader />
          </Nude>
        );
        break;
      default:
        stepComponent = <p key="default">default</p>;
    }

    return (
      <ProgressBarProvider>
        <QuestionFlowHeader>
          <CloseButtonQuestionsHeader as={Link} to={ODRS_DASH_HOME_ROUTE}>
            &nbsp;&nbsp;Close
          </CloseButtonQuestionsHeader>
        </QuestionFlowHeader>
        <StyledPageContent>
          <StepTransition
            pageKey={step.step}
            transitionTime={500}
            appear
            noLeftPadding
          >
            {step.step ? stepComponent : null}
          </StepTransition>
        </StyledPageContent>
      </ProgressBarProvider>
    );
  }
}

InvitePartner.propTypes = {
  navigate: PropTypes.func.isRequired,
  invitePartner: PropTypes.func.isRequired,
  matter: PropTypes.object, // eslint-disable-line
};

InvitePartner.contextType = RouteContext;

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  invitePartner: details => dispatch(invitePartnerAction(details)),
  navigate: (to, state) => dispatch(push(to, state)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvitePartner));
