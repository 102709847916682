import get from 'lodash/get';

import {
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_BOTH_PARTIES,
  DATA_SECTION,
  DATA_GROUP,
  CARD_NORMAL,
  CARD_AGREEMENT,
  CARD_STATEMENT,
  CARD_SUBHEADING,
  SECTION_PARENTING,
  DATA_PAGE,
  PAGE_PARENTING_ESSENTIALS,
  PAGE_PARENTING_SCHEDULE,
  PAGE_PARENTING_ADDITIONS,
  PAGE_PARENTING_COMPLETE,
  CARD_COMPONENT,
  AGREEMENT_INCOMPLETE,
  AGREEMENT_READY_TO_EXPORT,
  CARD_CUSTOM,
} from 'Common/constants';
import MatterPropsJS from 'Common/Utils/MatterProps';
import {
  ODRS_SCHEDULE_ROUTE,
  ODRS_PARENTING_PLAN_SCHEDULE,
  ODRS_PARENTING_PLAN_HANDOVERS,
  ODRS_PARENTING_PLAN_BIRTHDAYS,
  ODRS_PARENTING_PLAN_MOTHERS_FATHERS_DAY,
  ODRS_PARENTING_PLAN_CHRISTMAS,
  ODRS_PARENTING_PLAN_EASTER,
  ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_SHORT,
  ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_LONG,
  ODRS_PARENTING_PLAN_EXTRACURRICULAR,
  ODRS_PARENTING_PLAN_PARENT_TEACHER_INTERVIEWS,
  ODRS_PARENTING_PLAN_DOCTORS_APPOINTMENTS,
  ODRS_PARENTING_PLAN_MEDICATION,
  ODRS_PARENTING_PLAN_TRAVELLING,
  ODRS_PARENTING_PLAN_EXPENSES,
  ODRS_PARENTING_PLAN_COMMUNICATION,
  ODRS_PARENTING_PLAN_RELIGION_AND_CULTURE,
  ODRS_PARENTING_PLAN_COMMUNICATION_METHOD,
  ODRS_PARENTING_PLAN_COOPERATING,
  ODRS_PARENTING_PLAN_CONTACT_DETAILS,
  ODRS_DASH_PARENTING_ROUTE,
  ODRS_PARENTING_ESSENTIALS_ROUTE,
  ODRS_PARENTING_SCHEDULE_ROUTE,
  ODRS_PARENTING_ADDITIONS_ROUTE,
  ODRS_PARENTING_COMPLETE_ROUTE,
} from 'Common/routes';
import {
  areParentingPlanAssetsAllAccountedFor,
  areRelationshipAssetsAllAccountedFor,
  isParentingPlanBasicsAgreed,
} from 'App/Utils/matterHelpers';
import { getNavItemStatus } from 'Common/UI/Navigation';
import BackgroundQuestionFlowParenting from 'Common/Assets/images/question-flow-bg-parenting.png';
import colours from 'Common/Utils/colours';
import getChildrenData from 'Common/Utils/getChildrenData';
import { getSectionCardStats } from 'Common/Utils/cardHelpers';
import { currentStatusParenting } from 'Common/Utils/documentStatus';
import ParentingDocumentCards from 'App/UI/DocumentCard/Parenting/ParentingDocumentCards';
import { buildCard } from '../../../buildCard';
import { getAppSections } from '../VERSIONED_appSections';
import {
  CardAgreement,
  CardComponent,
  CardCustom,
  CardNormal,
  CardStatement,
  CardSubheading,
} from '../../../../Types/appSections';
import Matter from '../../../../Types/matter';

const generate = ({
  parentingPlanBasics,
  parentingPlanScheduling,
  parentingPlanAdditions,
}: {
  parentingPlanBasics: any;
  parentingPlanScheduling: any;
  parentingPlanAdditions: any;
}) => ({
  type: DATA_SECTION,
  id: SECTION_PARENTING,
  title: 'Creating parenting arrangements',
  route: ODRS_DASH_PARENTING_ROUTE,
  questionFlowBackgroundImage: BackgroundQuestionFlowParenting,
  questionFlowBackgroundColour: colours.offWhite,
  children: [
    {
      type: DATA_PAGE,
      id: PAGE_PARENTING_ESSENTIALS,
      title: '1. The Essentials',
      route: ODRS_PARENTING_ESSENTIALS_ROUTE,
      subTitle: '1. The Essentials',
      description:
        "Before you work out living arrangements and the details around raising your child, it's important to include these basic legal statements about your parenting. Read and agree to them to move on to the next section.",
      generateStatus: (matter: Matter) =>
        getNavItemStatus({
          isCompleted: isParentingPlanBasicsAgreed(),
          isLocked: [
            !areRelationshipAssetsAllAccountedFor(),
            matter.childrenData
              ? !matter.childrenData.haveBeenConfirmed
              : !getChildrenData(matter.items).haveBeenConfirmed,
          ].some(condition => condition === true),
        }),
      children: [
        {
          type: DATA_GROUP,
          id: 'basics',
          children: [
            buildCard<CardAgreement>({
              type: CARD_AGREEMENT,
              baseID: 'majorDecisions',
              cardID: `majorDecisions${MatterPropsJS('self.party', 'A')}`,
              title: 'Major decisions',
              statement: get(parentingPlanBasics, 'majorDecisions.statement'),
              index: MatterPropsJS('self.party', 'A'),
              status: STATUS_NEW_ONLY_SELF,
              hideFromOtherParty: true,
            }),
            buildCard<CardAgreement>({
              type: CARD_AGREEMENT,
              baseID: 'disputeResolution',
              cardID: `disputeResolution${MatterPropsJS('self.party', 'A')}`,
              title: 'Dispute resolution',
              statement: get(
                parentingPlanBasics,
                'disputeResolution.statement'
              ),
              index: MatterPropsJS('self.party', 'A'),
              status: STATUS_NEW_ONLY_SELF,
              hideFromOtherParty: true,
            }),
            buildCard<CardAgreement>({
              type: CARD_AGREEMENT,
              baseID: 'majorDecisions',
              cardID: `majorDecisions${MatterPropsJS('other.party', 'B')}`,
              title: 'Major decisions',
              statement: get(parentingPlanBasics, 'majorDecisions.statement'),
              index: MatterPropsJS('other.party', 'B'),
              status: STATUS_NEW_ONLY_SELF,
              hideFromOtherParty: true,
            }),
            buildCard<CardAgreement>({
              type: CARD_AGREEMENT,
              baseID: 'disputeResolution',
              cardID: `disputeResolution${MatterPropsJS('other.party', 'B')}`,
              title: 'Dispute resolution',
              statement: get(
                parentingPlanBasics,
                'disputeResolution.statement'
              ),
              index: MatterPropsJS('other.party', 'B'),
              status: STATUS_NEW_ONLY_SELF,
              hideFromOtherParty: true,
            }),
          ],
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PARENTING_SCHEDULE,
      title: '2. Schedule',
      route: ODRS_PARENTING_SCHEDULE_ROUTE,
      subTitle: '2. Schedule',
      description:
        'This is where you think about when your child spends time with each of you, including special occasions.',
      generateStatus: () => {
        const stats = getSectionCardStats({
          appSections: getAppSections(),
          section: SECTION_PARENTING,
          page: PAGE_PARENTING_SCHEDULE,
        });

        const hasDocumentExportStarted = ![
          AGREEMENT_INCOMPLETE,
          AGREEMENT_READY_TO_EXPORT,
        ].includes(currentStatusParenting());

        return getNavItemStatus({
          isCompleted:
            stats.completedIncludingOptional || hasDocumentExportStarted,
          isLocked: !isParentingPlanBasicsAgreed(),
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'scheduling',
          disabled: () => !isParentingPlanBasicsAgreed(),
          children: [
            buildCard<CardCustom>({
              type: CARD_CUSTOM,
              baseID: 'schedule',
              cardID: 'schedule',
              title: 'Schedule',
              questions: get(parentingPlanScheduling, 'schedule.questions'),
              questionsRoute: ODRS_PARENTING_PLAN_SCHEDULE,
              statement: get(parentingPlanScheduling, 'schedule.statement'),
              status: STATUS_NEW_BOTH_PARTIES,
              updateRoute: `${ODRS_SCHEDULE_ROUTE}/update`,
              summaryComponentGenerator: get(
                parentingPlanScheduling,
                'schedule.summaryComponentGenerator'
              ),
              fullComponentGenerator: get(
                parentingPlanScheduling,
                'schedule.fullComponentGenerator'
              ),
              typeName: 'schedule',
            }),
            buildCard<CardNormal>({
              type: CARD_NORMAL,
              baseID: 'handovers',
              cardID: 'handovers',
              title: 'Handovers',
              questions: get(parentingPlanScheduling, 'handovers.questions'),
              questionsRoute: ODRS_PARENTING_PLAN_HANDOVERS,
              updateRoute: `${ODRS_PARENTING_PLAN_HANDOVERS}/update`,
              statement: get(parentingPlanScheduling, 'handovers.statement'),
              status: STATUS_NEW_BOTH_PARTIES,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'schoolHolidayShort',
              cardID: 'schoolHolidayShort',
              title: 'Short school holidays',
              questions: get(
                parentingPlanScheduling,
                'schoolHolidayShort.questions'
              ),
              statement: get(
                parentingPlanScheduling,
                'schoolHolidayShort.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_SHORT,
              updateRoute: `${ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_SHORT}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'schoolHolidayLong',
              cardID: 'schoolHolidayLong',
              title: 'Long school holidays',
              questions: get(
                parentingPlanScheduling,
                'schoolHolidayLong.questions'
              ),
              statement: get(
                parentingPlanScheduling,
                'schoolHolidayLong.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_LONG,
              updateRoute: `${ODRS_PARENTING_PLAN_SCHOOL_HOLIDAY_LONG}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            {
              type: CARD_SUBHEADING,
              id: 'exceptions',
              title: 'Special Occasions',
              data: {},
            },
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'birthdays',
              cardID: 'birthdays',
              title: 'Birthdays',
              questions: get(parentingPlanScheduling, 'birthdays.questions'),
              statement: get(parentingPlanScheduling, 'birthdays.statement'),
              questionsRoute: ODRS_PARENTING_PLAN_BIRTHDAYS,
              updateRoute: `${ODRS_PARENTING_PLAN_BIRTHDAYS}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'mothersFathersDay',
              cardID: 'mothersFathersDay',
              title: "Mother's/Father's Day",
              questions: get(
                parentingPlanScheduling,
                'mothersFathersDay.questions'
              ),
              statement: get(
                parentingPlanScheduling,
                'mothersFathersDay.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_MOTHERS_FATHERS_DAY,
              updateRoute: `${ODRS_PARENTING_PLAN_MOTHERS_FATHERS_DAY}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'christmas',
              cardID: 'christmas',
              title: 'Christmas',
              questions: get(parentingPlanScheduling, 'christmas.questions'),
              statement: get(parentingPlanScheduling, 'christmas.statement'),
              questionsRoute: ODRS_PARENTING_PLAN_CHRISTMAS,
              updateRoute: `${ODRS_PARENTING_PLAN_CHRISTMAS}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'easter',
              cardID: 'easter',
              title: 'Easter',
              questions: get(parentingPlanScheduling, 'easter.questions'),
              statement: get(parentingPlanScheduling, 'easter.statement'),
              questionsRoute: ODRS_PARENTING_PLAN_EASTER,
              updateRoute: `${ODRS_PARENTING_PLAN_EASTER}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
          ],
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PARENTING_ADDITIONS,
      title: '3. Additions',
      route: ODRS_PARENTING_ADDITIONS_ROUTE,
      subTitle: '3. Additions',
      description:
        'This section is for additional areas you would like to include in your agreement. These statements are optional. Adding statements about these things can help everyone to know where they stand.',
      generateStatus: () => {
        const stats = getSectionCardStats({
          appSections: getAppSections(),
          section: SECTION_PARENTING,
          page: PAGE_PARENTING_ADDITIONS,
        });

        const hasDocumentExportStarted = ![
          AGREEMENT_INCOMPLETE,
          AGREEMENT_READY_TO_EXPORT,
        ].includes(currentStatusParenting());

        return getNavItemStatus({
          isCompleted:
            stats.completedIncludingOptional || hasDocumentExportStarted,
          isLocked: !isParentingPlanBasicsAgreed(),
        });
      },
      children: [
        {
          type: DATA_GROUP,
          id: 'additions',
          disabled: () => !isParentingPlanBasicsAgreed(),
          children: [
            buildCard<CardSubheading>({
              type: CARD_SUBHEADING,
              id: 'education',
              title: 'Education',
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'extracurricular',
              cardID: 'extracurricular',
              title: 'Extracurricular events',
              questions: get(
                parentingPlanAdditions,
                'extracurricular.questions'
              ),
              statement: get(
                parentingPlanAdditions,
                'extracurricular.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_EXTRACURRICULAR,
              updateRoute: `${ODRS_PARENTING_PLAN_EXTRACURRICULAR}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'parentTeacherInterviews',
              cardID: 'parentTeacherInterviews',
              title: 'School events',
              questions: get(
                parentingPlanAdditions,
                'parentTeacherInterviews.questions'
              ),
              statement: get(
                parentingPlanAdditions,
                'parentTeacherInterviews.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_PARENT_TEACHER_INTERVIEWS,
              updateRoute: `${ODRS_PARENTING_PLAN_PARENT_TEACHER_INTERVIEWS}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            {
              type: CARD_SUBHEADING,
              id: 'health',
              title: 'Health',
              data: {},
            },
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'doctorsAppointments',
              cardID: 'doctorsAppointments',
              title: 'Health notifications',
              questions: get(
                parentingPlanAdditions,
                'doctorsAppointments.questions'
              ),
              statement: get(
                parentingPlanAdditions,
                'doctorsAppointments.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_DOCTORS_APPOINTMENTS,
              updateRoute: `${ODRS_PARENTING_PLAN_DOCTORS_APPOINTMENTS}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'medication',
              cardID: 'medication',
              title: 'Health consultations',
              questions: get(parentingPlanAdditions, 'medication.questions'),
              statement: get(parentingPlanAdditions, 'medication.statement'),
              questionsRoute: ODRS_PARENTING_PLAN_MEDICATION,
              updateRoute: `${ODRS_PARENTING_PLAN_MEDICATION}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardSubheading>({
              type: CARD_SUBHEADING,
              id: 'comms',
              title: 'Commmunication',
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'travelling',
              cardID: 'travelling',
              title: 'Traveling interstate or overseas',
              questions: get(parentingPlanAdditions, 'travelling.questions'),
              statement: get(parentingPlanAdditions, 'travelling.statement'),
              questionsRoute: ODRS_PARENTING_PLAN_TRAVELLING,
              updateRoute: `${ODRS_PARENTING_PLAN_TRAVELLING}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'expenses',
              cardID: 'expenses',
              title: 'Expenses',
              questions: get(parentingPlanAdditions, 'expenses.questions'),
              statement: get(parentingPlanAdditions, 'expenses.statement'),
              questionsRoute: ODRS_PARENTING_PLAN_EXPENSES,
              updateRoute: `${ODRS_PARENTING_PLAN_EXPENSES}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'communicationMethod',
              cardID: 'communicationMethod',
              title: 'Communication Method',
              questions: get(
                parentingPlanAdditions,
                'communicationMethod.questions'
              ),
              statement: get(
                parentingPlanAdditions,
                'communicationMethod.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_COMMUNICATION_METHOD,
              updateRoute: `${ODRS_PARENTING_PLAN_COMMUNICATION_METHOD}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'communication',
              cardID: 'communication',
              title: 'Communication',
              questions: get(parentingPlanAdditions, 'communication.questions'),
              statement: get(parentingPlanAdditions, 'communication.statement'),
              questionsRoute: ODRS_PARENTING_PLAN_COMMUNICATION,
              updateRoute: `${ODRS_PARENTING_PLAN_COMMUNICATION}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'cooperating',
              cardID: 'cooperating',
              title: 'Using positive language',
              questions: get(parentingPlanAdditions, 'cooperating.questions'),
              statement: get(parentingPlanAdditions, 'cooperating.statement'),
              questionsRoute: ODRS_PARENTING_PLAN_COOPERATING,
              updateRoute: `${ODRS_PARENTING_PLAN_COOPERATING}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'contactDetails',
              cardID: 'contactDetails',
              title: 'Contact details',
              questions: get(
                parentingPlanAdditions,
                'contactDetails.questions'
              ),
              statement: get(
                parentingPlanAdditions,
                'contactDetails.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_CONTACT_DETAILS,
              updateRoute: `${ODRS_PARENTING_PLAN_CONTACT_DETAILS}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
            buildCard<CardSubheading>({
              type: CARD_SUBHEADING,
              id: 'religion',
              title: 'Religion and Cultural Background',
            }),
            buildCard<CardStatement>({
              type: CARD_STATEMENT,
              baseID: 'religionAndCulture',
              cardID: 'religionAndCulture',
              title: 'Religion and cultural background',
              questions: get(
                parentingPlanAdditions,
                'religionAndCulture.questions'
              ),
              statement: get(
                parentingPlanAdditions,
                'religionAndCulture.statement'
              ),
              questionsRoute: ODRS_PARENTING_PLAN_RELIGION_AND_CULTURE,
              updateRoute: `${ODRS_PARENTING_PLAN_RELIGION_AND_CULTURE}/update`,
              status: STATUS_NEW_BOTH_PARTIES,
              optional: true,
            }),
          ],
        },
      ],
    },
    {
      type: DATA_PAGE,
      id: PAGE_PARENTING_COMPLETE,
      title: '4. Document creation',
      route: ODRS_PARENTING_COMPLETE_ROUTE,
      subTitle: '4. Document creation',
      description: 'This is where you both agree to create your documents.',
      lockedTooltip:
        'You will have to agree to the Essentials and a schedule before you can get started on this section.',
      generateStatus: () =>
        getNavItemStatus({
          isCompleted: areParentingPlanAssetsAllAccountedFor(),
          isLocked: !areParentingPlanAssetsAllAccountedFor(),
        }),
      children: [
        {
          type: DATA_GROUP,
          id: 'complete',
          children: [
            buildCard<CardComponent>({
              type: CARD_COMPONENT,
              cardID: 'parentingDocumentCards',
              ComponentClass: ParentingDocumentCards,
            }),
          ],
        },
      ],
    },
  ],
});

export default generate;
