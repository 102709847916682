import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  MESSAGE_STATUS_UPDATE_DONE,
  SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { reduxStore, useAppDispatch, useAppSelector } from 'App/State/Store';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { definitelyGetCard } from 'Common/Data/App/appSections';
import { CardYourSuperannuation } from 'Common/Data/Types/appSections';

export const superannuationBalanceRequestCancelledRequesteeFlags: AppState = {
  id: 'superannuationBalanceRequestCancelledRequestee',
  description:
    'The other party cancelled the request to check your superannuation balance',
  check: () => {
    const {
      matter: { items },
    } = reduxStore.getState();

    const selfParty = getPartyKeys().self;

    // check if there are any super cards with cancelled request flag that are created by me
    const superCardWithCancelledBalanceRequest = items.some(
      item =>
        item.BaseSection === 'yoursuperannuation' &&
        item.__flags?.cancelledRequest &&
        item.creator === selfParty
    );

    return superCardWithCancelledBalanceRequest;
  },
  flags: {
    appState: SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE,
  },
  usedAsNotificationState: true,
};

type Props = {
  otherName: string;
};

export const SuperannuationBalanceRequestCancelledRequestee: React.FC<Props> =
  ({ otherName }) => {
    const { withLoading } = usePromiseWithLoading();
    const dispatch = useAppDispatch();

    const selfParty = getPartyKeys().self;

    const { items } = useAppSelector(state => state.matter);

    // use `find` here as we just want the first card even if there are multiple
    const superCardWithCancelledBalanceRequest = items.find(
      item =>
        item.BaseSection === 'yoursuperannuation' &&
        item.__flags?.cancelledRequest &&
        item.creator === selfParty
    );

    if (!superCardWithCancelledBalanceRequest) {
      console.warn('No super card found with cancelled request flag');

      return null;
    }

    const card = definitelyGetCard<CardYourSuperannuation>(
      superCardWithCancelledBalanceRequest.SectionID
    );

    const { cardIDAsArray, data } = card;

    const onDismissBanner = () =>
      withLoading(async () => {
        const newCardData = {
          ...data,
          __flags: {
            cancelledRequest: false,
          },
        };

        const messageData = {
          section: cardIDAsArray,
          owner: selfParty,
          message: '',
          status: MESSAGE_STATUS_UPDATE_DONE,
          checkTone: false,
        };

        await dispatch(
          updateMatterSectionAction({
            section: cardIDAsArray,
            sectionData: newCardData,
            messageData,
          })
        );
      });

    return (
      <>
        <SpacedBox marginBottom={8}>
          <Heading2MarginTopNone>
            {otherName} will not request more information about your{' '}
            {data.superannuationName} balance
          </Heading2MarginTopNone>
          <Paragraph>
            {otherName} has cancelled their request for more information on your
            superannuation balance. They will review your superannuation
            statement and approve or send you a message.
          </Paragraph>
        </SpacedBox>
        <GenericButton fullWidth onClick={onDismissBanner} small>
          Got it
        </GenericButton>
      </>
    );
  };
