import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import Div100vh from 'react-div-100vh';
import { DATE_FORMAT_SHORT_WITH_TIME } from 'Common/constants';
import {
  INTRO_ROUTE,
  ODRS_BASE_ROUTE,
  ODRS_PROFILE_SIGNOUT_ROUTE,
} from 'Common/routes';

import ScrollMemory from 'Common/Utils/ScrollMemory';

import { APP_ID } from 'App/config';

import Analytics from 'Common/Utils/Analytics';

import ErrorBoundary from 'Common/UI/ErrorBoundary';

import Onboarding from 'App/Routes/Onboarding';
import ODRS from 'App/Routes/ODRS/Index';

import Intro from 'App/Routes/Intro';
import DiviRoute from 'Common/UI/DiviRoute';
import RouteTransition from 'App/UI/Transitions/RouteTransition';
import AppLoader from 'Common/UI/Loader/AppLoader';
import { blueGreyMobileTheme } from 'Common/Utils/theme';
import DialogManager from 'Common/UI/Layout/DialogManager';
import { reduxStore, history } from './State/Store';
import Signout from './UI/Signout';

// setting the default calendar date formats
moment.updateLocale('en', {
  calendar: {
    sameDay: '[Today], LT',
    nextDay: '[Tomorrrow], LT',
    nextWeek: 'dddd, LT',
    lastDay: '[Yesterday], LT',
    lastWeek: '[Last] dddd, LT',
    sameElse: DATE_FORMAT_SHORT_WITH_TIME,
  },
});

// eslint-disable-next-line react/prop-types
function App() {
  return (
    <ErrorBoundary>
      <Div100vh>
        <RouteTransition>
          <DiviRoute
            exact
            path={ODRS_PROFILE_SIGNOUT_ROUTE}
            component={Signout}
          />
          <DiviRoute
            exact
            path="/"
            component={Onboarding}
            theme={blueGreyMobileTheme}
          />
          <DiviRoute
            exact
            path={INTRO_ROUTE}
            component={Intro}
            theme={blueGreyMobileTheme}
          />
          <DiviRoute path={ODRS_BASE_ROUTE} component={ODRS} />
        </RouteTransition>
        <DialogManager />
      </Div100vh>
    </ErrorBoundary>
  );
}

const Root = (
  <Provider store={reduxStore}>
    <ConnectedRouter history={history}>
      <ScrollMemory scrollTopOffset={-142} elementID="scrollMemory" />
      <App />
      <AppLoader />
    </ConnectedRouter>
  </Provider>
);

Analytics.initGTM(process.env.GTM_TRACKING_ID);

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.STAGE,
    release: process.env.SENTRY_RELEASE,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (
        event.exception &&
        (event.level === 'fatal' || event.level === 'error')
      ) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
}

ReactDOM.render(Root, document.getElementById(APP_ID));

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
