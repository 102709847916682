import React from 'react';

import { DEFAULT_OTHER_TEXT } from 'Common/constants';
import MatterProps from 'Common/Utils/MatterProps';
import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';
import capitaliseText from 'Common/Utils/capitaliseText';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Sent } from 'Common/UI/Layout/Completed';

const DivisionAgree = () => (
  <Sent
    buttonLabel="Back to home"
    buttonRoute={ODRS_PROPERTY_ASSET_DIVISION_ROUTE}
  >
    <H1Conversational>You have agreed to amica’s suggestion</H1Conversational>
    <Paragraph large>
      {capitaliseText(MatterProps('other.firstname', DEFAULT_OTHER_TEXT))} will
      also be able to view our suggestion and make a decision.
    </Paragraph>
  </Sent>
);

export default DivisionAgree;
