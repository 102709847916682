import { STATUS_APPROVED } from 'Common/constants';

import MatterPropsJS from 'Common/Utils/MatterProps';

/**
 * Determine if the difference between (confirmed) super amounts
 * for party A and B are over the threshold of reasonableness,
 * currently 50k
 */
export const isThereALargeSuperDifferential = () => {
  const partyASuper =
    MatterPropsJS('items').find(i => i.SectionID === 'yoursuperannuationA') ||
    {};
  const partyBSuper =
    MatterPropsJS('items').find(i => i.SectionID === 'yoursuperannuationB') ||
    {};

  if (!partyASuper && !partyBSuper) {
    return false;
  }

  if (
    partyASuper.status !== STATUS_APPROVED ||
    partyBSuper.status !== STATUS_APPROVED
  ) {
    return false;
  }

  const diff =
    Number(partyASuper.superannuationValue) -
    Number(partyBSuper.superannuationValue);

  if (Math.abs(diff) > 50000) {
    return true;
  }

  return false;
};
