import React from 'react';
import PropTypes from 'prop-types';

class Nude extends React.Component {
  componentDidMount() {
    if (this.props.componentDidMount) {
      this.props.componentDidMount();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

Nude.propTypes = {
  componentDidMount: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Nude.defaultProps = {
  componentDidMount: null,
};

export default Nude;
