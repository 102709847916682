import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';

import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import Actions from 'Common/UI/Actions';
import {
  ButtonsWrapper,
  InnerContentMiddle,
} from '../../../../../../../common/UI/Signup/Layout';
import WhatIsFullAndFrankDisclosureButton from './WhatIsFullAndFrankDisclosureHelp';

const StyledList = styled.ul`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const SignupDone = ({ navigate }) => (
  <>
    <InnerContentMiddle className="step-transition-fade-up">
      <ResponsiveTextContainer>
        <H1Conversational>Great. You’re signed up!</H1Conversational>
        <Paragraph>
          It&apos;s time to get your documents together. This is a very
          important step in the process, as you need to provide full and frank
          disclosure of all of the property of the relationship with your former
          partner.
        </Paragraph>

        <Paragraph>For example</Paragraph>
        <StyledList>
          <li>how much any property or vehicles you own are worth</li>
          <li>
            the amount of any debts you have, whose name they are in and how
            they were acquired
          </li>
          <li>how much you earn</li>
          <li>your current superannuation balance</li>
        </StyledList>
        <WhatIsFullAndFrankDisclosureButton />
      </ResponsiveTextContainer>
    </InnerContentMiddle>
    <ButtonsWrapper>
      <Actions noPadding>
        <Actions noPadding>
          <GenericButton
            className="step-transition-fade-right"
            onClick={() => navigate(ODRS_DASH_HOME_ROUTE)}
          >
            Continue
          </GenericButton>
        </Actions>
      </Actions>
    </ButtonsWrapper>
  </>
);

SignupDone.propTypes = {
  navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
});

export default connect(null, mapDispatchToProps)(SignupDone);
