import React from 'react';

import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import formatCurrency from 'Common/Utils/formatCurrency';
import MatterProps from 'Common/Utils/MatterProps';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import Matter, { Item } from 'Common/Data/Types/matter';
import { reduxStore } from 'App/State/Store';
import { setMatterFlagsAction } from 'App/State/MatterActions';
import { ODRS_CONSENT_ORDERS_ASSET_ROUTE } from 'Common/routes';
import { HighlightedLabel } from 'Common/UI/Text/Label';

// Note any disabled props will not be saved into the database
export const yourSuperannuationQuestionsContinue = (): QuestionAny[] => {
  // Async calls
  const setAsyncFlag = (
    flags: Record<string, boolean | string>,
    navigate: (route: string) => void
  ) =>
    new Promise((_resolve, reject) => {
      reduxStore
        .dispatch(setMatterFlagsAction(flags))
        .then(() => {
          navigate(ODRS_CONSENT_ORDERS_ASSET_ROUTE);
        })
        .catch((error: any) => reject(new Error(error)));
    });
  const notSuitableAsync = (
    flags: Record<string, boolean | string>,
    navigate: (route: string) => void
  ) => setAsyncFlag(flags, navigate);

  return [
    {
      name: 'intro',
      type: 'intro',
      heading: ({ self: { firstname } }: Matter) => (
        <H1Conversational>
          Hi {firstname}, we're going to ask you to confirm some details about
          your superannuation to see if you can make an application for consent
          orders in amica.
        </H1Conversational>
      ),
      content: () => (
        <>
          <Paragraph>
            Even though you can't split superannuation using amica, the Court
            requires certain details about your superannuation interests.{' '}
          </Paragraph>

          <Paragraph>
            To learn more about superannuation, visit our{' '}
            <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
              Money and Property
            </ReferralLink>{' '}
            page, or get{' '}
            <ReferralLink href="https://amica.gov.au/help-and-support">
              Legal Help Line
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
      index: 0,
    },
    {
      name: 'whatIsYourSuperName',
      label: 'Please confirm the names of your superannuation fund(s)',
      type: 'textfield',
      hint: 'Super fund name(s)',
      defaultValue: () => {
        const items = MatterProps('items', []);
        const selfParty = MatterProps('self.party', PARTY_A);

        const superAccounts = items.filter(
          (item: Item) =>
            item.BaseSection === 'yoursuperannuation' &&
            item.creator === selfParty
        );

        const superNamesArray = superAccounts.map(
          (item: Item) => item.superannuationName
        );
        let superNames = '';

        if (superAccounts.length === 1) {
          superNames = superNamesArray[0];
        } else if (superAccounts.length === 2) {
          superNames = superNamesArray.join(' and ');
        } else {
          superNames = `${superNamesArray
            .slice(0, -1)
            .join(', ')} and ${superNamesArray.slice(-1)}`;
        }

        return superNames;
      },
      description: `You and ${MatterProps(
        'other.firstname',
        'Your former partner'
      )} have already agreed to this in previous section`,
      disabled: true,
      index: 1,
    },
    {
      name: 'whatIsYourSuperValue',
      label:
        'Please confirm the current agreed gross value of your superannuation interest(s).',
      type: 'textfield',
      hint: 'Superannuation interest(s) value',
      defaultValue: () => {
        const items = MatterProps('items', []);
        const selfParty = MatterProps('self.party', PARTY_A);

        const superAccounts = items.filter(
          (item: Item) =>
            item.BaseSection === 'yoursuperannuation' &&
            item.creator === selfParty
        );

        const superValues = superAccounts.reduce(
          (acc: number, curr: Item) => acc + Number(curr.superannuationValue),
          0
        );

        return formatCurrency(superValues);
      },
      disabled: true,
      description: `You and ${MatterProps(
        'other.firstname',
        'Your former partner'
      )} have already agreed to this in previous section`,
      index: 2,
    },
    {
      name: 'earlierPaymentSplit',
      type: 'yesno',
      label:
        'Are any of your superannuation interests subject to an earlier payment split?',
      help: {
        label: 'Learn more',
        content: (
          <>
            <H1Conversational>Learn more</H1Conversational>
            <Paragraph large>
              This question is asking whether any of your superannuation
              interests have been subject to an earlier payment split.
            </Paragraph>
          </>
        ),
      },
      index: 3,
    },
    {
      name: 'amicaIsUnsuitable1',
      type: 'info',
      exitOnSubmit: true,
      label:
        'Because you answered “yes”, amica is not suitable for making an application for consent orders for your situation.',
      content: (
        <Paragraph>
          If you would like help about your situation you can call the free{' '}
          <ReferralLink href="https://www.amica.gov.au/legal-help-line.html">
            Legal Help Line
          </ReferralLink>{' '}
          in your state or territory.
        </Paragraph>
      ),
      visible: answer => Boolean(answer.earlierPaymentSplit),
      onBeforeNext: (_data, navigate) =>
        notSuitableAsync(
          {
            superannuationNotSuitable: true,
            superannuationNotSuitableReason: 'earlierPaymentSplit',
          },
          navigate
        ),
      index: 4,
    },
    {
      name: 'info1',
      type: 'info',
      label:
        'The court requires parties to provide the information in the next question regardless of whether they are splitting superannuation or not. (You cannot split superannuation using amica.)',
      index: 5,
    },
    {
      name: 'isSurchargeDebtApplicable',
      type: 'yesno',
      label: (
        <>
          <HighlightedLabel>
            For each of your superannuation interests:
          </HighlightedLabel>

          <HighlightedLabel>
            Does the most recent member statement indicate that a surcharge debt
            is applicable to the interest?
          </HighlightedLabel>
        </>
      ),
      help: {
        label: 'Learn more',
        content: (
          <>
            <H1Conversational>Learn more</H1Conversational>
            <Paragraph large>
              During the financial years 1997-2005, a tax was applied to certain
              super contributions. The amount payable is referred to as a
              surcharge debt.
            </Paragraph>

            <Paragraph large>
              Check your most recent super statement to see if you have a
              current surcharge debt for that super fund.
            </Paragraph>
            <Paragraph large>If you are not sure, you can:</Paragraph>
            <Paragraph as="ul">
              <li>Ask your super fund</li>
              <li>
                Contact your local free{' '}
                <ReferralLink href="https://amica.gov.au/help-and-supporthttps://my.gov.au">
                  Legal Help Line
                </ReferralLink>{' '}
                to ask about surcharge debts.
              </li>
              <li>
                Check more info about surcharge debts on the{' '}
                <ReferralLink href="https://www.ato.gov.au/Individuals/Super/In-detail/Growing-your-super/Superannuation-surcharge---information-for-individuals/">
                  ATO website
                </ReferralLink>
                .
              </li>
            </Paragraph>
          </>
        ),
      },
      index: 6,
    },
    {
      name: 'amicaIsUnsuitable2',
      type: 'info',
      exitOnSubmit: true,
      label:
        'Because you answered “yes”, amica is not suitable for making an application for consent orders for your situation.',
      content: (
        <>
          If you would like help about your situation you can call the free{' '}
          <ReferralLink href="https://www.amica.gov.au/legal-help-line.html">
            Legal Help Line
          </ReferralLink>{' '}
          in your state or territory.
        </>
      ),
      visible: answer => Boolean(answer.isSurchargeDebtApplicable),
      onBeforeNext: (_data, navigate) =>
        notSuitableAsync(
          {
            superannuationNotSuitable: true,
            superannuationNotSuitableReason: 'surchargeDebt',
          },
          navigate
        ),
      index: 7,
    },
    {
      name: 'taxationConsequences',
      label:
        'Do you think you have any taxation consequences of any order sought in relation to any interest in superannuation?',
      hint: '',
      type: 'yesno',
      help: {
        label: 'Learn more',
        content: (
          <>
            <H1Conversational>Learn more</H1Conversational>
            <Paragraph large>
              If your situation includes superannuation entitlements, you would
              each keep your superannuation entitlements under any agreement
              made on amica, because amica does not enable parties to split
              superannuation.
              <br />
              On that basis the usual position is that you will each be liable
              to pay the tax on your own superannuation entitlement when you
              access your superannuation.
            </Paragraph>
            <Paragraph large>
              If you want legal help about tax consequences in relation to any
              interest in superannuation you can contact the free{' '}
              <ReferralLink href="https://www.amica.gov.au/legal-help-line.html">
                Legal Help Line
              </ReferralLink>{' '}
              in your state.
            </Paragraph>
          </>
        ),
      },
      index: 8,
    },
    {
      name: 'describeTaxationConsequences',
      type: 'freetext',
      hint: 'A brief description',
      label:
        'What are the taxation consequences of any order sought in relation to any interest in superannuation?',
      visible: answer => Boolean(answer.taxationConsequences),
      index: 9,
    },
    {
      name: 'statement',
      type: 'statement',
      label: 'You created a statement',
      index: 10,
    },
  ];
};

export const yourSuperannuationStatementContinue = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.CardIndex || matter.self.party;
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'taxationConsequences',
    'describeTaxationConsequences',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    yourSuperannuationQuestionsContinue(),
    data,
    hideSensitive
  );

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  // Data
  const { taxationConsequences } = data;

  const items = MatterProps('items', []);

  const superAccounts = items.filter(
    (item: Item) =>
      item.BaseSection === 'yoursuperannuation' && item.creator === party
  );

  const superNamesArray = superAccounts.map(
    (item: Item) => item.superannuationName
  );
  let superNames = '';

  if (superAccounts.length === 1) {
    superNames = superNamesArray[0];
  } else if (superAccounts.length === 2) {
    superNames = superNamesArray.join(' and ');
  } else {
    superNames = `${superNamesArray
      .slice(0, -1)
      .join(', ')} and ${superNamesArray.slice(-1)}`;
  }

  const superValues = superAccounts.reduce(
    (acc: number, curr: Item) => acc + Number(curr.superannuationValue),
    0
  );

  const superannuationFunds = highlight`${firstName} has a total of ${redact(
    'whatIsYourSuperValue',
    formatCurrency(superValues)
  )} in superannuation with ${redact(
    'whatIsYourSuperName',
    superNames
  )}.  None of their interests is subject to an earlier payment split. None of their interests is subject to a surcharge debt. `;

  const statementTaxationConsequence = taxationConsequences
    ? highlight`${firstName} ${updated(
        'taxationConsequences',
        redact('taxationConsequences', 'does')
      )} have taxation consequences in relation to any interest in superannuation: "${updated(
        ['taxationConsequences', 'describeTaxationConsequences'],
        redact('describeTaxationConsequences')
      )}".`
    : highlight`${firstName} ${updated(
        'taxationConsequences',
        redact('taxationConsequences', 'does not')
      )} have any taxation consequences in relation to any interest in superannuation.`;

  if (plainText) {
    return `${superannuationFunds} ${statementTaxationConsequence}`;
  }

  return (
    <>
      {superannuationFunds} {statementTaxationConsequence}
    </>
  );
};
