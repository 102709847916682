import { useAppSelector } from 'App/State/Store';
import {
  DocumentCardStatus,
  Agreements,
  DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE,
  DOCUMENT_CARD_PAYMENT_REQUIRED,
  AGREEMENT_CREATED,
  DOCUMENT_CARD_COMPLETE,
  DOCUMENT_CARD_SELF_REQUESTED,
  AGREEMENT_WAITING_FOR_OTHER,
  AGREEMENT_NEEDS_CONFIRMATION,
  DOCUMENT_CARD_OTHER_REQUESTED,
  AGREEMENT_INCOMPLETE,
  DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE,
  STATUS_REMOVED,
  DOC_TYPE_CONSENT_ORDERS,
  DOCUMENT_CARD_BLOCKED,
} from 'Common/constants';
import { isPaymentDue } from 'Common/Utils/Payments';
import { Item } from 'Common/Data/Types/matter';

const getDocumentCardStatus = (
  agreementType: Agreements,
  state: string
): DocumentCardStatus => {
  const matter = useAppSelector(appState => appState.matter);

  const { items = [] } = matter;

  const superOther = items.filter(
    (item: Item) =>
      item.BaseSection === 'yoursuperannuation' &&
      item.superannuationType === 'Other' &&
      item.status !== STATUS_REMOVED
  );

  if (
    superOther &&
    superOther.length > 0 &&
    agreementType === DOC_TYPE_CONSENT_ORDERS
  ) {
    return DOCUMENT_CARD_BLOCKED;
  }

  if (state === AGREEMENT_INCOMPLETE) {
    return DOCUMENT_CARD_REQUIRED_SECTIONS_INCOMPLETE;
  }

  if (state === AGREEMENT_WAITING_FOR_OTHER) {
    return DOCUMENT_CARD_SELF_REQUESTED;
  }

  if (state === AGREEMENT_NEEDS_CONFIRMATION) {
    return DOCUMENT_CARD_OTHER_REQUESTED;
  }

  if (isPaymentDue(matter, agreementType)) {
    return DOCUMENT_CARD_PAYMENT_REQUIRED;
  }

  if (state === AGREEMENT_CREATED) {
    return DOCUMENT_CARD_COMPLETE;
  }

  return DOCUMENT_CARD_REQUIRED_SECTIONS_COMPLETE;
};

export default getDocumentCardStatus;
