import React from 'react';
import StatementDetails from 'App/UI/Statements/StatementDetails';
import MatterGuard from '../Guard/MatterGuard';

const ODRSStatementDetails = props => (
  <MatterGuard>
    <StatementDetails {...props} />
  </MatterGuard>
);

export default ODRSStatementDetails;
