import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import {
  MESSAGE_STATUS_UPDATE_DONE,
  STATUS_APPROVED,
  STATUS_REMOVED,
  SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { reduxStore, useAppDispatch, useAppSelector } from 'App/State/Store';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import { definitelyGetCard } from 'Common/Data/App/appSections';
import { CardYourSuperannuation } from 'Common/Data/Types/appSections';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import colours from 'Common/Utils/colours';
import TextButton from '../Button/TextButton';

export const superannuationRequestBalanceStartedRequesterFlags: AppState = {
  id: 'superannuationRequestBalanceStartedRequester',
  description:
    'This party has started the request balance flow for a superannuation account',
  check: () => {
    const {
      matter: { items },
    } = reduxStore.getState();

    const selfParty = getPartyKeys().self;

    // get all open super cards that are not created by me that have had the flow started but not progressed
    const superCardsWithBalanceRequestStarted = items.filter(
      item =>
        ![STATUS_APPROVED, STATUS_REMOVED].includes(item.status) &&
        item.BaseSection === 'yoursuperannuation' &&
        item.__flags?.superBalanceRequested &&
        !item.__flags?.letterReceived &&
        item.creator !== selfParty
    );

    // if there are no cards found, don't show this banner
    if (!superCardsWithBalanceRequestStarted) {
      return false;
    }

    const haveAllCardsBannersBeenDismissed =
      superCardsWithBalanceRequestStarted.every(({ SectionID }) =>
        sessionStorageLoad(
          `SuperannuationRequestBalanceStartedRequester_${SectionID}_Dismissed`
        )
      );

    // if the banner for every card found has previously been dismissed, don't show this banner
    // note: we are putting this check in here rather than in the bannerTopState flag like normal because
    // the we want to skip this app state if it has been dismissed, not just trigger this app state but hide the banner
    return !haveAllCardsBannersBeenDismissed;
  },
  flags: {
    appState: SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER,
  },
  usedAsNotificationState: true,
};

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperannuationRequestBalanceStartedRequester: React.FC<Props> = ({
  otherName,
  updateAppState,
}) => {
  const { withLoading } = usePromiseWithLoading();
  const dispatch = useAppDispatch();

  const selfParty = getPartyKeys().self;

  const { items } = useAppSelector(state => state.matter);

  const allSuperCardsWithRequestBalancedStarted = items.filter(
    item =>
      ![STATUS_APPROVED, STATUS_REMOVED].includes(item.status) &&
      item.BaseSection === 'yoursuperannuation' &&
      item.__flags?.superBalanceRequested &&
      !item.__flags?.letterReceived &&
      item.creator !== selfParty
  );

  // find the first card that has not been dismissed
  const superCardWithRequestBalancedStarted =
    allSuperCardsWithRequestBalancedStarted.find(
      ({ SectionID }) =>
        !sessionStorageLoad(
          `SuperannuationRequestBalanceStartedRequester_${SectionID}_Dismissed`
        )
    );

  if (!superCardWithRequestBalancedStarted) {
    console.warn(
      'No super card found with request balance started but not progressed'
    );

    return null;
  }

  const card = definitelyGetCard<CardYourSuperannuation>(
    superCardWithRequestBalancedStarted.SectionID
  );

  const { cardIDAsArray, data, cardID } = card;

  const onConfirmReceivedLetter = () =>
    withLoading(async () => {
      const newCardData = {
        ...data,
        __flags: {
          ...(data?.__flags || {}),
          letterReceived: true,
        },
      };

      const messageData = {
        section: cardIDAsArray,
        owner: selfParty,
        message: '',
        status: MESSAGE_STATUS_UPDATE_DONE,
        checkTone: false,
      };

      await dispatch(
        updateMatterSectionAction({
          section: cardIDAsArray,
          sectionData: newCardData,
          messageData,
        })
      );
    });

  return (
    <>
      <SpacedBox marginBottom={8}>
        <Heading2MarginTopNone>
          You have decided to submit a Superannuation Information Kit to check{' '}
          {otherName}'s {data.superannuationName} balance.
        </Heading2MarginTopNone>
      </SpacedBox>
      <SpacedBox marginBottom={4}>
        <GenericButton
          fullWidth
          onClick={onConfirmReceivedLetter}
          small
          data-cy="received-letter-button"
        >
          I've received a letter
        </GenericButton>
      </SpacedBox>
      <TextButton
        fullWidth
        onClick={() => {
          sessionStorageSave(
            `SuperannuationRequestBalanceStartedRequester_${cardID}_Dismissed`,
            true
          );
          updateAppState();
        }}
        small
        color={colours.clickableGrey}
        data-cy="dismiss-button"
      >
        Dismiss
      </TextButton>
    </>
  );
};
