import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from 'Common/UI/Text/Paragraph';
import LearnMoreButton from 'Common/UI/Button/LearnMoreButton';
import DivisionHelpAndSupportContent from './Components/DivisionHelpAndSupport';
import TextSummary from './Components/TextSummary';

const HelpAndSupportContainer = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.padding.small}px;
  margin-bottom: 50px;
  ${Paragraph} {
    margin-bottom: ${({ theme }) => theme.padding.medium}px;
  }
`;

const DivisionHelpAndSupport = ({ viewer }) => (
  <HelpAndSupportContainer>
    <TextSummary viewer={viewer} />
    <LearnMoreButton help small popUpContent={DivisionHelpAndSupportContent}>
      Help and Support
    </LearnMoreButton>
  </HelpAndSupportContainer>
);

DivisionHelpAndSupport.propTypes = {
  viewer: PropTypes.string,
};

DivisionHelpAndSupport.defaultProps = {
  viewer: undefined,
};

export default DivisionHelpAndSupport;
