import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPage } from 'Common/Data/App/appSections';
import {
  CARD_AGREED_DIVISION,
  CARD_BUTTON,
  CARD_COMPONENT,
  CARD_CONTINUE,
  CARD_CUSTOM,
  CARD_DIVIDING_ASSETS,
  CARD_INFO,
  CARD_JURISDICTION,
  CARD_NORMAL,
  CARD_SUBHEADING,
  CARD_VIEW,
  PAGE_CONSENT_ORDERS_COMPLETED,
  SECTION_CONSENT_ORDERS,
} from 'Common/constants';
import { ODRS_CONSENT_ORDERS_COMPLETE_ROUTE } from 'Common/routes';
import DashboardContent from 'App/UI/Dashboard/common/DashboardContent';
import {
  generateAgreedDivisionCard,
  generateButtonCard,
  generateContinueCard,
  generateDividingAssetsCard,
  generateInfoCard,
  generateNormalCard,
  generateViewCard,
  generateComponentCard,
  generateCustomCard,
} from 'App/UI/Dashboard/ConsentOrders/StateCards';
import { generateSubHeading } from 'Common/UI/Dashboard/common/index';
import { DashboardPageProps } from 'Common/Data/Types/types';
import {
  CardAgreedDivision,
  CardButton,
  CardComponent,
  CardContinue,
  CardCustom,
  CardDividingAssets,
  CardInfo,
  CardNormal,
  CardView,
  EphemeralStateCard,
  QuestionStateCard,
} from 'Common/Data/Types/appSections';

const Complete: React.FC<DashboardPageProps> = ({
  matter,
  navigate,
  appState,
}) => {
  const location = useLocation();

  const customNavigate = (route: string) =>
    navigate(route, {
      backRoute: ODRS_CONSENT_ORDERS_COMPLETE_ROUTE,
      location,
    });

  const complete = getPage(
    SECTION_CONSENT_ORDERS,
    PAGE_CONSENT_ORDERS_COMPLETED
  );

  return (
    <DashboardContent
      pageData={complete}
      navigate={customNavigate}
      matter={matter}
      cardHandlers={{
        [CARD_NORMAL]: data =>
          generateNormalCard(data as QuestionStateCard<CardNormal>, matter),
        [CARD_CUSTOM]: data =>
          generateCustomCard(data as QuestionStateCard<CardCustom>, matter),
        [CARD_COMPONENT]: data =>
          generateComponentCard(data as EphemeralStateCard<CardComponent>),
        [CARD_SUBHEADING]: data => generateSubHeading(data),
        [CARD_DIVIDING_ASSETS]: data =>
          generateDividingAssetsCard(
            data as EphemeralStateCard<CardDividingAssets>
          ),
        [CARD_AGREED_DIVISION]: data =>
          generateAgreedDivisionCard(
            data as EphemeralStateCard<CardAgreedDivision>
          ),
        [CARD_VIEW]: data =>
          generateViewCard(data as QuestionStateCard<CardView>, matter),
        [CARD_CONTINUE]: data =>
          generateContinueCard(data as QuestionStateCard<CardContinue>, matter),
        [CARD_JURISDICTION]: data =>
          generateNormalCard(data as QuestionStateCard<CardNormal>, matter),
        [CARD_INFO]: data =>
          generateInfoCard(data as EphemeralStateCard<CardInfo>),
        [CARD_BUTTON]: data =>
          generateButtonCard(data as EphemeralStateCard<CardButton>, matter),
      }}
      appState={appState}
    />
  );
};

export default Complete;
