import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  STATUS_APPROVED,
  STATUS_REMOVED,
  SUPERANNUATION_REQUEST_BALANCE_COMPLETED_REQUESTER,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import { reduxStore, useAppSelector } from 'App/State/Store';
import { definitelyGetCard } from 'Common/Data/App/appSections';
import { CardYourSuperannuation } from 'Common/Data/Types/appSections';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import getPartyKeys from 'Common/Utils/getPartyKeys';

export const superannuationBalanceRequestCompletedBothPartiesFlags: AppState = {
  id: 'superannuationBalanceRequestCompletedBothParties',
  description:
    'The requester has completed the balance request flow and can view/approve the superannuation statement',
  check: () => {
    const {
      matter: { items },
    } = reduxStore.getState();

    // get all open super cards created by either party that have had the form 6 response letter uploaded
    const superCardsWithBalanceRequestCompleted = items.filter(
      item =>
        ![STATUS_APPROVED, STATUS_REMOVED].includes(item.status) &&
        item.BaseSection === 'yoursuperannuation' &&
        item.__flags?.letterUploaded
    );

    // if there are no cards found, don't show this banner
    if (!superCardsWithBalanceRequestCompleted) {
      return false;
    }

    const haveAllCardsBannersBeenDismissed =
      superCardsWithBalanceRequestCompleted.every(({ SectionID }) =>
        sessionStorageLoad(
          `SuperannuationRequestBalanceCompleted_${SectionID}_Dismissed`
        )
      );

    // if the banner for every card found has previously been dismissed, don't show this banner
    // note: we are putting this check in here rather than in the bannerTopState flag like normal because
    // the we want to skip this app state if it has been dismissed, not just trigger this app state but hide the banner
    return !haveAllCardsBannersBeenDismissed;
  },
  flags: {
    appState: SUPERANNUATION_REQUEST_BALANCE_COMPLETED_REQUESTER,
  },
};

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperannuationBalanceRequestCompletedBothParties: React.FC<Props> =
  ({ otherName, updateAppState }) => {
    const { items } = useAppSelector(state => state.matter);

    const allSuperCardsWithCancelledBalanceRequest = items.filter(
      item =>
        ![STATUS_APPROVED, STATUS_REMOVED].includes(item.status) &&
        item.BaseSection === 'yoursuperannuation' &&
        item.__flags?.letterUploaded
    );

    // find the first card that has not been dismissed
    const superCardWithCancelledBalanceRequest =
      allSuperCardsWithCancelledBalanceRequest.find(
        ({ SectionID }) =>
          !sessionStorageLoad(
            `SuperannuationRequestBalanceCompleted_${SectionID}_Dismissed`
          )
      );

    if (!superCardWithCancelledBalanceRequest) {
      console.warn('No super card found with cancelled request flag');

      return null;
    }

    const card = definitelyGetCard<CardYourSuperannuation>(
      superCardWithCancelledBalanceRequest.SectionID
    );

    const { data, cardID } = card;

    const { creator } = data;

    const selfParty = getPartyKeys().self;

    // my superannuation
    if (selfParty === creator) {
      return (
        <>
          <SpacedBox marginBottom={8}>
            <Heading2MarginTopNone>
              {otherName} has uploaded the letter they received from{' '}
              {data.superannuationName}
            </Heading2MarginTopNone>
            <Paragraph>
              {otherName} will review your superannuation statement and approve
              or send you a message.
            </Paragraph>
          </SpacedBox>
          <GenericButton
            fullWidth
            onClick={() => {
              sessionStorageSave(
                `SuperannuationRequestBalanceCompleted_${cardID}_Dismissed`,
                true
              );
              updateAppState();
            }}
            small
          >
            Got it
          </GenericButton>
        </>
      );
    }

    // their superannuation
    return (
      <div data-cy="banner-request-balance-complete">
        <SpacedBox marginBottom={8}>
          <Heading2MarginTopNone>
            You can now view {otherName}’s superannuation statement for their{' '}
            {data.superannuationName} account and approve or send them a message
          </Heading2MarginTopNone>
          <Paragraph>
            View the statement below and approve or send them a message to
            continue
          </Paragraph>
        </SpacedBox>
        <GenericButton
          fullWidth
          onClick={() => {
            sessionStorageSave(
              `SuperannuationRequestBalanceCompleted_${cardID}_Dismissed`,
              true
            );
            updateAppState();
          }}
          small
          data-cy="dismiss-button"
        >
          Got it
        </GenericButton>
      </div>
    );
  };
