import React, { useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import {
  ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
  ODRS_PAYMENT_PAY_FORM_ROUTE,
  ODRS_PAYMENT_COMPLETE_ROUTE,
  ODRS_PAYMENT_PAST_PAYMENTS_ROUTE,
  ODRS_PAYMENT_VIEW_PRICING_ROUTE,
} from 'Common/routes';
import {
  isPaymentActionNeeded,
  getCompletedOrders,
} from 'Common/Utils/Payments';

export const PaymentContext = createContext();

export const PaymentProvider = ({
  children,
  navigate,
  matter,
  isViewingPaymentConfirmation,
}) => {
  const { pathname: currentRoute } = useLocation();

  const isAwaitingPayment = isPaymentActionNeeded(matter);
  const isInPaymentFlow = isAwaitingPayment || isViewingPaymentConfirmation;
  const hasMadePastPayments = getCompletedOrders(matter).length > 0;

  // if no payments are due and no past payments have been made, redirect the user to the `view pricing page`
  // if no payments are due and at least one past payment has been made, redirect the user to the `past payments`
  useEffect(() => {
    if (
      !isInPaymentFlow &&
      !hasMadePastPayments &&
      currentRoute !== ODRS_PAYMENT_VIEW_PRICING_ROUTE
    ) {
      navigate(ODRS_PAYMENT_VIEW_PRICING_ROUTE, undefined, true);
    }

    if (
      !isInPaymentFlow &&
      hasMadePastPayments &&
      currentRoute !== ODRS_PAYMENT_PAST_PAYMENTS_ROUTE
    ) {
      navigate(ODRS_PAYMENT_PAST_PAYMENTS_ROUTE, undefined, true);
    }
  }, []);

  let index = 0;

  const pages = [];
  let withNav = true;

  if (isInPaymentFlow) {
    if (hasMadePastPayments) {
      pages.push({
        title: 'Past payments',
        route: ODRS_PAYMENT_PAST_PAYMENTS_ROUTE,
      });
    }

    pages.push({
      title: 'View pricing',
      route: ODRS_PAYMENT_VIEW_PRICING_ROUTE,
    });

    pages.push({
      title: 'Review Order',
      route: ODRS_PAYMENT_REVIEW_ORDER_ROUTE,
      index: (index += 1),
    });

    pages.push({
      title: 'Payment',
      route: ODRS_PAYMENT_PAY_FORM_ROUTE,
      index: (index += 1),
    });

    pages.push({
      title: 'Complete',
      route: ODRS_PAYMENT_COMPLETE_ROUTE,
      index: (index += 1),
      disabled: true,
    });
  } else if (!hasMadePastPayments) {
    withNav = false;
  }

  const getPageTitle = route => {
    const page = pages.find(p => p.route === route);

    if (page) {
      return `${page.index ? `${page.index}. ` : ''}${page.title}`;
    }

    return '';
  };

  return (
    <PaymentContext.Provider
      value={{
        pages,
        getPageTitle,
        withNav,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  navigate: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
  isViewingPaymentConfirmation: PropTypes.bool,
};

PaymentProvider.defaultProps = {
  navigate: () => {},
  isViewingPaymentConfirmation: false,
};

export const PaymentConsumer = ({ children }) => (
  <PaymentContext.Consumer>{children}</PaymentContext.Consumer>
);

PaymentConsumer.propTypes = {
  children: PropTypes.node.isRequired,
};
