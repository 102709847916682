import { STATUS_APPROVED } from 'Common/constants';
import { CardAny } from 'Common/Data/Types/appSections';
import {
  REMOVE_STATEMENT,
  REOPEN_STATEMENT,
} from 'Common/UI/OptionsDrawer/constants';

const generateOptionsForCard = (card: CardAny): [string[], boolean] => {
  const options: string[] = [];

  // make sure it is a valid card type
  if ('cardForParty' in card) {
    const { isRepeatable, status, cannotBeRemoved, cardForParty } = card;

    const currentPartyCanManageCard = cardForParty !== 'other';

    if (isRepeatable && currentPartyCanManageCard && !cannotBeRemoved) {
      options.push(REMOVE_STATEMENT);
    }

    if (status === STATUS_APPROVED) {
      options.push(REOPEN_STATEMENT);
    }

    return [options, options.length > 0];
  }

  return [options, false];
};

export default generateOptionsForCard;
