import React from 'react';

import { PARTY_A, PARTY_B } from 'Common/constants';
import calculateMultiItemsValue from 'Common/Utils/calculateMultiItemsValue';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import Matter from 'Common/Data/Types/matter';

export const sharedFinancesLabel = 'Shared Finances';

export const sharedFinancesQuestions: QuestionAny[] = [
  {
    name: 'intro',
    heading: ({ self: { firstname } }: Matter) => (
      <H1Conversational>
        Hi {firstname}, we’re going to ask you about the money and property you
        shared with your former partner.
      </H1Conversational>
    ),
    content: () => (
      <Paragraph>
        These questions are important because we will base our suggestions for a
        fair division on the information you give.
      </Paragraph>
    ),
    hint: '',
    type: 'intro',
    defaultValue: '',
    index: 0,
  },
  {
    name: 'intro2',
    label: 'Before you get started',
    content: () => (
      <Paragraph large>
        All the amounts you enter should use your most current values.
      </Paragraph>
    ),
    hint: '',
    type: 'info',
    defaultValue: '',
    index: 1,
    submitButtonLabel: 'I understand',
  },
  {
    name: 'sharedSavings',
    label: () =>
      'Please list the name of your joint accounts bank accounts, and the value in each of them.',
    hint: '',
    type: 'multiItems',
    defaultValue: '',
    index: 2,
    optional: true,
    formAttributes: {
      popupTitle: 'List accounts',
      hintText:
        'List the name of your bank, the account name, and the amount in each account.',
      textLabel: 'Bank name and account',
      textPlaceholder: 'E.g Westpac/Saver',
    },
  },

  // Shared Asset
  {
    name: 'sharedAssets',
    label:
      "Please list any other major money or property assets (such as shares, cash and household items) that you own jointly and haven't already included in the 'Your Finances' section.",
    hint: '',
    type: 'multiItems',
    defaultValue: undefined,
    optional: true,
    formAttributes: {
      popupTitle: 'List items',
      textLabel: 'Asset Type',
    },
    index: 3,
    help: {
      label: 'What should I list?',
      content: (
        <>
          <H1Conversational>What should I list?</H1Conversational>
          <Paragraph large>
            Please list any other major money or property assets you had when
            you separated that you haven't listed already.
          </Paragraph>
          <Paragraph large>
            You don't need to list any shared vehicles here. You can add those
            later in the Vehicles section.
          </Paragraph>
          <Paragraph large>
            You should list any other major money or property assets that you
            haven't already listed. For example:
          </Paragraph>
          <ul>
            <li>
              any life insurance policies that have a cash surrender value, this
              is the amount of money the policyholder will get if they cancel
              the policy. Please give the insurance company, policy number and
              the cash surrender value of your share in dollars.
            </li>
            <li>
              any interest in any other property, including leased property.
            </li>
            <li>
              any other financial resource, for example, are you expecting to
              get any money from a personal injury claim or court case or any
              property from an inheritance when someone passes away? If yes,
              please give details.
            </li>
          </ul>
        </>
      ),
    },
  },
  // Shared Debt
  {
    name: 'sharedDebts',
    label:
      "Please list any other debts you owe jointly (not including mortgages) that you haven't already included in the 'Your Finances' section.",
    hint: '',
    type: 'multiItems',
    defaultValue: undefined,
    optional: true,
    formAttributes: {
      popupTitle: 'List Debts',
      buttonText: 'Enter debts',
      textLabel: 'Debt Type',
    },
    index: 4,
    help: {
      label: 'What should I list?',
      content: (
        <>
          <H1Conversational>What should I list?</H1Conversational>
          <Paragraph large>
            You should list any debts that you haven't already listed. For
            example:
          </Paragraph>
          <ul>
            <li>
              any hire-purchase agreements (please include the name of the
              lender, a description of the property or item and your share of
              the amount owned).
            </li>
          </ul>
        </>
      ),
    },
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 5,
  },
];

export const sharedFinancesStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const { itemHistory } = matter;

  const itemDiff = getUpdatedItemValues(data, itemHistory, [
    'sharedSavings',
    'sharedAssets',
    'sharedDebts',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    sharedFinancesQuestions,
    data,
    hideSensitive
  );

  const partyNames = {
    A: getFirstName(matter.partyA, PARTY_A, hideSensitive),
    B: getFirstName(matter.partyB, PARTY_B, hideSensitive),
  };

  const names = `${partyNames.A} and ${partyNames.B}`;

  // "Name and Name have amount in their joint account, including name (number). The have amount in cash, shares, and household items that they haven’t listed previously including name (number). They have amount in debts they haven’t listed previously including name (number)."

  // Joint savings
  const sharedSavingsItems = calculateMultiItemsValue(data.sharedSavings);

  const sharedSavings =
    (() => {
      if (!sharedSavingsItems.hasItems) {
        return highlight`${updated(
          'sharedSavings',
          redact('sharedSavings', 'do not')
        )} have any joint savings.`;
      }

      return highlight`have joint savings, cash, shares, and household items that they haven't listed previously, including ${updated(
        'sharedSavings',
        redact('sharedSavings', sharedSavingsItems.string)
      )}.`;
    })() || '';

  // Assets
  const sharedAssetsItems = calculateMultiItemsValue(data.sharedAssets);

  const sharedAssets =
    (() => {
      if (!sharedAssetsItems.hasItems) {
        return highlight`They ${updated(
          'sharedAssets',
          redact('sharedAssets', 'do not')
        )} have any cash, shares, or household items that they haven't listed previously.`;
      }

      return highlight`They have cash, shares, and household items that they haven't listed previously including ${updated(
        'sharedAssets',
        redact('sharedAssets', sharedAssetsItems.string)
      )}.`;
    })() || '';

  // sharedDebts
  const sharedDebtsItems = calculateMultiItemsValue(data.sharedDebts);

  const sharedDebts =
    (() => {
      if (!data.sharedDebts) {
        return highlight`They ${updated(
          'sharedDebts',
          redact('sharedDebts', 'do not')
        )} have debts they haven't listed previously.`;
      }

      return highlight`They have debts that they haven't listed previously including ${updated(
        'sharedDebts',
        redact('sharedDebts', sharedDebtsItems.string)
      )}.`;
    })() || '';

  if (plainText) {
    return `${names} ${sharedSavings} ${sharedAssets} ${sharedDebts}`;
  }

  return (
    <>
      {names} {sharedSavings} {sharedAssets} {sharedDebts}
    </>
  );
};
