import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Theme, orangeTheme } from 'Common/Utils/theme';

import WarningIcon from 'Common/Assets/images/icon-warning-brown.svg';

const Wrap = styled.div<{
  theme: Theme;
  thin: boolean;
}>`
  background-color: ${({ theme }) => theme.backgroundColour};
  padding: ${({ theme }) => `${theme.padding.small}px`};
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;

  width: ${({ thin }) => (thin ? '400px' : 'auto')};
`;

const Icon = styled.img`
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  flex: 0;
`;

const CardWarning: React.FC<{
  children: React.ReactNode;
  thin?: boolean;
  className?: string;
  dataCyType?: string;
}> = ({ className, children, thin = false, dataCyType }) => (
  <ThemeProvider theme={orangeTheme}>
    <Wrap
      className={className}
      thin={thin}
      data-cy="warning-message"
      data-cy-type={dataCyType}
    >
      <Icon src={WarningIcon} />
      <div>{children}</div>
    </Wrap>
  </ThemeProvider>
);

export default CardWarning;
