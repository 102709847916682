import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import formatCurrency from 'Common/Utils/formatCurrency';
import DiviProps from 'Common/Data/App/diviProperties';
import capitaliseText from 'Common/Utils/capitaliseText';
import { Heading4, HeadingSpacedContainer } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Label, HighlightedLabel } from 'Common/UI/Text/Label';
import Appears from 'Common/UI/Layout/Appears';
import getDate from 'Common/Utils/getDate';
import { generateMessage } from 'App/UI/Messaging/Messages';
import getYouOrThem from 'Common/Utils/getYouOrThem';
import Indicator from './Indicator';

const YouReceiveIndicatorWrap = styled.div`
  display: block;
  margin-bottom: ${({ theme }) => theme.padding.medium}px;
`;

const getHeadingMessage = (party, makingOffer) => {
  if (makingOffer) return 'You are making an offer';
  if (party === 'suggestedDivision') return "amica's suggestion";
  return `${capitaliseText(getYouOrThem(party))} made an offer`;
};

const YouReceiveIndicator = ({
  ratio,
  message,
  toggleMessages,
  totalAssetPool,
  party,
  date,
  ref,
  displayValue,
  makingOffer = false,
  theme,
}) => {
  const { getOfferColour } = DiviProps();

  // Heading
  const headingMessage = getHeadingMessage(party, makingOffer);

  const historyMessage = {
    ...message,
    owner: party,
  };

  const indicatorColours = getOfferColour(party, theme);

  const indicatorSize = 16;
  const indicatorRatio =
    typeof displayValue === 'number' ? displayValue : ratio;
  const youReceivePercentage = Math.round(indicatorRatio * 100);

  return (
    <YouReceiveIndicatorWrap>
      <HeadingSpacedContainer
        marginTop="0px"
        marginBottom={`${theme.padding.small}px`}
      >
        <Heading4>{headingMessage}</Heading4>
        <Paragraph
          color={
            theme.t === 'theme'
              ? theme.colours.mediumGrey
              : theme.colours.lightGrey
          }
        >
          {date}
        </Paragraph>
      </HeadingSpacedContainer>
      <Indicator
        ref={ref}
        primary={ratio}
        indicatorColour={indicatorColours.indicatorColour}
        indicatorTextColour={indicatorColours.textColour}
        size={indicatorSize}
      />
      <HeadingSpacedContainer marginTop="12px" marginBottom="24px">
        <Label>
          You receive: <strong>{youReceivePercentage}%</strong>
        </Label>
        <HighlightedLabel>
          {formatCurrency(Math.round(totalAssetPool * indicatorRatio))}
        </HighlightedLabel>
      </HeadingSpacedContainer>
      {message && (
        <Appears on={toggleMessages}>{generateMessage(historyMessage)}</Appears>
      )}
    </YouReceiveIndicatorWrap>
  );
};

YouReceiveIndicator.propTypes = {
  ...Indicator.propTypes,
  message: PropTypes.shape({}),
  toggleMessages: PropTypes.bool,
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  displayValue: PropTypes.number,
  totalAssetPool: PropTypes.number.isRequired,
  party: PropTypes.oneOf(['suggestedDivision', 'A', 'B']),
  date: PropTypes.string,
};

YouReceiveIndicator.defaultProps = {
  ratio: 0,
  message: undefined,
  displayValue: undefined,
  toggleMessages: true,
  party: undefined,
  date: getDate().shortFormat(),
};

export default withTheme(YouReceiveIndicator);
