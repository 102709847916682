import React from 'react';
import CardQuestions from 'App/UI/Questions/CardQuestionsFlow';
import MatterPropsJS from 'Common/Utils/MatterProps';
import MatterGuard from '../Guard/MatterGuard';

const ODRSAboutYou = props => (
  <MatterGuard>
    <CardQuestions baseID="aboutyou" index="self" {...props} />
  </MatterGuard>
);

export default ODRSAboutYou;
