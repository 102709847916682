import React from 'react';
import PropTypes from 'prop-types';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { DOCUMENT_TYPES_OTHER_NOT_READY } from 'Common/constants';
import {
  areThereDocumentsWhereOtherNotReady,
  documentCheckKeys,
  documentsWhereOtherNotReady,
} from 'Common/Utils/documentHelpers';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { clearAllDismissSessionStorageProperty } from 'Common/Utils/sessionStorage';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';

export const documentTypesOtherNotReadyFlags = {
  id: 'docTypesOtherNotReady',
  description: 'Other is not ready for document types',
  check: () => areThereDocumentsWhereOtherNotReady(),
  flags: {
    appState: DOCUMENT_TYPES_OTHER_NOT_READY,
  },
  usedAsNotificationState: true,
};

const DocumentTypesOtherNotReady = ({
  otherName,
  resetPropertyAgreementState,
}) => {
  const { withLoading } = usePromiseWithLoading();

  return (
    <SpacedBox marginBottom="xxsmall">
        <Heading2MarginTopNone>
          {
            {
              [documentCheckKeys.BOTH]: `${otherName} is not ready to create an agreement document and consent orders yet`,
              [documentCheckKeys.PROPERTY_AGREEMENT]: `${otherName} is not ready to create an agreement document yet`,
              [documentCheckKeys.CONSENT_ORDERS]: `${otherName} is not ready to start the consent orders yet`,
            }[documentsWhereOtherNotReady()]
          }
        </Heading2MarginTopNone>
        <Paragraph large>
          You both need to confirm that you are ready to create your document as
          you can only do this once.
        </Paragraph>
        <GenericButton
          small
          fullWidth
          onClick={async () => {
            clearAllDismissSessionStorageProperty();
            await withLoading(resetPropertyAgreementState);
          }}
        >
          Got it
        </GenericButton>
      </SpacedBox>
  );
};

DocumentTypesOtherNotReady.propTypes = {
  otherName: PropTypes.string.isRequired,
  resetPropertyAgreementState: PropTypes.func.isRequired,
};

export default DocumentTypesOtherNotReady;
