import React from 'react';

import { ODRS_CONSENT_ORDERS_COMPLETE_ROUTE } from 'Common/routes';
import { Success } from 'Common/UI/Layout/Completed';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

const PFLSentConfirmation: React.FC = () => (
  <Success buttonRoute={ODRS_CONSENT_ORDERS_COMPLETE_ROUTE} buttonLabel="Done">
    <H1Conversational>
      Great, you’ve sent the draft minute of consent
    </H1Conversational>

    <Paragraph>
      Once the superannuation fund have reviewed your draft minutes of consent,
      you can make edits to your application if required or confirm your final
      orders.
    </Paragraph>
  </Success>
);

export default PFLSentConfirmation;
