import React, { useContext } from 'react';
import styled from 'styled-components';

import MatterProps from 'Common/Utils/MatterProps';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import FlexScroller from 'Common/UI/Layout/FlexScroller';
import { Row } from 'Common/UI/Layout/Flex';
import Appears from 'Common/UI/Layout/Appears';
import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import RoundButton from 'Common/UI/Button/RoundButton';
import GenericButton from 'Common/UI/Button/GenericButton';
import Calendar from './Calendar';
import { ScheduleContext } from '../ScheduleContext';

const ScheduleHeader = styled.div`
  padding: ${props => props.theme.padding.medium}px;
  position: relative;
`;

const ScheduleHeading = styled(Heading2)`
  margin: 0;
`;

const StyledFlexScroller = styled(FlexScroller)`
  padding: ${props => props.theme.padding.medium}px;

  ${props => props.withDrawer && 'padding-bottom: 180px;'}
`;

const NextButton = styled(RoundButton)`
  margin-top: ${props => props.theme.padding.xlarge}px;
`;

const StyledGenericButton = styled(GenericButton)`
  width: 100%;
`;

const DrawerParagraph = styled(Paragraph)`
  margin-top: 0;
`;

const SchedulePicker = () => {
  const context = useContext(ScheduleContext);

  const { isScheduleEmpty, screen, setScreen, onSubmit } = context;

  const { withLoading } = usePromiseWithLoading();

  return (
    <>
      <ScheduleHeader>
        <ScheduleHeading>
          {screen === 'edit' ? 'Select your nights' : 'Your Schedule'}
        </ScheduleHeading>
      </ScheduleHeader>
      <StyledFlexScroller withDrawer={screen === 'confirm'}>
        <ResponsiveTextContainer>
          <Calendar />
          <Appears on={screen === 'confirm'}>
            <RoundButton small subtle onClick={() => setScreen('edit')}>
              Edit
            </RoundButton>
          </Appears>
          <Appears on={screen === 'edit'}>
            <NextButton
              data-cy="scheduler-next-button"
              onClick={() => setScreen('confirm')}
              disabled={isScheduleEmpty}
            >
              Next
            </NextButton>
          </Appears>
        </ResponsiveTextContainer>
      </StyledFlexScroller>
      <FlexDrawer isVisible={screen === 'confirm'}>
        <ResponsiveTextContainer>
          <DrawerParagraph>
            {MatterProps('other.firstname')} will either approve your suggested
            schedule or ask you to change it.
          </DrawerParagraph>
          <Row>
            <StyledGenericButton
              onClick={() => withLoading(onSubmit)}
              data-cy="scheduler-send-button"
            >
              Send to {MatterProps('other.firstname')}
            </StyledGenericButton>
          </Row>
        </ResponsiveTextContainer>
      </FlexDrawer>
    </>
  );
};

export default SchedulePicker;
