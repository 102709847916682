import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import {
  RELATIONSHIP_DASHBOARD_COMPLETED,
  RELATIONSHIP_DASHBOARD_COMPLETED_AND_LOCKED,
} from 'Common/constants';
import RelationshipDashboardCompleted from 'Common/UI/Banners/RelationshipDashboardCompleted';

const ParentingPlanBannerContent = ({ navigate, appState }) => {
  switch (appState) {
    case RELATIONSHIP_DASHBOARD_COMPLETED:
    case RELATIONSHIP_DASHBOARD_COMPLETED_AND_LOCKED:
      return <RelationshipDashboardCompleted navigate={navigate} />;
    default:
      return null;
  }
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
});

ParentingPlanBannerContent.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentingPlanBannerContent);
