import React from 'react';

import isEmailAddress from 'Common/Utils/isEmailAddress';
import EvaluatePasswordStrength from 'Common/Utils/EvaluatePasswordStrength';
import isMobile from 'Common/Utils/isMobile';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { userCheckByEmail } from 'App/State/UserActions';
import { checkInviteEmailAction } from 'App/State/MatterActions';
import {
  ODRS_INVITE_PARTNER_BLOCK_ROUTE,
  ODRS_RE_SEND_INVITE_ROUTE,
} from 'Common/routes';
import { reduxStore } from 'App/State/Store';

const qLegalName = {
  name: 'legalname',
  label: "What's your legal name?",
  type: 'legalname',
  validate: legalname =>
    legalname.firstname && legalname.lastname ? [true] : [false],
  help: {
    label: 'What is a legal name?',
    content: (
      <>
        <H1Conversational>What is a legal name?</H1Conversational>

        <Paragraph>
          A legal name is your name as it appears on your Birth Certificate or
          Change of Name Certificate.
        </Paragraph>
      </>
    ),
  },
};

const qEmail = {
  name: 'email',
  label: 'What is your email address?',
  hint: 'Email address',
  placeholder: 'Enter email address',
  type: 'email',
  validate: email =>
    isEmailAddress(email) ? [true] : [false, 'Invalid email address'],
  help: {
    label: 'Why are you asking?',
    content: (
      <>
        <H1Conversational>Why are you asking?</H1Conversational>

        <Paragraph>
          amica will use this email address to contact you throughout the
          process. Remember, this email will be how you sign in to amica in the
          future.
        </Paragraph>
      </>
    ),
  },
};

const qPhoneNumber = {
  name: 'phone_number',
  label: 'What is your mobile number?',
  hint: 'Your mobile number',
  type: 'phone_number',
  validate: mobile =>
    isMobile(mobile) ? [true] : [false, 'Invalid mobile number'],
  help: {
    label: 'Why are you asking?',
    content: (
      <>
        <H1Conversational>Why are you asking?</H1Conversational>

        <Paragraph>
          We will be sending you a verification code to authorise your account.
          This is will also help keep your account private.
        </Paragraph>
      </>
    ),
  },
};

export const signupPartyA = [
  {
    ...qLegalName,
    index: 0,
  },
  {
    ...qEmail,
    onBeforeNext: ({ email }, navigate) =>
      new Promise((resolve, reject) => {
        checkInviteEmailAction(email)
          .then(({ status, result }) => {
            if (status === 500) throw Error('Something has gone wrong');
            if (status === 200) {
              const { InviteID, otherfirstname } = result;
              navigate(ODRS_RE_SEND_INVITE_ROUTE, {
                email,
                otherName: otherfirstname,
                InviteID,
              });
              reject(Error('Redirecting to resend invite route'));
            }
            resolve();
          })
          .catch(resolve);
      }),
    index: 1,
  },
  {
    ...qPhoneNumber,
    index: 2,
  },
  {
    name: 'password',
    label: 'Create a password',
    hint: 'Password',
    type: 'password',
    showConfirmField: true,
    validate: password => {
      const isPasswordValid = EvaluatePasswordStrength(password.password).pass;

      if (!isPasswordValid) {
        return [false];
      }

      return password.password === password.passwordconfirm
        ? [true]
        : [false, 'The passwords you entered do not match'];
    },
    index: 3,
  },
];

// Edit profile is signup without password.
export const editProfile = [
  {
    ...qLegalName,
    index: 0,
  },
  {
    ...qEmail,
    index: 1,
  },
  {
    ...qPhoneNumber,
    index: 2,
  },
  {
    name: 'notifications',
    label: 'Would you like notifications sent to your email?',
    description:
      'You will need to verify your email to send notifications. Your email can be verified from the profile screen',
    type: 'yesno',
    defaultValue: '',
    index: 3,
  },
];

export const signupPartnerInvite = [
  {
    name: 'partnerName',
    label: "What is your former partner's name?",
    hint: 'First name',
    placeholder: 'Enter their first name',
    type: 'text',
    index: 0,
    validate: partnerName =>
      partnerName && partnerName.length > 0
        ? [true]
        : [false, "Please enter your former partner's name"],
  },
  {
    name: 'partnerEmail',
    label: "What is your former partner's email address?",
    hint: 'Email address',
    placeholder: 'Enter their email address',
    type: 'email',
    validate: email =>
      isEmailAddress(email) ? [true] : [false, 'Invalid email address'],
    index: 1,
  },
  {
    name: 'partnerEmailConfirm',
    label: 'Please confirm their email address',
    hint: 'Email address',
    placeholder: 'Enter their email address',
    type: 'email',
    validate: (email, values) =>
      email === values.partnerEmail
        ? [true]
        : [false, "Sorry, your email doesn't match"],
    index: 2,
    onBeforeNext: ({ partnerName: otherName, partnerEmail: email }, navigate) =>
      reduxStore
        .dispatch(userCheckByEmail(email))
        .unwrap()
        .then(({ status }) => {
          if (status === 500) throw Error('Something has gone wrong');
          if (status === 200) {
            navigate(ODRS_INVITE_PARTNER_BLOCK_ROUTE, { otherName, email });
            // Need to reject question flow continues
            throw new Error('Redirecting to partner block route');
          }
        }),
  },
];

export const signupPartyB = [
  {
    ...qLegalName,
    index: 0,
  },
  {
    name: 'email',
    label: 'What is your email address?',
    hint: 'Email address',
    placeholder: 'Enter email address',
    type: 'email',
    validate: email =>
      isEmailAddress(email) ? [true] : [false, 'Invalid email address'],
    index: 1,
    help: {
      label: 'Why are you asking?',
      content: (
        <>
          <H1Conversational>Why are you asking?</H1Conversational>

          <Paragraph>
            amica will use this email address to contact you throughout the
            process. Remember, this email will be how you sign in to amica in
            the future.
          </Paragraph>
        </>
      ),
    },
  },
  {
    name: 'phone_number',
    label: 'What is your mobile number?',
    hint: 'Your mobile number',
    type: 'phone_number',
    validate: mobile =>
      isMobile(mobile) ? [true] : [false, 'Invalid mobile number'],
    index: 2,
  },
  {
    name: 'password',
    label: 'Create a password',
    hint: 'Password',
    type: 'password',
    showConfirmField: true,
    validate: password => {
      const isPasswordValid = EvaluatePasswordStrength(password.password).pass;

      if (!isPasswordValid) {
        return [false];
      }

      return password.password === password.passwordconfirm
        ? [true]
        : [false, 'The passwords you entered do not match'];
    },
    index: 3,
  },
];
