import React from 'react';
import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFullName,
} from 'Common/Utils/statementHelpers';

import MatterProps from 'Common/Utils/MatterProps';
import Paragraph from 'Common/UI/Text/Paragraph';
import { isDefacto as checkDefacto } from 'Common/Utils/consentOrderPageChecks';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import {
  jurisdictionStateOptions,
  getCourtLocationByState,
} from 'Common/Utils/jurisdictionHelpers';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { HelpLineContent } from './HelpLineContent';

// Keys
const stateResideQuestionsKey = 'stateReside';
const isDefactoFcaQuestionsKey = 'isDefactoFca';

export const jurisdictionQuestions = (): QuestionAny[] => {
  const isDefacto = checkDefacto();

  /**
   * Start Intro
   * */

  const startIntroQuestions: QuestionAny[] = [
    {
      name: 'startIntro1',
      label: `
        This section is about selecting the place where you will lodge 
        (file) your application for consent orders. Select the state or 
        territory where you would like to file your application.
      `,
      content: (
        <Paragraph large>
          {`
            Your selection will be shared with ${MatterProps(
              'other.firstname',
              'Your Former Partner'
            )}. They will be able to 
            accept your selection or suggest a different location and explain 
            their reasons.
            `}
        </Paragraph>
      ),
      hint: '',
      type: 'info',
      submitButtonLabel: 'Continue',
      help: {
        label: 'Learn more',
        content: (
          <>
            <Paragraph large>
              {`One way that you can select where to file your application, is 
              to select the state where you “ordinarily reside” as this is one 
              of the grounds of eligibility in all family law jurisdictions in 
              Australia.`}
            </Paragraph>
            <Paragraph large>
              {`In some situations there are reasons why an application might 
              be eligible to be filed in a different jurisdiction. `}
              <HelpLineContent />
            </Paragraph>
            <Paragraph large>
              {`Family law in Australia is federal law, which means that the 
              law is the same in all states of Australia, except in relation 
              to de facto relationships in Western Australia which are covered 
              by Western Australian law.`}
            </Paragraph>
          </>
        ),
      },
      index: 0,
    },
    {
      name: 'startIntro2',
      label: 'A few more things you should know',
      content: (
        <>
          <Paragraph large>
            {`
            All applications for consent orders in Australia need to be filed 
            with the Federal Circuit and Family Court of Australia (the FCFCOA), except for those that should be 
            filed in the Family Court of Western Australia.
            `}
          </Paragraph>
          <Paragraph large>
            {`
            You will be able to check the criteria in the next few screens if 
            you think that you might need to file in Western Australia. If you 
            select the Family Court of Western Australia it will be reflected 
            on your consent order documents.
            `}
          </Paragraph>
        </>
      ),
      hint: '',
      type: 'info',
      submitButtonLabel: 'Continue',
      index: 1,
    },
  ];

  /**
   * Which State Location
   * */

  const stateResideQuestions: QuestionAny[] = [
    {
      name: stateResideQuestionsKey,
      label: 'Select state where you ordinarily reside',
      hint: '',
      type: 'selectDropdown',
      options: () =>
        jurisdictionStateOptions.map(state => ({ label: state, value: state })),
      help: {
        label: 'Learn more',
        content: (
          <>
            <Paragraph large>
              {`One way that you can select where to file your application, is 
              to select the state where you “ordinarily reside” as this is one 
              of the grounds of eligibility in all family law jurisdictions in 
              Australia.`}
            </Paragraph>
            <Paragraph large>
              {`In some situations there are reasons why an application might 
              be eligible to be filed in a different jurisdiction. If you would 
              like to find out more about selecting a jurisdiction, you can 
              call the free `}
              <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
                Legal Help Line
              </ReferralLink>{' '}
              in your state.
            </Paragraph>
            <Paragraph large>
              {`Family law in Australia is federal law, which means that the 
              law is the same in all states of Australia, except in relation 
              to de facto relationships in Western Australia which are covered 
              by Western Australian law.`}
            </Paragraph>
          </>
        ),
      },
      index: 2,
    },
    {
      name: `${stateResideQuestionsKey}CourtLocation`,
      label: `
      In Western Australia the court where applications for consent orders 
      must be filed is the Family Court of Western Australia.
      `,
      type: 'info',
      setValue: 'Family Court WA',
      visible: (data: any) => data.stateReside === 'Western Australia',
      help: {
        label: 'Learn more',
        content: (
          <>
            <Paragraph large>
              {`Married couples who want to make property arrangements in 
              Western Australia are covered by the Family Law Act 1975 (Cwth).`}
            </Paragraph>
            <Paragraph large>
              {`De facto couples in Western Australia who want to make property 
              arrangements are covered by Western Australian law (the Family 
              Court Act 1979 (WA)).`}
            </Paragraph>
          </>
        ),
      },
      index: 3,
    },
    {
      name: `${stateResideQuestionsKey}CourtLocation`,
      label: data => `
        In ${data.stateReside} the court where applications 
        for consent orders must be filed is the Federal Circuit and Family Court of Australia (the FCFCOA).
      `,
      content: (
        <Paragraph large>
          {`Please select the court Registry where you want to file your 
            application for consent orders.`}
        </Paragraph>
      ),
      hint: '',
      type: 'selectDropdown',
      options: data =>
        getCourtLocationByState(data.stateReside).map(location => ({
          label: location,
          value: location,
        })),
      visible: (data: any) => data.stateReside !== 'Western Australia',
      help: {
        label: 'Learn more',
        content: (
          <Paragraph large>
            {`A Registry is the name for a court 'office'. The Federal Circuit and Family 
              Court of Australia (the FCFCOA) has registries in different locations, including 
              regional locations. The court requires applicants to indicate 
              their relevant registry on their application documents. You need 
              to select a Registry even if you intend to file your documents 
              electronically. amica will include the name of the registry that 
              you select on your application documents.`}
          </Paragraph>
        ),
      },
      index: 4,
    },
  ];

  /**
   * Question Split
   * */

  const isDefactoQuestion: QuestionAny = {
    name: 'isDefacto',
    label: isDefacto
      ? `Based on your previous answers, it looks like you were de facto. 
          We're going to ask you a few additional questions that relate to 
          the court's criteria for de facto applications.`
      : 'Based on your previous answers, it looks like you were married.',
    content: (
      <Paragraph large>
        If your marital status is incorrect, you can get{' '}
        <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
          help and support.
        </ReferralLink>
      </Paragraph>
    ),
    type: 'info',
    setValue: isDefacto,
    index: 5,
  };

  const isMarriedQuestions: QuestionAny[] = [
    {
      name: 'jurisdiction',
      label: `
        You have selected to file your application in Western Australia. 
        You will need to file your application documents in the Family Court of 
        Western Australia.
      `,
      type: 'info',
      setValue: 'WA',
      visible: (data: any) =>
        !isDefacto && data.stateReside === 'Western Australia',
      exitOnSubmit: true,
      index: 6,
    },
    {
      name: 'jurisdiction',
      label: data => `You have selected to file your application for consent 
        orders in ${data.stateResideCourtLocation}, ${data.stateReside}.`,
      content: (data: any) =>
        `The court in ${data.stateReside} where you need to 
        file your application is the Federal Circuit and Family Court of Australia.`,
      type: 'info',
      setValue: 'FCA',
      visible: (data: any) =>
        !isDefacto && data.stateReside !== 'Western Australia',
      exitOnSubmit: true,
      index: 7,
    },
  ];

  const defactoNotWA = (data: any) =>
    isDefacto && data.stateReside !== 'Western Australia';

  const isDefactoFcaQuestions: QuestionAny[] = [
    {
      name: `${isDefactoFcaQuestionsKey}Intro`,
      label: `The next few questions relate to de facto couples applying to 
        the Federal Circuit and Family Court of Australia.`,
      type: 'info',
      submitButtonLabel: 'Continue',
      visible: (data: any) => defactoNotWA(data),
      index: 8,
    },
    {
      name: `${isDefactoFcaQuestionsKey}1`,
      label: `Was either or both of the parties to the de facto relationship 
      ordinarily resident in one or more of the Australian Territories or 
      New South Wales, Queensland, Victoria, Tasmania or South Australia when 
      the relationship broke down?`,
      type: 'yesno',
      visible: (data: any) => defactoNotWA(data),
      index: 9,
    },
    {
      name: `${isDefactoFcaQuestionsKey}2`,
      label: `Are either or both of the parties to the de facto relationship 
      ordinarily resident in one or more of the Australian Territories or New 
      South Wales, Queensland, Victoria, Tasmania or South Australia at the 
      time this application is made?`,
      type: 'yesno',
      visible: (data: any) => defactoNotWA(data),
      index: 10,
    },
    {
      name: `${isDefactoFcaQuestionsKey}3`,
      label: `Were both of the parties to the de facto relationship ordinarily 
      resident in one or more of the Australian Territories or New South Wales, 
      Queensland, Victoria, Tasmania or South Australia for at least one third 
      of the de facto relationship?`,
      type: 'yesno',
      visible: (data: any) => defactoNotWA(data),
      index: 11,
    },
    {
      name: `${isDefactoFcaQuestionsKey}4`,
      label: `Did the applicant make substantial contributions of the kind 
      mentioned in paragraph 90SM(4)(a), (b) or (c) in relation to the de facto 
      relationship in one or more of the Australian Territories or New South Wales, 
      Queensland, Victoria, Tasmania or South Australia?`,
      type: 'yesno',
      visible: (data: any) => defactoNotWA(data),
      help: {
        label: 'Learn more',
        content: (
          <Paragraph large>
            {`This question is about establishing a geographical connection 
              to the places listed. It is asking whether one of you made 
              substantial financial, non-financial or homemaker/parent 
              contributions to the de facto relationship in one of the places 
              listed.`}
          </Paragraph>
        ),
      },
      index: 12,
    },
    {
      name: 'jurisdiction',
      label: (
        data: any
      ) => `You have selected to file your application for consent 
      orders in ${data.stateResideCourtLocation}, ${data.stateReside}.`,
      content: (data: any) =>
        `The court in ${data.stateReside} where you need to 
        file your application is the Federal Circuit and Family Court of Australia.`,
      type: 'info',
      setValue: 'FCA',
      exitOnSubmit: true,
      visible: (data: any) =>
        defactoNotWA(data) &&
        // If users tick yes to 1st card, no other yesses required on next  3 cards
        (data[`${isDefactoFcaQuestionsKey}1`] ||
          // If users tick no to 1st card but yes to 2nd card, can answer yes to EITHER  3rd   OR   4th card, to be eligible
          (!data[`${isDefactoFcaQuestionsKey}1`] &&
            data[`${isDefactoFcaQuestionsKey}2`] &&
            (data[`${isDefactoFcaQuestionsKey}3`] ||
              data[`${isDefactoFcaQuestionsKey}4`]))),
      index: 13,
    },
    {
      name: 'jurisdiction',
      label: 'amica is not suitable for your situation',
      content: () => (
        <>
          <Paragraph large>
            {`If they don't have the right combo of yes / no to de facto Qs. 
            kick off:`}
          </Paragraph>
          <Paragraph large>
            {`Based on your answers it seems that you do not meet the geographic
            criteria for de facto applications in the Federal Circuit and Family Court of Australia.`}
          </Paragraph>
          <Paragraph large>
            {`amica is not suitable for situations where you do not meet these 
            criteria.`}
          </Paragraph>
          <Paragraph large>
            <HelpLineContent legalHelpAbout="these criteria" />
          </Paragraph>
        </>
      ),
      type: 'info',
      setValue: 'Not Suited',
      exitOnSubmit: true,
      visible: (data: any) => defactoNotWA(data),
      index: 14,
    },
  ];

  const defactoWA = (data: any) =>
    isDefacto && data.stateReside === 'Western Australia';
  const isDefactoWaQuestionsKey = 'isDefactoWa';

  const isDefactoWaQuestions: QuestionAny[] = [
    {
      name: `${isDefactoWaQuestionsKey}Intro`,
      label: `The next few questions relate to de facto couples applying to 
      the Family Court of Western Australia.`,
      type: 'info',
      submitButtonLabel: 'Continue',
      visible: (data: any) => defactoWA(data),
      index: 15,
    },
    {
      name: `${isDefactoWaQuestionsKey}1`,
      label: `Are either or both of the parties to the de facto relationship 
      ordinarily resident Western Australia at the time this application is made?`,
      type: 'yesno',
      visible: (data: any) => defactoWA(data),
      index: 16,
    },
    {
      name: `${isDefactoWaQuestionsKey}2`,
      label: `Were both of the parties to the de facto relationship ordinarily 
      resident in Western Australia for at least one third of the de facto 
      relationship?`,
      type: 'yesno',
      visible: (data: any) => defactoWA(data),
      index: 17,
    },
    {
      name: `${isDefactoWaQuestionsKey}3`,
      label: `Did the applicant make substantial contributions of the kind 
      mentioned in paragraph 205ZG(4)(a), (b) or (c) in relation to the de 
      facto relationship in Western Australia?`,
      type: 'yesno',
      help: {
        label: 'Learn more',
        content: (
          <Paragraph large>
            {`This question is about establishing a geographical connection 
              to WA. It is asking whether one of you made substantial financial, 
              non-financial or homemaker/parent contributions to the de facto 
              relationship in WA.`}
          </Paragraph>
        ),
      },
      visible: (data: any) => defactoWA(data),
      index: 18,
    },
    {
      name: 'jurisdiction',
      label: `You have selected to file your application in Western Australia. 
      You will need to file your application documents in the Family Court of 
      Western Australia.`,
      content: (
        <Paragraph large>
          {`The Family Court of Western Australia requires de facto couples to 
            provide an additional document (an affidavit) when filing an application 
            for consent orders. You can find further information about this at `}
          <ReferralLink href="https://www.familycourt.wa.gov.au/D/defacto_relationships.aspx">
            https://www.familycourt.wa.gov.au/D/defacto_relationships.aspx
          </ReferralLink>
        </Paragraph>
      ),
      type: 'info',
      setValue: 'WA',
      exitOnSubmit: true,
      visible: (data: any) =>
        defactoWA(data) &&
        // users MUST tick yes to 1st card,  must also tick yes to EITHER  2nd OR   3rd card,
        data[`${isDefactoWaQuestionsKey}1`] &&
        (data[`${isDefactoWaQuestionsKey}2`] ||
          data[`${isDefactoWaQuestionsKey}3`]),
      index: 19,
    },
    {
      name: 'jurisdiction',
      label: 'amica is not suitable for your situation',
      content: () => (
        <>
          <Paragraph large>
            {`Based on your answers it seems that you do not meet the 
            geographic criteria for de facto applications in the Family Court 
            of Western  Australia.`}
          </Paragraph>
          <Paragraph large>
            amica is not suitable for situations where you do not meet these
            criteria.
          </Paragraph>
          <Paragraph large>
            <HelpLineContent legalHelpAbout="these criteria" />
          </Paragraph>
        </>
      ),
      type: 'info',
      setValue: 'Not Suited',
      exitOnSubmit: true,
      visible: (data: any) => defactoWA(data),
      index: 20,
    },
  ];

  // Combine and add index
  const questions: QuestionAny[] = [
    ...startIntroQuestions,
    ...stateResideQuestions,
    isDefactoQuestion,
    ...isMarriedQuestions,
    ...isDefactoFcaQuestions,
    ...isDefactoWaQuestions,
  ].map((question, index) => {
    const newQuestion = { ...question };
    return { ...newQuestion, index };
  });
  return questions;
};

export const jurisdictionStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
}: Statement) => {
  const { itemHistory } = matter;

  const itemDiff = getUpdatedItemValues(data, itemHistory, [
    `${stateResideQuestionsKey}CourtLocation`,
    stateResideQuestionsKey,
    'jurisdiction',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );

  const marriedStatus = data.isDefacto ? 'de facto' : 'married';
  const courtLocation = data[`${stateResideQuestionsKey}CourtLocation`];
  const stateReside = data[`${stateResideQuestionsKey}`];
  const jurisdictionCourt: string | undefined = {
    FCA: 'Federal Circuit and Family Court of Australia',
    WA: 'Family Court of Western Australia',
    'Not Suited': undefined,
  }[data.jurisdiction as string];

  let statement;

  if (jurisdictionCourt) {
    statement = highlight`You will apply for consent orders as a ${marriedStatus} couple in ${updated(
      [
        stateResideQuestionsKey,
        'jurisdiction',
        `${stateResideQuestionsKey}CourtLocation`,
      ],
      `${courtLocation}, ${stateReside}, at the ${jurisdictionCourt}`
    )}`;
  } else {
    statement = 'You are not suited for the consent orders';
  }

  if (plainText) {
    return `${statement}`;
  }

  return <>{statement}</>;
};
