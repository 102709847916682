import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { areSettlementAssetsAllAccountedFor } from 'App/Utils/matterHelpers';
import AssetProps from 'Common/Data/App/assetProperties';
import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';

import { finalizeCaptureAction } from 'App/State/MatterActions';

import Loader from 'Common/UI/Loader/Loader';
import DivisionIntroCarousel from './IntroCarousel/DivisionIntroCarousel';

const DivisionIntroPage = ({ matter, navigate, finalizeCapture, location }) => {
  const { isTAPNegative } = AssetProps();

  const [canProceed, setCanProceed] = useState(false);
  const { state: locationState } = location;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isTAPNegative && areSettlementAssetsAllAccountedFor({ matter })) {
      navigate(ODRS_PROPERTY_ASSET_DIVISION_ROUTE);
    } else if (!locationState || locationState.first) {
      setIsLoading(true);

      finalizeCapture().then(() => {
        setCanProceed(true);
        setIsLoading(false);
      });
    } else {
      setCanProceed(true);
    }
  }, []);

  return (
    <>
      <DivisionIntroCarousel enabledFinish={canProceed} finishLabel="Next" />
      {isLoading && <Loader floating />}
    </>
  );
};

DivisionIntroPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  finalizeCapture: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
  finalizeCapture: () => dispatch(finalizeCaptureAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DivisionIntroPage));
