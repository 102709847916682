import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';

import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import GenericButton from 'Common/UI/Button/GenericButton';
import theme from 'Common/Utils/theme';
import Actions from 'Common/UI/Actions';
import { ButtonsWrapper, InnerContentMiddle } from 'Common/UI/Signup/Layout';

import WhatDoesSeparatedCoupleMeanHelpButton from 'Common/UI/Signup/WhatDoesSeparatedCoupleMeanHelp';

const SignupIntro = ({ goNext }) => (
  <>
    <InnerContentMiddle className="step-transition-fade-up">
      <ResponsiveTextContainer>
        <H1Conversational data-cy="welcome-info">
          Before we start, we need to go over a few things
        </H1Conversational>
        <Paragraph large marginBottom={theme.spacing[6]}>
          amica is an online service provided by National Legal Aid that helps
          separated couples make parenting arrangements if they have children,
          and divide their money and property fairly.
        </Paragraph>
        <WhatDoesSeparatedCoupleMeanHelpButton />
      </ResponsiveTextContainer>
    </InnerContentMiddle>
    <ButtonsWrapper className="step-transition-fade-down">
      <ResponsiveTextContainer>
        <Actions noPadding>
          <GenericButton onClick={goNext} data-cy="confirm-button">
            Get started
          </GenericButton>
        </Actions>
      </ResponsiveTextContainer>
    </ButtonsWrapper>
  </>
);

SignupIntro.propTypes = {
  goNext: PropTypes.func.isRequired,
};

export default SignupIntro;
