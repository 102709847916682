import React from 'react';

import IconExternalLink from 'Common/Assets/images/icon-external-link.svg';
import IconExternalLinkWhite from 'Common/Assets/images/icon-external-link-white.svg';
import IconTextButton from './IconTextButton';

type Props = {
  disabled?: boolean;
  subtle?: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

// Open Icon Button
const IconOpenLinkButton: React.FC<Props> = ({
  children,
  disabled = false,
  subtle = false,
  ...props
}) => (
  <IconTextButton
    disabled={disabled}
    fullWidth
    subtle={subtle}
    icon={disabled || subtle ? IconExternalLink : IconExternalLinkWhite}
    {...props}
  >
    {children}
  </IconTextButton>
);

export default IconOpenLinkButton;
