import {
  openOdie as openOdieAction,
  updateOdie as updateOdieAction,
  closeOdie as closeOdieAction,
} from 'App/State/OdieReducer';
import { useAppDispatch, useAppSelector } from 'App/State/Store';
import push from 'Common/Utils/push';
import { blackTheme, Theme } from 'Common/Utils/theme';
import { ODRS_DASH_HOME_ROUTE } from 'Common/routes';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { ThemeProvider } from 'styled-components';
import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2 } from 'Common/UI/Text/Headings';
import { Row } from 'Common/UI/Layout/Flex';
import Paragraph from 'Common/UI/Text/Paragraph';
import getNotifications from 'App/AppState/getNotifications';

const NotificationsDrawerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
`;

const Item = styled(Paragraph)
  .attrs(() => ({
    large: true,
  }))
  .withConfig({
    shouldForwardProp: prop => !['large'].includes(prop),
  })<{
  theme: Theme;
}>`
  margin: 0;
  padding: ${({ theme }) =>
    `${theme.spacing[6]} ${theme.spacing[16]} ${theme.spacing[6]} ${theme.spacing[6]}`};
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colours.clickableGrey};
  font-weight: bold;
  display: block;
  text-decoration: none;
  transition: background 0.3s;
  cursor: pointer;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) =>
      `${theme.spacing[6]} ${theme.spacing[20]} ${theme.spacing[6]} ${theme.spacing[10]}`};
  }

  &:hover {
    background: ${({ theme }) => theme.colours.offWhite};
  }

  &:after {
    content: '';
    background: ${({ theme }) => theme.colours.midTeal};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: ${({ theme }) => theme.spacing[10]};
    transform: translateY(-50%);
  }
`;

const NotificationsIntro = styled(Heading2)<{
  theme: Theme;
}>`
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[6]}`};
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colours.clickableGrey};
  font-weight: 500;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[10]}`};
  }
`;

export const StyledHeader = styled.header<{
  theme: Theme;
}>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[6]}`};
  background: ${({ theme }) => theme.backgroundColour};
  color: ${({ theme }) => theme.textColour};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[10]}`};
  }
`;

const CloseButton = styled(GenericButton)<{
  theme: Theme;
}>`
  z-index: 10;

  border-radius: 60px;
  padding: 8px 24px 8px 24px;
  text-decoration: none;
`;

type HeaderProps = {
  onClose: () => void;
};

const Header: React.FC<HeaderProps> = ({ onClose }) => (
  <ThemeProvider theme={blackTheme}>
    <StyledHeader>
      <Row justifyContent="space-between">
        <Heading2>Notifications</Heading2>

        <CloseButton onClick={onClose}>Close</CloseButton>
      </Row>
    </StyledHeader>
  </ThemeProvider>
);

const NotificationsWrapper = styled.div``;

const NotificationsDrawer: React.FC = () => {
  const [loadInOdie, setOdieOpen] = useState(false);

  const location = useLocation<{ background?: string; previous?: string }>();
  const background = location.state && location.state.background;

  const previousRoute = location?.state?.previous
    ? location.state.previous
    : ODRS_DASH_HOME_ROUTE;

  const matter = useAppSelector(state => state.matter);
  const dispatch = useAppDispatch();

  const navigate = (to: string) => dispatch(push(to));
  const closeOdie = () => dispatch(closeOdieAction());
  const openOdie = () => dispatch(openOdieAction());
  const updateOdie = (data: any) => dispatch(updateOdieAction(data));

  // Load in Odie
  useEffect(() => {
    // if we have a background location set, render content in the odie
    if (!loadInOdie) {
      if (background) {
        setOdieOpen(true);
        updateOdie({
          hideFooter: true,
          noPadding: true,
          onClose: () => {
            navigate(previousRoute);
          },
        });
        openOdie();
      }
    }
  }, [updateOdie, openOdie, background, loadInOdie, navigate, previousRoute]);

  const gotoRoute = (route: string) => {
    if (background) closeOdie();
    navigate(route);
  };

  const notifications = getNotifications(matter);

  const notificationsIntroText =
    notifications.length > 0
      ? `You have ${notifications.length} new tasks to complete`
      : 'You have no new notifications';

  return (
    <NotificationsDrawerWrapper>
      <Header onClose={() => gotoRoute(previousRoute)} />

      <NotificationsIntro>{notificationsIntroText}</NotificationsIntro>
      <NotificationsWrapper>
        {notifications.map(({ text, route }) => (
          <Item onClick={() => gotoRoute(route)} key={text}>
            {text}
          </Item>
        ))}
      </NotificationsWrapper>
    </NotificationsDrawerWrapper>
  );
};

export default NotificationsDrawer;
