import React from 'react';
import PropTypes from 'prop-types';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import { ParagraphNoMarginBottom } from 'Common/UI/Text/Paragraph';
import { DOCUMENT_TYPES_WAITING_FOR_OTHER } from 'Common/constants';
import {
  areThereDocumentsWhereWaitingForOther,
  documentCheckKeys,
  documentsWhereWaitingForOther,
} from 'Common/Utils/documentHelpers';
import SpacedBox from 'Common/UI/Layout/SpacedBox';

export const documentTypesWaitingForOtherFlags = {
  id: 'docTypesWaitingOnOther',
  description: 'Waiting on other for document types',
  check: () => areThereDocumentsWhereWaitingForOther(),
  flags: {
    appState: DOCUMENT_TYPES_WAITING_FOR_OTHER,
  },
};

const DocumentTypesWaitingForOther = ({ otherName }) => (
  <SpacedBox marginBottom="xxsmall">
      <Heading2MarginTopNone>
        {
          {
            [documentCheckKeys.BOTH]: `Waiting for ${otherName} to agree to create consent order application and create an agreement document`,
            [documentCheckKeys.PROPERTY_AGREEMENT]: `Waiting for ${otherName} to confirm to create an agreement document`,
            [documentCheckKeys.CONSENT_ORDERS]: `Waiting for ${otherName} to agree to create consent order application`,
          }[documentsWhereWaitingForOther()]
        }
      </Heading2MarginTopNone>
      <ParagraphNoMarginBottom>
        You will both need to agree on the document types before proceeding.
      </ParagraphNoMarginBottom>
    </SpacedBox>
);

DocumentTypesWaitingForOther.propTypes = {
  otherName: PropTypes.string.isRequired,
};

export default DocumentTypesWaitingForOther;
