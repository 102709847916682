import React from 'react';

import Paragraph from 'Common/UI/Text/Paragraph';
import getOtherParty from 'Common/Utils/getOtherParty';
import {
  getHighlightStatementValuesFunction,
  getFirstName,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
} from 'Common/Utils/statementHelpers';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';
import formatCurrency from 'Common/Utils/formatCurrency';

export const yourIncomeQuestions = (): QuestionAny[] => [
  {
    name: 'doTheOrdersAffectYourEarning',
    label: 'Do the orders sought affect your earning capacity?',
    content: (
      <>
        <Paragraph large>
          (e.g. Are you disposing of or acquiring an investment or business?)
        </Paragraph>
        <Paragraph>
          Note: amica is not suitable if you are disposing of or acquiring an
          investment or business as part of your separation arrangements. For
          other options see our{' '}
          <ReferralLink href="https://www.amica.gov.au/help-and-support.html">
            Help and Support page.
          </ReferralLink>
        </Paragraph>
      </>
    ),
    type: 'yesno',
    index: 0,
  },
  {
    name: 'describeHowOrdersAffectYourEarning',
    label:
      'Please describe how the orders sought affect your earning capacity.',
    type: 'text',
    hint: 'Explain how',
    placeholder: 'Type something here',
    index: 1,
    visible: answers => Boolean(answers.doTheOrdersAffectYourEarning),
    help: {
      label: 'I need more information',
      content: (
        <Paragraph large>
          The court needs to know if these orders will affect your future
          earning capacity.
        </Paragraph>
      ),
    },
  },
  {
    name: 'areYouPayingChildSupport',
    label: 'Are you paying child support?',
    type: 'yesno',
    index: 2,
  },
  {
    name: 'howMuchChildSupportPayingPerWeek',
    label: 'How much are you paying per week?',
    type: 'currency',
    hint: 'Amount',
    placeholder: 'Enter amount',
    index: 3,
    visible: answers => Boolean(answers.areYouPayingChildSupport),
  },
  {
    name: 'whoAreYouPayingChildSupportTo',
    label: 'Who are you paying it to?',
    type: 'select',
    options: _MatterPropsJS => [
      {
        label: _MatterPropsJS('other.firstname'),
        value: _MatterPropsJS('other.party'),
      },
      {
        label: 'Someone else',
        value: 'Someone else',
      },
    ],
    index: 4,
    visible: answers => Boolean(answers.areYouPayingChildSupport),
  },
  {
    name: 'whoAreYouReallyPayingChildSupportTo',
    label: 'Who are you paying it to?',
    type: 'text',
    hint: 'Name',
    placeholder: 'Enter name',
    index: 5,
    visible: answers =>
      Boolean(answers.areYouPayingChildSupport) &&
      answers.whoAreYouPayingChildSupportTo === 'Someone else',
  },
  {
    name: 'areYouReceivingChildSupport',
    label: 'Are you receiving child support?',
    type: 'yesno',
    index: 6,
  },
  {
    name: 'howMuchChildSupportReceivingPerWeek',
    label: 'How much are you receiving per week?',
    type: 'currency',
    hint: 'Amount',
    placeholder: 'Enter amount',
    index: 7,
    visible: answers => Boolean(answers.areYouReceivingChildSupport),
  },
  {
    name: 'whoPaysYouChildSupport',
    label: 'Who pays it to you?',
    type: 'select',
    options: _MatterPropsJS => [
      {
        label: _MatterPropsJS('other.firstname'),
        value: _MatterPropsJS('other.party'),
      },
      {
        label: 'Someone else',
        value: 'Someone else',
      },
    ],
    index: 8,
    visible: answers => Boolean(answers.areYouReceivingChildSupport),
  },
  {
    name: 'whoReallyPaysYouChildSupport',
    label: 'Who pays it to you?',
    type: 'text',
    hint: 'Name',
    placeholder: 'Enter name',
    index: 9,
    visible: answers => answers.whoPaysYouChildSupport === 'Someone else',
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 10,
  },
];

export const yourIncomeStatement = ({
  matter,
  data,
  card,
  plainText = false,
  withUpdateLink = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.CardIndex || matter.self.party;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'doTheOrdersAffectYourEarning',
    'areYouPayingChildSupport',
    'howMuchChildSupportPayingPerWeek',
    'whoAreYouPayingChildSupportTo',
    'whoAreYouReallyPayingChildSupportTo',
    'areYouReceivingChildSupport',
    'howMuchChildSupportReceivingPerWeek',
    'whoPaysYouChildSupport',
    'whoReallyPaysYouChildSupport',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(yourIncomeQuestions(), data, hideSensitive);

  let firstName;
  let partnerFirstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
    partnerFirstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
    partnerFirstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  }

  const {
    doTheOrdersAffectYourEarning,
    areYouPayingChildSupport,
    howMuchChildSupportPayingPerWeek,
    whoAreYouPayingChildSupportTo,
    areYouReceivingChildSupport,
    howMuchChildSupportReceivingPerWeek,
    whoPaysYouChildSupport,
  } = data;

  const statementStart = doTheOrdersAffectYourEarning
    ? highlight`The orders sought ${updated(
        'doTheOrdersAffectYourEarning',
        redact('doTheOrdersAffectYourEarning', 'do')
      )} affect ${firstName}'s earning capacity. `
    : highlight`The orders sought ${updated(
        'doTheOrdersAffectYourEarning',
        redact('doTheOrdersAffectYourEarning', 'do not')
      )} affect ${firstName}'s earning capacity. `;

  let whoIsPaying;
  if (areYouPayingChildSupport) {
    whoIsPaying =
      whoAreYouPayingChildSupportTo === 'Someone else'
        ? highlight`${firstName} is paying ${updated(
            ['areYouPayingChildSupport', 'howMuchChildSupportPayingPerWeek'],
            redact(
              'howMuchChildSupportPayingPerWeek',
              formatCurrency(howMuchChildSupportPayingPerWeek)
            )
          )} per week in child support to ${updated(
            'whoAreYouReallyPayingChildSupportTo',
            redact('whoAreYouReallyPayingChildSupportTo')
          )}. `
        : highlight`${firstName} is paying ${updated(
            ['areYouPayingChildSupport', 'howMuchChildSupportPayingPerWeek'],
            redact(
              'howMuchChildSupportPayingPerWeek',
              formatCurrency(howMuchChildSupportPayingPerWeek)
            )
          )} per week in child support to ${partnerFirstName}. `;
  } else {
    whoIsPaying = highlight`${firstName} is ${updated(
      'areYouPayingChildSupport',
      'not'
    )} paying child support to ${partnerFirstName}. `;
  }

  let whoIsReceiving;
  if (areYouReceivingChildSupport) {
    whoIsReceiving =
      whoPaysYouChildSupport === 'Someone else'
        ? highlight`${firstName} is receiving ${updated(
            [
              'areYouReceivingChildSupport',
              'howMuchChildSupportReceivingPerWeek',
            ],
            redact(
              'howMuchChildSupportReceivingPerWeek',
              formatCurrency(howMuchChildSupportReceivingPerWeek)
            )
          )} per week in child support from ${updated(
            'whoReallyPaysYouChildSupport',
            redact('whoReallyPaysYouChildSupport')
          )}.`
        : highlight`${firstName} is receiving ${updated(
            [
              'areYouReceivingChildSupport',
              'howMuchChildSupportReceivingPerWeek',
            ],
            redact(
              'howMuchChildSupportReceivingPerWeek',
              formatCurrency(howMuchChildSupportReceivingPerWeek)
            )
          )} per week in child support from ${partnerFirstName}.`;
  } else {
    whoIsReceiving = highlight`${firstName} is ${updated(
      'areYouReceivingChildSupport',
      'not'
    )} receiving child support from ${partnerFirstName}.`;
  }

  if (plainText) {
    return `${statementStart} ${whoIsPaying} ${whoIsReceiving}`;
  }

  return (
    <>
      {statementStart}
      {whoIsPaying}
      {whoIsReceiving}
    </>
  );
};
