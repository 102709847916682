import React from 'react';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';

export const WhatIsAStatement = (
  <>
    <H1Conversational>What is a statement?</H1Conversational>
    <Paragraph>
      You and your former partner will answer some questions about your
      relationship, children, money and property.
    </Paragraph>
    <Paragraph>
      We will then use your answers to create statements. All you have to do is
      review each other&apos;s statements and if you are happy with them, agree
      on them together.
    </Paragraph>
    <Paragraph>
      Nothing is finalised until you’re both happy. Once everything is agreed,
      you can create and download parenting and property agreements.
    </Paragraph>
  </>
);
