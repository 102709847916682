import React, { useEffect, useState, useContext } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { PARTY_A } from 'Common/constants';
import DiviProps from 'Common/Data/App/diviProperties';
import MatterProps from 'Common/Utils/MatterProps';
import {
  ODRS_DIVISION_AGREE_ROUTE,
  ODRS_DIVISION_MESSAGE_SENT_ROUTE,
  ODRS_DIVISION_AGREE_WITH_OTHER_ROUTE,
  ODRS_CONSENT_ORDERS_ASSET_ROUTE,
  ODRS_PROPERTY_ASSET_DIVISION_ROUTE,
} from 'Common/routes';
import { Title, Heading1 } from 'Common/UI/Text/Headings';

import CloseButton from 'Common/UI/Button/CloseButton';
import defaultTheme, { blackTheme } from 'Common/Utils/theme';
import Loader from 'Common/UI/Loader/Loader';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import { RouteContext } from 'App/UI/Transitions/RouteContext';
import push from 'Common/Utils/push';

import {
  sendNegotiationAction,
  sendAssessmentEmailAction,
} from 'App/State/MatterActions';
import buildExport from 'Common/Utils/exportBuilder';
import DiviSuggestion from './Components/DiviSuggestion';
import DialogAddMessage from './Dialog/DialogAddMessage';
import DialogAgreeToOffer from './Dialog/DialogAgreeToOffer';

import { SCREEN_STATE_ACTION, SCREEN_STATE_VIEW } from './constants';

import { DetailsViewWrapper } from '../DetailsView';
import NegotiationHistory from './Components/NegotiationHistory';
import DivisionMessaging from './DivisionMessaging';
import DivisionHelpAndSupport from './DivisionHelpAndSupport';
import Navigation, { getNavItemStatus } from '../Navigation';

const StyledDetailsViewHeader = styled.div`
  border-bottom: 0;
  display: block;
  min-height: initial;
  height: auto;
  background-color: ${blackTheme.backgroundColour};
  padding: ${({ theme }) => `${theme.padding.medium}px`};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) =>
      `36px ${theme.padding.xlarge}px ${theme.padding.small}px`};
  }
`;

const StyledFlexScroller = styled.div`
  padding: 16px 0;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: 40px;
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 0;
  margin-right: 80px;
`;

const StyledHeading = styled(Heading1)`
  color: ${blackTheme.textColour};
  margin-top: 0;
  margin-right: 80px;
`;

const DivisionView = ({
  navigate,
  location,
  matter,
  sendNegotiation,
  sendAssessmentEmail,
}) => {
  const {
    rounds,
    lastRound,
    division,
    canMakeOffer,
    TAP,
    agreedToDivision,
    hasOtherPartyAcceptedSuggestion,
  } = DiviProps();

  const latestOffer = lastRound ? lastRound.split : division;

  const [viewState, setViewState] = useState(SCREEN_STATE_VIEW); // statement state - view, typing message, waiting, etc
  const [negotiateStep, setNegotiateStep] = useState('offer');
  const [dialog, setDialog] = useState(null);
  const [offer, setOffer] = useState(latestOffer);
  const [invalidOffer, setInvalidOffer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setTheme } = useContext(RouteContext);

  let pageTitle = '';

  switch (viewState) {
    case 'view':
      if (rounds.length === 0) {
        pageTitle = 'amica suggests you receive:';
      } else if (agreedToDivision) {
        pageTitle = 'Your agreed division';
      } else if (lastRound.party === MatterProps('self.party')) {
        pageTitle = 'Your offer';
      } else {
        pageTitle = `${MatterProps('other.firstname')}'s offer`;
      }

      break;

    case 'action':
    default:
      pageTitle =
        negotiateStep === 'message' ? 'Your reasoning' : 'Suggested division';

      break;
  }

  useEffect(() => {
    // Show an action after a short delay?
    setDialog(null);

    if (viewState === SCREEN_STATE_VIEW) {
      setTheme(blackTheme);
    } else {
      setTheme(defaultTheme);
    }

    setTimeout(() => {
      if (viewState === SCREEN_STATE_VIEW && canMakeOffer) {
        setDialog('DialogAgreeToOffer');
      }
    }, 1000);
  }, [viewState]);

  const goBack = () => {
    if (negotiateStep === 'offer') {
      setViewState(SCREEN_STATE_VIEW);
    } else {
      setNegotiateStep('offer');
    }
  };

  const buildOffer = (message, isAgreement = false) => ({
    split: MatterProps('self.party') === PARTY_A ? offer : 1 - offer,
    message,
    isAgreement,
  });

  const submitOffer = message => {
    setIsLoading(true);

    const offerDetails = buildOffer(message);

    return sendNegotiation(offerDetails)
      .then(() => {
        setIsLoading(true);
        navigate(ODRS_DIVISION_MESSAGE_SENT_ROUTE);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSendAssessmentEmail = () => {
    // we need to get it here otherwise it wont get refreshed with the new data
    const { agreedToDivision: agreed } = DiviProps();

    if (agreed) {
      return sendAssessmentEmail(buildExport(matter, true));
    }

    return Promise.resolve();
  };

  const acceptOfferFromDivi = () => {
    setIsLoading(true);

    sendNegotiation({ isAgreement: true })
      .then(() => handleSendAssessmentEmail())
      .then(() => {
        setIsLoading(false);
        navigate(ODRS_DIVISION_AGREE_ROUTE);
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const acceptOfferFromOther = () => {
    setIsLoading(true);

    const offerDetails = buildOffer(undefined, true);

    sendNegotiation(offerDetails)
      .then(() => handleSendAssessmentEmail()) // this fails on local, so next then doesnt call
      .then(() => {
        setIsLoading(false);
        navigate(ODRS_DIVISION_AGREE_WITH_OTHER_ROUTE);
      })
      .catch(e => {
        setIsLoading(false);
      });
  };

  const Heading = viewState === SCREEN_STATE_VIEW ? StyledTitle : StyledHeading;

  const isOfferFromDivi = !rounds || rounds.length === 0;

  // shows/hides the `add message` dialog depending on if the user has moved the offer slider or not
  useEffect(() => {
    if (
      offer !== latestOffer &&
      viewState === SCREEN_STATE_ACTION &&
      negotiateStep === 'offer'
    ) {
      setDialog('DialogAddMessage');
    } else {
      setDialog(null);
    }
  }, [offer]);

  const tabs = [
    {
      label: '1. Our suggestion',
      status: getNavItemStatus({
        isCompleted: true,
        isDisabled: true,
      }),
      onClick: goBack,
      testId: 'tab1',
    },
    {
      label: '2. Your offer',
      status: getNavItemStatus({
        isCompleted: true,
        isLocked: false,
      }),
      onClick: goBack,
      active: negotiateStep === 'offer',
      testId: 'tab2',
    },
    {
      label: '3. Your reasoning',
      status: getNavItemStatus({
        isCompleted: false,
        isLocked: negotiateStep !== 'message',
        isDisabled: true,
      }),
      active: negotiateStep === 'message',
      disabled: negotiateStep !== 'message' || undefined,
      testId: 'tab3',
    },
  ];

  const showNavigation = viewState !== SCREEN_STATE_VIEW;
  const showHistory = {
    [SCREEN_STATE_VIEW]: Boolean(rounds && rounds.length),
    [SCREEN_STATE_ACTION]: true,
  }[viewState];

  return (
    <DetailsViewWrapper withDrawer={dialog !== null}>
      <StyledDetailsViewHeader state={viewState}>
        <Heading>{pageTitle}</Heading>
        {showNavigation && <Navigation tabs={tabs} />}
        <CloseButton
          state={viewState}
          onClick={() => {
            if (
              location.state &&
              location.state.previous === ODRS_CONSENT_ORDERS_ASSET_ROUTE
            ) {
              navigate(ODRS_CONSENT_ORDERS_ASSET_ROUTE);
              return;
            }
            navigate(ODRS_PROPERTY_ASSET_DIVISION_ROUTE);
          }}
        >
          <span>Close</span>
        </CloseButton>
      </StyledDetailsViewHeader>
      {negotiateStep === 'message' && (
        <DivisionMessaging
          offer={offer}
          TAP={TAP}
          onSubmit={submitOffer}
          onCancel={goBack}
        />
      )}

      {negotiateStep !== 'message' && (
        <StyledFlexScroller>
          <DiviSuggestion
            matter={matter}
            screenState={viewState}
            onUpdateOffer={setOffer}
            invalidOffer={invalidOffer}
            onInvalidOffer={setInvalidOffer}
            showHistory={showHistory}
          />

          {rounds.length === 0 && (
            <FlexGrid medium={2}>
              <DivisionHelpAndSupport viewer="first" />
            </FlexGrid>
          )}

          <DialogAgreeToOffer
            isOfferFromDivi={isOfferFromDivi}
            hasOtherPartyAcceptedSuggestion={hasOtherPartyAcceptedSuggestion}
            showDialog={dialog === 'DialogAgreeToOffer'}
            onAccept={() => {
              if (isOfferFromDivi) {
                acceptOfferFromDivi();
              } else {
                acceptOfferFromOther();
              }
            }}
            onNegotiate={() => {
              setViewState(SCREEN_STATE_ACTION);
              setDialog(null);
            }}
          />

          <DialogAddMessage
            showDialog={dialog === 'DialogAddMessage'}
            onCancel={() => {
              setViewState(SCREEN_STATE_VIEW);
              setDialog(null);
            }}
            onNext={() => {
              setNegotiateStep('message');
              setDialog(null);
            }}
            onNextDisabled={invalidOffer}
          />
        </StyledFlexScroller>
      )}
      {showHistory && negotiateStep !== 'message' && (
        <NegotiationHistory
          viewState={viewState}
          showLatestOffer={viewState !== SCREEN_STATE_VIEW}
        />
      )}

      {isLoading && <Loader floating />}
    </DetailsViewWrapper>
  );
};

DivisionView.propTypes = {
  matter: PropTypes.object, // eslint-disable-line
  location: ReactRouterPropTypes.location.isRequired,
  navigate: PropTypes.func.isRequired,
  sendNegotiation: PropTypes.func.isRequired,
  sendAssessmentEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
  sendNegotiation: details => dispatch(sendNegotiationAction(details)),
  sendAssessmentEmail: content => dispatch(sendAssessmentEmailAction(content)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DivisionView)
);
