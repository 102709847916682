import React from 'react';

import {
  DIALOG_CANCEL_SUPERANNUATION_BALANCE_REQUEST,
  MESSAGE_STATUS_UPDATE_DONE,
  STATUS_APPROVED,
  STATUS_DISCUSSION,
  STATUS_NEW_BOTH_PARTIES,
  STATUS_NEW_ONLY_SELF,
  STATUS_NEW_ONLY_THEM,
  STATUS_REMOVED,
  STATUS_UPDATE_REQUESTED,
} from 'Common/constants';
import Matter from 'Common/Data/Types/matter';
import Card from 'Common/UI/Card';
import { youWereTheLastInDiscussion } from 'Common/Utils/getMessages';
import { canApproveCard } from 'Common/Utils/cardHelpers';
import { QuestionStateCard, QuestionCard } from 'Common/Data/Types/appSections';
import styled, { ThemeProvider } from 'styled-components';
import { orangeTheme, Theme } from 'Common/Utils/theme';
import Paragraph from 'Common/UI/Text/Paragraph';
import { useAppDispatch } from 'App/State/Store';
import push from 'Common/Utils/push';
import {
  ODRS_YOURSUPERANNUATION_CONFIRM_REQUEST_SUPERANNUATION_BALANCE_ROUTE,
  ODRS_YOURSUPERANNUATION_LETTER_RECEIVED_ROUTE,
  ODRS_YOURSUPERANNUATION_UPLOAD_LETTER_ROUTE,
} from 'Common/routes';
import { updateMatterSectionAction } from 'App/State/MatterActions';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import DocumentList from 'Common/UI/UploadedDocuments/DocumentList';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import isFileObject from 'Common/Utils/isFileObject';
import IconTrash from 'Common/Assets/images/icon-delete.svg';
import { showDialog } from 'App/State/AppReducer';
import NormalCard, { CardContent } from '../NormalCard';

const SuperBalanceWrapper = styled.div<{ theme: Theme }>`
  border-top: 1px solid ${({ theme }) => theme.colours.lightGrey};
  margin-top: ${({ theme }) => theme.spacing[8]};
  padding-top: ${({ theme }) => theme.spacing[8]};
`;

const RemoveBalanceRequestIcon = styled.img`
  cursor: pointer;
`;

const SuperannuationBalance: React.FC<{
  data: QuestionStateCard<QuestionCard>;
}> = ({ data }) => {
  const {
    otherName,
    index,
    data: cardData,
    cardIDAsArray,
    status,
    creator,
    cardID,
  } = data;

  const { form6Response } = cardData;

  const dispatch = useAppDispatch();

  const navigate = (route: string) => dispatch(push(route));

  const {
    superBalanceRequested = false,
    letterReceived = false,
    letterUploaded = false,
  } = cardData?.__flags ?? {};

  const { withLoading } = usePromiseWithLoading();

  const selfParty = getPartyKeys().self;

  const setFlagAndNavigate = () =>
    withLoading(async () => {
      const newCardData = {
        ...cardData,
        __flags: {
          ...(cardData?.__flags || {}),
          letterReceived: true,
        },
      };

      const messageData = {
        section: cardIDAsArray,
        owner: selfParty,
        message: '',
        status: MESSAGE_STATUS_UPDATE_DONE,
        checkTone: false,
      };

      await dispatch(
        updateMatterSectionAction({
          section: cardIDAsArray,
          sectionData: newCardData,
          messageData,
        })
      );

      navigate(ODRS_YOURSUPERANNUATION_LETTER_RECEIVED_ROUTE);
    });

  const handleRemoveBalanceRequest = async () => {
    dispatch(
      showDialog({
        dialog: DIALOG_CANCEL_SUPERANNUATION_BALANCE_REQUEST,
        data: cardID,
      })
    );
  };

  // requester has received and uploaded the form 6 response letter
  if (
    letterUploaded &&
    form6Response &&
    form6Response.length &&
    !isFileObject(form6Response)
  ) {
    const files = <DocumentList documents={form6Response} />;

    return (
      <SuperBalanceWrapper
        data-cy="request-balance-wrapper"
        data-cy-status="requester-uploaded"
      >
        <Card.MainHeading spaced={{ marginBottom: 4 }}>
          Request superannuation balance
        </Card.MainHeading>
        <Paragraph>
          {creator === selfParty ? (
            <>
              {otherName} uploaded the letter they received from your
              superannuation fund.
            </>
          ) : (
            <>
              You have uploaded the letter you received from {otherName}’s
              superannuation fund.
            </>
          )}
        </Paragraph>
        {files && <SpacedBox>{files}</SpacedBox>}
      </SuperBalanceWrapper>
    );
  }

  // if we are not the requesting party then we just want to render a message saying the flow is in process
  if (creator === selfParty) {
    if (superBalanceRequested) {
      return (
        <SuperBalanceWrapper
          data-cy="request-balance-wrapper"
          data-cy-status="requestee-waiting"
        >
          <Card.MainHeading spaced={{ marginBottom: 4 }}>
            Request superannuation balance
          </Card.MainHeading>

          <Paragraph>
            {otherName} is requesting more information about your
            superannuation.
          </Paragraph>
        </SuperBalanceWrapper>
      );
    }

    return null;
  }

  // requester has received the letter but hasn't uploaded it yet
  if (letterReceived) {
    return (
      <SuperBalanceWrapper
        data-cy="request-balance-wrapper"
        data-cy-status="requester-upload-letter"
      >
        <Card.HeadingWithIcon
          spaced={{ marginBottom: 4 }}
          icon={
            <RemoveBalanceRequestIcon
              src={IconTrash}
              alt="Cancel balance request"
              onClick={handleRemoveBalanceRequest}
              data-cy="cancel-balance-request-button"
            />
          }
        >
          Request superannuation balance
        </Card.HeadingWithIcon>
        <Paragraph>
          Upload the letter you received from your former partner’s
          superannuation fund
        </Paragraph>
        <ThemeProvider theme={orangeTheme}>
          <Card.Button
            mt={8}
            onClick={() =>
              navigate(
                `${ODRS_YOURSUPERANNUATION_UPLOAD_LETTER_ROUTE}/${index}`
              )
            }
            size="fullWidth"
          >
            Upload
          </Card.Button>
        </ThemeProvider>
      </SuperBalanceWrapper>
    );
  }

  // requester has requested the super balance but hasn't received the letter yet
  if (superBalanceRequested) {
    return (
      <SuperBalanceWrapper
        data-cy="request-balance-wrapper"
        data-cy-status="requester-waiting-for-letter"
      >
        <Card.HeadingWithIcon
          spaced={{ marginBottom: 4 }}
          icon={
            <RemoveBalanceRequestIcon
              src={IconTrash}
              alt="Cancel balance request"
              onClick={handleRemoveBalanceRequest}
              data-cy="cancel-balance-request-button"
            />
          }
        >
          Request superannuation balance
        </Card.HeadingWithIcon>
        <Paragraph>
          You have decided to request superannuation information from{' '}
          {otherName}’s superannuation fund. You can continue once you’ve sent
          them a Superannuation Information Kit and receive a letter back.
        </Paragraph>

        <ReferralLink
          showExternalIcon
          externalLinkIconColour="black"
          href="https://www.fcfcoa.gov.au/fl/forms/superannuation-kit"
        >
          Form 6 Superannuation Information Kit
        </ReferralLink>
        <ThemeProvider theme={orangeTheme}>
          <Card.Button mt={8} onClick={setFlagAndNavigate} size="fullWidth">
            I have received a letter
          </Card.Button>
        </ThemeProvider>
      </SuperBalanceWrapper>
    );
  }

  // don't show any request balance stuff if the card is approved or removed
  if (status === STATUS_APPROVED || status === STATUS_REMOVED) {
    return null;
  }

  // requester has not started the request balance process yet
  return (
    <SuperBalanceWrapper
      data-cy="request-balance-wrapper"
      data-cy-status="requester-initial"
    >
      <Card.MainHeading spaced={{ marginBottom: 4 }}>
        Request superannuation balance
      </Card.MainHeading>
      <Paragraph>
        If you want to request {otherName}'s superannuation balance, you can
        send a Superannuation Information Kit to {otherName}'s superannuation
        fund.
      </Paragraph>
      <Card.Button
        mt={8}
        onClick={() =>
          navigate(
            `${ODRS_YOURSUPERANNUATION_CONFIRM_REQUEST_SUPERANNUATION_BALANCE_ROUTE}/${index}`
          )
        }
        subtle
        size="fullWidth"
      >
        Request superannuation balance
      </Card.Button>
    </SuperBalanceWrapper>
  );
};

const ShowMore: React.FC<{ data: QuestionStateCard<QuestionCard> }> = ({
  data,
}) => {
  if ('showMoreText' in data && 'popUpContent' in data) {
    const { showMoreText, popUpContent } = data;

    return (
      <Card.LearnMoreButton
        spaced={{ marginTop: 'medium', marginBottom: 'medium' }}
        popUpContent={popUpContent}
      >
        {showMoreText}
      </Card.LearnMoreButton>
    );
  }

  return null;
};

type YourSuperannuationCardProps = {
  data: QuestionStateCard<QuestionCard>;
  isActive?: boolean;
  themeName?: string;
  cardHeadingText?: string;
  matter: Matter;
};

const YourSuperannuationCard: React.FC<YourSuperannuationCardProps> = ({
  data,
  isActive,
  themeName,
  cardHeadingText,
  matter,
}) => {
  const { cardID, label, navToView, status, date, typeName, autoApprove } =
    data;

  switch (status) {
    // these views are the same as the Normal card
    case STATUS_NEW_ONLY_SELF:
    case STATUS_NEW_BOTH_PARTIES:
    case STATUS_NEW_ONLY_THEM:
      return (
        <NormalCard
          matter={matter}
          data={data}
          isActive={isActive}
          themeName={themeName}
          cardHeadingText={cardHeadingText}
        />
      );
    case STATUS_APPROVED:
      if (autoApprove) {
        return (
          <Card key={cardID} cardTheme="approved" data={data}>
            <Card.MainHeading isApproved enableMoreButton>
              {cardHeadingText}
            </Card.MainHeading>
            <Card.Statement noBox />
            <Card.AutoApproveFooter />
          </Card>
        );
      }

      return (
        <Card cardTheme="approved" data={data}>
          <Card.MainHeading isApproved enableMoreButton>
            {cardHeadingText}
          </Card.MainHeading>
          <CardContent noBox data={data} />
          <Card.ApprovedFooter />
          <SuperannuationBalance data={data} />
        </Card>
      );
    case STATUS_REMOVED:
      return (
        <Card data={data} cardTheme="removed">
          <Card.HeadingWithSideText heading={label} text={date} />
          <Card.MainHeading>{cardHeadingText}</Card.MainHeading>
          <ShowMore data={data} />
          <CardContent data={data} />
        </Card>
      );
    case STATUS_DISCUSSION:
    case STATUS_UPDATE_REQUESTED:
      return (
        <Card
          cardTheme={
            youWereTheLastInDiscussion(matter.messages, cardID)
              ? 'self'
              : 'other'
          }
          data={data}
          active={isActive}
        >
          <Card.HeadingWithSideText heading={label} />
          <Card.MainHeading
            spaced={{ marginTop: 'xxsmall', marginBottom: 'medium' }}
          >
            {cardHeadingText}
          </Card.MainHeading>
          <CardContent data={data} />
          <Card.LatestMessage />
          <ShowMore data={data} />
          <Card.Button size="fullWidth" onClick={navToView}>
            {youWereTheLastInDiscussion(matter.messages, cardID)
              ? 'View'
              : 'Review'}{' '}
            {typeName}
          </Card.Button>

          <SuperannuationBalance data={data} />
        </Card>
      );
    default:
      if (canApproveCard(data, matter)) {
        return (
          <Card cardTheme="other" data={data} active={isActive}>
            <Card.HeadingWithSideText heading={label} enableMoreButton />
            <Card.MainHeading
              spaced={{ marginTop: 'xxsmall', marginBottom: 'medium' }}
            >
              {cardHeadingText}
            </Card.MainHeading>
            <div style={{ flexGrow: 1 }}>
              <CardContent data={data} />
            </div>
            <Card.LatestMessage />
            <ShowMore data={data} />
            <Card.Button size="fullWidth" onClick={navToView}>
              Review {typeName}
            </Card.Button>

            <SuperannuationBalance data={data} />
          </Card>
        );
      }

      return (
        <Card cardTheme="self" data={data} active={isActive}>
          <Card.HeadingWithSideText heading={label} enableMoreButton />
          <Card.MainHeading
            spaced={{ marginTop: 'xxsmall', marginBottom: 'medium' }}
          >
            {cardHeadingText}
          </Card.MainHeading>
          <div style={{ flexGrow: 1 }}>
            <CardContent data={data} />
          </div>
          <Card.LatestMessage />
          <ShowMore data={data} />
          <Card.Button size="fullWidth" onClick={navToView}>
            View {typeName}
          </Card.Button>

          <SuperannuationBalance data={data} />
        </Card>
      );
  }
};

export default YourSuperannuationCard;
