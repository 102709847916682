/* eslint-disable no-inner-declarations */
/* eslint-disable no-use-before-define */
import { useEffect, useRef } from 'react';
import useMountedState from 'react-use/lib/useMountedState';
import useSetState from 'react-use/lib/useSetState';

const useSlider = (ref, options = {}) => {
  const isMounted = useMountedState();
  const isSliding = useRef(false);
  const frame = useRef(0);
  const [state, setState] = useSetState({
    isSliding: false,
    value: 0,
    pos: 0,
    length: 0,
  });

  useEffect(() => {
    if (ref.current) {
      const { width } = ref.current.getBoundingClientRect();
      setState({
        value: options.initialValue,
        pos: Math.round(options.initialValue * width),
        width,
      });

      function startScrubbing() {
        if (!isSliding.current && isMounted()) {
          isSliding.current = true;
          setState({ isSliding: true });
          bindEvents();
        }
      }

      function stopScrubbing() {
        if (isSliding.current && isMounted()) {
          isSliding.current = false;
          setState({ isSliding: false });
          unbindEvents();
        }
      }

      function onMouseDown(event) {
        startScrubbing();
        onMouseMove(event);
      }
      function onMouseMove(event) {
        onScrub(event.clientX);
      }

      function onTouchStart(event) {
        startScrubbing();
        onTouchMove(event);
      }
      function onTouchMove(event) {
        onScrub(event.changedTouches[0].clientX);
      }

      function bindEvents() {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', stopScrubbing);

        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchend', stopScrubbing);
      }

      function unbindEvents() {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', stopScrubbing);

        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', stopScrubbing);
      }

      function onScrub(clientX) {
        cancelAnimationFrame(frame.current);

        frame.current = requestAnimationFrame(() => {
          if (!isMounted()) {
            return;
          }

          if (ref.current) {
            const { left, width: length } = ref.current.getBoundingClientRect();

            // Prevent returning 0 when element is hidden by CSS
            if (!length) {
              return;
            }

            let value = (clientX - left) / length;

            if (value > 1) {
              value = 1;
            } else if (value < 0) {
              value = 0;
            }

            let pos = clientX - left;

            if (pos > length) {
              pos = length;
            } else if (pos < 0) {
              pos = 0;
            }

            setState({ value, pos, length });
          }
        });
      }

      ref.current.addEventListener('mousedown', onMouseDown);
      ref.current.addEventListener('touchstart', onTouchStart);

      return () => {
        ref.current.removeEventListener('mousedown', onMouseDown);
        ref.current.removeEventListener('touchstart', onTouchStart);
      };
    }

    return () => {};
  }, [ref]);

  return [state, setState];
};

export default useSlider;
