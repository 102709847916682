import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MatterProps from 'Common/Utils/MatterProps';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Heading3 } from 'Common/UI/Text/Headings';
import Dialog from 'Common/UI/Layout/Dialog';
import StyledBackgroundOverlay from 'Common/UI/Layout/BackgroundOverlay';
import defaultTheme from 'Common/Utils/theme';
import GenericButton from 'Common/UI/Button/GenericButton';
import { TwoButtonRow } from 'Common/UI/Button/ButtonGroup';
import CheckboxField from 'Common/UI/Form/CheckboxField';

const Wrapper = styled.div`
  position: relative;
  z-index: 100;
`;

const StyledCheckboxField = styled(CheckboxField)`
  margin: ${props => props.theme.padding.small}px 0;
`;

const DialogConfirmAssetTransfer = ({
  showDialog,
  onConfirm,
  onCancel,
  buttonDisabled,
  ...rest
}) => {
  const [agreed, setAgreed] = useState(false);
  return (
    <Wrapper>
      <Dialog
        showDialog={showDialog}
        content={
          <>
            <Heading3>
              Please confirm that you approve the asset division.
            </Heading3>
            <Paragraph>
              Once you agree to the asset division, it will move into your
              property agreement or consent order.
            </Paragraph>

            <StyledCheckboxField
              value={agreed}
              name="agreed"
              data-cy="checkbox-asset-split-accept-offer-agree"
              label={<Paragraph>I understand and agree</Paragraph>}
              onChange={updateValue => setAgreed(updateValue)}
            />
          </>
        }
        buttons={
          <TwoButtonRow justifyContent="space-between">
            <GenericButton subtle onClick={onCancel}>
              Cancel
            </GenericButton>
            <GenericButton
              onClick={onConfirm}
              disabled={!agreed}
              data-cy="button-asset-split-accept-offer-confirm"
            >
              Confirm
            </GenericButton>
          </TwoButtonRow>
        }
        {...rest}
      />
      {showDialog && <StyledBackgroundOverlay onClick={onCancel} />}
    </Wrapper>
  );
};

DialogConfirmAssetTransfer.propTypes = {
  showDialog: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
  buttonDisabled: PropTypes.bool,
};

DialogConfirmAssetTransfer.defaultProps = {
  showDialog: false,
  theme: defaultTheme,
  buttonDisabled: false,
};

export default DialogConfirmAssetTransfer;
