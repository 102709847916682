import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import PropTypes from 'prop-types';

import formatCurrency from 'Common/Utils/formatCurrency';
import MatterProps from 'Common/Utils/MatterProps';
import DiviProps from 'Common/Data/App/diviProperties';
import Paragraph from 'Common/UI/Text/Paragraph';

import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import { Heading2 } from 'Common/UI/Text/Headings';
import BackgroundOverlay from 'Common/UI/Layout/BackgroundOverlay';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import theme, { blackTheme } from 'Common/Utils/theme';
import { TwoButtonResponsiveGroup } from 'Common/UI/Button/ButtonGroup';
import { MediumButton } from 'Common/UI/Button/GenericButton';

const StyledHeading = styled(Heading2)`
  margin-top: 0;
`;

const DialogAgreeToOffer = ({
  showDialog,
  onNegotiate,
  onAccept,
  isOfferFromDivi,
  hasOtherPartyAcceptedSuggestion,
}) => {
  const [screen, setScreen] = useState('options');

  const cancel = () => setScreen('options');

  let headingPrompt;

  if (hasOtherPartyAcceptedSuggestion) {
    headingPrompt = `${MatterProps(
      'other.firstname'
    )} has agreed to our suggestion`;
  } else {
    headingPrompt = isOfferFromDivi
      ? 'Do you accept the suggested divison?'
      : `Do you accept ${MatterProps('other.firstname')}'s offer?`;
  }

  const textPrompt = isOfferFromDivi
    ? 'You can either agree to our suggested division or make your own offer.'
    : `You can either agree to ${MatterProps(
        'other.firstname'
      )}'s offer or make your own offer. Accepting will move this division split to the property agreement.`;

  const { TAP, division, lastRound, hasAgreed } = DiviProps();

  const split = lastRound ? lastRound.split : division;

  const values = {
    [MatterProps('self.party')]: {
      percentage: Math.round(split * 100),
      amount: formatCurrency(Math.round(TAP * split)),
    },
    [MatterProps('other.party')]: {
      percentage: Math.round((1 - split) * 100),
      amount: formatCurrency(Math.round(TAP * (1 - split))),
    },
  };

  const otherHasAgreed = hasAgreed[MatterProps('other.party')];

  return (
    <>
      <ThemeProvider theme={blackTheme}>
        <FlexDrawer isVisible={showDialog && screen === 'options'}>
          <FlexGrid medium={2} large={2}>
            <div>
              <StyledHeading>{headingPrompt}</StyledHeading>
              <Paragraph>{textPrompt}</Paragraph>
            </div>
            <OffsetColumn>
              <TwoButtonResponsiveGroup>
                <MediumButton
                  onClick={() => setScreen('confirm')}
                  data-cy="button-accept-offer"
                >
                  Accept
                </MediumButton>
                <MediumButton
                  subtle
                  onClick={onNegotiate}
                  data-cy="button-make-counter-offer"
                >
                  Counter offer
                </MediumButton>
              </TwoButtonResponsiveGroup>
            </OffsetColumn>
          </FlexGrid>
        </FlexDrawer>
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        {showDialog && screen === 'confirm' && (
          <BackgroundOverlay onClick={cancel} />
        )}
        <FlexDrawer isVisible={showDialog && screen === 'confirm'}>
          <FlexGrid align="center" medium={2}>
            <div>
              <Heading2>
                Confirming that you accept the suggested division
              </Heading2>
              <Paragraph>
                You would receive{' '}
                <strong data-cy="confirm-receive-self">
                  {values[MatterProps('self.party')].percentage}% (
                  {values[MatterProps('self.party')].amount})
                </strong>{' '}
                and {MatterProps('other.firstname')} would receive{' '}
                <strong data-cy="confirm-receive-other">
                  {values[MatterProps('other.party')].percentage}% (
                  {values[MatterProps('other.party')].amount})
                </strong>
                .
              </Paragraph>
              {otherHasAgreed && (
                <Paragraph>
                  Confirming that you accept means that we will send this offer
                  to {MatterProps('other.firstname')}. If you both agree, the
                  property agreement section will be complete and you can view
                  and save your document.
                </Paragraph>
              )}
              {!otherHasAgreed && (
                <Paragraph>
                  {MatterProps('other.firstname')} will still need to confirm
                  that they are comfortable with our suggested division.
                </Paragraph>
              )}
            </div>
            <OffsetColumn>
              <TwoButtonResponsiveGroup>
                <MediumButton
                  onClick={onAccept}
                  data-cy="button-confirm-agree-accept"
                >
                  Accept
                </MediumButton>
                <MediumButton
                  subtle
                  onClick={cancel}
                  data-cy="button-confirm-agree-cancel"
                >
                  Cancel
                </MediumButton>
              </TwoButtonResponsiveGroup>
            </OffsetColumn>
          </FlexGrid>
        </FlexDrawer>
      </ThemeProvider>
    </>
  );
};

DialogAgreeToOffer.propTypes = {
  showDialog: PropTypes.bool,
  onNegotiate: PropTypes.func,
  onAccept: PropTypes.func,
  isOfferFromDivi: PropTypes.bool.isRequired,
  hasOtherPartyAcceptedSuggestion: PropTypes.bool.isRequired,
};

DialogAgreeToOffer.defaultProps = {
  showDialog: false,
  onNegotiate: () => {},
  onAccept: () => {},
};

export default DialogAgreeToOffer;
