import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import PageContainer from 'Common/UI/Layout/PageContainer';
import { AGREEMENT_PAYMENTS } from 'Common/constants';
import push from 'Common/Utils/push';
import { ODRS_PAYMENT_REVIEW_ORDER_ROUTE } from 'Common/routes';
import { getOrderWithItems } from 'Common/Utils/Payments';
import { getInvoiceAction } from 'App/State/MatterActions';
import openResolvedPromiseURL from 'Common/Utils/openResolvedPromiseURL';
import baseTheme from 'Common/Utils/theme';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import PaymentHeader from './PaymentHeader';
import { PaymentProvider } from './PaymentContext';
import { DrawerContent, OrderSummary, PaymentDrawer } from './common';

const HorizontalLine = styled.div`
  margin: ${props => props.theme.padding.medium}px -${props => props.theme.padding.medium}px;
  height: 1px;
  background: ${props => props.theme.colours.lightGrey};
`;

const PaymentConfirmation = ({ navigate, matter, location, getInvoice }) => {
  const { state = {} } = location;
  const { skus = [] } = state || {};

  const order = getOrderWithItems(matter, skus);

  useEffect(() => {
    if (!skus || skus.length === 0 || !order) {
      navigate(ODRS_PAYMENT_REVIEW_ORDER_ROUTE, undefined, true);
    }
  }, []);

  const onClickTaxInvoice = receiptNumber =>
    openResolvedPromiseURL(getInvoice(receiptNumber));

  return (
    <ThemeProvider theme={baseTheme}>
      <PaymentProvider
        navigate={navigate}
        matter={matter}
        isViewingPaymentConfirmation={skus.length > 0}
      >
        <PageContainer>
          <PaymentHeader matter={matter} />

          <PaymentDrawer>
            <DrawerContent>
              <ResponsiveTextContainer>
                <Heading2>Payment confirmation</Heading2>
                {order && (
                  <>
                    <Paragraph large>
                      Your payment has been successful and a summary appears
                      below. We sent an invoice to your email, but you can also
                      print your tax invoice below.
                    </Paragraph>
                    <HorizontalLine />

                    <OrderSummary
                      order={order}
                      items={AGREEMENT_PAYMENTS.filter(item =>
                        order.skus.includes(item.type)
                      )}
                      showTaxInvoiceLink
                      onClickTaxInvoice={() =>
                        onClickTaxInvoice(order.receiptNumber)
                      }
                    />
                  </>
                )}
              </ResponsiveTextContainer>
            </DrawerContent>
          </PaymentDrawer>
        </PageContainer>
      </PaymentProvider>
    </ThemeProvider>
  );
};

PaymentConfirmation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  getInvoice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  navigate: (route, state, replace) => dispatch(push(route, state, replace)),
  getInvoice: receiptNumber =>
    dispatch(getInvoiceAction(receiptNumber)).unwrap(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentConfirmation));
