import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import IconClose from 'Common/Assets/images/icon-close.svg';
import AmicaLogo from 'App/UI/AmicaLogo';
import { Title } from 'Common/UI/Text/Headings';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';

const CloseButton = styled.div`
  position: absolute;
  ${({ theme }) => theme.tokens.closeButtonPosition}
  top: ${({ theme }) => theme.spacing[4]};

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    top: ${({ theme }) => theme.spacing[8]};
  }
`;

const LoginHeaderContainer = styled.div`
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  padding-top: ${({ theme }) => theme.spacing[4]};
  position: relative;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding-top: ${({ theme }) => theme.spacing[8]};
  }
`;

const StyledTitle = styled(Title)`
  margin-top: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const AmicaLogoWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    display: inline-block;
    height: 26px;
    transition: all 0.3s 0.1s;
    width: 85px;
    margin-bottom: ${({ theme }) => theme.padding.medium}px;
    color: ${({ theme }) => theme.colours.white};
  }
`;

const LoginHeader = ({ toOnClose }) => (
  <LoginHeaderContainer className="transition-fade-up">
    <ResponsiveContainer fullWidth>
      <AmicaLogoWrapper>
        <AmicaLogo />
      </AmicaLogoWrapper>
      <CloseButton as={Link} to={toOnClose}>
        <img src={IconClose} alt="Close icon" />
      </CloseButton>
      <StyledTitle>Login</StyledTitle>
    </ResponsiveContainer>
  </LoginHeaderContainer>
);

LoginHeader.propTypes = {
  toOnClose: PropTypes.string.isRequired,
};

export default LoginHeader;
