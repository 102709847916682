import {
  ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE,
  ODRS_CONSENT_ORDERS_INTRO_SLIDER_ROUTE,
  ODRS_DASH_PARENTING_PLAN_INTRO_ROUTE,
  ODRS_PARENTING_ESSENTIALS_ROUTE,
  ODRS_PARENTING_SCHEDULE_ROUTE,
} from 'Common/routes';
import MatterProps from 'Common/Utils/MatterProps';
import { isParentingPlanBasicsAgreed } from 'App/Utils/matterHelpers';

export const getConsentOrderRoute = () => {
  const hasSeenIntro = MatterProps('self.hasSeenConsentOrdersIntro', false);

  if (hasSeenIntro) {
    return ODRS_CONSENT_ORDERS_GET_STARTED_ROUTE;
  }

  return ODRS_CONSENT_ORDERS_INTRO_SLIDER_ROUTE;
};

export const getParentingRoute = () => {
  const hasSeenIntro = MatterProps('self.hasSeenParentingPlanIntro', false);

  if (hasSeenIntro) {
    if (isParentingPlanBasicsAgreed()) {
      return ODRS_PARENTING_SCHEDULE_ROUTE;
    }

    return ODRS_PARENTING_ESSENTIALS_ROUTE;
  }

  return ODRS_DASH_PARENTING_PLAN_INTRO_ROUTE;
};
