import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2 } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { ODRS_DASH_RELATIONSHIP_ROUTE } from 'Common/routes';
import push from 'Common/Utils/push';
import {
  SECTION_STATUS_COMPLETED,
  SECTION_STATUS_IN_PROGRESS,
  SECTION_STATUS_NOT_STARTED,
} from 'Common/constants';
import getNames from 'Common/Utils/getNames';
import SectionCardWrapper from './SectionCardWrapper';

import BackgroundRelationship from './bg-relationship.svg';
import BackgroundRelationshipCompleted from './bg-relationship-completed.svg';

const StatusParagraph = styled(Paragraph)`
  border-top: 1px solid ${({ theme }) => theme.colours.clickableGrey};
  margin-top: 0;
  padding-top: ${({ theme }) => theme.padding.small}px;
`;

const RelationshipSectionCard = ({
  status,
  navigate,
  progress,
  stats: { notStarted, waitingSelf, waitingOther },
}) => {
  const names = getNames();

  const heading = <Heading2>About you and your relationship</Heading2>;

  switch (status) {
    case SECTION_STATUS_NOT_STARTED:
      return (
        <SectionCardWrapper
          hideProgressBar
          route={ODRS_DASH_RELATIONSHIP_ROUTE}
          headerImage={BackgroundRelationship}
          dataCy="overview-card-relationship-not-started"
          footer={
            <GenericButton
                fullWidth
                onClick={() => navigate(ODRS_DASH_RELATIONSHIP_ROUTE)}
              >
                Get started
              </GenericButton>
          }
        >
          {heading}
          <Paragraph>
            You have sections to fill out and statements to agree on.
          </Paragraph>
        </SectionCardWrapper>
      );
    case SECTION_STATUS_IN_PROGRESS: {
      const waitingOnOther = waitingOther > 0 && waitingSelf + notStarted === 0;

      return (
        <SectionCardWrapper
          progress={progress}
          route={ODRS_DASH_RELATIONSHIP_ROUTE}
          headerImage={BackgroundRelationship}
          dataCy="overview-card-relationship-in-progress"
          footer={
            <GenericButton
                fullWidth
                onClick={() => navigate(ODRS_DASH_RELATIONSHIP_ROUTE)}
              >
                Review
              </GenericButton>
          }
        >
          {heading}
          {waitingSelf > 0 && (
            <StatusParagraph>
              You have <strong>{waitingSelf}</strong> statements to review
            </StatusParagraph>
          )}
          {notStarted > 0 && (
            <StatusParagraph>
              You have <strong>{notStarted}</strong> required statements
              remaining
            </StatusParagraph>
          )}
          {waitingOnOther && (
            <StatusParagraph>
              Waiting on {names.other} to review your statements.
            </StatusParagraph>
          )}
        </SectionCardWrapper>
      );
    }
    case SECTION_STATUS_COMPLETED:
      return (
        <SectionCardWrapper
          progress={100}
          route={ODRS_DASH_RELATIONSHIP_ROUTE}
          headerImage={BackgroundRelationshipCompleted}
          dataCy="overview-card-relationship-completed"
          footer={
            <GenericButton
                subtle
                fullWidth
                onClick={() => navigate(ODRS_DASH_RELATIONSHIP_ROUTE)}
              >
                View
              </GenericButton>
          }
        >
          {heading}
          <Paragraph>Great! You have both completed this section.</Paragraph>
        </SectionCardWrapper>
      );
    default:
      return null;
  }
};

RelationshipSectionCard.propTypes = {
  navigate: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  stats: PropTypes.shape({
    notStarted: PropTypes.number,
    waitingSelf: PropTypes.number,
    waitingOther: PropTypes.number,
  }),
  progress: PropTypes.number,
};

RelationshipSectionCard.defaultProps = {
  stats: {},
  progress: undefined,
};

const mapDispatchToProps = dispatch => ({
  navigate: (to, state) => dispatch(push(to, state)),
});

export default connect(null, mapDispatchToProps)(RelationshipSectionCard);
