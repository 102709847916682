import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { push } from 'connected-react-router';

import { useAppDispatch } from 'App/State/Store';
import { orangeTheme, pastelPurpleTheme, Theme } from 'Common/Utils/theme';
import GenericButton from 'Common/UI/Button/GenericButton';
import IconAeroplane from 'Common/Assets/images/icon-aeroplane-purple.svg';
import IconTick from 'Common/Assets/images/icon-success-ticks-orange.svg';
import { Column } from './Flex';
import PageContent from './PageContent';
import Actions from '../Actions';

const StyledPageContent = styled(PageContent)<{
  theme: Theme;
}>`
  background: ${props => props.theme.altBackgroundColour};
  overflow: auto;
`;

const PageColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const ImageFullWidth = styled.img`
  width: auto;
  height: 150px;
  margin: 0 auto;
`;

const StyledColumn = styled(Column)<{
  theme: Theme;
}>`
  display: inline-flex;
  text-align: center;
  flex: 1;

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    flex: 0;
    padding-bottom: ${({ theme }) => theme.spacing[32]};
  }
`;

const FooterActions = styled(Actions)`
  flex: 0;
  width: 100%;
`;

const StyledTick = styled.img<{
  theme: Theme;
}>`
  margin-bottom: ${({ theme }) => theme.padding.xlarge}px;
`;

type Props = {
  buttonRoute: string;
  buttonLabel: string;
  children: React.ReactNode;
};

const Sent: React.FC<Props> = ({ children, buttonRoute, buttonLabel }) => {
  const dispatch = useAppDispatch();

  const navigate = (route: string, state?: any) => dispatch(push(route, state));

  return (
    <ThemeProvider theme={pastelPurpleTheme}>
      <StyledPageContent>
        <PageColumn>
          <div>
            <StyledColumn justifyContent="center">
              <Column alignItems="flex-start" className="transition-fade-up">
                <ImageFullWidth
                  src={IconAeroplane}
                  srcSet={`${IconAeroplane} 2x`}
                  alt=""
                />
              </Column>
              <Column className="transition-fade">{children}</Column>
            </StyledColumn>
            <FooterActions noPadding>
              <GenericButton
                onClick={() => navigate(buttonRoute)}
                data-cy="button-sent"
                className="transition-fade-down"
              >
                {buttonLabel}
              </GenericButton>
            </FooterActions>
          </div>
        </PageColumn>
      </StyledPageContent>
    </ThemeProvider>
  );
};

export { Sent };

const Success: React.FC<Props> = ({ children, buttonRoute, buttonLabel }) => {
  const dispatch = useAppDispatch();

  const navigate = (route: string, state?: any) => dispatch(push(route, state));

  return (
    <ThemeProvider theme={orangeTheme}>
      <StyledPageContent>
        <PageColumn>
          <div>
            <StyledColumn height="75%" justifyContent="center">
              <StyledTick
                src={IconTick}
                alt=""
                className="transition-fade-up"
              />
              {children}
            </StyledColumn>
            <FooterActions noPadding>
              <GenericButton
                onClick={() => navigate(buttonRoute)}
                className="transition-fade-down"
                data-cy="button-success"
              >
                {buttonLabel}
              </GenericButton>
            </FooterActions>
          </div>
        </PageColumn>
      </StyledPageContent>
    </ThemeProvider>
  );
};

export { Success };
