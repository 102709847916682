import React from 'react';
import { Switch } from 'react-router';

import {
  UploadReceivedLetter,
  LetterReceivedConfirmation,
  LetterUploadedConfirmation,
} from 'App/UI/Dashboard/common/Cards/YourSuperannuationCard';
import {
  ODRS_YOURSUPERANNUATION_LETTER_RECEIVED_ROUTE,
  ODRS_YOURSUPERANNUATION_CONFIRM_REQUEST_SUPERANNUATION_BALANCE_ROUTE,
  ODRS_YOURSUPERANNUATION_ROUTE,
  ODRS_YOURSUPERANNUATION_UPLOAD_LETTER_ROUTE,
  ODRS_YOURSUPERANNUATION_LETTER_UPLOAD_SUCCESS_ROUTE,
} from 'Common/routes';
import DiviRoute from 'Common/UI/DiviRoute';
import theme from 'Common/Utils/theme';
import MatterGuard from '../Guard/MatterGuard';
import ODRSYourSuperannuationConfirmRequestBalance from './ODRSYourSuperannuationConfirmRequestBalance';
import ODRSYourSuperannuationVersionAware from './ODRSYourSuperannuationVersionAware';

const ODRSYourSuperannuation = () => (
  <MatterGuard>
    <Switch>
      <DiviRoute
        path={`${ODRS_YOURSUPERANNUATION_CONFIRM_REQUEST_SUPERANNUATION_BALANCE_ROUTE}/:index?`}
        component={ODRSYourSuperannuationConfirmRequestBalance}
        theme={theme}
      />
      <DiviRoute
        path={`${ODRS_YOURSUPERANNUATION_LETTER_RECEIVED_ROUTE}`}
        component={LetterReceivedConfirmation}
      />
      <DiviRoute
        path={ODRS_YOURSUPERANNUATION_LETTER_UPLOAD_SUCCESS_ROUTE}
        component={LetterUploadedConfirmation}
      />
      <DiviRoute
        path={`${ODRS_YOURSUPERANNUATION_UPLOAD_LETTER_ROUTE}/:index?`}
        component={UploadReceivedLetter}
      />
      <DiviRoute
        path={`${ODRS_YOURSUPERANNUATION_ROUTE}/:index?/:updating?`}
        component={ODRSYourSuperannuationVersionAware}
        theme={theme}
      />
    </Switch>
  </MatterGuard>
);

export default ODRSYourSuperannuation;
