import React from 'react';

import { ODRS_PROPERTY_ASSET_DIVISION_ROUTE } from 'Common/routes';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Success } from 'Common/UI/Layout/Completed';

const DivisionAssetsSuccess = () => (
  <Success
    buttonLabel="Back to home"
    buttonRoute={ODRS_PROPERTY_ASSET_DIVISION_ROUTE}
  >
    <H1Conversational>Agreement reached</H1Conversational>
    <Paragraph large>
      Congratulations! You have finished dividing your assets. You can now view
      and save your property agreement or consent order.
    </Paragraph>
  </Success>
);

export default DivisionAssetsSuccess;
