import React from 'react';

import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import formatCurrency from 'Common/Utils/formatCurrency';
import { getMatter } from 'Common/Utils/MatterProps';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';
import ReferralLink from 'Common/UI/Analytics/ReferralLink';
import formatDate from '../../../../../Utils/formatDate';
import isNumeric from '../../../../../Utils/isNumeric';

// Note any disabled props will not be saved into the database
export const yourSuperannuationQuestionsContinue = (): QuestionAny[] => {
  const matter = getMatter();

  const isSplittableSuper = (SectionID: string) => {
    const assets = matter?.divi?.assets;
    if (!assets || !(assets.length > 0)) return false;

    const assetSplitable = assets.find(asset => asset.id === SectionID);

    return assetSplitable !== undefined;
  };

  return [
    {
      name: 'intro',
      type: 'intro',
      heading: () => (
        <H1Conversational>Superannuation details</H1Conversational>
      ),
      content: () => (
        <>
          <Paragraph>
            We're going to ask you to confirm some details about your
            superannuation to see if you can make an application for consent
            orders in amica.
          </Paragraph>
          <Paragraph>
            If you are splitting your superannuation, your superannuation fund
            can help you in filling out these details. After you complete this
            section, you will need to send your draft application for consent
            orders to your superannuation fund for approval.
          </Paragraph>

          <Paragraph>
            To learn more about superannuation, visit our{' '}
            <ReferralLink href="https://amica.gov.au/info-about-separating/money-and-property">
              Money and Property
            </ReferralLink>{' '}
            page, or get{' '}
            <ReferralLink href="https://amica.gov.au/help-and-support">
              Help and Support
            </ReferralLink>
            .
          </Paragraph>
        </>
      ),
      index: 0,
    },
    {
      name: 'superannuationName',
      label: 'What is the name of your superannuation fund?',
      type: 'textfield',
      hint: 'Super fund name',
      defaultValue: ({ superannuationName }) => superannuationName,
      description: `You and ${
        matter.other.firstname ?? 'Your former partner'
      } have already agreed to this in previous section`,
      disabled: true,
      index: 1,
    },
    {
      name: 'superannuationType',
      label: 'What type of interest is it?',
      type: 'textfield',
      hint: 'Super fund interest type',
      defaultValue: ({ superannuationType }) => superannuationType,
      description: `You and ${
        matter.other.firstname ?? 'Your former partner'
      } have already agreed to this in previous section`,
      disabled: true,
      index: 2,
    },
    {
      name: 'whatIsTrusteeName',
      label: 'What is the trustee name?',
      type: 'textfield',
      hint: 'Name of super trustee',
      placeholder: 'Name',
      description: '',
      index: 3,
      // visible if splitable
      visible: data => isSplittableSuper(String(data.SectionID)),
    },
    {
      name: 'whatIsSuperPostalAddress',
      label: 'What is the postal address of your superannuation fund?',
      type: 'googleAddress',
      hint: 'Super postal address',
      placeholder: 'Address',
      description: '',
      index: 4,
      visible: data => isSplittableSuper(String(data.SectionID)),
    },
    {
      name: 'membershipAccountNumber',
      label:
        'What is the membership or account number of the superannuation fund to be split?',
      type: 'textfield',
      description: '',
      index: 5,
      // visible if splitable
      visible: data => isSplittableSuper(String(data.SectionID)),
    },
    {
      name: 'superannuationValue',
      label:
        'What is the current agreed gross value of the interest in superannuation?',
      type: 'currency',
      hint: '',
      defaultValue: ({ superannuationValue }) => superannuationValue,
      description: `You and ${
        matter.other.firstname ?? 'Your former partner'
      } have already agreed to this in previous section`,
      index: 6,
      disabled: true,
    },
    {
      name: 'agreedValueCalculated',
      label:
        'Has the agreed value been calculated in accordance with the Family Law (Superannuation) regulations 2001?',
      type: 'yesno',
      hint: '',
      defaultValue: () => true,
      description: `You and ${
        matter.other.firstname ?? 'Your former partner'
      } have already agreed to this in previous section`,
      disabled: true,
      index: 7,
    },
    {
      name: 'earlierPaymentSplit',
      label:
        'For each interest, whether or not a splitting order is sought, advise if the interest is subject to an earlier payment split?',
      type: 'yesno',
      hint: '',
      index: 8,
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              This question is asking whether any of your superannuation
              interests have been subject to an earlier payment split. You can
              contact your superannuation fund to get this information.
            </Paragraph>
          </>
        ),
      },
    },
    // earlier payment split additional questions
    {
      name: 'furtherPayments',
      label: 'Are there any further payments to be made?',
      type: 'yesno',
      hint: '',
      description: '',
      index: 9,
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              This question is asking whether any of your superannuation
              interests have been subject to an earlier payment split. You can
              contact your superannuation fund to get this information.
            </Paragraph>
          </>
        ),
      },
      visible: answer => Boolean(answer.earlierPaymentSplit),
    },
    // further payment additional questions
    {
      name: 'operativeTimeSplit',
      label: 'What is the operative time for the split?',
      type: 'date',
      hint: '',
      description: '',
      index: 10,
      visible: answer => Boolean(answer.furtherPayments),
      formAttributes: {},
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              The operative time is the date in which the superannuation fund
              calculates any adjustment to the base amount. You can contact your
              superannuation fund to get this information.
            </Paragraph>
          </>
        ),
      },
    },
    {
      name: 'futurePaymentAmount',
      label:
        'What is the amount of any future payments in respect of a base amount split in the payment phase (where applicable)?',
      type: 'currency',
      hint: '',
      description: '',
      index: 11,
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              Check your response from your superannuation fund or contact them
              directly. If you are unsure you can seek legal and financial
              advice.
            </Paragraph>
          </>
        ),
      },
      visible: answer => Boolean(answer.furtherPayments),
    },
    {
      name: 'adjustedBaseAmount',
      label:
        'What is the adjusted base amount where the interest is in the growth phase (where applicable)?',
      type: 'currency',
      hint: '',
      description: '',
      index: 12,
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              Check your response from your superannuation fund or contact them
              directly. If you are unsure you can seek legal and financial
              advice.
            </Paragraph>
          </>
        ),
      },
      visible: answer => Boolean(answer.furtherPayments),
    },
    {
      name: 'percentageSplit',
      label:
        'What is the specified percentage in the case of a percentage split (where applicable)?',
      type: 'percentage',
      hint: 'Must be a percent value between 0 and 100',
      description: '',
      index: 13,
      visible: answer => Boolean(answer.furtherPayments),
      validate: value =>
        isNumeric(value) && value >= 0 && value <= 100
          ? [true]
          : [false, 'Please enter a value between 0 and 100'],
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              Check your response from your superannuation fund or contact them
              directly. If you are unsure you can seek legal and financial
              advice.
            </Paragraph>
          </>
        ),
      },
    },
    // Back to regular questions
    {
      name: 'definedBenefitInterestAmount',
      label:
        'If the interest is a defined benefit interest in the growth phase (not being an interest in a constitutionally protected fund), state the amount of any surcharge debt in the most recent member statement.',
      type: 'currency',
      hint: '',
      description: '',
      index: 14,
      optional: true,
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              Check your response from your superannuation fund or contact them
              directly. If you are unsure you can seek legal and financial
              advice.
            </Paragraph>
          </>
        ),
      },
    },
    {
      name: 'protectedFundInterestAmount',
      label:
        'If the interest is in a constitutionally protected fund, state the amount of any surcharge in the surcharge debt account',
      type: 'currency',
      hint: '',
      description: '',
      index: 15,
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              Check your response from your superannuation fund or contact them
              directly. If you are unsure you can seek legal and financial
              advice.
            </Paragraph>
          </>
        ),
      },
    },
    {
      name: 'describeTaxationConsequences',
      type: 'freetext',
      hint: 'A brief description',
      optional: true,
      label:
        'What are the taxation consequences of any order sought in relation to any interest in superannuation?',
      index: 16,
      help: {
        label: 'What does this mean?',
        content: (
          <>
            <H1Conversational>What does this mean?</H1Conversational>
            <Paragraph large>
              You should check with your superannuation fund to see if you have
              any taxation consequences when splitting your superannuation.
            </Paragraph>
            <Paragraph large>
              If you want information about the taxation consequences in
              relation to any interest in superannuation you should get legal
              and financial advice.
            </Paragraph>
          </>
        ),
      },
    },
    {
      name: 'statement',
      type: 'statement',
      label: 'You created a statement',
      index: 17,
    },
  ];
};

export const yourSuperannuationStatementContinue = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.itemNotInDatabase ? matter.self.party : data.creator;

  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'superannuationName',
    'superannuationValue',
    'whatIsTrusteeName',
    'whatIsSuperPostalAddress',
    'furtherPayments',
    'operativeTimeSplit',
    'futurePaymentAmount',
    'adjustedBaseAmount',
    'percentageSplit',
    'definedBenefitInterestAmount',
    'protectedFundInterestAmount',
    'describeTaxationConsequences',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    yourSuperannuationQuestionsContinue(),
    data,
    hideSensitive
  );

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  // Data
  const {
    superannuationName,
    superannuationValue,
    whatIsTrusteeName,
    whatIsSuperPostalAddress,
    furtherPayments,
    operativeTimeSplit,
    futurePaymentAmount,
    adjustedBaseAmount,
    percentageSplit,
    definedBenefitInterestAmount,
    protectedFundInterestAmount,
    describeTaxationConsequences,
  } = data;

  const superannuationFunds = highlight`${firstName} has ${updated(
    'superannuationValue',
    redact('superannuationValue', formatCurrency(superannuationValue))
  )} in superannuation with ${updated(
    'superannuationName',
    redact('superannuationName', superannuationName)
  )}. ${
    whatIsTrusteeName && whatIsSuperPostalAddress
      ? highlight`The Superannuation fund trustee is ${updated(
          'whatIsTrusteeName',
          redact('whatIsTrusteeName', whatIsTrusteeName)
        )} and their address is ${updated(
          'whatIsSuperPostalAddress',
          redact('whatIsSuperPostalAddress', whatIsSuperPostalAddress)
        )}.`
      : ''
  } The interest ${updated(
    'furtherPayments',
    furtherPayments ? 'does' : 'does not'
  )} have further payments to be made.`;

  const furtherPaymentsDetailsStatement = furtherPayments
    ? highlight`The operative time for the split is ${updated(
        'operativeTimeSplit',
        redact('operativeTimeSplit', formatDate(operativeTimeSplit))
      )}. The amount of any future payments in respect of a base amount split is ${updated(
        'futurePaymentAmount',
        redact('futurePaymentAmount', formatCurrency(futurePaymentAmount))
      )}. The adjusted base amount where the interest is in the growth phase is ${updated(
        'adjustedBaseAmount',
        redact('adjustedBaseAmount', formatCurrency(adjustedBaseAmount))
      )}. The specified percentage in the case of a percentage split is ${updated(
        'percentageSplit',
        redact('percentageSplit', percentageSplit)
      )}%.`
    : '';

  const definedBenefitInterestStatement =
    definedBenefitInterestAmount && definedBenefitInterestAmount !== '0'
      ? highlight`${firstName}'s superannuation fund is a defined benefit interest in the growth phase with ${updated(
          'definedBenefitInterestAmount',
          redact(
            'definedBenefitInterestAmount',
            formatCurrency(definedBenefitInterestAmount)
          )
        )} in surcharge debt in the most recent member statement.`
      : '';

  const protectedFundStatement =
    protectedFundInterestAmount && protectedFundInterestAmount !== '0'
      ? highlight`${firstName}'s superannuation fund is in a constitutionally protected fund with ${updated(
          'protectedFundInterestAmount',
          redact(
            'protectedFundInterestAmount',
            formatCurrency(protectedFundInterestAmount)
          )
        )} in surcharge debt account.`
      : '';

  const taxConsequencesStatement = highlight`${firstName} ${updated(
    'describeTaxationConsequences',
    describeTaxationConsequences ? 'does' : 'does not'
  )} have taxation consequences in relation to any interest in superannuation: ${
    describeTaxationConsequences
      ? updated(
          'describeTaxationConsequences',
          redact('describeTaxationConsequences', describeTaxationConsequences)
        ) ?? ''
      : ''
  }`;

  if (plainText) {
    return `${superannuationFunds}${furtherPaymentsDetailsStatement} ${definedBenefitInterestStatement} ${protectedFundStatement} ${taxConsequencesStatement}`;
  }

  return (
    <>
      {superannuationFunds}
      {furtherPaymentsDetailsStatement} {definedBenefitInterestStatement}{' '}
      {protectedFundStatement} {taxConsequencesStatement}
    </>
  );
};
