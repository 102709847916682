import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPage } from 'Common/Data/App/appSections';
import {
  CARD_AGREEMENT,
  SECTION_PARENTING,
  PAGE_PARENTING_ESSENTIALS,
} from 'Common/constants';
import { ODRS_PARENTING_ESSENTIALS_ROUTE } from 'Common/routes';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import { generateAgreementCard } from 'App/UI/Dashboard/Parenting/StateCards';
import ThingsToKnow from 'App/UI/Dashboard/Parenting/ThingsToKnow';
import DashboardContent from 'App/UI/Dashboard/common/DashboardContent';
import { DashboardPageProps } from 'Common/Data/Types/types';
import {
  CardAgreement,
  QuestionStateCard,
} from 'Common/Data/Types/appSections';

const Essentials: React.FC<DashboardPageProps> = ({
  matter,
  navigate,
  appState,
}) => {
  const location = useLocation();
  const customNavigate = (route: string) =>
    navigate(route, { backRoute: ODRS_PARENTING_ESSENTIALS_ROUTE, location });

  const essentials = getPage(SECTION_PARENTING, PAGE_PARENTING_ESSENTIALS);

  return (
    <>
      <FlexGrid medium={2} large={2}>
        <ThingsToKnow />
      </FlexGrid>

      <DashboardContent
        pageData={essentials}
        navigate={customNavigate}
        matter={matter}
        cardHandlers={{
          [CARD_AGREEMENT]: data =>
            generateAgreementCard(
              data as QuestionStateCard<CardAgreement>,
              matter
            ),
        }}
        appState={appState}
      />
    </>
  );
};

export default Essentials;
