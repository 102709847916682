import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Paragraph from 'Common/UI/Text/Paragraph';

import FlexDrawer from 'Common/UI/Layout/FlexDrawer';
import { Heading2 } from 'Common/UI/Text/Headings';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import theme from 'Common/Utils/theme';
import { TwoButtonResponsiveGroup } from 'Common/UI/Button/ButtonGroup';
import { MediumButton } from 'Common/UI/Button/GenericButton';
import OptionsDrawerPortal from 'Common/UI/OptionsDrawer/OptionsDrawerPortal';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { hideDialog } from 'App/State/AppReducer';
import { useAppDispatch } from 'App/State/Store';
import { setMatterFlagsAction } from 'App/State/MatterActions';
import push from 'Common/Utils/push';
import AssetProperties from 'Common/Data/App/assetProperties';
import { ODRS_SUPERANNUATION_FUND_CLAUSES_NO_CHANGES_SUCCESS_ROUTE } from 'Common/routes';
import DialogConfirmNoClauseChangesContent from './DialogConfirmNoClauseChanges.content';

const StyledHeading = styled(Heading2)`
  margin-top: 0;
`;

const StyledFlexDrawer = styled(FlexDrawer)`
  position: fixed;
`;

const DialogConfirmNoClauseChanges: React.FC = () => {
  const { withLoading } = usePromiseWithLoading();

  const dispatch = useAppDispatch();

  const navigate = (route: string) => dispatch(push(route));

  const closeDrawer = () => dispatch(hideDialog());

  const { superSplittingFlags } = AssetProperties();

  const onConfirm = () =>
    withLoading(async () => {
      await dispatch(
        setMatterFlagsAction({
          superSplitting: {
            ...superSplittingFlags,
            payerHasConfirmedNoChangesToClauses: true,
          },
        })
      );

      closeDrawer();

      navigate(ODRS_SUPERANNUATION_FUND_CLAUSES_NO_CHANGES_SUCCESS_ROUTE);
    });

  return (
    <OptionsDrawerPortal isOpen onBackgroundClick={closeDrawer}>
      <ThemeProvider theme={theme}>
        <StyledFlexDrawer isVisible>
          <FlexGrid
            align="center"
            medium={2}
            data-cy="drawer-confirm-no-changes-to-clauses"
          >
            <div>
              <StyledHeading>
                {DialogConfirmNoClauseChangesContent.heading}
              </StyledHeading>
              <Paragraph>
                {DialogConfirmNoClauseChangesContent.paragraph}
              </Paragraph>
            </div>
            <OffsetColumn>
              <TwoButtonResponsiveGroup>
                <MediumButton
                  onClick={onConfirm}
                  data-cy="button-super-confirm-no-clause-changes-accept"
                >
                  {DialogConfirmNoClauseChangesContent.continueButton}
                </MediumButton>
                <MediumButton
                  subtle
                  onClick={closeDrawer}
                  data-cy="button-super-confirm-no-clause-changes-cancel"
                >
                  {DialogConfirmNoClauseChangesContent.cancelButton}
                </MediumButton>
              </TwoButtonResponsiveGroup>
            </OffsetColumn>
          </FlexGrid>
        </StyledFlexDrawer>
      </ThemeProvider>
    </OptionsDrawerPortal>
  );
};

export default DialogConfirmNoClauseChanges;
