import React from 'react';

import {
  getHighlightStatementValuesFunction,
  getUpdatedItemValues,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import calculateMultiItemsValue from 'Common/Utils/calculateMultiItemsValue';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';

export const returnSpecificItemsQuestions: QuestionAny[] = [
  {
    name: 'itemsToReturnYesNo',
    label:
      'Do you have any items any sentimental items that you want returned to you?',
    type: 'yesno',
    index: 0,
  },
  {
    name: 'itemsToReturn',
    label:
      'If you would like any sentimental items returned to you, list them here',
    hint: '',
    type: 'multiItems',
    index: 1,
    defaultValue: '',
    formAttributes: {
      multiLimit: 10,
      hasValue: false,
      popupTitle: 'Return of specific items',
      buttonText: 'Enter items',
      hintText:
        'This section is about sentimental items. If you would like any sentimental items returned to you, list them here. (You can list up to 10 items).',
      textLabel: 'Item name',
      textPlaceholder: 'Enter item name',
    },
    visible: data => !!data.itemsToReturnYesNo,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 2,
  },
];

export const returnSpecificItemsStatement = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const { itemHistory } = matter;

  const itemDiff = getUpdatedItemValues(data, itemHistory, ['itemsToReturn']);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    returnSpecificItemsQuestions,
    data,
    hideSensitive
  );

  const party = data.CardIndex || matter.self.party;

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  const itemsToReturn = calculateMultiItemsValue(data.itemsToReturn);

  const itemsToReturnText =
    (() => {
      if (!data.itemsToReturnYesNo || !itemsToReturn.hasItems) {
        return highlight`${firstName} ${updated(
          'itemsToReturn',
          redact('itemsToReturn', 'does not')
        )} have any sentimental items that they want returned.`;
      }
      return highlight`${firstName} would like the following items returned to them: ${updated(
        'itemsToReturn',
        redact('itemsToReturn', itemsToReturn.string)
      )}.`;
    })() || '';

  if (plainText) {
    return `${itemsToReturnText}`;
  }

  return <>{itemsToReturnText}</>;
};
