import React from 'react';
import PropTypes from 'prop-types';

import GenericButton from 'Common/UI/Button/GenericButton';
import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import { ASSET_SPLIT_SELF_PARTY_COMPLETING } from 'Common/constants';
import { ODRS_DIVISION_ASSETS_INFO } from 'Common/routes';
import AssetProps from 'Common/Data/App/assetProperties';

export const assetSplitSelfOfferInProgressFlags = {
  id: 'settlementSelfPartyCompletingAssetSplit',
  description: 'Self party is currently completing Asset Split',
  check: () => {
    const { canWorkOnOffer } = AssetProps();
    return canWorkOnOffer;
  },
  flags: {
    appState: ASSET_SPLIT_SELF_PARTY_COMPLETING,
  },
};

const AssetSplitSelfOfferInProgress = ({
  navigateWithAssetSplitIntroGuard,
  otherName,
}) => (
  <>
    <Heading2MarginTopNone>
      Continue your asset division offer
    </Heading2MarginTopNone>
    <Paragraph>
      Continue with your asset division offer. Once you send your offer,{' '}
      {otherName} can review and either accept or send a counter-offer.
    </Paragraph>
    <GenericButton
      fullWidth
      onClick={() =>
        navigateWithAssetSplitIntroGuard(ODRS_DIVISION_ASSETS_INFO)
      }
      small
    >
      Continue
    </GenericButton>
  </>
);

AssetSplitSelfOfferInProgress.propTypes = {
  navigateWithAssetSplitIntroGuard: PropTypes.func.isRequired,
  otherName: PropTypes.string.isRequired,
};

export default AssetSplitSelfOfferInProgress;
