import React from 'react';

import Paragraph from 'Common/UI/Text/Paragraph';
import BubbleNotification from '../BubbleNotification';
import DivisionCalculationHelp from './Components/DivisionCalculationHelp';
import useOdie from 'Common/Utils/Hooks/useOdie';

const DivisionLearnMore = () => {
  const { handleOpenOdie: onLearnMore } = useOdie({
    message: <DivisionCalculationHelp />,
  });

  return (
    <BubbleNotification onClick={onLearnMore} buttonLabel="Learn more">
      <Paragraph>
        This suggested division is based on how legal principles are applied in
        your circumstances.
      </Paragraph>
    </BubbleNotification>
  );
};

export default DivisionLearnMore;
