import {
  ODRS_DIVISION_ASSETS_TRANSFER_OTHER,
  ODRS_DIVISION_ASSETS_TRANSFER_SELF,
} from 'Common/routes';
import CardInformation from 'Common/UI/Card/indicators/CardInformation';
import Paragraph from 'Common/UI/Text/Paragraph';
import getNames from 'Common/Utils/getNames';
import React from 'react';

const SuperannuationInfoMessage: React.FC<{
  isSuperIncludedInOffer: boolean;
  isOwingParty: boolean;
}> = ({ isSuperIncludedInOffer, isOwingParty }) => {
  const names = getNames();
  if (!isSuperIncludedInOffer && !isOwingParty) {
    // party is payee and super is not included
    return (
      <CardInformation
        buttonLabel="Add superannuation"
        buttonRoute={ODRS_DIVISION_ASSETS_TRANSFER_OTHER}
        thin
        dataCyType="payee-super-not-included"
      >
        <Paragraph>
          It looks like {names.other} will have to transfer cash to you. You can
          request a percentage of their super as a transferrable asset to reduce
          the amount of cash they owe you.
        </Paragraph>
      </CardInformation>
    );
  }

  // party is payer and super is not included
  return (
    <CardInformation
      buttonLabel="Add superannuation"
      buttonRoute={ODRS_DIVISION_ASSETS_TRANSFER_SELF}
      thin
      dataCyType="payee-super-not-included"
    >
      <Paragraph>
        It appears there isn't enough in your settlement to effect the agreed
        proposal. You may like to consider transferring some of your
        superannuation to make up the difference
      </Paragraph>
    </CardInformation>
  );
};

export default SuperannuationInfoMessage;
