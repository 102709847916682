import React from 'react';
import PropTypes from 'prop-types';

const AmicaOneLogo = ({width}) => (
  <svg fill="#15133d" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 541.73 99.1" width={width}>
    <path d="m53.66,62.82c0-10.54-7.53-18.48-17.11-18.48s-17.25,7.94-17.25,18.48,7.53,18.48,17.25,18.48,17.11-7.8,17.11-18.48m-53.66,0C0,42.56,14.51,26.54,33.81,26.54c7.53,0,14.51,2.6,20.12,6.84,1.23-3.97,4.93-6.84,9.31-6.84,5.34,0,9.58,4.24,9.58,9.58v53.39c0,5.34-4.24,9.58-9.58,9.58-4.38,0-8.08-2.88-9.31-6.84-5.61,4.38-12.59,6.84-20.12,6.84C14.51,99.1,0,83.08,0,62.82" />
    <path d="m89.25,55.15c0-17.25,11.77-28.61,26.69-28.61,7.94,0,14.92,2.6,19.85,10.27,4.79-7.67,11.91-10.27,19.85-10.27,14.92,0,26.7,11.36,26.7,28.61v34.36c0,5.34-4.24,9.58-9.58,9.58s-9.58-4.24-9.58-9.58v-34.64c0-7.12-3.97-10.54-8.9-10.54s-8.9,3.42-8.9,10.54v34.64c0,5.34-4.24,9.58-9.58,9.58s-9.58-4.24-9.58-9.58v-34.64c0-7.12-3.97-10.54-8.9-10.54s-8.9,3.42-8.9,10.54v34.64c0,5.34-4.24,9.58-9.58,9.58s-9.58-4.24-9.58-9.58v-34.36h0Z" />
    <path d="m231.62,62.82c0-20.26,15.33-36.28,36.55-36.28,11.5,0,21.22,4.93,25.87,10.54,1.78,2.05,2.74,4.52,2.74,6.43,0,5.34-4.11,9.04-9.45,9.04-3.83,0-5.75-2.05-8.21-4.11-2.46-2.05-5.75-4.11-10.95-4.11-9.72,0-17.25,8.21-17.25,18.48s7.53,18.48,17.25,18.48c4.79,0,8.35-1.92,10.82-3.97,2.46-2.05,4.52-4.38,8.35-4.38,5.34,0,9.45,3.83,9.45,9.17,0,1.92-.96,4.24-2.74,6.3-4.65,5.61-14.1,10.68-25.87,10.68-21.22,0-36.55-16.02-36.55-36.28" />
    <path d="m360.57,62.82c0-10.54-7.53-18.48-17.11-18.48s-17.25,7.94-17.25,18.48,7.53,18.48,17.25,18.48,17.11-7.8,17.11-18.48m-53.67,0c0-20.26,14.51-36.28,33.82-36.28,7.53,0,14.51,2.6,20.12,6.84,1.23-3.97,4.93-6.84,9.31-6.84,5.34,0,9.59,4.24,9.59,9.58v53.39c0,5.34-4.25,9.58-9.59,9.58-4.38,0-8.08-2.88-9.31-6.84-5.61,4.38-12.6,6.84-20.12,6.84-19.3,0-33.82-16.02-33.82-36.28" />
    <path d="m198.76,36.12c0-5.34,4.24-9.58,9.58-9.58s9.58,4.24,9.58,9.58v53.39c0,5.34-4.25,9.58-9.58,9.58s-9.58-4.24-9.58-9.58v-53.39h0Z" />
    <path d="m186.62,10.4c0-5.11,4.18-9.3,9.3-9.3s9.3,4.18,9.3,9.3-4.18,9.3-9.3,9.3-9.3-4.18-9.3-9.3" />
    <path d="m211.48,10.4c0-5.11,4.18-9.3,9.3-9.3s9.3,4.18,9.3,9.3-4.18,9.3-9.3,9.3-9.3-4.18-9.3-9.3" />
    <path
      stroke="#15133d"
      strokeMiterlimit={10}
      strokeWidth="7.14px"
      d="m513.57,3.57c10.83,0,24.59,12.39,24.59,27.99s-13.76,27.99-24.59,27.99h-97.17c-10.83,0-25.46-12.4-25.46-27.99s14.63-27.99,25.46-27.99h97.17Z"
    />
    <g fill="#FFF">
      <path d="m443.74,32.15c0,2.39-.37,4.59-1.11,6.6-.75,2.02-1.82,3.75-3.23,5.19-1.41,1.45-3.09,2.56-5.05,3.33-1.96.77-4.16,1.16-6.6,1.16s-4.61-.39-6.54-1.17c-1.94-.78-3.61-1.9-5.03-3.36-1.42-1.46-2.5-3.18-3.23-5.17-.73-1.99-1.1-4.18-1.1-6.59s.37-4.65,1.11-6.66,1.81-3.74,3.2-5.17c1.39-1.43,3.07-2.53,5.05-3.3,1.98-.77,4.16-1.16,6.54-1.16s4.63.39,6.6,1.17c1.98.78,3.67,1.9,5.08,3.35,1.41,1.45,2.48,3.17,3.21,5.17.73,2,1.1,4.2,1.1,6.6Zm-8.04,0c0-3.27-.72-5.81-2.16-7.63-1.44-1.82-3.37-2.73-5.8-2.73-1.57,0-2.95.41-4.14,1.22-1.19.81-2.11,2.01-2.76,3.6-.65,1.58-.97,3.43-.97,5.55s.32,3.92.95,5.49,1.55,2.76,2.73,3.59c1.18.83,2.58,1.25,4.18,1.25,2.43,0,4.36-.91,5.8-2.74,1.44-1.83,2.16-4.36,2.16-7.59Z" />
      <path d="m457.33,20.29v.97c1.41-1.86,2.95-3.22,4.62-4.09s3.59-1.31,5.77-1.31,4,.46,5.67,1.38c1.66.92,2.91,2.22,3.73,3.9.53.98.87,2.04,1.03,3.17.16,1.14.24,2.58.24,4.34v14.91c0,1.6-.37,2.82-1.1,3.64-.73.82-1.69,1.23-2.86,1.23s-2.16-.42-2.91-1.26c-.74-.84-1.11-2.04-1.11-3.61v-13.35c0-2.64-.37-4.66-1.1-6.06-.73-1.4-2.2-2.1-4.39-2.1-1.43,0-2.73.43-3.9,1.28-1.17.85-2.04,2.02-2.58,3.51-.39,1.19-.59,3.42-.59,6.69v10.04c0,1.62-.38,2.84-1.13,3.65-.75.81-1.73,1.22-2.92,1.22s-2.1-.42-2.85-1.26-1.11-2.04-1.11-3.61v-23.16c0-1.53.33-2.67,1-3.42.67-.75,1.57-1.13,2.73-1.13.7,0,1.34.17,1.91.5.57.33,1.02.83,1.37,1.5.34.67.51,1.48.51,2.44Z" />
      <path d="m508.72,34.12h-15.67c.02,1.82.38,3.42,1.1,4.81s1.66,2.44,2.85,3.14c1.18.71,2.49,1.06,3.92,1.06.96,0,1.83-.11,2.63-.34.79-.22,1.56-.58,2.3-1.06.75-.48,1.43-.99,2.06-1.54.63-.55,1.44-1.29,2.44-2.23.41-.35,1-.53,1.76-.53.82,0,1.49.22,2,.68s.76,1.09.76,1.91c0,.72-.28,1.57-.85,2.54s-1.42,1.9-2.57,2.79c-1.15.89-2.58,1.63-4.32,2.22-1.73.59-3.72.88-5.97.88-5.15,0-9.15-1.47-12-4.4-2.86-2.93-4.29-6.92-4.29-11.95,0-2.37.35-4.56,1.06-6.59.71-2.03,1.73-3.76,3.08-5.21s3.01-2.56,4.99-3.33c1.98-.77,4.17-1.16,6.57-1.16,3.13,0,5.82.66,8.06,1.98,2.24,1.32,3.92,3.03,5.03,5.12,1.11,2.09,1.67,4.23,1.67,6.4,0,2.02-.58,3.32-1.73,3.92-1.15.6-2.78.9-4.87.9Zm-15.67-4.55h14.53c-.2-2.74-.93-4.79-2.22-6.15-1.28-1.36-2.97-2.04-5.06-2.04s-3.63.69-4.92,2.07c-1.28,1.38-2.06,3.42-2.33,6.12Z" />
    </g>
  </svg>
);

AmicaOneLogo.propTypes = {
  width: PropTypes.string,
};

AmicaOneLogo.defaultProps = {
  width: '92px',
};

export default AmicaOneLogo;
