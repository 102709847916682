import React from 'react';

import Paragraph from 'Common/UI/Text/Paragraph';
import { H1Conversational } from 'Common/UI/Text/Headings';
import {
  getUpdatedItemValues,
  getHighlightStatementValuesFunction,
  getWasUpdatedFunction,
  getRedactFunction,
  getFirstName,
} from 'Common/Utils/statementHelpers';
import formatCurrency from 'Common/Utils/formatCurrency';
import MatterProps from 'Common/Utils/MatterProps';
import { QuestionAny, Statement } from 'Common/Data/Types/appSections';
import { PARTY_A, PARTY_B } from 'Common/constants';

// Note any disabled props will not be saved into the database
export const yourSuperannuationQuestionsContinue = (): QuestionAny[] => [
  {
    name: 'info',
    type: 'info',
    label: 'The next group of questions is about superannuation.',
    content:
      "Even though you can't split superannuation using amica, the Court requires certain details about your superannuation interests.",
    index: 0,
  },
  // What to do when there is no super?
  {
    name: 'whatIsYourSuperName',
    label: 'What is the name of your superannuation plan?',
    type: 'textfield',
    hint: 'Super name',
    defaultValue: (data: any) => data.superannuationName || 'Has No Super Fund',
    description: `You and ${MatterProps(
      'other.firstname',
      'Your former partner'
    )} have already agreed to this in previous section`,
    disabled: true,
    index: 1,
  },
  {
    name: 'whatIsYourSuperValue',
    label:
      'Specify the current agreed gross value of the interest in superannuation.',
    type: 'textfield',
    hint: 'Super value',
    defaultValue: (data: any) =>
      data.superannuationValue && data.superannuationValue !== '0'
        ? formatCurrency(data.superannuationValue)
        : 'Has No Super Fund',
    disabled: true,
    description: `You and ${MatterProps(
      'other.firstname',
      'Your former partner'
    )} have already agreed to this in previous section`,
    index: 2,
  },
  {
    name: 'isInterestSubjectToEarlierPaymentSplit',
    label:
      'For each interest, whether or not a splitting order is sought, advise if the interest is subject to an earlier payment split.',
    type: 'yesno',
    help: {
      label: 'Learn more',
      content: (
        <>
          <H1Conversational>Learn more</H1Conversational>
          <Paragraph large>
            This question is asking whether any of your superannuation interests
            have been subject to an earlier payment split.
          </Paragraph>
        </>
      ),
    },
    index: 3,
  },
  {
    name: 'amicaIsUnsuitable',
    type: 'info',
    exitOnSubmit: true,
    label:
      'amica is not suitable where one of you has a superannuation interest that is subject to an earlier payment split.',
    content: () => (
      <>
        If you would like help about your situation you can call the free{' '}
        <a
          href="https://www.amica.gov.au/legal-help-line.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal Help Line
        </a>{' '}
        in your state or territory.
      </>
    ),
    visible: (answer: any) =>
      Boolean(answer.isInterestSubjectToEarlierPaymentSplit),
    index: 4,
  },
  {
    name: 'info2',
    type: 'info',
    label:
      'The court requires parties to provide the information in the next two questions regardless of whether they are splitting superannuation or not.',
    content: '(You cannot split superannuation using amica.)',
    index: 5,
  },
  {
    name: 'surchargeDebtGrowth',
    type: 'currency',
    label:
      'For each interest: If the interest is a defined benefit interest in the growth phase (not being an interest in a constitutionally protected fund), state the amount of any surcharge debt in the most recent member statement.',
    hint: 'Amount',
    help: {
      label: 'Learn more',
      content: (
        <>
          <H1Conversational>Learn more</H1Conversational>
          <Paragraph large>
            If you are unsure of this value you can contact your superannuation
            fund or if you have a myGov account you can access superannuation
            information on{' '}
            <a
              href="https://my.gov.au/LoginServices/main/login?execution=e2s1"
              target="_blank"
              rel="noopener noreferrer"
            >
              myGov
            </a>
            .
          </Paragraph>
        </>
      ),
    },
    optional: true,
    index: 6,
  },
  {
    name: 'surchargeDebtConstitutionallyProtected',
    type: 'currency',
    label:
      'For each interest: If the interest is in a constitutionally protected fund, state the amount of any surcharge in the surcharge debt account.',
    hint: 'Amount',
    help: {
      label: 'Learn more',
      content: (
        <>
          <H1Conversational>Learn more</H1Conversational>
          <Paragraph large>
            If you are unsure of this value you can contact your superannuation
            fund or if you have a myGov account you can access superannuation
            information on{' '}
            <a
              href="https://my.gov.au/LoginServices/main/login?execution=e2s1"
              target="_blank"
              rel="noopener noreferrer"
            >
              myGov
            </a>
            .
          </Paragraph>
        </>
      ),
    },
    optional: true,
    index: 7,
  },
  {
    name: 'taxationConsequences',
    label:
      'Do you think you have any taxation consequences of any order sought in relation to any interest in superannuation?',
    hint: '',
    type: 'yesno',
    help: {
      label: 'Learn more',
      content: (
        <>
          <H1Conversational>Learn more</H1Conversational>
          <Paragraph large>
            If your situation includes superannuation entitlements, you would
            each keep your superannuation entitlements under any agreement made
            on amica, because amica does not enable parties to split
            superannuation.
            <br />
            On that basis the usual position is that you will each be liable to
            pay the tax on your own superannuation entitlement when you access
            your superannuation.
          </Paragraph>
          <Paragraph large>
            If you want legal help about tax consequences in relation to any
            interest in superannuation you can contact the free{' '}
            <a
              href="https://www.amica.gov.au/legal-help-line.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Legal Help Line
            </a>{' '}
            in your state.
          </Paragraph>
        </>
      ),
    },
    index: 8,
  },
  {
    name: 'describeTaxationConsequences',
    type: 'freetext',
    hint: 'A brief description',
    label:
      'What are the taxation consequences of any order sought in relation to any interest in superannuation?',
    visible: (answer: any) => Boolean(answer.taxationConsequences),
    index: 9,
  },
  {
    name: 'statement',
    type: 'statement',
    label: 'You created a statement',
    index: 10,
  },
];

export const yourSuperannuationStatementContinue = ({
  matter,
  data,
  card,
  withUpdateLink = false,
  plainText = false,
  hideSensitive = false,
}: Statement) => {
  const party = data.CardIndex || matter.self.party;
  const itemDiff = getUpdatedItemValues(data, matter.itemHistory, [
    'superannuationName',
  ]);
  const highlight = getHighlightStatementValuesFunction(plainText);
  const updated = getWasUpdatedFunction(
    itemDiff,
    plainText,
    withUpdateLink,
    card
  );
  const redact = getRedactFunction(
    yourSuperannuationQuestionsContinue(),
    data,
    hideSensitive
  );

  let firstName;
  if (party === PARTY_A) {
    firstName = getFirstName(matter.partyA, PARTY_A, hideSensitive);
  } else {
    firstName = getFirstName(matter.partyB, PARTY_B, hideSensitive);
  }

  // Data
  const {
    superannuationValue,
    surchargeDebtGrowth,
    surchargeDebtConstitutionallyProtected,
    taxationConsequences,
    describeTaxationConsequences,
  } = data;

  // Currency format
  const superValue = formatCurrency(parseFloat(superannuationValue));

  // Sensitive Data that should not appear on LSC email data collection
  const superName = updated('superannuationName', redact('superannuationName'));

  const statementIntro = `${firstName} has ${superValue} in Superannuation with ${superName}. The interest is not subject to an earlier payment split.`;
  const statementIntroHighlight = highlight`${firstName} has ${superValue} in Superannuation with ${superName}. The interest is not subject to an earlier payment split.`;

  const statementsurchargeDebtGrowth =
    surchargeDebtGrowth && surchargeDebtGrowth !== '0'
      ? `${firstName}’s superannuation fund is a defined benefit interest in the growth phase with ${formatCurrency(
          parseFloat(surchargeDebtGrowth)
        )} in surcharge debt in the most recent member statement.`
      : '';
  const statementsurchargeDebtGrowthHighlight =
    surchargeDebtGrowth && surchargeDebtGrowth !== '0'
      ? highlight`${firstName}’s superannuation fund is a defined benefit interest in the growth phase with ${formatCurrency(
          parseFloat(surchargeDebtGrowth)
        )} in surcharge debt in the most recent member statement.`
      : '';

  const statementSurchageDebtConstitutionallProtected =
    surchargeDebtConstitutionallyProtected &&
    surchargeDebtConstitutionallyProtected !== '0'
      ? `${firstName}’s superannuation fund is in a constitutionally protected fund with ${formatCurrency(
          parseFloat(surchargeDebtConstitutionallyProtected)
        )} in the surcharge debt account.`
      : '';
  const statementSurchageDebtConstitutionallProtectedHighlight =
    surchargeDebtConstitutionallyProtected &&
    surchargeDebtConstitutionallyProtected !== '0'
      ? highlight`${firstName}’s superannuation fund is in a constitutionally protected fund with ${formatCurrency(
          parseFloat(surchargeDebtConstitutionallyProtected)
        )} in the surcharge debt account.`
      : '';

  const statementTaxationConsequence = taxationConsequences
    ? `${firstName} does have taxation consequences in relation to any interest in superannuation. ${describeTaxationConsequences}`
    : `${firstName} does not have any taxation consequences in relation to any interest in superannuation`;
  const statementTaxationConsequenceHighlight = taxationConsequences
    ? highlight`${firstName} does have taxation consequences in relation to any interest in superannuation. ${describeTaxationConsequences}`
    : highlight`${firstName} does not have any taxation consequences in relation to any interest in superannuation`;

  if (plainText) {
    return `${statementIntro} ${statementsurchargeDebtGrowth} ${statementSurchageDebtConstitutionallProtected} ${statementTaxationConsequence}`;
  }

  return (
    <>
      {statementIntroHighlight}
      {statementsurchargeDebtGrowthHighlight}
      {statementSurchageDebtConstitutionallProtectedHighlight}
      {statementTaxationConsequenceHighlight}
    </>
  );
};
