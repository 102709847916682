/* eslint-disable no-case-declarations */
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import Analytics from 'Common/Utils/Analytics';

import Matter, {
  InviteRecord,
  Message,
  SuggestedDivisionRound,
} from 'Common/Data/Types/matter';

import {
  PARTY_A,
  PARTY_B,
  DEFAULT_SELF_TEXT,
  DEFAULT_OTHER_TEXT,
} from 'Common/constants';
import getChildrenData from 'Common/Utils/getChildrenData';
import capitaliseText from 'Common/Utils/capitaliseText';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  self: {
    firstname: capitaliseText(DEFAULT_SELF_TEXT),
    party: PARTY_A,
  },
  other: {
    firstname: capitaliseText(DEFAULT_OTHER_TEXT),
    party: PARTY_B,
  },
};

const matterSlice = createSlice({
  name: 'matter',
  initialState: initialState as Matter,
  reducers: {
    matterUpdatePartyDetails(state, action) {
      const { party, details } = action.payload;

      if (party === PARTY_A) {
        return {
          ...state,
          partyA: {
            ...state.partyA,
            ...details,
          },
        };
      }

      return {
        ...state,
        partyB: {
          ...state.partyB,
          ...details,
        },
      };
    },
    matterLoadMatterSuccess(_state, action: PayloadAction<{ matter: Matter }>) {
      const { matter } = action.payload;
      let messages: any = [];
      const divi = { ...matter.divi } || {};
      let mergedRounds = [];

      // add an empty object for matter flags if it doesn't exist
      // to prevent errors when trying to read them in the app
      if (!matter.flags) {
        matter.flags = {};
      }

      if (matter && matter.messages) {
        messages = matter.messages.map((message: Message) => {
          const [section, date] = message.MessageID.split('|');

          return {
            ...message,
            section,
            date,
          };
        });
      }

      // join division messages to rounds
      if (divi && divi.rounds) {
        mergedRounds = divi.rounds.map(
          (round: SuggestedDivisionRound, index: number) => {
            const roundMessage = messages.find(
              (message: Message) => message.section === `division${index + 1}`
            );

            return { ...round, message: roundMessage };
          }
        );
        divi.rounds = mergedRounds;
      }

      const childrenData = getChildrenData(matter.items);

      const other = { ...matter.other } || {};
      const partyB = { ...matter.partyB } || {};

      // if the other party hasn't been invited yet, set their firstname to `your former partner` instead of null
      if (!matter.other.email) {
        other.firstname = DEFAULT_OTHER_TEXT;
        partyB.firstname = DEFAULT_OTHER_TEXT;
      }

      // @TODO remove this once we are finished work on versions
      if (
        process.env.NODE_ENV !== 'production' &&
        process.env.NODE_ENV !== 'test'
      ) {
        console.log(`Loaded version ${matter.version}`);
      }

      // @TODO remove this! i'm just too lazy to click 3 buttons to get the ItemID so I added this
      (window as any).m = () => console.log(matter.MatterID);

////////////////////////////////////
////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

///////////////
//////////////////
///////////////////
///////////////////
//////////////////////
//////////////////////////
//////////////////////////////////
////////////////////////////////
////////////////////////////////////
/////////////////////////
////////////
///////////////////
//////////////////////
//////////////////////////
//////////////////////////////////
////////////////////////////////
////////////////////////////////////
/////////////////////////
////////////
////////////////
//////////////////
////////////////
///////////////////

/////////////////////////////
//////////////////
/////////////////////////////////
//////////////////////////////
//////////////////
///////////////////
/////////////////////////////
/////////////////////////////
////////////////
//////////

//////////////////////////////
//////////////////////////////
/////////////////////////
////////////////////////////
//////////////////////
//////////

////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////
///////////
//////////
////////
////////////////

      const newMatter: Matter = {
        ...matter,
        messages,
        divi,
        childrenData,
        other,
        partyB,
      };

      const { MatterID, self: { sub = undefined } = {} } = matter;

      Sentry.setUser({
        matterID: MatterID,
        id: sub,
      });

      Analytics.dataLayer({
        userId: sub,
      });

      return { ...newMatter, lastRefreshed: moment.now() };
    },
    matterLoadInviteSuccess(state, action: PayloadAction<InviteRecord>) {
      return {
        ...state,
        invite: action.payload,
        self: {
          ...state.self,
          firstname: action.payload.firstname,
        },
        other: {
          ...state.other,
          firstname: action.payload.otherfirstname,
        },
      };
    },
    clearMatterStore() {
      Analytics.dataLayer({
        userId: undefined,
      });

      return initialState as Matter;
    },
  },
});

export const {
  matterUpdatePartyDetails,
  matterLoadMatterSuccess,
  matterLoadInviteSuccess,
  clearMatterStore,
} = matterSlice.actions;

export default matterSlice.reducer;
