import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  REMOVE_SUPERANNUATION_REQUESTEE_DISMISS_CONFIRMATION_BANNER,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTEE,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import AssetProperties from 'Common/Data/App/assetProperties';
import { removeSuperannuationAction } from 'App/State/MatterActions';
import usePromiseWithLoading from 'Common/Utils/Hooks/usePromiseWithLoading';
import { useAppDispatch } from 'App/State/Store';
import getPartyKeys from 'Common/Utils/getPartyKeys';

export const superSplittingRemoveSuperannuationApprovedRequesteeFlags: AppState =
  {
    id: 'SuperSplittingRemoveSuperannuationApprovedRequestee',
    description: 'This party approved the request to remove superannuation',
    check: () => {
      const { superSplittingFlags } = AssetProperties();

      const {
        removeSuperannuationInProgress,
        removeSuperannuationRequestedBy,
        removeSuperannuationPartyDismissedConfirmationBanner,
        removeSuperannuationRequestStatus,
      } = superSplittingFlags;

      // if the remove super flow has not been started, then this app state is not relevant
      if (!removeSuperannuationInProgress) {
        return false;
      }

      const partyKeys = getPartyKeys();

      const otherRequestedRemoveSuper =
        removeSuperannuationRequestedBy === partyKeys.other;

      // other party has requested to remove super, this party has approved the request, and this party has not yet dismissed the confirmation banner
      if (
        otherRequestedRemoveSuper &&
        removeSuperannuationRequestStatus === 'APPROVED' &&
        removeSuperannuationPartyDismissedConfirmationBanner !== partyKeys.self
      ) {
        return true;
      }

      return false;
    },
    flags: {
      appState: SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTEE,
    },
  };

type Props = {
  otherName: string;
};

export const SuperSplittingRemoveSuperannuationApprovedRequestee: React.FC<Props> =
  ({ otherName }) => {
    const { withLoading } = usePromiseWithLoading();
    const dispatch = useAppDispatch();

    const onDismissBanner = () =>
      withLoading(async () => {
        await dispatch(
          removeSuperannuationAction(
            REMOVE_SUPERANNUATION_REQUESTEE_DISMISS_CONFIRMATION_BANNER
          )
        );
      });

    return (
      <>
        <SpacedBox marginBottom={8}>
          <Heading2MarginTopNone>
            We’ll let {otherName} know you agree to remove superannuation from
            your division of assets.
          </Heading2MarginTopNone>
          <Paragraph>
            Superannuation will now be removed from your division of assets.
            You’ll need to restart the asset division section.
          </Paragraph>
        </SpacedBox>
        <GenericButton fullWidth onClick={onDismissBanner} small>
          Got it
        </GenericButton>
      </>
    );
  };
