import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import diviProperties from 'Common/Data/App/diviProperties';
import { Heading2, HeadingSpacedContainer } from 'Common/UI/Text/Headings';
import RoundButton from 'Common/UI/Button/RoundButton';
import Appears from 'Common/UI/Layout/Appears';
import FlexGrid, { OffsetColumn } from 'Common/UI/Layout/FlexGrid';
import getDate from 'Common/Utils/getDate';

import YouReceiveIndicator from '../Indicator/YouReceiveIndicator';
import DivisionLearnMore from '../DivisionLearnMore';
import { SCREEN_STATE_VIEW } from '../constants';

const HistoryWrapper = styled.div`
  background: ${({ viewState, theme }) =>
    viewState !== 'action'
      ? theme.colours.transparentGray10
      : theme.colours.offWhite};
  padding: ${({ theme }) =>
    `${theme.padding.medium}px ${theme.padding.medium}px ${theme.padding.xsmall}px`};
  margin-bottom: 60px; /* make sure theres enough viewport */

  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    padding: ${({ theme }) =>
      `${theme.padding.medium}px ${theme.padding.xlarge}px`};
    margin-top: auto;
    margin-bottom: 0;
  }
`;

const PageBreak = styled.div`
  display: block;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: ${props => props.theme.colours.transparentGray20};
  height: 1px;
`;

const ToggleButton = styled(RoundButton)`
  background-color: ${({ theme }) => theme.colours.transparentGray20};
  ${props =>
    props.viewState !== SCREEN_STATE_VIEW &&
    css`
      color: ${props.theme.colours.black};
    `}
  font-size: 13px;
  font-weight: 600;
`;

const IndicatorWrapper = styled.div`
  padding-top: ${({ theme }) => theme.padding.xxxsmall}px;
  @media ${({ theme }) => theme.sizes.md.breakpoint} {
    margin-bottom: ${({ theme }) => theme.padding.large}px;
  }
`;

const NegotiationHistory = ({ viewState, showLatestOffer }) => {
  const { TAP, division, rounds, created } = diviProperties();
  const [toggleMessages, setToggleMessages] = useState(true);
  const roundsExists = Boolean(rounds && rounds.length);

  const history = [];
  history.push({
    name: 'history0',
    party: 'suggestedDivision',
    date: created,
    ratio: division,
  });

  if (roundsExists) {
    rounds
      .slice(0, showLatestOffer ? rounds.length : rounds.length - 1) // remove the newest round from the history since it's displayed at the top
      .forEach(({ split, party, offerMade, message }, index) => {
        history.push({
          name: `history${index + 1}`,
          party,
          date: offerMade,
          ratio: split,
          message,
        });
      });
  }

  return (
    <HistoryWrapper viewState={viewState} data-cy="suggested-division-history">
      <HeadingSpacedContainer align="center">
        <Heading2>History</Heading2>
        {rounds.length > 1 && (
          <ToggleButton
            small
            viewState={viewState}
            onClick={() => setToggleMessages(!toggleMessages)}
          >
            {toggleMessages ? 'Hide messages' : 'Show messages'}
          </ToggleButton>
        )}
      </HeadingSpacedContainer>
      <FlexGrid medium={2} large={2} align="flex-end">
        <div>
          {history
            .reverse()
            .map(({ name, date, party, ratio, message }, index) => (
              <IndicatorWrapper key={name}>
                <YouReceiveIndicator
                  party={party}
                  date={getDate(date).shortFormat()}
                  ratio={ratio}
                  totalAssetPool={TAP}
                  message={message}
                  toggleMessages={toggleMessages}
                />
                {index !== history.length - 1 && <PageBreak />}
              </IndicatorWrapper>
            ))}
        </div>

        <OffsetColumn>
          <Appears on={toggleMessages}>
            <DivisionLearnMore />
          </Appears>
        </OffsetColumn>
      </FlexGrid>
    </HistoryWrapper>
  );
};

NegotiationHistory.propTypes = {
  viewState: PropTypes.string.isRequired,
  showLatestOffer: PropTypes.bool,
};

NegotiationHistory.defaultProps = {
  showLatestOffer: true,
};

export default NegotiationHistory;
