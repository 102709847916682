import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  ODRS_START_ROUTE,
  ODRS_DASH_HOME_ROUTE,
  ODRS_INVITE_ROUTE,
  ODRS_INVITE_PARTNER_ROUTE,
  ODRS_RE_SEND_INVITE_ROUTE,
  ODRS_INVITE_PARTNER_BLOCK_ROUTE,
  ODRS_INVITE_PARTNER_SENT_ROUTE,
} from 'Common/routes';
import PartnerInviteSent from 'App/UI/Signup/PartnerInviteSent';

import Invite from 'App/UI/Signup/Join';
import InvitePartner from 'App/UI/Signup/InvitePartner';
import BlockPartnerInvite from 'App/UI/Signup/BlockPartnerInvite';
import Signup from 'App/Pages/Signup';
import ReSendInviteLink from 'App/UI/Signup/ReSendInviteLink';
import MatterGuard from '../Guard/MatterGuard';

const ODRS = () => (
  <Switch>
    <Route
      exact
      path={ODRS_START_ROUTE}
      render={() => (
        <MatterGuard expectNone destination={ODRS_DASH_HOME_ROUTE}>
          <Signup />
        </MatterGuard>
      )}
    />
    <Route
      exact
      path={ODRS_RE_SEND_INVITE_ROUTE}
      render={props => (
        <MatterGuard expectNone>
          <ReSendInviteLink {...props} />
        </MatterGuard>
      )}
    />
    <Route
      exact
      path={ODRS_INVITE_PARTNER_ROUTE}
      render={props => (
        <MatterGuard>
          <InvitePartner {...props} />
        </MatterGuard>
      )}
    />
    <Route
      exact
      path={ODRS_INVITE_PARTNER_SENT_ROUTE}
      render={props => (
        <MatterGuard>
          <PartnerInviteSent {...props} />
        </MatterGuard>
      )}
    />
    <Route
      exact
      path={ODRS_INVITE_PARTNER_BLOCK_ROUTE}
      render={props => (
        <MatterGuard>
          <BlockPartnerInvite {...props} />
        </MatterGuard>
      )}
    />
    <Route
      exact
      path={ODRS_INVITE_ROUTE}
      render={() => (
        <MatterGuard expectNone destination={ODRS_DASH_HOME_ROUTE}>
          <Invite />
        </MatterGuard>
      )}
    />
  </Switch>
);

export default ODRS;
