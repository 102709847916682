import { DATA_PAGE } from 'Common/constants';

const generateDashboardTabs = ({ sectionData, matter }) =>
  sectionData.children
    .filter(child => child.type === DATA_PAGE)
    .map(
      ({
        title: label,
        route: to,
        generateStatus = () => {},
        id,
        lockedTooltip,
      }) => ({
        label,
        to,
        status: generateStatus(matter),
        testId: `${id}-tab`,
        lockedTooltip,
      })
    );

export default generateDashboardTabs;
