import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { HOME_ROUTE, ODRS_START_ROUTE } from 'Common/routes';
import colours from 'Common/Utils/colours';
import Loader from 'Common/UI/Loader/Loader';
import BackButton from 'Common/UI/Button/BackButton';
import { Callout } from 'Common/UI/Text/Paragraph';
import baseTheme, { pastelPurpleTheme } from 'Common/Utils/theme';
import PageHeader from 'Common/UI/Layout/PageHeader';
import { Sent } from 'Common/UI/Layout/Completed';
import StepTransition from 'App/UI/Transitions/StepTransition';
import { RouteContext } from 'App/UI/Transitions/RouteContext';
import { resendInviteAction } from 'App/State/UserActions';
import { ProgressBarProvider } from 'Common/Utils/ProgressBarContext';
import {
  openOdie as openOdieAction,
  updateOdie as updateOdieAction,
} from 'App/State/OdieReducer';
import Nude from 'Common/UI/Nude';
import ProgressBar from 'Common/UI/ProgressBar';
import OnboardingResendInvite from './Common/OnboardingResendInvite';
import {
  StyledPageContent,
  StyledPageFooter,
} from '../../../../../../common/UI/Signup/Layout';

class InvitePartner extends React.Component {
  constructor(props) {
    super(props);

    this.steps = [
      {
        step: 'intro',
        stepNumber: 1,
        showProgress: true,
        hideBackButton: false,
      },
      {
        step: 'invite',
        stepNumber: 2,
        showProgress: true,
        theme: pastelPurpleTheme,
        hideBackButton: true,
      },
      {
        step: 'thanks',
        stepNumber: 3,
        showProgress: false,
        hideBackButton: true,
        theme: pastelPurpleTheme,
      },
    ];

    this.state = {
      step: false,
      currentStep: 0,
    };

    this.goBack = this.goBack.bind(this);
    this.goNext = this.goNext.bind(this);
    this.onQuestionsSubmit = this.onQuestionsSubmit.bind(this);
  }

  componentDidMount() {
    // Must have passed InviteID to route or redirect to start
    const { location, navigate } = this.props;
    if (location && !location.state.InviteID) {
      navigate(ODRS_START_ROUTE);
    }
    this.setState({ step: this.steps[0] });
  }

  // change the theme for the current step if needed
  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    const { setTheme } = this.context;
    if (this.steps.indexOf(prevState.step) !== this.steps.indexOf(step)) {
      setTheme(step.theme || baseTheme);
    }
  }

  onQuestionsSubmit() {
    this.goNext();
  }

  goNext() {
    const { step, currentStep } = this.state;
    const cur = this.steps.indexOf(step);

    if (this.steps[cur + 1]) {
      this.setState({
        step: this.steps[cur + 1],
        currentStep: currentStep + 1,
      });
    }
  }

  goBack() {
    const { step, currentStep } = this.state;
    const { navigate } = this.props;
    const cur = this.steps.indexOf(step);

    if (this.steps[cur - 1]) {
      this.setState({
        step: this.steps[cur - 1],
        currentStep: currentStep - 1,
      });
    } else {
      this.setState(
        {
          step: false,
        },
        () => {
          navigate(ODRS_START_ROUTE);
        }
      );
    }
  }

  render() {
    const { step } = this.state;

    const { resendInvite, location } = this.props;
    const inviteData = location.state;
    let stepComponent;

    switch (step.step) {
      case 'intro':
        stepComponent = (
          <OnboardingResendInvite
            inviteData={inviteData}
            goNext={this.goNext}
            onChange={(name, updateValue) => {
              this.onInputChange(name, updateValue);
            }}
          />
        );
        break;
      case 'invite':
        stepComponent = (
          <Nude
            key={step}
            componentDidMount={() => {
              if (inviteData.InviteID) {
                resendInvite(inviteData.InviteID).then(this.goNext);
              }
            }}
          >
            <Loader colour={colours.purple} />
          </Nude>
        );
        break;
      case 'thanks':
        stepComponent = (
          <Sent buttonLabel="Got it" buttonRoute={HOME_ROUTE}>
            <Callout>
              Please check your email. We have resent an invite link to:{' '}
              <b>{inviteData.email}</b>
              <br />
              <br />
              If you can&apos;t find the invite email, try checking your spam
              folder.
            </Callout>
          </Sent>
        );
        break;
      default:
        stepComponent = <p key="default">default</p>;
    }

    return (
      <>
        <ProgressBarProvider>
          <PageHeader show={!step.hideBackButton}>
            <BackButton onClick={this.goBack} subtle />
          </PageHeader>
          <StyledPageContent>
            <StepTransition pageKey={step.step} transitionTime={500} appear>
              {step.step ? stepComponent : null}
            </StepTransition>
          </StyledPageContent>
        </ProgressBarProvider>
        <StyledPageFooter>
          <ProgressBar
            label="Sign up"
            currentStep={step.stepNumber}
            steps={this.steps.length}
            hidden={!step.showProgress}
          />
        </StyledPageFooter>
      </>
    );
  }
}

InvitePartner.propTypes = {
  navigate: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      InviteID: PropTypes.string,
      otherName: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  matter: PropTypes.object, // eslint-disable-line
};

InvitePartner.defaultProps = {
  location: {},
};
InvitePartner.contextType = RouteContext;

const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  resendInvite: InviteID => dispatch(resendInviteAction(InviteID)),
  openOdie: () => dispatch(openOdieAction()),
  updateOdie: data => dispatch(updateOdieAction(data)),
  navigate: to => dispatch(push(to)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvitePartner));
