import React from 'react';
import { Switch } from 'react-router-dom';

import { ODRS_PROFILE_ROUTE, ODRS_PROFILE_UPDATE_ROUTE } from 'Common/routes';
import DiviRoute from 'Common/UI/DiviRoute';
import Profile from 'App/UI/Profile/Profile';
import ProfileUpdate from 'App/UI/Profile/ProfileUpdate';
import MatterGuard from '../Guard/MatterGuard';

const ODRSProfile = () => (
  <MatterGuard>
    <Switch>
      <DiviRoute exact path={ODRS_PROFILE_ROUTE} component={Profile} />
      <DiviRoute
        exact
        path={ODRS_PROFILE_UPDATE_ROUTE}
        component={ProfileUpdate}
      />
    </Switch>
  </MatterGuard>
);

export default ODRSProfile;
