import React from 'react';

import { ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE } from 'Common/routes';
import { Success } from 'Common/UI/Layout/Completed';
import { H1Conversational } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import getNames from 'Common/Utils/getNames';

const LetterReceivedConfirmation: React.FC = () => {
  const otherName = getNames().other;

  return (
    <Success
      buttonRoute={ODRS_PROPERTY_MONEY_AND_PROPERTY_ROUTE}
      buttonLabel="Back to the dashboard"
    >
      <H1Conversational>Great, you’ve received a letter back</H1Conversational>

      <Paragraph>
        The letter from {otherName}'s superannuation fund will include
        information relating to their superannuation fund. You can upload the
        letter and either agree to the superannuation balance, or ask{' '}
        {otherName} to make changes.
      </Paragraph>
    </Success>
  );
};

export default LetterReceivedConfirmation;
