import {
  INVITE_OTHER_PARTY,
  SUGGESTED_DIVISION_START,
  SUGGESTED_DIVISION_WAITING,
  SUGGESTED_DIVISION_READY,
  SUGGESTED_DIVISION_AGREED,
  SUGGESTED_DIVISION_YOU_MADE_OFFER,
  SUGGESTED_DIVISION_OTHER_MADE_OFFER,
  SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER,
  RELATIONSHIP_DASHBOARD_NOT_COMPLETED,
  PARENTING_PLAN_HAVE_NO_CHILDREN,
  PARENTING_PLAN_START,
  PARENTING_PLAN_AGREED,
  PARENTING_PLAN_WAITING_FOR_OTHER,
  PARENTING_PLAN_NEEDS_CONFIRMATION,
  PARENTING_PLAN_SELF_NOT_READY,
  PARENTING_PLAN_OTHER_NOT_READY,
  NEW_PARENTING_PLAN_WAITING_FOR_OTHER,
  NEW_PARENTING_PLAN_NEEDS_CONFIRMATION,
  NEW_PARENTING_PLAN_SELF_NOT_READY,
  NEW_PARENTING_PLAN_OTHER_NOT_READY,
  PROPERTY_DASHBOARD_COMPLETED,
  ASSET_SPLIT_OTHER_PARTY_COMPLETING,
  CONSENT_ORDER_GET_STARTED_COMPLETE,
  CONSENT_ORDERS_GET_STARTED_REQUIREMENTS,
  CONSENT_ORDERS_GET_STARTED_NOT_SUITABLE,
  CONSENT_ORDER_RELATIONSHIP_COMPLETE,
  CONSENT_ORDER_FINANCES_COMPLETE,
  CONSENT_ORDER_ASSETS_COMPLETE,
  DOCUMENT_TYPES_OTHER_NOT_READY,
  DOCUMENT_TYPES_WAITING_FOR_OTHER,
  PROPERTY_AGREEMENT_NEEDS_CONFIRMATION,
  CONSENT_ORDER_NEEDS_CONFIRMATION,
  SUGGESTED_DIVISION_CANT_CONTINUE,
  ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER,
  ASSET_SPLIT_VIEWED_BY_OWED_PARTY,
  ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER,
  ASSET_SPLIT_OFFER_SENT,
  PARENTING_PLAN_PAYMENT_REQUIRED,
  DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
  DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED,
  CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED,
  ASSET_SPLIT_SELF_PARTY_COMPLETING,
  DASHBOARD_TAB_LOCKED,
  RELATIONSHIP_DASHBOARD_COMPLETED,
  RELATIONSHIP_DASHBOARD_COMPLETED_AND_LOCKED,
  RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER,
  RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF,
  RESET_SUGGESTED_DIVISION_OTHER_NOT_READY,
  CONSENT_ORDER_SUPERANNUATION_NOT_SUITABLE,
  CONSENT_ORDER_GET_STARTED_LOCKED,
  SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYEE,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_COMPLETE_PAYEE,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_ACCEPTED_PAYER,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_OTHER_PAYER,
  SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYER,
  SUPER_SPLITTING_CONSENT_ORDER_WAITING_FOR_OTHER_TO_SEND_PAYEE,
  SUPER_SPLITTING_CONSENT_ORDER_VIEW_PFL_PAYER,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_SELF_PAYEE,
  SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE,
  SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER,
  SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER,
  SUPERANNUATION_REQUEST_BALANCE_COMPLETED_REQUESTER,
  SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_OTHER,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTER,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTEE,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTEE,
  SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTER,
} from 'Common/constants';
import theme, {
  darkTheme,
  orangeTheme,
  pastelPurpleTheme,
  greenTheme,
  lightGreenTheme,
} from 'Common/Utils/theme';

const getBannerTheme = bannerState => {
  switch (bannerState) {
    case INVITE_OTHER_PARTY:
    case PROPERTY_DASHBOARD_COMPLETED:
    case PARENTING_PLAN_START:
    case PARENTING_PLAN_AGREED:
    case SUGGESTED_DIVISION_START:
    case SUGGESTED_DIVISION_READY:
    case SUGGESTED_DIVISION_AGREED:
    case SUGGESTED_DIVISION_YOU_MADE_OFFER:
    case SUGGESTED_DIVISION_OTHER_MADE_OFFER:
    case SUGGESTED_DIVISION_OTHER_MADE_COUNTER_OFFER:
    case ASSET_SPLIT_SELF_PARTY_COMPLETING:
    case ASSET_SPLIT_OTHER_PARTY_INITIAL_OFFER:
    case ASSET_SPLIT_VIEWED_BY_OWED_PARTY:
    case ASSET_SPLIT_OTHER_PARTY_COUNTER_OFFER:
    case PROPERTY_AGREEMENT_NEEDS_CONFIRMATION:
    case CONSENT_ORDER_NEEDS_CONFIRMATION:
    case RELATIONSHIP_DASHBOARD_COMPLETED:
    case RELATIONSHIP_DASHBOARD_COMPLETED_AND_LOCKED:
    case PARENTING_PLAN_PAYMENT_REQUIRED:
    case DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED:
    case DOCUMENT_TYPES_PROPERTY_AGREEMENT_PAYMENT_REQUIRED:
    case PARENTING_PLAN_NEEDS_CONFIRMATION:
    case PARENTING_PLAN_SELF_NOT_READY:
    case NEW_PARENTING_PLAN_SELF_NOT_READY:
    case NEW_PARENTING_PLAN_NEEDS_CONFIRMATION:
    case RESET_SUGGESTED_DIVISION_WAITING_FOR_SELF:
    case RESET_SUGGESTED_DIVISION_OTHER_NOT_READY:
    case SUPERANNUATION_REQUEST_BALANCE_STARTED_REQUESTER:
    case SUPERANNUATION_REQUEST_BALANCE_UPLOAD_LETTER_REQUESTER:
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_SELF:
      return darkTheme;
    case ASSET_SPLIT_OTHER_PARTY_COMPLETING:
    case DOCUMENT_TYPES_OTHER_NOT_READY:
    case SUGGESTED_DIVISION_WAITING:
    case DOCUMENT_TYPES_WAITING_FOR_OTHER:
    case PARENTING_PLAN_WAITING_FOR_OTHER:
    case PARENTING_PLAN_OTHER_NOT_READY:
    case NEW_PARENTING_PLAN_WAITING_FOR_OTHER:
    case NEW_PARENTING_PLAN_OTHER_NOT_READY:
    case ASSET_SPLIT_OFFER_SENT:
    case RESET_SUGGESTED_DIVISION_WAITING_FOR_OTHER:
    case SUPERANNUATION_BALANCE_REQUEST_CANCELLED_REQUESTEE:
    case SUPERANNUATION_REQUEST_BALANCE_COMPLETED_REQUESTER:
    case SUPERANNUATION_REQUEST_BALANCE_IN_PROGRESS_REQUESTEE:
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTER:
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_APPROVED_REQUESTEE:
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTEE:
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_REJECTED_REQUESTER:
    case SUPER_SPLITTING_REMOVE_SUPERANNUATION_WAITING_FOR_OTHER:
      return pastelPurpleTheme;
    case PARENTING_PLAN_HAVE_NO_CHILDREN:
    case SUGGESTED_DIVISION_CANT_CONTINUE:
    case RELATIONSHIP_DASHBOARD_NOT_COMPLETED:
    case DASHBOARD_TAB_LOCKED:
    case CONSENT_ORDER_SUPERANNUATION_NOT_SUITABLE:
      return orangeTheme;
    case CONSENT_ORDER_GET_STARTED_COMPLETE:
    case CONSENT_ORDER_RELATIONSHIP_COMPLETE:
    case CONSENT_ORDER_FINANCES_COMPLETE:
    case CONSENT_ORDER_ASSETS_COMPLETE:
    case CONSENT_ORDERS_GET_STARTED_REQUIREMENTS:
    case CONSENT_ORDERS_GET_STARTED_NOT_SUITABLE:
    case CO_DASHBOARD_DOCUMENT_TYPES_CONSENT_ORDER_PAYMENT_REQUIRED:
    case SUPER_SPLITTING_CONSENT_ORDER_VIEW_PFL_PAYER:
    case SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_SELF_PAYEE:
      return greenTheme;
    case SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYEE:
    case SUPER_SPLITTING_CONSENT_ORDER_CHANGES_COMPLETE_PAYEE:
    case SUPER_SPLITTING_CONSENT_ORDER_CHANGES_ACCEPTED_PAYER:
    case SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_OTHER_PAYER:
    case SUPER_SPLITTING_CONSENT_ORDER_NO_CHANGES_PAYER:
    case SUPER_SPLITTING_CONSENT_ORDER_WAITING_FOR_OTHER_TO_SEND_PAYEE:
    case CONSENT_ORDER_GET_STARTED_LOCKED:
      return lightGreenTheme;
    default:
      return theme;
  }
};

export default getBannerTheme;
