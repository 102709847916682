import { Theme } from 'Common/Utils/theme';
import styled from 'styled-components';

const ProgressMeter = styled.div<{
  theme: Theme;
  percentage?: number;
}>`
  width: ${({ percentage }) => percentage || 2}%;
  background-color: ${({ theme }) => theme.continueProgressMeterColour};
  display: block;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export default ProgressMeter;
