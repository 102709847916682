import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { ODRS_PROFILE_SIGNOUT_ROUTE } from 'Common/routes';
import { Link } from 'react-router-dom';
import AppFooter from 'Common/UI/AppFooter/AppFooter';
import defaultTheme, { sizes, blackTheme } from 'Common/Utils/theme';
import PageContent from 'Common/UI/Layout/PageContent';
import Drawer from 'Common/UI/Layout/Drawer';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import TextButton from 'Common/UI/Button/TextButton';
import GenericButton from 'Common/UI/Button/GenericButton';
import FlexScroller from 'Common/UI/Layout/FlexScroller';
import PageContainer from 'Common/UI/Layout/PageContainer';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import Textfield from 'Common/UI/Form/Textfield';
import {
  getUserAttributesAction,
  getAttributeVerificationCodeAction,
  verifyAttributeAction,
} from 'App/State/UserActions';
import AppHeader from 'App/UI/AppHeader';
import FeedbackInline from 'Common/UI/Feedback/FeedbackInline';
import ProfilePromptContent from './ProfilePromptContent';
import {
  PROFILE_STATE_VIEW,
  PROFILE_STATE_LIMIT_EXCEEDED,
  PROFILE_STATE_VERIFYING_PHONE_ERROR,
  PROFILE_STATE_VERIFYING_EMAIL_ERROR,
  PROFILE_STATE_VERIFY_EMAIL,
  PROFILE_STATE_VERIFYING_EMAIL,
  PROFILE_STATE_VERIFY_PHONE,
  PROFILE_STATE_VERIFYING_PHONE,
} from './constants';
import DataExport from './DataExport';

import YourDetails from './YourDetails';
import AgreementDocuments from './AgreementDocuments';

const ATTRIBUTES_FOR_STATE = {
  PROFILE_STATE_VERIFY_EMAIL: 'email',
  PROFILE_STATE_VERIFY_PHONE: 'phone_number',
  PROFILE_STATE_VERIFYING_EMAIL: 'email',
  PROFILE_STATE_VERIFYING_PHONE: 'phone_number',
  PROFILE_STATE_VERIFYING_EMAIL_ERROR: 'email',
  PROFILE_STATE_VERIFYING_PHONE_ERROR: 'phone_number',
};

const ERROR_STATE_FOR_ATTRIBUTE = {
  email: PROFILE_STATE_VERIFYING_EMAIL_ERROR,
  phone: PROFILE_STATE_VERIFYING_PHONE_ERROR,
};

const StyledPageContent = styled(PageContent)`
  padding: 0;
  position: relative;
  overflow: hidden;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 24px);
    left: 0;
    right: 0;
    height: 24px;
    background: ${props => props.theme.colours.backgroundGrey};
  }
`;

const StyledDrawer = styled(Drawer)`
  background: ${props => props.theme.colours.white};
  color: ${props => props.theme.colours.black};

  min-height: 100%;

  height: auto;
  transition: transform 1s;
  padding-top: ${({ theme }) => theme.spacing[6]}
    ${({ theme }) => theme.tokens.pageSpacingHorizontal};
`;

const DrawerContent = styled.div`
  flex: 1;
  max-width: ${sizes.md.breakpoint};
`;

const StyledFooterDrawer = styled(StyledDrawer)`
  background: ${props => props.theme.colours.backgroundGrey};
  border-radius: 0;
`;

const LogoutButton = styled(GenericButton)`
  margin: 24px 0;
  min-width: 140px;
  text-decoration: none;
`;

const Verification = styled.div`
  padding: ${props => props.theme.padding.medium}px;
  ${Textfield} {
    padding-bottom: ${props => props.theme.padding.medium}px;
  }
`;

const ResendButton = styled(TextButton)`
  color: ${props => props.theme.buttonTextReverse};
`;

const HeaderContainer = styled.div`
  flex-grow: 0;
`;

class Profile extends React.Component {
  constructor(props) {
    super(props);

    const justChangedPhoneNumber =
      props.location &&
      props.location.state &&
      props.location.state.willChangePhoneNumber;

    this.state = {
      sessionDetails: {},
      state: justChangedPhoneNumber
        ? PROFILE_STATE_VERIFY_PHONE
        : PROFILE_STATE_VIEW,
      verificationCode: '',
      isSigningOut: false,
    };

    this.handleVerifyClick = this.handleVerifyClick.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.sendCode = this.sendCode.bind(this);

    if (
      props.location &&
      props.location.state &&
      props.location.state &&
      props.location.state.verifyEmail
    ) {
      this.handleVerifyClick(PROFILE_STATE_VERIFY_EMAIL);
    }
  }

  componentDidMount() {
    this.refreshAttributes();
  }

  refreshAttributes() {
    const { getUserAttributes } = this.props;
    const { state: currentState } = this.state;

    const shouldResetToViewAfterRefresh =
      [PROFILE_STATE_VERIFY_PHONE].indexOf(currentState) === -1;

    this.setState(
      {
        sessionDetails: {},
      },
      () => {
        getUserAttributes().then(result => {
          this.setState({
            sessionDetails: result,
            state: shouldResetToViewAfterRefresh
              ? PROFILE_STATE_VIEW
              : currentState,
          });
        });
      }
    );
  }

  handleVerifyClick(state) {
    this.sendCode(state);
  }

  sendCode(state) {
    const { getAttributeVerificationCode } = this.props;
    if (ATTRIBUTES_FOR_STATE[state]) {
      getAttributeVerificationCode(ATTRIBUTES_FOR_STATE[state])
        .then(() => {
          this.setState({ state });
        })
        .catch(() => {
          this.setState({ state: PROFILE_STATE_LIMIT_EXCEEDED });
        });
    } else {
      // It would be odd to not have a known state here,
      // but recover gracefully(?)
      this.setState({ state: PROFILE_STATE_VIEW });
    }
  }

  verifyCode() {
    const { state, verificationCode } = this.state;
    const { verifyAttribute } = this.props;

    let moveToState;

    switch (state) {
      case PROFILE_STATE_VERIFY_EMAIL:
        moveToState = PROFILE_STATE_VERIFYING_EMAIL;
        break;
      case PROFILE_STATE_VERIFY_PHONE:
        moveToState = PROFILE_STATE_VERIFYING_PHONE;
        break;
      default:
        moveToState = state;
    }

    this.setState(
      {
        state: moveToState,
      },
      () => {
        const attribute = ATTRIBUTES_FOR_STATE[state];
        verifyAttribute(attribute, verificationCode)
          .then(() => {
            this.refreshAttributes();
          })
          .catch(() => {
            this.setState({ state: ERROR_STATE_FOR_ATTRIBUTE[attribute] });
          });
      }
    );
  }

  render() {
    const { sessionDetails, state, verificationCode, isSigningOut } =
      this.state;
    const { matter } = this.props;

    const { given_name: firstname } = sessionDetails;

    // if the signout button has been clicked, we need to return null otherwise
    // the app throws errors when trying to re-render before we are redirected
    if (isSigningOut) {
      return null;
    }

    return (
      <ThemeProvider theme={defaultTheme}>
        <PageContainer>
          <FlexScroller>
            <HeaderContainer className="transition-fade-up">
              <AppHeader
                withProfile
                matter={matter}
                theme={blackTheme}
                title="Your account"
              />
            </HeaderContainer>

            <ProfilePromptContent state={state} />
            <StyledPageContent className="transition-fade-down">
              <StyledDrawer>
                <ResponsiveContainer>
                  <DrawerContent>
                    <ThemeProvider theme={defaultTheme}>
                      {state !== PROFILE_STATE_VIEW &&
                        state !== PROFILE_STATE_LIMIT_EXCEEDED && (
                          <Verification>
                            <Textfield
                              value={verificationCode}
                              name="code"
                              label="6 Digit Verification code"
                              placeholder=""
                              onChange={updateValue =>
                                this.setState({ verificationCode: updateValue })
                              }
                            />
                            <GenericButton
                              onClick={this.verifyCode}
                              disabled={
                                state === PROFILE_STATE_VERIFYING_EMAIL ||
                                state === PROFILE_STATE_VERIFYING_PHONE
                              }
                            >
                              Verify
                            </GenericButton>
                            <ResendButton
                              type="button"
                              onClick={() => this.sendCode(state)}
                              disabled={
                                state === PROFILE_STATE_VERIFYING_EMAIL ||
                                state === PROFILE_STATE_VERIFYING_PHONE
                              }
                            >
                              Resend code
                            </ResendButton>
                          </Verification>
                        )}
                      {(state === PROFILE_STATE_VIEW ||
                        state === PROFILE_STATE_LIMIT_EXCEEDED) &&
                        firstname && (
                          <>
                            <YourDetails
                              handleVerifyClick={this.handleVerifyClick}
                              sessionDetails={sessionDetails}
                              matter={matter}
                            />

                            <AgreementDocuments />

                            <LogoutButton
                              data-cy="logout-button"
                              as={Link}
                              to={ODRS_PROFILE_SIGNOUT_ROUTE}
                            >
                              Sign out
                            </LogoutButton>
                          </>
                        )}
                    </ThemeProvider>
                  </DrawerContent>
                </ResponsiveContainer>
              </StyledDrawer>
              <StyledFooterDrawer>
                <ResponsiveContainer marginTop={12}>
                  <DrawerContent>
                    <FlexGrid medium={2}>
                      <DataExport
                        matter={matter}
                        userAttributes={sessionDetails}
                      />
                    </FlexGrid>

                    <FlexGrid medium={2}>
                      <FeedbackInline />
                    </FlexGrid>
                  </DrawerContent>
                </ResponsiveContainer>
              </StyledFooterDrawer>
            </StyledPageContent>
            <AppFooter />
          </FlexScroller>
        </PageContainer>
      </ThemeProvider>
    );
  }
}

Profile.propTypes = {
  getUserAttributes: PropTypes.func.isRequired,
  getAttributeVerificationCode: PropTypes.func.isRequired,
  verifyAttribute: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  matter: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  matter: state.matter,
});

const mapDispatchToProps = dispatch => ({
  getUserAttributes: () => dispatch(getUserAttributesAction()).unwrap(),
  getAttributeVerificationCode: attribute =>
    dispatch(getAttributeVerificationCodeAction(attribute)),
  verifyAttribute: (attribute, code) =>
    dispatch(verifyAttributeAction({ attribute, code })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
