import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { darkGreenTheme, Theme } from 'Common/Utils/theme';
import IconTextButton from 'Common/UI/Button/IconTextButton';
import IconEdit from 'Common/Assets/images/icon-edit.svg';
import { Row } from 'Common/UI/Layout/Flex';
import { Heading4 } from 'Common/UI/Text/Headings';

const Wrapper = styled.div<{
  theme: Theme;
}>`
  padding-bottom: ${({ theme }) => theme.spacing[4]};
  width: 100%;
`;

const InnerWrapper = styled.div<{
  theme: Theme;
}>`
  background: ${({ theme }) => theme.colours.offWhite};
  padding: ${({ theme }) => theme.spacing[4]};
  border-top: 1px solid ${({ theme }) => theme.colours.lightGrey};

  max-width: 100%;
  width: 800px;
`;

type Props = {
  index: number;
  onAddParagraph: () => void;
};

const ClauseAddNewParagraph: React.FC<Props> = ({ index, onAddParagraph }) => (
  <ThemeProvider theme={darkGreenTheme}>
    <Wrapper data-cy="clause-add-new-paragraph">
      <InnerWrapper>
        <Heading4 mt={2} mb={4}>
          Paragraph {index}
        </Heading4>
        <Row gap={4}>
          <IconTextButton
            icon={IconEdit}
            iconOnLeft
            subtle
            onClick={onAddParagraph}
          >
            Add paragraph
          </IconTextButton>
        </Row>
      </InnerWrapper>
    </Wrapper>
  </ThemeProvider>
);

export default ClauseAddNewParagraph;
