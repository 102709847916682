import React from 'react';

import { Heading2MarginTopNone } from 'Common/UI/Text/Headings';
import Paragraph from 'Common/UI/Text/Paragraph';
import {
  BANNER_HIDE,
  BANNER_SHOW,
  STATUS_NEW_ONLY_SELF,
  STATUS_UPDATE_REQUESTED,
  SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_OTHER_PAYER,
} from 'Common/constants';
import SpacedBox from 'Common/UI/Layout/SpacedBox';
import { AppState } from 'Common/Data/Types/appState';
import GenericButton from 'Common/UI/Button/GenericButton';
import {
  sessionStorageLoad,
  sessionStorageSave,
} from 'Common/Utils/sessionStorage';
import { getMatter } from 'Common/Utils/MatterProps';
import AssetProperties from 'Common/Data/App/assetProperties';

export const superannuationConsentOrderChangesWaitingForOtherPayerFlags: AppState =
  {
    id: 'superannuationConsentOrderChangesWaitingForOtherPayer',
    description:
      'Notify payer that the payee will need to review and accept the superannuation clause changes',
    check: () => {
      const { isSuperIncludedInOffer, isPayer, superSplittingFlags } =
        AssetProperties();

      const {
        payerHasSubmittedProceduralFairnessLetter,
        payerHasConfirmedNoChangesToClauses,
      } = superSplittingFlags;

      if (
        !isSuperIncludedInOffer ||
        !isPayer ||
        !payerHasSubmittedProceduralFairnessLetter ||
        payerHasConfirmedNoChangesToClauses
      ) {
        return false;
      }

      const { items = [] } = getMatter();

      const superannuationItem = items.find(
        item => item.SectionID === 'superannuationfundclauses'
      );

      // if the superannuation item doesn't exist, then this app state is not relevant so return false
      if (!superannuationItem) {
        return false;
      }

      const { status } = superannuationItem;

      // if the card status is STATUS_NEW_ONLY_SELF or STATUS_UPDATE_REQUESTED, then we are not waiting for the payee to review anything so return false
      // NOTE: this logic should match the logic in `SuperannuationFundClauseEditorCard.tsx` as there is a matching card state
      if (
        status === STATUS_NEW_ONLY_SELF ||
        status === STATUS_UPDATE_REQUESTED
      ) {
        return false;
      }

      return true;
    },
    flags: {
      appState: SUPER_SPLITTING_CONSENT_ORDER_CHANGES_WAITING_FOR_OTHER_PAYER,
      bannerTopState: () =>
        sessionStorageLoad(
          'superannuationConsentOrderChangesWaitingForOtherPayerDismissed'
        )
          ? BANNER_HIDE
          : BANNER_SHOW,
    },
  };

type Props = {
  otherName: string;
  updateAppState: () => void;
};

export const SuperannuationConsentOrderChangesWaitingForOtherPayer: React.FC<Props> =
  ({ otherName, updateAppState }) => (
    <div data-cy="banner-consent-order-changes-waiting-for-other">
      <SpacedBox marginBottom={8}>
        <Heading2MarginTopNone>
          {otherName} will need to confirm your superannuation updates
        </Heading2MarginTopNone>
        <Paragraph>
          Once {otherName} has confirmed your updates, you can finalise your
          consent order application and file it with the court.
        </Paragraph>
      </SpacedBox>
      <GenericButton
        fullWidth
        onClick={() => {
          sessionStorageSave(
            `superannuationConsentOrderChangesWaitingForOtherPayerDismissed`,
            true
          );
          updateAppState();
        }}
        small
        data-cy="dismiss-button"
      >
        Got it
      </GenericButton>
    </div>
  );
