import React from 'react';

import {
  SECTION_PROPERTY,
  PAGE_PROPERTY_MONEY_AND_PROPERTY,
  PAGE_PROPERTY_CONTRIBUTIONS,
  PAGE_PROPERTY_ASSET_DIVISION,
  PAGE_PROPERTY_COMPLETE,
  DashboardPages,
} from 'Common/constants';
import Dashboard from 'App/UI/Dashboard/Dashboard';
import MoneyAndProperty from './DashboardProperty/MoneyAndProperty';
import Contributions from './DashboardProperty/Contributions';
import AssetDivision from './DashboardProperty/AssetDivision';
import Complete from './DashboardProperty/Complete';

type Props = {
  tab: DashboardPages;
};

const DashboardProperty: React.FC<Props> = ({ tab }) => (
  <Dashboard
    tab={tab}
    section={SECTION_PROPERTY}
    pages={{
      [PAGE_PROPERTY_MONEY_AND_PROPERTY]: MoneyAndProperty,
      [PAGE_PROPERTY_CONTRIBUTIONS]: Contributions,
      [PAGE_PROPERTY_ASSET_DIVISION]: AssetDivision,
      [PAGE_PROPERTY_COMPLETE]: Complete,
    }}
  />
);

export default DashboardProperty;
