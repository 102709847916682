import CloseButton from 'Common/UI/Button/CloseButton';
import styled from 'styled-components';

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  text-decoration: none;
`;

export default StyledCloseButton;
