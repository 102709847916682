import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import formatCurrency from 'Common/Utils/formatCurrency';
import getNames from 'Common/Utils/getNames';
import MatterProps from 'Common/Utils/MatterProps';
import { ODRS_DIVISION_ASSETS_VIEW_OFFER } from 'Common/routes';
import FlexGrid from 'Common/UI/Layout/FlexGrid';
import { Column } from 'Common/UI/Layout/Flex';
import ResponsiveContainer from 'Common/UI/Layout/ResponsiveContainer';
import ResponsiveTextContainer from 'Common/UI/Layout/ResponsiveTextContainer';
import Paragraph from 'Common/UI/Text/Paragraph';
import { Heading2 } from 'Common/UI/Text/Headings';
import { Label } from 'Common/UI/Text/Label';
import Dialog from 'Common/UI/Layout/Dialog';
import PageContent from 'Common/UI/Layout/PageContent';
import { blackTheme } from 'Common/Utils/theme';
import GenericButton from 'Common/UI/Button/GenericButton';
import push from 'Common/Utils/push';

export const FlexColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const FlexRow = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-around;
`;

const FullWidthFlexGrid = styled(FlexGrid)`
  width: 100%;
`;

export const OwingText = styled(Paragraph)`
  font-weight: bold;
  margin: 0;

  & > span.amount {
    font-weight: normal;
    color: ${props => props.theme.colours.darkOrange};
  }
`;

export const DoneButton = styled(GenericButton)``;

export const AmountLabel = styled(Paragraph)`
  margin: 0;
  font-size: 28px;
  color: ${props => props.theme.colours.darkOrange};
`;

export const AmountOwing = styled.div`
  background: ${props => props.theme.colours.offWhite};
  padding: ${props => props.theme.padding.small}px
    ${props => props.theme.padding.small}px
    ${props => props.paddingBottom || props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: space-around;
  flex-wrap: wrap;

  ${DoneButton} {
    width: 100%;
  }
`;

export const FixedFooter = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: ${props => props.theme.colours.white};
  padding: ${props => props.theme.padding.small}px;
  ${({ theme }) => theme.tokens.pageSpacingHorizontal}
  box-shadow: 8px 8px 24px rgba(30, 26, 78, 0.16);
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: space-around;

  ${DoneButton} {
    padding-top: 0;
    padding-bottom: 0;
    height: 35px;
  }
`;

export const AssetSplitPageContent = styled(PageContent)`
  ${props => props.bottomPadding && 'padding-bottom: 300px;'}
`;

const mapDispatchToProps = dispatch => ({
  navigate: to => dispatch(push(to)),
});

export const TransferFooter = connect(
  null,
  mapDispatchToProps
)(
  ({
    navigate,
    nextRoute,
    buttonLabel,
    buttonDisabled,
    remainingAmountToTransfer,
    absoluteRemainingAmountToTransfer,
    isAssetSplitCompleted,
    withPropertyDisclaimer,
    isOwingParty,
  }) => {
    const names = getNames();

    const whoPaysWhoText =
      (isOwingParty && remainingAmountToTransfer > 0) ||
      (!isOwingParty && remainingAmountToTransfer < 0)
        ? `To be transferred to ${names.other}`
        : 'To be transferred to you';

    return (
      !isAssetSplitCompleted && (
        <>
          <AmountOwing>
            <ResponsiveContainer>
              <FullWidthFlexGrid medium={2}>
                <Column alignItems="flex-start">
                  <Heading2>Remaining amount to be transferred</Heading2>

                  <FlexRow>
                    <FlexColumn>
                      <AmountLabel data-cy="amount-remaining">
                        {formatCurrency(absoluteRemainingAmountToTransfer)}
                      </AmountLabel>
                    </FlexColumn>
                  </FlexRow>
                  <FlexRow>
                    <Paragraph data-cy="who-pays">{whoPaysWhoText}</Paragraph>
                  </FlexRow>
                </Column>
                <Column alignItems="flex-start" justifyContent="flex-end">
                  <FlexRow>
                    <DoneButton
                      data-cy="footer-next"
                      onClick={() => navigate(nextRoute)}
                      disabled={buttonDisabled}
                    >
                      {buttonLabel}
                    </DoneButton>
                  </FlexRow>
                  {withPropertyDisclaimer && (
                    <FlexRow>
                      <Paragraph>
                        <br />
                        In order to continue, you will need to state what you
                        plan to do for each shared asset. You will be able to
                        choose what to do for your property in the next step.
                      </Paragraph>
                    </FlexRow>
                  )}
                </Column>
              </FullWidthFlexGrid>
            </ResponsiveContainer>
          </AmountOwing>
          <FixedFooter>
            <FlexColumn>
              <OwingText>
                Transfer amount:{' '}
                <span className="amount">
                  {formatCurrency(absoluteRemainingAmountToTransfer)}
                </span>
              </OwingText>
              <Label>{whoPaysWhoText}</Label>
            </FlexColumn>
            <FlexColumn>
              <DoneButton
                onClick={() => navigate(nextRoute)}
                disabled={buttonDisabled}
              >
                Next
              </DoneButton>
            </FlexColumn>
          </FixedFooter>
        </>
      )
    );
  }
);

TransferFooter.propTypes = {
  nextRoute: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonDisabled: PropTypes.bool,
  remainingAmountToTransfer: PropTypes.number.isRequired,
  absoluteRemainingAmountToTransfer: PropTypes.number.isRequired,
  withPropertyDisclaimer: PropTypes.bool,
};

TransferFooter.defaultProps = {
  buttonDisabled: false,
  withPropertyDisclaimer: false,
};

const ParagraphNoTopMargin = styled(Paragraph)`
  margin-top: 0;
`;

export const DialogViewProposal = connect(
  null,
  mapDispatchToProps
)(({ navigate, showDialog }) => (
  <Dialog
    showDialog={showDialog}
    theme={blackTheme}
    content={
      <ResponsiveTextContainer>
        <ParagraphNoTopMargin>
          {MatterProps('other.firstname')} has put together a proposal for how
          you can divide your assets to reach the agreed percentages. You will
          also be able to agree or respond and make your own proposal.
        </ParagraphNoTopMargin>
      </ResponsiveTextContainer>
    }
    buttons={
      <ResponsiveTextContainer>
        <GenericButton
          fullWidth
          onClick={() => navigate(ODRS_DIVISION_ASSETS_VIEW_OFFER)}
        >
          View {MatterProps('other.firstname')}
          &apos;s proposal
        </GenericButton>
      </ResponsiveTextContainer>
    }
  />
));

DialogViewProposal.propTypes = {
  showDialog: PropTypes.bool.isRequired,
};
