import React, { createContext, useMemo } from 'react';
import getNames from 'Common/Utils/getNames';
import AssetProps from 'Common/Data/App/assetProperties';
import getPartyKeys from 'Common/Utils/getPartyKeys';
import {
  ODRS_DIVISION_ASSETS_INFO,
  ODRS_DIVISION_ASSETS_TRANSFER_SELF,
  ODRS_DIVISION_ASSETS_TRANSFER_OTHER,
  ODRS_DIVISION_ASSETS_TRANSFER_JOINT,
  ODRS_DIVISION_ASSETS_TRANSFER_JOINT_PROPERTY,
  ODRS_DIVISION_ASSETS_CONFIRM,
  ODRS_DIVISION_ASSETS_VIEW_OFFER,
} from 'Common/routes';

type Context = {
  pages: {
    title: string;
    route: string;
    index: number;
    id: string;
  }[];
  getPageTitle: (route: string) => string;
};

export const DivisionAssetsContext = createContext<Context>({
  pages: [],
  getPageTitle: () => '',
});

export const DivisionAssetsProvider: React.FC<{ children: React.ReactNode }> =
  ({ children }) => {
    const {
      hasAnyOfferBeenMade,
      lastSentOfferBy,
      canStartOrWorkOnOffer,
      isAssetSplitCompleted,
      otherPartyIsMakingAnOffer,
    } = AssetProps();
    const party = getPartyKeys();
    const names = getNames();

    let index = 0;

    const pages = [
      {
        title: 'Overview',
        route: ODRS_DIVISION_ASSETS_INFO,
        index: (index += 1),
        id: 'asset-split-overview-tab',
      },
    ];

    if (hasAnyOfferBeenMade) {
      let offerTitle;

      if (isAssetSplitCompleted) {
        offerTitle = 'Your agreed asset division';
      } else {
        offerTitle = `${
          lastSentOfferBy === party.self ? 'Your' : `${names.other}'s`
        } offer`;
      }
      pages.push({
        title: offerTitle,
        route: ODRS_DIVISION_ASSETS_VIEW_OFFER,
        index: (index += 1),
        id: 'asset-split-overview-tab',
      });
    }

    if (!isAssetSplitCompleted && !otherPartyIsMakingAnOffer) {
      pages.push({
        title: 'Your assets',
        route: ODRS_DIVISION_ASSETS_TRANSFER_SELF,
        index: (index += 1),
        id: 'asset-split-self-assets-tab',
      });

      pages.push({
        title: `${names.other}'s assets`,
        route: ODRS_DIVISION_ASSETS_TRANSFER_OTHER,
        index: (index += 1),
        id: 'asset-split-other-assets-tab',
      });

      pages.push({
        title: 'Joint assets',
        route: ODRS_DIVISION_ASSETS_TRANSFER_JOINT,
        index: (index += 1),
        id: 'asset-split-joint-assets-tab',
      });

      pages.push({
        title: 'Joint property',
        route: ODRS_DIVISION_ASSETS_TRANSFER_JOINT_PROPERTY,
        index: (index += 1),
        id: 'asset-split-joint-property-tab',
      });
    }

    if (canStartOrWorkOnOffer) {
      pages.push({
        title: 'Submit',
        route: ODRS_DIVISION_ASSETS_CONFIRM,
        index: (index += 1),
        id: 'asset-split-confirm-tab',
      });
    }

    const getPageTitle = (route: string) => {
      const page = pages.find(p => p.route === route);

      if (page) {
        return `${page.index}. ${page.title}`;
      }

      return '';
    };

    const values = useMemo(() => ({ pages, getPageTitle }), []);

    return (
      <DivisionAssetsContext.Provider value={values}>
        {children}
      </DivisionAssetsContext.Provider>
    );
  };
